import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store';
import { cognito, actions, localStorage,  LoginContainer } from './auth';
import { cognito as cognitoCtx } from './env';
import { getAndClearPullRequestRedirect } from './RedirectToPullRequestRoute';
import { determineMaximumScaleViewport } from './util/dom';

determineMaximumScaleViewport();

const showError = (error: any) => {
    document.write(
        'An unexpected error occurred. Please try again. If you are using an old browser (like IE11) please try again using something more modern. If the problem persist please contact support@dintero.com '
    );
    // rethrow error so sentry can log it
    throw error;
};

const newCognitoLogin = () => {
    // We don't have a valid user session.
    if (cognitoCtx().external) {
        // Redirect to cognito if external
        cognito.login();
    } else {
        // Render login container
        ReactDOM.render(<LoginContainer />, document.getElementById('root'));
    }
};

try {
    // Force a reload when page is loaded from bfcache
    window.onpageshow = (event: PageTransitionEvent) => {
        if (event.persisted) {
            window.location.reload();
        }
    };

    const session = localStorage.getCognitoSession();
    cognito
        .authenticate(session)
        .then((authResult) => {
            if (authResult) {
                // Set user
                store.dispatch(actions.setAuthenticatedUser(authResult));

                // Start react application
                ReactDOM.render(<App />, document.getElementById('root'));
            } else {
                newCognitoLogin();
            }
        })
        .catch(error => {
            console.log('Authenticate from session failed', error);
            localStorage.clearCognitoSession();
            return newCognitoLogin();
        });
} catch (error) {
    showError(error);
}
