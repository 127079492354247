import React from 'react';

const Payments = () => (
    <svg width="22px" height="19px" viewBox="0 0 22 19" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>money copy 7</title>
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="1">
            <g id="Group-4" transform="translate(-41.000000, -285.000000)" fill="currentcolor" fillRule="nonzero">
                <g id="Group-3">
                    <g id="money-copy-7" transform="translate(41.000000, 285.000000)">
                        <rect id="Rectangle" x="0" y="14.25" width="22" height="1.58333333"></rect>
                        <rect id="Rectangle" x="0" y="17.4166667" width="22" height="1.58333333"></rect>
                        <path
                            d="M17.2857143,4.75 C16.4178383,4.75 15.7142857,5.45888248 15.7142857,6.33333333 C15.7142857,7.20778419 16.4178383,7.91666667 17.2857143,7.91666667 C18.1535903,7.91666667 18.8571429,7.20778419 18.8571429,6.33333333 C18.8571429,5.45888248 18.1535903,4.75 17.2857143,4.75 Z"
                            id="Path"
                        ></path>
                        <path
                            d="M11,9.5 C9.26424793,9.5 7.85714286,8.08223504 7.85714286,6.33333333 C7.85714286,4.58443163 9.26424793,3.16666667 11,3.16666667 C12.7357521,3.16666667 14.1428571,4.58443163 14.1428571,6.33333333 C14.1409086,8.08142117 12.7349443,9.49803671 11,9.5 L11,9.5 Z M11,4.75 C10.132124,4.75 9.42857143,5.45888248 9.42857143,6.33333333 C9.42857143,7.20778419 10.132124,7.91666667 11,7.91666667 C11.867876,7.91666667 12.5714286,7.20778419 12.5714286,6.33333333 C12.5705624,5.45924425 11.867517,4.75087278 11,4.75 Z"
                            id="Shape"
                        ></path>
                        <path
                            d="M4.71428571,4.75 C3.84640968,4.75 3.14285714,5.45888248 3.14285714,6.33333333 C3.14285714,7.20778419 3.84640968,7.91666667 4.71428571,7.91666667 C5.58216175,7.91666667 6.28571429,7.20778419 6.28571429,6.33333333 C6.28571429,5.45888248 5.58216175,4.75 4.71428571,4.75 Z"
                            id="Path"
                        ></path>
                        <path
                            d="M20.4285714,12.6666667 L1.57142857,12.6666667 C0.704466414,12.6644476 0.00220234024,11.9568634 0,11.0833333 L0,1.58333333 C0.00220234024,0.709803281 0.704466414,0.00221902464 1.57142857,-7.03141249e-16 L20.4285714,-7.03141249e-16 C21.2955336,0.00221902464 21.9977977,0.709803281 22,1.58333333 L22,11.0833333 C21.9988314,11.957296 21.2959629,12.6654892 20.4285714,12.6666667 Z M20.4285714,1.58333333 L1.57142857,1.58333333 L1.57142857,11.0833333 L20.4285714,11.0833333 L20.4285714,1.58333333 Z"
                            id="Shape"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Payments;
