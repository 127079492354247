import React from 'react';
import styled from 'styled-components/macro';

import { colors } from '../../styles/constants';
import { Gap } from '../../helpers/paging';
import { Button } from '../Buttons';

interface GapProps {
    gap: Gap;
    fillGap: (id: string) => void;
    label: string;
}

const ListGap = (
    {
        gap,
        fillGap,
        label,
    }: GapProps
) => (<GapWrap>
    <GapArea>
        <Button className="tiny alt" onClick={() => fillGap(gap.id)}>
            {label}
        </Button>
    </GapArea>
</GapWrap>);

const GapWrap = styled.div`
    display: table-row;
    position: relative;
    height: 64px;
`;

const GapArea = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 64px;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;

    &:after {
        content: ' ';
        position: absolute;
        height: 1px;
        background: ${colors.borderLight};
        bottom: 0;
        left: 32px;
        right: 32px;
    }
`;

export default ListGap;
