import { connect } from 'react-redux';

import { initiateInstabankConnection, closeInstabankModal } from '../actions';
import { State } from '../../../../reducer';
import { getSelector } from '../selectors';

import InstabankModal from '../components/InstabankModal';

const mapStateToProps = (state: State) => ({
    instabank: getSelector(state),
});

const mapDispatchToProps = {
    initiateInstabankConnection,
    closeInstabankModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InstabankModal);
