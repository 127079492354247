import { Account } from '../../types/management-auth';
import { Gap, isGap } from '../../helpers/paging';
import { AccountOrGap } from './types';
import { capitalize } from '../../helpers/string';

export const accountPagingIdFn = (account: AccountOrGap): string | undefined => {
    if (isAccount(account)) {
        return account.account_id;
    }
    return account.id;
};

export const getNextPageArgs = (accounts: AccountOrGap[]): { [key: string]: string } => {
    if (accounts.length === 0) {
        return {};
    }
    const last = accounts[accounts.length - 1];
    return { starting_after: accountPagingIdFn(last) || '' };
};

export const getGapPageArgs = (accounts: AccountOrGap[], id: string): { [key: string]: string } => {
    const gap = accounts.find((o) => isGap(o) && o.id === id);
    if (!gap) {
        return {};
    }
    return { starting_after: gap.id || '' };
};

export const isAccount = (obj: AccountOrGap): obj is Account => {
    return (obj as Gap).gap !== true;
};

export const getAccountManager = (value: string | undefined) => {
    if (!value) {
        return '';
    }
    if (value.endsWith('@dintero.com')) {
        const beforeAt = value.split('@')[0] || '';
        const userParts = beforeAt.split('.');
        return userParts.map(capitalize).join(' ');
    }
    return value;
};
