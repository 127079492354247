import _keyBy from 'lodash/keyBy';
import qs from 'qs';
import { Dispatch } from 'redux';
import apiRequest, { isApiError } from '../apiRequest';
import { createAccountIdChangedCaseReducer } from '../auth/useAccountId';
import { CORE_API_HOSTNAME } from '../env';
import { Causes, errorExternalStore } from '../errors';
import { CustomerDueDiligenceCase, CustomerDueDiligenceCaseDraft } from '../types/management-auth';
import { createSwitchlessReducer } from '../util/switchlessReducer';

export type CustomerDueDiligenceQuery = Partial<{
    products: CustomerDueDiligenceCase['products'];
    /**
     * supports `*` as wildcards, returns cases for locations
     */
    payout_destination_id: string;
    case_status: CustomerDueDiligenceCase['events'][number]['case_status'][];
    actions_on_approval: CustomerDueDiligenceCase['actions_on_approval'];
}>;

export type CustomerDueDiligenceState = {
    loading: Record<string, boolean>;
    cases: Record<string, Record<string, CustomerDueDiligenceCase>>;
    query: Record<string, CustomerDueDiligenceQuery>;
    queriedIds: Record<string, string[]>;
};

const initialState: CustomerDueDiligenceState = {
    loading: {},
    cases: {},
    query: {},
    queriedIds: {},
};

const namespace = 'management.cdd' as const;

const { setState, reducer } = createSwitchlessReducer({
    initialState,
    namespace,
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

const setError = (err: any) => {
    if (isApiError(err)) {
        return errorExternalStore.dispatch('setError', {
            cause: Causes.UnhandledStatus, status: err.status, statusText: err.statusText, message: err.message, 'request-id': err.requestId,
        });
    }
    return errorExternalStore.dispatch('setError', {
        cause: Causes.UnhandledStatus, message: err.message,
    });
};

const createCddActions = (dispatch: Dispatch, accountId: string) => {
    const baseUrl = (requestAccountId: string) => `${CORE_API_HOSTNAME}/v1/accounts/${requestAccountId}/management/settings/cdd/cases`;

    const getCddCases = async (requestAccountId: string, query?: CustomerDueDiligenceQuery) => {
        dispatch(setState('getCddCasesAttempt', state => ({ loading: {
            ...state.loading,
            [requestAccountId]: true,
        } })));

        try {
            const cases = await apiRequest<CustomerDueDiligenceCase[]>(
                'GET',
                requestAccountId,
                `${baseUrl(requestAccountId)}${query ? `?${qs.stringify(query)}` : ''}`
            );
            const casesForRequestedAccountId = cases.filter(cs => cs.case_for.account_id === requestAccountId);
            dispatch(
                setState('getCddCasesSuccess', (state) => ({
                    cases: {
                        ...state.cases,
                        [requestAccountId]: {
                            ...state.cases[requestAccountId],
                            ..._keyBy(casesForRequestedAccountId, 'id'),
                        },
                    },
                    query: {
                        ...state.query,
                        [requestAccountId]: query || {},
                    },
                    queriedIds: {
                        ...state.queriedIds,
                        [requestAccountId]: casesForRequestedAccountId.map((c) => c.id),
                    },
                    loading: {
                        ...state.loading,
                        [requestAccountId]: false,
                    },
                }))
            );
        } catch (err) {
            if (err.status === 404) {
                // if merchant has not live mode, return an empty list
                dispatch(
                    setState('getCddCasesSuccess', (state) => ({
                        cases: {
                            ...state.cases,
                            [requestAccountId]: {},
                        },
                        query: {
                            ...state.query,
                            [requestAccountId]: query || {},
                        },
                        queriedIds: {
                            ...state.queriedIds,
                            [requestAccountId]: [],
                        },
                        loading: {
                            ...state.loading,
                            [requestAccountId]: false,
                        },
                    }))
                );
            } else {
                dispatch(setState('getCddCasesFailure', state => ({
                    loading: {
                        ...state.loading,
                        [requestAccountId]: false,
                    },
                })));
                errorExternalStore.dispatch('setError', err);
            }
        }
    };

    const postCddCase = async (requestAccountId: string, data: CustomerDueDiligenceCaseDraft): Promise<CustomerDueDiligenceCase | null> => {
        dispatch(setState('postCddCaseAttempt', state => ({ loading: {
            ...state.loading,
            [requestAccountId]: true,
        } })));

        try {
            const cddCase = await apiRequest<CustomerDueDiligenceCase>('POST', requestAccountId, baseUrl(requestAccountId), undefined, data);
            dispatch(
                setState('postCddCaseSuccess', (state) => ({
                    cases: {
                        ...state.cases,
                        [requestAccountId]: {
                            ...state.cases[requestAccountId],
                            [cddCase.id]: cddCase,
                        },
                    },
                    loading: {
                        ...state.loading,
                        [requestAccountId]: false,
                    },
                }))
            );
            return cddCase;
        } catch (err) {
            dispatch(setState('postCddCaseFailure', state => ({
                loading: {
                    ...state.loading,
                    [requestAccountId]: false,
                },
            })));
            errorExternalStore.dispatch('setError', err);
            return null;
        }
    };

    return {
        getCddCases,
        postCddCase,
    };
};

export { createCddActions, initialState, namespace, reducer };
