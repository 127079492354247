import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Formik } from 'formik';
import {
    ErrorMessage,
    MfaBadge,
    MfaHeading,
    MfaHeadingAction,
    MfaHeadingTitleDescription,
    StyledButton,
    Wrapper
} from './Components';
import { H3 } from '../../../components/Typography';
import { MfaSettingContent, MfaSettingExpandCollapse, MfaSettingLabel } from '../MfaSettingExpandCollapse';
import { AuthenticatedAccountUser } from '../../../types/management-auth';
import { ChangeMfaTotpModalStates } from '../../types';
import Button from '../../../components/Buttons/Button';
import { ButtonGroup } from '../../../components/Buttons';
import Totp from './TotpIcon';
import { getMfaTotpSecret } from '../../cognitoIdentityWrapper';
import { QRCodeSVG } from 'qrcode.react';
import Input from '../../../components/Forms/Input';
import Checkbox from '../../../components/Forms/Checkbox';
import Hr from '../../../components/Hr';
import styled from 'styled-components/macro';
import { distances } from '../../../styles/constants';
import { ValidationState } from '../../../components/Forms/PhoneNumber';

interface MfaTotpSettingsProps {
    account: AuthenticatedAccountUser['accounts'][number] | undefined;
    changeMfaTotpState: ChangeMfaTotpModalStates;
    setCurrentMfaType: (name: string | undefined) => void;
    changeMfaTotp: (enabled: boolean, user_code?: string) => void;
    currentMfaType: string | undefined;
    mfaTotpEnabled: boolean;
    disabled?: boolean;
}

const MfaTotpSettings = ({
    changeMfaTotpState,
    setCurrentMfaType,
    currentMfaType,
    mfaTotpEnabled,
    changeMfaTotp,
    disabled,
}: MfaTotpSettingsProps) => {

    const { t } = useTranslation();

    const [secret, setSecret] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!secret) {
            getMfaTotpSecret(
                (newSecret) => {
                    setSecret(newSecret);
                },
                (e) => {
                    console.error('failed', e);
                }
            );
        }
    }, [secret]);

    const secretLink = useMemo(() => {
        return `otpauth://totp/Dintero?secret=${secret}`;
    }, [secret]);

    return <Formik
        validateOnMount={true}
        initialValues={{
            mfa_totp_enabled: mfaTotpEnabled,
            user_code: '',
        }}
        validate={(values) => {
            const errors: { user_code?: string } = {};

            return Object.values(errors).find((x) => x) ? errors : undefined;
        }}
        onSubmit={(values) => {
            changeMfaTotp(values.mfa_totp_enabled, values.user_code);
        }}
    >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, resetForm }) => (
            <form onSubmit={handleSubmit}>
                <Wrapper>
                    <MfaSettingExpandCollapse>
                        <MfaSettingLabel>
                            <MfaHeading>
                                <MfaHeadingTitleDescription><Totp /></MfaHeadingTitleDescription>
                                <MfaHeadingTitleDescription>
                                    <H3>
                                        {t('change_settings.mfa_totp.title')}
                                        {mfaTotpEnabled && <MfaBadge>{t('change_settings.configured')}</MfaBadge>}
                                    </H3>

                                    <p>{t('change_settings.mfa_totp.description')}</p>
                                </MfaHeadingTitleDescription>
                                <MfaHeadingAction>
                                    {mfaTotpEnabled &&
                                        <Button type="button" className="alt tiny" disabled={disabled || currentMfaType !== undefined} onClick={() => {
                                            setCurrentMfaType('totp');
                                        }}>{t('change_settings.edit')}</Button>}
                                    {!mfaTotpEnabled &&
                                        <Button type="button" className="alt tiny" disabled={disabled || currentMfaType !== undefined} onClick={() => {
                                            setCurrentMfaType('totp');
                                        }}>{t('change_settings.add')}</Button>}
                                </MfaHeadingAction>
                            </MfaHeading>
                        </MfaSettingLabel>
                        <MfaSettingContent isExpanded={currentMfaType === 'totp'}>
                            <Checkbox
                                name="mfa_totp_enabled"
                                label={t('change_settings.mfa_totp_enabled')}
                                checked={values.mfa_totp_enabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            {secret && values.mfa_totp_enabled && <QrCodeWrapper>
                                <QrCodeWrapper><QRCodeSVG value={secretLink} /></QrCodeWrapper>
                                <div>{t('change_settings.mfa_totp.qr_code_description')}</div>
                                <div>{t('change_settings.mfa_totp.secret_code')}: {secret}</div>
                            </QrCodeWrapper>}
                            <Input
                                label={t('change_settings.mfa_totp.user_code_label')}
                                name="user_code"
                                placeholder="XXXXXX"
                                value={values.user_code}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={!values.mfa_totp_enabled}
                                validation={
                                    touched.user_code && errors.user_code
                                        ? {
                                            state: ValidationState.Invalid,
                                            message: errors.user_code,
                                        }
                                        : undefined
                                }
                                required={values.mfa_totp_enabled}
                            />

                            <ErrorMessage
                                visible={['error', 'rate_limit', 'invalid_user_code'].includes(changeMfaTotpState)}
                            >
                                {t(`change_settings.errors.response.${changeMfaTotpState as 'error' | 'rate_limit' | 'invalid_user_code'}`)}
                            </ErrorMessage>
                            <ButtonGroup>
                                <StyledButton type="button" className="alt" onClick={() => {
                                    resetForm();
                                    setCurrentMfaType(undefined);
                                }}>
                                    {t('change_settings.abort')}
                                </StyledButton>
                                <StyledButton type="submit" disabled={!isValid || changeMfaTotpState === 'in-flight'}>
                                    {t('change_settings.submit')}
                                </StyledButton>
                            </ButtonGroup>
                            <Hr />
                        </MfaSettingContent>
                    </MfaSettingExpandCollapse>
                </Wrapper>
            </form>
        )}
    </Formik>;
};

const QrCodeWrapper = styled.div`
    margin-top: ${distances.normal};
    margin-bottom: ${distances.normal};

    div {
        margin-bottom: ${distances.small};
    }
`;


export default MfaTotpSettings;
