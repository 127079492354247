import { Account, PayExConnection } from '../types/management-auth';

type ConnectionPair =  [string, ]

const getConnections = (account: Account, status: 'pending' | 'enabled') => {
    if (!account) {
        return [];
    }
    if (!account.connections) {
        return [];
    }
    const legacyPayexCreditCard: [string, PayExConnection | undefined ] = ['payex', account.connections?.payex?.creditcard];
    return [...Object.entries(account.connections), legacyPayexCreditCard]
        .filter(([key, value]) => (key !== 'common' && value?.status === status));
};

export default getConnections;
