import * as React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import {
    AppBarContainer,
    AppBarIconLink,
    AtTheTop
} from '../AppBar';
import Page from '../Page';
import Card from '../Card';
import { distances } from '../../styles/constants';
import Main from '../Main';
import TopBarPlaceholder from './TopBar';

const PlaceholderApp = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <TopBarPlaceholder />
            <AppBarContainer>
                <AppBarIconLink icon="backoffice" title={t('app_bar.titles.dashboard')} to="/not/a/real/path" />
                <AppBarIconLink icon="orders" title={t('app_bar.titles.orders')} to="/not/a/real/path" />
                <AppBarIconLink icon="payments" title={t('app_bar.titles.payments')} to="/not/a/real/path" />
                <AppBarIconLink icon="reports" title={t('app_bar.titles.reports')} to="/not/a/real/path" />
                <AppBarIconLink icon="customers" title={t('app_bar.titles.customers')} to="/not/a/real/path" />
                <AppBarIconLink icon="campaigns" title={t('app_bar.titles.marketing')} to="/not/a/real/path" />
            </AppBarContainer>
            <Main>
                <Page title="">
                    <Card>
                        <ContentMedium />
                        <ContentLong />
                        <ContentShort />
                    </Card>
                </Page>
            </Main>
        </React.Fragment>
    );
};

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Grow = keyframes`
  100% {
    transform: scaleX(0.7)
  }
`;
const Shrink = keyframes`
  100% {
    transform: scaleX(0.3)
  }
`;

const MenuHeader = styled.div`
    height: 23px;
    background: rgba(0, 0, 0, 0.1);
    width: 220px;
    margin-top: ${distances.tiny};
    margin-bottom: 28px;
    margin-left: ${distances.small};
    border-radius: 2px;
    animation: 3840ms ${Grow} ease-out 200ms infinite both alternate, 200ms ${fadeIn} ease-out;
    transform: scaleX(0.5);
    transform-origin: 0 50%;
`;

const MenuPlaceholderMedium = styled.div`
    height: ${distances.small};
    background: #fff;
    background: rgba(0, 0, 0, 0.1);
    width: 180px;
    margin-bottom: ${distances.small};
    margin-top: ${distances.tiny};
    margin-left: ${distances.small};
    border-radius: 2px;
    animation: 3400ms ${Grow} ease-out 200ms infinite both alternate, 200ms ${fadeIn} ease-out;
    transform: scaleX(0.4);
    transform-origin: 0 50%;
`;
const MenuPlaceholderLong = styled(MenuPlaceholderMedium)`
    width: 190px;
    background: rgba(0, 0, 0, 0.05);
    animation: 3900ms ${Grow} ease-out 200ms infinite both alternate, 200ms ${fadeIn} ease-out;
    transform: scaleX(1);
    transform-origin: 0 50%;
`;
const MenuPlaceholderShort = styled(MenuPlaceholderMedium)`
    width: 170px;
    background: rgba(0, 0, 0, 0.075);
    animation: 20100ms ${Shrink} ease-out 200ms infinite alternate, 200ms ${fadeIn} ease-out;
    transform-origin: 0 50%;
    transform: scaleX(1);
`;

export const ContentMedium = styled.div`
    width: 80%;
    height: ${distances.small};
    margin-bottom: ${distances.small};
    background: rgba(0, 0, 0, 0.035);
    border-radius: 2px;
    animation: 12500ms ${Grow} ease-out 200ms infinite both alternate, 200ms ${fadeIn} ease-out;
    transform-origin: 0 50%;
`;

export const ContentLong = styled(ContentMedium)`
    width: 50%;
    height: 14px;
    background: rgba(0, 0, 0, 0.03);
    animation: 8300ms ${Grow} ease-out 200ms infinite both alternate, 200ms ${fadeIn} ease-out;
    transform-origin: 0 50%;
`;

export const ContentShort = styled(ContentMedium)`
    width: 60%;
    background: rgba(0, 0, 0, 0.04);
    animation: 10900ms ${Shrink} ease-out 200ms infinite alternate, 200ms ${fadeIn} ease-out;
    transform-origin: 0 50%;
`;

export default PlaceholderApp;
