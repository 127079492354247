import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { namespace } from '..';
import { useActions } from '../../../../Actions';
import { getUserId } from '../../../../auth/accessToken/selectors';
import { useAccountIds } from '../../../../auth/accessToken/withAccountIds';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { Select } from '../../../../components/SimpleSelect/Select';
import { formatDate, formatOrgNumber, formatTime } from '../../../../helpers/formatters';
import { ApprovalsPaymentResponse } from '../../../../types/management-auth/generated';
import { getPasswordlessUsernamesKey } from '../../collectorCallback/utils';
import { CollectorPayload } from '../types';
import StoreConfig from './StoreConfig';
import { validate, validateRequired } from '../../../../helpers/validation';
import { determineStoreIdValidators } from './validators';
import { countryMapping } from '../../collectorCallback/components/CollectorCallbackModal';
import MissingApprovalDescription from './MissingApprovalDescription';
import styled from 'styled-components/macro';
import { distances } from '../../../../styles/constants';

const toOption = (approval: ApprovalsPaymentResponse | undefined, language: string) => {
    const country = approval?.bank_accounts?.[0]?.bank_account_country_code || '';
    const currency = approval?.bank_accounts?.[0]?.bank_account_currency;
    const org = approval?.organization_number || '';
    const date = formatDate(approval?.created_at || '', language);
    const time = formatTime(approval?.created_at || '', language);
    const mcc = approval?.mcc || '';
    return {
        label: [currency, date, time, country, formatOrgNumber(country, org).trim(), mcc].filter(x => x).join(', '),
        value: approval?.id || '',
    };
};

type CollectorPayFacFormProps = {
    activeApprovals: ApprovalsPaymentResponse[];
};

export default function CollectorPayFacCustomForm({ activeApprovals }: CollectorPayFacFormProps) {
    const { closeModal, finishRegistration } = useActions(namespace);
    const { prodAccountId } = useAccountIds();
    const { t, i18n } = useTranslation();
    const userId = useSelector(state => getUserId(state));
    const { account } = useSelector(state => ({
        account: state[namespace].account,
    }));
    const initialValues = {
        caseId: activeApprovals[activeApprovals.length - 1]?.id,
        b2b: {
            enabled: false,
            currencies: [
                {
                    currency: 'NOK',
                    enabled: false,
                    store_id: '',
                },
                {
                    currency: 'SEK',
                    enabled: false,
                    store_id: '',
                },
                {
                    currency: 'EUR',
                    enabled: false,
                    store_id: '',
                },
            ],
        },
        b2c: {
            enabled: false,
            currencies: [
                {
                    currency: 'NOK',
                    enabled: false,
                    store_id: '',
                },
                {
                    currency: 'SEK',
                    enabled: false,
                    store_id: '',
                },
                {
                    currency: 'EUR',
                    enabled: false,
                    store_id: '',
                },
            ],
        },
    };
    const onSubmit = async (values: typeof initialValues) => {
        const activeApproal = activeApprovals.find(a => a.id === values.caseId);
        if (!activeApproal) {
            return;
        };
        if (
            !account ||
            !account.company ||
            !account.company.organization_number ||
            !account.company.website ||
            !prodAccountId
        ) {
            return;
        }
        const b2cCurrencies = (values.b2c.enabled && values.b2c.currencies.filter(currency => currency.enabled).map(currency => currency.currency)) || [];
        const b2bCurrencies = (values.b2b.enabled && values.b2b.currencies.filter(currency => currency.enabled).map(currency => currency.currency)) || [];
        const stores: CollectorPayload['body']['stores'] = [];
        if (values.b2c) {
            values.b2c.currencies.filter(currency => currency.enabled).forEach(currency => {
                const country = countryMapping[currency.currency as keyof typeof countryMapping];
                const storeIdB2C = Number.parseInt(currency.store_id);
                stores.push({
                    store_id: storeIdB2C,
                    currency: currency.currency,
                    country,
                    segment: 'B2C',
                });
            });
        }
        if (values.b2b) {
            values.b2b.currencies.filter(currency => currency.enabled).forEach(currency => {
                const country = countryMapping[currency.currency as keyof typeof countryMapping];
                const storeIdB2B = Number.parseInt(currency.store_id);
                stores.push({
                    store_id: storeIdB2B,
                    currency: currency.currency,
                    country,
                    segment: 'B2B',
                });
            });
        }
        const payload: CollectorPayload['body'] = {
            signup: {
                legal_name: account?.company?.business_name || '',
                business_name: account?.company?.business_name || '',
                organization_number: account?.company.organization_number || '',
                bank_name: activeApproal.bank_accounts?.[0]?.bank_name || '',
                account_number: activeApproal.bank_accounts?.[0]?.bank_account_number || '',
                website: account?.company.website || '',
                payment_options: [
                    {
                        currencies: [...new Set([...b2cCurrencies, ...b2bCurrencies])],
                        type: 'collector.invoice',
                    },
                    {
                        currencies: [...new Set([...b2cCurrencies, ...b2bCurrencies])],
                        type: 'collector.installment',
                    },
                ],
            },
            username: getPasswordlessUsernamesKey() === 'test' ? 'dintero_api' : 'dintero_SM',
            stores: stores,
            payment_options: [
                ...((values.b2c.enabled && [{
                    currencies: b2cCurrencies,
                    type: 'collector.invoice' as const,
                }]) || []),
                ...((values.b2b.enabled && [{
                    currencies: b2bCurrencies,
                    type: 'collector.invoice_b2b' as const,
                }]) || []),
                {
                    currencies: [...new Set([...b2cCurrencies, ...b2bCurrencies])],
                    type: 'collector.installment' as const,
                },
            ].filter(option => option.currencies.length > 0),
        };
        await finishRegistration({
            accountId: prodAccountId,
            account: account,
            body: payload,
            userId,
        });
    };
    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validate={values => {
                let errors: { [key: string]: string } = {};
                if (!values) {
                    // this should not happen but...
                    return { error: 'account not loaded yet...' };
                }

                const validators = [
                    {
                        path: 'caseId',
                        validator: validateRequired(t('settings.payment_connections.collector_callback_modal.invalid.caseId.required')),
                    },
                    ...determineStoreIdValidators(values, t),
                ];

                errors = validators.reduce((acc, elem) => {
                    return validate(elem.path, elem.validator, values, acc);
                }, errors);

                return errors;
            }}
        >
            {({ handleSubmit, handleChange, handleBlur, values, setFieldValue, isSubmitting, errors, touched, setFieldTouched }) => (
                <Form onSubmit={handleSubmit}>
                    <Select
                        label={t(
                            'settings.payment_connections.collector_callback_modal.fields.caseId'
                        )}
                        defaultValue={values.caseId}
                        onChange={option => {
                            setFieldValue('caseId', option);
                        }}
                        disabled={activeApprovals.length === 0 || isSubmitting}
                        items={
                            activeApprovals.reverse().map((a) => toOption(a, i18n.language))
                        }
                        required
                        error={errors.caseId}
                    />
                    <StoreConfig values={values} initialValues={initialValues} errors={errors} touched={touched} handleChange={handleChange} handleBlur={handleBlur} setFieldValue={setFieldValue} setFieldTouched={setFieldTouched} />
                    <MissingApprovalDescription activeApprovals={activeApprovals} agreement={t('settings.payment_connections.collector_modal.agreement_payfac-custom')} />
                    <ButtonGroup>
                        <Button className="alt" type="button" onClick={closeModal}>{t(
                            'settings.payment_connections.collector_callback_modal.cancel'
                        )}</Button>
                        <Button disabled={
                            Object.keys(errors).length > 0 ||
                            Object.keys(touched).length === 0
                        } type="submit">{
                                t(
                                    'settings.payment_connections.collector_modal.setup_collector'
                                )
                            }</Button>
                    </ButtonGroup>
                </Form>
            )}
        </Formik>
    );
}

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${distances.small};
    width: 100%;
`;
