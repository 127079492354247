import React from 'react';
import { Route } from 'react-router-dom';
import { Merchants } from './merchants';
import { NewAccount } from './newAccount';
import { MerchantDetails } from './merchantDetails';
import { AllowRoute, WithSubAccountAccessToken } from '../auth/accessToken';
import { WithNotFound } from '../errors';

const PartnerRoutes = () => (
    <WithNotFound>
        <AllowRoute path={`/:accountId/sub-accounts/new`} exact component={NewAccount} anyOfScopes={[['admin:accounts'], ['write:accounts']]} />
        <Route
            path={`/:accountId/sub-accounts/:subAccountId`}
            exact
            render={(props) => (
                <WithSubAccountAccessToken {...props}>
                    <MerchantDetails {...props} />
                </WithSubAccountAccessToken>
            )}
        />
        <Route path={`/:accountId/sub-accounts`} exact component={Merchants} />
    </WithNotFound>
);

export default PartnerRoutes;
