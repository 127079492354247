import { combineReducers } from 'redux';

import { reducer as auth } from './auth';
import { reducer as partner } from './partner';
import {
    ConfigState as PayoutMerchantConfigState,
    reducer as payoutConfig,
    namespace as payoutConfigNamespace
} from './payout/merchant/config/actions';
import { reducer as settings } from './settings';


export interface State
    extends
        auth.State,
        settings.State,
        partner.State
        {
    [payoutConfigNamespace]: PayoutMerchantConfigState;
}

export const initialState: State = {
    ...auth.initialState,
    ...settings.initialState,
    ...partner.initialState,
    [payoutConfigNamespace]: payoutConfig(undefined, { type: '@@INIT' }),
};

export const reducer = combineReducers<State>({
    ...auth.reducers,
    ...settings.reducers,
    ...partner.reducers,
    [payoutConfigNamespace]: payoutConfig,
});

declare module 'react-redux' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultRootState extends State {}
}
