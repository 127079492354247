import { Account } from '../../../types/management-auth';

export enum ActionTypes {
    FetchAccount = '[settings/locations] FETCH_ACCOUNT',
    FetchAccountResponse = '[settings/locations] FETCH_ACCOUNT_RESPONSE',
    SearchCompanyName = '[settings/locations] SEARCH_COMPANY_NAME',
    SearchCompanyNameResponse = '[settings/locations] SEARCH_COMPANY_NAME_RESPONSE',
}

export interface FetchAccount {
    type: ActionTypes.FetchAccount;
    payload: {
        accountId: string;
    };
}

export interface FetchAccountResponse {
    type: ActionTypes.FetchAccountResponse;
    payload: {
        account: Account;
    };
    meta: {
        cause: FetchAccount;
    };
}

export function fetchAccount(accountId: string): FetchAccount {
    return {
        type: ActionTypes.FetchAccount,
        payload: {
            accountId,
        },
    };
}

export interface SearchCompanyName {
    type: ActionTypes.SearchCompanyName;
    payload: {
        accountId: string;
        partialName: string;
    };
}

export type SearchedCompany = {
    organization_number: string;
    business_name: string;
    website: string;
    address: {
        address_line?: string;
        address_line_2?: string;
        postal_code?: string;
        postal_place?: string;
        country?: string;
    };
};

export interface SearchCompanyNameResponse {
    type: ActionTypes.SearchCompanyNameResponse;
    payload: {
        results: SearchedCompany[];
    };
}

export function searchCompanyName(partialName: string, accountId: string): SearchCompanyName {
    return {
        type: ActionTypes.SearchCompanyName,
        payload: {
            partialName,
            accountId,
        },
    };
}

export function searchCompanyNameResponse(results: SearchedCompany[]): SearchCompanyNameResponse {
    return {
        type: ActionTypes.SearchCompanyNameResponse,
        payload: {
            results,
        },
    };
}

export type Actions =
    | FetchAccount
    | FetchAccountResponse
    | SearchCompanyName
    | SearchCompanyNameResponse;
