import type { ApiModel, ConfigBankAccount } from '../../types';
import { createSwitchlessReducer } from '../../../util/switchlessReducer';
import _keyBy from 'lodash/keyBy';

export type ApiConfigBankAccount = ApiModel<ConfigBankAccount>

const transformApiConfigBankAccount = (entity: ApiConfigBankAccount): ConfigBankAccount => ({
    ...entity,
    created_at: new Date(entity.created_at),
    updated_at: new Date(entity.updated_at),
});

export type BankAccountState = {
    bankAccounts: Record<string, ConfigBankAccount>;
}

const initialState: BankAccountState = {
    bankAccounts: {},
};

export const { reducer, actions } = createSwitchlessReducer({
    initialState,
    namespace: 'payout.bankaccount',
    reducers: {
        addBankAccounts: (state, action: { bankAccounts: ApiConfigBankAccount[] }) => ({
            ...state,
            bankAccounts: {
                ...state.bankAccounts,
                ..._keyBy(action.bankAccounts.map(transformApiConfigBankAccount), 'id'),
            },
        }),
    },
});

export const addBankAccounts = actions.addBankAccounts;
