import { createSwitchlessReducer } from '../../util/switchlessReducer';
import type { Dispatch } from 'redux';
import fulfill from '../../fulfill';
import { CORE_API_HOSTNAME } from '../../env';
import {
    SettlementReportConfig,
    SettlementReportConfigDraft,
    SettlementReportConfigsResponse
} from '../../reports/settlementList/types';

export const namespace = 'settings.settlement_reports' as const;

export interface State {
    configurations: { [accountId: string]: SettlementReportConfig[] };
    isLoading: boolean;
    account_id: string;
    configurationToDelete: SettlementReportConfig | undefined;
    showNewConfigurationModal: boolean;
    configurationToEdit: SettlementReportConfig | undefined;
    createError: undefined | 'bad_request' | 'conflict';
    editError: undefined | 'bad_request' | 'conflict';
}

export const initialState: State = {
    configurations: {},
    isLoading: true,
    account_id: '',
    configurationToDelete: undefined,
    showNewConfigurationModal: false,
    createError: undefined,
    editError: undefined,
    configurationToEdit: undefined,
};

type Requested = {};
type ReturnedConfigs = Requested & { account_id: string; configurations: SettlementReportConfigsResponse['items'] };
type UpdatedConfig = Requested & { account_id: string; updatedConfiguration: SettlementReportConfig };
type Removed = { account_id: string; configuration_id: string };
type ToggleDeleteModal = { account_id: string; configuration?: SettlementReportConfig };
type ToggleCreateModal = { showNewConfigurationModal: boolean };
type ToggleEditModal = { account_id: string; configuration?: SettlementReportConfig };
type NewConfiguration = { account_id: string; configuration: SettlementReportConfig };
type NewConfigurationError = { error: State['createError'] };
type EditConfigurationError = { error: State['editError'] };

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        startLoading: (state: State) => {
            return {
                ...initialState,
            };
        },
        addConfigurations: (state: State, { account_id, configurations }: ReturnedConfigs): State => {
            return {
                ...state,
                isLoading: false,
                configurations: {
                    ...state.configurations,
                    [account_id]: configurations || [],
                },
            };
        },
        updatedConfiguration: (state: State, { account_id, updatedConfiguration }: UpdatedConfig): State => {
            const updatedAccountConfigurations = (state.configurations[account_id] || []).map(config => {
                if (config.id === updatedConfiguration.id) {
                    return updatedConfiguration;
                }
                return config;
            });
            return {
                ...state,
                isLoading: false,
                editError: undefined,
                configurationToEdit: undefined,
                configurations: {
                    ...state.configurations,
                    [account_id]: updatedAccountConfigurations,
                },
            };
        },
        resetLoading: (state: State): State => {
            return {
                ...state,
                isLoading: false,
            };
        },
        removeConfiguration: (state: State, { account_id, configuration_id }: Removed): State => {
            return {
                ...state,
                isLoading: false,
                configurations: {
                    ...state.configurations,
                    [account_id]: (state.configurations[account_id] || []).filter((x) => x.id !== configuration_id),
                },
                configurationToDelete: undefined,
            };
        },
        toggleDeleteModal: (state: State, { configuration, account_id }: ToggleDeleteModal): State => {
            return {
                ...state,
                account_id,
                configurationToDelete: configuration,
            };
        },
        toggleNewModal: (state: State, { showNewConfigurationModal }: ToggleCreateModal): State => {
            return {
                ...state,
                showNewConfigurationModal: showNewConfigurationModal,
                createError: undefined,
            };
        },
        toggleEditModal: (state: State, { account_id, configuration }: ToggleEditModal): State => {
            return {
                ...state,
                account_id,
                configurationToEdit: configuration,
                editError: undefined,
            };
        },
        addNewConfiguration: (state: State, { account_id, configuration }: NewConfiguration): State => {
            return {
                ...state,
                showNewConfigurationModal: false,
                createError: undefined,
                configurations: {
                    ...state.configurations,
                    [account_id]: [configuration, ...(state.configurations[account_id] || [])],
                },
            };
        },
        setCreateError: (state: State, { error }: NewConfigurationError): State => {
            return {
                ...state,
                createError: error,
            };
        },
        setEditError: (state: State, { error }: EditConfigurationError): State => {
            return {
                ...state,
                editError: error,
            };
        },
    },
});

export const createConnectedActions = (dispatch: Dispatch) => {
    const fetchConfigurations = async (account_ids: string[]) => {
        dispatch(actions.startLoading());
        await Promise.all(
            account_ids.map((account_id) =>
                fulfill.get({
                    accountId: account_id,
                    url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/settlements/reports/configuration?limit=1000`,
                    handlers: {
                        200: (configurations: SettlementReportConfigsResponse) => {
                            dispatch(actions.addConfigurations({ account_id, configurations: configurations.items }));
                        },
                    },
                }))
        );
        dispatch(actions.resetLoading());
    };
    const openDeleteModal = async (account_id: string, configuration: SettlementReportConfig) => {
        dispatch(actions.toggleDeleteModal({ account_id, configuration }));
    };
    const closeDeleteModal = async () => {
        dispatch(actions.toggleDeleteModal({ account_id: '' }));
    };
    const openNewModal = async () => {
        dispatch(actions.toggleNewModal({ showNewConfigurationModal: true }));
    };
    const closeNewModal = async () => {
        dispatch(actions.toggleNewModal({ showNewConfigurationModal: false }));
    };
    const openEditModal = async (account_id: string, configuration: SettlementReportConfig) => {
        dispatch(actions.toggleEditModal({ account_id, configuration }));
    };
    const closeEditModal = async () => {
        dispatch(actions.toggleEditModal({ account_id: '' }));
    };
    const postNewConfiguration = async (account_id: string, configuration: SettlementReportConfigDraft) => {
        return await fulfill.post({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/settlements/reports/configuration`,
            json: configuration,
            handlers: {
                200: (response) => {
                    dispatch(actions.addNewConfiguration({ account_id, configuration: response }));
                    return true;
                },
                409: () => {
                    dispatch(actions.setCreateError({ error: 'conflict' }));
                    return false;
                },
                400: () => {
                    dispatch(actions.setCreateError({ error: 'bad_request' }));
                    return false;
                },
            },
        });
    };
    const updateConfiguration = async (account_id: string, configuration_id: string, configuration: SettlementReportConfigDraft) => {
        return await fulfill.put({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/settlements/reports/configuration/${configuration_id}`,
            json: configuration,
            handlers: {
                200: (response) => {
                    dispatch(actions.updatedConfiguration({ account_id, updatedConfiguration: response }));
                    return true;
                },
                409: () => {
                    dispatch(actions.setEditError({ error: 'conflict' }));
                    return false;
                },
                400: () => {
                    dispatch(actions.setEditError({ error: 'bad_request' }));
                    return false;
                },
            },
        });
    };
    const removeConfiguration = async (account_id: string, configuration_id: string) => {
        fulfill.delete({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/settlements/reports/configuration/${configuration_id}`,
            handlers: {
                200: () => {
                    dispatch(actions.removeConfiguration({ account_id, configuration_id }));
                },
            },
        });
    };
    return {
        fetchConfigurations,
        openDeleteModal,
        closeDeleteModal,
        openNewModal,
        closeNewModal,
        postNewConfiguration,
        removeConfiguration,
        openEditModal,
        closeEditModal,
        updateConfiguration,
    };
};
