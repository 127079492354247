import { Formik } from 'formik';
import { Trans, WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components/macro';
import { Allow } from '../../../auth/accessToken';
import { Button, ButtonGroup } from '../../../components/Buttons';
import Card from '../../../components/Card';
import { Dd, Dl, Dt } from '../../../components/DescriptionList/Vertical';
import { Dropdown, Email, Input, ValidationState } from '../../../components/Forms';
import Hr from '../../../components/Hr';
import Modal from '../../../components/Modal';
import { H2, H3, P } from '../../../components/Typography';
import { validate, validateEmail, validateMinLength } from '../../../helpers/validation';
import { State } from '../../../reducer';
import { distances, grid } from '../../../styles/constants';
import { changeEditState, editMerchant } from '../actions';
import * as selectors from '../selectors';
import { Editable, PartnerAccount } from '../types';
import CardArea from './CardArea';
import { useIsAllowed, useScopes } from '../../../auth/accessToken/Allow';

const SALE_CHANNEL_OPTIONS = [
    'INBOUND',
    'DIRECT',
    'PARTNER',
    'REFERRAL',
] as const;

export interface AccountConfigurationProps extends WithTranslation {
    partnerId: string;
    account: PartnerAccount;
    changeEditState: (editing: Editable) => void;
    editMerchant: (partnerId: string, account: PartnerAccount) => void;
    editing: Editable;
}

const mapStateToProps = (state: State) => ({
    editing: selectors.getEditingSelector(state),
});

const mapDispatchToProps = {
    changeEditState,
    editMerchant,
};

const SaleChannelDropdown = (props: {
    name: string;
    value?: string;
    onChange: (value: string) => void;
}) => {
    const { name, onChange } = props;
    const { t } = useTranslation('app');
    const options = SALE_CHANNEL_OPTIONS.map((option) => ({
        value: option,
        label: t(`partner.merchant.fields.sale_channel.options.option`, {
            context: option,
        }),
    }));
    const value = options.find((option) => option.value === props.value);
    return (
        <Dropdown
            name={name}
            label={t('partner.merchant.fields.sale_channel.label')}
            options={options}
            value={value ?? options[0]}
            onChange={(e) => {
                onChange(e.value);
            }}
        />
    );
};

const EditAccountConfigurationModal = (
    {
        t,
        partnerId,
        account,
        editMerchant,
        changeEditState,
    }: Omit<AccountConfigurationProps, 'editing'>
) => {
    const isDinteroAdmin = useIsAllowed([['admin:dintero']]);
    return (
        <Modal>
            <Formik
                isInitialValid={false}
                initialValues={{
                    email: account.account_manager?.email || '',
                    sale_channel: account.sale_channel,
                    price_package: account.price_package || '',
                }}
                validate={(values) =>
                    [validateMinLength(1, ' '), validateEmail(' ')].reduce((acc, validator) =>
                        validate('email', validator, values, acc))
                }
                onSubmit={(values) => {
                    editMerchant(partnerId, {
                        ...account,
                        account_manager: { email: values.email },
                        price_package: values.price_package,
                        ...(values.sale_channel && {
                            sale_channel: values.sale_channel,
                        }),
                    });
                }}
            >
                {({ values, errors, handleBlur, handleSubmit, setFieldValue, isValid }) => {
                    return (
                        <form onSubmit={handleSubmit} autoComplete="nope">
                            <H2>{t('partner.merchant.edit_account_configuration.title')}</H2>
                            <P>
                                <Trans
                                    i18nKey="partner.merchant.edit_account_configuration.subtitle"
                                    values={{ account: account.account_id || '' }}
                                >
                                    <em />
                                </Trans>
                            </P>
                            <Hr />
                            <Wrapper>
                                <Email
                                    label={t('partner.merchant.fields.email')}
                                    placeholder={'new.user@dintero.com'}
                                    name="email"
                                    value={values.email}
                                    setFieldValue={setFieldValue}
                                    onBlur={handleBlur}
                                    validation={{
                                        state: errors.email ? ValidationState.Invalid : ValidationState.Pristine,
                                        message: errors.email,
                                    }}
                                />

                                <SaleChannelDropdown
                                    name="sale_channel"
                                    value={values.sale_channel}
                                    onChange={(value) => value && setFieldValue('sale_channel', value)}
                                />

                                <Input
                                    name="price_package"
                                    label={t('partner.merchant.fields.price_package')}
                                    value={values.price_package}
                                    onChange={(e) => setFieldValue('price_package', e.currentTarget.value)}
                                    onBlur={handleBlur}
                                    validation={{
                                        state: errors.price_package ? ValidationState.Invalid : ValidationState.Pristine,
                                        message: errors.price_package,
                                    }}
                                    placeholder=""
                                    disabled={!isDinteroAdmin}
                                />

                            </Wrapper>
                            <ButtonGroup>
                                <Button className="tiny-768" type="submit" disabled={!isValid}>
                                    {t('partner.merchant.form.save')}
                                </Button>
                                <Button className="tiny-768 alt" onClick={() => changeEditState(undefined)}>
                                    {t('partner.merchant.form.cancel')}
                                </Button>
                            </ButtonGroup>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

const AccountConfiguration = (
    {
        t,
        partnerId,
        account,
        editing,
        editMerchant,
        changeEditState,
        i18n,
        tReady,
    }: AccountConfigurationProps
) => {
    return (
        <Card marginBottom={distances.normal}>
            <FlexHorizontal>
                <H3>{t('partner.merchant.account_configuration')}</H3>

                <Allow anyOfScopes={[['admin:dintero']]}>
                    <Button className="tiny-768 alt" onClick={() => changeEditState('account_manager')}>
                        {t('partner.merchant.form.edit')}
                    </Button>
                </Allow>
            </FlexHorizontal>
            <Hr />
            <CardArea>
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.account_manager_email')}</Dt>
                    <Dd>{account.account_manager?.email || t('partner.merchant.no_account_manager')}</Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.sale_channel')}</Dt>
                    <Dd>
                        {t(`partner.merchant.fields.sale_channel.options.option`, {
                            context: account.sale_channel,
                        })}
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.price_package')}</Dt>
                    <Dd>{account.price_package || t('partner.merchant.no_price_package')}</Dd>
                </Dl>
            </CardArea>
            {editing === 'account_manager' && (
                <EditAccountConfigurationModal
                    partnerId={partnerId}
                    account={account}
                    editMerchant={editMerchant}
                    changeEditState={changeEditState}
                    t={t}
                    i18n={i18n}
                    tReady={tReady}
                />
            )}
        </Card>
    );
};

const Wrapper = styled.div`
    width: ${grid.spans.span6};
    margin-bottom: ${distances.small};
    font-size: 14px;

    @media (max-width: 768px) {
        width: 100%;
        height: 300px;
    }

    @media (max-width: 350px) {
        width: 100%;
        height: 250px;
    }
`;



const FlexHorizontal = styled.div`
    display: flex;
    justify-content: space-between;
`;

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AccountConfiguration));
