import { useEffect, useMemo } from 'react';
import { useActions } from '../Actions';
import { PayoutDestinationWithBankAccounts } from '../settings/payoutDestinations/PayoutDestinationsList';
import { BillingPayoutRule, BillingPayoutRuleDestination } from '../types/billing';
import { useSelector } from 'react-redux';
import { billingSelector } from '../settings/billingActions';
import { pdSelector } from '../payout/merchant/config/selectors';
import { useAccountId } from '../auth/useAccountId';

const getDestinations = (destination: BillingPayoutRuleDestination): string[] => {
    if (destination.destination) {
        return [destination.destination];
    }
    if (destination.destinations) {
        return destination.destinations.flatMap((d) => getDestinations(d as BillingPayoutRuleDestination));
    }
    return [];
};

const getBillingRulePayoutDestinations = (rules: BillingPayoutRule[]) => {
    return rules.flatMap((rule) => rule.destinations.flatMap(getDestinations));
};

const arrayUniqueByKey = (arr: any[], key: string) => [
    ...new Map(arr.map(item =>
        [item[key], item])).values(),
];


const payoutDestinationOptions = (
    account: string,
    payoutDestinationsWithBankAccounts: PayoutDestinationWithBankAccounts[],
    payoutRules: BillingPayoutRule[]
): PayoutDestinationOption[] => {
    const billingRuleDestinations = getBillingRulePayoutDestinations(payoutRules || [])
        .filter((x) => x)
        .sort()
        .map((value) => ({
            accountId: account || '',
            value,
            label: value,
            type: 'payout-rule-destination',
        }));
    const payoutDestinations = payoutDestinationsWithBankAccounts
        .filter(payoutDestination => payoutDestination.payout_destination_id)
        .map((payoutDestination) => ({
            value: payoutDestination.payout_destination_id,
            label: payoutDestination.reference || payoutDestination.payout_destination_id,
            accountId: account || '',
            type: 'payout-destination',
        }));
    const destinations = [
        ...payoutDestinations,
        ...billingRuleDestinations,
    ].sort((a, b) => a.label.localeCompare(b.label));

    return [
        ...arrayUniqueByKey(destinations, 'value'),
    ];
};

export type PayoutDestinationOption = {
    accountId: string;
    value: string;
    label: string;
    type: 'payout-destination' | 'payout-rule-destination';

};

const toDestinationIdLabelMap = (options: PayoutDestinationOption[], accountId: string | undefined) => options.filter(option => option.accountId === accountId).reduce((acc, item) => {
    return {
        ...acc,
        [item.value]: item.label,
    };
}, {});

export type DestinationIdLabelMap = { [destinationId: string]: string };
export default function usePayoutDestinations(): {
    destinationOptions: PayoutDestinationOption[]; destinationIdLabelMap: DestinationIdLabelMap;
    } {
    const { getBankAccountPayoutDestinations, getPayoutDestinations, getBankAccounts } = useActions('payout.config');
    const { fetchPayoutRules } = useActions('settings.billing');
    const accountId = useAccountId();

    useEffect(
        () => {
            fetchPayoutRules(accountId);
            getPayoutDestinations(accountId);
            getBankAccounts(accountId);
            getBankAccountPayoutDestinations(accountId);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [accountId]
    );
    const { payoutDestinations } = useSelector(pdSelector(accountId || ''));
    const { payoutRules } = useSelector(billingSelector);

    const { destinationOptions, destinationIdLabelMap } = useMemo(() => {
        const options = payoutDestinationOptions(
            accountId || '',
            payoutDestinations,
            payoutRules
        );
        return { destinationOptions: options, destinationIdLabelMap: toDestinationIdLabelMap(options, accountId) };
    }, [accountId, payoutDestinations, payoutRules]);
    return { destinationOptions, destinationIdLabelMap };
}
