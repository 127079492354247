import { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Button, ButtonGroup } from '../../../../components/Buttons';
import Modal from '../../../../components/Modal';
import { H1, P } from '../../../../components/Typography';
import { distances } from '../../../../styles/constants';
import { Client } from '../../../../types/management-auth';
import { Warning } from './Warning';

export interface DeleteClientModalProps extends WithTranslation {
    accountId: string;
    client: Client;
    dismissDeleteClientModal: () => void;
    deleteClient: (accountId: string, clientId: string) => void;
}

const DeleteClientModal = (
    {
        t,
        client,
        accountId,
        deleteClient,
        dismissDeleteClientModal,
    }: DeleteClientModalProps
) => {
    const [rootAccountConfirm, setRootAccountConfirm] = useState(false);
    const isRootAccount = accountId === 'P00000000';
    return (
        <Modal>
            <H1>{t('settings.delete_client.title', { name: client.name })}</H1>
            {isRootAccount &&
                <Warning flash={rootAccountConfirm} />
            }
            <TextWrapper>
                <P>{t('settings.delete_client.description')}</P>
            </TextWrapper>
            {
                (isRootAccount && !rootAccountConfirm) &&
                <Button
                    onClick={() => {
                        setRootAccountConfirm(true);
                    }}
                >
                    Yes I confirm that I want to delete the Dintero root account API key
                </Button>
            }
            <ButtonGroup>
                <Button className="alt" onClick={dismissDeleteClientModal}>
                    {t('settings.delete_client.go_back')}
                </Button>
                {(!isRootAccount || rootAccountConfirm) && <Button
                    onClick={() => {
                        deleteClient(accountId, client.id || '');
                    }}
                >
                    {t('settings.delete_client.delete_client_call_to_action')}
                </Button>}
            </ButtonGroup>
        </Modal>
    );
};

export default withTranslation()(DeleteClientModal);
const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
