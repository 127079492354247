import React from 'react';

const Excel = () => (
    <svg
        height="24"
        width="16"
        viewBox="0 0 12 16"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m8 0 4 4v11c0 .5522847-.4477153 1-1 1h-10c-.55228475 0-1-.4477153-1-1v-14c0-.55228475.44771525-1 1-1zm-.99 1h-6.01v14h10v-10.003l-2.96294504-.00926954c-.54715305-.00169402-.9913515-.44281176-.99685339-.98993977zm2.99 10v1h-3v-1zm-4-4v5h-4v-5zm-2.50927644 1h-.92393321l.92949907 1.46845124-.99628942 1.53154876h.82931354l.62894249-1.00956023.6122449 1.00956023h.92949907l-1.02411874-1.59464627.92949907-1.40535373h-.8348794l-.53432282.90057361zm6.50927644 1v1h-3v-1zm0-2v1h-3v-1zm-1.986-5.57.02615101 2.55773525 2.54084899.00726475z"
            fill="#0552e0"
        />
    </svg>
);

export default Excel;
