import React from 'react';
import _debounce from 'lodash/debounce';

/**
 * Returns a debounced version of the function.
 *
 * The return value is stable as long as wait is the same.
 * I.e. you may change the input function all you like and still get the same debounced function back.
 * The function called at the end of the debounce is always the most recent input function.
 */
export const useDebounced = <T extends (...args: any[]) => any>(func: T, wait: number) => {
    const callbackRef = React.useRef<T>(func);

    React.useEffect(() => {
        callbackRef.current = func;
    }, [func]);

    return React.useMemo(() => {
        const funcToDebounce = ((...args: any[]) => callbackRef.current(...args)) as T;
        return _debounce(funcToDebounce, wait);
    }, [wait]);
};
