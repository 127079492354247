import { ENVIRONMENT, Environment } from '../../../env';

export type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};

const getAccountPartner = (accountId: string) => {
    if (accountId.startsWith('P') || accountId.startsWith('T')) {
        return accountId.substr(1, 3);
    }

    return accountId.substr(0, 3);
};

const belongsToPartner = (accountId: string, partners: string[]) => {
    return partners.some((partner) => {
        return getAccountPartner(accountId) === partner;
    });
};

export type PaymentProductType =
    | 'bambora.creditcard'
    | 'bambora.vipps'
    | 'bambora.mobilepay'
    | 'payex.creditcard'
    | 'payex.vipps'
    | 'payex.mobilepay'
    | 'payex.swish'
    | 'collector.installment'
    | 'collector.invoice'
    | 'collector.invoice_b2b'
    | 'vipps'
    | 'swish.swish'
    | 'instabank.invoice'
    | 'instabank.finance'
    | 'instabank.installment'
    | 'instabank.postponement';

type PayexCategory = 'kyc' | 'dintero1' | 'dintero2' | 'dintero3' | 'dintero4';

const payexLinks: { [category in PayexCategory]: { [country: string]: string } } = {
    kyc: {
        NO: 'https://signup.swedbankpay.com/no/kundekontroll/ny-kunde/firmaoppslag',
        SE: 'https://signup.swedbankpay.com/se/kundk%C3%A4nnedom/befintlig-kund/f%C3%B6retagss%C3%B6kning',
    },
    dintero1: {
        NO: 'https://signup.swedbankpay.com/no/payment-instrument/dintero-1',
        SE: 'https://signup.swedbankpay.com/se/payment-instrument/dintero-5/f%C3%B6retagss%C3%B6kning',
    },
    dintero2: {
        NO: 'https://signup.swedbankpay.com/no/payment-instrument/dintero-2',
        SE: 'https://signup.swedbankpay.com/se/payment-instrument/dintero-6/f%C3%B6retagss%C3%B6kning',
    },
    dintero3: {
        // Default price
        NO: 'https://signup.swedbankpay.com/no/payment-instrument/dintero-3',
        SE: 'https://signup.swedbankpay.com/se/payment-instrument/dintero-7/f%C3%B6retagss%C3%B6kning',
    },
    dintero4: {
        NO: 'https://signup.swedbankpay.com/no/payment-instrument/dintero-4',
        SE: 'https://signup.swedbankpay.com/se/payment-instrument/dintero-8/f%C3%B6retagss%C3%B6kning',
    },
};

// Used to get correct link in UX for legacy payex connections
export const legacyPayexDefaults: { [country: string]: string } = {
    NO: payexLinks.dintero3.NO,
    SE: payexLinks.dintero3.SE,
};

type PartnerConfigurationPayment = {
    requires?: PaymentProductType[];
    replaces?: PaymentProductType[];
    link?: string;
};

type PartnerConfigurationCountry = 'NO' | 'SE';

type PartnerConfigurationUi = 'common' | 'separate';

export type PartnerCountryConfiguration = {
    name?: string;
    ui: PartnerConfigurationUi;
    payment_product_types: PartialRecord<PaymentProductType, PartnerConfigurationPayment>;
};

export type PartnerConfiguration = PartialRecord<PartnerConfigurationCountry, PartnerCountryConfiguration>;

export const defaultPartnerConfiguration: PartnerConfiguration = {
    NO: {
        name: 'default NO',
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero3.NO,
            },
            vipps: {},
            'collector.installment': {},
            'collector.invoice': {},
            'swish.swish': {},
        },
    },
    SE: {
        name: 'default SE',
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero2.SE,
            },
            'swish.swish': {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};


export const extendaConfiguration: PartnerConfiguration = {
    NO: {
        name: 'Extenda NO',
        ui: 'separate',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero3.NO,
            },
            vipps: {},
            'instabank.invoice': {},
            'collector.installment': {},
            'collector.invoice': {},
            'swish.swish': {},
        },
    },
    SE: {
        name: 'Extenda SE',
        ui: 'separate',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero2.SE,
            },
            'swish.swish': {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};

// Uses Bambora and common signup
const tidbitConfig: PartnerConfiguration = {
    NO: {
        name: 'tidbit NO',
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero3.NO,
            },
            vipps: {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
    SE: {
        name: 'tidbit SE',
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero2.SE,
            },
            'swish.swish': {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};

// Replace collector with Instabank
const instabankConfig: PartnerConfiguration = {
    NO: {
        name: 'instabank',
        ui: 'common',
        payment_product_types: {
            'instabank.invoice': {},
            'instabank.installment': {},
            'instabank.finance': {},
            'instabank.postponement': {},
            'bambora.creditcard': {},
            vipps: {},
        },
    },
};

// Same as default but use payex KYC link
const payexKycConfig: PartnerConfiguration = {
    NO: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.kyc.NO,
            },
            vipps: {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
    SE: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.kyc.SE,
            },
            'swish.swish': {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};

// Same as default but use payex KYC link and vipps via payex
const payexKycVippsConfig: PartnerConfiguration = {
    NO: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.kyc.NO,
            },
            'payex.vipps': {
                link: payexLinks.kyc.NO,
            },
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
    SE: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.kyc.SE,
            },
            'swish.swish': {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};

const bamboraVippsPartnerConfig: PartnerConfiguration = {
    NO: {
        name: 'default NO',
        ui: 'common',
        payment_product_types: {
            'bambora.creditcard': {},
            vipps: {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
    SE: {
        name: 'default SE',
        ui: 'common',
        payment_product_types: {
            'bambora.creditcard': {},
            'swish.swish': {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};

// Same as default but use payex Dintero1 link for correct prices
const payexDintero1Config: PartnerConfiguration = {
    NO: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero1.NO,
            },
            vipps: {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
    SE: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero1.SE,
            },
            'swish.swish': {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};
// Same as default but use payex Dintero2 link for correct prices
const payexDintero2Config: PartnerConfiguration = {
    NO: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero2.NO,
            },
            vipps: {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
    SE: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero2.SE,
            },
            'swish.swish': {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};
// Same as default but use payex Dintero3 link for correct prices
const payexDintero3Config: PartnerConfiguration = {
    NO: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero3.NO,
            },
            vipps: {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
    SE: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero3.SE,
            },
            'swish.swish': {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};

// Same as default but use payex Dintero4 link for correct prices
const payexDintero4Config: PartnerConfiguration = {
    NO: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero4.NO,
            },
            vipps: {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
    SE: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero4.SE,
            },
            'swish.swish': {},
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};

// Same as default but use payex for Vipps and Swish
const payexDefaultWithPayexVippsSwishConfig: PartnerConfiguration = {
    NO: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero3.NO,
            },
            'payex.vipps': {
                link: payexLinks.dintero3.NO,
            },
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
    SE: {
        ui: 'common',
        payment_product_types: {
            'payex.creditcard': {
                link: payexLinks.dintero2.SE,
            },
            'payex.swish': {
                link: payexLinks.dintero3.SE,
            },
            'collector.installment': {},
            'collector.invoice': {},
        },
    },
};

export const partnerConfigurations: {
    [env in Environment]: { partnerConfiguration: PartnerConfiguration; partnerIds: string[] }[];
} = {
    '': [],
    dev: [
        {
            partnerConfiguration: tidbitConfig,
            partnerIds: ['113', '114'],
        },
    ],
    test: [
        {
            partnerConfiguration: tidbitConfig,
            partnerIds: ['113', '114'],
        },
    ],
    prod: [
        {
            partnerConfiguration: tidbitConfig,
            partnerIds: ['112', '146'],
        },
        {
            partnerConfiguration: instabankConfig,
            partnerIds: ['114'],
        },
        {
            partnerConfiguration: bamboraVippsPartnerConfig,
            partnerIds: [],
        },
        {
            partnerConfiguration: payexKycConfig,
            partnerIds: ['116', '126', '131', '150', '152'],
        },
        {
            partnerConfiguration: payexKycVippsConfig,
            partnerIds: ['119'],
        },
        {
            partnerConfiguration: payexDintero1Config,
            partnerIds: ['149'],
        },
        {
            partnerConfiguration: payexDintero2Config,
            partnerIds: ['127', '130', '137', '138', '139', '144', '153', '159', '160'],
        },
        {
            partnerConfiguration: payexDintero3Config,
            partnerIds: ['164'],
        },
        {
            partnerConfiguration: payexDintero4Config,
            partnerIds: ['129'],
        },
        {
            partnerConfiguration: extendaConfiguration,
            partnerIds: ['121'],
        },
        {
            partnerConfiguration: defaultPartnerConfiguration,
            partnerIds: [
                '100',
                '111',
                '113',
                '117',
                '118',
                '120',
                '122',
                '123',
                '124',
                '125',
                '128',
                '132',
                '133',
                '134',
                '135',
                '136',
                '140',
                '141',
                '142',
                '143',
                '145',
                '147',
                '148',
                '151',
                '154',
                '155',
                '166',
                '167',
            ],
        },
        {
            partnerConfiguration: payexDefaultWithPayexVippsSwishConfig,
            partnerIds: ['168', '170'],
        },
    ],
};

export const getPartnerConfiguration = (
    accountId: string,
    country: string | undefined,
    environmentOverride?: Environment
): PartnerCountryConfiguration | undefined => {
    const envConfigs = partnerConfigurations[environmentOverride || ENVIRONMENT] || [];
    const partnerConfiguration =
        envConfigs.find((group) => belongsToPartner(accountId, group.partnerIds))?.partnerConfiguration ||
        defaultPartnerConfiguration;
    return partnerConfiguration[country as PartnerConfigurationCountry];
};
