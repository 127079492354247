import { effects as userDetailsEffects } from './userDetails';
import { effects as newUserEffects } from './newUser';
import { effects as editUserEffects } from './editUser';

const effects = {
    ...userDetailsEffects,
    ...newUserEffects,
    ...editUserEffects,
};

export default effects;
