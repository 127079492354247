import React from 'react';

interface TimelineProps {
    strokewidth?: string | number;
    width?: string;
    height?: string;
    fill?: string;
}

const Timeline = (
    {
        strokewidth = 1,
        width = '100%',
        height = '100%',
        fill = '#0000ff',
    }: TimelineProps
) => {
    return (
        <svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g
                fill={fill}
                stroke={fill}
                strokeLinecap="square"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeWidth={strokewidth}
            >
                <circle cx="16" cy="16" fill="none" r="4" stroke={fill} strokeLinecap="butt" />
                <circle cx="34" cy="57" fill="none" r="4" stroke={fill} strokeLinecap="butt" />
                <circle cx="52" cy="16" fill="none" r="4" stroke={fill} />
                <circle cx="56" cy="57" fill="none" r="4" />
                <polyline fill="none" points="25 33 25 48 31.172 54.172" stroke={fill} strokeLinecap="butt" />
                <polyline fill="none" points="56 53 56 48 45 37 42 37 42 34 37.064 29.064" />
                <path
                    d="M42,45H37l-3-3V39H31L20.268,28.268a13.04,13.04,0,1,1,8-8L32,24H44l5.172-5.172"
                    fill="none"
                    stroke={fill}
                />
            </g>
        </svg>
    );
};

export default Timeline;
