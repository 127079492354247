import styled from 'styled-components/macro';

import { colors, distances, border } from '../../styles/constants';

const Th = styled.th`
    margin: 0;
    padding: ${distances.small} 0;
    color: ${colors.textSecondary};
    font-size: 14px;
    font-weight: 400;
    border-bottom: ${border.normal} solid ${colors.borderLight};
    text-align: left;
    white-space: nowrap;
    padding-right: ${distances.small};

    &:last-of-type {
        padding-right: 0;
    }

    &.first-child {
        padding-left: ${distances.normal};
    }

    &.last-child {
        padding-right: ${distances.normal};
    }

    &.align-right {
        text-align: right;
    }

    &.align-center {
        text-align: center;
    }

    &.collapse-768 {
        @media (max-width: 768px) {
            display: none;
        }
    }

    &.collapse-650 {
        @media (max-width: 650px) {
            display: none;
        }
    }
`;

export default Th;
