import * as React from 'react';
import styled from 'styled-components/macro';

import { distances } from '../../styles/constants';

import ItemCounter from './ItemCounter';
import PageLinks from './PageLinks';

interface PagingProps {
    currentPage: number;
    currentPageItemsCount: number;
    itemsPerPage: number;
    totalCount?: number;
    entityStringKey?: string;
    nextLocation?: string;
    prevLocation?: string;
}

const Paging = (props: PagingProps) => {
    return (
        <Wrapper>
            <ItemCounter {...props} />
            <PageLinks {...props} />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: ${distances.normal};
    font-size: 14px;
`;
export default Paging;
