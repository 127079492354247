export const isRequired = (value: any) => {
    if (Array.isArray(value)) {
        return value.length > 0;
    }
    if (typeof value === 'string') {
        return value.trim() ? true : false;
    }
    return value ? true : false;
};

export const validateRequired = (message: string) => {
    return (value: any) => {
        const isValid = isRequired(value);
        if (!isValid) {
            return message;
        }
    };
};
