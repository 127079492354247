import { AccountAdminUser, AccountUser, Account } from '../../../types/management-auth';

export enum ActionTypes {
    ResetForm = '[settings/users] RESET_FORM',
    CreateUser = '[settings/users] CREATE_USER',
    CreateUserResponse = '[settings/users] FETCH_POST_USER_RESPONSE',
    GetAccount = '[settings/users] GET_ACCOUNT',
    FetchAccountResponse = '[settings/users] FETCH_ACCOUNT_RESPONSE',
}

export interface CreateUser {
    type: ActionTypes.CreateUser;
    payload: {
        accountId: string;
        newUser: AccountUser;
    };
}

export interface ResetForm {
    type: ActionTypes.ResetForm;
    payload: {};
}

export function resetForm(): ResetForm {
    return {
        type: ActionTypes.ResetForm,
        payload: {},
    };
}

export interface FetchCreateUserResponse {
    type: ActionTypes.CreateUserResponse;
    payload: {
        user: AccountAdminUser;
    };
    meta: {
        cause: CreateUser;
    };
}

export function createUser(accountId: string, newUser: AccountUser): CreateUser {
    return {
        type: ActionTypes.CreateUser,
        payload: {
            accountId,
            newUser,
        },
    };
}

export interface GetAccount {
    type: ActionTypes.GetAccount;
    payload: {
        accountId: string;
    };
}

export function getAccount(accountId: string): GetAccount {
    return {
        type: ActionTypes.GetAccount,
        payload: {
            accountId,
        },
    };
}

export interface FetchAccountResponse {
    type: ActionTypes.FetchAccountResponse;
    payload: {
        account: Account;
    };
    meta: {
        cause: GetAccount;
    };
}

export type Actions =
    | CreateUser
    | FetchCreateUserResponse
    | ResetForm
    | GetAccount
    | FetchAccountResponse;
