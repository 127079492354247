import { Account } from '../../../types/management-auth';

type Product = {
    type: 'connection' | 'payment_option';
    key: string;
    status: string;
}

type ConnectionStatus = 'pending' | 'enabled' | 'failed' | 'declined';
type SimplifiedConnection = {
    status: ConnectionStatus;
    signup: {
        payment_options: {
            type: string;
        }[];
    };
    configuration: {
        payment_options: {
            type: string;
        }[];
    };
};

type SimplifiedPayexConnection = SimplifiedConnection & {
    creditcard: SimplifiedConnection;
    swish: SimplifiedConnection;
};

export const asConnections = (account: Account): Product[] => {
    const data: { [key: string]: any } = account.connections || {};
    return Object.keys(data)
        .filter((key) => key !== 'common')
        .flatMap((key) => connectionAsPaymentOptions(key, data[key]));
};

const connectionToProduct = (key: string, rawConnection: any): Product => {
    const connection: SimplifiedConnection = key === 'payex' ? mostSignificantPayexConnection(rawConnection) : rawConnection;
    return {
        key,
        status: connection.status,
        type: 'connection',
    };
};

const connectionAsPaymentOptions = (key: string, rawConnection: any): Product[] => {
    const connection: SimplifiedConnection = key === 'payex' ? mostSignificantPayexConnection(rawConnection) : rawConnection;
    const paymentOptions = connection.configuration?.payment_options || connection.signup?.payment_options;
    if (!paymentOptions || paymentOptions.length === 0) {
        return [connectionToProduct(key, rawConnection)];
    }
    return paymentOptions.map((option: any) => ({
        key: option.type,
        status: connection.status,
        type: 'payment_option',
    }));
};

const mostSignificantPayexConnection = (connection: SimplifiedPayexConnection): SimplifiedConnection => {
    // In the future, the payex-object will only have one level, and we should migrate to that at some point
    // For now, only display creditcard, unless it's not defined AND there is a swish connection (highly unlikely).
    if (connection.status) {
        return connection;
    }
    const sortedKeys = Object.keys(connection)
        .sort((keyA, keyB) => {
            if (keyA === 'creditcard') {
                return -1;
            } else if (keyB === 'creditcard') {
                return 1;
            }
            return 0;
        });
    return connection[sortedKeys[0] as 'creditcard' | 'swish'];
};
