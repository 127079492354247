import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Page from '../../../components/Page';
import { H1, H2 } from '../../../components/Typography';
import { ACCOUNTING_INTEGRATIONS, ECOMMERCE_INTEGRATIONS, POS_INTEGRATIONS } from '../constants';
import ApplicationEntity from './ApplicationEntity';

const ThirdPartyPage = () => {
    const { t } = useTranslation('app');
    return (
        <Page
            title={
                <>
                    <Title>{t('settings.third_party.title')}</Title>
                </>
            }
        >
            <Category>{t('settings.third_party.accounting')}</Category>
            <Grid>
                {ACCOUNTING_INTEGRATIONS.map((data, i) => (
                    <ApplicationEntity key={data.id} {...data} />
                ))}
            </Grid>
            <Category>{t('settings.third_party.ecommerce')}</Category>
            <Grid>
                {ECOMMERCE_INTEGRATIONS.map((data, i) => (
                    <ApplicationEntity key={data.id} {...data} />
                ))}
            </Grid>
            <Category>{t('settings.third_party.pos')}</Category>
            <Grid>
                {POS_INTEGRATIONS.map((data, i) => (
                    <ApplicationEntity key={data.id} {...data} />
                ))}
            </Grid>
        </Page>
    );
};

const Title = styled(H1)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 16px;
    transition: all 0.2s ease-in-out;
    margin: 0 auto;
    width: 100%;
`;

const Category = styled(H2)`
    margin: 16px auto;
    width: 100%;

    &:first-of-type {
        margin-top: 0;
    }
`;

export default ThirdPartyPage;
