import {  useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';
import styled from 'styled-components/macro';

import NoData from './NoData';
import { Button } from '../../../../components/Buttons';
import EmptyState from '../../../../components/EmptyState';
import { P } from '../../../../components/Typography';
import { SalesLocation } from '../../../../types/customers';
import { useUrlSearchState } from '../../../../util/useUrlSearchState';

export interface ListMessageProps {
    locations: SalesLocation[];
    isLoading: boolean;
}

const getEmptyFilterStringPart = (t: TFunction, urlsearch: Record<string, any>) => {
    const entries = Object.entries(urlsearch).filter(([key]) => !['starting_after'].includes(key));
    return entries.map(([, value]) => value).join(', ');
};

const ListMessage = (
    {
        locations,
        isLoading,
    }: ListMessageProps
) => {
    const { t } = useTranslation('app');
    const [urlSearch, setUrlSearch] = useUrlSearchState();

    const locationValues = useMemo(() => getEmptyFilterStringPart(t, urlSearch), [t, urlSearch]);
    if (isLoading) {
        return null;
    }
    if (!isLoading && locations.length === 0 && !locationValues) {
        return (
            <RelativeGroup>
                <NoData />
            </RelativeGroup>
        );
    }

    if (locations.length === 0 && locationValues) {
        return (
            <RelativeGroup>
                <EmptyState>
                    <P>
                        <Trans i18nKey="locations.empty_filtered_list.message" values={{ locationValues }}>
                            Sorry, we can't find any sales locations matching <strong>{locationValues}</strong>.
                        </Trans>
                    </P>
                    <Button onClick={() => setUrlSearch({})}>
                        {t('locations.empty_filtered_list.clear_search_and_filters')}
                    </Button>
                </EmptyState>
            </RelativeGroup>
        );
    }

    return null;
};

const RelativeGroup = styled.div`
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
`;

export default ListMessage;
