const StatisticsIcon = ({ fill='currentColor', strokewidth=1, width='50px', height='50px', title='statistics' }: {fill?: string; strokewidth?: number; width?: string; height?: string; title?: string}) => {
    return (
        <svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
            <title>{title}</title>
            <g fill={fill} stroke={fill} strokeLinecap="square" strokeLinejoin="miter" strokeMiterlimit="10" strokeWidth={strokewidth}>
                <line fill="none" stroke={fill} x1="3.999" x2="4" y1="29" y2="22"/>
                <line fill="none" stroke={fill} x1="11.999" x2="11.999" y1="13" y2="29"/>
                <line fill="none" stroke={fill} x1="20.018" x2="20" y1="29" y2="22"/>
                <line fill="none" stroke={fill} x1="27.999" x2="27.999" y1="13" y2="29"/>
                <polyline fill="none" points="3 13 12 4 20 12 29 3"/>
            </g>
        </svg>
    );
};

export default StatisticsIcon;
