import React, { Component } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { colors, distances } from '../../../../styles/constants';
import Page, { Back, PageButton } from '../../../../components/Page';
import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import { H1 } from '../../../../components/Typography';
import Icon from '../../../../components/Icons';
import NotFound from './NotFound';
import Details from './Details';
import { State as ClientState } from '../reducer';
import DeleteClientModal from './DeleteClientModal';
import RotateSecretModal from './RotateSecretModal';
import { Grant } from '../../../../types/management-auth';
import DeleteGrantModal from './DeleteGrantModal';

interface MatchParams {
    accountId: string;
    clientId: string;
}

export interface UserPageProps extends WithTranslation, RouteComponentProps<MatchParams> {
    getClient: (accountId: string, clientId: string) => void;
    openDeleteClientModal: () => void;
    dismissDeleteClientModal: () => void;
    deleteClient: (accountId: string, clientId: string) => void;
    openRotateSecretModal: () => void;
    dismissRotateSecretModal: () => void;
    rotateSecret: (accountId: string, clientId: string) => void;
    toggleSecretIsSafe: (value: boolean) => void;
    openDeleteGrantModal: (grant: Grant) => void;
    dismissDeleteGrantModal: () => void;
    deleteGrant: (accountId: string, grantId: string) => void;
    client: ClientState;
}

class UserPage extends Component<UserPageProps> {
    componentDidMount() {
        const { accountId, clientId } = this.props.match.params;
        this.props.getClient(accountId, clientId);
    }

    componentDidUpdate(prevProps: Readonly<UserPageProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const { clientId } = prevProps.match.params;
        if (this.props.match.params.clientId !== clientId) {
            this.props.getClient(this.props.match.params.accountId, this.props.match.params.clientId);
        }
    }

    render() {
        const {
            t,
            match,
            client,
            openDeleteClientModal: openDeleteUserModal,
            openRotateSecretModal,
            openDeleteGrantModal,
            dismissDeleteGrantModal,
            deleteGrant,
        } = this.props;
        const { accountId, clientId } = match.params;

        if (client.isDeleted) {
            return <Redirect to={`/${accountId}/settings/clients`} />;
        }

        return (
            <Page
                title={
                    <React.Fragment>
                        <Back to={`/${accountId}/settings/clients`} />
                        <Title>{t('settings.client.title')}</Title>
                        <PageButton
                            onClick={openDeleteUserModal}
                            disabled={!client.client || client.client.deleted_at !== undefined}
                        >
                            <Icon icon="delete" />
                            {t('settings.client.delete_client')}
                        </PageButton>
                        <PageButton
                            onClick={openRotateSecretModal}
                            disabled={!client.client || client.client.deleted_at !== undefined}
                        >
                            <Icon icon="revert" />
                            {t('settings.client.rotate_secret')}
                        </PageButton>
                    </React.Fragment>
                }
            >
                <Card title={undefined} noPadding>
                    <Wrapper>
                        {client.client && (
                            <Details
                                accountId={accountId}
                                client={client.client}
                                grants={client.grants}
                                openDeleteGrantModal={openDeleteGrantModal}
                            />
                        )}
                        {!client.isLoading && !client.client && <NotFound clientId={clientId} accountId={accountId} />}
                        {client.isLoading && <LoadingOverlay />}
                    </Wrapper>
                </Card>
                {client.client && client.isShowingDeleteModal && (
                    <DeleteClientModal
                        accountId={accountId}
                        client={client.client}
                        deleteClient={this.props.deleteClient}
                        dismissDeleteClientModal={this.props.dismissDeleteClientModal}
                    />
                )}
                {client.client && client.isShowingRotateSecretModal && (
                    <RotateSecretModal
                        accountId={accountId}
                        client={client.client}
                        grants={client.grants}
                        rotated={client.rotated}
                        isSecretSafe={client.isSecretSafe}
                        dismissRotateSecretModal={this.props.dismissRotateSecretModal}
                        rotateSecret={this.props.rotateSecret}
                        toggleSecretIsSafe={this.props.toggleSecretIsSafe}
                    />
                )}
                {client.client && client.deleteGrant && client.isShowingDeleteGrantModal && (
                    <DeleteGrantModal
                        accountId={accountId}
                        client={client.client}
                        grant={client.deleteGrant}
                        dismissDeleteGrantModal={dismissDeleteGrantModal}
                        deleteGrant={deleteGrant}
                    />
                )}
            </Page>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default withTranslation()(UserPage);
