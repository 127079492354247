import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '../../../../components/Forms';
import { P } from '../../../../components/Typography';
import View from '../../../../components/View';
import { distances, palette } from '../../../../styles/constants';
import TranslatedValidatedInput from './ValidateInput';

type StoreConfigProps = {
    values: {
        b2c: {
            enabled: boolean;
            currencies: {
                currency: string;
                enabled: boolean;
                store_id: string;
            }[];
        };
        b2b: {
            enabled: boolean;
            currencies: {
                currency: string;
                enabled: boolean;
                store_id: string;
            }[];
        };
    };
    initialValues: any;
    errors: any;
    touched: any;
    handleChange: (e: React.FormEvent<HTMLInputElement>) => void;
    handleBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    setFieldValue: (field: string, value: any) => void;
    setFieldTouched: (field: string, value: boolean) => void;
    hideInput?: boolean;
};

export default function StoreConfig({
    values,
    initialValues,
    errors,
    touched,
    handleChange,
    handleBlur,
    hideInput,
    setFieldTouched,
    setFieldValue,
}: StoreConfigProps) {
    const { t } = useTranslation();
    return (
        <View direction="column" width="100%" gap={distances.small} alignItems="flex-start">
            <Checkbox
                name="b2c.enabled"
                checked={values.b2c.enabled}
                onChange={(e) => {
                    handleChange(e);
                    if (!values.b2c.enabled) {
                        delete errors.b2c;
                        delete touched.b2c;
                    }
                }}
                label={t('settings.payment_connections.collector_modal.fields.enable_b2c')}
            />
            {values.b2c.enabled && (<>
                <FieldArray name="b2c.currencies" render={() => (
                    values.b2c.currencies.map((currency, index) => (
                        <View key={[currency.currency, index].join('-')} direction="column" width="100%" gap={distances.small} alignItems="flex-start"
                            px="24px"
                        >
                            <Checkbox
                                name={`b2c.currencies[${index}].enabled`}
                                checked={currency.enabled}
                                onChange={e => {
                                    setFieldValue(`b2c.currencies[${index}].enabled`, e.currentTarget.checked);
                                    setFieldTouched(`b2c.currencies[${index}].enabled`, true);
                                }}
                                label={t(
                                    'settings.payment_connections.collector_modal.fields.enable_currency',
                                    {
                                        context: currency.currency,
                                    }
                                )}
                            />
                            {currency.enabled && !hideInput && (
                                <>
                                    <TranslatedValidatedInput
                                        path={`b2c.currencies[${index}].store_id`}
                                        initialValues={initialValues}
                                        values={values}
                                        errors={errors}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        touched={touched}
                                        required
                                        className="stretch"
                                    />
                                </>
                            )}
                        </View>
                    )))} />
                {errors?.b2c?.currency && <P color={palette.destructive[500]}>
                    {errors.b2c.currency}
                </P>}
            </>
            )}
            <Checkbox
                name="b2b.enabled"
                checked={values.b2b.enabled}
                onChange={(e) => {
                    handleChange(e);
                    if (!values.b2b.enabled) {
                        delete errors.b2b;
                        delete touched.b2b;
                    }
                }}
                label={t('settings.payment_connections.collector_modal.fields.enable_b2b')}
            />
            {values.b2b.enabled && (
                <>
                    <FieldArray name="b2b.currencies" render={() =>
                        values.b2b.currencies.map((currency, index) => (
                            <View key={[currency.currency, index].join('-')} direction="column" width="100%" gap={distances.small} alignItems="flex-start"
                                px="24px"
                            >
                                <Checkbox
                                    name={`b2b.currencies[${index}].enabled`}
                                    checked={currency.enabled}
                                    onChange={e => {
                                        setFieldValue(`b2b.currencies[${index}].enabled`, e.currentTarget.checked);
                                        setFieldTouched(`b2b.currencies[${index}].enabled`, true);
                                    }}
                                    label={t(
                                        'settings.payment_connections.collector_modal.fields.enable_currency',
                                        {
                                            context: currency.currency,
                                        }
                                    )}
                                />
                                {currency.enabled && !hideInput && (
                                    <>
                                        <TranslatedValidatedInput
                                            path={`b2b.currencies[${index}].store_id`}
                                            initialValues={initialValues}
                                            values={values}
                                            errors={errors}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            touched={touched}
                                            required
                                            className="stretch"
                                        />
                                    </>
                                )}
                            </View>
                        ))} />
                    {errors?.b2b?.currency && <P color={palette.destructive[500]}>
                        {errors.b2b.currency}
                    </P>}
                </>
            )}
            {errors.stores && <P color={palette.destructive[500]}>
                {errors.stores}
            </P>}
        </View>
    );
}
