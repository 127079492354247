
export const toLanguageLink = (url: string | undefined, language: string) => {
    if (!url) {
        return undefined;
    }
    const joiner = url.includes('?') ? '&' : '?';
    return `${url}${joiner}language=${language}`;
};

export const paymentMethods = [
    {
        value: 'creditcard',
        label: 'Visa / Mastercard',
        countries: ['*'],
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'applepay',
        label: 'Apple Pay',
        countries: ['NO', 'SE'],
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'googlepay',
        label: 'Google Pay',
        countries: [], // Disabled by default for now but can be enabled with ?showAll=true query param
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'vipps',
        label: 'Vipps',
        countries: ['NO'],
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'swish',
        label: 'Swish',
        countries: ['SE'],
        product_types: ['checkout'],
    },
    {
        value: 'mobilepay',
        label: 'MobilePay',
        countries: ['FI', 'DK'],
        product_types: ['checkout'],
    },
    {
        value: 'klarna',
        label: 'Klarna',
        countries: ['NO', 'SE'],
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'billie',
        label: 'Billie',
        countries: ['SE'], // TODO: add 'SE' when integration is approved by Klarna
        product_types: ['checkout', 'split_payment'],
    },
    {
        value: 'walley',
        label: 'Walley',
        countries: ['NO', 'SE'],
        product_types: ['checkout'],
    },
];

export const getPaymentMethods = (country: string, showAll: boolean) => {
    if (showAll) {
        return paymentMethods;
    }
    return paymentMethods.filter(pm => (pm.countries.includes(country) || pm.countries.includes('*')));
};
