import { useSyncExternalStore } from 'use-sync-external-store/shim';
import messageExStore, { Message } from '../observables/messageExStore';

const generateId = () => {
    return (
        new Date().valueOf() +
        Math.random()
            .toString(36)
            .substring(7)
    );
};

export default function useMessageStore() {
    const store = useSyncExternalStore(messageExStore.subscribe, messageExStore.getSnapshot);
    return {
        ...store,
        addMessage: async (message: Omit<Message, 'id'>) => {
            const messageWithId = {
                ...message,
                id: generateId(),
            };
            messageExStore.dispatch('appendMessage', messageWithId);
            return messageWithId;
        },
        removeVisibleMessage: () => messageExStore.dispatch('removeVisibleMessage'),
    };
}
