import type { TFunction } from 'i18next';
import { validateRequired } from '../../../../helpers/validation';

export const determineStoreIdValidators = <T extends {
    b2c: {
        enabled: boolean;
        currencies: {
            enabled: boolean;
            store_id: string;
        }[];
    };
    b2b: {
        enabled: boolean;
        currencies: {
            enabled: boolean;
            store_id: string;
        }[];
    };
}>(values: T, t: TFunction): {
    path: string;
    validator: (value: string) => string | undefined;
}[] => {
    if ((!values.b2c.enabled && !values.b2b.enabled)) {
        return [
            {
                path: 'stores',
                validator: validateRequired(t(
                    'settings.payment_connections.collector_callback_modal.invalid.type.required'
                )),
            },
        ];
    }
    const b2bValidations = () => {
        if (values.b2b.enabled) {
            const enabledCurrencies = values.b2b.currencies.filter(currency => currency.enabled);
            if (enabledCurrencies.length === 0) {
                return [
                    {
                        path: 'b2b.currency',
                        validator: validateRequired(t(
                            'settings.payment_connections.collector_callback_modal.invalid.currencies.required'
                        )),
                    },
                ];
            }
            let faults: {
                path: string;
                validator: (value: string) => string | undefined;
            }[] = [];
            values.b2b.currencies.forEach((currency, index) => {
                if (currency.enabled) {
                    faults = [
                        {
                            path: `b2b.currencies[${index}].store_id`,
                            validator: validateRequired(t(
                                'settings.payment_connections.collector_callback_modal.invalid.store_id.required'
                            )),
                        },
                        {
                            path: `b2b.currencies[${index}].store_id`,
                            validator: (value) => isNaN(value as any) ? (
                                t('settings.payment_connections.collector_callback_modal.invalid.store_id.invalid')
                            ) : undefined,
                        },
                    ];
                }
            });
            return faults;
        }
    };
    const b2cValidations = () => {
        if (values.b2c.enabled) {
            const enabledCurrencies = values.b2c.currencies.filter(currency => currency.enabled);
            if (enabledCurrencies.length === 0) {
                return [
                    {
                        path: 'b2c.currency',
                        validator: validateRequired(t(
                            'settings.payment_connections.collector_callback_modal.invalid.currencies.required'
                        )),
                    },
                ];
            }
            let faults: {
                path: string;
                validator: (value: string) => string | undefined;
            }[] = [];
            values.b2c.currencies.forEach((currency, index) => {
                if (currency.enabled) {
                    faults = [
                        {
                            path: `b2c.currencies[${index}].store_id`,
                            validator: validateRequired(t(
                                'settings.payment_connections.collector_callback_modal.invalid.store_id.required'
                            )),
                        },
                        {
                            path: `b2c.currencies[${index}].store_id`,
                            validator: (value) => isNaN(value as any) ? (
                                t('settings.payment_connections.collector_callback_modal.invalid.store_id.invalid')
                            ) : undefined,
                        },
                    ];
                }
            });
            return faults;
        }
    };
    return [
        ...b2bValidations() || [],
        ...b2cValidations() || [],
    ];
};
