import { useActions } from '../../Actions';
import { useAccountId } from '../../auth/useAccountId';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getUsersSelector } from '../../settings/users/users/selectors';
import { AccountAdminUser } from '../../types/management-auth';
import useHasAnyOfScopes from './useHasAnyOfScopes';

type useUsersReturnType = {
    isLoading: boolean;
    users: AccountAdminUser[];
    refresh: () => void;
};

const useUsers: () => useUsersReturnType = () => {
    const actions = useActions('users');
    const fetchUsers = actions.fetchUsers;
    const fetchUsersRef = useRef(fetchUsers);
    const accountId = useAccountId();
    const hasScopes = useHasAnyOfScopes(['read:accounts', 'admin:accounts']);
    const { users, isLoading, status } = useSelector(getUsersSelector);
    useEffect(() => {
        if (accountId && users.length === 0 && !isLoading && hasScopes && status === -1) {
            fetchUsersRef.current(accountId);
        }
    }, [accountId, isLoading, hasScopes, users, status]);
    const refresh = () => fetchUsersRef.current(accountId);
    return {
        isLoading,
        users,
        refresh,
    };
};

export default useUsers;
