import Card from '../../../components/Card';
import { Json } from '../../../components/Formatters';
import { H3 } from '../../../components/Typography';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { distances } from '../../../styles/constants';
import { CheckoutConfig } from '../../../types/checkout';
import Hr from '../../../components/Hr';

type CredentialsProps = {
    checkout: CheckoutConfig;
}
const Credentials = ({ checkout }: CredentialsProps) => {
    const { t } = useTranslation();
    const credentials = Object.entries(checkout?.gateways || {});
    return <Card marginBottom={distances.normal}>
        <H3>{t('partner.merchant.credentials')}</H3>
        <Hr />
        <List>
            {credentials.map(([key, value]) => {
                return <Item key={key}>
                    <H3>{key}</H3>
                    <Json data={value} />
                </Item>;
            })
            }
        </List>
    </Card>;
};

const List = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: ${distances.small};
`;

const Item = styled.div`
    flex: 1;
    max-width: 100%;
`;


export default memo(Credentials);
