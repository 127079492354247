import * as React from 'react';
import styled from 'styled-components/macro';
import { WithTranslation, withTranslation } from 'react-i18next';
import { formatDate, formatTime } from '../../helpers/formatters';

interface DateTimeProps extends WithTranslation {
    isoString?: string;
    boldTime?: boolean;
}

const DateTime = (
    {
        isoString,
        boldTime,
        i18n,
    }: DateTimeProps
) => {
    if (!isoString) {
        return null;
    }
    try {
        const dateString = formatDate(isoString, i18n.language);
        const timeString = formatTime(isoString, i18n.language);

        if (boldTime) {
            return (
                <Wrapper>
                    {dateString} <Time>{timeString}</Time>
                </Wrapper>
            );
        }
        return (
            <Wrapper>
                {dateString} {timeString}
            </Wrapper>
        );
    } catch {
        return <Wrapper>{isoString}</Wrapper>;
    }
};

const Wrapper = styled.span`
    white-space: nowrap;
    font-feature-settings: 'tnum';
`;

const Time = styled.span`
    font-size: 11px;
    font-weight: 700;
`;

export default withTranslation()(DateTime);
