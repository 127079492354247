import { Actions, ActionTypes } from './actions';
import { Client, Grant } from '../../../types/management-auth';

export interface State {
    isLoading: boolean;
    isCreated: boolean;
    client: Client | undefined;
    grants: Grant[];
}

export const initialState: State = {
    isLoading: false,
    isCreated: false,
    client: undefined,
    grants: [],
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.ResetForm:
            return {
                ...initialState,
            };

        case ActionTypes.GetClient:
            return {
                ...initialState,
                isLoading: true,
            };

        case ActionTypes.FetchClientResponse:
            return {
                ...state,
                client: action.payload.client,
            };

        case ActionTypes.FetchGrantResponse:
            return {
                ...state,
                isLoading: false,
                grants: action.payload.grants,
            };

        case ActionTypes.CreateGrant:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FetchCreateGrantResponse:
            return {
                ...state,
                isCreated: true,
            };

        default:
            return state;
    }
}
