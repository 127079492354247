import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import StatisticsIcon from './StatisticsIcon';
import CheckIcon from './CheckIcon';

export const NoData = () => {
    const { t } = useTranslation();

    return <EmptyStateWrapper>
        <EmptyStateContent>
            <StatisticsIcon />
            <br/>
            {t('dashboard.kpis.empty_states.no_data')}
        </EmptyStateContent>
    </EmptyStateWrapper>;
};


export const AllCaptured = () => {
    const { t } = useTranslation();

    return <EmptyStateWrapper>
        <EmptyStateContent>
            <CheckIcon />
            <br/>
            {t('dashboard.kpis.empty_states.all_captured')}
        </EmptyStateContent>
    </EmptyStateWrapper>;
};
const EmptyStateWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
    font-size: 12px;

    @media (min-width: 768px) and (max-width: 1200px) {
        width: 50%;
    }
`;

const EmptyStateContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;
