import { useState } from 'react';
import { Select } from '../../../../components/SimpleSelect/Select';
import VippsAgreementForm from './VippsAgreementForm';
import VippsDirectForm from './VippsDirectForm';
import { ApprovalsPaymentResponse } from '../../../../types/management-auth/generated';
import { useTranslation } from 'react-i18next';

type VippsFormProps = {
    activeApprovals: ApprovalsPaymentResponse[];
};

export default function VippsForm(_: VippsFormProps) {
    const { t } = useTranslation();
    const [type, setType] = useState<'agreement' | 'direct'>('agreement');

    return (
        <>
            <Select
                label={t(
                    'settings.payment_connections.vipps_modal.fields.agreement'
                )}
                defaultValue={type}
                items={[
                    { value: 'agreement', label: 'Agreement via Dintero' },
                    { value: 'direct', label: 'Direct Agreement' },
                ].map((x) => ({
                    ...x,
                    label: t('settings.payment_connections.vipps_modal.agreement', {
                        context: x.value,
                    }),
                }))}
                onChange={(value) => setType(value as 'agreement' | 'direct')}
            />
            {type === 'agreement' && <VippsAgreementForm />}
            {type === 'direct' && <VippsDirectForm />}
        </>
    );
}
