import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { State } from '../../reducer';

import PlaceholderApp from '../../components/Placeholders/App';
import ChangeLanguage from '../../components/TopBar/ChangeLanguage';
import ChangeAccount from './ChangeAccount';
import ChangePassword from './ChangePassword';
import ChangeSettings from './ChangeSettings';
import NoAccess from './NoAccess';
import RedirectToAccount from './RedirectToAccount';
import { loadTokens } from './actions';
import * as selectors from './selectors';

interface MatchParams {
    accountId: string;
}

const mapDispatchToProps = {
    loadTokens,
};

const mapStateToProps = (state: State) => ({
    cognitoToken: selectors.getCognitoToken(state),
    isLoadingTokens: selectors.getIsLoadingTokens(state),
    tokens: selectors.getTokens(state),
    exchangeTokenPartnerId: selectors.getExchangeTokenPartnerId(state),
});

interface WithAccountAccessTokenProps extends RouteComponentProps<MatchParams> {
    cognitoToken: string;
    children: any;
    loadTokens: (accountId: string) => void;
    isLoadingTokens: boolean;
    tokens: { [accountId: string]: string };
    exchangeTokenPartnerId: string | undefined;
}

class WithAccountAccessToken extends Component<WithAccountAccessTokenProps> {
    componentDidMount() {
        const accountId = this.props.match.params.accountId;
        if (accountId) {
            this.props.loadTokens(accountId);
        }
    }

    shouldReloadTokens(prevProps: WithAccountAccessTokenProps) {
        // if the account_id has changed
        const accountId = this.props.match.params.accountId || '';
        const prevAccountId = prevProps.match.params.accountId || '';
        if (accountId && accountId.slice(1) !== prevAccountId.slice(1)) {
            return true;
        }
        // if the cognito token has been replaced
        if (prevProps.cognitoToken !== this.props.cognitoToken) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps: WithAccountAccessTokenProps) {
        const shouldReloadTokens = this.shouldReloadTokens(prevProps);
        if (shouldReloadTokens) {
            const accountId = this.props.match.params.accountId;
            this.props.loadTokens(accountId);
        }
    }

    render() {
        // Loading animation
        if (this.props.isLoadingTokens) {
            return <PlaceholderApp />;
        }

        // Select account page
        const pathname = this.props.location.pathname;
        const accountId = this.props.match.params.accountId;
        if (`${pathname}` === `/${accountId}/accounts`) {
            return (
                <>
                    <PlaceholderApp />
                    <ChangeAccount />
                </>
            );
        }
        if (`${pathname}` === `/${accountId}/change_password`) {
            return (
                <>
                    <PlaceholderApp />
                    <ChangePassword />
                </>
            );
        }
        if (`${pathname}` === `/${accountId}/change_settings`) {
            return (
                <>
                    <PlaceholderApp />
                    <ChangeSettings />
                </>
            );
        }
        if (`${pathname}` === `/${accountId}/change_language`) {
            return (
                <>
                    <PlaceholderApp />
                    <ChangeLanguage />
                </>
            );
        }

        // If we aren't able to get a token for this account
        const token = this.props.tokens[accountId];
        if (!token) {
            const exchangeTokenPartnerId = this.props.exchangeTokenPartnerId;
            return (
                <>
                    <PlaceholderApp />
                    {exchangeTokenPartnerId ? <RedirectToAccount /> : <NoAccess />}
                </>
            );
        }
        return <React.Fragment key={accountId}>{this.props.children}</React.Fragment>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithAccountAccessToken);
