export const emailRegex = new RegExp(
    /^(([^<>()\\\\.,;:\s@"]+(\.[^<>()\\\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const isEmail = (email: string) => {
    return emailRegex.test(email);
};

export const validateEmail = (message: string) => {
    return (value: string) => {
        if (!value) {
            return;
        }
        const isValid = isEmail(value);
        if (!isValid) {
            return message;
        }
    };
};
