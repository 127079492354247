import { useCallback, useEffect, useState, useRef } from 'react';

const useDocumentVisibleAgainEffect = (fn: () => any) => {
    const prevVisibleStateRef= useRef(false);
    const [counter, setCounter] = useState(0);
    const handleVisibilityChange = useCallback(() => {
        if (!prevVisibleStateRef.current && document.visibilityState === 'visible') {
            setCounter(counter + 1);
            fn();
        }
        prevVisibleStateRef.current = document.visibilityState === 'visible'; 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return counter;
};

export default useDocumentVisibleAgainEffect;
