import { connect } from 'react-redux';

import { getPayoutRules, getCheckoutConfiguration, clearData } from '../actions';
import { State } from '../../../../reducer';
import { getPayoutRulesSelector } from '../selectors';
import { getUser } from '../../../../auth/accessToken/selectors';

import PayoutRules from '../components/PayoutRules';

const mapStateToProps = (state: State) => ({
    payoutRules: getPayoutRulesSelector(state),
    user: getUser(state),
});

const mapDispatchToProps = {
    getPayoutRules,
    getCheckoutConfiguration,
    clearData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutRules);
