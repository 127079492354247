import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { H1, P } from '../../../components/Typography';
import Modal from '../../../components/Modal';
import { Button } from '../../../components/Buttons';
import { distances } from '../../../styles/constants';
import { LoadingOverlay } from '../../../components/Loading';

export interface DeleteModalProps {
    dismiss: () => void;
    remove: () => void;
}

const RemovePayoutDestinationApproval = ({ remove, dismiss }: DeleteModalProps) => {
    const { t } = useTranslation();
    const [isDeleting, setIsDeleting] = useState(false);
    return (
        <Modal>
            <H1>{t('settings.payout_destination_approval.delete_modal.title')}</H1>
            <TextWrapper>
                <P>{t('settings.payout_destination_approval.delete_modal.description')}</P>
            </TextWrapper>
            <Button
                onClick={() => {
                    setIsDeleting(true);
                    remove();
                }}
                disabled={isDeleting}
            >
                {t('settings.payout_destination_approval.delete_modal.call_to_action')}
            </Button>
            <ButtonSpacer />
            <Button className="alt" onClick={dismiss}>
                {t('settings.payout_destination_approval.delete_modal.cancel')}
            </Button>
            {isDeleting && <LoadingOverlay />}
        </Modal>
    );
};

export default RemovePayoutDestinationApproval;

const ButtonSpacer = styled.span`
    display: inline-block;
    width: ${distances.tiny};
`;

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
