import React from 'react';
import styled from 'styled-components/macro';
import {  RouteComponentProps, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup } from '../../components/Buttons';
import Page from '../../components/Page';
import Card from '../../components/Card';
import { distances } from '../../styles/constants';
import { P } from '../../components/Typography';

interface UncaughtComponentErrorProps extends RouteComponentProps {}

const UncaughtComponentError = (
    {
        history,
    }: UncaughtComponentErrorProps
) => {
    const { t } = useTranslation();
    return (
        <Page title={t('errors.component_error.title')}>
            <Card>
                <P>{t('errors.component_error.description')}</P>
                <br />
                <P>{t('errors.component_error.developers_are_notified_and_it_will_be_fixed')}</P>

                <ButtonWrapper>
                    <Button
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        {t('errors.component_error.browser_back_call_to_action')}
                    </Button>
                    <Button
                        className="alt"
                        onClick={() => {
                            window.location.reload();
                        }}
                    >
                        {t('errors.component_error.reload_window_call_to_action')}
                    </Button>
                </ButtonWrapper>
            </Card>
        </Page>
    );
};

export default withRouter(UncaughtComponentError);

const ButtonWrapper = styled(ButtonGroup)`
    margin-top: ${distances.normal};
`;
