import { AccountAdminUser } from '../../../types/management-auth';

export enum ActionTypes {
    GetUser = '[settings/userDetails] GET_USER',
    FetchUserResponse = '[settings/userDetails] FETCH_USER_RESPONSE',
    OpenRemoveUserModal = '[settings/userDetails] OPEN_DELETE_USER_MODAL',
    DismissRemoveUserModal = '[settings/userDetails] DISMISS_DELETE_USER_MODAL',
    RemoveUser = '[settings/userDetails] DELETE_USER',
    FetchRemoveUserResponse = '[settings/userDetails] DELETE_USER_RESPONSE',
}

export interface GetUser {
    type: ActionTypes.GetUser;
    payload: {
        accountId: string;
        userId: string;
    };
}

export interface FetchUserResponse {
    type: ActionTypes.FetchUserResponse;
    payload: {
        user: AccountAdminUser;
        users: AccountAdminUser[];
    };
    meta: {
        cause: GetUser;
    };
}

export function getUser(accountId: string, userId: string): GetUser {
    return {
        type: ActionTypes.GetUser,
        payload: {
            accountId,
            userId,
        },
    };
}

export interface DismissRemoveUserModal {
    type: ActionTypes.DismissRemoveUserModal;
    payload: {};
}

export function dismissRemoveUserModal(): DismissRemoveUserModal {
    return {
        type: ActionTypes.DismissRemoveUserModal,
        payload: {},
    };
}

export interface OpenRemoveUserModal {
    type: ActionTypes.OpenRemoveUserModal;
    payload: {};
}

export function openRemoveUserModal(): OpenRemoveUserModal {
    return {
        type: ActionTypes.OpenRemoveUserModal,
        payload: {},
    };
}

export interface RemoveUser {
    type: ActionTypes.RemoveUser;
    payload: {
        accountId: string;
        userId: string;
    };
}

export function removeUser(accountId: string, userId: string): RemoveUser {
    return {
        type: ActionTypes.RemoveUser,
        payload: {
            accountId,
            userId,
        },
    };
}

export interface FetchRemoveUserResponse {
    type: ActionTypes.FetchRemoveUserResponse;
    payload: {};
    meta: {
        cause: RemoveUser;
    };
}

export type Actions =
    | GetUser
    | FetchUserResponse
    | OpenRemoveUserModal
    | DismissRemoveUserModal
    | RemoveUser
    | FetchRemoveUserResponse;
