import React from 'react';

type ChatProps = {
    fill?: string;
}

const Chat = (props: ChatProps) => {
    const fill = props.fill || 'currentColor';

    return (
        <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill} strokeLinecap="butt" strokeLinejoin="miter">
                <path d="M21,2H3c-1.105,0-2,.895-2,2V15c0,1.105,.895,2,2,2h5l4,5,4-5h5c1.105,0,2-.895,2-2V4c0-1.105-.895-2-2-2Z" fill="none" stroke={fill} strokeLinecap="square" strokeMiterlimit="10" strokeWidth="2"/>
            </g>
        </svg>
    );
};

export default Chat;
