import React from 'react';
import styled from 'styled-components/macro';

import { colors, distances } from '../../styles/constants';
import Dropdown, { DropdownOption } from '../../components/Forms/Dropdown';
import { InputValidation } from '../../components/Forms';
import { useTranslation } from 'react-i18next';

import { Label } from '../../components/Typography';

export interface EnvironmentDropdownProps {
    value: string;
    name: string;
    label: string;
    placeholder: string;
    onChange: (value: string) => void;
    validation?: InputValidation;
    accountIds: string[];
    disabled?: boolean;
}

const EnvironmentDropdown = (
    {
        label,
        name,
        value,
        onChange,
        accountIds,
        validation,
        disabled,
    }: EnvironmentDropdownProps
) => {
    const { t } = useTranslation();
    const optionTitle = (accountId: string) =>
        t((accountId || '').startsWith('P') ? 'environments.production' : 'environments.test');
    const options: DropdownOption[] = accountIds.map((accountId) => ({
        label: <span>{optionTitle(accountId || '')}</span>,
        value: accountId || '',
    }));
    const selectedOption = options.find((option) => option.value === value);
    return (
        <Wrapper isDisabled={!!disabled}>
            <Label htmlFor={name}>{label}</Label>
            {selectedOption && (
                <Dropdown
                    name={name}
                    value={selectedOption}
                    options={options}
                    onChange={(val) => {
                        onChange(val.value);
                    }}
                    disabled={disabled}
                />
            )}
            {validation && validation.message && (
                <Message htmlFor={name} className={validation.state}>
                    {validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

const Message = styled.label`
    position: absolute;
    top: 66px;
    left: 0;
    font-size: 11px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

const Wrapper = styled.div<{ isDisabled: boolean }>`
    position: relative;
    opacity: ${(props) => (props.isDisabled ? '0.6' : '1')};
`;

export default EnvironmentDropdown;
