import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';
import _pick from 'lodash/pick';

import { useActions } from '../../../Actions';
import { externalSelectors as selectors } from '../../../payout/merchant/config/selectors';
import type {
    ConfigBankAccount,
    ConfigBankAccountPayoutDestination,
    ConfigRfc,
    ConfigRfcBankAccountUpdateData
} from '../../../payout/types';
import { LoadingOverlay } from '../../../components/Loading';
import { Button, ButtonGroup } from '../../../components/Buttons';
import { Dl, Dt, Dd } from '../../../components/DescriptionList/Horizontal';
import EditBankAccountDialog from './EditBankAccountDialog';

import { H2, P } from '../../../components/Typography';
import Modal from '../../../components/Modal';
import Grid, { GridItem } from '../../../components/Grid';
import View from '../../../components/View';
import styled from 'styled-components/macro';
import { defaultRadius, palette } from '../../../styles/constants';

export type ConfigBankAccountWithRfcAndLink = ConfigBankAccount & {
    rfc?: ConfigRfc;
    link?: ConfigBankAccountPayoutDestination;
};

type BankAccountProps = {
    bankAccount: ConfigBankAccountWithRfcAndLink;
    onClickEdit: (bankAccount: ConfigBankAccountWithRfcAndLink) => void;
    accountId: string;
};

const Wrapper = styled.div`
    padding-inline: 24px;
    padding-block: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    border: 1px solid ${palette.neutral[200]};
    border-radius: ${defaultRadius};

    .button-group {
        margin-top: var(--Spacing-1);
    }
`;

const BankAccount = ({ bankAccount, onClickEdit, accountId }: BankAccountProps) => {
    const { t } = useTranslation('payout');
    const [unlinkDialogOpen, setUnlinkDialogOpen] = useState(false);
    const [deleteRfcDialogOpen, setDeleteRfcDialogOpen] = useState(false);
    const { deleteConfigRfc, deleteBankAccountPayoutDestination } = useActions('payout.config');

    const { rfc, link } = bankAccount;
    const hasRfc = !!rfc;
    const hasLink = !!link;

    const onConfirmDeleteRfc = useCallback(() => {
        if (rfc) {
            deleteConfigRfc(accountId, rfc.id);
        }
        setDeleteRfcDialogOpen(false);
    }, [accountId, rfc, deleteConfigRfc]);

    const onConfirmUnlink = useCallback(() => {
        if (link) {
            deleteBankAccountPayoutDestination(accountId, link.id);
        }
        setUnlinkDialogOpen(false);
    }, [accountId, link, deleteBankAccountPayoutDestination]);

    const getFieldValue = useCallback(
        <TKey extends keyof ConfigBankAccount>(key: TKey) => {
            const rfcData = bankAccount.rfc?.data;
            const value = bankAccount[key];
            const hasUpdateRequest = rfcData?.type === 'configBankAccountUpdate';
            if (hasUpdateRequest) {
                const updatedValue = (rfcData.bank_account as any)[key];
                if (value !== updatedValue) {
                    return (
                        <>
                            <i>
                                <del>{value}</del>
                            </i>
                            &nbsp;{updatedValue}
                        </>
                    );
                }
            }
            return value;
        },
        [bankAccount]
    );

    return (
        <Wrapper>
            <div>
                <Dl break={1000}>
                    <Dt span="span2">{t('bank_account.nickname')}</Dt>
                    <Dd>{getFieldValue('nickname')}</Dd>
                </Dl>
                <Dl break={1000}>
                    <Dt span="span2">{t('bank_account.number', { context: bankAccount.bank_account_type })}</Dt>
                    <Dd>{getFieldValue('bank_account_number')}</Dd>
                </Dl>
                <Dl break={1000}>
                    <Dt span="span2">{t('bank_account.bic')}</Dt>
                    <Dd>{getFieldValue('bank_account_bic')}</Dd>
                </Dl>
                <Dl break={1000}>
                    <Dt span="span2">{t('general.currency')}</Dt>
                    <Dd>{getFieldValue('bank_account_currency')}</Dd>
                </Dl>
                <Dl break={1000}>
                    <Dt span="span2">{t('bank_account.country_code')}</Dt>
                    <Dd>{getFieldValue('bank_account_country_code')}</Dd>
                </Dl>
                {hasRfc && (
                    <P>
                        <strong>
                            <em>{t('bank_account.active_rfc.label')}</em>
                        </strong>
                    </P>
                )}
            </div>
            <ButtonGroup>
                {hasRfc ? (
                    <Button className="alt" onClick={() => setDeleteRfcDialogOpen(true)}>
                        {t('bank_account.active_rfc.delete')}
                    </Button>
                ) : (
                    <>
                        <Button className="alt" onClick={() => onClickEdit(bankAccount)}>
                            {t('bank_account.edit')}
                        </Button>
                    </>
                )}
                {hasLink && (
                    <Button className="alt" onClick={() => setUnlinkDialogOpen(true)}>
                        {t('payout_destination.unlink_bank_account')}
                    </Button>
                )}
            </ButtonGroup>
            {hasRfc && deleteRfcDialogOpen && (
                <Modal>
                    <View gap={24} direction="column" justify="flex-start" alignItems="flex-start">
                        <View direction="column" justify="flex-start" alignItems="flex-start">
                            <H2>
                                {t('bank_account.delete_rfc_dialog.title')}
                            </H2>
                            <P>
                                {t('bank_account.delete_rfc_dialog.message', {
                                    nickname: bankAccount.nickname,
                                    bank_account_number: bankAccount.bank_account_number,
                                })}
                            </P>
                        </View>
                        <ButtonGroup>
                            <Button className="alt large" onClick={() => setDeleteRfcDialogOpen(false)}>{t('bank_account.delete_rfc_dialog.cancel')}</Button>
                            <Button className="red large" onClick={onConfirmDeleteRfc}>{t('bank_account.delete_rfc_dialog.delete')}</Button>
                        </ButtonGroup>
                    </View>
                </Modal>
            )}
            {hasLink && unlinkDialogOpen && (
                <Modal>
                    <View gap={24} direction="column" justify="flex-start" alignItems="flex-start">
                        <View direction="column" justify="flex-start" alignItems="flex-start">
                            <H2>
                                {t('payout_destination.unlink_bank_account_dialog.title')}
                            </H2>
                            <P>
                                {t('payout_destination.unlink_bank_account_dialog.message', {
                                    name: `${bankAccount.nickname} - ${bankAccount.bank_account_number}`,
                                })}
                            </P>
                        </View>
                        <ButtonGroup>
                            <Button className="alt large" onClick={() => setUnlinkDialogOpen(false)}>{t('payout_destination.unlink_bank_account_dialog.cancel')}</Button>
                            <Button className="red large" onClick={onConfirmUnlink}>{t('payout_destination.unlink_bank_account_dialog.unlink')}
                            </Button>
                        </ButtonGroup>
                    </View>
                </Modal>
            )}
        </Wrapper>
    );
};

const isLoading = (accountId: string) => createSelector(
    selectors.account(accountId).isLoading,
    selectors.bankAccounts(accountId).isLoading,
    selectors.rfcs(accountId).isLoading,
    selectors.links(accountId).isLoading,
    (l1, l2, l3, l4) => l1 || l2 || l3 || l4
);

const activeBankAccountUpdateRequestsByBankAccountId = (accountId: string) => createSelector(selectors.rfcs(accountId).rfcs, (rfcs) =>
    rfcs
        .filter((rfc) => rfc.data.type === 'configBankAccountUpdate' && rfc.approved === null)
        .reduce((res, rfc) => {
            const data = rfc.data as ConfigRfcBankAccountUpdateData;
            res[data.id] = rfc as any;
            return res;
        }, {} as Record<string, Omit<ConfigRfc, 'data'> & { data: ConfigRfcBankAccountUpdateData }>));

const newBankAccountsFromRfcs = (accountId: string) => createSelector(
    selectors.rfcs(accountId).rfcs,
    (rfcs) => rfcs
        .filter((rfc) => rfc.approved === null)
        .flatMap<ConfigBankAccountWithRfcAndLink>((rfc) => {
            if (rfc.data.type === 'onboarding') {
                return rfc.data.bank_accounts.map((oba, i) => ({
                    id: `${rfc.id}-${i}`,
                    ...oba,
                    ..._pick(rfc, ['updated_at', 'updated_by', 'created_at', 'created_by', 'account_id']),
                    rfc,
                }));
            }
            if (rfc.data.type === 'configBankAccountCreate') {
                return rfc.data.bank_accounts.map((crba, i) => ({
                    id: `${rfc.id}-${i}`,
                    ...crba,
                    ..._pick(rfc, ['updated_at', 'updated_by', 'created_at', 'created_by', 'account_id']),
                    rfc,
                }));
            }
            // type === 'configBankAccountUpdate' is grouped with existing bankAccounts in the selector below
            return [];
        })
);

export const createPayoutDestinationBankAccountsSelector = (accountId: string, pdId: string) => createSelector(
    selectors.payoutDestinations(accountId).payoutDestinationConfigsRaw,
    selectors.bankAccounts(accountId).bankAccountsRaw,
    selectors.links(accountId).links,
    activeBankAccountUpdateRequestsByBankAccountId(accountId),
    newBankAccountsFromRfcs(accountId),
    isLoading(accountId),
    (
        payoutDestinations,
        bankAccounts,
        links,
        updateRequests,
        newBankAccountsFromRfcs,
        loading
    ) => {
        const payoutDestination = payoutDestinations[pdId] ?? undefined;

        const linkedBankAccountsWithRfc = links
            .filter(l => l.payout_destination_config_id === pdId && !!bankAccounts[l.config_bank_account_id])
            .map(l => ({
                ...bankAccounts[l.config_bank_account_id],
                rfc: updateRequests[l.config_bank_account_id] ?? undefined,
                link: l,
            }));

        const filteredNewBankAccounts = newBankAccountsFromRfcs.filter(({ rfc }) => {
            if (rfc && 'payout_destinations' in rfc.data) {
                return (
                    rfc.data.payout_destinations?.find(
                        (pd) => pd.payout_destination_id === payoutDestination?.payout_destination_id
                    ) !== undefined
                );
            }
            return false;
        });

        return {
            loading,
            payoutDestination,
            bankAccounts: [...linkedBankAccountsWithRfc, ...filteredNewBankAccounts],
        };
    }
);

const defaultOrganizationBankAccountsSelector = (accountId: string, organizationNumber: string) => createSelector(
    selectors.bankAccounts(accountId).bankAccounts,
    isLoading(accountId),
    (bankAccounts, loading) => ({
        loading,
        bankAccounts: bankAccounts.filter(b => b.owner_orgno === organizationNumber),
    })
);

const allBankAccountsSelector = (accountId: string) => createSelector(
    selectors.bankAccounts(accountId).bankAccounts,
    activeBankAccountUpdateRequestsByBankAccountId(accountId),
    newBankAccountsFromRfcs(accountId),
    isLoading(accountId),
    (bankAccounts, updateRequests, newBankAccountsFromRfcs, loading) => {
        const bankAccountsWithUpdateRequests: ConfigBankAccountWithRfcAndLink[] = bankAccounts.map((ba) => ({
            ...ba,
            rfc: updateRequests[ba.id] ?? undefined,
        }));
        return {
            loading,
            bankAccounts: [...bankAccountsWithUpdateRequests, ...newBankAccountsFromRfcs],
        };
    }
);

export type BankAccountsProps = {
    payoutDestinationConfigId: string;
    organizationNumber?: undefined;
    accountId: string;
} | {
    payoutDestinationConfigId?: undefined;
    organizationNumber: string;
    accountId: string;
};

export const getSelector = (accountId: string, props: BankAccountsProps) => {
    if (props.payoutDestinationConfigId) {
        return createPayoutDestinationBankAccountsSelector(accountId, props.payoutDestinationConfigId);
    }
    if (props.organizationNumber) {
        return defaultOrganizationBankAccountsSelector(accountId, props.organizationNumber);
    }
    return allBankAccountsSelector(accountId);
};

export default function BankAccounts(props: BankAccountsProps) {
    const { t } = useTranslation('payout');
    const { getAccountConfig, getBankAccounts, getConfigRfcs, getBankAccountPayoutDestinations } = useActions(
        'payout.config'
    );
    const [editTarget, setEditTarget] = useState<ConfigBankAccountWithRfcAndLink>();
    const { bankAccounts, loading } = useSelector(useMemo(() => getSelector(props.accountId, props), [props]));

    useEffect(() => {
        if (props.accountId) {
            getAccountConfig(props.accountId);
            getBankAccounts(props.accountId);
            getConfigRfcs(props.accountId);
            getBankAccountPayoutDestinations(props.accountId);
        }
    }, [props.accountId, getAccountConfig, getBankAccounts, getConfigRfcs, getBankAccountPayoutDestinations]);
    if (!loading && (bankAccounts.length === 0 || bankAccounts === undefined)) {
        return (
            <span>
                {t('config.bank_accounts_empty')}
            </span>
        );
    }
    return (
        <>
            <Grid gap={24} alignItems="stretch">
                {bankAccounts.map((ba) => (
                    <GridItem key={ba.id}>
                        <BankAccount
                            bankAccount={ba}
                            accountId={props.accountId}
                            onClickEdit={(bankAccount) => setEditTarget(bankAccount)}
                        />
                    </GridItem>
                ))}
            </Grid>
            {editTarget && (
                <EditBankAccountDialog
                    open={Boolean(editTarget)}
                    onClose={() => setEditTarget(undefined)}
                    bankAccount={editTarget}
                />
            )}
            {loading && <LoadingOverlay />}
        </>
    );
}
