import fulfill from '../../fulfill';
import { SideEffectFunction } from '../../sideEffects';
import { CORE_API_HOSTNAME } from '../../env';
import {
    ActionTypes,
    FetchAccountsResponse,
    GetAccounts,
    GetExchangeToken,
    FetchExchangeTokenResponse,
    GetPartnerUsers,
    FetchPartnerUsersResponse
} from './actions';
import { Account, AccountAdminUser } from '../../types/management-auth';
import { authenticate } from '../../auth/cognito';
import { CognitoSession } from '../../auth/types';

import { setInspectAuthenticatedUser, SetInspectAuthenticatedUser } from '../../auth/accessToken/actions';

const fetchAccounts: SideEffectFunction<GetAccounts, FetchAccountsResponse> = async (action) => {
    const params = action.payload.params;
    const result: FetchAccountsResponse = await fulfill.get({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/accounts?${params.toString()}`,
        accountId: action.payload.accountId,
        handlers: {
            200: (accounts: Account[]) => ({
                type: ActionTypes.FetchAccountsResponse,
                payload: {
                    accounts,
                },
                meta: {
                    cause: action,
                },
            }),
            401: () => ({
                type: ActionTypes.FetchAccountsResponse,
                payload: {
                    accounts: [],
                },
                meta: {
                    cause: action,
                },
            }),
            403: () => ({
                type: ActionTypes.FetchAccountsResponse,
                payload: {
                    accounts: [],
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchExchangeToken: SideEffectFunction<GetExchangeToken, FetchExchangeTokenResponse> = async (action) => {
    const result: FetchExchangeTokenResponse = await fulfill.post({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/auth/exchange_token`,
        accountId: action.payload.accountId,
        json: {
            account_id: action.payload.subAccountId,
        },
        handlers: {
            200: (response: { access_token: string }) => ({
                type: ActionTypes.FetchExchangeTokenResponse,
                payload: {
                    subAccountId: action.payload.subAccountId,
                    accessToken: response.access_token,
                },
                meta: {
                    cause: action,
                },
            }),
            403: () => null,
        },
    });
    return result;
};

const replaceCognitoToken: SideEffectFunction<
    FetchExchangeTokenResponse,
    SetInspectAuthenticatedUser | undefined
> = async (action) => {
    const authenticatedUser = await authenticate({
        cognito_expires: '0',
        cognito_access_token: action.payload.accessToken,
    } as CognitoSession);
    if (authenticatedUser) {
        return setInspectAuthenticatedUser(authenticatedUser, action.meta.cause.payload.accountId);
    }
};


const fetchPartnerUsers: SideEffectFunction<GetPartnerUsers, FetchPartnerUsersResponse> = async action => {
    const result: FetchPartnerUsersResponse = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/users`,
        handlers: {
            200: (users: AccountAdminUser[]) => ({
                type: ActionTypes.FetchPartnerUsersResponse,
                payload: {
                    users,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.GetAccounts]: fetchAccounts,
    [ActionTypes.GetExchangeToken]: fetchExchangeToken,
    [ActionTypes.FetchExchangeTokenResponse]: [replaceCognitoToken],
    [ActionTypes.GetPartnerUsers]: fetchPartnerUsers,
};

export default effects;
