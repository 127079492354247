import { useActions } from '../../Actions';
import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getClientsSelector } from '../../settings/clients/clients/selectors';
import { Client, Grant } from '../../types/management-auth';
import useHasAnyOfScopes from './useHasAnyOfScopes';
import { useAccountIds } from '../../auth/accessToken/withAccountIds';

type UseApiClientsReturnType = {
    isLoading: boolean;
    clients: Client[];
    grants: { [clientId: string]: Grant[] };
    refresh: () => void;
};

type UseApiClientsProps = {
    environment: 'test' | 'production' | 'url';
};

const getEnvironmentAccountId = (environment: UseApiClientsProps['environment'], testAccountId: string | undefined, prodAccountId: string | undefined, urlAccountId: string) => {
    switch (environment) {
        case 'test':
            return testAccountId || urlAccountId;
        case 'production':
            return prodAccountId || urlAccountId;
        case 'url':
            return urlAccountId;
    }
};

const useApiClients: (props?: UseApiClientsProps) => UseApiClientsReturnType = (props) => {
    const { environment } = props || { environment: 'url' };
    const actions = useActions('clients');
    const fetchClients = actions.fetchClients;
    const fetchClientsRef = useRef(fetchClients);
    const {
        testAccountId,
        prodAccountId,
        urlAccountId,
    } = useAccountIds();
    const hasScopes = useHasAnyOfScopes(['read:accounts', 'admin:accounts']);
    const { clients, isLoading, grants, status } = useSelector(getClientsSelector);

    const accountId = getEnvironmentAccountId(environment, testAccountId, prodAccountId, urlAccountId);

    useEffect(() => {
        if (accountId && clients.length === 0 && !isLoading && hasScopes && status === -1) {
            fetchClientsRef.current(accountId);
        }
    }, [accountId, clients.length, isLoading, hasScopes, status]);
    const refresh = () => fetchClientsRef.current(accountId);
    return {
        isLoading,
        clients,
        grants,
        refresh,
    };
};

export default useApiClients;
