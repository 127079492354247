import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

import Icon from '../../components/Icons';
import { Attachment } from '../settlementList/types';

const attachmentIconMap = new Map<string, 'xml' | 'pdf' | 'tabular' | 'excel'>([
    ['.xml', 'xml'],
    ['.pdf', 'pdf'],
    ['.xsl', 'tabular'],
    ['.xlsx', 'excel'],
    ['.xls', 'excel'],
    ['.csv', 'tabular'],
]);

interface AttachmentLinkProps {
    attachment: Attachment;
    download: (attachment: Attachment) => void;
}

export const AttachmentLink = (
    {
        attachment,
        download,
    }: AttachmentLinkProps
) => {
    const { t } = useTranslation();
    const handleClick = () => {
        download(attachment);
    };
    const iconType = attachmentIconMap.get(attachment.extension);
    if (!iconType) {
        return null;
    }
    return (
        <AttachmentIcon
            onClick={handleClick}
            pointer={true}
            icon={iconType}
            title={t(`settlements.download${attachment.extension}`)}
        />
    );
};

export const AttachmentIcon = styled(Icon)`
    margin-left: 2px;
    font-size: 1.3em;
    width: unset !important;
`;
