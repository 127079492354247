import { CORE_API_HOSTNAME } from '../../env';
import fulfill from '../../fulfill';
import { download } from './download';

export const downloadDataTablePdf = async (
    accountId: string,
    title: string,
    created_at: string,
    data: any,
    language: string,
    filename: string,
    table_font_size?: number
) => {
    const reportURL = new URL(`${CORE_API_HOSTNAME}/v1/accounts/${accountId}/reports/generation`);
    const params = reportURL.searchParams;
    params.append('template_id', 'generic_data_table');
    params.append('template_data_path', `generic_data_table`);
    params.append('template_accept', 'application/pdf');
    params.append('template_accept_language', language || 'en');
    await fulfill.post({
        accountId,
        url: reportURL.toString(),
        json: [
            {
                key: 'generic_data_table',
                path: `generic_data_table`,
                data: {
                    title,
                    created_at,
                    data,
                    table_font_size,
                },
            },
        ],
        handlers: {
            200: (blob: Blob) => {
                download(`${filename}.pdf`, blob);
            },
        },
    });
};
