import React, { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import Hr from '../../../../components/Hr';
import { border, colors, distances, globalColumnMaxWidth, grid } from '../../../../styles/constants';
import Page, { Back, Dismiss, PageButton } from '../../../../components/Page';
import { Table, Thead, Tbody, Tr, Th, Td } from '../../../../components/Table';
import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import { H1, H2 } from '../../../../components/Typography';
import Icon from '../../../../components/Icons';
import NotFound from './NotFound';
import { State as ClientState } from '../reducer';
import { Checkbox, BackButton } from '../../../../components/Forms';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { AuthenticatedAccountUser, Grant } from '../../../../types/management-auth';
import { DOCUMENTED_CORE_API_HOSTNAME } from '../../../../env';
import { ValidationState } from '../../../../components/Forms/validation';
import ClientTypeDropdown from '../../components/ClientTypeDropdown';
import ClientAudienceDropdown, { ClientAudienceDropdownProps } from '../../components/ClientAudienceDropdown';
import {
    services,
    isCheckedDiscrete,
    handleWriteReadScopeToggle,
    hasAllDiscrete,
    toggleAllWriteReadAdmin
} from '../../../scopes';

interface MatchParams {
    accountId: string;
    clientId: string;
}

export interface EditClientGrantProps extends WithTranslation, RouteComponentProps<MatchParams> {
    getClient: (accountId: string, clientId: string) => void;
    createGrant: (accountId: string, clientId: string, grant: Grant) => void;
    resetForm: () => void;
    client: ClientState;
    grants: Grant[];
    accountUser: AuthenticatedAccountUser;
}

interface FormikErrors {
    type?: string;
    audience?: string;
}

interface FormikValues {
    scope: string[];
    type: 'any' | 'authorization_code' | 'client_credentials' | 'password';
    audience: string;
}

class EditClientGrant extends Component<EditClientGrantProps> {
    componentDidMount() {
        const { accountId, clientId } = this.props.match.params;
        this.props.getClient(accountId, clientId);
    }
    componentWillUnmount() {
        this.props.resetForm();
    }

    render() {
        const { t, createGrant, accountUser } = this.props;
        const { isLoading, client, grants, isCreated } = this.props.client;
        const { accountId, clientId } = this.props.match.params;

        const currentAccount = accountUser.accounts.find((account) => account.account_id === accountId);
        if (!currentAccount) {
            return null;
        }

        if (isCreated) {
            return <Redirect to={`/${accountId}/settings/clients/${clientId}`} />;
        }

        const audienceRoot = `${DOCUMENTED_CORE_API_HOSTNAME}/v1/accounts`;
        const initialAudience = `${DOCUMENTED_CORE_API_HOSTNAME}/v1/accounts/${accountId}`;

        const takenAudiences = grants.filter((grant) => grant.deleted_at === undefined).map((grant) => grant.audience);

        if (!isLoading && !client) {
            return (
                <Page
                    title={
                        <React.Fragment>
                            <Back />
                            <Title>{t('settings.new_client_grant.title')}</Title>
                        </React.Fragment>
                    }
                >
                    <Card title={undefined} noPadding>
                        <Wrapper>
                            <NotFound clientId={clientId} accountId={accountId} />
                        </Wrapper>
                    </Card>
                </Page>
            );
        }
        return (
            <Formik
                enableReinitialize
                initialValues={
                    {
                        scope: [],
                        type: 'any',
                        audience: initialAudience,
                    } as FormikValues
                }
                validate={(values) => {
                    const errors: FormikErrors = {};

                    if (!['any', 'authorization_code', 'client_credentials', 'password'].includes(values.type)) {
                        errors.type = t('settings.new_client_grant.validation.type_invalid');
                    }
                    if (!values.audience) {
                        errors.audience = t('settings.new_client_grant.validation.audience_required');
                    }
                    if (values.audience && !values.audience.startsWith(audienceRoot)) {
                        errors.audience = t('settings.new_client_grant.validation.audience_invalid', {
                            audience_root: audienceRoot,
                        });
                    }
                    if (values.audience && takenAudiences.includes(values.audience)) {
                        errors.audience = t('settings.new_client_grant.validation.audience_already_exists');
                    }

                    return errors;
                }}
                validateOnChange={false}
                onSubmit={(values) => {
                    const grantClientId = client ? client.client_id || '' : '';
                    const newGrant: Grant = {
                        client_id: grantClientId,
                        type: values.type,
                        audience: values.audience,
                        scope: values.scope,
                    };
                    createGrant(accountId, grantClientId, newGrant);
                }}
            >
                {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
                    <Form onSubmit={handleSubmit} autoComplete="nope">
                        <Page
                            title={
                                <React.Fragment>
                                    <Dismiss />
                                    <Title>{t('settings.new_client_grant.title')}</Title>
                                    <PageButton disabled={!(Object.keys(errors).length === 0)} type="submit">
                                        <Icon icon="save" fill="currentColor" />
                                        {t('settings.new_client_grant.update_client_grant')}
                                    </PageButton>
                                </React.Fragment>
                            }
                        >
                            <Card title={undefined}>
                                <Wrapper>
                                    <FormGroup>
                                        <H2>{t('settings.new_client_grant.grant')}</H2>
                                        <Hr />
                                        <Flex>
                                            <InputWrapper>
                                                <ClientAudienceDropdown
                                                    {...({
                                                        label: t('settings.new_client_grant.fields.audience'),
                                                        placeholder: 'settings.new_client_grant.placeholders.audience',
                                                        name: 'audience',
                                                        value: values.audience,
                                                        onChange: (value: string) => setFieldValue('audience', value),
                                                        audienceRoot: audienceRoot,
                                                        validation: {
                                                            state: errors.audience
                                                                ? ValidationState.Invalid
                                                                : ValidationState.Pristine,
                                                            message: errors.audience,
                                                        },
                                                    } as unknown as ClientAudienceDropdownProps)}
                                                />
                                            </InputWrapper>
                                            <InputWrapper>
                                                <ClientTypeDropdown
                                                    label={t('settings.new_client_grant.fields.type')}
                                                    placeholder={t('settings.new_client_grant.placeholders.type')}
                                                    name="type"
                                                    value={values.type}
                                                    onChange={(value) => setFieldValue('type', value)}
                                                    validation={{
                                                        state: errors.type
                                                            ? ValidationState.Invalid
                                                            : ValidationState.Pristine,
                                                        message: errors.type,
                                                    }}
                                                />
                                            </InputWrapper>
                                        </Flex>
                                    </FormGroup>
                                    <FormGroup>
                                        <H2>{t('settings.new_client_grant.scope')}</H2>
                                        <Hr />
                                        <Table>
                                            <Thead>
                                                <Tr>
                                                    <Th>{t('settings.scopes.service')}</Th>
                                                    <Th>{t('settings.scopes.description')}</Th>
                                                    <Th className="align-center">{t('settings.scopes.admin')}</Th>
                                                    <Th className="align-center">{t('settings.scopes.write')}</Th>
                                                    <Th className="align-center">{t('settings.scopes.read')}</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {Object.entries(services).map(([service, access]) => (
                                                    <Tr key={service}>
                                                        <Scope>
                                                            <code>{service}</code>
                                                        </Scope>
                                                        <Description>
                                                            {t([`settings.scopes.service_descriptions.${service}`, ''])}
                                                        </Description>
                                                        <Access className="align-center">
                                                            {access.includes('admin') ? (
                                                                <Checkbox
                                                                    label=""
                                                                    name="scope"
                                                                    checked={isCheckedDiscrete(
                                                                        values.scope,
                                                                        service,
                                                                        'admin'
                                                                    )}
                                                                    value={`admin:${service}`}
                                                                    onChange={(e) =>
                                                                        setFieldValue(
                                                                            'scope',
                                                                            handleWriteReadScopeToggle(values.scope, e)
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </Access>
                                                        <Access className="align-center">
                                                            {access.includes('write') ? (
                                                                <Checkbox
                                                                    label=""
                                                                    name="scope"
                                                                    checked={isCheckedDiscrete(
                                                                        values.scope,
                                                                        service,
                                                                        'write'
                                                                    )}
                                                                    value={`write:${service}`}
                                                                    onChange={(e) =>
                                                                        setFieldValue(
                                                                            'scope',
                                                                            handleWriteReadScopeToggle(values.scope, e)
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                '-'
                                                            )}
                                                        </Access>
                                                        <Access className="align-center">
                                                            {access.includes('read') ? (
                                                                <Checkbox
                                                                    label=""
                                                                    name="scope"
                                                                    checked={isCheckedDiscrete(
                                                                        values.scope,
                                                                        service,
                                                                        'read'
                                                                    )}
                                                                    value={`read:${service}`}
                                                                    onChange={(e) =>
                                                                        setFieldValue(
                                                                            'scope',
                                                                            handleWriteReadScopeToggle(values.scope, e)
                                                                        )
                                                                    }
                                                                />
                                                            ) : (
                                                                ''
                                                            )}
                                                        </Access>
                                                    </Tr>
                                                ))}
                                                <Tr>
                                                    <Td></Td>
                                                    <Td></Td>
                                                    <Td className="align-center">
                                                        <Button
                                                            className="alt tiny"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    'scope',
                                                                    toggleAllWriteReadAdmin(values.scope, 'admin')
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            {t(
                                                                hasAllDiscrete(values.scope, 'admin')
                                                                    ? 'settings.scopes.disable_all'
                                                                    : 'settings.scopes.enable_all'
                                                            )}
                                                        </Button>
                                                    </Td>
                                                    <Td className="align-center">
                                                        <Button
                                                            className="alt tiny"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    'scope',
                                                                    toggleAllWriteReadAdmin(values.scope, 'write')
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            {t(
                                                                hasAllDiscrete(values.scope, 'write')
                                                                    ? 'settings.scopes.disable_all'
                                                                    : 'settings.scopes.enable_all'
                                                            )}
                                                        </Button>
                                                    </Td>
                                                    <Td className="align-center">
                                                        <Button
                                                            className="alt tiny"
                                                            onClick={() => {
                                                                setFieldValue(
                                                                    'scope',
                                                                    toggleAllWriteReadAdmin(values.scope, 'read')
                                                                );
                                                            }}
                                                            type="button"
                                                        >
                                                            {t(
                                                                hasAllDiscrete(values.scope, 'read')
                                                                    ? 'settings.scopes.disable_all'
                                                                    : 'settings.scopes.enable_all'
                                                            )}
                                                        </Button>
                                                    </Td>
                                                </Tr>
                                            </Tbody>
                                        </Table>
                                    </FormGroup>
                                    <ButtonGroup>
                                        <BackButton>{t('settings.new_client_grant.abort')}</BackButton>
                                        <Button disabled={!(Object.keys(errors).length === 0)} type="submit">
                                            {t('settings.new_client_grant.update_client_grant')}
                                        </Button>
                                    </ButtonGroup>
                                    {isLoading && <LoadingOverlay />}
                                </Wrapper>
                            </Card>
                        </Page>
                    </Form>
                )}
            </Formik>
        );
    }
}

const Form = styled.form`
    width: 100%;
`;

const Scope = styled(Td)`
    width: 20%;
`;
const Description = styled(Td)`
    width: 35%;
`;
const Access = styled(Td)`
    width: 15%;
`;
const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;



export default withTranslation()(EditClientGrant);
