import { useState } from 'react';
import { Select } from '../../../../components/SimpleSelect/Select';
import PayExDirectForm from './PayExDirectForm';
import { useTranslation } from 'react-i18next';

export default function PayExForm() {
    const { t } = useTranslation();
    const [type, setType] = useState<'direct'>('direct');

    return (
        <>
            <Select
                label={t(
                    'settings.payment_connections.payex_modal.fields.agreement'
                )}
                defaultValue={type}
                items={[
                    { value: 'direct', label: 'Direct' },
                ].map((x) => ({
                    ...x,
                    label: t('settings.payment_connections.payex_modal.agreement', {
                        context: x.value,
                    }),
                }))}
                onChange={(value) => setType(value as 'direct')}
            />
            {type === 'direct' && <PayExDirectForm />}
        </>
    );
}
