import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Formik } from 'formik';

import { colors, grid } from '../../../styles/constants';
import Page, { Dismiss } from '../../../components/Page';
import Card from '../../../components/Card';
import PrefillForm from './PrefillForm';
import { LoadingOverlay } from '../../../components/Loading';
import { H1 } from '../../../components/Typography';
import { ValidationState } from '../../../components/Forms';
import { Account, AccountCompany } from '../../../types/management-auth';
import { validate, validateRequired } from '../../../helpers/validation';
import { getValueAt } from '../../../helpers/getValueAt';
import { AccountIdsProps } from '../../../auth/accessToken/withAccountIds';

interface MatchParams {
    accountId: string;
}

export interface InitialCompanyInfoProps extends AccountIdsProps, WithTranslation, RouteComponentProps<MatchParams> {
    searchCompanyName: (partialName: string, accountId: string) => void;
    fillAccount: (account: Account) => void;
    possibleCompanies: any;
    isLoading: boolean;
    isEmbedded?: boolean;
    type: 'partner' | 'merchant' | undefined;
}

export interface FormValues {
    company: AccountCompany;
    prefilled_account?: Account;
    [key: string]: any;
}

class NewAccountStep1 extends Component<InitialCompanyInfoProps> {
    render() {
        const { t, searchCompanyName, isEmbedded, possibleCompanies, isLoading, urlAccountId, type } = this.props;

        const initialFormValues: FormValues = {
            company: {
                business_name: '',
                address: {
                    country: 'NO',
                    address_line: '',
                    postal_place: '',
                },
            },
        };


        return (
            <Formik
                validateOnMount={true}
                enableReinitialize
                initialValues={initialFormValues}
                onSubmit={values => {
                    if (!values) {
                        return;
                    }

                    const accountInitial = {
                        company: values.company,
                    };

                    const prefilledAccount: Account = {
                        ...accountInitial,
                        ...values.prefilled_account,
                        billing:
                            values.prefilled_account && values.prefilled_account.company
                                ? values.prefilled_account.company
                                : values.company,
                        type: 'account',
                        applicant: {
                            agreement: {},
                            email: '',
                        },
                    };
                    this.props.fillAccount(prefilledAccount);
                }}
                validate={values => {
                    let errors: { [key: string]: string } = {};
                    if (!values) {
                        // this should not happen but...
                        return { error: 'account not loaded yet...' };
                    }

                    const validationValues = { ...values };
                    const requiredBusinessName = validateRequired(
                        t('partner.new_account.invalid.company.business_name')
                    );

                    const validators = [
                        {
                            path: 'company.business_name',
                            validator: requiredBusinessName,
                        },
                    ];

                    if (getValueAt('company.address.country', values) === 'NO') {
                        validators.push({
                            path: 'prefilled_account',
                            validator: requiredBusinessName,
                        });
                    }

                    errors = validators.reduce((acc, elem) => {
                        return validate(elem.path, elem.validator, validationValues, acc);
                    }, errors);

                    return errors;
                }}
            >
                {({
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    touched,
                    setTouched,
                    isValid,
                }) => {
                    const hasChosenCountry = getValueAt('company.address.country', values) !== undefined;
                    const hasChosenNorway = getValueAt('company.address.country', values) === 'NO';

                    const businessNameValidation =
                        getValueAt('company.business_name', touched) && getValueAt('company.business_name', errors)
                            ? {
                                state: ValidationState.Invalid,
                                message: getValueAt('company.business_name', errors),
                            }
                            : undefined;

                    return (
                        <Form onSubmit={handleSubmit} autoComplete="nope">
                            {!isEmbedded &&
                                <Page
                                    title={
                                        <React.Fragment>
                                            <Dismiss />
                                            <Title>
                                                {t('partner.new_account.title', {
                                                    context: type,
                                                })}
                                            </Title>
                                        </React.Fragment>
                                    }
                                >
                                    <Card title={undefined}>
                                        <Wrapper>
                                            <PrefillForm
                                                values={values}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                setTouched={setTouched}
                                                touched={touched}
                                                handleBlur={handleBlur}
                                                hasChosenNorway={hasChosenNorway}
                                                hasChosenCountry={hasChosenCountry}
                                                businessNameValidation={businessNameValidation}
                                                errors={errors}
                                                isValid={isValid}
                                                searchCompanyName={searchCompanyName}
                                                possibleCompanies={possibleCompanies}
                                                accountId={urlAccountId}
                                                accountType={type}
                                            />
                                            {isLoading && <LoadingOverlay />}
                                        </Wrapper>
                                    </Card>
                                </Page>
                            }
                            {!!isEmbedded &&
                            <PrefillForm
                                values={values}
                                handleChange={handleChange}
                                setFieldValue={setFieldValue}
                                setTouched={setTouched}
                                touched={touched}
                                handleBlur={handleBlur}
                                hasChosenNorway={hasChosenNorway}
                                hasChosenCountry={hasChosenCountry}
                                businessNameValidation={businessNameValidation}
                                errors={errors}
                                isValid={isValid}
                                searchCompanyName={searchCompanyName}
                                possibleCompanies={possibleCompanies}
                                accountId={urlAccountId}
                                accountType={type}
                            />
                            }
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

const Form = styled.form`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    width: 100%;
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default withTranslation()(NewAccountStep1);
