import { Actions, ActionTypes } from './actions';
import { Account } from '../../types/management-auth';
import { PricePackage } from '../../types/management-auth/generated';

export interface State {
    isLoading: boolean;
    account: Account | undefined;
    isCreated: boolean;
    possibleCompanies: Account[];
    isPrefilled: boolean;
    newAccount: Account | undefined;
    partnerLink: string | undefined;
    type: 'partner' | 'merchant' | undefined;
    pricePackages: PricePackage[];
}

export const initialState: State = {
    isLoading: false,
    account: undefined,
    isCreated: false,
    isPrefilled: false,
    possibleCompanies: [],
    newAccount: undefined,
    partnerLink: undefined,
    type: undefined,
    pricePackages: [],
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.ResetForm:
            return {
                ...initialState,
            };

        case ActionTypes.CreateAccount:
            return {
                ...state,
                isLoading: true,
                newAccount: action.payload.account,
            };

        case ActionTypes.CreateAccountResponse:
            return {
                ...state,
                isLoading: false,
                isCreated: true,
                partnerLink: action.payload.partnerLink,
                ...(state.type === 'merchant' && { newAccount: action.payload.account }),
            };

        case ActionTypes.SearchCompanyNameResponse:
            return {
                ...state,
                possibleCompanies: action.payload.accounts,
            };

        case ActionTypes.FillAccount:
            return {
                ...state,
                account: action.payload.account,
                isPrefilled: true,
            };

        case ActionTypes.GetPricePackagesResponse:
            return {
                ...state,
                pricePackages: action.payload.pricePackages,
            };

        case ActionTypes.GetAccountType:
            return {
                ...state,
                type: action.payload.type,
            };

        default:
            return state;
    }
}
