import { connect } from 'react-redux';

import { createAccount, resetForm, searchCompanyName, fillAccount, getPricePackages, getAccountType } from '../actions';
import { State } from '../../../reducer';
import { getAccountSelector } from '../selectors';
import NewAccountPage from '../components/NewAccountPage';

const mapStateToProps = (state: State) => ({
    account: getAccountSelector(state),
});

const mapDispatchToProps = {
    createAccount,
    resetForm,
    searchCompanyName,
    fillAccount,
    getAccountType,
    getPricePackages,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewAccountPage);
