import React from 'react';

type TotpProps = {
    fill?: string;
    secondaryfill?: string;
}

const Totp = (props: TotpProps) => {
    const fill = props.fill || 'currentColor';
    const secondaryfill = props.secondaryfill || fill;

    return (
        <svg height="24" width="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <g fill={fill}>
                <path d="M22,24H13a2,2,0,0,1-2-2V11a2,2,0,0,1,2-2h9a2,2,0,0,1,2,2V22A2,2,0,0,1,22,24ZM13,11h0V21h9V11Z" fill={secondaryfill}/>
                <path d="M9,18H2V2H16V7h2V2a2,2,0,0,0-2-2H2A2,2,0,0,0,0,2V20a2,2,0,0,0,2,2H9Z" fill={fill}/>
            </g>
        </svg>
    );
};

export default Totp;
