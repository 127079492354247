import React from 'react';
import { BillingPayoutRuleDestination } from '../../../../types/orders/generated';
import {
    FormGroup,
    HalfNHalf,
    InputWrapper,
    OverallError,
    TranslatedValidatedInput,
    TranslatedValidatedLocationSelect
} from './common';
import DestinationTypeDropdown from './DestinationTypeDropdown';
import { useTranslation } from 'react-i18next';
import { FieldArray, FieldArrayRenderProps, FormikErrors } from 'formik';
import { Button } from '../../../../components/Buttons';
import { BillingPayoutRuleDestinationNested } from '../../../../types/billing/generated';
import styled from 'styled-components/macro';
import { colors, distances } from '../../../../styles/constants';
import { PayoutRuleFormikErrors, PayoutRuleFormikValues } from './types';
import { getValueAt } from '../../../../helpers/getValueAt';
import { SalesLocation } from '../../../../types/customers';
import { getLocaleNumeralDecimal, getLocaleNumeralDelimiter } from '../../../../helpers/intl';

interface DestinationProps {
    destination: BillingPayoutRuleDestination | BillingPayoutRuleDestinationNested;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    index: number;
    path: string;
    arrayHelpers: FieldArrayRenderProps;
    levels: 1 | 2 | 3 | 4;
    values: PayoutRuleFormikValues;
    errors: FormikErrors<PayoutRuleFormikErrors>;
    touched: any;
    locations: SalesLocation[];
    locationNames: { [id: string]: string };
    setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
}

type DefaultDestinationProps = (
    currentDestinations: BillingPayoutRuleDestination[] | undefined
) => BillingPayoutRuleDestination;
export const defaultDestination: DefaultDestinationProps = (currentDestinations) => {
    const base = {
        destination: '',
        destinations: undefined,
    };
    if (!currentDestinations) {
        return {
            ...base,
            type: 'percentage',
            value: '100',
        };
    }
    if (currentDestinations.every((dest) => dest.type === 'percentage')) {
        const percentageSum = currentDestinations.reduce((acc, dest) => acc + parseFloat(dest.value || '0'), 0);
        return {
            ...base,
            type: 'percentage',
            value: (100 - percentageSum).toString(),
        };
    }
    if (currentDestinations.length > 0) {
        return {
            ...base,
            type: 'remaining_amount',
        };
    }
    return {
        ...base,
        type: 'percentage',
        value: '100',
    };
};

const Destination = (
    {
        destination,
        setFieldValue,
        index,
        onBlur,
        onChange,
        arrayHelpers,
        levels,
        path,
        values,
        errors,
        touched,
        setFieldTouched,
        locations,
        locationNames,
    }: DestinationProps
) => {
    const { t, i18n } = useTranslation();
    const allowNesting = levels > 1;
    const lng = i18n.language;

    const overallDestinationsError = getValueAt(`${path}.destinations_overall`, errors);

    return (
        <DestinationContainer>
            <DestinationFormContainer>
                <HalfNHalf>
                    <InputWrapper>
                        <DestinationTypeDropdown
                            label={t('payout_rules.new_payout_rule.fields.destination_type')}
                            placeholder={t('payout_rules.new_payout_rule.placeholders.destination_type')}
                            name={`${path}.type`}
                            value={destination.type || null}
                            onChange={(value) => {
                                setFieldValue(`${path}.type`, value, true);
                                if (value === 'remaining_amount') {
                                    setFieldValue(`${path}.value`, undefined, true);
                                }
                            }}
                        />
                    </InputWrapper>
                    {destination.type === 'percentage' && (
                        <TranslatedValidatedInput
                            label={'payout_rules.new_payout_rule.fields.destination_value'}
                            placeholder={`payout_rules.new_payout_rule.placeholders.destination_value.${destination.type}`}
                            path={`${path}.value`}
                            options={{
                                numeral: true,
                                blocks: [3],
                                numeralThousandsGroupStyle: 'thousand',
                                delimiter: getLocaleNumeralDelimiter(lng),
                                numeralDecimalMark: getLocaleNumeralDecimal(lng),
                                numeralPositiveOnly: true,
                            }}
                            onBlur={onBlur}
                            onChange={onChange}
                            required={true}
                            values={values}
                            errors={errors}
                            touched={touched}
                            autoFocus={true}
                        />
                    )}
                    {destination.type === 'flat_amount' && (
                        <TranslatedValidatedInput
                            label={'payout_rules.new_payout_rule.fields.destination_value'}
                            placeholder={`payout_rules.new_payout_rule.placeholders.destination_value.${destination.type}`}
                            path={`${path}.value`}
                            options={{
                                numericOnly: true,
                                numeral: true,

                                numeralThousandsGroupStyle: 'thousand',
                                delimiter: getLocaleNumeralDelimiter(lng),
                                numeralDecimalMark: getLocaleNumeralDecimal(lng),
                                numeralPositiveOnly: true,
                                numeralDecimalScale: 2,

                            }}
                            onBlur={onBlur}
                            onChange={onChange}
                            required={true}
                            values={values}
                            errors={errors}
                            touched={touched}
                            autoFocus={true}
                        />
                    )}
                </HalfNHalf>
                {(!destination.destinations || destination.destinations.length === 0) && (
                    <InputWrapper>
                        <TranslatedValidatedLocationSelect
                            path={`${path}.destination`}
                            label={'payout_rules.new_payout_rule.fields.destination_destination'}
                            placeholder={'payout_rules.new_payout_rule.placeholders.destination_destination'}
                            values={values}
                            errors={errors}
                            onBlur={onBlur}
                            touched={touched}
                            required
                            setFieldValue={setFieldValue}
                            locations={locations}
                            locationNames={locationNames}
                            setFieldTouched={setFieldTouched}
                        />
                    </InputWrapper>
                )}
                <Actions>
                    <StyledButtonGroup>
                        {allowNesting && (
                            <Button
                                type="button"
                                className="alt"
                                onClick={() => {
                                    const newSubDestinations = [
                                        ...(destination.destinations || []),
                                        defaultDestination(destination.destinations as BillingPayoutRuleDestination[]),
                                    ];
                                    setFieldValue(`${path}.destinations`, newSubDestinations);
                                    setFieldValue(`${path}.destination`, undefined);
                                }}
                            >
                                + {t('payout_rules.new_payout_rule.add_substep')}
                            </Button>
                        )}
                        <Button type="button" className="alt" onClick={() => arrayHelpers.remove(index)}>
                            {t('payout_rules.new_payout_rule.remove_step')}
                        </Button>
                    </StyledButtonGroup>
                </Actions>
            </DestinationFormContainer>
            {allowNesting && destination.destinations && (
                <FieldArray
                    name={`${path}.destinations`}
                    render={(subArrayHelpers) => {
                        const subDestinations = (destination as BillingPayoutRuleDestination).destinations || [];
                        return (
                            <SubDestinationsContainer>
                                {subDestinations.map((subDestination, idx) => {
                                    return (
                                        <Destination
                                            key={idx}
                                            destination={subDestination}
                                            setFieldValue={setFieldValue}
                                            onBlur={onBlur}
                                            onChange={onChange}
                                            arrayHelpers={subArrayHelpers}
                                            index={idx}
                                            levels={(levels - 1) as 1 | 2 | 3 | 4}
                                            path={`${path}.destinations.${idx}`}
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            setFieldTouched={setFieldTouched}
                                            locations={locations}
                                            locationNames={locationNames}
                                        />
                                    );
                                })}
                            </SubDestinationsContainer>
                        );
                    }}
                />
            )}
            {overallDestinationsError && <OverallError message={overallDestinationsError} />}
        </DestinationContainer>
    );
};

const DestinationContainer = styled.div`
    margin-bottom: ${distances.tiny};
`;

const DestinationFormContainer = styled(FormGroup)`
    background-color: ${colors.backgroundAlt};
    padding: ${distances.normal};
    margin-bottom: ${distances.tiny};
    border-margin: 2px;
`;

const SubDestinationsContainer = styled.div`
    margin-left: ${distances.normal};
`;

const Actions = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const StyledButtonGroup = styled.div`
    background-color: ${colors.background};

    > a,
    > button,
    > div,
    > input {
        margin-right: 0;
        margin-bottom: 0;
        &::last-child {
            margin-right: 0;
        }
    }
`;

export default Destination;
