import { reducer as locationDetailsReducer, namespace as locationDetailsNamespace } from './locationDetails';
import { reducer as newLocationReducer, namespace as newLocationNamespace } from './newLocation';

export interface State {
    [locationDetailsNamespace]: locationDetailsReducer.State;
    [newLocationNamespace]: newLocationReducer.State;
}

export const initialState: State = {
    [locationDetailsNamespace]: locationDetailsReducer.initialState,
    [newLocationNamespace]: newLocationReducer.initialState,
};

export const reducers = {
    [locationDetailsNamespace]: locationDetailsReducer.reducer,
    [newLocationNamespace]: newLocationReducer.reducer,
};
