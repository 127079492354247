import { Actions, ActionTypes } from './actions';
import { BillingPayoutRule } from '../../../types/billing';
import { SalesLocation } from '../../../types/customers';

export interface State {
    isLoading: boolean;
    result: BillingPayoutRule | undefined;
    locations: SalesLocation[] | undefined;
}

export const initialState: State = {
    isLoading: false,
    result: undefined,
    locations: undefined,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.CreatePayoutRule:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CreatePayoutRuleResponse:
            return {
                ...state,
                isLoading: false,
                result: action.payload.payoutRule,
            };

        case ActionTypes.ResetForm:
            return {
                ...initialState,
            };

        case ActionTypes.FetchLocationsResponse:
            return {
                ...state,
                locations: action.payload,
            };

        default:
            return state;
    }
}
