import { Actions, ActionTypes } from './actions';
import { HasSecretClientResponse, Grant, Account } from '../../../types/management-auth';

export interface State {
    isLoading: boolean;
    client: HasSecretClientResponse | undefined;
    grants: Grant[] | undefined;
    isSecretSafe: boolean;
    account: Account | undefined;
}

export const initialState: State = {
    isLoading: false,
    client: undefined,
    grants: undefined,
    isSecretSafe: false,
    account: undefined,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.GetAccount:
            return {
                ...initialState,
                isLoading: true,
            };

        case ActionTypes.GetAccountResponse:
            return {
                ...state,
                isLoading: false,
                account: action.payload.account,
            };

        case ActionTypes.CreateClient:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CreateClientResponse:
            return {
                ...state,
                isLoading: true,
                client: action.payload.client,
            };

        case ActionTypes.CreateGrantsResponse:
            return {
                ...state,
                isLoading: false,
                grants: action.payload.grants,
            };

        case ActionTypes.ToggleSecretIsSafe:
            return {
                ...state,
                isSecretSafe: action.payload.value,
            };

        case ActionTypes.ResetForm:
            return {
                ...initialState,
            };

        default:
            return state;
    }
}
