import * as React from 'react';
import { formatPhoneNumber } from '../../helpers/formatters';

interface DateTimeProps {
    phoneNumber: string;
}

const PhoneNumber = (
    {
        phoneNumber,
    }: DateTimeProps
) => <>{formatPhoneNumber(phoneNumber)}</>;

export default PhoneNumber;
