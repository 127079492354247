import { AuthenticatedUser } from '../types';

export enum ActionTypes {
    SetAuthenticatedUser = '[auth] SET_AUTHENTICATED_USER',
    SetInspectAuthenticatedUser = '[auth] SET_INSPECT_AUTHENTICATED_USER',
    SetLeaveInspect = '[auth] SET_LEAVE_INSPECT',
    SetSelectedAccount = '[auth] SET_SELECTED_ACCOUNT',
    LoadTokens = '[auth] GET_TOKEN',
    FetchTokenResponse = '[auth] FETCH_TOKEN_RESPONSE',
    UpdateAuthenticatedUser = '[auth] UPDATE_AUTHENTICATED_USER',
    UpdateAuthenticatedUserResult = '[auth] UPDATE_AUTHENTICATED_USER_RESULT',
    LoadSubAccountTokens = '[auth] GET_SUB_ACCOUNT_TOKENS',
    FetchSubAccountTokensResponse = '[auth] FETCH_SUB_ACCOUNTS_TOKENS_RESPONSE',
    OpenChangePassword = '[auth] OPEN_CHANGE_PASSWORD',
    ChangePassword = '[auth] CHANGE_PASSWORD',
    ChangePasswordResponse = '[auth] CHANGE_PASSWORD_RESPONSE',
    OpenAuthenticatedUserSettings = '[auth] OPEN_AUTHENTICATED_USER_SETTINGS',
    ChangeAuthenticatedUserMfaSms = '[auth] CHANGE_AUTHENTICATED_USER_MFA_SMS',
    ChangeAuthenticatedUserMfaTotp = '[auth] CHANGE_AUTHENTICATED_USER_MFA_TOTP',
    ChangeAuthenticatedUserMfaSmsResponse = '[auth] CHANGE_AUTHENTICATED_USER_MFA_SMS_RESPONSE',
    ChangeAuthenticatedUserMfaTotpResponse = '[auth] CHANGE_AUTHENTICATED_USER_MFA_TOTP_RESPONSE',
}

export interface SetAuthenticatedUser {
    type: ActionTypes.SetAuthenticatedUser;
    payload: {
        accountUser: AuthenticatedUser;
        exchangeTokenPartnerId: string | undefined;
    };
}

export function setAuthenticatedUser(
    accountUser: AuthenticatedUser,
    exchangeTokenPartnerId: string | undefined = undefined
): SetAuthenticatedUser {
    return {
        type: ActionTypes.SetAuthenticatedUser,
        payload: {
            accountUser,
            exchangeTokenPartnerId,
        },
    };
}

export interface SetInspectAuthenticatedUser {
    type: ActionTypes.SetInspectAuthenticatedUser;
    payload: {
        accountUser: AuthenticatedUser;
        exchangeTokenPartnerId: string;
    };
}

export function setInspectAuthenticatedUser(
    accountUser: AuthenticatedUser,
    exchangeTokenPartnerId: string
): SetInspectAuthenticatedUser {
    return {
        type: ActionTypes.SetInspectAuthenticatedUser,
        payload: {
            accountUser,
            exchangeTokenPartnerId,
        },
    };
}

export interface SetLeaveInspect {
    type: ActionTypes.SetLeaveInspect;
    payload: {};
}

export function setLeaveInspect(): SetLeaveInspect {
    return {
        type: ActionTypes.SetLeaveInspect,
        payload: {},
    };
}

export interface LoadTokens {
    type: ActionTypes.LoadTokens;
    payload: {
        accountId: string;
        showLoading: boolean;
    };
}

export function loadTokens(accountId: string, showLoading = true): LoadTokens {
    return {
        type: ActionTypes.LoadTokens,
        payload: {
            accountId,
            showLoading,
        },
    };
}

export interface FetchTokenResponse {
    type: ActionTypes.FetchTokenResponse;
    payload: {
        accounts: {
            accountId: string;
            token: string;
        }[];
    };
}

export interface UpdateAuthenticatedUser {
    type: ActionTypes.UpdateAuthenticatedUser;
    payload: {
        accountId: string;
    };
}

export function updateAuthenticatedUser(accountId: string): UpdateAuthenticatedUser {
    return {
        type: ActionTypes.UpdateAuthenticatedUser,
        payload: {
            accountId,
        },
    };
}

export interface UpdateAuthenticatedUserResult {
    type: ActionTypes.UpdateAuthenticatedUserResult;
    payload: AuthenticatedUser;
    meta: {
        cause: UpdateAuthenticatedUser;
    };
}

export interface LoadSubAccountTokens {
    type: ActionTypes.LoadSubAccountTokens;
    payload: {
        accountId: string;
        subAccountId: string;
    };
}

export function loadSubAccountTokens(accountId: string, subAccountId: string): LoadSubAccountTokens {
    return {
        type: ActionTypes.LoadSubAccountTokens,
        payload: {
            accountId,
            subAccountId,
        },
    };
}

export interface FetchSubAccountTokensResponse {
    type: ActionTypes.FetchSubAccountTokensResponse;
    payload: {
        accounts: {
            accountId: string;
            token: string;
        }[];
    };
}

export interface OpenChangePassword {
    type: ActionTypes.OpenChangePassword;
    payload: {};
}

export function openChangePasswordModal(): OpenChangePassword {
    return {
        type: ActionTypes.OpenChangePassword,
        payload: {},
    };
}

export interface ChangePassword {
    type: ActionTypes.ChangePassword;
    payload: {
        previous_password: string;
        proposed_password: string;
    };
}

export function changePassword(previous_password: string, proposed_password: string): ChangePassword {
    return {
        type: ActionTypes.ChangePassword,
        payload: {
            previous_password,
            proposed_password,
        },
    };
}

export interface ChangePasswordResponse {
    type: ActionTypes.ChangePasswordResponse;
    payload: {
        result: 'success' | 'wrong_password' | 'error' | 'rate_limit';
    };
}

export interface OpenAuthenticatedUserSettings {
    type: ActionTypes.OpenAuthenticatedUserSettings;
    payload: {};
}

export function openAuthenticatedUserSettingsModal(): OpenAuthenticatedUserSettings {
    return {
        type: ActionTypes.OpenAuthenticatedUserSettings,
        payload: {},
    };
}

export interface ChangeAuthenticatedUserMfaSms {
    type: ActionTypes.ChangeAuthenticatedUserMfaSms;
    payload: {
        enabled: boolean;
        phone_number?: string;
    };
}

export interface ChangeAuthenticatedUserMfaTotp {
    type: ActionTypes.ChangeAuthenticatedUserMfaTotp;
    payload: {
        enabled: boolean;
        user_code?: string;
    };
}

export function changeMfaSms(enabled: boolean, phone_number?: string): ChangeAuthenticatedUserMfaSms {
    return {
        type: ActionTypes.ChangeAuthenticatedUserMfaSms,
        payload: enabled
            ? {
                enabled,
                phone_number,
            }
            : { enabled },
    };
}

export function changeMfaTotp(enabled: boolean, user_code?: string): ChangeAuthenticatedUserMfaTotp {
    return {
        type: ActionTypes.ChangeAuthenticatedUserMfaTotp,
        payload: enabled
            ? {
                enabled,
                user_code,
            }
            : { enabled },
    };
}

export interface ChangeAuthenticatedUserMfaSmsResponse {
    type: ActionTypes.ChangeAuthenticatedUserMfaSmsResponse;
    payload: {
        result: 'success' | 'invalid_phone' | 'error' | 'rate_limit';
    };
}

export interface ChangeAuthenticatedUserMfaTotpResponse {
    type: ActionTypes.ChangeAuthenticatedUserMfaTotpResponse;
    payload: {
        result: 'success' | 'invalid_user_code' | 'error' | 'rate_limit';
    };
}

export type Actions =
    | SetAuthenticatedUser
    | SetInspectAuthenticatedUser
    | SetLeaveInspect
    | LoadTokens
    | FetchTokenResponse
    | UpdateAuthenticatedUserResult
    | LoadSubAccountTokens
    | FetchSubAccountTokensResponse
    | OpenChangePassword
    | ChangePassword
    | ChangePasswordResponse
    | OpenAuthenticatedUserSettings
    | ChangeAuthenticatedUserMfaSms
    | ChangeAuthenticatedUserMfaSmsResponse
    | ChangeAuthenticatedUserMfaTotp
    | ChangeAuthenticatedUserMfaTotpResponse;
