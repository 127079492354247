import { Subscription } from '../../../types/webhooks';

export enum ActionTypes {
    GetHooks = '[settings/hooks] GET_HOOKS',
    FetchHooksResponse = '[settings/hooks] FETCH_HOOKS_RESPONSE',
}

export interface GetHooks {
    type: ActionTypes.GetHooks;
    payload: {
        accountIds: string[];
    };
}

export interface FetchHooksResponse {
    type: ActionTypes.FetchHooksResponse;
    payload: {
        hooks: Subscription[];
    };
    meta: {
        cause: GetHooks;
    };
}

export function getHooks(accountIds: string[]): GetHooks {
    return {
        type: ActionTypes.GetHooks,
        payload: {
            accountIds,
        },
    };
}

export type Actions = GetHooks | FetchHooksResponse;
