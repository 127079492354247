import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { distances } from '../../../../styles/constants';
import ProfilePreview from '../../components/ProfilePreview';
import DetailsInfo from './DetailsInfo';
import { ProfileConfiguration, CheckoutConfig } from '../../../../types/checkout';

export interface ProfileDetailsProps extends WithTranslation {
    profile: ProfileConfiguration;
    configuration: CheckoutConfig;
    countryCode?: string;
}

const ProfileDetails = (
    {
        profile,
        configuration,
        countryCode,
    }: ProfileDetailsProps
) => {
    return (
        <Wrapper>
            <PreviewWrapper>
                <ProfilePreview profile={profile} configuration={configuration} countryCode={countryCode} />
            </PreviewWrapper>
            <DetailsInfo profile={profile} />
        </Wrapper>
    );
};

export default withTranslation()(ProfileDetails);

const Wrapper = styled.div`
    position: relative;
`;
const PreviewWrapper = styled.div`
    padding: ${distances.normal};
    margin: 0 auto;
`;
