import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import { useFormik } from 'formik';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Button } from '../../../components/Buttons';
import BorderInput from '../../../components/Forms/BorderInput';
import { P } from '../../../components/Typography';
import { ONBOARDING_HOSTNAME } from '../../../env';
import { validate, validateRequired } from '../../../helpers/validation';
import { colors, distances, palette } from '../../../styles/constants';
import { federatedSignin } from '../../cognito';
import { authenticate, redirectToApp } from '../../cognitoIdentityWrapper';
import type { MfaType, SelectMfaTypeOptions } from '../../types';
import { stringToSlug } from '../helpers';
import GoogleLogo from './google-g.svg';

interface LoginProps {
    username: string;
    setForgotPassword: (username: string) => void;
    setMfaRequired: (cognitoUser: AmazonCognitoIdentity.CognitoUser, mfaType: MfaType) => void;
    setSelectMfaRequired: (cognitoUser: AmazonCognitoIdentity.CognitoUser, options: SelectMfaTypeOptions) => void;
    setShake: (value: boolean) => void;
}

const getRedirectError = () => {
    try {
        const urlParams = new URLSearchParams(window.location.hash);
        if (urlParams.get('error')) {
            return 'redirect_generic';
        }
    } catch (e) {
        // ignore
    }
    return '';
};

const Login= ({ username, setForgotPassword, setMfaRequired, setSelectMfaRequired, setShake }: LoginProps) => {
    const { t } = useTranslation();

    const [loginError, setLoginError] = useState(getRedirectError());


    const formik = useFormik({
        initialValues: {
            username: username,
            password: '',
        },
        validate: (values) => {
            let errors: { [key: string]: string } = {};
            const validators = [
                {
                    path: 'username',
                    validator: validateRequired(t('login.invalid.username')),
                },
                {
                    path: 'password',
                    validator: validateRequired(t('login.invalid.password')),
                },
            ];
            errors = validators.reduce((acc, elem) => {
                return validate(elem.path, elem.validator, values, acc);
            }, errors);

            return errors;
        },
        onSubmit: async (values) => {
            try {
                const authenticateResult = await authenticate(values.username.toLowerCase(), values.password);
                if (authenticateResult.success) {
                    redirectToApp(authenticateResult.success);
                } else if (authenticateResult.mfaRequired && authenticateResult.selectMfaTypeOptions) {
                    setSelectMfaRequired(authenticateResult.mfaRequired, authenticateResult.selectMfaTypeOptions);
                } else if (authenticateResult.mfaRequired) {
                    setMfaRequired(authenticateResult.mfaRequired, authenticateResult.mfaType || 'SMS_MFA');
                } else {
                    throw new Error('Unexpected result');
                }
            } catch (error) {
                console.log('Error signing in.', error);
                setLoginError(error.message);
                setShake(true);
            }
        },
    });

    return (
        <Wrapper>
            <P
                fontSize={33}
                fontWeight={600}
                lineHeight={41}
                style={{
                    textAlign: 'center',
                    letterSpacing: -0.6,
                }}
            >{t('login.description')}</P>
            {loginError !== '' && <ErrorMessage visible={loginError !== ''}>
                {t('login.login_error', { context: stringToSlug(loginError) })}
            </ErrorMessage>}
            <div style={{ marginTop: distances.tiny }} id="signin-buttons">
                <Button
                    id="google-signin-button"
                    type="button"
                    className="stretch outlined"
                    onClick={() => {
                        federatedSignin.redirect(window);
                    }}
                >
                    <GoogleLogoImg src={GoogleLogo} alt="Google Logo" />
                    {t('login.actions.sign_in_with_google')}
                </Button>
            </div>
            <Form onSubmit={formik.handleSubmit}>
                <Separator>
                    <span>{t('login.or')}</span>
                </Separator>
                <InputWrapper>
                    <BorderInput
                        label={t('login.fields.username')}
                        placeholder={t(`login.placeholders.username`)}
                        type="email"
                        name="username"
                        autoComplete="username"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                    />
                    <BorderInput
                        label={t('login.fields.password')}
                        placeholder={t(`login.placeholders.password`)}
                        type="password"
                        name="password"
                        autoComplete="current-password"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        required
                    />
                </InputWrapper>
                <LogInButton type="submit" className="stretch" disabled={!formik.isValid || formik.isSubmitting}>
                    {t('login.actions.sign_in')}
                </LogInButton>
                <Button className="stretch outlined"
                    onClick={() => {
                        setForgotPassword(formik.values.username);
                    }}
                >
                    {t('login.actions.forgot_password')}
                </Button>
                <Trans i18nKey="login.actions.go_to_sign_up" parent={P} style={{
                    textAlign: 'center',
                }}>
                    <ExternalLink href={ONBOARDING_HOSTNAME} />
                </Trans>
            </Form>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--Spacing-3);
`;

const Form = styled.form`
    display: contents;
`;

const InputWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100% - 2px);

    &> *:first-child {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        &> *:first-child {
            border-top-left-radius: 6px;
        }
        &> *:last-child {
            border-top-right-radius: 6px;
        }
        &.phone-number{
            &> *:last-child {
                border-top-left-radius: 6px;
            }
        }
    }
    &> *:last-child {
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;

        &> *:first-child {
            border-bottom-left-radius: 6px;
        }
        &> *:last-child {
            border-bottom-right-radius: 6px;
        }

        &.phone-number{
            &> *:last-child {
                border-bottom-left-radius: 6px;
            }
        }
    }
    &>*{
        margin-top: -0.5px;
        margin-bottom: -0.5px;
    }
`;

const GoogleLogoImg = styled.img`
    margin-right: ${distances.tiny};
`;

const ExternalLink = styled.a`
    display: inline-block;
    color: ${colors.primary};
    user-select: none;
    cursor: pointer;
    text-decoration: none;

    &:hover {
        color: ${colors.primaryHover};
    }
`;

const LogInButton = styled(Button)`
    @media (max-width: 768px) {
        width: 100%;
        margin-right: 0;
    }
`;

const Separator = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    span {
        width: max-content;
        min-width: 40px;
        text-align: center;
        margin: 0 auto;
        color: ${palette.neutral[900]};
        text-align: center;
        font-size: 16.884px;
        font-style: normal;
        font-weight: 500;
        line-height: 26.264px; /* 155.556% */
    }

    &::before {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: ${palette.neutral[300]};
    }

    &::after {
        content: '';
        display: block;
        width: 100%;
        height: 1px;
        background-color: ${palette.neutral[300]};
    }

    @media (max-width: 768px) {
        margin-left: 0;
        margin-right: 0;
    }
`;

interface ErrorMessageProps {
    visible: boolean;
}

const ErrorMessage = styled.p<ErrorMessageProps>`
    text-align: center;
    font-size: 11px;
    line-height: ${distances.small};
    color: ${colors.invalid};
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export default Login;
