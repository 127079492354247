import React from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Tbody, Td, Th, Thead, Tr } from '../../../components/Table/Fake';
import { Button } from '../../../components/Buttons';
import Icon from '../../../components/Icons';
import { getListKey } from '../../../helpers/paging';
import { colors, distances } from '../../../styles/constants';
import { SettlementReportConfig } from '../../../reports/settlementList/types';
import styled from 'styled-components/macro';
import Filters from './Filters';

interface RowProps {
    configuration: SettlementReportConfig;
    accountId: string;
    openDeleteModal: (accountId: string, configuration: SettlementReportConfig) => void;
    openEditModal: (accountId: string, configuration: SettlementReportConfig) => void;
    accountBillingEmailAddress?: string;
    destinationIdLabelMap: {[destination_id: string]: string};
}

const Row = ({ configuration, accountId, openDeleteModal, openEditModal, accountBillingEmailAddress, destinationIdLabelMap }: RowProps) => {
    const { t } = useTranslation();
    const destinations = configuration.destinations.map(dest => {
        if (dest.destination_type === 'account_email') {
            return accountBillingEmailAddress;
        }
        return dest.destination_value;
    }).filter((dest): dest is string => !!dest);
    const providers = configuration.providers
        .map((provider) => t((`settings.settlement_report_config.providers.${provider}`) as any));
    return (
        <Tr className="stop-borders-before-edges">
            <Td>
                {providers.length > 0 && <UnstyledList>
                    {providers.map(provider => (
                        <li key={provider}>{provider}</li>
                    ))}
                </UnstyledList>}
                {providers.length === 0 && t(`settings.settlement_report_config.providers_all`)}
            </Td>
            <Td>
                <Filters filters={configuration.filters} destinationIdLabelMap={destinationIdLabelMap} />
            </Td>
            <Td>
                {destinations && destinations.length > 0 && <UnstyledList>{destinations.map(destination => (
                    <li key={destination}>{destination}</li>
                ))}</UnstyledList>}
                {(!destinations || destinations.length === 0) && <span>-</span>}
            </Td>
            <Td>
                {configuration.filetypes.length > 0 && <InlineList>{configuration.filetypes.map((filetype) => (
                    <li key={filetype}>
                        <code>{t(`settings.settlement_report_config.filetypes.${filetype}`).toLocaleLowerCase()}</code>
                    </li>
                ))}</InlineList>}
                {configuration.filetypes.length === 0 && t(`settings.settlement_report_config.filetypes_all`)}
            </Td>
            <Td className="center">
                <FixedWidthIconButton className="alt tiny" onClick={() => openEditModal(accountId, configuration)}>
                    <Icon icon="edit_pen" />
                </FixedWidthIconButton>
                <FixedWidthIconButton className="alt tiny" onClick={() => openDeleteModal(accountId, configuration)}>
                    <Icon icon="close_small" fill={colors.invalid} />
                </FixedWidthIconButton>
            </Td>
        </Tr>
    );
};

const FixedWidthIconButton = styled(Button)`
  width: 40px;
`;

export interface SessionTableProps {
    accountId: string;
    configurations: SettlementReportConfig[];
    openDeleteModal: (accountId: string, configuration: SettlementReportConfig) => void;
    openEditModal: (accountId: string, configuration: SettlementReportConfig) => void;
    accountBillingEmailAddress?: string;
    destinationIdLabelMap: {[destination_id: string]: string};
}

const ConfigurationsTable = (
    {
        configurations,
        accountId,
        openDeleteModal,
        openEditModal,
        accountBillingEmailAddress,
        destinationIdLabelMap,
    }: SessionTableProps
) => {
    const { t } = useTranslation();
    return (
        <Table className="no-wrap">
            <Thead>
                <Tr>
                    <Th>{t('settings.settlement_report_config.fields.providers')}</Th>
                    <Th>{t('settings.settlement_report_config.fields.filters')}</Th>
                    <Th>{t('settings.settlement_report_config.fields.destination')}</Th>
                    <Th>{t('settings.settlement_report_config.fields.filetypes')}</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            {configurations.length > 0 && (
                <Tbody>
                    {configurations.map((configuration) => (
                        <Row
                            key={getListKey(configuration)}
                            configuration={configuration}
                            accountId={accountId}
                            openDeleteModal={openDeleteModal}
                            openEditModal={openEditModal}
                            accountBillingEmailAddress={accountBillingEmailAddress}
                            destinationIdLabelMap={destinationIdLabelMap}
                        />
                    ))}
                </Tbody>
            )}
        </Table>
    );
};

const UnstyledList = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;

  > li {
    margin-bottom: ${distances.micro};
  }
`;

const InlineList = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;

  > li {
    display: inline-block;
    margin-right: ${distances.micro};
  }

  > li::after {
    content: ',';
  }

  > li:last-child::after {
    content: none;
  }
`;

export default ConfigurationsTable;
