import React, { Component } from 'react';
import styled from 'styled-components/macro';

import { grid, colors, border, distances, defaultRadius, palette } from '../../styles/constants';
import Icon from '../Icons';

const DEFAULT_DEBOUNCE_TIMEOUT_MS = 500;

export type OnChangeFunction = (event: Event) => void;

interface SearchProps {
    name: string;
    placeholder: string;
    defaultValue: string;
    onChange: (value: string) => void;
    onBlur: (value: string) => void;
    onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    hideIcon?: boolean;
    debounceTimeoutMs?: number;
    className?: string;
}

interface SearchState {
    searchValue: string;
    timeoutReference: number | undefined;
}

class Search extends Component<SearchProps, SearchState> {
    inputRef: React.RefObject<HTMLInputElement> | null = null;

    constructor(props: SearchProps) {
        super(props);
        this.setSearchChanged = this.setSearchChanged.bind(this);
        this.setSearchBlur = this.setSearchBlur.bind(this);
        this.state = {
            searchValue: props.defaultValue,
            timeoutReference: undefined,
        };
        this.inputRef = React.createRef();
    }

    setSearchChanged(e: React.FormEvent<HTMLInputElement>) {
        const { debounceTimeoutMs = DEFAULT_DEBOUNCE_TIMEOUT_MS } = this.props;
        const value = e.currentTarget.value;
        if (value !== this.state.searchValue) {
            window.clearTimeout(this.state.timeoutReference);
            this.setState({
                searchValue: value,
                timeoutReference: window.setTimeout(
                    () => {
                        this.props.onChange(value);
                        this.setState({ timeoutReference: undefined });
                    },
                    debounceTimeoutMs
                ),
            });
        }
    }

    setSearchBlur(e: React.FormEvent<HTMLInputElement>) {
        const value = e.currentTarget.value;
        if (value !== this.state.searchValue) {
            window.clearTimeout(this.state.timeoutReference);
            this.setState({
                searchValue: value,
                timeoutReference: undefined,
            });
            this.props.onBlur(value);
        }
    }

    componentDidUpdate(oldProps: SearchProps) {
        // if search is cleared from parent, then reset internal state and clear timeout
        if (oldProps.defaultValue && this.props.defaultValue === '' && this.state.searchValue !== '') {
            window.clearTimeout(this.state.timeoutReference);
            this.setState({ searchValue: '' });
        }
    }

    render() {
        const { name, disabled, placeholder, hideIcon, onKeyPress, onPaste, className } = this.props;
        const value = this.state.searchValue;
        return (
            <Wrapper>
                {!hideIcon && (
                    <IconPosition>
                        <Icon icon="search" />
                    </IconPosition>
                )}
                <Input
                    autoComplete="off"
                    value={value}
                    id={name}
                    type="text"
                    name={name}
                    disabled={disabled}
                    placeholder={placeholder}
                    onChange={this.setSearchChanged}
                    onKeyPress={onKeyPress}
                    onPaste={onPaste}
                    onBlur={this.setSearchBlur}
                    ref={this.inputRef}
                    leftMargin={!hideIcon}
                    className={className}
                />
            </Wrapper>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
`;

const IconPosition = styled.div`
    position: absolute;
    top: 2px;
    left: 2px;

    path {
        fill: ${colors.text};
    }
`;

interface InputProps {
    leftMargin: boolean;
}

const Input = styled.input<InputProps>`
    border: none;
    outline: none;
    border: ${border.normal} solid ${palette.neutral[400]};
    border-radius: ${defaultRadius};
    background: ${colors.background};
    padding: ${distances.tiny} ${distances.small12};
    padding-left: ${(props) => (props.leftMargin ? '36px' : distances.small)};
    text-align: left;
    width: ${grid.spans.span4};
    appearance: textfield;
    -moz-appearance: textfield;
    min-height: 40px;
    transition: all 150ms cubic-bezier(0.2, 0, 0.2, 1);

    &.full-width {
        width: 100%;
    }

    &::placeholder {
        color: ${colors.textSecondary};
    }

    &:focus {
        outline: none;
        border: ${border.normal} solid ${palette.primary[300]};
        box-shadow: 0 0 0 3px ${palette.primary[100]};
    }

    &:disabled {
        background: ${palette.neutral[50]};
        border: 1px solid ${palette.neutral[200]};
        color: ${palette.neutral[500]};
    }

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export default Search;
