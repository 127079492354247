import fulfill from '../../fulfill';
import { SideEffectFunction } from '../../sideEffects';
import {
    ActionTypes,
    CreateAccount,
    CreateAccountResponse,
    GetPricePackages,
    GetPricePackagesResponse,
    SearchCompanyName,
    SearchCompanyNameResponse,
    searchCompanyNameResponse
} from './actions';
import { Account, PricePackageCollectionResponse } from '../../types/management-auth';
import { CORE_API_HOSTNAME } from '../../env';
import { isInteger } from '../../helpers/validation/integer';

const createAccount: SideEffectFunction<CreateAccount, CreateAccountResponse | undefined> = async action => {
    const result: CreateAccountResponse | undefined = await fulfill.post({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/accounts`,
        json: action.payload.account,
        accountId: action.payload.accountId,
        handlers: {
            200: (account: Account) => ({
                type: ActionTypes.CreateAccountResponse,
                payload: {
                    account,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    if (result?.type === ActionTypes.CreateAccountResponse && action.payload.type === 'partner') {
        const resultingAccountId = result.payload.account.account_id;
        if (resultingAccountId) {
            const partnerLink = await fulfill.post({
                url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/accounts/P${resultingAccountId}/partner_onboarding_link`,
                accountId: action.payload.accountId,
                handlers: {
                    200: (response: { partner_onboarding_url: string }) => response.partner_onboarding_url,
                    400: () => undefined,
                    401: () => undefined,
                    403: () => undefined,
                    500: () => undefined,
                },
            });
            result.payload.partnerLink = partnerLink;
        }
    }

    return result;
};

export const searchCompanyName: SideEffectFunction<SearchCompanyName, SearchCompanyNameResponse> = async action => {
    const partialName = action.payload.partialName;
    let searchValue = partialName;
    let searchProp = 'name';
    const possibleOrgnumber = partialName.replace(/\D/g, '');
    if (possibleOrgnumber.length === 9 && isInteger(searchValue)) {
        searchValue = possibleOrgnumber;
        searchProp = 'organization_number';
    }
    searchValue = encodeURIComponent(searchValue);
    const response: SearchCompanyNameResponse = await fulfill.get({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/search/external/organizations/no?${searchProp}=${searchValue}`,
        accountId: 'none',
        handlers: {
            200: (response) => {
                const accounts = response.map((entry: any) => {
                    return {
                        company: {
                            organization_number: entry.organization_number,
                            business_name: entry.business_name,
                            website: asProtocolUrl(entry.url),
                            address: {
                                address_line: entry.business_address?.address_line,
                                address_line_2: entry.business_address?.address_line_2,
                                postal_code: entry.business_address?.postal_code,
                                postal_place: entry.business_address?.postal_place,
                                country: entry.business_address?.country,
                            },
                        },
                    } as Account;
                });
                return searchCompanyNameResponse(accounts);
            },
        },
    });
    return response;
};

const asProtocolUrl = (url: string | undefined) => {
    if (!url) {
        return '';
    }
    if (url.startsWith('http')) {
        return url;
    }
    return `https://${url}`;
};

const getPricePackages: SideEffectFunction<GetPricePackages, GetPricePackagesResponse> = async action => {
    const response: GetPricePackagesResponse = await fulfill.get({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/price-packages`,
        accountId: action.payload.accountId,
        handlers: {
            200: (response: PricePackageCollectionResponse) => {
                return {
                    type: ActionTypes.GetPricePackagesResponse,
                    payload: {
                        pricePackages: response.price_packages,
                    },
                    meta: {
                        cause: action,
                    },
                };
            },
        },
    });
    return response;
};

const effects = {
    [ActionTypes.CreateAccount]: createAccount,
    [ActionTypes.SearchCompanyName]: searchCompanyName,
    [ActionTypes.GetPricePackages]: getPricePackages,
};

export default effects;
