import React, { Suspense } from 'react';
import { PUBLIC_URL } from './env';
import { setItem, getItem, removeItem } from './helpers/localStorage';

// Local storage helpers
const setRedirect = (path: string, search: string) => {
    setItem('pr_redirect_path', path);
    setItem('pr_redirect_search', search);
};

const clearRedirect = () => {
    removeItem('pr_redirect_path');
    removeItem('pr_redirect_search');
};

const getRedirect = () => {
    const path = getItem('pr_redirect_path');
    const search = getItem('pr_redirect_search');
    return { path, search };
};

// Get next location for PR builds
export const getAndClearPullRequestRedirect = () => {
    const routerBaseName = PUBLIC_URL || '/';
    const pullRequestBaseName = routerBaseName !== '/';
    const { path, search } = getRedirect();
    // Clear redirect regardless of whether we are on a PR build or not
    clearRedirect();
    if (pullRequestBaseName && path) {
        return path + search;
    }
};

const RedirectToPullRequestRoute = () => {
    // Eg. the user has refreshed the page from a PR build but since the page
    // does not exist cloudfront has returned the index html file from the
    // master branch. We need to redirect the user to the correct branch
    // and move the non-pr path to  local storage so that the user is redirected
    // to the correct page when the PR build is loaded.
    const path = window.location.pathname;
    const pathParts = path.split('/');
    const pullRequestPath = pathParts.slice(0, 3).join('/');
    const nextRedirectToPath = `/${pathParts.slice(3).join('/')}`;
    const nextRedirectToSearch = window.location.search;

    // Do not  redirect if the user is already on a PR build or if the
    // nextRedirectToPath is the root of the PR build (if the build exists
    // the user should not load the 404 page).
    const routerBaseName = PUBLIC_URL || '/';
    const invalidBasename = routerBaseName !== '/';
    const shouldHitCircuitBreaker = nextRedirectToPath === '/';
    if (invalidBasename || shouldHitCircuitBreaker) {
        console.log('RedirectToPullRequestRoute circuit breaker', {
            path,
            pullRequestPath,
            nextRedirectToPath,
            nextRedirectToSearch,
            routerBaseName,
        });
        throw new Error('RedirectToPullRequestRoute circuit breaker');
    }

    // Set next desired path in local storage
    setRedirect(nextRedirectToPath, nextRedirectToSearch);
    console.log('RedirectToPullRequestRoute redirecting', {
        path,
        pullRequestPath,
        nextRedirectToPath,
        nextRedirectToSearch,
    });
    // Redirect to the PR build
    window.location.replace(pullRequestPath);
    return null;
};

export default RedirectToPullRequestRoute;
