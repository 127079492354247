import { connect } from 'react-redux';

import { State } from '../../../reducer';
import MerchantPage from '../components/MerchantPage';
import { getMerchant, downloadSignupFile } from '../actions';
import { getExchangeToken } from '../../merchants/actions';
import {
    getMerchantSelector,
    getMerchantSubscriptionsSelector,
    getIsLoadingSelector,
    getIsNotFoundSelector,
    getMerchantCheckoutSelector,
    getApprovalsPaymentsSelector
} from '../selectors';

const mapStateToProps = (state: State) => ({
    merchant: getMerchantSelector(state),
    checkout: getMerchantCheckoutSelector(state),
    approvals: getApprovalsPaymentsSelector(state),
    subscriptions: getMerchantSubscriptionsSelector(state),
    isNotFound: getIsNotFoundSelector(state),
    isLoading: getIsLoadingSelector(state),
});

const mapDispatchToProps = {
    getMerchant: getMerchant,
    getExchangeToken: getExchangeToken,
    downloadSignupFile: downloadSignupFile,
};

export default connect(mapStateToProps, mapDispatchToProps)(MerchantPage);
