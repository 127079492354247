import { Account, AccountAdminUser } from '../../types/management-auth';

export enum ActionTypes {
    GetPartnerUsers = '[partner/merchants] GET_PARTNER_USERS',
    FetchPartnerUsersResponse = '[partner/merchants] FETCH_PARTNER_USERS_RESPONSE',
    GetAccounts = '[partner/merchants] GET_ACCOUNTS',
    FetchAccountsResponse = '[partner/merchants] FETCH_ACCOUNTS_RESPONSE',
    GetExchangeToken = '[partner/merchants] GET_EXCHANGE_TOKEN',
    FetchExchangeTokenResponse = '[partner/merchants] FETCH_EXCHANGE_TOKEN_RESPONSE',
}

export enum GetAccountsReason {
    mount = 'mount',
    refresh = 'refresh',
    update = 'update',
    next = 'next',
}

export interface GetAccounts {
    type: ActionTypes.GetAccounts;
    payload: {
        accountId: string;
        params: URLSearchParams;
        reason: GetAccountsReason;
        requestId: number;
    };
}

export function getAccounts(accountId: string, params: URLSearchParams, reason: GetAccountsReason): GetAccounts {
    return {
        type: ActionTypes.GetAccounts,
        payload: {
            accountId,
            params,
            reason,
            requestId: performance.now(),
        },
    };
}

export interface GetPartnerUsers {
    type: ActionTypes.GetPartnerUsers;
    payload: {
        accountId: string;
    };
}

export function getPartnerUsers(accountId: string): GetPartnerUsers {
    return {
        type: ActionTypes.GetPartnerUsers,
        payload: {
            accountId,
        },
    };
}

export interface FetchPartnerUsersResponse {
    type: ActionTypes.FetchPartnerUsersResponse;
    payload: {
        users: AccountAdminUser[];
    };
    meta: {
        cause: GetPartnerUsers;
    };
}

export interface FetchAccountsResponse {
    type: ActionTypes.FetchAccountsResponse;
    payload: {
        accounts: Account[];
    };
    meta: {
        cause: GetAccounts;
    };
}

export interface GetExchangeToken {
    type: ActionTypes.GetExchangeToken;
    payload: {
        accountId: string;
        subAccountId: string;
    };
}

export function getExchangeToken(accountId: string, subAccountId: string): GetExchangeToken {
    return {
        type: ActionTypes.GetExchangeToken,
        payload: {
            accountId,
            subAccountId,
        },
    };
}

export interface FetchExchangeTokenResponse {
    type: ActionTypes.FetchExchangeTokenResponse;
    payload: {
        subAccountId: string;
        accessToken: string;
    };
    meta: {
        cause: GetExchangeToken;
    };
}

export type Actions = GetAccounts | FetchAccountsResponse | FetchPartnerUsersResponse;
