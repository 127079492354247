import { effects as settingsEffects } from './settings';
import { effects as authEffects } from './auth';
import { effects as partnerEffects } from './partner';

const effects = {
    ...settingsEffects,
    ...authEffects,
    ...partnerEffects,
};

export default effects;
