import { createSwitchlessReducer } from '../../util/switchlessReducer';
import { createAccountIdChangedCaseReducer } from '../../auth/useAccountId';
import { Dispatch } from 'redux';
import { AccountConnectionEvent } from '../../types/management-auth/generated';
import { CORE_API_HOSTNAME } from '../../env';
import apiRequest from '../../apiRequest';

export const namespace = 'connections.events' as const;

export interface State {
    isLoading: boolean;
    lastConnectionEventSuccess?: string;
    lastConnectionEventFailure: string | undefined;
}

export const initialState: State = {
    isLoading: false,
    lastConnectionEventSuccess: undefined,
    lastConnectionEventFailure: undefined,
};

type ConnectionEventProps = {
    connection: string;
}

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        postConnectionEvent: (state: State) => {
            return {
                ...state,
                isLoading: true,
                lastConnectionEventSuccess: undefined,
                lastConnectionEventFailure: undefined,
            };
        },
        postConnectionEventSuccess: (state: State, { connection }: ConnectionEventProps) => {
            return {
                ...state,
                isLoading: false,
                lastConnectionEventSuccess: connection,
                lastConnectionEventFailure: undefined,
            };
        },
        postConnectionEventFailure: (state: State, { connection }: ConnectionEventProps) => {
            return {
                ...state,
                isLoading: false,
                lastConnectionEventFailure: connection,
                lastConnectionEventSuccess: undefined,
            };
        },
    },
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

export const createConnectedActions = (dispatch: Dispatch) => {
    const addConnectionEvent = async (partner_id: string, sub_account_id: string, connection: string, data: AccountConnectionEvent) => {
        const url = `${CORE_API_HOSTNAME}/v1/accounts/${partner_id}/management/accounts/${sub_account_id}/connections/${connection}/events`;
        dispatch(actions.postConnectionEvent());

        try {
            await apiRequest('POST', partner_id, url, {}, data);
            dispatch(actions.postConnectionEventSuccess({ connection }));
        } catch (err) {
            dispatch(actions.postConnectionEventFailure({ connection }));
        }
    };
    return {
        addConnectionEvent,
    };
};
