import { useTranslation } from 'react-i18next';
import Card from '../../../components/Card';
import { LoadingOverlay } from '../../../components/Loading';
import Page from '../../../components/Page';

const PaymentsLoading = () => {
    const { t } = useTranslation();
    return <Page title={t('settings.approvalsPayments.title')}>
        <Card>
            <LoadingOverlay />
        </Card>
    </Page>;
};

export default PaymentsLoading;
