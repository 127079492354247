import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import _upperFirst from 'lodash/upperFirst';
import styled from 'styled-components/macro';
import { useIsDinteroRoot } from '../../../auth/accessToken/Allow';
import { Button, ButtonGroup } from '../../../components/Buttons';
import { Checkbox, Dropdown, Input, PhoneNumber } from '../../../components/Forms';
import Grid, { GridItem } from '../../../components/Grid';
import { getPostalPlace } from '../../../helpers/dataload/postalCodes';
import { validateValues } from '../newLocation/validation/validateValues';
import { LocationFormValues } from '../types';
import { getCountryOptions, getTypeOptions } from './options';
import { getPhonePrefixFromCountry, validateInput } from './utils';


interface LocationFormProps {
    locationFormValues: LocationFormValues;
    onSubmit: (values: LocationFormValues) => void;
    isEdit?: boolean;
    withGrid?: boolean;
}

const LocationForm = (
    {
        locationFormValues,
        onSubmit,
        isEdit,
        withGrid = true,
    }: LocationFormProps
) => {
    const { t } = useTranslation('app');
    const isDinteroAdmin = useIsDinteroRoot();

    const typeOptions = getTypeOptions(t);
    const countryOptions = getCountryOptions(t, '');

    const disableUneditable = isEdit && !isDinteroAdmin;

    const Wrapper = withGrid ? Grid : InvisibleWrapper;

    return (
        <Formik
            initialValues={locationFormValues}
            validate={(values) => validateValues(values, t)}
            onSubmit={(values) => onSubmit(values)}
            enableReinitialize
        >
            {({ values, handleChange, handleBlur, handleSubmit, setFieldValue, errors, touched }) => (
                <InvisibleForm onSubmit={handleSubmit}>
                    <Wrapper>
                        {isEdit && <>
                            <GridItem>
                                <Dropdown
                                    name={'country'}
                                    label={t('locations.location_form.fields.country')}
                                    value={values.country}
                                    options={countryOptions}
                                    onChange={(option) => {
                                        setFieldValue('country', option);
                                        setFieldValue('countryPrefix', getPhonePrefixFromCountry(option.value));
                                    }}
                                    disabled={disableUneditable}
                                    required
                                />
                            </GridItem>
                            <GridItem>
                                <Input
                                    name={'locationId'}
                                    label={t('locations.location_form.fields.location_id')}
                                    placeholder={t('locations.location_form.placeholders.location_id')}
                                    value={values.locationId}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={disableUneditable}
                                />
                            </GridItem>
                            <GridItem>
                                <Input
                                    name={'organizationNumber'}
                                    label={t('locations.location_form.fields.org_nr')}
                                    placeholder={t('locations.location_form.placeholders.org_nr')}
                                    value={values.organizationNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    validation={validateInput('organizationNumber', errors, touched)}
                                    disabled={disableUneditable}
                                    required
                                />
                            </GridItem>
                        </>}
                        <GridItem>
                            <Input
                                name={'businessName'}
                                label={t('locations.location_form.fields.legal_name')}
                                placeholder={t('locations.location_form.placeholders.legal_name')}
                                value={values.businessName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                validation={validateInput('businessName', errors, touched)}
                                disabled={disableUneditable}
                                required
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                name={'name'}
                                label={t('locations.location_form.fields.name')}
                                placeholder={t('locations.location_form.placeholders.name')}
                                value={values.name}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                validation={validateInput('name', errors, touched)}
                                required
                            />
                        </GridItem>
                        <GridItem>
                            <Dropdown
                                name={'type'}
                                label={t('locations.location_form.fields.type')}
                                value={values.type}
                                options={typeOptions}
                                onChange={(value) => setFieldValue('type', value)}
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                name={'websiteUrl'}
                                label={t('locations.location_form.fields.website')}
                                placeholder={t('locations.location_form.placeholders.website')}
                                value={values.websiteUrl}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                name={'addressLine'}
                                label={t('locations.location_form.fields.address_line')}
                                placeholder={t('locations.location_form.placeholders.address_line')}
                                value={values.addressLine}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                validation={validateInput('addressLine', errors, touched)}
                                disabled={disableUneditable}
                                required
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                name={'addressLine2'}
                                label={t('locations.location_form.fields.address_line_2')}
                                placeholder={t('locations.location_form.placeholders.address_line_2')}
                                value={values.addressLine2}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={disableUneditable}
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                name={'postalCode'}
                                label={t('locations.location_form.fields.postal_code')}
                                placeholder={t('locations.location_form.placeholders.postal_code')}
                                value={values.postalCode}
                                onChange={(event) => {
                                    getPostalPlace(values.country.value, event.currentTarget.value).then((place: string) => {
                                        if (place) {
                                            setFieldValue('postalPlace', _upperFirst(place.toLowerCase()));
                                        }
                                    });
                                    handleChange(event);
                                }}
                                onBlur={handleBlur}
                                validation={validateInput('postalCode', errors, touched)}
                                disabled={disableUneditable}
                                required
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                name={'postalPlace'}
                                label={t('locations.location_form.fields.postal_place')}
                                placeholder={t('locations.location_form.placeholders.postal_place')}
                                value={values.postalPlace}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                validation={validateInput('postalPlace', errors, touched)}
                                disabled={disableUneditable}
                                required
                            />
                        </GridItem>
                        <GridItem>
                            <Checkbox
                                name="franchise"
                                label={t('locations.location_form.fields.franchise')}
                                checked={values.franchise}
                                onChange={() => setFieldValue('franchise', !values.franchise)}
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                name={'franchiseName'}
                                label={t('locations.location_form.fields.franchise_name')}
                                placeholder={t('locations.location_form.placeholders.franchise_name')}
                                value={values.franchiseName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                validation={validateInput('franchiseName', errors, touched)}
                                disabled={!values.franchise}
                                required={values.franchise}
                            />
                        </GridItem>
                        <GridItem>
                            <PhoneNumber
                                name={'phoneNumber'}
                                label={t('locations.location_form.fields.phone_number')}
                                phoneNumber={values.phoneNumber}
                                countryPrefix={values.countryPrefix}
                                countryPrefixName="countryPrefix"
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                disabled={false}
                                autoComplete="off"
                                validation={validateInput('phoneNumber', errors, touched)}
                            />
                        </GridItem>
                        <GridItem>
                            <Input
                                name={'email'}
                                label={t('locations.location_form.fields.email')}
                                placeholder={t('locations.location_form.placeholders.email')}
                                value={values.email}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </GridItem>
                    </Wrapper>
                    <ButtonGroup
                        width="100%"
                    >
                        <Button type="submit">
                            {isEdit
                                ? t('locations.location_edit.action_button')
                                : t('locations.new_location.action_button')}
                        </Button>
                    </ButtonGroup>
                </InvisibleForm>
            )}
        </Formik>
    );
};

const InvisibleForm = styled.form`
    display: contents;
`;

const InvisibleWrapper = styled.div`
    display: contents;
`;

export default LocationForm;
