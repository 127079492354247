import React from 'react';

const ArrowRightSmall = () => (
    <svg width="11px" height="9px" viewBox="0 0 11 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="currentColor" fillRule="evenodd">
            <g
                id="arrow_left"
                transform="translate(5.500000, 4.500000) scale(-1, 1) translate(-5.500000, -4.500000) "
                fillRule="nonzero"
            >
                <polygon
                    id="arrow"
                    transform="translate(5.259892, 4.500000) rotate(90.000000) translate(-5.259892, -4.500000) "
                    points="5.91710868 7.08914203 8.47487373 4.41005178 9.42655818 5.31031153 5.26299448 9.74010848 1.09322483 5.31068538 2.04420143 4.40967793 4.60708158 7.08990563 4.60708158 -0.74010803 5.91710868 -0.74010803"
                ></polygon>
            </g>
        </g>
    </svg>
);

export default ArrowRightSmall;
