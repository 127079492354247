import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { distances } from '../../../styles/constants';
import { Dl, Dt, Dd } from '../../../components/DescriptionList/Vertical';
import { H3 } from '../../../components/Typography';
import { Account } from '../../../types/management-auth';
import Card from '../../../components/Card';
import Hr from '../../../components/Hr';

export interface JwksInformationProps extends WithTranslation {
    account: Account;
}

const JwksInformation = (
    {
        t,
        account,
    }: JwksInformationProps
) => {
    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('settings.account.jwks')}</H3>
            <Hr />
            <Dl>
                <Dt doubleColumn>{t('settings.account.fields.jwks_name')}</Dt>
                <Dd>{account.jwks?.name}</Dd>
            </Dl>
            <Dl>
                <Dt doubleColumn>{t('settings.account.fields.jwks_uri')}</Dt>
                <Dd>
                    <code>{account.jwks?.uri}</code>
                </Dd>
            </Dl>
        </Card>
    );
};

export default withTranslation()(JwksInformation);
