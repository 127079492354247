import { Client, HasSecretClientResponse, Grant, Account } from '../../../types/management-auth';

export enum ActionTypes {
    GetAccount = '[settings/new_client] GET_ACCOUNT',
    GetAccountResponse = '[settings/new_client] GET_ACCOUNT_RESPONSE',
    CreateClient = '[settings/new_client] CREATE_CLIENT',
    CreateClientResponse = '[settings/new_client] FETCH_POST_CLIENT_RESPONSE',
    CreateGrantsResponse = '[settings/new_client] FETCH_POST_CLIENT_GRANT_RESPONSE',
    ToggleSecretIsSafe = '[settings/new_client] TOGGLE_SECRET_IS_SAFE',
    ResetForm = '[settings/new_client] RESET_CREATE_CLIENT_FORM',
}

export interface GetAccount {
    type: ActionTypes.GetAccount;
    payload: {
        accountId: string;
    };
}

export function getAccount(accountId: string) {
    return {
        type: ActionTypes.GetAccount,
        payload: {
            accountId,
        },
    };
}

export interface GetAccountResponse {
    type: ActionTypes.GetAccountResponse;
    payload: {
        account: Account;
    };
}

export interface CreateClient {
    type: ActionTypes.CreateClient;
    payload: {
        accountId: string;
        newClient: Client;
        newGrants: Grant[];
    };
}

export interface FetchCreateClientResponse {
    type: ActionTypes.CreateClientResponse;
    payload: {
        client: HasSecretClientResponse;
    };
    meta: {
        cause: CreateClient;
    };
}
export interface FetchCreateGrantsResponse {
    type: ActionTypes.CreateGrantsResponse;
    payload: {
        grants: Grant[];
    };
    meta: {
        cause: FetchCreateClientResponse;
    };
}

export function createClient(accountId: string, newClient: Client, newGrants: Grant[]): CreateClient {
    return {
        type: ActionTypes.CreateClient,
        payload: {
            accountId,
            newClient,
            newGrants,
        },
    };
}

export interface ResetForm {
    type: ActionTypes.ResetForm;
    payload: {};
}

export function resetForm() {
    return {
        type: ActionTypes.ResetForm,
        payload: {},
    };
}

export interface ToggleSecretIsSafe {
    type: ActionTypes.ToggleSecretIsSafe;
    payload: {
        value: boolean;
    };
}

export function toggleSecretIsSafe(value: boolean) {
    return {
        type: ActionTypes.ToggleSecretIsSafe,
        payload: {
            value,
        },
    };
}

export type Actions =
    | GetAccount
    | GetAccountResponse
    | CreateClient
    | FetchCreateClientResponse
    | FetchCreateGrantsResponse
    | ResetForm
    | ToggleSecretIsSafe;
