export function copy(value: string) {
    const temp = document.createElement('textarea');
    temp.innerHTML = value;
    temp.style.height = '0';
    temp.style.width = '0';
    temp.style.opacity = '0';
    document.body.appendChild(temp);
    temp.select();
    document.execCommand('copy');
    document.body.removeChild(temp);
}
