import React from 'react';

import { Input, InputValidation } from './';

const formatUrl = (url: string | undefined) => {
    const loweredUrl = url ? url.toLowerCase() : '';
    const isProtocol = ['h', 'ht', 'htt', 'http', 'https', 'https:/', 'https://'].some(prefix => loweredUrl.startsWith(prefix));
    const isEmpty = loweredUrl.length === 0;
    if (isProtocol || isEmpty) {
        return loweredUrl;
    }
    return `https://${url}`;
};

interface UrlInputProps {
    label: any;
    placeholder: string;
    setFieldValue: (field: string, value: string) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    validation?: InputValidation;
    autoComplete?: string;
    name: string;
    value: string;
    required?: boolean;
}

const UrlInput = (props: UrlInputProps) => (<Input
    label={props.label}
    placeholder={props.placeholder}
    type="text"
    name={props.name}
    value={props.value}
    onChange={(event: any) => {
        const value = event.currentTarget.value.trim();
        const formattedUrl = formatUrl(value);
        event.currentTarget.value = formattedUrl;
        props.setFieldValue(props.name, formattedUrl);
    }}
    onBlur={props.onBlur}
    validation={props.validation}
    autoComplete="off"
    required={props.required}
/>);

export default UrlInput;
