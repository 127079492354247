import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';
import { ActionTypes, FetchCreateUserResponse, CreateUser, FetchAccountResponse, GetAccount } from './actions';
import { AccountAdminUser, AccountUser, Account } from '../../../types/management-auth';

const fetchCreateUser: SideEffectFunction<CreateUser, FetchCreateUserResponse> = async (action) => {
    const result: FetchCreateUserResponse = await fulfill.post<AccountUser>({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/users`,
        json: action.payload.newUser,
        handlers: {
            200: (user: AccountAdminUser) => ({
                type: ActionTypes.CreateUserResponse,
                payload: {
                    user,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchAccount: SideEffectFunction<GetAccount, FetchAccountResponse> = async action => {
    const result: FetchAccountResponse = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/settings`,
        handlers: {
            200: (account: Account) => ({
                type: ActionTypes.FetchAccountResponse,
                payload: {
                    account,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.CreateUser]: fetchCreateUser,
    [ActionTypes.GetAccount]: fetchAccount,
};

export default effects;
