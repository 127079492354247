import React, { useEffect } from 'react';
import { Trans, WithTranslation, useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ButtonLink } from '../../../../components/Buttons';
import { DateTime } from '../../../../components/Formatters';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { PageButtonLink } from '../../../../components/Page';
import { LinkTr, Table, Tbody, Td, Th, Thead, Tr } from '../../../../components/Table/Fake';
import { H1, P } from '../../../../components/Typography';

import { useAccountId } from '../../../../auth/useAccountId';
import useApiClients from '../../../../common/hooks/useApiClients';
import EmptyState, { EmptyStateIconWrapper } from '../../../../components/EmptyState';
import { EnvironmentBadge } from '../../../../components/EnvironmentBadge';
import Icon from '../../../../components/Icons';
import { colors } from '../../../../styles/constants';
import { Grant } from '../../../../types/management-auth';
import ClientIcon from '../ClientIcon';
import { State as ClientsState } from '../reducer';

interface MatchParams {
    accountId: string;
}

const getGrantEnvironment = (grant: Grant): 'test' | 'production' | undefined => {
    if (grant.deleted_at) {
        return undefined;
    }
    const regex = /accounts\/(T|P)/;
    const match = grant.audience.match(regex);
    if (match && match[1] === 'T') {
        return 'test';
    } else if (match && match[1] === 'P') {
        return 'production';
    }
    return undefined;
};

const environments = (grants: Grant[] | undefined) => {
    if (grants) {
        const envSet = grants.reduce((acc: Set<'test' | 'production'>, grant) => {
            const environment = getGrantEnvironment(grant);
            if (environment) {
                return acc.add(environment);
            }
            return acc;
        }, new Set<'test' | 'production'>());
        const envList = [...envSet];
        envList.sort();
        envList.reverse();
        return envList;
    }
    return [];
};

export interface UsersListProps extends WithTranslation, RouteComponentProps<MatchParams> {
    getClients: (accountId: string) => void;
    clients: ClientsState;
}

const ClientList = () => {
    const { t } = useTranslation();
    const { clients, grants, isLoading, refresh } = useApiClients({
        environment: 'production',
    });
    const accountId = useAccountId();

    useEffect(() => {
        if (!isLoading && clients.length > 0) {
            refresh();
        }
    /* Infinite loop */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Page
            title={
                <React.Fragment>
                    <Title>{t('settings.clients.title')}</Title>
                    <PageButtonLink to={`/${accountId}/settings/clients/select`}>
                        <Icon icon="plus" fill="currentColor" />
                        {t('settings.clients.create_new_user')}
                    </PageButtonLink>
                </React.Fragment>
            }
        >
            <Wrapper>
                <Table className="no-wrap">
                    <Thead>
                        <Tr>
                            <Th className="first-child">{t('settings.clients.fields.name')}</Th>
                            <Th className="collapse-1200">{t('settings.clients.fields.updated_at')}</Th>
                            <Th>{t('settings.clients.fields.state')}</Th>
                            <Th className="last-child">{t('settings.clients.fields.client_id')}</Th>
                            <Th className="last-child">{t('settings.clients.fields.environment')}</Th>
                        </Tr>
                    </Thead>
                    {clients.length > 0 && (
                        <Tbody>
                            {clients.map((client) => (
                                <ClientTr
                                    key={client.id}
                                    to={`/${accountId}/settings/clients/${client.id}`}
                                    className={client.deleted_at ? 'deleted' : ''}
                                >
                                    <Td className="first-child">{client.name}</Td>
                                    <Td className="collapse-1200">
                                        {client.updated_at && (
                                            <DateTime isoString={client.updated_at || ''} boldTime />
                                        )}
                                    </Td>
                                    <Td>
                                        {client.deleted_at ? (
                                            <Deleted>{t('settings.clients.state.deleted')}</Deleted>
                                        ) : (
                                            <Active>{t('settings.clients.state.active')}</Active>
                                        )}
                                    </Td>
                                    <MutedTd className="last-child ellipsis">{client.client_id}</MutedTd>
                                    <Td className="last-child ellipsis">
                                        {client.client_id &&
                                                    environments(grants[client.client_id]).map((env) => (
                                                        <React.Fragment key={env}>
                                                            <EnvironmentBadge key={env} environment={env} />{' '}
                                                        </React.Fragment>
                                                    ))}
                                    </Td>
                                </ClientTr>
                            ))}
                        </Tbody>
                    )}
                </Table>
                {isLoading && <LoadingOverlay />}
                {!isLoading && clients.length === 0 && (
                    <EmptyState>
                        <EmptyStateIconWrapper>
                            <ClientIcon />
                        </EmptyStateIconWrapper>
                        <P>
                            {t('settings.clients.empty.message')}
                            <br />
                            <br />
                        </P>
                        <P>
                            <Trans i18nKey="settings.clients.empty.docs">
                                <a
                                    href="https://docs.dintero.com/docs/clients/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {' '}
                                </a>
                            </Trans>
                        </P>
                        <ButtonLink to={`/${accountId}/settings/clients/select`}>
                            {t('settings.clients.empty.call_to_action')}
                        </ButtonLink>
                    </EmptyState>
                )}
            </Wrapper>
        </Page>
    );
};

export default ClientList;

const Wrapper = styled.div`
    position: relative;
`;

const Title = styled(H1)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const ClientTr = styled(LinkTr)`
    &.deleted {
        background-color: ${colors.backgroundAlt};
    }
`;

const Active = styled.span`
    color: ${colors.primary};
`;

const Deleted = styled.span`
    color: ${colors.invalid};
`;

const MutedTd = styled(Td)`
    color: ${colors.textTeritary};
`;
