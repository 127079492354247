import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useAccountId } from '../../../../auth/useAccountId';
import type { Account } from '../../../../types/management-auth';
import { colors } from '../../../../styles/constants';
import { H1, H2 } from '../../../../components/Typography';
import { Dd, Dl, Dt } from '../../../../components/DescriptionList/Horizontal';
import { OrganizationNumber, PhoneNumber } from '../../../../components/Formatters';
import StyledLink from '../../../../components/StyledLink';
import Address from '../../../../components/Address';
import { checkAccountingAndBillingEqual } from '../../helpers';
import Hr from '../../../../components/Hr';
import { PageButtonLink } from '../../../../components/Page';
import Icon from '../../../../components/Icons';
import View from '../../../../components/View';

const Muted = styled.span`
    color: ${colors.textSecondary};
`;

export type CompanyInfoProps = {
    account: Account;
    isLoading: boolean;
};

export default function CompanyInfo({ account, isLoading }: CompanyInfoProps) {
    const { t } = useTranslation('app');

    const accountId = useAccountId();

    const companyBillingEqual = checkAccountingAndBillingEqual(account.company, account.billing);
    const nameToDisplay = account.company.display_name ? account.company.display_name : account.company.business_name;

    return (
        <>
            <View justify="space-between">
                <H1>{nameToDisplay || <Muted>{t('settings.account.company_name_not_set_yet')}</Muted>}</H1>
                <PageButtonLink to={`/${accountId}/settings/account/edit`} className={isLoading ? 'disabled' : ''}>
                    <Icon icon="document" fill="currentColor" />
                    {t('settings.account.edit_account')}
                </PageButtonLink>
            </View>
            <Dl>
                <Dt>{t('settings.account.fields.id')}</Dt>
                <Dd>
                    <code>{account.account_id}</code>
                </Dd>
            </Dl>
            <br />
            <H2>{t('settings.account.company_info')}</H2>
            <Hr />
            {account.company.business_name && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.business_name')}</Dt>
                    <Dd>{account.company.business_name}</Dd>
                </Dl>
            )}
            {account.company.organization_number && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.organization_number')}</Dt>
                    <Dd>
                        <OrganizationNumber
                            country={account.company.address.country}
                            value={account.company.organization_number}
                        />
                    </Dd>
                </Dl>
            )}
            {account.company.website && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.website')}</Dt>
                    <Dd>
                        <StyledLink href={account.company.website} target="_blank">
                            {account.company.website}
                        </StyledLink>
                    </Dd>
                </Dl>
            )}
            {account.company.industry && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.industry')}</Dt>
                    <Dd>{account.company.industry}</Dd>
                </Dl>
            )}
            {account.company.address && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.address')}</Dt>
                    <Dd>
                        <Address {...account.company.address} />
                    </Dd>
                </Dl>
            )}
            {account.company.phone_number && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.phone_number')}</Dt>
                    <Dd>
                        <PhoneNumber phoneNumber={account.company.phone_number} />
                    </Dd>
                </Dl>
            )}
            {account.company.email && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.email')}</Dt>
                    <Dd>{account.company.email}</Dd>
                </Dl>
            )}
            {account.company.technical_email && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.technical_email')}</Dt>
                    <Dd>{account.company.technical_email}</Dd>
                </Dl>
            )}
            {account.company.branding?.logo_url && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.logo_url')}</Dt>
                    <Dd>{account.company.branding.logo_url}</Dd>
                </Dl>
            )}
            {account.company.branding?.icon_url && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.icon_url')}</Dt>
                    <Dd>{account.company.branding.icon_url}</Dd>
                </Dl>
            )}
            <br />
            <H2>{t('settings.account.billing_info')}</H2>
            <Hr />
            {!companyBillingEqual && account.billing.organization_number && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.business_name')}</Dt>
                    <Dd>{account.billing.business_name}</Dd>
                </Dl>
            )}
            {!companyBillingEqual && account.billing.organization_number && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.organization_number')}</Dt>
                    <Dd>{account.billing.organization_number}</Dd>
                </Dl>
            )}
            {account.billing.email && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.email')}</Dt>
                    <Dd>{account.billing.email}</Dd>
                </Dl>
            )}
            {account.billing.reference && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.billing_reference')}</Dt>
                    <Dd>{account.billing.reference}</Dd>
                </Dl>
            )}
            {!companyBillingEqual && account.billing.address && (
                <Dl>
                    <Dt doubleColumn>{t('settings.account.fields.address')}</Dt>
                    <Dd>
                        <Address {...account.billing.address} />
                    </Dd>
                </Dl>
            )}
        </>
    );
}
