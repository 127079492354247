import React from 'react';
import { Route } from 'react-router-dom';

import { Users } from './users';
import { UserDetails } from './userDetails';
import { NewUser } from './newUser';
import { EditUser } from './editUser';

const Routes = [
    <Route key="users" path="/:accountId/settings/users" exact component={Users} />,
    <Route key="new_user" path="/:accountId/settings/users/new" exact component={NewUser} />,
    <Route key="user_details" path="/:accountId/settings/users/:userId" exact component={UserDetails} />,
    <Route key="edit_user" path="/:accountId/settings/users/:userId/edit" exact component={EditUser} />,
];

export default Routes;
