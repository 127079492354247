import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';

import { ActionTypes, FetchPayoutRulesResponse, GetPayoutRules } from './actions';
import { BillingPayoutRule } from '../../../types/billing';

type Response = {
    payout_rules: BillingPayoutRule[];
    starting_after: string | undefined;
};

const fetchPayoutRulesReq = async (accountId: string, params: URLSearchParams): Promise<Response> => {
    return fulfill.get({
        accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/billing/payout-rules?${params.toString()}`,
        handlers: {
            200: (response: Response) => response,
            404: () => [],
            400: () => [],
        },
    });
};

const recursivelyFetchPayoutRules = async (
    accountId: string,
    params: URLSearchParams,
    rules: BillingPayoutRule[]
): Promise<BillingPayoutRule[]> => {
    const { payout_rules, starting_after } = await fetchPayoutRulesReq(accountId, params);
    const nextRules = [...rules, ...payout_rules];
    if (payout_rules.length.toString() === params.get('limit') && starting_after) {
        const nextParams = new URLSearchParams(params);
        nextParams.delete('starting_after');
        nextParams.append('starting_after', starting_after);
        return recursivelyFetchPayoutRules(accountId, params, nextRules);
    }
    return nextRules;
};

const fetchPayoutRules: SideEffectFunction<GetPayoutRules, FetchPayoutRulesResponse> = async (action) => {
    const params = action.payload.params;
    const payoutRules = await recursivelyFetchPayoutRules(action.payload.accountId, params, []);
    return {
        type: ActionTypes.FetchPayoutRulesResponse,
        payload: {
            payoutRules,
        },
        meta: {
            cause: action,
        },
    };
};

const effects = {
    [ActionTypes.GetPayoutRules]: fetchPayoutRules,
};

export default effects;
