import React, { useCallback, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { getExchangeTokenPartnerId, getTokens } from './selectors';
import { State } from '../../reducer';
import { Route, Redirect, type RouteComponentProps } from 'react-router-dom';
import { RouteProps, useParams } from 'react-router';
import { AuthenticatedUser } from '../types';
import styled from 'styled-components/macro';
import { colors } from '../../styles/constants';
import { createSelector } from 'reselect';
import decode from 'jwt-decode';

const getAccountUserScope = (accountId: string, user: AuthenticatedUser) => {
    const account = user.account_user.accounts.find((account) => account.account_id === accountId);
    if (!accountId || !account) {
        return [];
    }
    return account.user.scope || [];
};

export const hasAnyOfScopes = (accountId: string, user: AuthenticatedUser, scope: string[][]): boolean => {
    const userAccountScope = getAccountUserScope(accountId, user);
    return isAllowed(scope, userAccountScope);
};

export const isAllowed = (allowedScopes: string[][], userScopes: string[]): boolean => {
    return (
        allowedScopes
            .map((scopeArray) =>
                scopeArray.reduce((acc: boolean, singleScope: string) => {
                    return acc && userScopes.includes(singleScope);
                }, true))
            .find((result) => result === true) || false
    );
};

export const selectTokenScopes = createSelector(
    (_: State, accountId: string) => accountId,
    (state: State) => getTokens(state),
    (accountId, tokens) => {
        const token = tokens[accountId];
        if (!token) return [];
        const decoded = decode(token) as any;
        if (!decoded || !decoded.scopes) return [];
        return decoded.scopes.split(' ') as string[];
    }
);

export const useScopes = () => {
    const { accountId } = useParams();
    const selector = useCallback((state: State) => selectTokenScopes(state, accountId), [accountId]);
    return useSelector(selector);
};

export const useIsAllowed = (scopes: string[][]) => {
    const tokenScopes = useScopes();
    return useMemo(() => isAllowed(scopes, tokenScopes), [tokenScopes, scopes]);
};

export type AllowProps = {
    scope?: string;
    anyOfScopes?: string[][];
    children: JSX.Element;
    fallback?: JSX.Element;
};

export const Allow = ({ scope, anyOfScopes, fallback, children }: AllowProps) => {
    const allowedScopes = scope ? [[scope]] : anyOfScopes ?? [];
    return useIsAllowed(allowedScopes) ? children : fallback ?? null;
};

export type WithScopes = {
    scopes: string[];
};

export function withScopes<P extends WithScopes>(
    Component: React.ComponentType<P>
) {
    return (props: Omit<P, keyof WithScopes>) => {
        const scopes = useScopes();
        return <Component {...(props as P)} scopes={scopes} />;
    };
}

type AllowRouteProps = RouteProps & {
    scope?: string;
    anyOfScopes?: string[][];
    fallback?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any> | undefined;
};

const RedirectUp = () => <Redirect to="../" />;

export const AllowRoute = ({ scope, anyOfScopes, component, fallback, ...rest }: AllowRouteProps) => {
    const allowedScopes = scope ? [[scope]] : anyOfScopes ?? [];
    const allowed = useIsAllowed(allowedScopes);
    if (allowed) {
        return <Route component={component} {...rest} />;
    }

    return <Route component={fallback ? fallback : RedirectUp} {...rest} />;
};

const mapPartnerStateToProps = (state: State) => ({
    exchangeTokenPartnerId: getExchangeTokenPartnerId(state),
});

interface AllowPartnerProps {
    exchangeTokenPartnerId: string | undefined;
    children: JSX.Element;
    fallback?: JSX.Element;
    visibleFrame?: boolean;
    partnerId?: string;
    onlyDinteroAdmin?: boolean;
    useBackgroundColor?: boolean;
}

const ConnectedAllowPartner = ({
    exchangeTokenPartnerId,
    children,
    fallback,
    visibleFrame,
    partnerId,
    onlyDinteroAdmin,
    useBackgroundColor = true,
}: AllowPartnerProps) => {
    if (
        exchangeTokenPartnerId &&
        (!partnerId || partnerId === exchangeTokenPartnerId) &&
        (!onlyDinteroAdmin || exchangeTokenPartnerId === 'P00000000')
    ) {
        if (visibleFrame) {
            return (
                <AllowPartnerFrame data-use-bg={useBackgroundColor}>
                    <PartnerFrameMessage>
                        {exchangeTokenPartnerId === 'P00000000' ? 'admin:dintero' : 'admin:partner'}
                    </PartnerFrameMessage>
                    {children}
                </AllowPartnerFrame>
            );
        }
        return children;
    }
    return fallback || null;
};

export const useAllowPartner = () => {
    const { exchangeTokenPartnerId } = useSelector(mapPartnerStateToProps);
    return !!exchangeTokenPartnerId;
};

const AllowPartnerFrame = styled.div`
    border: 1px solid ${colors.invalid};
    border-radius: 4px;
    padding: 8px;
    &[data-use-bg='true'] {
        background: rgba(189, 4, 75, 0.01);
    }
    position: relative;
    margin: 16px -8px;
`;

const PartnerFrameMessage = styled.div`
    color: ${colors.invalid};
    position: absolute;
    top: -20px;
    left: 8px;
    font-size: 11px;
`;

export const AllowPartner = connect(mapPartnerStateToProps)(ConnectedAllowPartner);

export const useIsDinteroRoot = () => {
    const { exchangeTokenPartnerId } = useSelector(mapPartnerStateToProps);
    return exchangeTokenPartnerId === 'P00000000';
};

export const AllowDinteroRoot = (props: {
    children: JSX.Element;
    visibleFrame?: boolean;
    fallback?: JSX.Element;
    useBackgroundColor?: boolean;
}) => <AllowPartner {...props} onlyDinteroAdmin={true} />;

export const WrappedAllow = ({ children, predicate, Wrapper, visibleFrame }: {
    children: JSX.Element | false;
    predicate: boolean;
    Wrapper: React.FC<{
        children: JSX.Element;
        visibleFrame?: boolean;
    }>;
    visibleFrame?: boolean;
}) => {
    if (!children) {
        return null;
    }
    if (predicate) {
        return children;
    }
    return <Wrapper visibleFrame={visibleFrame}>{children}</Wrapper>;
};
