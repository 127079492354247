import addWeeks from 'date-fns/addWeeks';
import startOfISOWeek from 'date-fns/startOfISOWeek';
import addHours from 'date-fns/addHours';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Account } from '../../../types/management-auth';
import { H3 } from '../../../components/Typography';
import { distances } from '../../../styles/constants';
import Card from '../../../components/Card';
import Hr from '../../../components/Hr';
import CaptureChart from '../../../dashboard/components/kpi/CaptureChart';
import type { InsightCheckoutKpis } from '../../../types/insight';
import { accountCurrencies } from '../../../settings/account/helpers';
import {
    getDefaultCountryCurrency,
    currencyCodes
} from '../../../helpers/currency';

export interface StatisticsProps extends WithTranslation {
    account: Account;
    partnerId: string;
}

const currenciesFrom = (account: Account) => {
    const currencies = accountCurrencies(account);
    if (currencies.length > 0) {
        return currencies;
    }
    // noting configured so lets try to use a sane default
    return [getDefaultCountryCurrency(account, [...currencyCodes], 'NOK')];
};

export const isoWeekToDate = (isoWeek: string): string => {
    const year = parseInt(isoWeek.split('-')[0]);
    const week = parseInt(isoWeek.split('W')[1]);
    return addHours(startOfISOWeek(
        new Date(addWeeks(new Date(Date.UTC(year, 0, 1, 10)), Math.max(0, week)))
        // get correct Date for UTC and +02:00
    ), 12).toISOString().split('T')[0] + 'T00:00:00.000Z';
};

const weeklyCapturesKpis = (account: Account): {
    kpisCollection: InsightCheckoutKpis[];
    currencies: string[];
} => {
    const weeklyCaptures = (account.statistics?.checkout_captures || [])
        .filter((s) => s.type === 'weekly')
        .sort((a, b) => a.period.localeCompare(b.period));

    const kpisCollection = weeklyCaptures.reduce(
        (prev, { volume, period, count, currency }) => {
            prev[0].values.push({
                currency,
                value: volume,
                date: isoWeekToDate(period),
            });
            prev[1].values.push({
                currency,
                value: count,
                date: isoWeekToDate(period),
            });
            return prev;
        },
        [
            { kpi_name: 'sum', values: []},
            { kpi_name: 'count', values: []},
        ] as InsightCheckoutKpis[]
    );

    const currencies = [...new Set(weeklyCaptures.map((s) => s.currency))];
    if (currencies.length === 0) {
        currenciesFrom(account).forEach((currency) =>
            currencies.push(currency));
    }
    return { kpisCollection, currencies };
};

const Statistics = ({ t, account }: StatisticsProps) => {
    const { kpisCollection, currencies } = weeklyCapturesKpis(account);
    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('partner.merchant.statistics')}</H3>
            <Hr />
            <CaptureChart
                loading={false}
                kpisCollection={kpisCollection}
                currencies={currencies}
            />
        </Card>
    );
};

export default withTranslation()(Statistics);
