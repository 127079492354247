import styled from 'styled-components/macro';
import { distances } from '../styles/constants';

type GridProps = {
  columns?: number;
  brs?: {
    [key: string]: GridProps;
  };
  gap?: React.CSSProperties['gap'];
  alignItems?: React.CSSProperties['alignItems'];
}

const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: repeat(${props => props.columns || 2}, 1fr);
  gap: ${props => props.gap || 16}px;
  align-items: ${props => props.alignItems || 'stretch'};
  width: 100%;
  ${props => props.brs && Object.entries(props.brs).map(([key, value]) => `
    @media (min-width: ${key}) {
      grid-template-columns: repeat(${value.columns || 2}, 1fr);
      gap: ${value.gap || 16}px;
      align-items: ${value.alignItems || 'stretch'};
    }
  `)}
`;

export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: auto;
  justify-content: center;
  width: 100%;
  &.align-top-left {
    align-items: flex-start;
    justify-content: flex-start;
  }
  &.gap-tiny {
    gap: ${distances.tiny};
  }
  &.gap-small {
    gap: ${distances.small};
  }
`;

export default Grid;
