import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { colors, distances, globalPageMaxWidth } from '../../../styles/constants';
import Page, { Back } from '../../../components/Page';
import { LoadingOverlay } from '../../../components/Loading';
import { H1 } from '../../../components/Typography';
import NotFound from './NotFound';
import MerchantDetails from './MerchantDetails';
import { Account } from '../../../types/management-auth';
import { BillingSubscription } from '../../../types/billing';
import { useParams } from 'react-router';
import { useAccountId } from '../../../auth/useAccountId';
import { useActions } from '../../../Actions';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { namespace as connectionEventsNamespace } from '../connectionEventsActions';
import { CheckoutConfig } from '../../../types/checkout';
import { ApprovalsPaymentResponse } from '../../../types/management-auth/generated';

export interface MerchantPageProps {
    getMerchant: (accountId: string, subAccountId: string) => void;
    getExchangeToken: (accountId: string, subAccountId: string) => void;
    merchant: undefined | Account;
    checkout: CheckoutConfig | undefined;
    subscriptions: BillingSubscription[];
    isLoading: boolean;
    isNotFound: boolean;
    downloadSignupFile: (accountId: string, subAccountId: string) => void;
    approvals: ApprovalsPaymentResponse[];
}

const MerchantPage = ({ getMerchant,  checkout, merchant, approvals,  subscriptions, isLoading, isNotFound, getExchangeToken, downloadSignupFile }: MerchantPageProps) => {
    const { subAccountId } = useParams();

    const accountId = useAccountId();
    const { t } = useTranslation();

    const eventActions = useActions(connectionEventsNamespace);
    const handleMarkAsDeclined = (connection: string, description: string) => {
        if (accountId && subAccountId) {
            eventActions.addConnectionEvent(accountId, subAccountId, connection, {
                status: 'declined',
                event: 'declined_from_backoffice',
                description,
            });
        }
    };

    const lastConnectionEventSuccess = useSelector((state) => state[connectionEventsNamespace].lastConnectionEventSuccess);
    const lastConnectionEventFailure = useSelector((state) => state[connectionEventsNamespace].lastConnectionEventFailure);

    useEffect(() => {
        getMerchant(accountId, subAccountId);
    }, [getMerchant, accountId, subAccountId, lastConnectionEventSuccess]);

    return (
        <Page
            title={
                <React.Fragment>
                    <Back />
                    <Title>{t('partner.merchant.title')}</Title>
                </React.Fragment>
            }
            hasEnvFrame={true}
        >
            <MaxWidth>
                {merchant && (
                    <MerchantDetails
                        checkout={checkout}
                        account={merchant}
                        subscriptions={subscriptions}
                        getExchangeToken={getExchangeToken}
                        partnerId={accountId}
                        downloadSignupFile={downloadSignupFile}
                        markAsDeclinedBag={{
                            handleMarkAsDeclined,
                            lastConnectionEventFailure,
                            lastConnectionEventSuccess,
                        }}
                        approvals={approvals}
                    />
                )}
                {isNotFound && <NotFound subAccountId={subAccountId} />}
                {isLoading && <LoadingOverlay />}
            </MaxWidth>
        </Page>
    );
};

const MaxWidth = styled.div`
    position: relative;
    width: 100%;
    max-width: ${globalPageMaxWidth}px;
    min-height: calc(${distances.ginormous} + ${distances.large});
`;

const Title = styled(H1)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default MerchantPage;
