import { reducer as clients, namespace as clientsNamespace } from './clients';
import { reducer as newClient, namespace as newClientNamespace } from './newClient';
import { reducer as clientDetails, namespace as clientDetailsNamespace } from './clientDetails';
import { reducer as newClientGrant, namespace as newClientGrantNamespace } from './newClientGrant';

export interface State
    extends clients.State {
    [newClientNamespace]: newClient.State;
    [clientDetailsNamespace]: clientDetails.State;
    [newClientGrantNamespace]: newClientGrant.State;
}

export const initialState: State = {
    ...clients.initialState,
    [newClientNamespace]: newClient.initialState,
    [clientDetailsNamespace]: clientDetails.initialState,
    [newClientGrantNamespace]: newClientGrant.initialState,
};

export const reducers = {
    ...clients.reducers,
    [newClientNamespace]: newClient.reducer,
    [clientDetailsNamespace]: clientDetails.reducer,
    [newClientGrantNamespace]: newClientGrant.reducer,
};
