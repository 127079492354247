import { withTranslation, type WithTranslation } from 'react-i18next';
import { Input, ValidationState } from '../../../../components/Forms';
import { getValueAt } from '../../../../helpers/getValueAt';

interface ValidatedInputProps extends WithTranslation {
    values: any;
    initialValues: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    maxlength?: number;
    type?: 'text' | 'password';
    formatter?: (value: string) => string;
    autoComplete?: string;
    autoFocus?: boolean;
}

const ValidatedInput = (
    {
        t,
        values,
        initialValues,
        errors,
        path,
        onChange,
        onBlur,
        touched,
        required,
        maxlength,
        type,
        autoComplete,
        autoFocus,
    }: ValidatedInputProps
) => {
    const value = getValueAt(path, values);
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    const validation =
        (was_touched || initialValue) && error
            ? {
                state: ValidationState.Invalid,
                message: error,
            }
            : undefined;
    const label = t(`settings.payment_connections.bambora_callback_modal.fields.${path}`);
    return (
        <Input
            label={label}
            placeholder={t(`settings.payment_connections.bambora_callback_modal.placeholders.${path}`)}
            type={type || 'text'}
            name={path}
            value={value || ''}
            onChange={(e) => {
                onChange(e);
            }}
            onBlur={(e) => {
                onChange(e);
                onBlur(e);
            }}
            validation={validation}
            maxlength={maxlength}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            required={required}
        />
    );
};

const TranslatedValidatedInput = withTranslation()(ValidatedInput);

export default TranslatedValidatedInput;
