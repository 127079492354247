import { effects as merchants } from './merchants';
import { effects as newPartner } from './newAccount';
import { effects as merchantDetails } from './merchantDetails';

const effects = {
    ...merchants,
    ...newPartner,
    ...merchantDetails,
};

export default effects;
