import { Subscription, Delivery, DeliveryDetails } from '../../../types/webhooks';
export enum ActionTypes {
    GetHook = '[settings/hookDetails] GET_HOOK',
    FetchHookResponse = '[settings/hookDetails] FETCH_HOOK_RESPONSE',
    OpenDeleteHookModal = '[settings/hookDetails] OPEN_DELETE_HOOK_MODAL',
    DismissDeleteHookModal = '[settings/hookDetails] DISMISS_DELETE_HOOK_MODAL',
    DeleteHook = '[settings/hookDetails] DELETE_HOOK',
    FetchDeleteHookResponse = '[settings/hookDetails] DELETE_HOOK_RESPONSE',
    OpenPingHookModal = '[settings/hookDetails] OPEN_PING_HOOK_MODAL',
    DismissPingHookModal = '[settings/hookDetails] DISMISS_PING_HOOK_MODAL',
    PingHook = '[settings/hookDetails] PING_HOOK',
    FetchPingHookResponse = '[settings/hookDetails] PING_HOOK_RESPONSE',
    GetDeliveries = '[settings/hookDetails] GET_DELIVERIES',
    FetchDeliveriesResponse = '[settings/hookDetails] GET_DELIVERIES_RESPONSE',
    GetDeliveryDetails = '[settings/hookDetails] GET_DELIVERY_DETAILS',
    FetchDeliveryDetailsResponse = '[settings/hookDetails] GET_DELIVERY_DETAILS_RESPONSE',
}

export interface GetHook {
    type: ActionTypes.GetHook;
    payload: {
        accountId: string;
        hookId: string;
    };
}

export interface FetchHookResponse {
    type: ActionTypes.FetchHookResponse;
    payload: {
        hook: Subscription;
    };
    meta: {
        cause: GetHook;
    };
}

export function getHook(accountId: string, hookId: string): GetHook {
    return {
        type: ActionTypes.GetHook,
        payload: {
            accountId,
            hookId,
        },
    };
}

export interface DismissDeleteHookModal {
    type: ActionTypes.DismissDeleteHookModal;
    payload: {};
}

export function dismissDeleteHookModal(): DismissDeleteHookModal {
    return {
        type: ActionTypes.DismissDeleteHookModal,
        payload: {},
    };
}

export interface OpenDeleteHookModal {
    type: ActionTypes.OpenDeleteHookModal;
    payload: {};
}

export function openDeleteHookModal(): OpenDeleteHookModal {
    return {
        type: ActionTypes.OpenDeleteHookModal,
        payload: {},
    };
}

export interface DeleteHook {
    type: ActionTypes.DeleteHook;
    payload: {
        accountId: string;
        hookId: string;
    };
}

export function deleteHook(accountId: string, hookId: string): DeleteHook {
    return {
        type: ActionTypes.DeleteHook,
        payload: {
            accountId,
            hookId,
        },
    };
}

export interface FetchDeleteHookResponse {
    type: ActionTypes.FetchDeleteHookResponse;
    payload: {};
    meta: {
        cause: DeleteHook;
    };
}

export interface OpenPingHookModal {
    type: ActionTypes.OpenPingHookModal;
    payload: {};
}

export function openPingHookModal(): OpenPingHookModal {
    return {
        type: ActionTypes.OpenPingHookModal,
        payload: {},
    };
}

export interface DismissPingHookModal {
    type: ActionTypes.DismissPingHookModal;
    payload: {};
}

export function dismissPingHookModal(): DismissPingHookModal {
    return {
        type: ActionTypes.DismissPingHookModal,
        payload: {},
    };
}

export interface PingHook {
    type: ActionTypes.PingHook;
    payload: {
        accountId: string;
        hookId: string;
    };
}

export function pingHook(accountId: string, hookId: string): PingHook {
    return {
        type: ActionTypes.PingHook,
        payload: {
            accountId,
            hookId,
        },
    };
}

export interface FetchPingHookResponse {
    type: ActionTypes.FetchPingHookResponse;
    payload: {};
    meta: {
        cause: PingHook;
    };
}

export interface GetDeliveries {
    type: ActionTypes.GetDeliveries;
    payload: {
        accountId: string;
        hookId: string;
    };
}

export function getDeliveries(accountId: string, hookId: string): GetDeliveries {
    return {
        type: ActionTypes.GetDeliveries,
        payload: {
            accountId,
            hookId,
        },
    };
}

export interface FetchDeliveriesResponse {
    type: ActionTypes.FetchDeliveriesResponse;
    payload: {
        deliveries: Delivery[];
    };
    meta: {
        cause: GetDeliveries;
    };
}

export interface GetDeliveryDetails {
    type: ActionTypes.GetDeliveryDetails;
    payload: {
        accountId: string;
        hookId: string;
        deliveryId: string;
    };
}

export function getDeliveryDetails(accountId: string, hookId: string, deliveryId: string): GetDeliveryDetails {
    return {
        type: ActionTypes.GetDeliveryDetails,
        payload: {
            accountId,
            hookId,
            deliveryId,
        },
    };
}

export interface FetchDeliveryDetailsResponse {
    type: ActionTypes.FetchDeliveryDetailsResponse;
    payload: {
        deliveryDetails: DeliveryDetails;
    };
    meta: {
        cause: GetDeliveries;
    };
}

export type Actions =
    | GetHook
    | FetchHookResponse
    | OpenDeleteHookModal
    | DismissDeleteHookModal
    | DeleteHook
    | OpenPingHookModal
    | DismissPingHookModal
    | FetchDeleteHookResponse
    | PingHook
    | FetchPingHookResponse
    | GetDeliveries
    | FetchDeliveriesResponse
    | GetDeliveryDetails
    | FetchDeliveryDetailsResponse;
