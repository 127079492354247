import { Route } from 'react-router-dom';

import LocationsList from './locationsList';
import LocationDetails from './locationDetails';
import NewLocation from './newLocation';
import LocationEdit from './locationEdit';

const Routes = [
    <Route key="locations" path="/:accountId/settings/locations" exact component={LocationsList} />,
    <Route key="newLocation" path="/:accountId/settings/locations/new" exact component={NewLocation} />,
    <Route key="locationDetails" path="/:accountId/settings/locations/:locationId" exact component={LocationDetails} />,
    <Route key="locationEdit" path="/:accountId/settings/locations/:locationId/edit" exact component={LocationEdit} />,
];

export default Routes;
