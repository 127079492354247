import { State } from '../../reducer';
import { namespace } from './';
import { createSelector } from 'reselect';

const getMerchantSelectorState = (state: State) => state[namespace].merchant;
export const getMerchantSelector = createSelector([getMerchantSelectorState], (s) => s);

const getMerchantCheckoutSelectorState = (state: State) => state[namespace].checkout;
export const getMerchantCheckoutSelector = createSelector([getMerchantCheckoutSelectorState], (s) => s);

const getMerchantSubscriptionsSelectorState = (state: State) => state[namespace].subscriptions;
export const getMerchantSubscriptionsSelector = createSelector([getMerchantSubscriptionsSelectorState], (s) => s);

const getIsLoadingSelectorState = (state: State) => state[namespace].isLoading;
export const getIsLoadingSelector = createSelector([getIsLoadingSelectorState], (s) => s);

const getIsNotFoundSelectorState = (state: State) => state[namespace].isNotFound;
export const getIsNotFoundSelector = createSelector([getIsNotFoundSelectorState], (s) => s);

const getEditingState = (state: State) => state[namespace].editing;
export const getEditingSelector = createSelector([getEditingState], (s) => s);

const getApprovalsPaymentsSelectorState = (state: State) => state[namespace].approvals;
export const getApprovalsPaymentsSelector = createSelector([getApprovalsPaymentsSelectorState], (s) => s);
