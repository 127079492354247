export const validateSwedishPostalNumber = (message: string) => {
    return (postal_number: string) => {
        if (!postal_number) {
            return;
        }
        if (/^[0-9]{3}\s?[0-9]{2}$/.test(postal_number)) {
            return;
        }
        return message;
    };
};

export const validateNorwegianPostalNumber = (message: string) => {
    return (postal_number: string) => {
        if (!postal_number) {
            return;
        }
        if (/^[0-9]{4}$/.test(postal_number)) {
            return;
        }
        return message;
    };
};

export const getPostalCodeValidator = (country: string, errorMessage: string) => {
    if (country === 'NO') {
        return validateNorwegianPostalNumber(errorMessage);
    } else if (country === 'SE') {
        return validateSwedishPostalNumber(errorMessage);
    }
    return (value: string) => {
        return undefined;
    };
};
