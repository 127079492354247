import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { colors } from '../styles/constants';

export const UnstyledLink = styled(Link)`
    text-decoration: none;
`;

export const StyledRouteLink = styled(Link)`
    color: ${colors.primary};
    &:hover {
        color: ${colors.primaryHover};
    }
`;

const StyledLink = styled.a`
    color: ${colors.primary};
    text-decoration: none;

    &:hover {
        color: ${colors.primaryHover};
    }
`;

export default StyledLink;
