import { Redirect, Route } from 'react-router-dom';

import PayoutDestinationList from './PayoutDestinationsList';
import PayoutDestination from './PayoutDestination';
import PayoutDestinationApproval from './PayoutDestinationApproval';


const Routes = [
    <Route key="payout-destinations-list" path="/:accountId/settings/sellers" exact component={PayoutDestinationList} />,
    <Route key="payout-destination-approval" path="/:accountId/settings/sellers/approvals/:payoutDestinationApprovalId" exact component={PayoutDestinationApproval} />,
    <Route key="payout-destination" path="/:accountId/settings/sellers/:payoutDestinationConfigId" exact component={PayoutDestination} />,
    <Redirect key="payout-destinations-to-sellers" to="/:accountId/settings/sellers" from="/:accountId/settings/payout-destinations" />,
];

export default Routes;
