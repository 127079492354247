import React from 'react';

import CheckboxListFilter, { Option } from '../../../components/Filters/CheckboxListFilter';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FilterProps } from './FilterProps';

export type ConnectionStatusProps = FilterProps

const getConnectionStatusName = (t: TFunction, value: string) => {
    const key = `settings.payment_connections.status.${value || 'undefined'}`;
    const translated = t(key);
    if (translated === key) {
        return value;
    }
    return translated;
};

const optionValues = [
    'enabled',
    'failed',
    'declined',
    'pending',
] as const;

const ConnectionStatusFilter = ({ queryParams, onChange, onClose }: ConnectionStatusProps) => {
    const { t } = useTranslation();

    const options: Option[] = optionValues.map((value) => ({
        label: t(`settings.payment_connections.status.${value}`),
        value,
    })).sort((a, b) => a.label.localeCompare(b.label));

    const values = queryParams.getAll('connection_status');

    return (
        <CheckboxListFilter
            label={t('partner.merchants.filter.connection_status.label', {
                count: values.length,
                value: values.map(value => getConnectionStatusName(t, value)).join(', '),
            })}
            title={t('partner.merchants.filter.connection_status.title')}
            name="connection_status"
            values={values}
            options={options}
            onApply={(name, values) => {
                onChange({ [name]: values });
            }}
            onDelete={name => {
                onChange({ [name]: []});
            }}
            onCloseMenu={onClose}
        />
    );
};

export default ConnectionStatusFilter;
