import { reducer as profiles, namespace as profilesNamespace } from './profiles';
import { reducer as newPaymentProfile, namespace as newPaymentProfileNamespace } from './newProfile';
import { reducer as editPaymentProfile, namespace as editPaymentProfileNamespace } from './editProfile';
import { reducer as paymentProfile, namespace as paymentProfileNamespace } from './profileDetails';

export interface State {
    [profilesNamespace]: profiles.State;
    [newPaymentProfileNamespace]: newPaymentProfile.State;
    [editPaymentProfileNamespace]: editPaymentProfile.State;
    [paymentProfileNamespace]: paymentProfile.State;
}

export const initialState: State = {
    [profilesNamespace]: profiles.initialState,
    [newPaymentProfileNamespace]: newPaymentProfile.initialState,
    [editPaymentProfileNamespace]: editPaymentProfile.initialState,
    [paymentProfileNamespace]: paymentProfile.initialState,
};

export const reducers = {
    [profilesNamespace]: profiles.reducer,
    [newPaymentProfileNamespace]: newPaymentProfile.reducer,
    [editPaymentProfileNamespace]: editPaymentProfile.reducer,
    [paymentProfileNamespace]: paymentProfile.reducer,
};
