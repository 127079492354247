import { useState } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Button, ButtonGroup } from '../../../../components/Buttons';
import Modal from '../../../../components/Modal';
import { H1, P } from '../../../../components/Typography';
import { distances } from '../../../../styles/constants';
import { Client, Grant } from '../../../../types/management-auth';
import { Warning } from './Warning';

export interface DeleteGrantModalProps extends WithTranslation {
    accountId: string;
    client: Client;
    grant: Grant;
    dismissDeleteGrantModal: () => void;
    deleteGrant: (accountId: string, grant_id: string) => void;
}

const DeleteGrantModal = (
    {
        t,
        client,
        grant,
        accountId,
        deleteGrant,
        dismissDeleteGrantModal,
    }: DeleteGrantModalProps
) => {
    const [rootAccountConfirm, setRootAccountConfirm] = useState(false);
    const isRootAccount = accountId === 'P00000000';
    return (
        <Modal>
            <H1>{t('settings.delete_client_grant.title', { name: client.name })}</H1>
            {isRootAccount &&
                <Warning flash={rootAccountConfirm} />
            }

            <TextWrapper>
                <P>{t('settings.delete_client_grant.description')}</P>
            </TextWrapper>

            <TextWrapper>
                <P>
                    <Trans i18nKey="settings.delete_client_grant.audience" values={{ audience: grant.audience }}>
                        This will remove the grant with audience <code>{grant.audience}</code>.
                    </Trans>
                </P>
            </TextWrapper>
            {
                (isRootAccount && !rootAccountConfirm) &&
                <Button
                    onClick={() => {
                        setRootAccountConfirm(true);
                    }}
                >
                    Yes I confirm that I want to delete this grant for the Dintero root account API key
                </Button>
            }
            <ButtonGroup>
                <Button className="alt" onClick={dismissDeleteGrantModal}>
                    {t('settings.delete_client_grant.go_back')}
                </Button>
                {(!isRootAccount || rootAccountConfirm) && <Button
                    onClick={() => {
                        deleteGrant(accountId, grant.id || '');
                    }}
                >
                    {t('settings.delete_client_grant.delete_client_call_to_action')}
                </Button>
                }
            </ButtonGroup>
        </Modal>
    );
};

export default withTranslation()(DeleteGrantModal);

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
