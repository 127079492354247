import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';
import { ActionTypes, FetchUserResponse, GetUser, RemoveUser, FetchRemoveUserResponse } from './actions';
import { AccountAdminUser } from '../../../types/management-auth';

const fetchUsers: SideEffectFunction<GetUser, FetchUserResponse> = async action => {
    const result: FetchUserResponse = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/users`,
        handlers: {
            200: (users: AccountAdminUser[]) => ({
                type: ActionTypes.FetchUserResponse,
                payload: {
                    user: users.find(user => user.id === action.payload.userId),
                    users: users,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchRemoveUser: SideEffectFunction<RemoveUser, FetchRemoveUserResponse> = async action => {
    const result: FetchRemoveUserResponse = await fulfill.delete({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/users/${action.payload.userId}`,
        handlers: {
            200: () => ({
                type: ActionTypes.FetchRemoveUserResponse,
                payload: {},
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.GetUser]: fetchUsers,
    [ActionTypes.RemoveUser]: fetchRemoveUser,
};

export default effects;
