import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Button } from '../../../components/Buttons';
import styled from 'styled-components/macro';
import { distances } from '../../../styles/constants';

interface PagingProps extends WithTranslation {
    isLoading: boolean;
    hasNext: boolean;
    next: () => void;
}

const Paging = (
    {
        next,
        hasNext,
        isLoading,
        t,
    }: PagingProps
) => {
    return (
        <Wrapper isLoading={isLoading}>
            {hasNext && (
                <Button className="alt" onClick={next}>
                    {t('partner.merchants.load_older')}
                </Button>
            )}
        </Wrapper>
    );
};

export default withTranslation()(Paging);

interface WrapperProps {
    isLoading: boolean;
}
const Wrapper = styled.div<WrapperProps>`
    display: flex;
    justify-content: center;
    padding: ${distances.normal};
    opacity: ${props => (props.isLoading ? '0' : '1')};
`;
