import { Actions, ActionTypes } from './actions';
import { Client, Grant, HasSecretClientResponse } from '../../../types/management-auth';
import { sortElements } from '../../helpers';

export interface State {
    isLoading: boolean;
    client: Client | undefined;
    grants: Grant[];
    isShowingDeleteModal: boolean;
    isDeleted: boolean;
    isShowingRotateSecretModal: boolean;
    rotated: HasSecretClientResponse | undefined;
    isSecretSafe: boolean;
    isShowingDeleteGrantModal: boolean;
    deleteGrant: Grant | undefined;
}

export const initialState: State = {
    isLoading: false,
    client: undefined,
    grants: [],
    isShowingDeleteModal: false,
    isDeleted: false,
    isShowingRotateSecretModal: false,
    rotated: undefined,
    isSecretSafe: false,
    isShowingDeleteGrantModal: false,
    deleteGrant: undefined,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.GetClient:
            return {
                ...initialState,
                isLoading: true,
            };

        case ActionTypes.FetchClientResponse:
            return {
                ...state,
                client: action.payload.client,
            };

        case ActionTypes.FetchGrantResponse:
            return {
                ...state,
                isLoading: false,
                grants: action.payload.grants,
            };

        case ActionTypes.OpenDeleteClientModal:
            return {
                ...state,
                isShowingDeleteModal: true,
            };

        case ActionTypes.DismissDeleteClientModal:
            return {
                ...state,
                isShowingDeleteModal: false,
            };

        case ActionTypes.FetchDeleteClientResponse:
            return {
                ...state,
                isDeleted: true,
                client: action.payload.client,
            };

        case ActionTypes.OpenRotateSecretModal:
            return {
                ...state,
                isShowingRotateSecretModal: true,
            };

        case ActionTypes.DismissRotateSecretModal:
            return {
                ...state,
                isShowingRotateSecretModal: false,
                rotated: undefined,
                isSecretSafe: false,
            };

        case ActionTypes.FetchRotateSecretResponse:
            return {
                ...state,
                rotated: action.payload.rotated,
                client: action.payload.rotated,
            };

        case ActionTypes.ToggleSecretIsSafe:
            return {
                ...state,
                isSecretSafe: action.payload.value,
            };

        case ActionTypes.OpenDeleteGrantModal:
            return {
                ...state,
                isShowingDeleteGrantModal: true,
                deleteGrant: action.payload.grant,
            };

        case ActionTypes.DismissDeleteGrantModal:
            return {
                ...state,
                isShowingDeleteGrantModal: false,
                deleteGrantId: undefined,
            };

        case ActionTypes.FetchDeleteGrantResponse:
            return {
                ...state,
                grants: sortElements(
                    state.grants.map(grant => {
                        if (grant.id === action.payload.grant.id) {
                            return action.payload.grant;
                        }
                        return grant;
                    })
                ),
                isShowingDeleteGrantModal: false,
                deleteGrantId: undefined,
            };

        default:
            return state;
    }
}
