import { Route } from 'react-router-dom';

import { Connections } from './connections';
import ApprovalsPage from './approvalsPayments/ApprovalsPage';

const Routes = [
    <Route key="/payment-connections/gateways" path="/:accountId/settings/payment-connections/gateways" exact component={Connections} />,
    <Route key="/payment-connections" path="/:accountId/settings/payment-connections" component={ApprovalsPage} />,
];

export default Routes;
