import { useState } from 'react';
import { Select } from '../../../../components/SimpleSelect/Select';
import CollectorDirectForm from './CollectorDirectForm';
import CollectorPayFacCustomForm from './CollectorPayFacCustom';
import CollectorPayFacForm from './CollectorPayFacForm';
import { ApprovalsPaymentResponse } from '../../../../types/management-auth/generated';
import { useTranslation } from 'react-i18next';

type CollectorFormProps = {
    activeApprovals: ApprovalsPaymentResponse[];
};

export default function CollectorForm({ activeApprovals }: CollectorFormProps) {
    const { t } = useTranslation();
    const [type, setType] = useState<'direct' | 'payfac' | 'payfac-custom'>('payfac');

    return (
        <>
            <Select
                label={t(
                    'settings.payment_connections.collector_modal.fields.agreement'
                )}
                defaultValue={type}
                items={[
                    { value: 'payfac', label: 'PayFac' },
                    { value: 'payfac-custom', label: 'PayFac with own Store IDs' },
                    { value: 'direct', label: 'Direct Agreement' },
                ].map((x) => ({
                    ...x,
                    label: t('settings.payment_connections.collector_modal.agreement', {
                        context: x.value,
                    }) }))}
                onChange={(value) => setType(value as typeof type)}
            />
            {type === 'direct' && <CollectorDirectForm activeApprovals={activeApprovals} />}
            {type === 'payfac-custom' && <CollectorPayFacCustomForm activeApprovals={activeApprovals} />}
            {type === 'payfac' && <CollectorPayFacForm activeApprovals={activeApprovals} />}
        </>
    );
}
