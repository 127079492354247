import React from 'react';

export const services = {
    accounts: ['read', 'write', 'admin'] as Access[],
    automations: ['read', 'write', 'admin'] as Access[],
    audit: ['read'] as Access[],
    billing: ['read', 'write', 'admin'] as Access[],
    checkout: ['read', 'write', 'admin'] as Access[],
    customers: ['read', 'write', 'admin'] as Access[],
    discounts: ['read', 'write', 'admin'] as Access[],
    hooks: ['read', 'write', 'admin'] as Access[],
    insight: ['read', 'write', 'admin'] as Access[],
    locations: ['read', 'write', 'admin'] as Access[],
    products: ['read', 'write', 'admin'] as Access[],
    receipts: ['read', 'write', 'admin'] as Access[],
    reports: ['read', 'write', 'admin'] as Access[],
    shopping: ['read', 'write', 'admin'] as Access[],
    wallets: ['read', 'write', 'admin'] as Access[],
    payout: ['read', 'write', 'admin'] as Access[],
} as const;

type ServiceName = keyof typeof services;

type Access = 'admin' | 'write' | 'read';

const toScope = (access: Access, service: string) => `${access}:${service}`;

export const isChecked = (scopes: string[], service: string, access: Access) => {
    const isAdmin = scopes.includes(`admin:${service}`);
    if (access === 'admin') {
        return isAdmin;
    }
    const scope = toScope(access, service);
    return isAdmin || scopes.includes(scope);
};

export const isCheckedDiscrete = (scopes: string[], service: string, access: Access) => {
    const scope = toScope(access, service);
    return scopes.includes(scope);
};

const highestAvailable = (accesses: Access[]): Access | undefined => {
    const priority: Access[] = ['admin', 'write', 'read'];
    for (const access of priority) {
        if (accesses.includes(access)) {
            return access;
        }
    }
    return undefined;
};

const enableAdminReadServiceAccess = (scopes: string[], access: Access, service: string) => {
    // if both read and write are present, replace with admin for service
    const scope = `${access}:${service}`;
    const serviceScopes = [scope, ...scopes.filter((x) => x.split(':').pop() === service)];
    const hasAdmin = serviceScopes.includes(toScope('admin', service));
    const hasRead = serviceScopes.includes(toScope('read', service));
    const hasWrite = serviceScopes.includes(toScope('write', service));
    if (hasAdmin || (hasRead && hasWrite)) {
        if (service === 'accounts') {
            // add highest available access to all services
            return Object.keys(services).reduce((scopes, key) => {
                const highestAccess = highestAvailable(services[key as ServiceName]);
                if (highestAccess) {
                    return [...scopes, toScope(highestAccess, key)];
                }
                return scopes;
            }, [] as string[]);
        }
        // add admin for service
        return [...scopes.filter((x) => x.split(':').pop() !== service), toScope('admin', service)];
    } else {
        // add new service
        return [...scopes, scope];
    }
};

const disableAdminReadServiceAccess = (scopes: string[], access: string, service: string) => {
    // if non-admin is removed, make sure admin is also removed for service
    const scopesToRemove = [`admin:${service}`, `${access}:${service}`];
    const filtered = scopes.filter((scope) => !scopesToRemove.includes(scope));
    // if admin was removed, add read access to service
    const isDisablingAdmin = access === 'admin';
    const scopesToAdd = isDisablingAdmin ? [toScope('read', service)] : [];
    return [...filtered, ...scopesToAdd];
};

export const getNextAdminReadScopes = (scopes: string[], access: Access, service: string, enabled: boolean) => {
    if (enabled) {
        return enableAdminReadServiceAccess(scopes, access, service);
    }
    return disableAdminReadServiceAccess(scopes, access, service);
};

export const handleAdminReadScopeToggle = (scopes: string[], e: React.ChangeEvent<HTMLInputElement>) => {
    const scope = e.currentTarget.value;
    const [access, service] = scope.split(':');
    const enabled = e.currentTarget.checked;
    return getNextAdminReadScopes(scopes, access as Access, service, enabled);
};

export const hasAll = (scopes: string[], access: Access) => {
    return Object.keys(services)
        .filter((service) => {
            return !['write', 'admin'].includes(access) || services[service as ServiceName].includes('admin');
        })
        .reduce((reduced, service) => reduced && isChecked(scopes, service, access), true);
};

export const hasAllDiscrete = (scopes: string[], access: Access) => {
    const servicesWithAccess = Object.keys(services).filter((service) =>
        services[service as ServiceName].includes(access));
    return servicesWithAccess.every((service) => isCheckedDiscrete(scopes, service, access), true);
};

export const toggleAllAdminRead = (scopes: string[], access: Access) => {
    const enable = !hasAll(scopes, access);
    return Object.keys(services).reduce((reduced, service) => {
        if (!services[service as ServiceName].includes(access)) {
            return reduced;
        }
        return getNextAdminReadScopes(reduced, access, service, enable);
    }, scopes);
};

export const getNextWriteReadScopes = (scopes: string[], scope: string, enabled: boolean) => {
    const filtered = scopes.filter((s) => s !== scope);
    if (enabled) {
        return [...filtered, scope];
    }
    return filtered;
};

export const handleWriteReadScopeToggle = (scopes: string[], e: React.ChangeEvent<HTMLInputElement>) => {
    const scope = e.currentTarget.value;
    const enabled = e.currentTarget.checked;
    return getNextWriteReadScopes(scopes, scope, enabled);
};

export const toggleAllWriteReadAdmin = (scopes: string[], access: 'read' | 'write' | 'admin') => {
    const enable = !hasAll(scopes, access);
    return Object.keys(services).reduce((reduced, service) => {
        const scope = toScope(access, service);
        return getNextWriteReadScopes(reduced, scope, enable);
    }, scopes);
};

export const adminToWriteRead = (scopes: string[]) => {
    // explode all admin to write and read
    return scopes.reduce<string[]>((result, scope) => {
        const [access, service] = scope;
        if (access === 'admin') {
            return [...result, toScope('write', service), toScope('read', service)];
        }
        return [...result, scope];
    }, []);
};
