/**
 * This utility function is used to prevent iOS from zooming in on inputs while allowing the user to zoom in on the page.
 * Extracted from Quickr v2
 */
export const determineMaximumScaleViewport = () => {
    const el = document.querySelector('meta[name=\'viewport\']');
    const isApple =
        /iPad|iPhone|iPod/.test(navigator.userAgent) &&
        !(window as any).MSStream;
    if (el && isApple) {
        let content = el.getAttribute('content') as string;
        const re = /maximum-scale=[0-9.]+/g;

        if (re.test(content)) {
            content = content.replace(re, 'maximum-scale=1.0');
        } else {
            content = [content, 'maximum-scale=1.0'].join(', ');
        }

        el.setAttribute('content', content);
    }
};
