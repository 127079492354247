import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';
import { ActionTypes, FetchUserResponse, GetUser, EditUser, FetchEditUserResponse } from './actions';
import { AccountAdminUser } from '../../../types/management-auth';
import { UpdateAccountUserBody } from './types';

const fetchUsers: SideEffectFunction<GetUser, FetchUserResponse> = async action => {
    const result: FetchUserResponse = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/users`,
        handlers: {
            200: (users: AccountAdminUser[]) => ({
                type: ActionTypes.FetchUserResponse,
                payload: {
                    user: users.find(user => user.id === action.payload.userId),
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchEditUser: SideEffectFunction<EditUser, FetchEditUserResponse> = async action => {
    const result: FetchEditUserResponse = await fulfill.put<UpdateAccountUserBody>({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/users/${action.payload.userId}`,
        json: action.payload.user,
        handlers: {
            200: () => ({
                type: ActionTypes.FetchEditUserResponse,
                payload: {},
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.GetUser]: fetchUsers,
    [ActionTypes.EditUser]: fetchEditUser,
};

export default effects;
