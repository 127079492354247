import { connect } from 'react-redux';

import { getHooks } from '../actions';
import { State } from '../../../../reducer';
import { getHooksSelector } from '../selectors';

import HooksList from '../components/HooksList';

const mapStateToProps = (state: State) => ({
    hooks: getHooksSelector(state),
});

const mapDispatchToProps = {
    getHooks: getHooks,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HooksList);
