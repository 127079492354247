import { AuthenticatedAccountUser } from '../../types/management-auth';

export interface AccountUserAccount {
    account_id: string;
    business_name?: string;
    display_name?: string;
    organization_number?: string;
    icon_url?: string;
    user: {
        id: string;
        last_seen_at?: string;
        scope?: string[];
        name?: string;
        email: string;
    };
}

const getNonDupedAccountList = (accountUser: AuthenticatedAccountUser) => {
    const accountsById: { [key: string]: AccountUserAccount } = accountUser.accounts.reduce(
        (reduced: any, account: AccountUserAccount) => {
            const idWithoutLeadingChar = account.account_id.substr(1);
            if (idWithoutLeadingChar in reduced) {
                const inReduced = reduced[idWithoutLeadingChar].account_id.substr(0, 1);
                // replace reduced test account with current
                if (inReduced === 'T') {
                    return {
                        ...reduced,
                        [idWithoutLeadingChar]: account,
                    };
                } else {
                    return reduced;
                }
            }
            return {
                ...reduced,
                [idWithoutLeadingChar]: account,
            };
        },
        {}
    );
    return Object.values(accountsById).sort((a: AccountUserAccount, b: AccountUserAccount) =>
        new Date(b.user.last_seen_at ?? 0).getTime() - new Date(a.user.last_seen_at ?? 0).getTime());
};

export { getNonDupedAccountList };
