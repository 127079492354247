import { connect } from 'react-redux';

import { getProfile, openRemoveProfileModal, dismissRemoveProfileModal, removeProfile } from '../actions';
import { State } from '../../../../reducer';
import { getProfileSelector } from '../selectors';

import ProfilePage from '../components/ProfilePage';

const mapStateToProps = (state: State) => ({
    profile: getProfileSelector(state),
});

const mapDispatchToProps = {
    getProfile,
    openRemoveProfileModal,
    dismissRemoveProfileModal,
    removeProfile,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilePage);
