import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { H1, P } from '../../../../components/Typography';
import Modal from '../../../../components/Modal';
import { Subscription } from '../../../../types/webhooks';
import { Button } from '../../../../components/Buttons';
import { distances } from '../../../../styles/constants';

export interface PingHookModalProps extends WithTranslation {
    accountId: string;
    hook: Subscription;
    dismissPingHookModal: () => void;
    pingHook: (accountId: string, hookId: string) => void;
}

const PingHookModal = (
    {
        t,
        hook,
        accountId,
        pingHook,
        dismissPingHookModal,
    }: PingHookModalProps
) => {
    return (
        <Modal>
            <H1>{t('settings.ping_hook.title', { url: new URL(hook.config.url).host })}</H1>

            <TextWrapper>
                <P>{t('settings.ping_hook.description')}</P>
                <P><code>{hook.config.url}</code></P>
            </TextWrapper>
            <Button
                onClick={() => {
                    pingHook(accountId, hook.id || '');
                }}
            >
                {t('settings.ping_hook.ping_hook_call_to_action')}
            </Button>
            <ButtonSpacer />
            <Button className="alt" onClick={dismissPingHookModal}>
                {t('settings.ping_hook.go_back')}
            </Button>
        </Modal>
    );
};

export default withTranslation()(PingHookModal);

const ButtonSpacer = styled.span`
    display: inline-block;
    width: ${distances.tiny};
`;

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
