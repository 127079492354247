import * as React from 'react';
import styled from 'styled-components/macro';

import { colors, distances, border } from '../../styles/constants';

export type OnChangeFunction = (name: string, value: boolean) => void;

interface RadiobuttonProps {
    name: string;
    label: any;
    value: boolean;
    className?: string;
    onChange: OnChangeFunction;
    disabled?: boolean;
    banned?: boolean;
    children?: React.ReactNode;
}

const Radiobutton = (props: RadiobuttonProps) => {
    const [focus, setFocus] = React.useState(false);
    return (
        <Label
            className={props.className || ''}
            checked={props.value}
            disabled={props.disabled || false}
            banned={props.banned || false}
            focus={focus}
        >
            <HiddenBox
                type="checkbox"
                checked={props.value}
                name={props.name}
                disabled={props.disabled}
                onChange={(event) => {
                    props.onChange(props.name, event.target.checked);
                }}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
            />
            {props.label}
        </Label>
    );
};

interface LabelProps {
    checked: boolean;
    disabled: boolean;
    banned: boolean;
    focus: boolean;
}

const getBackground = (props: LabelProps) => {
    if (props.banned) {
        return `
            background-image: url('/assets/icons/ban.svg');
            background-size: contain;
        `;
    }
    if (props.checked) {
        return `
            background-color: ${colors.primary};
            background-image: url('/assets/icons/input_selected.svg');
        `;
    }
    return '';
};

const Label = styled.label<LabelProps>`
    display: inline-block;
    position: relative;
    user-select: none;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    opacity: ${(props) => (props.disabled ? '0.4' : '1')};

    &:before {
        content: '';
        position: relative;
        vertical-align: middle;
        width: ${distances.small};
        height: ${distances.small};
        border-radius: 2px;
        bottom: 1px;
        margin-right: ${distances.tiny};
        background-color: transparent;
        ${(props) => getBackground(props)}
        border: ${(props) => (props.checked ? 'none' : `${border.normal} solid ${colors.borderDark}`)};
        display: inline-block;
        transition: all 100ms ease-out;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        ${props => props.focus ? `border-color:${colors.primaryFocusOutline};`: ''}

    }

    &.half:before {
        background-image: ${(props) =>
        props.checked ? 'url(/assets/icons/input_checked.svg)' : 'url(/assets/icons/input_half_checked.svg)'};
    }

    &:hover:before {
        border: ${border.normal} solid ${colors.primaryHover};
        border-color: ${(props) => (props.checked ? colors.primary : colors.primaryHover)};
    }

`;

const HiddenBox = styled.input`
    opacity: 0;
    height: 0;
    width: 0;
`;

export default Radiobutton;
