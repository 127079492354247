import { useEffect, useRef } from 'react';
import styled from 'styled-components/macro';
import useWindowSize from '../../util/useWindowSize';
import { colors, palette } from '../../styles/constants';

type SegmentedControlOption = {
    value: string;
    label: string;
    disabled?: boolean;
};

type SegmentedControlProps = {
    options: SegmentedControlOption[];
    value: string;
    onChange: (value: string) => void;
    disabled?: boolean;
}

const SegmentedControl = ({ options, value, onChange, disabled }: SegmentedControlProps) => {
    const selectedRef = useRef<HTMLButtonElement>(null);
    const indicatorRef = useRef<HTMLDivElement>(null);
    const areaRef = useRef<HTMLDivElement>(null);
    const size = useWindowSize();

    useEffect(() => {
        if (selectedRef.current && indicatorRef.current && areaRef.current) {
            const selected = selectedRef.current.getBoundingClientRect();
            const area = areaRef.current.getBoundingClientRect();

            indicatorRef.current.style.left = `${selected.left - area.left}px`;
            indicatorRef.current.style.width = `${selected.width}px`;
        }
    }, [value, size]);

    return (
        <Wrapper>
            <Area ref={areaRef}>
                {options.map((option) => (
                    <Option
                        disabled={disabled || option.disabled}
                        key={option.value}
                        role="radio"
                        aria-checked={option.value === value}
                        onClick={() => onChange(option.value)}
                        type="button"
                        {...(option.value === value && { ref: selectedRef })}
                    >
                        {option.label}
                    </Option>
                ))}
                <Indicator ref={indicatorRef} />
            </Area>
        </Wrapper>
    );
};


const Wrapper = styled.div`
    display: inline-block;
`;

const Area = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    border-radius: 6px;
    align-items: center;
    justify-content: center;
    background-color: ${palette.neutral[100]};
    padding: 4px;
    gap: 4px;
`;

const Option = styled.button<{ 'aria-checked': boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: ${(props) => (props['aria-checked'] ? colors.background : colors.text)};
    height: 40px;
    padding: 0 20px;
    z-index: 1;
    cursor: pointer;
    border: none;
    background-color: transparent;
    border-radius: 4px;
    font-weight: 600;
    &:focus {
        outline: none;
    }
`;

const Indicator = styled.div`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    height: calc(100% - 8px);
    margin-top: 4px;
    border-radius: 4px;
    background-color: ${colors.primary};
    transition: all 300ms cubic-bezier(0.339, 0.575, 0.119, 1);
`;

export default SegmentedControl;
