import React, {  } from 'react';
import { withTranslation } from 'react-i18next';

import InputFilter from '../../../../../components/Filters/InputFilter';
import { FilterProps } from './FilterProps';

export type PhoneNumberFilterProps = FilterProps

const PhoneNumberFilter = (
    {
        queryParams,
        t,
        onChange,
    }: PhoneNumberFilterProps
) => {
    const value = queryParams.get('phone_number') || '';

    return (
        <InputFilter
            label={value ? value : ''}
            name="phone_number"
            title={t('locations.filter.phone_number.title')}
            value={value}
            placeholder={''}
            validate={(value: string) => {
                if (!value) {
                    return 'error';
                }
                return;
            }}
            autoFocus={true}
            onApply={(value) => {
                onChange({ phone_number: value ? [value] : []});
            }}
            onDelete={(_) => {
                onChange({
                    phone_number: [],
                });
            }}
        />
    );
};

export default withTranslation()(PhoneNumberFilter);
