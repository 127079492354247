import { CORE_API_HOSTNAME } from '../../../env';
import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { Account } from '../../../types/management-auth';
import { FetchInitiateInstabankConnectionResponse, ActionTypes as InstabankActionTypes } from '../instabank/actions';
import {
    FetchCompletePayExConnectionResponse,
    ActionTypes as PayExCallbackActionTypes
} from '../payexCallback/actions';
import { ActionTypes, FetchAccountResponse, GetAccount } from './actions';

const fetchAccount: SideEffectFunction<GetAccount, FetchAccountResponse> = async (action) => {
    const result: FetchAccountResponse = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/settings`,
        handlers: {
            200: (account: Account) => ({
                type: ActionTypes.FetchAccountResponse,
                payload: {
                    account,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const refetchAccount: SideEffectFunction<
    | FetchInitiateInstabankConnectionResponse
    | FetchCompletePayExConnectionResponse,
    FetchAccountResponse
> = async (action) => {
    const accountId = action.meta.cause.payload.accountId;
    const result: FetchAccountResponse = await fulfill.get({
        accountId: accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings`,
        handlers: {
            200: (account: Account) => ({
                type: ActionTypes.FetchAccountResponse,
                payload: {
                    account,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.GetAccount]: fetchAccount,
    [InstabankActionTypes.FetchInitiateInstabankConnectionResponse]: refetchAccount,
    [PayExCallbackActionTypes.FetchCompletePayExConnectionResponse]: refetchAccount,
};

export default effects;
