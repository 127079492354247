import React from 'react';
import styled from 'styled-components/macro';

import { colors, distances } from '../../styles/constants';
import Dropdown, { DropdownOption } from '../../components/Forms/Dropdown';
import { useTranslation } from 'react-i18next';
import { InputValidation } from '../../components/Forms';

import { Label } from '../../components/Typography';

type CustomerTypeDropdownProps = {
    value: string;
    name: string;
    label: string;
    onChange: (value: string) => void;
    validation?: InputValidation;
    disabled?: boolean;
}

const optionValues = ['b2c', 'b2b'];


const CustomerTypeDropdown = (
    {
        label,
        name,
        value,
        onChange,
        validation,
        disabled,
    }: CustomerTypeDropdownProps
) => {
    const { t } = useTranslation();
    const options: DropdownOption[] = optionValues
        .map(value => ({
            label: t('settings.payment_profile.values.default_customer_type', { context: value }),
            value,
        }));


    const selectedOption = options.find(option => option.value === value);
    return (
        <Wrapper>
            <Label htmlFor={name}>{label}</Label>
            {selectedOption && (
                <Dropdown
                    name={name}
                    value={selectedOption}
                    options={options}
                    onChange={val => {
                        onChange(val.value);
                    }}
                    disabled={disabled}
                />
            )}
            {validation && validation.message && (
                <Message htmlFor={name} className={validation.state}>
                    {validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

const Message = styled.label`
    position: absolute;
    top: 66px;
    left: 0;
    font-size: 11px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
`;

export default CustomerTypeDropdown;
