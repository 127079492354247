import React, {  } from 'react';

interface SendToPhoneProps {
    width?: string;
    height?: string;
    fill?: string;
}

const SendToPhone = (
    {
        width = '100%',
        height = '100%',
        fill = '#0000FF',
    }: SendToPhoneProps
) => (<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g
        fill={fill}
        stroke={fill}
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeWidth="2"
    >
        <line fill="none" strokeLinecap="butt" x1="1" x2="16" y1="16" y2="16" />
        <polyline fill="none" points="12 12 16 16 12 20" />
        <path
            d="M6,20v8a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V4a3,3,0,0,0-3-3H9A3,3,0,0,0,6,4v8"
            fill="none"
            stroke={fill}
        />
    </g>
</svg>);

export default SendToPhone;
