import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { distances, grid } from '../../../styles/constants';
import { H1, H3 } from '../../../components/Typography';
import { ButtonLink } from '../../../components/Buttons';

export interface NotFoundProps extends WithTranslation {
    subAccountId: string;
}

const NotFound = (
    {
        t,
        subAccountId,
    }: NotFoundProps
) => {
    return (
        <HeroWrapper>
            <H1>
                {t('partner.merchants.fields.account')} {subAccountId}
            </H1>
            <H3>{t('partner.merchant.not_found.message')}</H3>
            <ActionWrapper>
                <ButtonLink to="./">{t('partner.merchant.not_found.call_to_action')}</ButtonLink>
            </ActionWrapper>
        </HeroWrapper>
    );
};

export default withTranslation()(NotFound);

const HeroWrapper = styled.div`
    padding-top: ${distances.large};
    padding-bottom: ${distances.large};
    padding-left: calc(${grid.spans.span2} + ${grid.gutter} + ${distances.normal});
    padding-right: calc(${grid.gutter});
    background: rgba(218, 227, 232, 1);
    width: 100%;
    display: block;
`;

const ActionWrapper = styled.div`
    margin-top: ${distances.small};
`;
