import { connect } from 'react-redux';

import { createClient, resetForm, toggleSecretIsSafe } from '../actions';
import { State } from '../../../../reducer';
import { getClientSelector } from '../selectors';
import { getAccountUser } from '../../../../auth/accessToken/selectors';

import NewClientForm from '../components/NewClientForm';

const mapStateToProps = (state: State) => ({
    client: getClientSelector(state),
    accountUser: getAccountUser(state),
});

const mapDispatchToProps = {
    createClient,
    resetForm,
    toggleSecretIsSafe,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewClientForm);
