import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import { useAccount } from '../../account/hooks';
import { usePaymentApprovals } from './hooks';
import Page from '../../../components/Page';
import ApprovalsPaymentsForm from './ApprovalsPaymentsForm';
import ApprovalsStatus from './ApprovalStatus';
import PaymentMethodConfiguration from './PaymentMethodConfiguration';
import { useCheckoutConfiguration } from '../../checkoutConfigurationHooks';
import { CheckoutConfig } from '../../../types/checkout';
import PaymentsLoading from './PaymentsLoading';
import { Account } from '../../../types/management-auth';
import { ApprovalsPaymentResponse } from '../../../types/management-auth/generated';
import { useAccountIds } from '../../../auth/accessToken/withAccountIds';
import { AllowDinteroRoot } from '../../../auth/accessToken/Allow';
import { ButtonLink } from '../../../components/Buttons';
import FinishAccountInfobox from '../../components/FinishAccountInfobox';
import Card from '../../../components/Card';

const hasAnyPaymentMethod = (checkoutConfiguration: CheckoutConfig | undefined) => {
    if (!checkoutConfiguration) {
        return false;
    }
    return (checkoutConfiguration?.configuration?.payment_options?.length || 0) > 0;
};

const hasAnyConnection = (account: Account | undefined) => {
    if (!account) {
        return false;
    }
    return Object.keys(account?.connections || {}).length  > 0;
};

const checkShowApprovalsStatus = (approvals: ApprovalsPaymentResponse[], checkoutConfiguration: CheckoutConfig | undefined, account: Account | undefined) => {
    // Hide approvals status if user has no approvals and the user already has payment methods or connections configured.
    const noApprovals = approvals.length === 0;
    const approvalIsActive = approvals.some(a => a.case_status === 'ACTIVE');
    const shouldHide = (
        (
            noApprovals && (hasAnyPaymentMethod(checkoutConfiguration) || hasAnyConnection(account))
        ) || approvalIsActive
    );

    return !shouldHide;
};


const hasAnyApprovals = (approvals: ApprovalsPaymentResponse[]) => {
    return (approvals || []).length > 0;
};

const ApprovalsPage = () => {
    const { t } = useTranslation();
    const { urlAccountId, prodAccountId } = useAccountIds();
    const { isLoading: isLoadingAccount, account } = useAccount();
    const { isLoading: isLoadingApprovals,  approvals } = usePaymentApprovals();
    const { isLoading: isLoadingCheckoutConfiguration, checkoutConfiguration } = useCheckoutConfiguration('P');

    if (isLoadingAccount || isLoadingApprovals || isLoadingCheckoutConfiguration) {
        return <PaymentsLoading/>;
    }
    if (approvals === undefined && !prodAccountId) {
        return  <Page title={t('settings.approvalsPayments.title')}>
            <Card>
                <FinishAccountInfobox context="payment_connections" />
            </Card>
        </Page>;
    }

    if (account && approvals) {
        const showApprovalsStatus = checkShowApprovalsStatus(approvals, checkoutConfiguration, account);
        const showPaymentMethodConfiguration = (hasAnyApprovals(approvals) || hasAnyPaymentMethod(checkoutConfiguration) || hasAnyConnection(account));
        return (
            <Page title={t('settings.approvalsPayments.title')}><Switch>
                <Route path="/:accountId/settings/payment-connections/form" exact component={() => <ApprovalsPaymentsForm account={account}/>} />
                <Route path="/:accountId/settings/payment-connections" component={() => (<>
                    {showApprovalsStatus && (
                        <>
                            <ApprovalsStatus approvals={approvals} account={account} />
                            <br />
                        </>
                    )}
                    {showPaymentMethodConfiguration && (
                        <PaymentMethodConfiguration approvals={approvals} checkoutConfiguration={checkoutConfiguration} account={account} />
                    )}
                </>)} />
            </Switch>
            {
                !(
                    window.location.pathname === `/${urlAccountId}/settings/payment-connections/form` &&
                    window.location.search === '?showAll=true'
                )&&
                <AllowDinteroRoot visibleFrame>
                    <ButtonLink to={`/${urlAccountId}/settings/payment-connections/form?showAll=true`}>{t('settings.approvalsPayments.adminFullFormLink')}</ButtonLink>
                </AllowDinteroRoot>
            }

            </Page>);
    }
    return null;
};

export default ApprovalsPage;
