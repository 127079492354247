import styled from 'styled-components/macro';

import { distances } from '../../styles/constants';
import View from '../View';

const ButtonGroup = styled(View).attrs(props => ({
    ...props,
    justify: 'flex-end',
    gap: distances.small,
    className: 'button-group',
}))`
    @media (max-width: 768px) {
        justify-content: center;
        flex-direction: column;
        & > button {
            width: 100%;
            flex: 1;
        }
    }
`;

export default ButtonGroup;
