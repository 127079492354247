import iso3166 from 'iso-3166-2';

export const formatCountryCode = (iso3166Code: string | undefined) => {
    if (iso3166Code) {
        const country = iso3166.country(iso3166Code);
        if (country && 'name' in country) {
            return country.name;
        }
    }
    return iso3166Code;
};
