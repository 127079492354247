import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { AccountAdminUser } from '../../../../types/management-auth';
import Hero from './Hero';
import DetailsInfo from './DetailsInfo';

export interface TransactionDetailsProps extends WithTranslation {
    user: AccountAdminUser;
    users: AccountAdminUser[];
}

const TransactionDetails = (
    {
        user,
        users,
    }: TransactionDetailsProps
) => {
    return (
        <Wrapper>
            <Hero user={user} />
            <DetailsInfo user={user} users={users} />
        </Wrapper>
    );
};

export default withTranslation()(TransactionDetails);

const Wrapper = styled.div`
    position: relative;
`;
