import styled from 'styled-components/macro';

import { grid } from '../../../../../styles/constants';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '../../../../../components/Buttons';

import { useFormik } from 'formik';
import { capitalize } from '../../../../../helpers/string';
import {
    validate,
    validateHexadecimal,
    validateLength,
    validateRequired,
    validateUuid
} from '../../../../../helpers/validation';
import { ValidatedInput } from './ValidatedInput';
import { OverrideDialogProps } from '../../types';
import Modal from '../../../../../components/Modal';
import View from '../../../../../components/View';
import { H2 } from '../../../../../components/Typography';

const PayexOverrideDialog = (
    {
        gateway,
        putOverride,
        isLoading,
        isDialogOpen,
        handleCloseDialog,
    }: OverrideDialogProps
) => {
    const { t } = useTranslation();
    const { name, gatewayDetails } = gateway;
    const formik = useFormik({
        initialValues: {
            payee_id: gatewayDetails.find(detail => detail.key === 'gateway_id')?.value || '',
            token: '',
            subsite: gatewayDetails.find(detail => detail.key === 'subsite')?.value || '',
        },
        validate: (values => {
            let errors: { [key: string]: string } = {};
            if (!values || Object.keys(values).length === 0) {
                // this should not happen but...
                return { error: 'account not loaded yet...' };
            }

            const validators = [
                {
                    path: 'token',
                    validator: validateHexadecimal(
                        t('locations.location_details.payment_connections.edit_override.payex.invalid.token.invalid')
                    ),
                },
                {
                    path: 'token',
                    validator: validateLength(
                        64,
                        t('locations.location_details.payment_connections.edit_override.payex.invalid.token.invalid')
                    ),
                },
                {
                    path: 'token',
                    validator: validateRequired(
                        t('locations.location_details.payment_connections.edit_override.payex.invalid.token.required')
                    ),
                },
                {
                    path: 'payee_id',
                    validator: validateUuid(
                        t('locations.location_details.payment_connections.edit_override.payex.invalid.payee_id.invalid')
                    ),
                },
                {
                    path: 'payee_id',
                    validator: validateRequired(
                        t('locations.location_details.payment_connections.edit_override.payex.invalid.payee_id.required')
                    ),
                },
            ];
            errors = validators.reduce((acc, elem) => {
                return validate(elem.path, elem.validator, values, acc);
            }, errors);
            return errors;
        }),
        onSubmit: values => {
            const modifiedValues = {
                ...values,
                subsite: values.subsite || undefined,
            };
            putOverride(name, modifiedValues);
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            {isDialogOpen ? <Modal onClose={handleCloseDialog}>
                <View gap={24} direction="column" justify="flex-start" alignItems="flex-start">
                    <View direction="column" justify="flex-start" alignItems="flex-start">
                        <H2>
                            {t('locations.location_details.payment_connections.edit_override.title', { gateway: capitalize(gateway.name) })}
                        </H2>
                        <InputWrapper>
                            <ValidatedInput
                                values={formik.values}
                                errors={formik.errors}
                                path="payee_id"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                touched={formik.touched}
                                label={t('locations.location_details.payment_connections.edit_override.payex.fields.payee_id')}
                                placeholder={t('locations.location_details.payment_connections.edit_override.payex.placeholders.payee_id')}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <ValidatedInput
                                values={formik.values}
                                errors={formik.errors}
                                path="token"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                touched={formik.touched}
                                label={t('locations.location_details.payment_connections.edit_override.payex.fields.token')}
                                placeholder={t('locations.location_details.payment_connections.edit_override.payex.placeholders.token')}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <ValidatedInput
                                values={formik.values}
                                errors={formik.errors}
                                path="subsite"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                touched={formik.touched}
                                label={t('locations.location_details.payment_connections.edit_override.payex.fields.subsite')}
                                placeholder={t('locations.location_details.payment_connections.edit_override.payex.placeholders.subsite')}
                            />
                        </InputWrapper>
                    </View>
                    <ButtonGroup>
                        <Button
                            disabled={isLoading}
                            className="alt"
                            type="button"
                            onClick={handleCloseDialog}
                        >
                            {t('locations.location_details.payment_connections.edit_override.cancel')}
                        </Button>
                        <Button disabled={isLoading || !formik.isValid}
                            type="submit">{t('locations.location_details.payment_connections.edit_override.save')}</Button>
                    </ButtonGroup>
                </View>
            </Modal> : null}
        </form>
    );
};

const InputWrapper = styled.div`
  margin-right: ${grid.gutter};

  &:last-child {
    margin: 0;
  }
`;

export default PayexOverrideDialog;
