import React, {  } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { H1, P } from '../../../components/Typography';
import Modal from '../../../components/Modal';
import { Button } from '../../../components/Buttons';
import { distances } from '../../../styles/constants';

interface InvalidAddressModalProps {
    dismissModal: () => void;
}

const InvalidAddressModal = (
    {
        dismissModal,
    }: InvalidAddressModalProps
) => {
    const { t } = useTranslation('app');

    return (
        <Modal>
            <H1>{t('locations.invalid_address.title')}</H1>
            <TextWrapper>
                <P>{t('locations.invalid_address.description')}</P>
            </TextWrapper>
            <Button onClick={dismissModal}>
                {t('locations.invalid_address.back')}
            </Button>
        </Modal>
    );
};

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;

export default InvalidAddressModal;
