import React, {  } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Card from '../../../../components/Card';
import { P, H3 } from '../../../../components/Typography';
import { formatDate } from '../../../../helpers/formatters';
import { distances } from '../../../../styles/constants';
import { BillingPayoutRule } from '../../../../types/billing';

interface DetailedDataProps {
    payoutRule: BillingPayoutRule;
}

const DetailedData = (
    {
        payoutRule,
    }: DetailedDataProps
) => {
    const { t, i18n } = useTranslation();

    return (
        <Card title={t('payout_rules.payout_rules_details.fields.data')} fitContent>
            <DataWrapper>
                <Field>
                    <StyledH3>{t('payout_rules.payout_rules_details.fields.id')}</StyledH3>
                    <P>{`${payoutRule.rule_type} ${payoutRule.rule_id}`}</P>
                </Field>
                <Field>
                    <StyledH3>{t('payout_rules.payout_rules_details.fields.type')}</StyledH3>
                    <P>{payoutRule.rule_type}</P>
                </Field>
                <Field>
                    <StyledH3>{t('payout_rules.payout_rules_details.fields.status')}</StyledH3>
                    <P>
                        {t('payout_rules.payout_rules.fields.statustext' as any, {
                            context: payoutRule.deleted_at && payoutRule.deleted_by ? 'deleted' : 'active',
                        })}
                    </P>
                </Field>
                <Field>
                    <StyledH3>{t('payout_rules.payout_rules_details.fields.created_by')}</StyledH3>
                    <P>{payoutRule.created_by}</P>
                </Field>
                <Field>
                    <StyledH3>{t('payout_rules.payout_rules_details.fields.created_at')}</StyledH3>
                    <P>{formatDate(new Date(payoutRule.created_at ?? 0).toISOString(), i18n.language)}</P>
                </Field>
                <Field>
                    <StyledH3>{t('payout_rules.payout_rules_details.fields.last_edited')}</StyledH3>
                    <P>{formatDate(new Date(payoutRule.updated_at ?? 0).toISOString(), i18n.language)}</P>
                </Field>
            </DataWrapper>
        </Card>
    );
};

const DataWrapper = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
`;

const Field = styled.div`
    width: 50%;
    margin-bottom: ${distances.small};
`;

const StyledH3 = styled(H3 as any)`
    line-height: 22px;
`;

export default DetailedData;
