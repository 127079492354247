import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { CustomerDueDiligenceCase } from '../../../types/management-auth';

import { transaction_status } from '../../../styles/constants';
import { forwardRef, memo, ReactHTMLElement } from 'react';

type payoutDestinationStatus = CustomerDueDiligenceCase['events'][number]['case_status'] | 'PAYOUT_DESTINATION' | 'ARCHIVED';
type colorSet = {
    border: string;
    background: string;
    text: string;
}


const payoutDestinationStatusMap: {[key in payoutDestinationStatus]: colorSet} = {
    WAITING_FOR_DECLARATION: transaction_status.ON_HOLD,
    WAITING_FOR_SIGNATURE: transaction_status.AUTHORIZED,
    AUTOMATIC_REVIEW: transaction_status.PARTIALLY_REFUNDED,
    UNDER_MANUAL_REVIEW: transaction_status.REFUNDED,
    WAITING_FOR_DETAILS: transaction_status.REFUNDED,
    ACTIVE: transaction_status.PARTIALLY_CAPTURED,
    PAYOUT_DESTINATION: transaction_status.CAPTURED,
    ERROR: transaction_status.FAILED,
    DECLINED: transaction_status.DECLINED,
    ARCHIVED: transaction_status.DECLINED,
};

interface PayoutDestinationStatusProps {
    status: payoutDestinationStatus;
    activeAsPayoutDestination?: boolean;
}

const getStatusStringContext = (props: PayoutDestinationStatusProps) => {
    if (props.status === 'ACTIVE' && props.activeAsPayoutDestination) {
        return 'PAYOUT_DESTINATION';
    }
    return props.status;
};

const PayoutDestinationStatus = forwardRef<HTMLSpanElement, PayoutDestinationStatusProps>((props, ref) => {
    const { t } = useTranslation();
    const statusColors = payoutDestinationStatusMap[props.status || ''] || transaction_status.UNKNOWN;
    const statusString = t(`settings.payout_destinations.case_status`, { context: getStatusStringContext(props) }) as string;
    return <BaseStatus
        title={statusString}
        border={statusColors.border}
        background={statusColors.background}
        text={statusColors.text}
        {...props}
        ref={ref}
    >
        <span>{statusString}</span>
    </BaseStatus>;
});

interface StatusProps {
    border: string;
    background: string;
    text: string;
}
const BaseStatus = styled.span.attrs(({ ref }) => ({ ref: ref }))<StatusProps>`
    font-weight: 600;
    line-height: normal;
    padding: 2px 8px;
    border-radius: 16px;
    display: inline-block;
    border: 1px solid ${props => props.border};
    background: ${props => props.background};
    color: ${props => props.text};
    margin-right: 2px;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: -6px;

    &> span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;



export default PayoutDestinationStatus;
