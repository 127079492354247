import type { AuthenticatedAccountUser } from '../../types/management-auth';
import { parseISOStrictOr } from '../../helpers/date';

/**Get the account_id the most recently used account for an authenticatedUser  */
export const getInitialAccount = (accountUser: AuthenticatedAccountUser) => {
    const mostRecentAccount = accountUser.accounts.reduce((accumulator, account) => {
        if (!accumulator) {
            return account;
        }
        const accumulatorTimestamp = parseISOStrictOr(accumulator.user.last_seen_at, new Date(0));
        const accountTimestamp = parseISOStrictOr(account.user.last_seen_at, new Date(0));

        if (accountTimestamp > accumulatorTimestamp) {
            return account;
        }
        return accumulator;
    });

    const mostRecentProdAccountId = `P${mostRecentAccount.account_id.substr(1)}`;
    const mostRecentProdAccount = accountUser.accounts.find(account => account.account_id === mostRecentProdAccountId);
    return mostRecentProdAccount ? mostRecentProdAccountId : mostRecentAccount.account_id;
};
