import React, { Component } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { colors, distances } from '../../../../styles/constants';
import Page, { Back, PageButton, PageButtonLink } from '../../../../components/Page';
import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import { H1 } from '../../../../components/Typography';
import Icon from '../../../../components/Icons';
import NotFound from './NotFound';
import Details from './Details';
import { State as ProfileState } from '../reducer';
import RemoveProfileModal from './RemoveProfileModal';
import View from '../../../../components/View';

interface MatchParams {
    accountId: string;
    profileAccountId: string;
    profileId: string;
}

export interface ProfilePageProps extends WithTranslation, RouteComponentProps<MatchParams> {
    getProfile: (accountId: string, profileId: string) => void;
    openRemoveProfileModal: () => void;
    dismissRemoveProfileModal: () => void;
    removeProfile: (accountId: string, profileId: string) => void;
    profile: ProfileState;
}

class ProfilePage extends Component<ProfilePageProps> {
    componentDidMount() {
        const { profileAccountId, profileId } = this.props.match.params;
        this.props.getProfile(profileAccountId, profileId);
    }

    componentDidUpdate(prevProps: Readonly<ProfilePageProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const { profileId } = prevProps.match.params;
        if (this.props.match.params.profileId !== profileId) {
            this.props.getProfile(this.props.match.params.profileAccountId, this.props.match.params.profileId);
        }
    }

    render() {
        const { t, match, profile, openRemoveProfileModal } = this.props;
        const { accountId, profileAccountId, profileId } = match.params;

        if (profile.isDeleted) {
            return <Redirect to={`/${accountId}/settings/payment-profiles`} />;
        }

        return (
            <Page
                title={
                    <React.Fragment>
                        <Back />
                        <Title>{t('settings.payment_profile.title')}</Title>
                        <View ml="auto" gap={8}>
                            <PageButton onClick={openRemoveProfileModal} disabled={!profile.profile}>
                                <Icon icon="delete" fill="currentColor" />
                                {t('settings.payment_profile.delete_profile')}
                            </PageButton>
                            <PageButtonLink to={`${match.url}/edit`} className={!profile.profile ? 'disabled' : ''}>
                                <Icon icon="document" fill="currentColor" />
                                {t('settings.payment_profile.edit_profile')}
                            </PageButtonLink>
                        </View>
                    </React.Fragment>
                }
            >
                <Card title={undefined} noPadding>
                    <Wrapper>
                        {profile.profile && profile.configuration && (
                            <Details
                                profile={profile.profile}
                                configuration={profile.configuration}
                                countryCode={profile.account?.company?.address?.country || undefined}
                            />
                        )}
                        {!profile.isLoading && !profile.profile && (
                            <NotFound elementId={profileId} accountId={accountId} />
                        )}
                        {profile.isLoading && <LoadingOverlay />}
                    </Wrapper>
                </Card>
                {profile.profile && profile.isShowingRemoveModal && (
                    <RemoveProfileModal
                        accountId={profileAccountId}
                        profile={profile.profile}
                        removeProfile={this.props.removeProfile}
                        dismissRemoveProfileModal={this.props.dismissRemoveProfileModal}
                    />
                )}
            </Page>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default withTranslation()(ProfilePage);
