import iso3166 from 'iso-3166-2';

export const isISO_3166_1 = (iso3166Code: string | undefined): boolean => {
    if (iso3166Code && typeof iso3166Code === 'string' && iso3166Code.length === 2) {
        const country = iso3166.country(iso3166Code);
        if (country) {
            return true;
        }
    }
    return false;
};

export const validateISO_3166_1 = (message: string) => {
    return (value: string) => {
        if (!value) {
            return;
        }
        const isValid = isISO_3166_1(value);
        if (!isValid) {
            return message;
        }
    };
};
