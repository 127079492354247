export const urlRegex = new RegExp(
    //eslint-disable-next-line
    /^(?:http(?:s)?:\/\/)(?!((?:[\w\S])*(?::\/\/)))(?:www\.)?(?:[\w\S])+\.(?:[\w\S])+$/
);

export const isUrl = (url: string) => {
    return urlRegex.test(url);
};

export const validateUrl = (message: string) => {
    return (value: string) => {
        if (!value) {
            return;
        }
        const isValid = isUrl(value);
        if (!isValid) {
            return message;
        }
    };
};
