import { VictoryThemeDefinition } from 'victory-core';
import { VictoryTheme } from 'victory';
export const fontFamily = '\'Inter\', \'Aeonik Pro\', \'HelveticaNeue-Light\', \'Helvetica Neue Light\', \'Helvetica Neue\', Helvetica, Arial, sans-serif';
const borderColor = '#dfdfdf';
const labelColor = '#46535E';
const barColor = '#0891EB';
const { grayscale } = VictoryTheme;

const theme: VictoryThemeDefinition =  {
    ...grayscale,
    axis: {
        ...grayscale.axis,
        style: {
            ...grayscale.axis?.style,
            axis: {
                ...grayscale.axis?.style?.axis,
                stroke: borderColor,
            },
            axisLabel: {
                ...grayscale.axis?.style?.axisLabel,
                fontFamily: fontFamily,
                fontSize: 10,
            },
            grid: {
                ...grayscale.axis?.style?.grid,
                stroke: borderColor,
            },
            tickLabels: {
                ...grayscale.axis?.style?.tickLabels,
                fontSize: 10,
                background: labelColor,
                fontFamily: fontFamily,
            },
        },
    },
    tooltip: {
        ...grayscale.tooltip,
        cornerRadius: 0,
        pointerLength: 0,
        flyoutStyle: {
            ...grayscale.tooltip?.flyoutStyle,
            fill: '#fff',
            fontFamily: fontFamily,
        },
        style: {
            ...grayscale.tooltip?.style,
            fontFamily: fontFamily,
        },
    },
    bar: {
        ...grayscale.bar,
        style: {
            ...grayscale.bar?.style,
            data: {
                ...grayscale.bar?.style?.data,
                fill: barColor,
            },
            labels: {
                ...grayscale.bar?.style?.labels,
                fontFamily: fontFamily,
            },
        },
    },
};

export default theme;
