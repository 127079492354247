import styled from 'styled-components/macro';
import { border, defaultRadius, distances, globalColumnMaxWidth, grid, palette } from '../../../../styles/constants';
import { withTranslation, WithTranslation, TFuncKey } from 'react-i18next';
import React from 'react';
import { getValueAt } from '../../../../helpers/getValueAt';
import { FormattedInput, Input, ValidationState } from '../../../../components/Forms';
import { CleaveOptions } from 'cleave.js/options';
import Icon from '../../../../components/Icons';
import Select from '../../../../components/Forms/Select';
import { SalesLocation } from '../../../../types/customers';

export const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;

export const HalfNHalf = styled.div`
    display: flex;
    justify-content: space-between;
    word-break: normal;
    white-space: nowrap;
    > * {
        width: 50%;
        &:nth-child(odd) {
            margin-right: ${distances.tiny};
        }
        &:nth-child(even) {
            margin-left: ${distances.tiny};
        }
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;

        > * {
            &:nth-child(odd) {
                margin-right: 0;
            }
            &:nth-child(even) {
                margin-left: 0;
            }
        }
    }
`;

export const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

interface ValidatedInputProps extends WithTranslation {
    values: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    label?: string | TFuncKey<'app'>;
    placeholder?: string | TFuncKey<'app'>;
    required?: boolean;
    options?: CleaveOptions;
    autoFocus?: boolean;
}

const ValidatedInput = (
    {
        t,
        values,
        errors,
        path,
        onChange,
        onBlur,
        touched,
        required,
        label,
        placeholder,
        options,
        autoFocus,
    }: ValidatedInputProps
) => {
    const value = getValueAt(path, values);
    const error = getValueAt(path, errors);
    const wasTouched = getValueAt(path, touched);
    const validation = error
        ? {
            state: ValidationState.Invalid,
            message: error,
        }
        : undefined;

    const labelPath = label || `payout_rules.new_payout_rule.fields.${path}`;
    const placeholderPath = placeholder || `payout_rules.new_payout_rule.placeholders.${path}`;

    const translatedLabel = t(labelPath);
    if (options) {
        return (
            <InputWrapper>
                <FormattedInput
                    label={translatedLabel}
                    placeholder={t(placeholderPath)}
                    name={path}
                    value={value || ''}
                    options={options}
                    autoFocus={autoFocus}
                    onBlur={(e) => {
                        onChange(e);
                        onBlur(e);
                    }}
                    onChange={onChange}
                    validation={wasTouched && validation}
                />
            </InputWrapper>
        );
    }
    return (
        <InputWrapper>
            <Input
                label={translatedLabel}
                placeholder={t(placeholderPath)}
                type="text"
                name={path}
                value={value || ''}
                onChange={onChange}
                onBlur={(e) => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={wasTouched && validation}
                required={required}
            />
        </InputWrapper>
    );
};


interface ValidatedLocationSelectProps extends WithTranslation {
  values: any;
  errors: any;
  path: string;
  onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
  touched: any;
  label?: string;
  placeholder?: string;
  required?: boolean;
  options?: CleaveOptions;
  autoFocus?: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setFieldTouched: (field: string, isTouched?: boolean, shouldValidate?: boolean) => void;
  locations: SalesLocation[];
  locationNames: {[id: string]: string};
}

const ValidatedLocationSelect = (
    {
        t,
        values,
        errors,
        path,
        onBlur,
        touched,
        required,
        label,
        placeholder,
        autoFocus,
        setFieldValue,
        setFieldTouched,
        locations,
        locationNames,
    }: ValidatedLocationSelectProps
) => {
    const value = getValueAt(path, values);
    const error = getValueAt(path, errors);


    const labelPath = label || `payout_rules.new_payout_rule.fields.${path}`;
    const placeholderPath = placeholder || `payout_rules.new_payout_rule.placeholders.${path}`;

    const translatedLabel = t(labelPath);
    const possibleLocations = locations.filter((location) => {
        const search = value?.toLowerCase() ?? '';
        return (
            location?.name?.toLowerCase()?.startsWith(search) ||
        location?.id?.toLowerCase()?.startsWith(search) ||
        location?.location_id?.toLowerCase()?.startsWith(search) ||
        location?.business_name?.toLowerCase()?.startsWith(search)
        );
    });
    const wasTouched = getValueAt(path, touched);
    const errorMessage = error
        ? {
            state: ValidationState.Invalid,
            message: error,
        }
        : undefined;

    const validation = wasTouched && errorMessage ? errorMessage : {
        state: ValidationState.Pristine,
        message: locationNames[value] || '',
    };
    return (
        <InputWrapper>
            <Select
                onChange={(selection) => {
                    if (!selection) {
                        setFieldValue(path, value);
                    } else {
                        setFieldValue(path, selection.location_id);
                    }
                    setFieldTouched(path, true, true);
                }}
                autoFocus={autoFocus}
                placeholder={t(placeholderPath)}
                value={value}
                label={translatedLabel}
                name={path}
                isEditable={true}
                onBlur={onBlur}
                options={possibleLocations}
                getOptionValue={(op) => op.location_id}
                hideOnNoOptions={true}
                onInputChange={(e) => {
                    const partial = e.target.value;
                    setFieldValue(path, partial);
                }}
                getOptionLabel={(op) => op.name}
                getOptionSubLabel={(op) => op.location_id}
                validation={validation}
                required={required}
            />
        </InputWrapper>
    );
};

export const TranslatedValidatedInput = withTranslation()(ValidatedInput);
export const TranslatedValidatedLocationSelect = withTranslation()(ValidatedLocationSelect);

const OverallErrorBox = styled.div`
    background-color: ${palette.warning[50]};
    border: 1px solid ${palette.warning[300]};
    display: flex;
    padding: 8px 10px;
    border-radius: ${defaultRadius};
    font-size: 0.8em;
    align-items: center;

    > span {
        margin-right: 10px;
    }
`;

interface OverallErrorProps {
    message: string;
}

export const OverallError = ({ message }: OverallErrorProps) => (
    <OverallErrorBox>
        <Icon icon="info_circle" fill="#e9e2c8" />
        <div>{message}</div>
    </OverallErrorBox>
);
