type HumanPercentageProps = {
    value: number;
}

const HumanPercentage = ({ value }: HumanPercentageProps) => {
    return <>
        {value.toFixed(2)}%
    </>;
};

export default HumanPercentage;
