import { AccountUser } from '../../../types/management-auth';
import { UpdateAccountUserBody } from './types';

export enum ActionTypes {
    GetUser = '[settings/editUser] GET_USER',
    FetchUserResponse = '[settings/editUser] FETCH_USER_RESPONSE',
    EditUser = '[settings/editUser] EDIT_USER_START',
    FetchEditUserResponse = '[settings/editUser] FETCH_EDIT_USER_RESPONSE',
    ResetForm = '[settings/editUser] RESET_FORM',
}

export interface ResetForm {
    type: ActionTypes.ResetForm;
    payload: {};
}
export function resetForm(): ResetForm {
    return {
        type: ActionTypes.ResetForm,
        payload: {},
    };
}

export interface GetUser {
    type: ActionTypes.GetUser;
    payload: {
        accountId: string;
        userId: string;
    };
}

export interface FetchUserResponse {
    type: ActionTypes.FetchUserResponse;
    payload: {
        user: AccountUser;
    };
    meta: {
        cause: GetUser;
    };
}

export function getUser(accountId: string, userId: string): GetUser {
    return {
        type: ActionTypes.GetUser,
        payload: {
            accountId,
            userId,
        },
    };
}

export interface EditUser {
    type: ActionTypes.EditUser;
    payload: {
        accountId: string;
        userId: string;
        user: UpdateAccountUserBody;
    };
}

export function editUser(accountId: string, userId: string, user: UpdateAccountUserBody): EditUser {
    return {
        type: ActionTypes.EditUser,
        payload: {
            accountId,
            userId,
            user,
        },
    };
}

export interface FetchEditUserResponse {
    type: ActionTypes.FetchEditUserResponse;
    payload: {};
    meta: {
        cause: EditUser;
    };
}

export type Actions = GetUser | FetchUserResponse | EditUser | FetchEditUserResponse | ResetForm;
