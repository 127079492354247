import { connect } from 'react-redux';

import { createUser, resetForm, getAccount } from '../actions';
import { State } from '../../../../reducer';
import { getUsersSelector } from '../selectors';
import { getAccountUser } from '../../../../auth/accessToken/selectors';

import NewUserForm from '../components/NewUserForm';

const mapStateToProps = (state: State) => ({
    user: getUsersSelector(state),
    accountUser: getAccountUser(state),
});

const mapDispatchToProps = {
    createUser,
    resetForm,
    getAccount,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewUserForm);
