import styled from 'styled-components/macro';
import { border, colors, defaultRadius, distances, grid, palette } from '../../../../styles/constants';

interface PaymentConnectionProps {
    disabled: boolean;
}

const PaymentConnection = styled.div<PaymentConnectionProps>`
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    width: 100%;
    padding-block: ${distances.small};
`;

const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-block: ${distances.small};
`;

const ConnectionText = styled.div`
    width: 100%;
`;

const ConnectionAside = styled.div`
    flex: 0 0 auto;
    display: flex;
    justify-content: flex-end;
`;

const Details = styled.div`
    padding-inline: ${distances.small};
    padding-block: ${distances.small};
    border: 1px solid ${palette.neutral[300]};
    border-radius: ${defaultRadius};
    overflow: hidden;
    transition: all 0.2s ease;

    &.closed {
        max-height: 0;
        padding-block: 0;
        border-color: transparent;
        border-width: 0;
    }
`;

const ToggleDetails = styled.button`
    color: ${colors.textSecondary};
    font-size: 11px;
    background: none;
    border: none;
    padding: ${distances.tiny} 0;
    text-decoration: underline;
    margin: 0;
    cursor: pointer;
    &:focus {
        outline: none;
    }
`;

interface ConnectionsStatusProps {
    status: 'pending' | 'failed' | 'enabled' | 'declined';
}

const getStatusColor = (status: 'pending' | 'failed' | 'enabled' | 'declined') => {
    if (status === 'pending') {
        return {
            background: palette.warning[100],
            color: palette.warning[700],
            border: `1px solid ${palette.warning[300]}`,
        };
    }
    if (status === 'enabled') {
        return {
            background: palette.success[500],
            color: 'white',
            border: `1px solid ${palette.success[500]}`,
        };
    }
    return {
        background: palette.neutral[100],
        color: palette.neutral[900],
        border: `1px solid ${palette.neutral[300]}`,
    };
};

const ConnectionsStatus = styled.div<ConnectionsStatusProps>`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 50px;
    ${props => Object.entries(getStatusColor(props.status)).map(([key, value]) => `${key}: ${value};`).join('\n')};
    padding: ${distances.nano} ${distances.tiny};
`;

export { ConnectionsStatus, ConnectionAside, ConnectionText, Flex, ToggleDetails, Details, PaymentConnection };
