import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';
import { ActionTypes, FetchHooksResponse, GetHooks } from './actions';
import { Subscription } from '../../../types/webhooks';
import { sortElements } from '../../helpers';

const fetchHooks: SideEffectFunction<GetHooks, FetchHooksResponse> = async action => {
    const promises: Promise<Subscription[]>[] = action.payload.accountIds.map(accountId =>
        fulfill.get({
            accountId: accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/hooks/subscriptions?include_deleted=true&limit=100`,
            handlers: {
                200: (hooks: Subscription[]) => hooks,
                403: () => [],
            },
        }));
    const results = await Promise.all(promises);
    const hooks = results.reduce((acc: Subscription[], result) => [...acc, ...result], []);
    return {
        type: ActionTypes.FetchHooksResponse,
        payload: {
            hooks: sortElements(hooks),
        },
        meta: {
            cause: action,
        },
    };
};

const effects = {
    [ActionTypes.GetHooks]: fetchHooks,
};

export default effects;
