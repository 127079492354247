import React from 'react';

import CheckboxListFilter, { Option } from '../../../components/Filters/CheckboxListFilter';

import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FilterProps } from './FilterProps';
import { PaymentType } from '../../../types/checkout';
import { allPaymentTypes } from '../../../util/paymentTypes';
import { paymentOptionTranslationKeys } from '../utils/paymentOptionName';

export type ConnectionStatusProps = FilterProps

const getPaymentOptionName = (t: TFunction, value: PaymentType) => {
    const translationKeys = paymentOptionTranslationKeys(value);
    const translated = translationKeys.map(key => t(key)).join(' ');
    if (translationKeys.length === 1 && translated === translationKeys[0]) {
        return value;
    }
    return translated;
};

const optionValues = allPaymentTypes;

const PaymentOptionsFilter = ({ queryParams, onChange, onClose }: ConnectionStatusProps) => {
    const { t } = useTranslation();

    const options: Option[] = optionValues.map((value) => ({
        label: getPaymentOptionName(t, value),
        value,
    })).sort((a, b) => a.label.localeCompare(b.label));

    const values = queryParams.getAll('payment_option') as PaymentType[];

    return (
        <CheckboxListFilter
            label={t('partner.merchants.filter.payment_option.label', {
                count: values.length,
                value: values.map(value => getPaymentOptionName(t, value)).join(', '),
            })}
            title={t('partner.merchants.filter.payment_option.title')}
            name="payment_option"
            values={values}
            options={options}
            onApply={(name, values) => {
                onChange({ [name]: values });
            }}
            onDelete={name => {
                onChange({ [name]: []});
            }}
            optionsClassName="span3"
            onCloseMenu={onClose}
        />
    );
};

export default PaymentOptionsFilter;
