import { connect } from 'react-redux';

import { getUser, openRemoveUserModal, dismissRemoveUserModal, removeUser } from '../actions';
import { State } from '../../../../reducer';
import { getUsersSelector } from '../selectors';

import UserPage from '../components/UserPage';

const mapStateToProps = (state: State) => ({
    user: getUsersSelector(state),
});

const mapDispatchToProps = {
    getUser,
    openRemoveUserModal,
    dismissRemoveUserModal,
    removeUser,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserPage);
