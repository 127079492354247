import { reducer as merchants, namespace as merchantsNamespace } from './merchants';
import { reducer as newPartner, namespace as newPartnerNamespace } from './newAccount';
import { reducer as merchantDetails, namespace as merchantDetailsNamespace } from './merchantDetails';
import * as connectionEvents  from './merchantDetails/connectionEventsActions';

export interface State {
    [merchantsNamespace]: merchants.State;
    [newPartnerNamespace]: newPartner.State;
    [merchantDetailsNamespace]: merchantDetails.State;
    [connectionEvents.namespace]: connectionEvents.State;
}

export const initialState: State = {
    [merchantsNamespace]: merchants.initialState,
    [newPartnerNamespace]: newPartner.initialState,
    [merchantDetailsNamespace]: merchantDetails.initialState,
    [connectionEvents.namespace]: connectionEvents.initialState,
};

export const reducers = {
    [merchantsNamespace]: merchants.reducer,
    [newPartnerNamespace]: newPartner.reducer,
    [merchantDetailsNamespace]: merchantDetails.reducer,
    [connectionEvents.namespace]: connectionEvents.reducer,
};
