import { connect } from 'react-redux';

import {
    getHook,
    openDeleteHookModal,
    dismissDeleteHookModal,
    deleteHook,
    openPingHookModal,
    dismissPingHookModal,
    pingHook,
    getDeliveries,
    getDeliveryDetails
} from '../actions';
import { State } from '../../../../reducer';
import { getHookSelector } from '../selectors';

import HookPage from '../components/HookPage';

const mapStateToProps = (state: State) => ({
    hook: getHookSelector(state),
});

const mapDispatchToProps = {
    getHook,
    openDeleteHookModal,
    dismissDeleteHookModal,
    deleteHook,
    openPingHookModal,
    dismissPingHookModal,
    pingHook,
    getDeliveries,
    getDeliveryDetails,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HookPage);
