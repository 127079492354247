import React from 'react';
import { Route } from 'react-router-dom';

import { PayoutRules } from './payoutRules';
import { NewPayoutRule } from './newPayoutRule';
import { PayoutRuleEdit } from './payoutRuleEdit';
import { PayoutRuleDetails } from './payoutRuleDetails';

const Routes = [
    <Route key="payout-rules" path="/:accountId/settings/payout-rules" exact component={PayoutRules} />,
    <Route key="payout-rules-new" path="/:accountId/settings/payout-rules/new" exact component={NewPayoutRule} />,
    <Route key="payout-rules-details" path="/:accountId/settings/payout-rules/:payoutRuleId" exact component={PayoutRuleDetails} />,
    <Route key="payout-rules-edit" path="/:accountId/settings/payout-rules/:payoutRuleId/edit" exact component={PayoutRuleEdit} />,
];

export default Routes;
