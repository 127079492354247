import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { RouteComponentProps } from 'react-router-dom';
import { Formik } from 'formik';

import { colors, distances, border, grid, globalColumnMaxWidth } from '../../../../styles/constants';
import Page, { Dismiss, PageButton } from '../../../../components/Page';
import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import { H1, H2, P } from '../../../../components/Typography';
import { DOCUMENTED_CORE_API_HOSTNAME } from '../../../../env';

import { State as ClientState } from '../reducer';
import { Input, ValidationState, BackButton } from '../../../../components/Forms';
import { AuthenticatedAccountUser, Client, Grant } from '../../../../types/management-auth';
import ClientSecret from './ClientSecret';
import Icon from '../../../../components/Icons';
import { ButtonGroup, Button } from '../../../../components/Buttons';
import { withAccountIds, AccountIdsProps } from '../../../../auth/accessToken/withAccountIds';
import Hr from '../../../../components/Hr';

interface MatchParams {
    accountId: string;
}

export interface NewClientProps extends AccountIdsProps, WithTranslation, RouteComponentProps<MatchParams> {
    client: ClientState;
    accountUser: AuthenticatedAccountUser;
    getAccount: (accountId: string) => void;
    createClient: (accountId: string, newClient: Client, newGrants: Grant[]) => void;
    toggleSecretIsSafe: (value: boolean) => void;
    resetForm: () => void;
}

interface FormikErrors {
    name?: string;
    description?: string;
    type?: string;
    audience?: string;
}

interface FormikValues {
    name: string;
    description: string;
    scope: string[];
    type: 'any' | 'authorization_code' | 'client_credentials' | 'password';
    audience: string;
}

class NewClientForm extends Component<NewClientProps> {
    componentDidMount() {
        const accountId = this.props.match.params.accountId;
        this.props.getAccount(accountId);
    }

    componentWillUnmount() {
        this.props.resetForm();
    }

    render() {
        const { t, accountUser, createClient, toggleSecretIsSafe, accountIds } = this.props;
        const { isLoading, client, grants, isSecretSafe, account } = this.props.client;
        const accountId = this.props.match.params.accountId;
        const audienceRoot = `${DOCUMENTED_CORE_API_HOSTNAME}/v1/accounts`;
        const currentAccount = accountUser.accounts.find((account) => account.account_id === accountId);

        if (!currentAccount) {
            return null;
        }
        const searchParams = new URLSearchParams(this.props.location.search.substring(1));
        return (
            <React.Fragment>
                <Formik
                    enableReinitialize
                    initialValues={
                        {
                            name: searchParams.get('name') || account?.company.website || '',
                            description: 'Dintero Checkout',
                            scope: [
                                'write:checkout',
                                'read:checkout',
                                'write:receipts',
                                'write:notifications',
                                'write:discounts:/available_for_receipt',
                            ],
                            type: 'client_credentials',
                        } as FormikValues
                    }
                    validate={(values) => {
                        const errors: FormikErrors = {};

                        if (!values.name) {
                            errors.name = t('settings.new_client.validation.name_required');
                        }

                        return errors;
                    }}
                    validateOnChange={false}
                    onSubmit={(values) => {
                        const newClient: Client = {
                            name: values.name,
                            description: values.description,
                        };
                        const newGrants: Grant[] = accountIds.map((aid) => ({
                            client_id: '',
                            type: values.type,
                            audience: `${audienceRoot}/${aid}`,
                            scope: values.scope.filter((x) => x),
                        }));
                        createClient(accountId, newClient, newGrants);
                    }}
                >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, touched }) => (
                        <Form onSubmit={handleSubmit} autoComplete="nope">
                            <Page
                                title={
                                    <>
                                        <Dismiss />
                                        <Title>{t('settings.new_client.title_checkout')}</Title>
                                        <PageButton disabled={errors.name !== undefined || !values.name} type="submit">
                                            <Icon icon="save" fill="currentColor" />
                                            {t('settings.new_client.create_client')}
                                        </PageButton>
                                    </>
                                }
                            >
                                <Card title={undefined}>
                                    <Wrapper>
                                        <FormGroup>
                                            <H2>{t('settings.new_client.checkout_info')}</H2>
                                            <Hr />

                                            <Description>{t('settings.new_client.checkout_description')}</Description>

                                            <Flex>
                                                <InputWrapper>
                                                    <Input
                                                        autoComplete="off"
                                                        label={t('settings.new_client.fields.checkout_client_name')}
                                                        placeholder={t('settings.new_client.placeholders.name')}
                                                        type="text"
                                                        name="name"
                                                        value={values.name}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        validation={{
                                                            state:
                                                                errors.name && touched.name
                                                                    ? ValidationState.Invalid
                                                                    : ValidationState.Pristine,
                                                            message: errors.name,
                                                        }}
                                                        required
                                                    />
                                                </InputWrapper>
                                            </Flex>
                                        </FormGroup>
                                        <ButtonGroup>
                                            <BackButton>{t('settings.new_client.abort')}</BackButton>
                                            <Button disabled={errors.name !== undefined || !values.name} type="submit">
                                                {t('settings.new_client.create_client')}
                                            </Button>
                                        </ButtonGroup>
                                        {isLoading && <LoadingOverlay />}
                                    </Wrapper>
                                </Card>
                            </Page>
                        </Form>
                    )}
                </Formik>
                {client && grants && (
                    <ClientSecret
                        accountId={accountId}
                        isSecretSafe={isSecretSafe}
                        client={client}
                        grants={grants}
                        toggleSecretIsSafe={toggleSecretIsSafe}
                    />
                )}
            </React.Fragment>
        );
    }
}

export default withAccountIds(withTranslation()(NewClientForm));

const Form = styled.form`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    width: 100%;
`;

const Description = styled(P)`
    margin-top: ${distances.normal};
    margin-bottom: ${distances.normal};
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;
