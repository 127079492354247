import styled from 'styled-components/macro';

import { distances } from '../../../styles/constants';

interface DtProps {
    doubleColumn?: boolean;
}

const Dt = styled.dt<DtProps>`
    padding: 0;
    margin: 0;
    font-weight: 700;
    font-size: 14px;
    line-height: ${distances.normal};
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export default Dt;
