import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { distances, grid, border, colors, globalColumnMaxWidth } from '../../../../styles/constants';
import { Dl, Dt, Dd } from '../../../../components/DescriptionList/Horizontal';
import { H2 } from '../../../../components/Typography';
import { Subscription } from '../../../../types/webhooks';
import { DateTime } from '../../../../components/Formatters';
import { EnvironmentBadge } from '../../../../components/EnvironmentBadge';
import Hr from '../../../../components/Hr';
interface MatchParams {
    accountId: string;
    user_id: string;
}
export interface DetailsInfoProps extends WithTranslation, RouteComponentProps<MatchParams> {
    hook: Subscription;
}

const DetailsInfo = (
    {
        t,
        hook,
    }: DetailsInfoProps
) => {
    return (
        <Wrapper>
            <InfoWrapper>
                <H2>{t('settings.hook.hook_info')}</H2>
                <Hr />
                <Dl>
                    <Dt doubleColumn>{t('settings.hook.fields.environment')}</Dt>
                    <Dd>
                        <EnvironmentBadge
                            environment={(hook.account_id || '').startsWith('P') ? 'production' : 'test'}
                        />
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.hook.fields.updated_at')}</Dt>
                    <Dd>
                        <DateTime isoString={hook.updated_at || ''} boldTime />
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.hook.fields.created_at')}</Dt>
                    <Dd>
                        <DateTime isoString={hook.created_at || ''} boldTime />
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.hook.fields.created_by')}</Dt>
                    <Dd>{hook.created_by}</Dd>
                </Dl>
                {(hook.deleted_at || hook.deleted_by) && <Spacer />}
                {hook.deleted_at && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.hook.fields.deleted_at')}</Dt>
                        <Dd>
                            <DateTime isoString={hook.deleted_at || ''} boldTime />
                        </Dd>
                    </Dl>
                )}
                {hook.deleted_by && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.hook.fields.deleted_by')}</Dt>
                        <Dd>{hook.deleted_by}</Dd>
                    </Dl>
                )}
                {hook.config.secret && hook.config.secret.type === 'HMAC-SHA1' && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.hook.fields.secret')}</Dt>
                        <Dd>{hook.config.secret.value}</Dd>
                    </Dl>
                )}
            </InfoWrapper>
            <EventWrapper>
                <H2>{t('settings.hook.fields.events')}</H2>
                <Hr />
                <Ul>
                    {hook.events &&
                        hook.events.map((event) => (
                            <li key={event}>
                                <code>{event}</code>
                            </li>
                        ))}
                </Ul>
            </EventWrapper>
        </Wrapper>
    );
};

export default withRouter(withTranslation()(DetailsInfo));

const Wrapper = styled.div`
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    padding: ${distances.normal} ${distances.small};
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const InfoWrapper = styled.div`
    padding-top: ${distances.normal};
    max-width: ${grid.spans.span5};
    margin-right: ${grid.gutter};

    @media (max-width: ${globalColumnMaxWidth}px) {
        max-width: 100%;
        margin-right: 0;
    }
`;

const EventWrapper = styled.div`
    padding-top: ${distances.normal};
    max-width: ${grid.spans.span3};
    margin-right: ${grid.gutter};

    @media (max-width: ${globalColumnMaxWidth}px) {
        max-width: 100%;
        margin-right: 0;
    }
`;

const Ul = styled.ul`
    margin: 0;
    padding-left: 17px;
    list-style-type: circle;
`;

const Spacer = styled.div`
    height: ${distances.normal};
`;
