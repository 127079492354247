import { Actions, ActionTypes, GetAccountsReason, GetAccounts, FetchAccountsResponse } from './actions';
import { paramsEqual } from '../../helpers/urlParams';
import { AccountOrGap } from './types';
import { AccountAdminUser } from '../../types/management-auth';
import { mergeLists } from '../../helpers/paging';
import { accountPagingIdFn } from './helpers';

export interface State {
    isLoading: boolean;
    limit: number;
    accounts: AccountOrGap[];
    hasNext: boolean;
    isLoadingNext: boolean;
    accountId: string;
    params: URLSearchParams;
    requestId: number;
    users: AccountAdminUser[];
    
}

export const initialState: State = {
    isLoading: false,
    limit: 20,
    accounts: [],
    hasNext: true,
    isLoadingNext: false,
    accountId: '',
    params: new URLSearchParams(),
    requestId: 0,
    users: [],
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.GetAccounts:
            const clearList = shouldClearList(action, state);
            return {
                ...initialState,
                isLoading: true,
                isLoadingNext: action.payload.reason !== GetAccountsReason.refresh,
                accounts: clearList ? [] : state.accounts,
                accountId: action.payload.accountId,
                params: action.payload.params,
                requestId: action.payload.requestId,
                users: state.users,

            };

        case ActionTypes.FetchPartnerUsersResponse:
            return {
                ...state,
                isLoading: false,
                users: action.payload.users,
            };

        case ActionTypes.FetchAccountsResponse:
            if (action.meta.cause.payload.requestId !== state.requestId) {
                return state;
            }
            return {
                ...state,
                isLoading: false,
                accounts: mergeAccountLists(action, state),
                hasNext: action.payload.accounts.length === state.limit,
                isLoadingNext: false,
            };

        default:
            return state;
    }
}

const shouldClearList = (action: GetAccounts, state: State) => {
    if (action.payload.accountId !== state.accountId) {
        return true;
    }
    if (action.payload.reason === GetAccountsReason.update) {
        return true;
    }
    const blacklist = ['starting_after', 'limit'];
    return !paramsEqual(action.payload.params, state.params, blacklist);
};

const mergeAccountLists = (action: FetchAccountsResponse, state: State): AccountOrGap[] => {
    // sanity check, should clear list
    if (shouldClearList(action.meta.cause, state)) {
        return action.payload.accounts;
    }

    const result = mergeLists(
        action.payload.accounts,
        state.accounts,
        action.meta.cause.payload.params.get('starting_after'),
        accountPagingIdFn
    );
    return result;
};
