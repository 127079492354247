import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActions } from '../../../../Actions';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { Dd, Dl, Dt } from '../../../../components/DescriptionList/Horizontal';
import { DateTime } from '../../../../components/Formatters';
import { H2, P } from '../../../../components/Typography';
import View from '../../../../components/View';
import { distances } from '../../../../styles/constants';
import { CheckoutConfig } from '../../../../types/checkout';
import { CheckoutConfigWrite } from '../../../../types/checkout/generated';
import { Account, BamboraConnection } from '../../../../types/management-auth';
import { BamboraPayFacConnectionSignup } from '../../../../types/management-auth/generated';
import { ActivePaymentOptions } from './ActivePaymentOptions';
import { PaymentOptionsDialog } from './PaymentOptionsDialog';
import {
    ConnectionAside,
    ConnectionsStatus,
    ConnectionText,
    Details,
    Flex,
    PaymentConnection,
    ToggleDetails
} from './styledListItemComponents';

export interface BamboraConnectionListItemProps {
    bambora: BamboraConnection | undefined;
    disabled: boolean;
    account: Account;
    accountId: string;
    checkoutConfig: CheckoutConfig | undefined;
    updateCheckoutConfig: (accountId: string, checkoutConfig: CheckoutConfigWrite) => Promise<void>;
    isUpdatedSuccessfully: boolean;
}

const availablePaymentOptions: CheckoutConfigWrite['configuration']['payment_options'] = [
    { type: 'bambora.creditcard', currencies: ['NOK', 'SEK', 'EUR', 'DKK']},
    { type: 'bambora.mobilepay', currencies: ['DKK', 'EUR']},
    { type: 'bambora.vipps', currencies: ['NOK']},
];

const BamboraConnectionListItem = (
    {
        bambora,
        disabled,
        account,
        accountId,
        checkoutConfig,
        updateCheckoutConfig,
        isUpdatedSuccessfully,
    }: BamboraConnectionListItemProps
) => {
    const { t } = useTranslation();
    const [bamboraDetails, setBamboraDetails] = useState(false);
    const {
        submerchant_signups = [],
        direct_signups = [],
        signup: oldDirectSignup,
    } = bambora || {} ;
    const [showPaymentOptionsDialog, setShowPaymentOptionsDialog] = useState(false);

    useEffect(() => {
        if (isUpdatedSuccessfully) {
            setShowPaymentOptionsDialog(false);
        }
    }, [isUpdatedSuccessfully]);

    const lastSubmerchantSignup = submerchant_signups[submerchant_signups.length -1];
    const lastDirectSignup = direct_signups[direct_signups.length -1] || oldDirectSignup;
    const hasDirectSignup = lastDirectSignup && lastSubmerchantSignup
        // both agreements exists: check direct is the most recent
        ? new Date(lastDirectSignup.created_at) > new Date(lastSubmerchantSignup.created_at)
        : lastDirectSignup !== undefined;
    const lastSignup = hasDirectSignup ? lastDirectSignup : lastSubmerchantSignup;
    const { openModal } = useActions('connections.bambora');
    const vipps = bambora?.configuration?.payment_options?.some((po) => po.type === 'bambora.vipps');
    return (
        <PaymentConnection disabled={disabled}>
            <View alignItem="flex-start" gap={distances.small}>
                <H2>
                    {vipps
                        ? t(`settings.payment_connections.bambora.name_vipps`)
                        : t(`settings.payment_connections.bambora.name`)}
                </H2>
                {bambora && bambora.status && (
                    <ConnectionsStatus status={bambora.status}>
                        {t(`settings.payment_connections.status.${bambora.status}`)}
                    </ConnectionsStatus>
                )}
            </View>
            <Flex>
                <ConnectionText>
                    {vipps && <P>{t(`settings.payment_connections.bambora.description_vipps`)}</P>}
                    {!vipps && <P>{t(`settings.payment_connections.bambora.description`)}</P>}
                </ConnectionText>
                <ConnectionAside>
                    {!bambora && <Button className="stretch" onClick={() => {openModal(account);}} >{t(
                        'settings.payment_connections.bambora.call_to_action'
                    )}</Button>}
                    {bambora && hasDirectSignup && !lastDirectSignup.callback_at && (
                        <div>
                            <Button onClick={() => openModal(account)} className="alt">
                                {t(`settings.payment_connections.bambora.complete_callback`)}
                            </Button>
                        </div>
                    )}
                </ConnectionAside>
            </Flex>
            {bambora && (
                <>
                    <ToggleDetails onClick={() => setBamboraDetails(!bamboraDetails)}>
                        {bamboraDetails
                            ? t('settings.payment_connections.collapse_details')
                            : t('settings.payment_connections.expand_details')}
                    </ToggleDetails>
                    <Details className={bamboraDetails ? 'open' : 'closed'}>
                        {bambora.status === 'pending' && <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.instructions')}</Dt>
                            <Dd>
                                {t('settings.payment_connections.bambora.pending')}
                            </Dd>
                        </Dl>}
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.created_at')}</Dt>
                            <Dd>
                                {(lastSignup?.created_at) ? (
                                    <DateTime isoString={lastSignup.created_at} />
                                ) : (
                                    <em>{t('settings.payment_connections.fields.no_callback_yet')}</em>
                                )}
                            </Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.callback_at')}</Dt>
                            <Dd>
                                {(lastSignup?.callback_at) ? (
                                    <DateTime isoString={lastSignup.callback_at} />
                                ) : (
                                    <em>{t('settings.payment_connections.fields.no_callback_yet')}</em>
                                )}
                            </Dd>
                        </Dl>
                        {lastSignup?.case_id && <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.case_id')}</Dt>
                            <Dd>
                                <code>{lastSignup?.case_id}</code>
                            </Dd>
                        </Dl>}
                        {(lastSignup as BamboraPayFacConnectionSignup)?.mcc && <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.signup_mcc')}</Dt>
                            <Dd>
                                <code>{(lastSignup as BamboraPayFacConnectionSignup).mcc}</code>
                            </Dd>
                        </Dl>}
                        {checkoutConfig && <ActivePaymentOptions checkoutConfig={checkoutConfig} paymentType="bambora" />}
                        {checkoutConfig && !['pending'].includes(bambora.status ?? '') &&
                            <ButtonGroup>
                                {!['failed'].includes(bambora.status ?? '') && <Button
                                    type="button"
                                    className="alt"
                                    onClick={() => setShowPaymentOptionsDialog(true)}>
                                    {t('settings.payment_connections.edit_payment_options_button')}
                                </Button>
                                }
                                <Button className="alt" onClick={() => {openModal(account);}} >{t(
                                    'settings.payment_connections.bambora.fresh_setup_call_to_action'
                                )}</Button>
                                <PaymentOptionsDialog
                                    availablePaymentOptions={availablePaymentOptions}
                                    isDialogOpen={showPaymentOptionsDialog}
                                    handleCloseDialog={() => setShowPaymentOptionsDialog(false)}
                                    handleSavePaymentOptions={(newCheckoutConfiguration) => updateCheckoutConfig(accountId, newCheckoutConfiguration)}
                                    checkoutConfig={checkoutConfig}
                                />
                            </ButtonGroup>
                        }
                    </Details>

                </>
            )}
        </PaymentConnection>
    );
};

export default BamboraConnectionListItem;
