import React from 'react';
import styled from 'styled-components/macro';

import { PersistedInsightReportConfig } from '../../../types/insight';
import { colors, distances } from '../../../styles/constants';
import Dropdown, { DropdownOption } from '../../../components/Forms/Dropdown';
import { useTranslation } from 'react-i18next';
import { InputValidation } from '../../../components/Forms';

import { Label } from '../../../components/Typography';

export interface ScheduleDropdownProps {
    value: PersistedInsightReportConfig['schedule'];
    name: string;
    placeholder: string;
    onChange: (value: string) => void;
    validation?: InputValidation;
    disabled?: boolean;
    source: PersistedInsightReportConfig['dataType'];
}

const ScheduleDropdown = (
    {
        name,
        value,
        onChange,
        validation,
        disabled,
        source,
    }: ScheduleDropdownProps
) => {
    const { t } = useTranslation();
    const optionValuesPerSource: Record<
        PersistedInsightReportConfig['dataType'],
        PersistedInsightReportConfig['schedule'][]
    > = {
        'checkout/transactions': ['monthly', 'weekly', 'daily'],
        'checkout/transactions/open': ['monthly', 'weekly'],
        'wallets/transactions': ['monthly', 'weekly', 'daily'],
        'orders/orderoperations': ['weekly', 'twice-a-month', 'daily'],
    };
    const options: DropdownOption[] = (optionValuesPerSource[source] as PersistedInsightReportConfig['schedule'][]).map(
        (value) => ({
            label: <span>{t(`settings.reports.schedules.schedule`, { context: value })}</span>,
            value,
        })
    );
    const selectedOption = options.find((option) => option.value === value);
    return (
        <Wrapper isDisabled={!!disabled}>
            <Label htmlFor={name}>{t('settings.reports.schedule')}</Label>
            {selectedOption && (
                <Dropdown
                    name={name}
                    value={selectedOption}
                    options={options}
                    onChange={(val) => {
                        onChange(val.value);
                    }}
                    disabled={disabled}
                />
            )}
            {validation && validation.message && (
                <Message htmlFor={name} className={validation.state}>
                    {validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

const Message = styled.label`
    position: absolute;
    top: 66px;
    left: 0;
    font-size: 11px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

const Wrapper = styled.div<{ isDisabled: boolean }>`
    position: relative;
    opacity: ${(props) => (props.isDisabled ? '0.6' : '1')};
`;

export default ScheduleDropdown;
