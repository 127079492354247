import { createSelector } from 'reselect';
import { namespace } from './approvalsActions';
import { State } from './reducer';

// export const 
export const approvalsStateSelector = (accountId: string) => (state: State) => {
    return {
        loading: state[namespace].loading[accountId] || false,
        query: state[namespace].query[accountId] || {},
        approvals: state[namespace].approvals[accountId] || [],
        approvalById: state[namespace].approvalById || [],
    };
};

