import React from 'react';

const EditPen = () => (
    <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Dintero---rewamp-2020" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
                id="Adjusted:-customers---edit-info-alternatives"
                transform="translate(-854.000000, -260.000000)"
                fill="currentColor"
            >
                <g id="element/top-button-copy-5" transform="translate(854.000000, 258.000000)">
                    <g id="icons/card" transform="translate(0.000000, 2.000000)">
                        <path
                            d="M11.2900574,0.852881524 L13.5988039,3.161628 C13.9893282,3.55215229 13.9893282,4.18531727 13.5988039,4.57584156 C13.5879636,4.58668192 13.5768754,4.59727156 13.5655481,4.60760209 L4.16200132,13.1836872 L0.470237379,13.1836872 L0.470237379,9.46356716 L9.90764402,0.822451228 C10.3023533,0.461045988 10.9116337,0.474457737 11.2900574,0.852881524 Z M8.39323738,3.56423454 L1.47023738,9.90380721 L1.47023738,12.1836872 L3.77447839,12.1836872 L10.7002374,5.86623454 L8.39323738,3.56423454 Z M10.5829507,1.55998831 L9.13123738,2.88823454 L11.4392374,5.19223454 L12.8916971,3.86873478 L10.5829507,1.55998831 Z"
                            id="Combined-Shape"
                        ></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default EditPen;
