import namespace from './namespace';
import * as reducer from './actions';



export interface State {
    [namespace]: reducer.State;
}

export const initialState: State = {
    [namespace]: reducer.initialState,
};

export const reducers = {
    [namespace]: reducer.reducer,
};
