import React from 'react';
import { useTranslation } from 'react-i18next';

import { Table, Thead, Tbody, Tr, Th, Td } from '../../../components/Table/Fake';
import { Button } from '../../../components/Buttons';
import Icon from '../../../components/Icons';
import { getListKey } from '../../../helpers/paging';
import { PersistedInsightReportConfig } from '../../../types/insight';
import { colors } from '../../../styles/constants';
import Filters from '../../../reports/components/Filters';

interface RowProps {
    configuration: PersistedInsightReportConfig;
    accountId: string;
    openDeleteModal: (accountId: string, configuration: PersistedInsightReportConfig) => void;
    destinationIdLabelMap: {[destination_id: string]: string};
}

const dataTypeFileType = {
    'orders/orderoperations': 'csv',
    'checkout/transactions/open': 'pdf',
    'wallets/transactions': 'csv',
    'checkout/transactions': 'pdf',
};

const Row = (
    {
        configuration,
        accountId,
        openDeleteModal,
        destinationIdLabelMap,
    }: RowProps
) => {
    const { t } = useTranslation();
    return (
        <Tr key={configuration.id} className="stop-borders-before-edges">
            <Td>{t(`settings.reports.sources.source`, { context: configuration.dataType })}</Td>
            <Td>{t(`settings.reports.schedules.schedule`, { context: configuration.schedule })}</Td>
            <Td>
                <Filters filters={(configuration as PersistedInsightReportConfig).reportFilters} destinationIdLabelMap={destinationIdLabelMap} />
            </Td>
            <Td>{configuration.name}</Td>
            <Td>{(configuration.emails || []).join(', ')}</Td>
            <Td>
                <code>{configuration.contentTypes ?
                    configuration.contentTypes.map(x => x.split('/')[x.split('/').length - 1]).join(', ')
                    : dataTypeFileType[configuration.dataType]}</code>
            </Td>
            <Td className="center">
                <Button className="icon tiny red" onClick={() => openDeleteModal(accountId, configuration)}>
                    <Icon icon="close_small" fill={colors.invalid} />
                </Button>
            </Td>
        </Tr>
    );
};

export interface SessionTableProps {
    accountId: string;
    configurations: PersistedInsightReportConfig[];
    openDeleteModal: (accountId: string, configuration: PersistedInsightReportConfig) => void;
    destinationIdLabelMap: {[destination_id: string]: string};
}

const ConfigurationsTable = (
    {
        configurations,
        accountId,
        openDeleteModal,
        destinationIdLabelMap,
    }: SessionTableProps
) => {
    const { t } = useTranslation();
    return (
        <Table className="no-wrap">
            <Thead>
                <Tr>
                    <Th>{t('settings.reports.source')}</Th>
                    <Th>{t('settings.reports.schedule')}</Th>
                    <Th>{t('settings.reports.filter')}</Th>
                    <Th>{t('settings.reports.name')}</Th>
                    <Th>{t('settings.reports.email')}</Th>
                    <Th>{t('settings.reports.file_type')}</Th>
                    <Th></Th>
                </Tr>
            </Thead>
            {configurations.length > 0 && (
                <Tbody>
                    {configurations.map((configuration) => (
                        <Row
                            key={getListKey(configuration)}
                            configuration={configuration}
                            accountId={accountId}
                            openDeleteModal={openDeleteModal}
                            destinationIdLabelMap={destinationIdLabelMap}
                        />
                    ))}
                </Tbody>
            )}
        </Table>
    );
};

export default ConfigurationsTable;
