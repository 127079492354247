import styled from 'styled-components/macro';

import { distances } from '../../../styles/constants';

const CardArea = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: ${distances.tiny};
    align-items: stretch;
    > dl {
        width: 45%;
        &:nth-child(even) {
            margin-left: 5%;
        }
    }
    @media (max-width: 600px) {
        flex-direction: column;
        > dl {
            width: 100%;
            &:nth-child(even) {
                margin-left: 0;
            }
        }
    }
`;

export default CardArea;
