import React from 'react';
import styled from 'styled-components/macro';
import { WithTranslation, withTranslation } from 'react-i18next';
import cc, { CurrencyCodeRecord } from 'currency-codes';
import iso3166 from 'iso-3166-2';

import { ProfileConfiguration, CheckoutConfig } from '../../../types/checkout';
import { colors, distances } from '../../../styles/constants';
import { Money } from '../../../components/Formatters';
import getProfilePaymentTypes from '../../../helpers/getProfilePaymentTypes';
import { intersectMany } from '../../../helpers/sets';

const getMerchant = (profile: ProfileConfiguration, configuration: CheckoutConfig) => {
    if (profile.merchant) {
        return profile.merchant;
    }
    return configuration.configuration.merchant;
};

export interface ProfileProps extends WithTranslation {
    profile: ProfileConfiguration;
    configuration: CheckoutConfig;
    countryCode?: string;
}

const getCurrencyCodesFromConfiguration = (
    configuration: CheckoutConfig | undefined,
    profile: ProfileConfiguration | undefined,
    countryCode?: string
): CurrencyCodeRecord[] => {
    const country = iso3166.country(countryCode || 'NO');
    const name = country ? country.name : 'Norway';
    const fallback = [...cc.country(name), cc.code('NOK') as CurrencyCodeRecord];

    if (configuration && profile) {
        // get payment options supported by current profile
        const profileTypes = getProfilePaymentTypes(profile);
        const profileOptions = configuration.configuration.payment_options.filter((option) =>
            profileTypes.includes(option.type));
        // from these payment options get the currencies
        const currencySets = profileOptions.map((o) => new Set<string>(o.currencies));
        const commonDenominator = intersectMany<string>(currencySets);
        const fromProfile = [...commonDenominator]
            .map((code) => cc.code(code))
            .filter((code) => code !== undefined) as CurrencyCodeRecord[];
        if (fromProfile.length === 1) {
            return fromProfile;
        } else if (fromProfile.length > 1) {
            // if more that one result use fallback if possible
            const fallbackInFromProfile = fromProfile.find((x) => x.code === fallback[0].code);
            if (!fallbackInFromProfile) {
                return fromProfile;
            }
        }
    }
    return fallback;
};

const Profile = (
    {
        profile,
        configuration,
        countryCode,
        t,
    }: ProfileProps
) => {
    const merchant = getMerchant(profile, configuration);
    const currency = getCurrencyCodesFromConfiguration(configuration, profile, countryCode)[0];
    const showLogo = merchant.logo_url && !merchant.logo_url.endsWith('/assets/images/merchants/default.png');

    return (
        <ProfileWrapper>
            <Left>
                {showLogo && <ProfileImage logoUrl={merchant.logo_url} />}
                <ProfileText>
                    {merchant.name ? (
                        <BoldText>{merchant.name}</BoldText>
                    ) : (
                        <BoldTextPlaceholder>{t('payment_profile.merchant_name_placeholder')}</BoldTextPlaceholder>
                    )}

                    <ThinText>0 items</ThinText>
                </ProfileText>
            </Left>
            <Right>
                <BoldText>
                    <Money amount={0} currency={currency.code} />
                </BoldText>
                <ThinText>inc. VAT</ThinText>
            </Right>
        </ProfileWrapper>
    );
};

const ProfileWrapper = styled.div`
    background: rgb(248, 248, 248);
    padding: 14px 24px 18px 18px;
    display: flex;
    align-items: center;
    user-select: none;
    max-width: 480px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 15px 0px;
    margin: 0 auto;
    justify-content: space-between;
`;

const ProfileText = styled.div``;
const BoldText = styled.div`
    font-weight: bold;
    color: #000000;
    font-size: 1.125rem;
`;
const BoldTextPlaceholder = styled.div`
    font-weight: bold;
    color: ${colors.borderDark};
    font-size: 1.125rem;
`;
const ThinText = styled.div`
    color: #000000;
    font-size: 1.125rem;
    font-weight: 100;
    margin-top: ${distances.tiny};
`;
const Left = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const Right = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

interface ProfileImageProps {
    logoUrl: string;
}
const ProfileImage = styled.div<ProfileImageProps>`
    min-width: 50px;
    min-height: 50px;
    background-color: rgb(255, 255, 255);
    background-size: contain;
    margin-right: 15px;
    background-image: url(${(props) => props.logoUrl});
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 50%;
`;

export default withTranslation()(Profile);
