type ActionArgs<T, R extends {
    // eslint-disable-next-line space-before-function-paren
    [key: string]: (state: T, ...args: any[]) => T;
}> = {
        [K in keyof R]: R[K] extends (state: T, ...args: infer A) => T ? A : never;
    };

/**
 * Creates an external store that can be used to manage state outside of React components.
 * @param initialState Initial state to be used by the store
 * @param actions Actions that can be dispatched to update the state
 * @returns External store with the given initial state and actions
 */
export default function createExternalStore<T, R extends {
    // eslint-disable-next-line space-before-function-paren
    [key: string]: (state: T, ...args: any[]) => T;
}>(initialState: T, actions: R, name?: string) {
    let state = initialState;
    const subscribers = new Set<() => void>();

    const externalStore = {
        getSnapshot: ((value: T) => () => {
            if (value !== state) {
                value = state;
            }
            if (process.env.NODE_ENV === 'development') {
                console.debug(`[${name || 'EXTERNAL_STORE'}] 📸 Get snapshot`, value);
            }
            return value;
        })(state),
        dispatch(actionName: keyof R, ...args: ActionArgs<T, R>[keyof R]) {
            const oldState = state;
            state = actions[actionName](state, ...args);
            emitChange();
            if (process.env.NODE_ENV === 'development') {
                console.debug(`[${name || 'EXTERNAL_STORE'}] 🔄 Action: ${String(actionName)}`, {
                    oldState,
                    newState: state,
                    args,
                });
            }
        },
        subscribe(callback: () => void) {
            subscribers.add(callback);
            return () => {
                subscribers.delete(callback);
            };
        },
    };

    function emitChange() {
        for (const subscriber of subscribers) {
            subscriber();
        }
    }

    return externalStore;
}
