import { reducer as hooks, namespace as hooksNamespace } from './hooks';
import { reducer as newHook, namespace as newHookNamespace } from './newHook';
import { reducer as hookDetails, namespace as hookDetailsNamespace } from './hookDetails';

export interface State {
    [hooksNamespace]: hooks.State;
    [newHookNamespace]: newHook.State;
    [hookDetailsNamespace]: hookDetails.State;
}

export const initialState: State = {
    [hooksNamespace]: hooks.initialState,
    [newHookNamespace]: newHook.initialState,
    [hookDetailsNamespace]: hookDetails.initialState,
};

export const reducers = {
    [hooksNamespace]: hooks.reducer,
    [newHookNamespace]: newHook.reducer,
    [hookDetailsNamespace]: hookDetails.reducer,
};
