import React from 'react';
import { Modals as ConnectionModals } from './paymentConnections';

const Modals = () => (
    <>
        <ConnectionModals />
    </>
);

export default Modals;
