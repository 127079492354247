import React from 'react';

import { FormattedInput, InputValidation } from './';
import { unformatOrgNumber } from '../../helpers/formatters';

interface OrganizationNumberProps {
    label: any;
    placeholder: string;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    setFieldValue: (field: string, value: string) => void;
    disabled?: boolean;
    validation?: InputValidation;
    autoComplete?: string;
    name: string;
    value: string;
    country: string;
    required?: boolean;
}

const getFormat = (country: string) => {
    if (country === 'NO') {
        return {
            blocks: [3, 3, 3],
            delimiter: ' ',
            numericOnly: true,
        };
    }
    return {};
};

const getPrefix = (country: string) => {
    if (country === 'NO') {
        return 'MVA';
    }
    return '';
};

const OrganizationNumber = (props: OrganizationNumberProps) => (<FormattedInput
    key={props.country}
    label={props.label}
    placeholder={props.placeholder}
    options={getFormat(props.country)}
    type="text"
    name={props.name}
    value={props.value}
    onChange={(event: any) => {
        props.setFieldValue(props.name, event.currentTarget.value);
    }}
    onBlur={e => {
        const unformattedValue = unformatOrgNumber(props.country, e.currentTarget.value);
        props.setFieldValue(props.name, unformattedValue);
        props.onBlur(e);
    }}
    validation={props.validation}
    prefix={getPrefix(props.country)}
    disabled={props.disabled}
    required={props.required}
/>);

export default OrganizationNumber;
