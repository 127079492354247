import styled from 'styled-components/macro';
import { distances, border, colors } from '../styles/constants';

const Hr = styled.hr`
    margin-top: ${distances.small};
    margin-bottom: ${distances.small};
    width: 100%;
    border: none;
    border-bottom: ${border.normal} solid ${colors.borderLight};

    @media (max-width: 400px) {
        margin-bottom: 0;
    }
`;

export default Hr;
