import { reducer as accessToken, namespace as accessTokenNamespace } from './accessToken';

export interface State {
    [accessTokenNamespace]: accessToken.State;
}

export const initialState: State = {
    [accessTokenNamespace]: accessToken.initialState,
};

export const reducers = {
    [accessTokenNamespace]: accessToken.reducer,
};
