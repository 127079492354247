import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { H3, P } from '../../../../components/Typography';
import { distances } from '../../../../styles/constants';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Buttons';

import { AllowPartner } from '../../../../auth/accessToken/Allow';
import Icon from '../../../../components/Icons';
import { GatewayParsed, OverrideDialogProps } from '../types';

interface GatewayProps {
    gateway: GatewayParsed;
    putOverride: (gateway: string, credentials: Record<string, string | undefined>) => void;
    loading: boolean;
    recentlyOverridenGateways: string[];
    OverrideDialog?: React.FC<OverrideDialogProps>;
}

const LocationGateway = ({
    gateway,
    putOverride,
    loading,
    recentlyOverridenGateways,
    OverrideDialog,
}: GatewayProps) => {
    const { t } = useTranslation();
    const [editOverride, setEditOverride] = useState(false);
    const { name, gatewayDetails, status } = gateway;
    const recentlyUpdated = recentlyOverridenGateways.includes(name);
    const canBeOverridden = !recentlyUpdated && OverrideDialog !== undefined;

    if (!status || status.length === 0) {
        return null;
    }

    const toggleDialog = () => {
        setEditOverride(!editOverride);
    };

    const onCloseDialog = () => {
        setEditOverride(false);
    };

    return (
        <>
            <Field>
                <StyledH3>{t(
                        `locations.location_details.payment_connections.fields.${name}` as any
                )}</StyledH3>
                <P>{status}</P>
                {gatewayDetails.map(gatewayDetail => {
                    return (
                        <P key={gatewayDetail.key}>
                            {t(`locations.location_details.payment_connections.fields.${gatewayDetail.key}`)}: <span>{gatewayDetail.value}</span> {recentlyUpdated && <Icon icon="check" title={t('locations.location_details.payment_connections.recently_updated')}/>}
                        </P>
                    );
                })}
                {canBeOverridden && <AllowPartner onlyDinteroAdmin={true}>
                    <OverrideGateway>
                        <>
                            <Button className="alt" onClick={toggleDialog}>
                                {t('locations.location_details.payment_connections.edit_override.edit')}
                            </Button>
                            <OverrideDialog
                                gateway={gateway}
                                putOverride={putOverride}
                                isLoading={loading}
                                isDialogOpen={editOverride}
                                handleCloseDialog={onCloseDialog} />
                        </>
                    </OverrideGateway>
                </AllowPartner>
                }
            </Field>
        </>
    );
};

const OverrideGateway = styled.div`
  margin-top: ${distances.normal};
`;

const Field = styled.div`
  margin-bottom: ${distances.small};
`;

const StyledH3 = styled(H3 as any)`
  line-height: 22px;
`;

export default LocationGateway;
