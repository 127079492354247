import { Dispatch } from 'redux';
import { createAccountIdChangedCaseReducer } from '../../../auth/useAccountId';
import type { Account } from '../../../types/management-auth';
import { createSwitchlessReducer } from '../../../util/switchlessReducer';
import fulfill from '../../../fulfill';
import { CORE_API_HOSTNAME } from '../../../env';
import { getAccount } from '../connections/actions';

export const namespace = 'connections.swish';

export enum SwishModalState {
    Closed = 'Closed',
    InitiateApplicationLoading = 'InitiateApplicationLoading',
    ApplicationFormOpened = 'ApplicationFormOpened',
    ApplicationReceived = 'ApplicationReceived',
    SwishApiError = 'SwishApiError',
}

export interface State {
    swishModalState: SwishModalState;
    account: Account | undefined;
}

export const initialState: State = {
    swishModalState: SwishModalState.Closed,
    account: undefined,
};

export const { reducer, actions } = createSwitchlessReducer({
    namespace,
    initialState,
    reducers: {
        openSwishModal: (state: State, account: Account) => {
            return {
                ...state,
                swishModalState: SwishModalState.ApplicationFormOpened,
                account,
            };
        },
        closeSwishModal: (_: State) => {
            return initialState;
        },
        postConnection: (state: State) => {
            return {
                ...state,
                swishModalState: SwishModalState.InitiateApplicationLoading,
            };
        },
        postConnectionSuccess: (state: State) => {
            return {
                ...state,
                swishModalState: SwishModalState.ApplicationReceived,
            };
        },
        postConnectionFailure: (state: State) => {
            return {
                ...state,
                swishModalState: SwishModalState.SwishApiError,
            };
        },
        postConnectionComplete: (_: State) => {
            return initialState;
        },
    },
    globalReducer: createAccountIdChangedCaseReducer(() => initialState),
});

export const createConnectedActions = (dispatch: Dispatch) => ({
    openSwishModal: (account: Account) => {
        dispatch(actions.openSwishModal(account));
    },
    closeSwishModal: () => {
        dispatch(actions.closeSwishModal());
    },
    postConnection: async (payload: {
        accountId?: string;
        body: {
            signup: any;
            payload: any;
            [key: string]: any;
        };
        userId?: string;
        account: Account;
    }) => {
        const { accountId, body, userId, account } = payload;
        if (!accountId || !userId) return;
        dispatch(actions.postConnection());
        if (!account.connections?.swish?.signup) {
            await fulfill.post({
                accountId: accountId,
                json: {
                    signup: body.signup,
                },
                url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/swish`,
                handlers: {
                    200: () => {
                        dispatch(actions.postConnectionSuccess());
                    },
                    500: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    501: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    502: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    503: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                    504: () => {
                        dispatch(actions.postConnectionFailure());
                    },
                },
            });
        }
        await fulfill.post({
            accountId: accountId,
            json: body.payload,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/connections/swish/${userId}`,
            handlers: {
                202: () => {
                    dispatch(getAccount(accountId));
                    dispatch(actions.postConnectionComplete());
                },
                500: () => {
                    dispatch(actions.postConnectionFailure());
                },
                501: () => {
                    dispatch(actions.postConnectionFailure());
                },
                502: () => {
                    dispatch(actions.postConnectionFailure());
                },
                503: () => {
                    dispatch(actions.postConnectionFailure());
                },
                504: () => {
                    dispatch(actions.postConnectionFailure());
                },
            },
        });
    },
});
