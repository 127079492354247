import React from 'react';

interface ProgressTwoProps {
    strokewidth?: string | number;
    width?: string;
    height?: string;
    fill?: string;
}

const ProgressTwo = (
    {
        strokewidth = 1,
        width = '100%',
        height = '100%',
        fill = '#0000ff',
    }: ProgressTwoProps
) => {
    return (
        <svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g
                fill={fill}
                stroke={fill}
                strokeLinecap="square"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeWidth={strokewidth}
            >
                <polyline fill="none" points="17 33 26 42 46 22" stroke={fill} />
                <path d="M32,3a29,29,0,0,1,0,58" fill="none" stroke={fill} />
                <path d="M3.121,34.407C3.056,33.611,3,32.812,3,32s.056-1.611.121-2.407" fill="none" />
                <path d="M16.82,7.325a28.716,28.716,0,0,1,4.286-2.19" fill="none" />
                <path d="M6.135,18.935a28.865,28.865,0,0,1,2.526-4.1" fill="none" />
                <path d="M8.608,49.091a28.914,28.914,0,0,1-2.515-4.114" fill="none" />
                <path d="M21.106,5.135a28.716,28.716,0,0,0-4.286,2.19" fill="none" />
                <path d="M16.77,56.645a28.821,28.821,0,0,0,4.3,2.206" fill="none" />
            </g>
        </svg>
    );
};

export default ProgressTwo;
