import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import Card from '../../../components/Card';
import { H2 } from '../../../components/Typography';
import PaymentLogoMark from './PaymentLogoMark';
import { ApprovalsPaymentResponse } from '../../../types/management-auth/generated';
import { colors } from '../../../styles/constants';
import { CheckoutConfig } from '../../../types/checkout';
import { Account } from '../../../types/management-auth';
import { PaymentMethod } from './types';

type MethodStatusProps = {
    isConfigured: boolean;
    isPending: boolean;
    isDeclined: boolean;
    isFailed: boolean;
}

const MethodStatus = ({
    isConfigured,
    isPending,
    isDeclined,
    isFailed,
}: MethodStatusProps) => {
    const { t } = useTranslation();
    if (isConfigured) {
        return (
            <BaseStatus background={colors.valid}>
                {t('settings.approvalsPaymentsConfiguration.approved')}
            </BaseStatus>
        );
    }
    if (isDeclined) {
        return (
            <BaseStatus background={colors.invalid}>
                {t('settings.approvalsPaymentsConfiguration.declined')}
            </BaseStatus>
        );
    }
    if (isFailed) {
        return (
            <BaseStatus background={colors.invalid}>
                {t('settings.approvalsPaymentsConfiguration.failed')}
            </BaseStatus>
        );
    }
    if (isPending) {
        return (
            <BaseStatus background={colors.text}>
                {t('settings.approvalsPaymentsConfiguration.pending')}
            </BaseStatus>
        );
    }
    return <EmptyStatus>
        {t('settings.approvalsPaymentsConfiguration.noStatus')}
    </EmptyStatus>;
};

const resolveConnectionsStatuses = (account: Account, status: string) => {
    // Trying to map the gateway connections to the payment types where
    // possible. Some gateways deliver more than one payment type so in those
    // cases we might not be able to map the connection to all the payment
    // types that are pending / failed or declined.
    const result = [];
    if (account?.connections?.vipps?.status === status) {
        result.push('vipps');
    }
    if (account?.connections?.payex?.status === status) {
        result.push('creditcard');
    }
    if (account?.connections?.bambora?.status === status) {
        result.push('creditcard');
    }
    if (account?.connections?.swish?.status === status) {
        result.push('swish');
    }
    if (account?.connections?.collector?.status === status) {
        result.push('walley');
    }
    if (account?.connections?.instabank?.status === status) {
        result.push('instabank');
    }
    return [...(new Set(result))] as PaymentMethod[];
};

type PaymentMethodConfigurationProps = {
    approvals: ApprovalsPaymentResponse[];
    checkoutConfiguration: CheckoutConfig | undefined;
    account: Account;
}
type PaymentTypeGroupProperties = {
    pendingConnections: PaymentMethod[];
    failedConnections: PaymentMethod[];
    declinedConnections: PaymentMethod[];
    configuredPaymentOptions: CheckoutConfig['configuration']['payment_options'];
    appliedForPaymentMethods: ApprovalsPaymentResponse['payment_methods'];
    approvalDeclined: boolean;
    approvalFailed: boolean;
    hasNonActiveCase: boolean;

}

const checkHasCaseButNotActive = (approvals: ApprovalsPaymentResponse[]) => {
    const active = approvals.some(a => a.case_status === 'ACTIVE');
    return approvals.length > 0 && !active;
};

const showDefaultPaymentType = (hasNonActiveCase: boolean, hasPaymentType: boolean) => {
    // Only hide if we have a non active case and it has not been applied for (or connection is active or pending )
    if (hasNonActiveCase && !hasPaymentType) {
        return false;
    }
    return true;
};

const PaymentTypeGroupBnpl = ({ pendingConnections, failedConnections, declinedConnections, hasNonActiveCase, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined,  approvalFailed }: PaymentTypeGroupProperties) => {
    const { t } = useTranslation();

    const appliedForKlarna = appliedForPaymentMethods.some(x => x.payment_method === 'klarna');
    const appliedForBillie = appliedForPaymentMethods.some(x => x.payment_method === 'billie');
    const appliedForWalley = appliedForPaymentMethods.some(x => x.payment_method === 'walley');

    // Combined statuses
    const hasKlarna = (
        appliedForKlarna ||
        pendingConnections.some(x => x === 'klarna') ||
        configuredPaymentOptions.some(x => x.type === 'klarna.klarna')
    );
    const hasBillie = (
        appliedForBillie ||
        pendingConnections.some(x => x === 'klarna') ||
        configuredPaymentOptions.some(x => x.type === 'klarna.billie')
    );
    const hasWalley = (
        appliedForWalley ||
        pendingConnections.some(x => x === 'walley')||
        configuredPaymentOptions.some(x => ['collector.invoice', 'collector.finance', 'collector.installment', 'collector.invoice_b2b', 'collector.invoice_b2b_preapproved'].includes(x.type))
    );
    const hasInstabank = (
        configuredPaymentOptions.some(x => ['instabank.finance', 'instabank.invoice', 'instabank.installment', 'instabank.postponement'].includes(x.type))
    );

    // Check if we should hide payment type group
    const hasBnpl = (
        hasKlarna || hasBillie || hasWalley || hasInstabank
    );
    const hide = hasNonActiveCase && !hasBnpl;
    if (hide) {
        return null;
    }
    return (
        <PaymentTypeGroup>
            <H2>{t('settings.approvalsPaymentsConfiguration.title_bnpl')}</H2>
            {(hasKlarna) && <PaymentType>
                <Label>
                    <PaymentLogoMark logo="klarna" label="Klarna" />
                    <LabelText>Klarna</LabelText>
                </Label>
                <MethodStatus
                    isConfigured={configuredPaymentOptions.some(x => x.type === 'klarna.klarna')}
                    isPending={appliedForKlarna || pendingConnections.some(x => x === 'klarna')}
                    isFailed={
                        (appliedForKlarna && approvalFailed) || failedConnections.some(x => x === 'klarna')
                    }
                    isDeclined={
                        (appliedForKlarna && approvalDeclined) || declinedConnections.some(x => x === 'klarna')
                    }
                />
            </PaymentType>}
            {(hasBillie) && <PaymentType>
                <Label>
                    <PaymentLogoMark logo="billie" label="Billie" />
                    <LabelText>Billie</LabelText>
                </Label>
                <MethodStatus
                    isConfigured={configuredPaymentOptions.some(x => x.type === 'klarna.billie')}
                    isPending={appliedForBillie || pendingConnections.some(x => x === 'klarna')}
                    isFailed={
                        (appliedForBillie && approvalFailed) || failedConnections.some(x => x === 'klarna')
                    }
                    isDeclined={
                        (appliedForBillie && approvalDeclined) || declinedConnections.some(x => x === 'klarna')
                    }
                />
            </PaymentType>}
            {(hasInstabank) && <PaymentType>
                <Label>
                    <PaymentLogoMark logo="instabank" label="Instabank" />
                    <LabelText>Instabank</LabelText>
                </Label>
                <MethodStatus
                    isConfigured={
                        configuredPaymentOptions.some(x => x.type === 'instabank.finance') ||
                        configuredPaymentOptions.some(x => x.type === 'instabank.invoice') ||
                        configuredPaymentOptions.some(x => x.type === 'instabank.installment')
                    }
                    isPending={pendingConnections.some(x => x === 'instabank')}
                    isFailed={failedConnections.some(x => x === 'instabank')}
                    isDeclined={declinedConnections.some(x => x === 'instabank')}
                />
            </PaymentType>}

            {showDefaultPaymentType(hasNonActiveCase, hasWalley)  && <PaymentType>
                <Label>
                    <PaymentLogoMark logo="walley" label="Walley" />
                    <LabelText>Walley</LabelText>
                </Label>
                <MethodStatus
                    isConfigured={configuredPaymentOptions.some(x => ['collector.invoice', 'collector.finance', 'collector.installment', 'collector.invoice_b2b', 'collector.invoice_b2b_preapproved'].includes(x.type))}
                    isPending={appliedForWalley || pendingConnections.some(x => x === 'walley')}
                    isFailed={
                        (appliedForWalley && approvalFailed) || failedConnections.some(x => x === 'walley')
                    }
                    isDeclined={
                        (appliedForWalley && approvalDeclined) || declinedConnections.some(x => x === 'walley')
                    }
                />
            </PaymentType>}
        </PaymentTypeGroup>
    );
};

const PaymentTypeGroupCreditcard = ({ pendingConnections, failedConnections, declinedConnections, hasNonActiveCase, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined,  approvalFailed }: PaymentTypeGroupProperties) => {
    const { t } = useTranslation();

    const appliedForCreditcard = appliedForPaymentMethods.some(x => x.payment_method === 'creditcard');

    // Check if we should hide payment type group
    const hasCards = (
        appliedForCreditcard ||
        pendingConnections.some(x => x === 'creditcard') ||
        configuredPaymentOptions.some(x => ['payex.creditcard', 'bambora.creditcard'].includes(x.type))
    );
    const hide = hasNonActiveCase && !hasCards;
    if (hide) {
        return null;
    }
    return (
        <PaymentTypeGroup>
            <H2>{t('settings.approvalsPaymentsConfiguration.title_cards')}</H2>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="mastercard" label="Mastercard" />
                    <LabelText>Mastercard</LabelText>
                </Label>
                <MethodStatus
                    isConfigured={configuredPaymentOptions.some(x => ['payex.creditcard', 'bambora.creditcard'].includes(x.type))}
                    isPending={appliedForCreditcard || pendingConnections.some(x => x === 'creditcard')}
                    isFailed={
                        (appliedForCreditcard && approvalFailed) || failedConnections.some(x => x === 'creditcard')
                    }
                    isDeclined={
                        (appliedForCreditcard && approvalDeclined) || declinedConnections.some(x => x === 'creditcard')
                    }
                />
            </PaymentType>
            <PaymentType>
                <Label>
                    <PaymentLogoMark logo="visa" label="Visa" />
                    <LabelText>Visa</LabelText>
                </Label>
                <MethodStatus
                    isConfigured={configuredPaymentOptions.some(x => ['payex.creditcard', 'bambora.creditcard'].includes(x.type))}
                    isPending={appliedForCreditcard || pendingConnections.some(x => x === 'creditcard')}
                    isFailed={
                        (appliedForCreditcard && approvalFailed) || failedConnections.some(x => x === 'creditcard')
                    }
                    isDeclined={
                        (appliedForCreditcard && approvalDeclined) || declinedConnections.some(x => x === 'creditcard')
                    }
                />
            </PaymentType>
        </PaymentTypeGroup>
    );
};

const PaymentTypeGroupWallets = ({ failedConnections, declinedConnections, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined, approvalFailed }: PaymentTypeGroupProperties) => {
    const { t } = useTranslation();

    const appliedForApplepay = appliedForPaymentMethods.some(x => x.payment_method === 'applepay');
    const appliedForGooglepay = appliedForPaymentMethods.some(x => x.payment_method === 'googlepay');

    // Check if we should hide payment type group
    const hasApplePay = (
        appliedForApplepay ||
        configuredPaymentOptions.some(x => x.type === 'payex.applepay')
    );
    const hasGooglePay = (
        appliedForGooglepay ||
        configuredPaymentOptions.some(x => x.type === 'payex.googlepay')
    );
    const hasWallets = (
        hasApplePay ||
        hasGooglePay
    );
    const hide = !hasWallets;
    if (hide) {
        return null;
    }
    return (
        <PaymentTypeGroup>
            <H2>{t('settings.approvalsPaymentsConfiguration.title_wallets')}</H2>
            {hasApplePay &&
                    <PaymentType>
                        <Label>
                            <PaymentLogoMark logo="applepay" label="Apple Pay" />
                            <LabelText>Apple Pay</LabelText>
                        </Label>
                        <MethodStatus
                            isConfigured={configuredPaymentOptions.some(x => ['payex.applepay'].includes(x.type))}
                            isPending={appliedForApplepay}
                            isFailed={
                                (appliedForApplepay && approvalFailed) || failedConnections.some(x => x === 'applepay')
                            }
                            isDeclined={
                                (appliedForApplepay && approvalDeclined) || declinedConnections.some(x => x === 'applepay')
                            }
                        />
                    </PaymentType>
            }
            {hasGooglePay &&
                    <PaymentType>
                        <Label>
                            <PaymentLogoMark logo="googlepay" label="Google Pay" />
                            <LabelText>Google Pay</LabelText>
                        </Label>
                        <MethodStatus
                            isConfigured={configuredPaymentOptions.some(x => ['payex.googlepay'].includes(x.type))}
                            isPending={appliedForGooglepay}
                            isFailed={
                                (appliedForGooglepay && approvalFailed) || failedConnections.some(x => x === 'googlepay')
                            }
                            isDeclined={
                                (appliedForGooglepay && approvalDeclined) || declinedConnections.some(x => x === 'googlepay')
                            }
                        />
                    </PaymentType>
            }
        </PaymentTypeGroup>
    );
};


const PaymentTypeGroupMobile = ({ pendingConnections,  declinedConnections,  hasNonActiveCase, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined,  approvalFailed }: PaymentTypeGroupProperties) => {
    const { t } = useTranslation();

    const appliedForVipps = appliedForPaymentMethods.some(x => x.payment_method === 'vipps');
    const appliedForMobilepay = appliedForPaymentMethods.some(x => x.payment_method === 'mobilepay');
    const appliedForSwish = appliedForPaymentMethods.some(x => x.payment_method === 'swish');

    const hasMobilePay = (
        appliedForMobilepay ||
        pendingConnections.some(x => x === 'mobilepay') ||
        configuredPaymentOptions.some(x => ['payex.mobilepay', 'bambora.mobilepay'].includes(x.type))
    );

    const hasSwish = (
        appliedForSwish ||
        pendingConnections.some(x => x === 'swish') ||
        configuredPaymentOptions.some(x => ['swish.swish', 'payex.swish'].includes(x.type))
    );
    const hasVipps = (
        appliedForVipps ||
        pendingConnections.some(x => x === 'vipps') ||
        configuredPaymentOptions.some(x => ['vipps', 'payex.vipps', 'bambora.vipps'].includes(x.type))
    );
    const hasMobile = (
        hasMobilePay ||
        hasSwish ||
        hasVipps
    );

    // Check if we should hide payment type group
    const hide = hasNonActiveCase && !hasMobile;

    if (hide) {
        return null;
    }
    return (
        <PaymentTypeGroup>
            <H2>{t('settings.approvalsPaymentsConfiguration.title_mobile')}</H2>
            {showDefaultPaymentType(hasNonActiveCase, hasMobilePay)  && <PaymentType>
                <Label>
                    <PaymentLogoMark logo="mobilepay" label="MobilePay" />
                    <LabelText>MobilePay</LabelText>
                </Label>
                <MethodStatus
                    isConfigured={configuredPaymentOptions.some(x => ['payex.mobilepay', 'bambora.mobilepay'].includes(x.type))}
                    isPending={appliedForMobilepay}
                    isFailed={
                        (appliedForMobilepay && approvalFailed) || declinedConnections.some(x => x === 'mobilepay')
                    }
                    isDeclined={
                        (appliedForMobilepay && approvalDeclined) || declinedConnections.some(x => x === 'mobilepay')
                    }
                />
            </PaymentType>}
            {showDefaultPaymentType(hasNonActiveCase, hasSwish)  && <PaymentType>
                <Label>
                    <PaymentLogoMark logo="swish" label="Swish" />
                    <LabelText>Swish</LabelText>
                </Label>
                <MethodStatus
                    isConfigured={configuredPaymentOptions.some(x => ['swish.swish', 'payex.swish'].includes(x.type))}
                    isPending={appliedForSwish || pendingConnections.some(x => x === 'swish')}
                    isFailed={
                        (appliedForSwish && approvalFailed) || declinedConnections.some(x => x === 'swish')
                    }
                    isDeclined={
                        (appliedForSwish && approvalDeclined) || declinedConnections.some(x => x === 'swish')
                    }
                />
            </PaymentType>}
            {showDefaultPaymentType(hasNonActiveCase, hasVipps) && <PaymentType>
                <Label>
                    <PaymentLogoMark logo="vipps" label="Vipps" />
                    <LabelText>Vipps</LabelText>
                </Label>
                <MethodStatus
                    isConfigured={configuredPaymentOptions.some(x => ['vipps', 'payex.vipps', 'bambora.vipps'].includes(x.type))}
                    isPending={appliedForVipps  || pendingConnections.some(x => x === 'vipps')}
                    isFailed={
                        (appliedForVipps && approvalFailed) || declinedConnections.some(x => x === 'vipps')
                    }
                    isDeclined={
                        (appliedForVipps && approvalDeclined) || declinedConnections.some(x => x === 'vipps')
                    }
                />
            </PaymentType>}
        </PaymentTypeGroup>
    );
};

const PaymentMethodConfiguration = ({ approvals, checkoutConfiguration, account }: PaymentMethodConfigurationProps) => {
    // Legacy connections statuses
    const configuredPaymentOptions = checkoutConfiguration?.configuration?.payment_options || [];

    const pendingConnections = resolveConnectionsStatuses(account, 'pending');
    const failedConnections = resolveConnectionsStatuses(account, 'failed');
    const declinedConnections = resolveConnectionsStatuses(account, 'declined');

    const hasNonActiveCase = checkHasCaseButNotActive(approvals);

    const lastApproval = approvals[approvals.length -1];
    const appliedForPaymentMethods = lastApproval?.payment_methods || [];
    const approvalDeclined = lastApproval?.case_status === 'DECLINED';
    const approvalFailed = lastApproval?.case_status === 'ERROR';
    return (
        <Card>
            <PaymentTypeGroupBnpl {...{ account, pendingConnections, failedConnections, declinedConnections, hasNonActiveCase, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined, approvalFailed }} />
            <PaymentTypeGroupCreditcard {...{ account, pendingConnections, failedConnections, declinedConnections, hasNonActiveCase, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined, approvalFailed }} />
            <PaymentTypeGroupWallets {...{ account, pendingConnections, failedConnections, declinedConnections, hasNonActiveCase, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined, approvalFailed }} />
            <PaymentTypeGroupMobile {...{ account, pendingConnections, failedConnections, declinedConnections, hasNonActiveCase, configuredPaymentOptions, appliedForPaymentMethods, approvalDeclined, approvalFailed }} />
        </Card>
    );
};

const BaseStatus = styled.span<{background: string}>`
    font-weight: 600;
    line-height: normal;
    padding: 8px 16px;
    border-radius: 32px;
    display: inline-block;
    background: ${props => props.background};
    color: #fff;
    margin-right: 2px;
    position: relative;
    overflow: hidden;
    max-width: 100%;

    &> span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

const EmptyStatus = styled.span`
    font-weight: 600;
    line-height: normal;
    padding: 7px 15px;
    border-radius: 32px;
    display: inline-block;
    background: transparent;
    border: 1px dashed ${colors.primaryDisabled};
    margin-right: 2px;
    position: relative;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: -6px;

    &> span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        color: ${colors.primaryDisabled};
    }
`;




const PaymentTypeGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
    margin-bottom: 64px;

    &:last-child {
        margin-bottom: 0px;
    }
`;

const PaymentType = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 16px;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
`;

const Label = styled.div`
    display: flex;
    flex-direction: row;
`;

const LabelText = styled.div`
    font-size: 18px;
    font-weight: 700;
`;



export default PaymentMethodConfiguration;
