import { memo } from 'react';
import { WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Button, ButtonLink } from '../../../components/Buttons';
import { DateTime } from '../../../components/Formatters';
import { Td, Tr } from '../../../components/Table/Fake';
import { Muted } from '../../../components/Typography';
import { distances, palette } from '../../../styles/constants';
import { PartnerAccountCollectionAccount } from '../../../types/management-auth';
import { getAccountManager } from '../helpers';
import { asConnections } from '../utils/account';
import { paymentOptionTranslationKeys } from '../utils/paymentOptionName';
import { priorityCompare } from '../utils/prioritySort';
import View from '../../../components/View';

export interface AccountRowProps extends WithTranslation {
    account: PartnerAccountCollectionAccount;
    getExchangeToken: (accountId: string, subAccountId: string) => void;
    accountId: string;
}

const connectionList = (account: PartnerAccountCollectionAccount): { status: string; i18n: string[]}[] => {
    return asConnections(account)
        .sort(priorityCompare('status', {
            'enabled': 1,
            'pending': 2,
            'declined': 3,
            'failed': 4,
        })).map(product => {
            let i18n = [`payments.payment_product.${product.key}`];
            if (product.type === 'payment_option') {
                i18n = paymentOptionTranslationKeys(product.key);
            }
            return {
                status: product.status,
                i18n,
            };
        });
};

const badgeColors: { [key: string]: any } = {
    enabled: palette.success[400],
    pending: palette.warning[400],
    declined: palette.destructive[300],
    undefined: palette.neutral[100],
};

const Org = styled.div`
    display: inline-block;
    margin-right: ${distances.tiny};
    background-color: #efefef;
    padding: 2px ${distances.tiny};
    border-radius: 3px;
    margin-top: 8px;
    font-size: 11px;
    font-weight: 700;
`;

const SignupReference = styled.div`
    display: inline-block;
    margin-right: ${distances.tiny};
    background-color: #ffaeec;
    padding: 2px ${distances.tiny};
    border-radius: 3px;
    margin-top: 8px;
    font-size: 11px;
    font-weight: 700;
    max-width: 200px;
    white-space: wrap;
    word-wrap: break-all;
`;


interface BadgeProps {
    status: string;
}

const ConnectionBadge = styled.span<BadgeProps>`
    padding: 2px 10px;
    margin-left: ${distances.tiny};
    border-radius: 24px;
    background: ${(props) => badgeColors[props.status]};
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const showDisplayName = (account: PartnerAccountCollectionAccount) => {
    if (!account.company.display_name) {
        return false;
    }
    if ((account.company.business_name || '').toUpperCase() === account.company.display_name.toUpperCase()) {
        return false;
    }
    return true;
};

const flagEmojiMap: {[key: string]: string} = {
    'NO': '🇳🇴',
    'SE': '🇸🇪',

};

const PaymentApprovalSummary = ({ account, connectionCount }: {account: PartnerAccountCollectionAccount; connectionCount: number}) => {
    const { t } = useTranslation();
    const paymentApprovals = account.approvals_payments_statuses || {};
    if (paymentApprovals.ACTIVE) {
        return <>
            <li><ConnectionBadge status="enabled">{t('partner.merchants.approvals_payments_status.active')}</ConnectionBadge></li>
            {connectionCount === 0 && <li><ConnectionBadge status="pending">{t('partner.merchants.approvals_payments_status.active_pending_connections')}</ConnectionBadge></li>}
        </>;
    }
    if (paymentApprovals.UNDER_MANUAL_REVIEW || paymentApprovals.AUTOMATIC_REVIEW) {
        return <li><ConnectionBadge status="pending">{t('partner.merchants.approvals_payments_status.pending_review')}</ConnectionBadge></li>;
    }
    if (paymentApprovals.WAITING_FOR_SIGNATURE) {
        return <li><ConnectionBadge status="pending">{t('partner.merchants.approvals_payments_status.waiting_for_signature')}</ConnectionBadge></li>;
    }
    if (paymentApprovals.WAITING_FOR_DETAILS) {
        return <li><ConnectionBadge status="pending">{t('partner.merchants.approvals_payments_status.waiting_for_details')}</ConnectionBadge></li>;
    }
    if (paymentApprovals.WAITING_FOR_DECLARATION) {
        return <li><ConnectionBadge status="pending">{t('partner.merchants.approvals_payments_status.waiting_for_declaration')}</ConnectionBadge></li>;
    }
    if (paymentApprovals.DECLINED) {
        return <li><ConnectionBadge status="declined">{t('partner.merchants.approvals_payments_status.declined')}</ConnectionBadge></li>;
    }
    if (paymentApprovals.ERROR) {
        return <li><ConnectionBadge status="declined">{t('partner.merchants.approvals_payments_status.error')}</ConnectionBadge></li>;
    }
    return null;
};

const SellersTitle = styled.span`
    display: block;
    margin-top: ${distances.tiny};
    margin-left: ${distances.tiny};
    font-size: 11px;
    font-weight: 700;
`;

const SellerStatsList = styled.ul`
    display: inline;
    list-style: none;
    padding: 0;
    margin: 0;
    &>li{
        display: inline;
        padding: 0;
        margin: 0;
    }
    &>li:after{
        content: " /";

    }
    &>li:last-child:after {
    content: "";
}
`;

const PayoutDestinationApprovalSummary = ({ account }: {account: PartnerAccountCollectionAccount}) => {
    const { t } = useTranslation();
    const approvals = account.approvals_payout_destinations_statuses || {};
    const pendingSum = [
        approvals.AUTOMATIC_REVIEW,
        approvals.UNDER_MANUAL_REVIEW,
        approvals.WAITING_FOR_SIGNATURE,
        approvals.WAITING_FOR_DECLARATION,
        approvals.WAITING_FOR_DETAILS,
    ].reduce<number>((sum, count) => sum + (count || 0), 0);
    const errorSum = [
        approvals.ERROR,
        approvals.DECLINED,
    ].reduce<number>((sum, count) => sum + (count || 0), 0);
    if (approvals?.ACTIVE || pendingSum) {
        return <div>
            <SellersTitle>{t('partner.merchants.payout_destinations')}</SellersTitle>
            <SellerStatsList>
                {(approvals?.ACTIVE || 0) > 0 && <li><ConnectionBadge status="enabled">{approvals.ACTIVE}</ConnectionBadge></li>}
                {pendingSum > 0 && <li><ConnectionBadge status="pending">{pendingSum}</ConnectionBadge></li>}
                {errorSum > 0 && <li><ConnectionBadge status="declined">{errorSum}</ConnectionBadge></li>}
            </SellerStatsList>
        </div>;
    }
    return null;
};

const AccountRow = (
    {
        account,
        getExchangeToken,
        accountId,
        t,
    }: AccountRowProps
) => {

    const connections = connectionList(account);
    return (
        <Tr key={account.id}>
            <Td className="first-child">{account.account_id} {flagEmojiMap[account?.company?.address?.country || '']}</Td>
            <Td>
                {showDisplayName(account) && (
                    <>
                        {account.company.display_name}
                        <br />
                    </>
                )}
                {account.company.business_name || <Muted>{t('partner.merchants.no_name_yet')}</Muted>}
                {account.company.organization_number && (
                    <>
                        <br />
                        <Org>Org. {account.company.organization_number}</Org>
                    </>
                )}
            </Td>
            <Td>
                <View direction="column" justify="flex-start" alignItems="flex-start">
                    {account.applicant.email}
                    {account.applicant.signup_reference && <SignupReference>ref: {account.applicant.signup_reference}</SignupReference>}
                </View>
            </Td>
            <Td>
                <UnstyledList>
                    {connections.map((product, idx) => {
                        const value = product.i18n.map((key: string) => t(key)).join(' ');
                        return (
                            <li key={idx}>
                                <ConnectionBadge
                                    status={product.status}
                                    title={t(`settings.payment_connections.status.${product.status}`)}
                                >
                                    {value}
                                </ConnectionBadge>
                            </li>
                        );
                    })}
                    {connections.length === 0 && <>
                        <PaymentApprovalSummary account={account} connectionCount={connections.length}/>
                    </>}
                    <PayoutDestinationApprovalSummary account={account} />
                </UnstyledList>
            </Td>
            <Td>{getAccountManager(account.account_manager?.email)}</Td>
            <Td>
                <DateTime isoString={account.created_at || ''} boldTime />
            </Td>
            <Td className="align-right no-ellipsis">
                <Actions>
                    <ButtonLink
                        className="alt full-width small-1800"
                        to={`/${accountId}/sub-accounts/${account.livemode ? 'P' : 'T'}${account.account_id}`}
                    >
                        {t('partner.merchants.details')}
                    </ButtonLink>
                    <Button
                        className="full-width small-1800"
                        onClick={() => {
                            getExchangeToken(accountId, `${account.livemode ? 'P' : 'T'}${account.account_id}`);
                        }}
                    >
                        {t('partner.merchants.inspect')}
                    </Button>
                </Actions>
            </Td>
        </Tr>
    );
};

const UnstyledList = styled.ul`
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  > li {
    margin-bottom: ${distances.micro};
  }
`;

const Actions = styled(View).attrs(p => ({
    gap: 16,
    direction: 'column',
    justify: 'flex-end',
}))`
  @media (min-width: 1800px) {
    flex-direction: row;
    gap: 16px;
  }
`;

export default withTranslation()(memo(AccountRow));
