import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { Button } from '../Buttons';

const BackButton = withRouter(({ history, children }) => (
    <Button className="alt" onClick={() => history.goBack()} type="button">
        {children}
    </Button>
));

export default BackButton;
