import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Delivery, Subscription, DeliveryDetails } from '../../../../types/webhooks';
import DeliveryRow from './DeliveryRow';

export interface DeliveriesTableProps extends WithTranslation {
    hook: Subscription;
    deliveries: Delivery[];
    deliveryDetails: DeliveryDetails[];
    getDeliveryDetails: (accountId: string, hookId: string, deliveryId: string) => void;
}

const DeliveryTable = (
    {
        deliveries,
        hook,
        deliveryDetails,
        getDeliveryDetails,
    }: DeliveriesTableProps
) => (<div>
    {deliveries.length > 0 && (
        <>
            {deliveries.map(delivery => [
                <DeliveryRow
                    key={delivery.id}
                    hook={hook}
                    delivery={delivery}
                    deliveryDetails={deliveryDetails}
                    getDeliveryDetails={getDeliveryDetails}
                />,
            ])}
        </>
    )}
</div>);

export default withTranslation()(DeliveryTable);
