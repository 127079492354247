import { BillingSubscription } from '../../types/billing';
import { Editable, PartnerAccount } from './types';
import { CheckoutConfig } from '../../types/checkout';
import { ApprovalsPaymentResponse } from '../../types/management-auth/generated';

export enum ActionTypes {
    GetMerchant = '[merchant] GET_MERCHANT',
    MerchantResponse = '[merchant] FETCH_MERCHANT_RESPONSE',
    MerchantCheckoutResponse = '[merchant] FETCH_MERCHANT_CHECKOUT_RESPONSE',
    MerchantNotFoundResponse = '[merchant] MERCHANT_NOT_FOUND',
    MerchantCheckoutNotFoundResponse = '[merchant] MERCHANT_CHECKOUT_NOT_FOUND',
    MerchantBillingResponse = '[merchant] FETCH_MERCHANT_BILLING_RESPONSE',
    ChangeEditState = '[merchant] CHANGE_MERCHANT_EDIT_STATE',
    EditMerchant = '[merchant] EDIT_MERCHANT_START',
    DownloadSignupFile = '[merchant] DOWNLOAD_SIGNUP_FILE',
    MerchantApprovalsPaymentsResponse = '[merchant] FETCH_MERCHANT_APPROVALS_PAYMENTS_RESPONSE',
}

export interface GetMerchant {
    type: ActionTypes.GetMerchant;
    payload: {
        accountId: string;
        subAccountId: string;
    };
}

export interface MerchantResponse {
    type: ActionTypes.MerchantResponse;
    payload: {
        merchant: PartnerAccount;
    };
    meta: {
        cause: GetMerchant;
    };
}

export interface MerchantCheckoutResponse {
    type: ActionTypes.MerchantCheckoutResponse;
    payload: {
        checkout: CheckoutConfig;
    };
    meta: {
        cause: GetMerchant;
    };
}
export interface MerchantBillingResponse {
    type: ActionTypes.MerchantBillingResponse;
    payload: {
        subscriptions: BillingSubscription[];
    };
    meta: {
        cause: GetMerchant;
    };
}

export interface MerchantNotFoundResponse {
    type: ActionTypes.MerchantNotFoundResponse;
    meta: {
        cause: GetMerchant;
    };
}
export interface MerchantCheckoutNotFoundResponse {
    type: ActionTypes.MerchantCheckoutNotFoundResponse;
    meta: {
        cause: GetMerchant;
    };
}

export function getMerchant(
    accountId: string,
    subAccountId: string
): GetMerchant {
    return {
        type: ActionTypes.GetMerchant,
        payload: {
            accountId,
            subAccountId,
        },
    };
}

export interface EditMerchant {
    type: ActionTypes.EditMerchant;
    payload: {
        partnerId: string;
        account: PartnerAccount;
    };
}

export function editMerchant(partnerId: string, account: PartnerAccount): EditMerchant {
    return {
        type: ActionTypes.EditMerchant,
        payload: {
            partnerId,
            account,
        },
    };
}

export interface ChangeEditState {
    type: ActionTypes.ChangeEditState;
    payload: {
        editing: Editable;
    };
}

export function changeEditState(editing: Editable): ChangeEditState {
    return {
        type: ActionTypes.ChangeEditState,
        payload: {
            editing,
        },
    };
}

export interface DownloadSignupFile {
    type: ActionTypes.DownloadSignupFile;
    payload: {
        accountId: string;
        subAccountId: string;
    };
}

export function downloadSignupFile(accountId: string, subAccountId: string): DownloadSignupFile {
    return {
        type: ActionTypes.DownloadSignupFile,
        payload: {
            accountId,
            subAccountId,
        },
    };
}

export interface MerchantApprovalsPaymentsResponse {
    type: ActionTypes.MerchantApprovalsPaymentsResponse;
    payload: {
        approvals: ApprovalsPaymentResponse[];
    };
    meta: {
        cause: GetMerchant;
    };
}

export type Actions =
    | GetMerchant
    | MerchantResponse
    | MerchantCheckoutResponse
    | MerchantApprovalsPaymentsResponse
    | MerchantNotFoundResponse
    | MerchantCheckoutNotFoundResponse
    | MerchantBillingResponse
    | ChangeEditState
    | EditMerchant;
