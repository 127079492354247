import styled from 'styled-components/macro';

const Dl = styled.dl`
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid transparent;
    position: relative;
`;

export default Dl;
