import React from 'react';
import styled from 'styled-components/macro';
import { H2, P } from '../../../../components/Typography';
import { ButtonExternalLink } from '../../../../components/Buttons';
import { withTranslation, WithTranslation } from 'react-i18next';
import { grid, distances, colors, defaultRadius } from '../../../../styles/constants';

type Props = WithTranslation

const Container = styled.div`
    max-width: ${grid.spans.span7};
    margin: 0 auto 100px;
    padding: ${distances.normal};
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
    border: 1px solid ${colors.borderLight};
    border-radius: ${defaultRadius};
`;

const NoOrderData = (
    {
        t,
    }: Props
) => {
    const link = 'https://docs.dintero.com/billing-api.html#tag/payout.rules';
    return (
        <Container>
            <H2>{t('payout_rules.payout_rules.no_data.title')}</H2>
            <br />
            <br />
            <P>{t('payout_rules.payout_rules.no_data.body')}</P>
            <br />
            <br />
            <ButtonExternalLink target="_blank" rel="noopener noreferrer" href={link}>
                {t('payout_rules.payout_rules.no_data.call_to_action')}
            </ButtonExternalLink>
        </Container>
    );
};

export default withTranslation()(NoOrderData);
