import fulfill, { getAuthorizationBearerHeader } from '../../fulfill';
import { SideEffectFunction } from '../../sideEffects';
import {
    loadTokens,
    ActionTypes,
    LoadTokens,
    FetchTokenResponse,
    UpdateAuthenticatedUser,
    UpdateAuthenticatedUserResult,
    SetAuthenticatedUser,
    LoadSubAccountTokens,
    FetchSubAccountTokensResponse,
    ChangePassword,
    ChangePasswordResponse,
    ChangeAuthenticatedUserMfaSms,
    ChangeAuthenticatedUserMfaSmsResponse, ChangeAuthenticatedUserMfaTotpResponse, ChangeAuthenticatedUserMfaTotp
} from './actions';
import { AccessToken, AuthToken, AuthenticatedAccountUser } from '../../types/management-auth';
import { getAccounts, logout } from '../cognito';
import { AuthenticatedUser } from '../types';
import { expiresAt } from '../../helpers/jwt';
import { sleep } from '../../helpers/sleep';
import { errorExternalStore, Causes } from '../../errors';

import { CORE_API_HOSTNAME } from '../../env';
import { HttpError } from '../../errors/types';

const getAccountIdsForAccount = (accountUser: AuthenticatedAccountUser, accountId: string) => {
    const withoutPrefix = accountId.substring(1);
    const matchingAccounts = accountUser.accounts.filter((a) => a.account_id.substring(1) === withoutPrefix);
    return matchingAccounts.map((a) => a.account_id);
};

const grant: AuthToken = {
    grant_type: 'account_user',
};

const fetchAccountTokens: SideEffectFunction<LoadTokens, FetchTokenResponse> = async (action, getState) => {
    // Get account tokens for account, both test and prod if exists

    const state = getState();
    const accountIds = getAccountIdsForAccount(state.accessToken.account_user, action.payload.accountId);

    const promises = accountIds.map((accountId) =>
        fulfill.post<AuthToken>({
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/auth/token`,
            json: grant,
            headers: getAuthorizationBearerHeader(state.accessToken.cognito_access_token),
            accountId: 'none',
            handlers: {
                200: (responseJson: AccessToken) => {
                    if (responseJson.access_token) {
                        const token = responseJson.access_token;
                        return {
                            accountId: accountId,
                            token,
                        };
                    } else {
                        throw new Error('200 response does not contain access_token');
                    }
                },
                403: () => {
                    return null;
                },
            },
        }));
    const results = await Promise.all(promises);
    const accounts = results.filter((x) => x);
    return {
        type: ActionTypes.FetchTokenResponse,
        payload: {
            accounts: accounts,
        },
    };
};

const fetchPasswordResult = (
    result: 'success' | 'wrong_password' | 'error' | 'rate_limit'
): ChangePasswordResponse => ({
    type: ActionTypes.ChangePasswordResponse,
    payload: {
        result,
    },
});

const fetchPostPassword: SideEffectFunction<ChangePassword, ChangePasswordResponse> = async (action, getState) => {
    // Get account tokens for account, both test and prod if exists

    const state = getState();

    return fulfill.post({
        url: `${CORE_API_HOSTNAME}/v1/account/user/password`,
        json: action.payload,
        headers: getAuthorizationBearerHeader(state.accessToken.cognito_access_token),
        accountId: 'none',
        handlers: {
            201: () => fetchPasswordResult('success'),
            400: () => fetchPasswordResult('wrong_password'),
            401: () => fetchPasswordResult('error'),
            403: () => fetchPasswordResult('error'),
            429: () => fetchPasswordResult('rate_limit'),
            500: () => fetchPasswordResult('error'),
        },
    });
};

const refetchAuthenticatedUser: SideEffectFunction<
    UpdateAuthenticatedUser,
    UpdateAuthenticatedUserResult | undefined
> = async (action, getState) => {
    const state = getState();
    const authenticatedAccountUser = await getAccounts(state.accessToken.cognito_access_token);
    if (authenticatedAccountUser) {
        return {
            type: ActionTypes.UpdateAuthenticatedUserResult,
            payload: { account_user: authenticatedAccountUser } as AuthenticatedUser,
            meta: {
                cause: action,
            },
        };
    } else {
        // unable to authenticate user so we force a logout so the user can login again
        logout(false);
    }
};

const refetchTokens: SideEffectFunction<UpdateAuthenticatedUserResult, LoadTokens> = async (action) => {
    // refetch tokens without showing a loading state
    const accountId = action.meta.cause.payload.accountId;
    return loadTokens(accountId, false);
};

const periodicallyCheckExpiry = async (expiresAt: number) => {
    while (expiresAt >  new Date().getTime()) {
        await sleep(60 * 1000);
    }
};

const logoutOnExpiry: SideEffectFunction<SetAuthenticatedUser, HttpError> = async (action) => {
    const cognitoToken = action.payload.accountUser.cognito_access_token;
    const expiry = expiresAt(cognitoToken);
    const buffer = 5 * 60 * 1000;
    await periodicallyCheckExpiry(expiry.getTime() - buffer);
    errorExternalStore.dispatch('setError', {
        cause: Causes.AccessTokenExpired,
    });
    return {
        cause: Causes.AccessTokenExpired,
    };
};

const fetchExchangeToken = async (accountId: string, subAccountId: string) => {
    return await fulfill.post({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/auth/exchange_token`,
        accountId: accountId,
        json: {
            account_id: subAccountId,
        },
        handlers: {
            200: (response: { access_token: string }) => response.access_token,
            403: () => null,
        },
    });
};

const fetchSubAccountTokens: SideEffectFunction<
    LoadSubAccountTokens,
    FetchSubAccountTokensResponse | undefined
> = async (action) => {
    // Get exchange token
    const exchangeToken = await fetchExchangeToken(action.payload.accountId, action.payload.subAccountId);
    if (!exchangeToken) {
        return {
            type: ActionTypes.FetchSubAccountTokensResponse,
            payload: {
                accounts: [],
            },
        };
    }

    // With exchange token get sub account tokens
    const nonPrefixedSubAccount = action.payload.subAccountId.substring(1);
    const promises = ['P', 'T']
        .map((prefix) => `${prefix}${nonPrefixedSubAccount}`)
        .map((accountId) =>
            fulfill.post<AuthToken>({
                url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/auth/token`,
                json: grant,
                headers: getAuthorizationBearerHeader(exchangeToken),
                accountId: 'none',
                handlers: {
                    200: (responseJson: AccessToken) => {
                        if (responseJson.access_token) {
                            const token = responseJson.access_token;
                            return {
                                accountId: accountId,
                                token,
                            };
                        } else {
                            throw new Error('200 response does not contain access_token');
                        }
                    },
                    403: () => {
                        return null;
                    },
                },
            }));
    const results = await Promise.all(promises);
    const accounts = results.filter((x) => x);
    return {
        type: ActionTypes.FetchSubAccountTokensResponse,
        payload: {
            accounts,
        },
    };
};

const fetchMfaSmsResult = (
    result: 'success' | 'invalid_phone' | 'error' | 'rate_limit'
): ChangeAuthenticatedUserMfaSmsResponse => ({
    type: ActionTypes.ChangeAuthenticatedUserMfaSmsResponse,
    payload: {
        result,
    },
});

const fetchPutMfaSms: SideEffectFunction<
    ChangeAuthenticatedUserMfaSms,
    ChangeAuthenticatedUserMfaSmsResponse
> = async (action, getState) => {
    // Get account tokens for account, both test and prod if exists

    const state = getState();

    return fulfill.put({
        url: `${CORE_API_HOSTNAME}/v1/account/user/mfa/sms`,
        json: action.payload,
        headers: getAuthorizationBearerHeader(state.accessToken.cognito_access_token),
        accountId: 'none',
        handlers: {
            201: () => fetchMfaSmsResult('success'),
            400: () => fetchMfaSmsResult('invalid_phone'),
            401: () => fetchMfaSmsResult('error'),
            403: () => fetchMfaSmsResult('error'),
            429: () => fetchMfaSmsResult('rate_limit'),
            500: () => fetchMfaSmsResult('error'),
        },
    });
};

const fetchMfaTotpResult = (
    result: 'success' | 'invalid_user_code' | 'error' | 'rate_limit'
): ChangeAuthenticatedUserMfaTotpResponse => ({
    type: ActionTypes.ChangeAuthenticatedUserMfaTotpResponse,
    payload: {
        result,
    },
});

const fetchPutMfaTotp: SideEffectFunction<
    ChangeAuthenticatedUserMfaTotp,
    ChangeAuthenticatedUserMfaTotpResponse
> = async (action, getState) => {
    // Get account tokens for account, both test and prod if exists

    const state = getState();

    return fulfill.put({
        url: `${CORE_API_HOSTNAME}/v1/account/user/mfa/totp`,
        json: action.payload,
        headers: getAuthorizationBearerHeader(state.accessToken.cognito_access_token),
        accountId: 'none',
        handlers: {
            201: () => fetchMfaTotpResult('success'),
            400: () => fetchMfaTotpResult('invalid_user_code'),
            401: () => fetchMfaTotpResult('error'),
            403: () => fetchMfaTotpResult('error'),
            429: () => fetchMfaTotpResult('rate_limit'),
            500: () => fetchMfaTotpResult('error'),
        },
    });
};

const effects = {
    [ActionTypes.SetAuthenticatedUser]: logoutOnExpiry,
    [ActionTypes.LoadTokens]: fetchAccountTokens,
    [ActionTypes.UpdateAuthenticatedUser]: refetchAuthenticatedUser,
    [ActionTypes.UpdateAuthenticatedUserResult]: refetchTokens,
    [ActionTypes.LoadSubAccountTokens]: fetchSubAccountTokens,
    [ActionTypes.ChangePassword]: fetchPostPassword,
    [ActionTypes.ChangeAuthenticatedUserMfaSms]: fetchPutMfaSms,
    [ActionTypes.ChangeAuthenticatedUserMfaTotp]: fetchPutMfaTotp,
};

export { effects };
