import { Component } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { State as AccountState } from '../reducer';
import { Account, UpdateAccount } from '../../../types/management-auth';
import NewAccountStep2 from './NewAccountStep2';
import NewAccountStep1 from './NewAccountStep1';
import { AccountIdsProps, withAccountIds } from '../../../auth/accessToken/withAccountIds';
import { isPartner } from '../../../auth/helpers';

interface MatchParams {
    accountId: string;
}

export interface NewAccountPageProps extends RouteComponentProps<MatchParams>, AccountIdsProps {
    createAccount: (accountId: string, account: UpdateAccount) => void;
    resetForm: () => void;
    account: AccountState;
    searchCompanyName: (partialName: string, accountId: string) => void;
    fillAccount: (account: Account) => void;
    getAccountType: (type: 'partner' | 'merchant') => void;
    getPricePackages: (accountId: string) => void;
}

class NewAccountPage extends Component<NewAccountPageProps> {
    componentDidMount() {
        const { account, getAccountType, match } = this.props;
        const { accountId } = match.params;
        if (!account.type) {
            const accountIsRoot = isPartner(accountId) && accountId.endsWith('00000000');
            getAccountType(accountIsRoot ? 'partner' : 'merchant');
        }
        if (!account.pricePackages.length) {
            this.props.getPricePackages(accountId);
        }
    }

    componentDidUpdate(prevProps: Readonly<NewAccountPageProps>): void {
        // If the form got reset but component is still mounted, get the account type
        if (prevProps.account.type !== this.props.account.type) {
            const { accountId } = this.props.match.params;
            const accountIsRoot = isPartner(accountId) && accountId.endsWith('00000000');
            this.props.getAccountType(accountIsRoot ? 'partner' : 'merchant');
        }
    }

    componentWillUnmount() {
        this.props.resetForm();
    }

    render() {
        const { match, account } = this.props;
        const { accountId } = match.params;
        if (account.isCreated) {
            const createdAccountId = account.newAccount?.account_id;
            const hasPartnerLink = !!account.partnerLink;
            return <Redirect to={`/${accountId}/sub-accounts?search=${createdAccountId}&newAccount=P${createdAccountId}&partnerLinkCreated=${hasPartnerLink}`} />;
        }

        if (!account.isPrefilled) {
            return <NewAccountStep1
                {...this.props}
                possibleCompanies={account.possibleCompanies}
                isLoading={account.isLoading}
                type={account.type}
            />;
        } else {
            return <NewAccountStep2 {...this.props} />;
        }
    }
}

export default withAccountIds(NewAccountPage);
