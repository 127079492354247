import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CHECKOUT_API_HOSTNAME, CORE_API_HOSTNAME } from '../../../env';
import {
    ActionTypes,
    FetchCreatePaymentProfileResponse,
    CreatePaymentProfile,
    GetCheckoutConfiguration,
    FetchCheckoutConfigurationResponse
} from './actions';
import { ProfileConfiguration, NewProfileConfiguration, CheckoutConfig } from '../../../types/checkout';
import { Account } from '../../../types/management-auth';

const fetchGetCheckoutConfiguration: SideEffectFunction<
    GetCheckoutConfiguration,
    FetchCheckoutConfigurationResponse
> = async action => {
    const result = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/checkout`,
        handlers: {
            200: (configuration: CheckoutConfig[]) => ({
                configuration,
            }),
            404: () => ({
                configuration: undefined,
            }),
        },
    });
    const account = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/settings`,
        handlers: {
            200: (account: Account) => ({
                account,
            }),
            404: () => ({
                account: undefined,
            }),
        },
    });
    return {
        type: ActionTypes.FetchCheckoutConfigurationResponse,
        payload: {
            configuration: result.configuration,
            account: account.account,
        },
        meta: {
            cause: action,
        },
    };
};

const fetchCreatePaymentProfile: SideEffectFunction<
    CreatePaymentProfile,
    FetchCreatePaymentProfileResponse
> = async action => {
    const result: FetchCreatePaymentProfileResponse = await fulfill.post<NewProfileConfiguration>({
        accountId: action.payload.accountId,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/session/profiles`,
        json: action.payload.newProfile,
        handlers: {
            200: (paymentProfile: ProfileConfiguration) => ({
                type: ActionTypes.CreatePaymentProfileResponse,
                payload: {
                    paymentProfile,
                    accountId: action.payload.accountId,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.GetCheckoutConfiguration]: fetchGetCheckoutConfiguration,
    [ActionTypes.CreatePaymentProfile]: fetchCreatePaymentProfile,
};

export default effects;
