import { connect } from 'react-redux';

import { getProfiles } from '../actions';
import { State } from '../../../../reducer';
import { getProfilesSelector } from '../selectors';

import ProfilesList from '../components/ProfilesList';

const mapStateToProps = (state: State) => ({
    profiles: getProfilesSelector(state),
});

const mapDispatchToProps = {
    getProfiles,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProfilesList);
