import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import ArrowTopRightOnSquareIcon from '@heroicons/react/20/solid/ArrowTopRightOnSquareIcon';

import { ButtonExternalLink } from '../../../components/Buttons';
import IconWrapper from '../../../components/Icons/IconWrapper';
import { toLanguageLink } from './utils';
import { getCurrentLanguage } from '../../../i18n';
import { P } from '../../../components/Typography';

const NotAvailablePartner = ({ productLabel }: {productLabel: string}) => {
    const { t, i18n } = useTranslation();
    return (
        <Group>
            <P>{t('settings.approvalsPayments.notAvailablePartnerText', { product: productLabel })}</P>
            <ButtonExternalLink
                href={
                    toLanguageLink(
                        `https://www.dintero.com/contact-us`,
                        getCurrentLanguage(i18n)
                    )
                }
                target="_blank"
            >
                <ButtonInner>
                    {t('settings.approvalsPayments.contactSalesButton')}
                    <IconWrapper>
                        <ArrowTopRightOnSquareIcon />
                    </IconWrapper>
                </ButtonInner>
            </ButtonExternalLink>
        </Group>
    );
};

const Group = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-top: 8px;
    margin-bottom: 24px;
`;

const ButtonInner = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
`;

export default NotAvailablePartner;
