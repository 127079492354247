type BamboraMerchantNumberValidatorOptions = {
    ignoreEmpty?: boolean;
};

export const validateBamboraMerchantNumber = (message: string, options?: BamboraMerchantNumberValidatorOptions) => {
    return (value: unknown) => {
        if (options?.ignoreEmpty === true) {
            if (value === undefined || value === null || value === '') {
                return; // Passthrough for other validators if empty
            }
        }
        /** Bambora merchant numbers appear to follow in the pattern: {char}<digits of any length> */
        const bamboraMerchantNumberRegex = new RegExp(/^[a-zA-Z]\d+$/);
        const isValid = typeof value === 'string' && bamboraMerchantNumberRegex.test(value);
        if (!isValid) {
            return message;
        }
    };
};
