import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { namespace } from './checkoutConfigurationActions';
import { useActions } from '../Actions';
import { isLoading as isLoadingSelector, checkoutConfiguration as checkoutConfigurationSelector } from './checkoutConfigurationSelectors';
import { useAccountIds } from '../auth/accessToken/withAccountIds';

export const useCheckoutConfiguration = (environment?: 'T' | 'P') => {
    const { getCheckoutConfig, updateCheckoutConfig } = useActions(namespace);
    const getCheckoutConfigRef = useRef(getCheckoutConfig);
    const { urlAccountId, testAccountId, prodAccountId } = useAccountIds();
    const accountIdMap = {
        'T': testAccountId,
        'P': prodAccountId,
    };
    const accountId = (environment ? accountIdMap[environment] : urlAccountId) || '';
    const isLoading = useSelector(state => isLoadingSelector(state, accountId));
    const checkoutConfiguration = useSelector(state => checkoutConfigurationSelector(state, accountId));
    useEffect(() => {
        if (accountId) {
            getCheckoutConfigRef.current(accountId);
        }
    }, [
        accountId, getCheckoutConfigRef,
    ]);
    return { isLoading, checkoutConfiguration, updateCheckoutConfiguration: updateCheckoutConfig };
};
