import { CheckoutConfig } from '../../../../types/checkout';
import { Dd, Dl, Dt } from '../../../../components/DescriptionList/Horizontal';
import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';

type ActivePaymentOptionsProps = {
    checkoutConfig: CheckoutConfig;
    paymentType: 'payex' | 'collector' | 'bambora';
}

export const ActivePaymentOptions = ({ checkoutConfig, paymentType }: ActivePaymentOptionsProps) => {
    const activePaymentOptions = checkoutConfig?.configuration.payment_options
        .filter(option => option.type.startsWith(`${paymentType}.`)).map(option => `${option.type} (${option.currencies.join(', ')})`) ?? [];
    const { t } = useTranslation();
    return (
        <Dl>
            <Dt doubleColumn>{t('settings.payment_connections.fields.active_payment_options')}</Dt>
            <Dd>
                <UnstyledList>
                    {activePaymentOptions.map(active => {
                        return (
                            <li key={active}>{active}</li>
                        );
                    })}
                </UnstyledList>
            </Dd>
        </Dl>
    );
};

const UnstyledList = styled.ul`
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-top: 0;
`;
