export enum ValidationState {
    Pristine = 'pristine',
    Valid = 'valid',
    Invalid = 'invalid',
}

export interface InputValidation {
    state: ValidationState;
    message?: string | JSX.Element;
}
