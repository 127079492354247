import React from 'react';

const CheckCircle = () => (
    <svg width="10px" height="9px" viewBox="0 0 10 9" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="check" fill="#0652E0" fillRule="nonzero">
            <polygon
                id="checkmark"
                points="8.2928932 0.66789322 3.75 5.21078644 1.70710678 3.16789322 0.29289322 4.58210678 3.75 8.0392136 9.7071068 2.08210678"
            ></polygon>
        </g>
    </svg>
);

export default CheckCircle;
