import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _pickFp from 'lodash/fp/pick';

import { useActions } from '../../../Actions';
import useDraft from '../../../util/useDraft';
import { useValidatedSchema } from '../../../util/useValidatedSchema';

import { externalSelectors as selectors } from '../../../payout/merchant/config/selectors';
import type {
    ConfigBankAccount,
    ConfigBankAccountDraft,
    ConfigRfc
} from '../../../payout/types';
import { Button, ButtonGroup } from '../../../components/Buttons';

import BankAccountForm from './BankAccountForm';
import { bankAccountSchema } from './validationSchema';
import { useAccountId } from '../../../auth/useAccountId';
import Modal from '../../../components/Modal';
import { H2, P } from '../../../components/Typography';
import View from '../../../components/View';

type ConfigBankAccountWithRfc = ConfigBankAccount & {
    rfc?: ConfigRfc;
};

type ConfigBankAccountPatchableKey = keyof ConfigBankAccountDraft;
const patchableKeys: ConfigBankAccountPatchableKey[] = [
    'bank_account_bic',
    'bank_account_country_code',
    'bank_account_currency',
    'bank_account_type',
    'is_default_for_currency',
    'nickname',
    'owner_address',
    'owner_city',
    'owner_country_code',
    'owner_name',
    'owner_orgno',
    'owner_postal_code',
];
const pickPatchable = _pickFp(patchableKeys);

export type EditBankAccountDialogProps = {
    open: boolean;
    onClose: () => void;
    bankAccount: ConfigBankAccountWithRfc;
}

const EditBankAccountDialog = ({ onClose, bankAccount, open, ...props }: EditBankAccountDialogProps) => {
    const { t } = useTranslation('payout');
    const [confirmCreateRfcDialogOpen, setConfirmCreateRfcDialogOpen] = useState(false);
    const accountId = useAccountId();
    const loading = useSelector(selectors.bankAccounts(accountId).isLoading);
    const { draft, combined, dirty, setValue, setTouched, setSubmit, clear, isTouched } = useDraft<ConfigBankAccountDraft>(bankAccount);
    const { result, hasErrors } = useValidatedSchema({ schema: bankAccountSchema, value: combined });
    const { createConfigRfc, updateBankAccount } = useActions('payout.config');

    const { rfc, id } = bankAccount;
    const hasRfc = !!rfc;

    const onDialogClose = () => {
        if (dirty) return;
        onClose();
    };

    const onSave = useCallback(async () => {
        setSubmit();
        if (!dirty || loading) return;
        if (Object.keys(draft).every((key) => ['nickname', 'is_default_for_currency'].includes(key))) {
            if (await updateBankAccount(accountId, id, pickPatchable(draft))) {
                clear();
                onClose();
            }
            return;
        }
        setConfirmCreateRfcDialogOpen(true);
    }, [accountId, loading, updateBankAccount, id, draft, clear, dirty, onClose, setSubmit]);

    const onConfirmCreateRfc = useCallback(async () => {
        if (!dirty || loading) return;
        if (await createConfigRfc(accountId, {
            type: 'configBankAccountUpdate',
            bank_account: pickPatchable(combined),
            id,
        })) {
            clear();
            onClose();
        }
        setConfirmCreateRfcDialogOpen(false);
    }, [accountId, createConfigRfc, id, dirty, loading, combined, clear, onClose]);

    if (!open) {
        return null;
    }


    return (<>
        <Modal onClose={onDialogClose}>
            <View gap={24} direction="column" justify="flex-start" alignItems="flex-start">
                <View direction="column" justify="flex-start" alignItems="flex-start">
                    <H2>
                        {`${combined.nickname}${!!combined.nickname ? ' - ' : ''}${combined.bank_account_number}`}
                    </H2>
                    <BankAccountForm
                        value={combined}
                        onChange={setValue}
                        onTouched={setTouched}
                        isTouched={isTouched}
                        errors={result}
                        disabled={hasRfc}
                        hideOwner
                        disabledFields={['bank_account_number']}
                    />
                </View>
                <ButtonGroup>
                    <Button
                        className="alt"
                        onClick={onClose}
                    >
                        {t('bank_account.create_dialog.cancel')}
                    </Button>
                    <Button
                        onClick={onSave}
                        disabled={hasErrors || !dirty}
                    >
                        {t('bank_account.create_dialog.save')}
                    </Button>
                </ButtonGroup>
            </View>
        </Modal>
        {confirmCreateRfcDialogOpen && <Modal>
            <View gap={24} direction="column" justify="flex-start" alignItems="flex-start">
                <View direction="column" justify="flex-start" alignItems="flex-start">
                    <H2>
                        {t('bank_account.create_rfc_dialog.title')}
                    </H2>
                    <P>
                        {t('bank_account.create_rfc_dialog.message')}
                    </P>
                </View>
                <ButtonGroup>
                    <Button
                        className="alt"
                        onClick={() => setConfirmCreateRfcDialogOpen(false)}
                    >
                        {t('bank_account.create_rfc_dialog.cancel')}
                    </Button>
                    <Button
                        onClick={onConfirmCreateRfc}
                        disabled={loading}
                    >
                        {t('bank_account.create_rfc_dialog.ok')}
                    </Button>
                </ButtonGroup>
            </View>
        </Modal>
        }
    </>);
};

export default EditBankAccountDialog;
