import { WithTranslation, withTranslation } from 'react-i18next';

import { Account } from '../../../types/management-auth';
import Card from '../../../components/Card';
import { distances } from '../../../styles/constants';
import { H3 } from '../../../components/Typography';
import Hr from '../../../components/Hr';
import CardArea from './CardArea';
import { Dl, Dt, Dd } from '../../../components/DescriptionList/Vertical';
import { CurrencyCode } from '../../../helpers/currency';

interface ProductAndServiceDetailsProps extends WithTranslation {
    account: Account;
}

function formatProjectedSales(priceRange: string, currency?: CurrencyCode): string {
    const maxPrice = priceRange.split('-')?.[1]?.trim() || '';

    return currency ? `${maxPrice} ${currency}` : maxPrice;
}

function formatAverageTransactionValue(avg_txn_value: string, currency?: CurrencyCode): string {
    const values = avg_txn_value.split('-').map((value) => (
        parseInt(value.trim().replace(/\s/g, ''))
    ));
    const total = values.reduce((acc, curr) => acc + curr);
    const avg = total / values.length;

    return currency ? `${avg} ${currency}` : `${avg}`;
}

function formatMostExpensiveProductPrice(price: string, currency?: CurrencyCode): string {
    return currency ? `${price.trim()} ${currency}` : `${price.trim()}`;
}

const ProductAndServiceDetails = (
    {
        t,
        account,
    }: ProductAndServiceDetailsProps
) => {
    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('partner.merchant.product_and_service_details')}</H3>
            <Hr />
            <CardArea>
                {account.average_transaction_value && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.average_transaction_value')}</Dt>
                        <Dd>{formatAverageTransactionValue(account.average_transaction_value, account.currency)}</Dd>
                    </Dl>
                )}
                {account.projected_sales && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.projected_sales')}</Dt>
                        <Dd>{formatProjectedSales(account.projected_sales, account.currency)}</Dd>
                    </Dl>
                )}
                {account.most_expensive_product_price && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.most_expensive_product_price')}</Dt>
                        <Dd>{formatMostExpensiveProductPrice(account.most_expensive_product_price, account.currency)}</Dd>
                    </Dl>
                )}
                {account.delivery_timeline && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.delivery_time')}</Dt>
                        <Dd>{t(`components.delivery_times.${account.delivery_timeline}`)}</Dd>
                    </Dl>
                )}
                {account.gift_card_revenue_percentage && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.gift_card_revenue_percentage')}</Dt>
                        <Dd>{`${account.gift_card_revenue_percentage}%`}</Dd>
                    </Dl>
                )}
                {account.credit_rating && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.credit_rating')}</Dt>
                        <Dd>{`${account.credit_rating}`}</Dd>
                    </Dl>
                )}
            </CardArea>
        </Card>
    );
};

export default withTranslation()(ProductAndServiceDetails);
