type SortingOrder = {[key: string]: number};

/**
 * Comparator to sort an array by one of its string properties
 * @param key A key of the object which has type string
 * @param sortingOrder An object of prioritized values, {highest_priority: 1, next_priority: 2} etc
 * @param order 'asc' | 'desc'
 */
export const priorityCompare = <T extends Record<string, string | undefined>>(key: keyof T, sortingOrder: SortingOrder, order: 'asc' | 'desc' = 'asc') => (a: T, b: T) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
    }

    const keyA = a[key]?.toLowerCase() ?? '';
    const keyB = b[key]?.toLowerCase() ?? '';

    const first = (keyA in sortingOrder) ? sortingOrder[keyA] : Number.MAX_SAFE_INTEGER;
    const second = (keyB in sortingOrder) ? sortingOrder[keyB] : Number.MAX_SAFE_INTEGER;

    let result = 0;
    if (first < second) {
        result = -1;
    } else if (first > second) {
        result = 1;
    }
    return (order === 'desc') ? ~result : result;
};
