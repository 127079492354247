import React, { useEffect } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ButtonGroup } from '../../components/Buttons';
import Hr from '../../components/Hr';
import Modal from '../../components/Modal';
import { H2, P } from '../../components/Typography';
import View from '../../components/View';
import { State } from '../../reducer';
import { defaultRadius, distances, palette } from '../../styles/constants';
import { AuthenticatedAccountUser } from '../../types/management-auth';
import { isFederatedSignin } from '../cognito';
import { ChangeMfaSmsModalStates, ChangeMfaTotpModalStates } from '../types';
import {
    changeMfaSms,
    changeMfaTotp,
    openAuthenticatedUserSettingsModal,
    updateAuthenticatedUser
} from './actions';
import { StyledButton, Wrapper } from './mfa/Components';
import MfaSmsSettings from './mfa/MfaSmsSettings';
import MfaTotpSettings from './mfa/MfaTotpSettings';
import * as selectors from './selectors';

const mapStateToProps = (state: State) => ({
    accountUser: selectors.getAccountUser(state),
    changeMfaSmsState: selectors.getMfaSmsState(state),
    changeMfaTotpState: selectors.getMfaTotpState(state),
});

const mapDispatchToProps = {
    changeMfaSms,
    changeMfaTotp,
    openAuthenticatedUserSettingsModal,
    updateAuthenticatedUser,
};

interface MatchParams {
    accountId: string;
}

interface ChangeAccountProps extends WithTranslation, RouteComponentProps<MatchParams> {
    accountUser: AuthenticatedAccountUser;
    changeMfaSmsState: ChangeMfaSmsModalStates;
    changeMfaTotpState: ChangeMfaTotpModalStates;
    changeMfaSms: (enabled: boolean, phone_number: string) => void;
    changeMfaTotp: (enabled: boolean, user_code?: string) => void;
    openAuthenticatedUserSettingsModal: () => void;
    updateAuthenticatedUser: (accountId: string) => void;
}

const ChangeSettings = ({
    t,
    match,
    history,
    accountUser,
    changeMfaSmsState,
    changeMfaTotpState,
    changeMfaSms,
    changeMfaTotp,
    openAuthenticatedUserSettingsModal,
    updateAuthenticatedUser,
}: ChangeAccountProps) => {
    // reset form on mount
    useEffect(() => {
        openAuthenticatedUserSettingsModal();
    }, [openAuthenticatedUserSettingsModal]);
    const accountId = match.params.accountId;
    const account = accountUser.accounts.find((x) => x.account_id === accountId);
    const mfaSmsEnabled = accountUser?.configuration?.mfa?.sms?.enabled || false;
    const mfaTotpEnabled = accountUser?.configuration?.mfa?.totp?.enabled || false;

    const [currentMfaType, setCurrentMfaType] = React.useState<string | undefined>();

    useEffect(() => {
        if (changeMfaSmsState === 'success' || changeMfaTotpState === 'success') {
            updateAuthenticatedUser(accountId);
        }
    }, [changeMfaSmsState, changeMfaTotpState, updateAuthenticatedUser, accountId]);

    const isFederatedSession = isFederatedSignin();

    return (
        <Modal overflowY="scroll">
            {(changeMfaSmsState === 'success' || changeMfaTotpState === 'success') ? (
                <>
                    <H2>{t('change_settings.title')}</H2>
                    <P></P>
                    <Hr/>
                    <Wrapper>
                        <P>{t('change_settings.success')}</P>
                        <SuccessIcon/>
                    </Wrapper>
                    <ButtonGroup>
                        <StyledButton type="button" className="alt" onClick={() => {
                            setCurrentMfaType(undefined);
                            openAuthenticatedUserSettingsModal();
                        }}>
                            {t('change_settings.close')}
                        </StyledButton>
                    </ButtonGroup>
                </>
            ) : (
                <>
                    <H2>{t('change_settings.title')}</H2>
                    <P>
                        <Trans
                            i18nKey="change_settings.subtitle"
                            values={{ email: account && account.user.email }}
                        >
                            <em/>
                        </Trans>
                    </P>
                    <Hr />
                    {isFederatedSession && <View direction="column" alignItems="flex-start" px={distances.tiny} py={distances.tiny} mb={distances.normal} wrap="wrap" style={{
                        background: palette.warning[50],
                        border: `1px solid ${palette.warning[200]}`,
                        borderRadius: defaultRadius,
                    }}>
                        <P fontWeight={600}>{t('change_settings.federated.title')}</P>
                        <P style={{
                            wordBreak: 'break-word',
                            whiteSpace: 'pre-wrap',
                        }}>{t('change_settings.federated.description')}</P>
                    </View>}
                    <MfaTotpSettings
                        account={account}
                        changeMfaTotpState={changeMfaTotpState}
                        changeMfaTotp={changeMfaTotp}
                        mfaTotpEnabled={mfaTotpEnabled}
                        setCurrentMfaType={setCurrentMfaType}
                        currentMfaType={currentMfaType}
                        disabled={isFederatedSession}
                    />
                    <MfaSmsSettings
                        account={account}
                        changeMfaSmsState={changeMfaSmsState}
                        changeMfaSms={changeMfaSms}
                        mfaSmsEnabled={mfaSmsEnabled}
                        setCurrentMfaType={setCurrentMfaType}
                        currentMfaType={currentMfaType}
                        disabled={isFederatedSession}
                    />

                    {currentMfaType === undefined && <ButtonGroup>
                        <StyledButton type="button" className="alt" onClick={() => history.goBack()}>
                            {t('change_settings.close')}
                        </StyledButton>
                    </ButtonGroup>}
                </>
            )}
        </Modal>
    );
};

const SuccessIcon = styled.div`
    background-image: url(/assets/icons/icon_check_circle_primary.svg);
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: ${distances.normal};
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ChangeSettings)));
