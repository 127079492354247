import React from 'react';
import { getValueAt } from '../../../helpers/getValueAt';
import { Input, ValidationState } from '../../../components/Forms';
import styled from 'styled-components/macro';
import { grid } from '../../../styles/constants';

interface ValidatedInputProps {
    values: any;
    initialValues: any;
    errors: any;
    path: string;
    placeholder: string;
    label: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    prefix?: string;
    maxlength?: number;
    formatter?: (value: string) => string;
}

export const ValidatedInput = (
    {
        values,
        initialValues,
        errors,
        path,
        onChange,
        onBlur,
        touched,
        required,
        prefix,
        maxlength,
        placeholder,
        label,
    }: ValidatedInputProps
) => {
    const value = getValueAt(path, values);
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    const validation =
        (was_touched || initialValue) && error
            ? {
                state: ValidationState.Invalid,
                message: error,
            }
            : undefined;
    return (
        <InputWrapper>
            <Input
                label={`${label}`}
                placeholder={placeholder}
                type="text"
                name={path}
                value={value || ''}
                onChange={(e) => {
                    onChange(e);
                }}
                onBlur={(e) => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={validation}
                prefix={prefix}
                maxlength={maxlength}
                required={required}
            />
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }
`;
