import React from 'react';
import { getValueAt } from '../../../../../helpers/getValueAt';
import { Input, ValidationState } from '../../../../../components/Forms';
import styled from 'styled-components/macro';
import { globalColumnMaxWidth, grid } from '../../../../../styles/constants';

interface ValidatedInputProps {
    values: any;
    errors: any;
    path: string;
    label: string;
    placeholder: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    setFieldValue?: (field: string, value: string) => void;
    setTouched?: (fields: any) => void;
    initialValues?: any;
}

const ValidatedInput = ({
    values,
    errors,
    path,
    label,
    placeholder,
    onChange,
    onBlur,
    touched,
    required,
}: ValidatedInputProps) => {
    const value = getValueAt(path, values);
    const error = getValueAt(path, errors);
    const was_toched = getValueAt(path, touched);
    const validation = error
        ? {
            state: ValidationState.Invalid,
            message: error,
        }
        : undefined;

    return (
        <InputWrapper>
            <Input
                label={required ? `${label} *` : label}
                placeholder={placeholder}
                type="text"
                name={path}
                value={value || ''}
                onChange={e => {
                    onChange(e);
                }}
                onBlur={e => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={was_toched && validation}
            />
        </InputWrapper>
    );
};

const InputWrapper = styled.div`
    width: ${grid.spans.span4};
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
    }
`;

export {
    ValidatedInput
};
