import { Client, Grant, HasSecretClientResponse } from '../../../types/management-auth';

export enum ActionTypes {
    GetClient = '[settings/clientDetails] GET_CLIENT',
    FetchClientResponse = '[settings/clientDetails] FETCH_CLIENT_RESPONSE',
    FetchGrantResponse = '[settings/clientDetails] FETCH_GRANT_RESPONSE',
    OpenDeleteClientModal = '[settings/clientDetails] OPEN_DELETE_CLIENT_MODAL',
    DismissDeleteClientModal = '[settings/clientDetails] DISMISS_DELETE_CLIENT_MODAL',
    DeleteClient = '[settings/clientDetails] DELETE_CLIENT',
    FetchDeleteClientResponse = '[settings/clientDetails] DELETE_CLIENT_RESPONSE',
    OpenRotateSecretModal = '[settings/clientDetails] OPEN_ROTATE_SECRET_MODAL',
    DismissRotateSecretModal = '[settings/clientDetails] DISMISS_ROTATE_SECRET_MODAL',
    RotateSecret = '[settings/clientDetails] ROTATE_SECRET',
    FetchRotateSecretResponse = '[settings/clientDetails] ROTATE_SECRET_RESPONSE',
    ToggleSecretIsSafe = '[settings/clientDetails] TOGGLE_ROTATE_SECRET_SAFE',
    OpenDeleteGrantModal = '[settings/clientDetails] OPEN_DELETE_GRANT_MODAL',
    DismissDeleteGrantModal = '[settings/clientDetails] DISMISS_DELETE_GRANT_MODAL',
    DeleteGrant = '[settings/clientDetails] DELETE_GRANT',
    FetchDeleteGrantResponse = '[settings/clientDetails] FETCH_DELETE_GRANT_RESPONSE',
}

export interface GetClient {
    type: ActionTypes.GetClient;
    payload: {
        accountId: string;
        clientId: string;
    };
}

export interface FetchClientResponse {
    type: ActionTypes.FetchClientResponse;
    payload: {
        client: Client;
    };
    meta: {
        cause: GetClient;
    };
}

export interface FetchGrantResponse {
    type: ActionTypes.FetchGrantResponse;
    payload: {
        grants: Grant[];
    };
    meta: {
        cause: GetClient;
    };
}

export function getClient(accountId: string, clientId: string): GetClient {
    return {
        type: ActionTypes.GetClient,
        payload: {
            accountId,
            clientId,
        },
    };
}

export interface DismissDeleteClientModal {
    type: ActionTypes.DismissDeleteClientModal;
    payload: {};
}

export function dismissDeleteClientModal(): DismissDeleteClientModal {
    return {
        type: ActionTypes.DismissDeleteClientModal,
        payload: {},
    };
}

export interface OpenDeleteClientModal {
    type: ActionTypes.OpenDeleteClientModal;
    payload: {};
}

export function openDeleteClientModal(): OpenDeleteClientModal {
    return {
        type: ActionTypes.OpenDeleteClientModal,
        payload: {},
    };
}

export interface DeleteClient {
    type: ActionTypes.DeleteClient;
    payload: {
        accountId: string;
        clientId: string;
    };
}

export function deleteClient(accountId: string, clientId: string): DeleteClient {
    return {
        type: ActionTypes.DeleteClient,
        payload: {
            accountId,
            clientId,
        },
    };
}

export interface FetchDeleteClientResponse {
    type: ActionTypes.FetchDeleteClientResponse;
    payload: {
        client: Client;
    };
    meta: {
        cause: DeleteClient;
    };
}
export interface OpenRotateSecretModal {
    type: ActionTypes.OpenRotateSecretModal;
    payload: {};
}

export function openRotateSecretModal(): OpenRotateSecretModal {
    return {
        type: ActionTypes.OpenRotateSecretModal,
        payload: {},
    };
}
export interface DismissRotateSecretModal {
    type: ActionTypes.DismissRotateSecretModal;
    payload: {};
}

export function dismissRotateSecretModal(): DismissRotateSecretModal {
    return {
        type: ActionTypes.DismissRotateSecretModal,
        payload: {},
    };
}

export interface RotateSecret {
    type: ActionTypes.RotateSecret;
    payload: {
        accountId: string;
        clientId: string;
    };
}

export function rotateSecret(accountId: string, clientId: string): RotateSecret {
    return {
        type: ActionTypes.RotateSecret,
        payload: {
            accountId,
            clientId,
        },
    };
}

export interface FetchRotateSecretResponse {
    type: ActionTypes.FetchRotateSecretResponse;
    payload: {
        rotated: HasSecretClientResponse;
    };
    meta: {
        cause: RotateSecret;
    };
}

export interface ToggleSecretIsSafe {
    type: ActionTypes.ToggleSecretIsSafe;
    payload: {
        value: boolean;
    };
}

export function toggleSecretIsSafe(value: boolean) {
    return {
        type: ActionTypes.ToggleSecretIsSafe,
        payload: {
            value,
        },
    };
}

export interface OpenDeleteGrantModal {
    type: ActionTypes.OpenDeleteGrantModal;
    payload: {
        grant: Grant;
    };
}

export function openDeleteGrantModal(grant: Grant): OpenDeleteGrantModal {
    return {
        type: ActionTypes.OpenDeleteGrantModal,
        payload: {
            grant,
        },
    };
}
export interface DismissDeleteGrantModal {
    type: ActionTypes.DismissDeleteGrantModal;
    payload: {};
}

export function dismissDeleteGrantModal(): DismissDeleteGrantModal {
    return {
        type: ActionTypes.DismissDeleteGrantModal,
        payload: {},
    };
}

export interface DeleteGrant {
    type: ActionTypes.DeleteGrant;
    payload: {
        accountId: string;
        grant_id: string;
    };
}

export function deleteGrant(accountId: string, grant_id: string): DeleteGrant {
    return {
        type: ActionTypes.DeleteGrant,
        payload: {
            accountId,
            grant_id,
        },
    };
}

export interface FetchDeleteGrantResponse {
    type: ActionTypes.FetchDeleteGrantResponse;
    payload: {
        grant: Grant;
    };
    meta: {
        cause: DeleteGrant;
    };
}

export type Actions =
    | GetClient
    | FetchClientResponse
    | FetchGrantResponse
    | OpenDeleteClientModal
    | DismissDeleteClientModal
    | DeleteClient
    | FetchDeleteClientResponse
    | OpenRotateSecretModal
    | DismissRotateSecretModal
    | RotateSecret
    | FetchRotateSecretResponse
    | ToggleSecretIsSafe
    | OpenDeleteGrantModal
    | DismissDeleteGrantModal
    | DeleteGrant
    | FetchDeleteGrantResponse;
