import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { colors, distances } from '../../../styles/constants';
import { SelectMfaTypeOptions } from '../../types';
import { selectMfaType } from '../../cognitoIdentityWrapper';
import { Button } from '../../../components/Buttons';
import { P } from '../../../components/Typography';

interface SelectMfaTypeProps {
    cognitoUser: CognitoUser;
    setBack: () => void;
    setShake: (value: boolean) => void;
    selectMfaTypeOptions: SelectMfaTypeOptions;
    setMfaRequired: (cognitoUser: CognitoUser, mfaType: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA') => void;
}

const SelectMfaType = ({ cognitoUser, setShake, selectMfaTypeOptions, setMfaRequired }: SelectMfaTypeProps) => {
    const { t } = useTranslation();
    const [selectError, setSelectError] = useState('');

    const selectOption = async (option: 'SMS_MFA' |'SOFTWARE_TOKEN_MFA') => {
        try {
            const selectResult = await selectMfaType(cognitoUser, option);
            if (selectResult.mfaRequired) {
                setMfaRequired(selectResult.mfaRequired, selectResult.mfaType || 'SMS_MFA');
            }
        } catch (error) {
            console.log('Error selecting mfa type.', error);
            setSelectError(error.message);
            setShake(true);
        }
    };

    return (
        <>
            <P style={{ textAlign: 'center' }}>{t('login.select_mfa_title')}</P>
            <ErrorMessage visible={selectError !== ''}>
                {t('login.select_mfa_error')}
            </ErrorMessage>
            <UnstyledList>
                {(JSON.parse(selectMfaTypeOptions.MFAS_CAN_CHOOSE) as ('SMS_MFA' |'SOFTWARE_TOKEN_MFA')[]).map(option => {
                    return (
                        <li key={option}>
                            <Button className="alt stretch" onClick={() => {
                                selectOption(option);
                            }}>{t(`login.select_mfa_type.${option}`)}
                            </Button>
                        </li>
                    );
                })}
            </UnstyledList>
        </>
    );
};

const UnstyledList = styled.ul`
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-top: 0;

    li {
        margin-bottom: 8px;
    }
`;

interface ErrorMessageProps {
    visible: boolean;
}

const ErrorMessage = styled.p<ErrorMessageProps>`
    text-align: center;
    font-size: 11px;
    line-height: ${distances.small};
    color: ${colors.invalid};
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export default SelectMfaType;
