import { connect } from 'react-redux';

import { completePayExConnection, closePayExCallbackModal, payExCreateCredentials } from '../actions';
import { State } from '../../../../reducer';
import { getPayExSelector } from '../selectors';
import { getUserId } from '../../../../auth/accessToken/selectors';

import PayExCallbackModal from '../components/PayExCallbackModal';

const mapStateToProps = (state: State) => ({
    payex: getPayExSelector(state),
    userId: getUserId(state),
});

const mapDispatchToProps = {
    completePayExConnection: completePayExConnection,
    payExCreateCredentials: payExCreateCredentials,
    closePayExCallbackModal: closePayExCallbackModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PayExCallbackModal);
