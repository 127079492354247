import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Button, ButtonGroup } from '../../../components/Buttons';
import { Checkbox } from '../../../components/Forms';
import { Label } from '../../../components/Typography';
import { SettlementReportConfig } from '../../../reports/settlementList/types';
import { border, colors, distances } from '../../../styles/constants';
import { PayoutDestinationOption } from '../../../util/usePayoutDestinations';
import PayoutDestinationDropdown from '../../components/PayoutDestinationDropdown';
import { ValidatedInput } from './ValidatedInput';

export type SettlementReportConfigurationFormikValues = {
    accountId: string;
    filetypes: SettlementReportConfig['filetypes'];
    filters: SettlementReportConfig['filters'];
    providers: SettlementReportConfig['providers'];
    emails: string[];
    accountEmail: boolean;
};

type ConfigurationFormProps = {
    formik: FormikProps<SettlementReportConfigurationFormikValues>;
    destinations: PayoutDestinationOption[];
    accountIds: string[];
    accountBillingEmailAddress?: string;
    dismissModal: () => void;
    operation: 'create' | 'edit';
};

const allFiletypes = ['csv', 'pdf', 'xml', 'json'] as const;

export const ConfigurationForm = ({ formik, destinations, accountIds, accountBillingEmailAddress, dismissModal, operation }: ConfigurationFormProps) => {
    const { t } = useTranslation();
    const { handleSubmit, setFieldValue, initialValues, values, handleChange, handleBlur, isSubmitting, isValid, errors, touched } = formik;
    return (
        <form onSubmit={handleSubmit}>
            {destinations.length > 0 && (
                <SpacedWrapper>
                    <PayoutDestinationDropdown
                        name="filters"
                        onChange={(value) => {
                            setFieldValue('filters', [
                                { filter: 'payout_destination_id', value: value },
                            ]);
                        }}
                        currentValue={(values.filters || []).find(
                            (x) => x.filter === 'payout_destination_id'
                        )?.value}
                        placeholder=""
                        accountId={values.accountId}
                        destinations={destinations}
                    />
                </SpacedWrapper>
            )}
            {accountBillingEmailAddress && <SpacedWrapper>
                <Label>{t('settings.settlement_report_config.form.fields.account_email')}</Label>
                <Checkbox
                    name="accountEmail"
                    label={accountBillingEmailAddress}
                    checked={values.accountEmail}
                    onChange={handleChange}
                />

            </SpacedWrapper>}
            {(values.emails || []).map((value, i) => (
                <SpacedWrapper key={`new-config-email-${i}`}>
                    <ValidatedInput
                        path={`emails.${i}`}
                        label={
                            t('settings.settlement_report_config.form.fields.email') +
                            ((values.emails || []).length > 1 ? ` ${i + 1}` : '')
                        }
                        placeholder={t('settings.settlement_report_config.form.placeholders.email')}
                        initialValues={initialValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                    />
                </SpacedWrapper>
            ))}
            <SpacedWrapper>
                <AddEmailButton
                    type="button"
                    onClick={() => {
                        setFieldValue('emails', [...(values.emails || []), '']);
                    }}
                >
                    {t('settings.settlement_report_config.form.fields.add_email')}
                </AddEmailButton>
            </SpacedWrapper>
            <SpacedWrapper>
                <Label>{t('settings.settlement_report_config.fields.filetypes')}</Label>
                <Options>
                    {allFiletypes.map(filetype => {
                        return (
                            <OptionItem key={filetype}>
                                <Checkbox
                                    name="filetypes"
                                    label={t(`settings.settlement_report_config.filetypes.${filetype}`)}
                                    value={filetype}
                                    checked={values.filetypes.includes(filetype)}
                                    onChange={handleChange}
                                />
                            </OptionItem>
                        );
                    })}
                </Options>
            </SpacedWrapper>

            <ButtonGroup>
                <Button className="alt" onClick={dismissModal}>
                    {t(`settings.settlement_report_config.${operation}.go_back`)}
                </Button>
                <Button type="submit" disabled={isSubmitting || !isValid}>
                    {t(`settings.settlement_report_config.${operation}.call_to_action`)}
                </Button>
            </ButtonGroup>
        </form>
    );

};

const Options = styled.div`
    display: flex;
`;
const OptionItem = styled.div`
    margin-right: ${distances.small};
`;

const ButtonSpacer = styled.span`
    display: inline-block;
    width: ${distances.tiny};
`;

const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.small};
`;

const AddEmailButton = styled(Button)`
    border: ${border.normal} dashed ${colors.borderDark};
    color: ${colors.primary};
    text-align: center;
    background-color: transparent;
    width: 100%;

    &:hover {
        background: ${colors.background};
        border: ${border.normal} solid ${colors.primary};
    }
    &:active {
        background: ${colors.primary};
        color: ${colors.background};
    }
`;
