import React, {  } from 'react';

interface BankStatementProps {
    width?: string;
    height?: string;
    fill?: string;
}

const BankStatement = (
    {
        width = '100%',
        height = '100%',
        fill = '#0000FF',
    }: BankStatementProps
) => (<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g
        fill={fill}
        stroke={fill}
        strokeLinecap="square"
        strokeLinejoin="miter"
        strokeMiterlimit="10"
        strokeWidth="2"
    >
        <rect x="1" y="1" width="30" height="30" rx="1" fill="transparent" stroke={fill} />
        <polygon fill="none" points="16 5 9 9 9 11 23 11 23 9 16 5" />
        <line fill="none" x1="11" x2="11" y1="16" y2="11" />
        <line fill="none" x1="16" x2="16" y1="16" y2="11" />
        <line fill="none" x1="21" x2="21" y1="16" y2="11" />
        <line fill="none" x1="9" x2="23" y1="20" y2="20" />
        <line fill="none" x1="9" x2="16" y1="25" y2="25" />
    </g>
</svg>);

export default BankStatement;
