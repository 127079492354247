import React, { Component } from 'react';

import BaseFilter from './BaseFilter';
import { H3 } from '../Typography';
import { FormattedInput, Input } from '../Forms';
import { CleaveOptions } from 'cleave.js/options';

export type OnChangeFunction = (event: Event) => void;

interface FilterSelectState {
    value: string;
}

interface InputFilterProps {
    name: string;
    label: string;
    title: string;
    placeholder: string;
    value: string;
    validate: (value: string) => string | undefined;
    maxlength?: number;
    options?: CleaveOptions;
    autoFocus: boolean;
    disabled?: boolean;
    prefix?: string;
    onApply: (value: string) => void;
    onDelete: (name: string) => void;
}

class InputFilter extends Component<InputFilterProps, FilterSelectState> {
    constructor(props: InputFilterProps, context: any) {
        super(props, context);
        this.state = { value: props.value };
    }

    handleChange = (name: string, value: string) => {
        const nextState = { [name]: value } as Pick<FilterSelectState, keyof FilterSelectState>;
        this.setState(nextState);
    };

    render() {
        const {
            label,
            name,
            title,
            placeholder,
            validate,
            maxlength,
            options,
            autoFocus,
            prefix,
            disabled,
            onApply,
            onDelete,
        } = this.props;
        const { value } = this.state;
        const inputProps = {
            name,
            placeholder,
            value,
            maxlength,
            autoFocus,
            prefix,
            onChange: (e: React.FormEvent<HTMLInputElement>) => this.handleChange('value', e.currentTarget.value),
            onBlur: (e: React.FormEvent<HTMLInputElement>) => this.handleChange('value', e.currentTarget.value),
        };
        return (
            <BaseFilter
                initialOpen={!value}
                label={label}
                disabled={disabled}
                name={name}
                onApply={() => {
                    onApply(value);
                }}
                onDelete={() => {
                    onDelete(name);
                }}
                applyIsDisabled={validate(value) !== undefined}
            >
                <H3>{title}</H3>
                {options ? <FormattedInput {...{ ...inputProps, options }} /> : <Input {...inputProps} />}
            </BaseFilter>
        );
    }
}

export default InputFilter;
