import styled from 'styled-components/macro';

import { distances } from '../../styles/constants';

const Thead = styled.thead`
    margin: ${distances.normal} 0;
    padding: 0;
`;

export default Thead;
