import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Button } from '../../../components/Buttons';
import { FilterSelect, Search } from '../../../components/Filters';
import { ParamChanges, updateParams } from '../../../helpers/urlParams';
import { distances } from '../../../styles/constants';

import { History, Location } from 'history';
import RefreshFilterButton from '../../../components/Filters/RefreshFilterButton';
import { accountFromPossibleCheckoutId } from '../../../util/checkoutId';
import AccountManagerFilter from './AccountManagerFilter';
import ConnectionFilter from './ConnectionFilter';
import ConnectionStatusFilter from './ConnectionStatusFilter';
import PaymentOptionsFilter from './PaymentOptionFilter';
import { FilterComponentWrapper, FilterRowWrapper, FiltersContainer, FiltersWrapper, Wrapper } from '../../../components/Filters/elements';

export interface StateFilterProps extends WithTranslation {
    queryParams: URLSearchParams;
    onChange: (changes: { [key: string]: string[] }) => void;
}

const updateFilterParams = (queryParams: URLSearchParams, changes: ParamChanges) => {
    // remove paging when filter changes
    const nonPagingChanges = { ...changes, starting_after: undefined };
    return updateParams(queryParams, nonPagingChanges);
};

const filterConfigs = {
    'account_manager': {
        component: AccountManagerFilter,
        keys: ['account_manager'],
    },
    'connection': {
        component: ConnectionFilter,
        keys: ['connection'],
    },
    'connection_status': {
        component: ConnectionStatusFilter,
        keys: ['connection_status'],
    },
    'payment_option': {
        component: PaymentOptionsFilter,
        keys: ['payment_option'],
    },
};
type FilterKey = keyof typeof filterConfigs;

const getConfigNamesFromQueryParams = (queryParamsKeys: string[]) => {
    const entries = Object.entries(filterConfigs);
    const matchingEntries = entries.filter(([_, config]) => {
        const matchingKeys = config.keys.filter(configKey => queryParamsKeys.includes(configKey));
        return matchingKeys.length > 0;
    });
    return matchingEntries.map(([name, _]) => name);
};

interface FiltersProps extends WithTranslation {
    location: Location;
    history: History;
    refresh: () => void;
    account_managers: string[];
}

const extraFilterProps = (props: FiltersProps): { [name: string]: { [key: string]: any } } => {
    return {
        'account_manager': {
            account_managers: props.account_managers,
        },
    };
};


interface FiltersState {
    pending: FilterKey | undefined;
}

class Filters extends Component<FiltersProps, FiltersState> {
    constructor(props: FiltersProps, context: any) {
        super(props, context);
        this.state = {
            pending: undefined,
        };
        this.setPending = this.setPending.bind(this);
    }

    setPending(pending: FilterKey | undefined) {
        this.setState({ pending });
    }

    render() {
        const { t, refresh, location, history } = this.props;
        const { pending } = this.state;
        const queryParams = new URLSearchParams(location.search.substring(1));
        const search = queryParams.get('search') || undefined;
        const queryParamKeys = [...queryParams.keys()];
        const visibleFilterNames = [...getConfigNamesFromQueryParams(queryParamKeys), pending];
        const addableFilters = Object.keys(filterConfigs).filter(key => !visibleFilterNames.includes(key));

        return (
            <Wrapper>
                <FilterRowWrapper>
                    <FiltersWrapper>
                        <FilterSelect
                            name="filterSelect"
                            disabled={!(!pending && addableFilters.length > 0)}
                            options={addableFilters.map(key => (
                                <Button
                                    className="alt tiny"
                                    onClick={() => this.setPending(key as FilterKey)}
                                    key={`add-filter-${key}`}
                                >
                                    {t(`partner.merchants.filter.${key}.add_filter_button`)}
                                </Button>
                            ))}
                        />

                    </FiltersWrapper>
                    <FilterComponentWrapper>
                        <Search
                            name="search"
                            placeholder={t('payments.filter.search_placeholder')}
                            defaultValue={search || ''}
                            onChange={value => {
                                const accountId = accountFromPossibleCheckoutId(value);
                                const nextParams = updateFilterParams(queryParams, { search: accountId || value });
                                history.push(`${location.pathname}?${nextParams.toString()}`);
                            }}
                            onBlur={() => {}}
                        />
                        <RefreshFilterButton onClick={refresh} title={t('payments.filter.refresh')} />
                    </FilterComponentWrapper>
                </FilterRowWrapper>
                <FiltersContainer>
                    {visibleFilterNames.filter((key): key is FilterKey => !!key)
                        .map(key => {
                            const FilterComponent = filterConfigs[key].component;
                            const extraProps = extraFilterProps(this.props)[key];
                            return (
                                <FilterComponentWrapper key={`transaction-filter-${key}`}>
                                    <FilterComponent
                                        {...extraProps as any}
                                        queryParams={queryParams}
                                        onChange={value => {
                                            const nextParams = updateFilterParams(queryParams, value);
                                            history.push(`${location.pathname}?${nextParams.toString()}`);
                                            if (key === pending) {
                                                this.setPending(undefined);
                                            }
                                        }}
                                        onClose={() => {
                                            if (Array.from(queryParams.keys()).length === 0 || key === pending) {
                                                this.setPending(undefined);
                                            }
                                        }}
                                    />
                                </FilterComponentWrapper>
                            );
                        })}

                </FiltersContainer>
            </Wrapper>
        );
    }
}

export default withTranslation()<React.ComponentType<FiltersProps>>(Filters);
