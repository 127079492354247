import React, {  } from 'react';
import styled from 'styled-components/macro';

import { H3, P } from '../../../../components/Typography';
import { distances } from '../../../../styles/constants';

interface OptionalFieldProps {
    title: string;
    text?: string;
}

const OptionalField = (
    {
        title,
        text,
    }: OptionalFieldProps
) => (<>
    {text && text.length > 0 && (
        <Field>
            <StyledH3>{title}</StyledH3>
            <P>{text}</P>
        </Field>
    )}
</>);

const Field = styled.div`
    margin-bottom: ${distances.small};
`;

const StyledH3 = styled(H3 as any)`
    line-height: 22px;
`;

export default OptionalField;
