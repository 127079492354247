import styled from 'styled-components/macro';

import { colors, distances, globalMobileBreakpoint, palette } from '../styles/constants';

type TypographyProps = {
    color?: React.CSSProperties['color'];
    fontSize?: React.CSSProperties['fontSize'];
    lineHeight?: React.CSSProperties['lineHeight'];
    fontWeight?: React.CSSProperties['fontWeight'];
    letterSpacing?: React.CSSProperties['letterSpacing'];
    margin?: React.CSSProperties['margin'];
    textTransform?: React.CSSProperties['textTransform'];
    textAlign?: React.CSSProperties['textAlign'];
};

type ParagraphProps = {
    variant?: 'xs' | 'sm' | 'md' | 'lg';
}

const H1 = styled.h1`
    margin: 0;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 120% */
    letter-spacing: -0.8px;

    @media (max-width: ${globalMobileBreakpoint}px) {
        font-size: 28px;
    }
`;

const H2 = styled.h2`
    margin: 0;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 44px; /* 122.222% */
    letter-spacing: -0.72px;
    @media (max-width: ${globalMobileBreakpoint}px) {
        font-size: 20px;
    }
`;

const H2Alt = styled.h2`
    font-size: 14px;
    font-weight: 700;
    line-height: ${distances.normal};
    text-transform: uppercase;
    margin: 0;
    font-style: normal;
    letter-spacing: -0.72px;
    color: ${palette.neutral[600]};
`;

const H3 = styled.h3`
    line-height: ${distances.normal};
    margin: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 125% */
    letter-spacing: -0.64px;
`;

const H4 = styled.h4`
    margin: 0;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 128.571% */
    letter-spacing: -0.56px;
`;

const H5 = styled.h5`
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
    letter-spacing: -0.48px;
    margin: 0;
`;

const H6 = styled.h6`
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
    margin: 0;
`;

const Overline = styled.h6`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
`;

const H4Label = styled.h4`
    padding: ${distances.tiny} 0;
    font-weight: 700;
    display: block;
    letter-spacing: -0.56px;
`;

const Label = styled.label`
    padding: ${distances.micro} 0;
    font-weight: 500;
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: ${colors.text};
`;

const Text = styled.span`
    color: ${colors.text};
    font-size: 14px;
    line-height: 22px;
`;

const P = styled.p<TypographyProps & ParagraphProps>`
    color: ${colors.text};
    font-size: 14px;
    line-height: 22px;
    margin: 0;

    &.margin-bottom {
        margin-bottom: ${distances.small};
    }

    &.margin-top {
        margin-top: ${distances.small};
    }

    ${props => props.color && `color: ${props.color};`}
    ${props => props.fontSize && `font-size: ${parseStylePropValue(props.fontSize)};`}
    ${props => props.lineHeight && `line-height: ${parseStylePropValue(props.fontSize)};`}
    ${props => props.fontWeight && `font-weight: ${props.fontWeight};`}
    ${props => props.letterSpacing && `letter-spacing: ${parseStylePropValue(props.fontSize)};`}
    ${props => props.margin && `margin: ${parseStylePropValue(props.fontSize)};`}
    ${props => props.textTransform && `text-transform: ${props.textTransform};`}
    ${props => props.textAlign && `text-align: ${props.textAlign};`}


    ${props => props.variant === 'xs' && `
        font-size: 12px;
        line-height: 20px;
    `}

    ${props => props.variant === 'sm' && `
        font-size: 14px;
        line-height: 22px;
    `}

    ${props => props.variant === 'md' && `
        font-size: 16px;
        line-height: 24px;
    `}

    ${props => props.variant === 'lg' && `
        font-size: 18px;
        line-height: 28px;
    `}
`;

const Muted = styled.span`
    color: ${palette.neutral[500]};
    font-size: 14px;
    line-height: ${distances.small};
`;
const Small = styled.small`
    color: ${colors.text};
    font-size: 11px;
    line-height: ${distances.small};
`;

const parseStylePropValue = <T, >(value: T) => {
    if (typeof value === 'number') {
        return `${value}px`;
    }

    return value;
};

export { H1, H2, H2Alt, H3, H4, H5, H6, Text, P, Muted, Small, H4Label, Label, Overline };
