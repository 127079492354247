import { Dispatch } from 'redux';

import { createConnectedActions as collector, namespace as collectorNamespace } from './collector/actions';
import { createConnectedActions as collectorCallback, namespace as collectorCallbackNamespace } from './collectorCallback/actions';
import { createConnectedActions as klarna, namespace as klarnaNamespace } from './klarna/actions';
import { createConnectedActions as bambora, namespace as bamboraNamespace } from './bambora/actions';
import { createConnectedActions as vipps, namespace as vippsNamespace } from './vipps/actions';
import { createConnectedActions as payex, namespace as payexNamespace } from './payex/actions';
import { createConnectedActions as swish, namespace as swishNamespace } from './swish/actions';

export type PaymentConnectionActions = {
    [collectorNamespace]: ReturnType<typeof collector>;
    [collectorCallbackNamespace]: ReturnType<typeof collectorCallback>;
    [klarnaNamespace]: ReturnType<typeof klarna>;
    [bamboraNamespace]: ReturnType<typeof bambora>;
    [vippsNamespace]: ReturnType<typeof vipps>;
    [payexNamespace]: ReturnType<typeof payex>;
    [swishNamespace]: ReturnType<typeof swish>;
}

export const createPaymentConnectionActions = (dispatch: Dispatch): PaymentConnectionActions => ({
    [collectorNamespace]: collector(dispatch),
    [collectorCallbackNamespace]: collectorCallback(dispatch),
    [klarnaNamespace]: klarna(dispatch),
    [bamboraNamespace]: bambora(dispatch),
    [vippsNamespace]: vipps(dispatch),
    [payexNamespace]: payex(dispatch),
    [swishNamespace]: swish(dispatch),
});
