import { effects as usersEffects } from './users';
import { effects as clientsEffects } from './clients';
import { effects as hooksEffects } from './hooks';
import { effects as paymentProfilesEffects } from './paymentProfiles';
import { effects as paymentConnectionsEffects } from './paymentConnections';
import { effects as payoutRulesEffects } from './payoutRules';
import { effects as locationsEffects } from './locations';

const effects = {
    ...usersEffects,
    ...clientsEffects,
    ...hooksEffects,
    ...paymentProfilesEffects,
    ...paymentConnectionsEffects,
    ...payoutRulesEffects,
    ...locationsEffects,
};

export default effects;
