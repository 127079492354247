import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Formik, FormikValues } from 'formik';

import { H1, P, Label } from '../../../../components/Typography';
import Modal from '../../../../components/Modal';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { colors, distances, grid, palette } from '../../../../styles/constants';
import { PayExCallbackModalState, State as PayExState } from '../reducer';
import { LoadingOverlay } from '../../../../components/Loading';
import { useAccountIds } from '../../../../auth/accessToken/withAccountIds';
import { PayExConnectionCallback } from '../../../../types/management-auth';
import {
    validate,
    validateRequired,
    validateHexadecimal,
    validateLength,
    validateMinLength,
    validateUuid
} from '../../../../helpers/validation';
import { getValueAt } from '../../../../helpers/getValueAt';
import { Checkbox, Input, ValidationState } from '../../../../components/Forms';
import { DINTERO_STANDARD_PAYEX_PAYEE_ID, DINTERO_STANDARD_2_PAYEX_PAYEE_ID, NODE_ENV } from '../../../../env';
import AgreementTypeDropdown from './AgreementTypeDropdown';
import { useAccount } from '../../../account/hooks';
import { getDefaultCountryCurrency, CurrencyCode } from '../../../../helpers/currency';
import { useActions } from '../../../../Actions';
import { useSelector } from 'react-redux';
import { payoutInfoSelector } from '../../../../payout/merchant/config/selectors';
import * as checkoutConfigSelectors from '../../../checkoutConfigurationSelectors';
import { PayExCredentialsData } from '../actions';

interface ValidatedInputProps {
    values: any;
    initialValues: any;
    errors: any;
    path: 'payee_id' | 'token';
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    maxlength?: number;
    formatter?: (value: string) => string;
}

const TranslatedValidatedInput = (
    {
        values,
        initialValues,
        errors,
        path,
        onChange,
        onBlur,
        touched,
        required,
        maxlength,
    }: ValidatedInputProps
) => {
    const { t } = useTranslation();
    const value = getValueAt(path, values);
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    const validation =
        (was_touched || initialValue) && error
            ? {
                state: ValidationState.Invalid,
                message: error,
            }
            : undefined;
    const label = t(`settings.payment_connections.payex_callback_modal.fields.${path}`);
    return (
        <InputWrapper>
            <Input
                label={label}
                placeholder={t(`settings.payment_connections.payex_callback_modal.placeholders.${path}`)}
                type="text"
                name={path}
                value={value || ''}
                onChange={(e) => {
                    onChange(e);
                }}
                onBlur={(e) => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={validation}
                maxlength={maxlength}
                required={required}
            />
        </InputWrapper>
    );
};

export interface PayExCallbackModalProps {
    completePayExConnection: (accountId: string, userId: string, data: PayExConnectionCallback) => void;
    payExCreateCredentials: (accountId: string, userId: string, data: PayExCredentialsData) => void;
    closePayExCallbackModal: () => void;
    payex: PayExState;
    userId: string;
}
const payexCurrencies: CurrencyCode[] = ['NOK', 'SEK', 'DKK', 'EUR'];

const PayExCallbackModal = (props: PayExCallbackModalProps) => {
    const accountIds = useAccountIds();
    const { payex } = props;
    if (!accountIds.prodAccountId) {
        return null;
    }
    if (payex.payexModalState === PayExCallbackModalState.Closed) {
        return null;
    }
    return <OpenPayExCallbackModalWithProductionAccount {...props} />;
};

const OpenPayExCallbackModalWithProductionAccount = ({
    payex,
    closePayExCallbackModal,
    completePayExConnection,
    payExCreateCredentials,
    userId,
}: PayExCallbackModalProps) => {
    const { t } = useTranslation();
    const accountIds = useAccountIds();
    const { account, isLoading } = useAccount();
    const getPayoutAccountConfig = useActions('payout.config').getAccountConfig;
    const getCheckoutConfig = useActions('checkout.config').getCheckoutConfig;
    const { loading, hasPayout } = useSelector(payoutInfoSelector(accountIds.optimisticProdAccountId));
    const { checkoutConfig } = useSelector(state => {
        return ({
            checkoutConfig: checkoutConfigSelectors.checkoutConfiguration(state, accountIds.optimisticProdAccountId),
        });
    });
    useEffect(() => {
        getPayoutAccountConfig(accountIds.optimisticProdAccountId);
        getCheckoutConfig(accountIds.optimisticProdAccountId);
    }, [accountIds.optimisticProdAccountId, getCheckoutConfig, getPayoutAccountConfig]);

    if (isLoading || loading) {
        return null;
    }

    const initialFormValues = {
        agreement_type: 'dedicated',
        payee_id: '',
        token: '',
        subsite: '',
        currencies: [getDefaultCountryCurrency(account, payexCurrencies, 'NOK')],
        enable_payout: false,
    };

    if (payex.type === 'swap_agreement' && checkoutConfig) {
        const containsOverride = checkoutConfig.gateways?.payex?.overrides;
        console.log({ containsOverride });
        return (
            <Modal fixedWidth>
                {containsOverride ?
                    <>
                        <SpacedWrapper>
                            <P>
                                <Trans i18nKey="settings.payment_connections.payex_callback_modal.override_warning" />
                            </P>
                        </SpacedWrapper>
                        <ButtonGroup>
                            <Button className="alt" onClick={closePayExCallbackModal}>
                                {t('settings.payment_connections.payex_callback_modal.close')}
                            </Button>
                        </ButtonGroup>
                    </>
                    : [PayExCallbackModalState.ApplicationForm, PayExCallbackModalState.InitiateApplicationLoading].includes(
                        payex.payexModalState
                    ) && (
                        <>
                            <H1>{t('settings.payment_connections.payex.add_new_agreement')}</H1>
                            <Formik
                                enableReinitialize
                                initialValues={initialFormValues as FormikValues}
                                validate={(values) => {
                                    let errors: { [key: string]: string } = {};
                                    if (!values) {
                                        // this should not happen but...
                                        return { error: 'account not loaded yet...' };
                                    }

                                    const validators = [
                                        {
                                            path: 'token',
                                            validator: validateHexadecimal(
                                                t('settings.payment_connections.payex_callback_modal.invalid.token.invalid')
                                            ),
                                        },
                                        {
                                            path: 'token',
                                            validator: validateLength(
                                                64,
                                                t('settings.payment_connections.payex_callback_modal.invalid.token.invalid')
                                            ),
                                        },
                                        {
                                            path: 'token',
                                            validator: validateRequired(
                                                t('settings.payment_connections.payex_callback_modal.invalid.token.required')
                                            ),
                                        },
                                        {
                                            path: 'payee_id',
                                            validator: validateUuid(
                                                t('settings.payment_connections.payex_callback_modal.invalid.payee_id.invalid')
                                            ),
                                        }, {
                                            path: 'payee_id',
                                            validator: validateRequired(
                                                t('settings.payment_connections.payex_callback_modal.invalid.payee_id.required')
                                            ),
                                        },
                                    ];

                                    errors = validators.reduce((acc, elem) => {
                                        return validate(elem.path, elem.validator, values, acc);
                                    }, errors);

                                    return errors;
                                }}
                                onSubmit={(values) => {
                                    const payload: PayExCredentialsData = {
                                        name: values.payee_id,
                                        data: {
                                            payee_id: values.payee_id,
                                            token: values.token,
                                            subsite: accountIds.baseAccountId,
                                            url: NODE_ENV === 'development' ? 'https://api.externalintegration.payex.com' : 'https://api.payex.com',
                                        },
                                        promote: [{ type: 'default', value: '' }],
                                    };
                                    payExCreateCredentials(accountIds.optimisticProdAccountId, userId, payload);
                                }}
                            >
                                {({ values, errors, handleChange, handleBlur, touched, handleSubmit }) => (
                                    <form onSubmit={handleSubmit}>
                                        <SpacedWrapper>
                                            <P>
                                                <Trans i18nKey="settings.payment_connections.payex_callback_modal.intro_new_agreement" />
                                            </P>
                                            <SpacedWrapper>
                                                <TranslatedValidatedInput
                                                    path="payee_id"
                                                    initialValues={initialFormValues}
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                />
                                                <TranslatedValidatedInput
                                                    path="token"
                                                    initialValues={initialFormValues}
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                />
                                            </SpacedWrapper>
                                        </SpacedWrapper>
                                        <ButtonGroup>
                                            <Button className="alt" onClick={closePayExCallbackModal}>
                                                {t('settings.payment_connections.payex_callback_modal.cancel')}
                                            </Button>
                                            <Button
                                                type="submit"
                                                disabled={
                                                    Object.keys(errors || {}).length > 0 ||
                                            Object.keys(touched || {}).length === 0
                                                }
                                            >
                                                {t(
                                                    'settings.payment_connections.payex_callback_modal.sign_up_with_payex_call_to_action'
                                                )}
                                            </Button>
                                        </ButtonGroup>
                                        {payex.payexModalState === PayExCallbackModalState.InitiateApplicationLoading && (
                                            <LoadingOverlay />
                                        )}
                                    </form>
                                )}
                            </Formik>
                        </>
                    )}
                {payex.payexModalState === PayExCallbackModalState.Error &&
                    <>
                        <H1>{t('settings.payment_connections.payex_callback_modal.title_error')}</H1>

                        <SpacedWrapper>
                            <P>
                                <Trans i18nKey="settings.payment_connections.payex_callback_modal.error_description">
                                    <a title="support@dintero.com" href="mailto:support@dintero.com">
                                        {' '}
                                    </a>
                                </Trans>
                            </P>
                            {payex.error && <ErrorWrapper>
                                {payex.error?.error.message}
                            </ErrorWrapper>}
                        </SpacedWrapper>

                        <ButtonGroup>
                            <Button onClick={closePayExCallbackModal}>
                                {t('settings.payment_connections.payex_callback_modal.close')}
                            </Button>
                        </ButtonGroup>
                    </>
                }
                {payex.payexModalState === PayExCallbackModalState.ApplicationReceived &&
                    <>
                        <H1>{t('settings.payment_connections.payex_callback_modal.title_last_step_new_agreement')}</H1>

                        <SpacedWrapper>
                            <P>
                                <Trans i18nKey="settings.payment_connections.payex_callback_modal.last_step_new_agreement" />
                            </P>
                        </SpacedWrapper>

                        <ButtonGroup>
                            <Button onClick={closePayExCallbackModal}>
                                {t('settings.payment_connections.payex_callback_modal.close')}
                            </Button>
                        </ButtonGroup>
                    </>
                }
            </Modal>
        );
    }

    return (
        <Modal fixedWidth height="800px">
            {[PayExCallbackModalState.ApplicationForm, PayExCallbackModalState.InitiateApplicationLoading].includes(
                payex.payexModalState
            ) && (
                <>
                    <H1>{t('settings.payment_connections.payex_callback_modal.title_intro')}</H1>
                    <Formik
                        enableReinitialize
                        initialValues={initialFormValues as FormikValues}
                        validate={(values) => {
                            let errors: { [key: string]: string } = {};
                            if (!values) {
                                // this should not happen but...
                                return { error: 'account not loaded yet...' };
                            }

                            const validators = [
                                {
                                    path: 'token',
                                    validator: validateHexadecimal(
                                        t('settings.payment_connections.payex_callback_modal.invalid.token.invalid')
                                    ),
                                },
                                {
                                    path: 'token',
                                    validator: validateLength(
                                        64,
                                        t('settings.payment_connections.payex_callback_modal.invalid.token.invalid')
                                    ),
                                },
                                {
                                    path: 'token',
                                    validator: validateRequired(
                                        t('settings.payment_connections.payex_callback_modal.invalid.token.required')
                                    ),
                                },
                                {
                                    path: 'currencies',
                                    validator: validateMinLength(
                                        1,
                                        t(
                                            'settings.payment_connections.payex_callback_modal.invalid.currencies.required'
                                        )
                                    ),
                                },
                            ];
                            if (values.agreement_type === 'dedicated') {
                                validators.push({
                                    path: 'payee_id',
                                    validator: validateUuid(
                                        t('settings.payment_connections.payex_callback_modal.invalid.payee_id.invalid')
                                    ),
                                });
                                validators.push({
                                    path: 'payee_id',
                                    validator: validateRequired(
                                        t('settings.payment_connections.payex_callback_modal.invalid.payee_id.required')
                                    ),
                                });
                            }

                            errors = validators.reduce((acc, elem) => {
                                return validate(elem.path, elem.validator, values, acc);
                            }, errors);

                            return errors;
                        }}
                        onSubmit={(values) => {
                            const payload: PayExConnectionCallback = {
                                payee_id: values.payee_id,
                                token: values.token,
                                payment_options: [
                                    {
                                        currencies: values.currencies,
                                        type: 'payex.creditcard',
                                    },
                                ],
                                ...(values.agreement_type === 'dedicated' && values.enable_payout && { payout: {
                                    payment_products: [
                                        { payment_product: 'payex' },
                                    ],
                                } }),
                            };
                            if (values.agreement_type === 'standard') {
                                payload.payee_id = DINTERO_STANDARD_PAYEX_PAYEE_ID;
                                payload.subsite = accountIds.baseAccountId;
                            } else if (values.agreement_type === 'standard_2') {
                                payload.payee_id = DINTERO_STANDARD_2_PAYEX_PAYEE_ID;
                                payload.subsite = accountIds.baseAccountId;
                            }
                            completePayExConnection(accountIds.optimisticProdAccountId, userId, payload);
                        }}
                    >
                        {({ values, errors, handleChange, handleBlur, setFieldValue, touched, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                <SpacedWrapper>
                                    <P>
                                        <Trans i18nKey="settings.payment_connections.payex_callback_modal.intro" />
                                    </P>
                                    <SpacedWrapper>
                                        <Label>
                                            {t('settings.payment_connections.payex_callback_modal.fields.currencies')}
                                        </Label>
                                        {payexCurrencies.map((currency) => (
                                            <CheckboxWrapper key={`payex-currency-${currency}`}>
                                                <Checkbox
                                                    name={`payex-currency-${currency}`}
                                                    label={currency}
                                                    checked={values.currencies.includes(currency)}
                                                    onChange={() => {
                                                        if (values.currencies.includes(currency)) {
                                                            setFieldValue(
                                                                'currencies',
                                                                values.currencies.filter((c: string) => c !== currency)
                                                            );
                                                        } else {
                                                            setFieldValue('currencies', [
                                                                ...values.currencies,
                                                                currency,
                                                            ]);
                                                        }
                                                    }}
                                                />
                                            </CheckboxWrapper>
                                        ))}

                                        <AgreementTypeDropdown
                                            value={values.agreement_type}
                                            name="agreement_type"
                                            label={t(
                                                'settings.payment_connections.payex_callback_modal.fields.agreement_type'
                                            )}
                                            placeholder=""
                                            onChange={(value) => setFieldValue('agreement_type', value)}
                                        />
                                        {values.agreement_type === 'dedicated' && (<>
                                            {hasPayout && <>
                                                <SpacedWrapper>
                                                    <Checkbox
                                                        name="enable_payout"
                                                        label={t('settings.payment_connections.payex_callback_modal.fields.enable_payout')}
                                                        checked={values.enable_payout}
                                                        onChange={(event) => setFieldValue('enable_payout', event.target.checked)}
                                                    />
                                                </SpacedWrapper>
                                                <SpacedWrapper>
                                                    <Alert>
                                                        <AlertTitle>{t('settings.payment_connections.payex_callback_modal.dedicated_agreement.notice.title')}</AlertTitle>
                                                        <Trans i18nKey="settings.payment_connections.payex_callback_modal.dedicated_agreement.notice.body">
                                                            <a href="mailto:payex@settlements.dintero.com">{' '}</a>
                                                        </Trans>
                                                    </Alert>
                                                </SpacedWrapper>
                                            </>}
                                            <TranslatedValidatedInput
                                                path="payee_id"
                                                initialValues={initialFormValues}
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                required
                                            />
                                        </>)}
                                        <TranslatedValidatedInput
                                            path="token"
                                            initialValues={initialFormValues}
                                            values={values}
                                            errors={errors}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            touched={touched}
                                            required
                                        />
                                    </SpacedWrapper>
                                </SpacedWrapper>
                                <ButtonGroup>
                                    <Button className="alt" onClick={closePayExCallbackModal}>
                                        {t('settings.payment_connections.payex_callback_modal.cancel')}
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={
                                            Object.keys(errors || {}).length > 0 ||
                                            Object.keys(touched || {}).length === 0
                                        }
                                    >
                                        {t(
                                            'settings.payment_connections.payex_callback_modal.sign_up_with_payex_call_to_action'
                                        )}
                                    </Button>
                                </ButtonGroup>
                                {payex.payexModalState === PayExCallbackModalState.InitiateApplicationLoading && (
                                    <LoadingOverlay />
                                )}
                            </form>
                        )}
                    </Formik>
                </>
            )}
            {payex.payexModalState === PayExCallbackModalState.Error &&
                    <>
                        <H1>{t('settings.payment_connections.payex_callback_modal.title_error')}</H1>

                        <SpacedWrapper>
                            <P>
                                <Trans i18nKey="settings.payment_connections.payex_callback_modal.error_description">
                                    <a title="support@dintero.com" href="mailto:support@dintero.com">
                                        {' '}
                                    </a>
                                </Trans>
                            </P>
                        </SpacedWrapper>

                        <ButtonGroup>
                            <Button onClick={closePayExCallbackModal}>
                                {t('settings.payment_connections.payex_callback_modal.close')}
                            </Button>
                        </ButtonGroup>
                    </>
            }
            {payex.payexModalState === PayExCallbackModalState.ApplicationReceived && (
                <>
                    <H1>{t('settings.payment_connections.payex_callback_modal.title_last_step')}</H1>

                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.payex_callback_modal.last_step" />
                        </P>
                    </SpacedWrapper>

                    <ButtonGroup>
                        <Button onClick={closePayExCallbackModal}>
                            {t('settings.payment_connections.payex_callback_modal.close')}
                        </Button>
                    </ButtonGroup>
                </>
            )}
        </Modal>
    );
};

export default PayExCallbackModal;

const CheckboxWrapper = styled.div`
    margin-bottom: ${distances.small};
`;

const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;

const InputWrapper = styled.div`
    &:last-child {
        margin: 0;
    }
`;

const ErrorWrapper = styled.div`
    padding-top: ${distances.small};
    font-size: 14px;
    font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter',
        'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New',
        Courier, monospace;
    color: ${colors.invalid};
`;

const Alert = styled.div`
    padding: ${distances.small};
    border: 1px solid ${colors.warning};
    background-color: ${palette.warning[100]};
`;

const AlertTitle = styled.div`
    font-weight: 500;
    margin-bottom: ${distances.small};
`;
