import { getValueAt } from '../getValueAt';

/**
 * Validate that the value exists in another field
 */
export const validateExists = (message: string, paths: string[], values: any) => {
    return (value: any) => {
        const doesExistAlready = paths.some(path => {
            return value === getValueAt(path, values);
        });
        if (doesExistAlready) {
            return message;
        }
    };
};
