import { effects as profilesEffects } from './profiles';
import { effects as newPaymentProfileEffects } from './newProfile';
import { effects as editPaymentProfileEffects } from './editProfile';
import { effects as paymentProfileEffects } from './profileDetails';

const effects = {
    ...profilesEffects,
    ...newPaymentProfileEffects,
    ...paymentProfileEffects,
    ...editPaymentProfileEffects,
};

export default effects;
