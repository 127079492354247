import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import { PersistedInsightReportConfig } from '../../types/insight';

type ReportFilter = Required<PersistedInsightReportConfig>['reportFilters'][number];

const Filters = (
    {
        filters,
        destinationIdLabelMap,
    }: {
        filters: ReportFilter[] | undefined;
        destinationIdLabelMap?: {[destination_id: string]: string};
    }
) => {
    const { t } = useTranslation();
    if (!filters || filters.length === 0) {
        return <>-</>;
    }
    return (
        <FiltersWrapper>
            {filters.map(({ filter, value }) => {
                const destinationIdLabelMapElement = value && destinationIdLabelMap && destinationIdLabelMap[value];
                if (filter === 'older_than_days') {
                    value = t('settings.reports.create.fields.older_than_weeks', { count: Number(value) / 7 });
                }
                return (
                    <FilterDiv key={`filter-${filter}-${value}`}>
                        <strong>{t('settings.reports.filters.filter', { context: filter })}</strong>
                        {destinationIdLabelMapElement && <>
                            <br />
                            <code>{destinationIdLabelMapElement}</code>
                        </>}
                        {destinationIdLabelMapElement !== value && <>
                            <br />
                            <code>{value}</code>
                        </>}
                    </FilterDiv>
                );
            })}
        </FiltersWrapper>
    );
};

const FilterDiv = styled.div`
    display: inline-block;
    text-align: center;
    border-radius: 3px;
    background-color: #efefef;
    margin-bottom: 4px;
    font-size: 11px;
    padding: 4px;
`;
const FiltersWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 8px;
`;

export default Filters;
