import { Fragment } from 'react';

type LinesProps = {
    lines: any[];
}

const LineBreaks = ({ lines }: LinesProps) => (
    <>
        {
            lines.map((l, i) => <Fragment key={`line-${i}`}>{l}{i < lines.length - 1 && <br />}</Fragment>)
        }
    </>
);

export default LineBreaks;
