import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { distances, colors } from '../../../styles/constants';
import { Dl, Dt, Dd } from '../../../components/DescriptionList/Vertical';
import { H3 } from '../../../components/Typography';
import { Account } from '../../../types/management-auth';
import { OrganizationNumber } from '../../../components/Formatters';
import Address from '../../../components/Address';
import Card from '../../../components/Card';
import CardArea from './CardArea';
import Hr from '../../../components/Hr';

export interface CompanyInformationProps extends WithTranslation {
    account: Account;
}

const CompanyInformation = (
    {
        t,
        account,
    }: CompanyInformationProps
) => {
    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('partner.merchant.company_information')}</H3>
            <Hr />
            <CardArea>
                {account.company.business_name && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.business_name')}</Dt>
                        <Dd>{account.company.business_name}</Dd>
                    </Dl>
                )}
                {account.company.industry && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.industry')}</Dt>
                        <Dd>{account.company.industry}</Dd>
                    </Dl>
                )}
                {account.company.organization_number && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.organization_number')}</Dt>
                        <Dd>
                            <OrganizationNumber
                                country={account.company.address.country}
                                value={account.company.organization_number}
                            />
                        </Dd>
                    </Dl>
                )}
                {account.company.website && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.website')}</Dt>
                        <Dd>
                            <StyledLink href={account.company.website}>{account.company.website}</StyledLink>
                        </Dd>
                    </Dl>
                )}

                {account.company.address && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.address')}</Dt>
                        <Dd>
                            <Address {...account.company.address} />
                        </Dd>
                    </Dl>
                )}
                {account.company.email && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.email')}</Dt>
                        <Dd>{account.company.email}</Dd>
                    </Dl>
                )}
                {account.company.technical_email && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.technical_email')}</Dt>
                        <Dd>{account.company.technical_email}</Dd>
                    </Dl>
                )}
                {account.company.branding?.logo_url && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.logo_url')}</Dt>
                        <Dd>{account.company.branding.logo_url}</Dd>
                    </Dl>
                )}
                {account.company.branding?.icon_url && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.icon_url')}</Dt>
                        <Dd>{account.company.branding.icon_url}</Dd>
                    </Dl>
                )}
            </CardArea>
        </Card>
    );
};

export default withTranslation()(CompanyInformation);

const StyledLink = styled.a`
    color: ${colors.primary};
    text-decoration: none;

    &:hover {
        color: ${colors.primaryHover};
    }
`;
