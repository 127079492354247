import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';

import { distances } from '../../styles/constants';

export const MfaSettingLabel = ({
    children,
}: {
    children?: ReactNode;
}) => {
    return (
        <LabelWrap>
            {children}
        </LabelWrap>
    );
};

export const MfaSettingContent = ({
    isExpanded,
    children,
}: {
    isExpanded: boolean;
    children?: ReactNode;
}) => {
    return (
        <div>{isExpanded && <Expanded>{children}</Expanded>}</div>
    );
};

export const MfaSettingExpandCollapse = ({
    children,
}: {
    children?: ReactNode;
}) => {
    return (
        <WrapExpandCollapse>
            {children}
        </WrapExpandCollapse>
    );
};

const WrapExpandCollapse = styled.div`
    margin-top: ${distances.tiny};
    display: flex;
    flex-direction: column;
`;

const LabelWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 0 3px;
`;

const Expanded = styled.div`
    margin: 0;
    padding: ${distances.normal};
`;
