import { Fragment, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';

import { Button } from '../../../../components/Buttons';
import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { Back, Title } from '../../../../components/Page';
import { H2, H3 } from '../../../../components/Typography';
import { distances } from '../../../../styles/constants';
import { State } from '../reducer';
import { buildDataForGraph, buildDestinationsArray } from '../utils';
import DestinationsList from './DestinationsList';
import DetailedData from './DetailedData';
import PayoutRuleGraph from './PayoutRuleGraph';

interface PayoutRuleDetailsProps {
    payoutRuleDetailsState: State;
    fetchPayoutRule: (accountId: string, payoutRuleId: string) => void;
    deletePayoutRule: (accountId: string, payoutRuleId: string) => void;
    clearFetchedData: () => void;
}

const PayoutRuleDetails = (
    {
        payoutRuleDetailsState,
        fetchPayoutRule,
        deletePayoutRule,
        clearFetchedData,
    }: PayoutRuleDetailsProps
) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { accountId, payoutRuleId } = useParams();

    useEffect(() => {
        if (accountId && payoutRuleId) {
            fetchPayoutRule(accountId, payoutRuleId);
        }
        return () => clearFetchedData();
    }, [accountId, payoutRuleId, fetchPayoutRule, clearFetchedData]);

    const goToEditPage = () => {
        clearFetchedData();
        history.push(`/${accountId}/settings/payout-rules/${payoutRuleId}/edit`);
    };

    const { payoutRule } = payoutRuleDetailsState;

    if (!payoutRule) {
        return <LoadingOverlay />;
    }

    const destinationsArray = buildDestinationsArray(payoutRule.destinations, null);
    const { graphValues, exampleValue } = buildDataForGraph(destinationsArray);

    const destinationsWithoutParent = destinationsArray.filter(destination => !destination.parentId);

    return (
        <Page title={
            <Fragment>
                <Back />
                <Title>{t('payout_rules.payout_rules_details.title')}</Title>
            </Fragment>
        }>
            <Card title={''} marginBottom={distances.small}>
                <H2>
                    {t('payout_rules.payout_rules_details.fields.rule_id_with_text', {
                        ruleId: `${payoutRule.rule_id} ${payoutRule.rule_type}`,
                    })}
                </H2>
            </Card>
            <CardsWrapper>
                <Card title={t('payout_rules.payout_rules_details.fields.details')}>
                    <StyledH3>{t('payout_rules.payout_rules_details.fields.steps')}</StyledH3>
                    <PayoutRuleGraph exampleValue={exampleValue} graphValues={graphValues} />
                    {destinationsWithoutParent.map((destination, index) => (
                        <DestinationsList
                            key={index}
                            destination={destination}
                            destinationsArray={destinationsArray}
                            index={index}
                        />
                    ))}
                    <ButtonsWrapper>
                        <Button
                            className="alt"
                            onClick={() => {
                                if (accountId && payoutRuleId) {
                                    deletePayoutRule(accountId, payoutRuleId);
                                    history.push(`/${accountId}/settings/payout-rules`);
                                }
                            }}
                        >
                            {t('payout_rules.payout_rules_details.fields.delete')}
                        </Button>
                        <Button onClick={goToEditPage}>{t('payout_rules.payout_rules_details.fields.edit')}</Button>
                    </ButtonsWrapper>
                </Card>
                <DetailedData payoutRule={payoutRule} />
            </CardsWrapper>
        </Page>
    );
};

const CardsWrapper = styled.div`
    display: flex;

    > *:first-child {
        margin-right: ${distances.small};
    }
`;

const ButtonsWrapper = styled.div`
    margin-top: ${distances.normal};

    display: flex;
    justify-content: flex-end;
    gap: ${distances.small};

    > button {
        margin-left: ${distances.small};
        &:last-child {
            margin-left: 0;
        }
    }
`;

const StyledH3 = styled(H3 as any)`
    margin: ${distances.small} 0;
`;

export default PayoutRuleDetails;
