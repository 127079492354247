import { v4 as uuidv4 } from 'uuid';

import { BillingPayoutRuleDestination } from '../../../types/billing/generated';
import { transaction_states } from '../../../styles/constants';

export interface DestinationsElement {
    id: string;
    type: 'percentage' | 'flat_amount' | 'remaining_amount';
    value: string;
    destination: string | null;
    parentId: string | null;
    color: string;
}

interface GraphValueWithColor {
    value: number;
    color: string;
    index: string;
    type: string;
    parentId: string | null;
    destination: string | null;
}

export const buildDestinationsArray = (destinations: BillingPayoutRuleDestination[], parentId: string | null) => {
    const array: DestinationsElement[] = [];
    const flattenDestinations = (destinations: BillingPayoutRuleDestination[], parentId: string | null) => {
        destinations.forEach((destination, i) => {
            const id = uuidv4();
            array.push({
                id,
                type: destination.type,
                value: destination.value ?? '',
                destination: destination.destination ?? null,
                parentId,
                color: getColor(),
            });
            if (destination.destinations) {
                flattenDestinations(destination.destinations as BillingPayoutRuleDestination[], id);
            }
        });
    };
    resetColorCount();
    flattenDestinations(destinations, parentId);
    return array;
};

type Partition = {[id: string]: number};

export const buildDataForGraph = (destinationsArray: DestinationsElement[]) => {
    const sumValue = destinationsArray.reduce((sum, destination) => {
        const parentDestination = destination.parentId
            ? destinationsArray.find((parentDestination) => parentDestination.id === destination.parentId)
            : null;
        if (destination.value && destination.type === 'flat_amount' && parentDestination?.type !== 'flat_amount') {
            sum = sum + Number(destination.value);
        }
        return sum;
    }, 0);

    const sumValueMagnitude = sumValue.toString().length;
    const amountToAdd = Math.pow(10, sumValueMagnitude - 1);
    const amountToRound = sumValue + amountToAdd;
    let exampleValue = (Math.ceil(amountToRound / amountToAdd) * amountToAdd) / 100;
    if (exampleValue < 1) exampleValue = 1;
    let maxParentValue = 0;

    const getMaxParentValue = (partitions: Partition, destination: DestinationsElement) => {
        const parentDestination = destination.parentId
            ? destinationsArray.find((parentDestination) => parentDestination.id === destination.parentId)
            : null;
        if (parentDestination) {
            switch (parentDestination.type) {
                case 'percentage':

                    maxParentValue = (getMaxParentValue(partitions, parentDestination) * Number(parentDestination.value)) / 100;
                    break;

                case 'flat_amount':

                    maxParentValue = Number(parentDestination.value) / 100;
                    break;

                case 'remaining_amount':
                    const calculatedForParentId1 = partitions[parentDestination?.parentId || 'root'];
                    maxParentValue = Number(exampleValue - calculatedForParentId1 || 0);
                    break;
            }
        } else {
            maxParentValue = Number(exampleValue);
        }
        return maxParentValue;
    };

    const getRemainingAmount = (partitions: Partition, destination: DestinationsElement): number => {
        /**
         * If there is a parent,  we want the remaining of all the other partitions.
         * If not, we only want the remaining of all the other sub-values.
         */
        const maxValue = destination.parentId ? getMaxParentValue(partitions, destination) : exampleValue;
        const previousValue = destinationsArray.reduce((sum, previousDestination) => {
            if (destination.parentId === previousDestination.parentId) {
                if (previousDestination.type === 'percentage') {
                    sum = sum + Number(previousDestination.value) * maxValue;
                } else {
                    sum = sum + Number(previousDestination.value);
                }
            }
            return sum;
        }, 0);
        const remainingAmount = (maxValue * 100) - previousValue;
        if (remainingAmount <= 0) {
            exampleValue = exampleValue * 2;
            /** Update the value of the partitions as well */
            Object.keys(partitions).forEach((key) => {
                partitions[key] = partitions[key] * 2;
            });
            return getRemainingAmount(partitions, destination);
        } else {
            return remainingAmount;
        }
    };

    const partitions = destinationsArray.reduce((a, destination) => {
        let value = 0;
        switch (destination.type) {
            case 'flat_amount':
                value = Number(destination.value);
                break;
            case 'percentage':
                value = getMaxParentValue(a, destination) * Number(destination.value);
                break;
            case 'remaining_amount':
                value = getRemainingAmount(a, destination);
                break;
        }
        if (destination.destination) {
            const parentId = destination.parentId || 'root';
            a[parentId] = (a[parentId] || 0) + value / 100;
        }
        return a;
    }, {} as Partition);
    const graphValuesWithColors = destinationsArray.reduce((array, destination) => {
        let value = 0;
        switch (destination.type) {
            case 'flat_amount':
                value = Number(destination.value);
                break;
            case 'percentage':
                value = getMaxParentValue(partitions, destination) * Number(destination.value);
                break;
            case 'remaining_amount':
                value = getRemainingAmount(partitions, destination);
                break;
        }
        if (destination.destination) {
            array.push({
                value: value / 100,
                color: destination.color,
                index: destination.id,
                type: destination.type,
                parentId: destination.parentId,
                destination: destination.destination,
            });

            maxParentValue = 0;
        }
        return array;
    }, [] as GraphValueWithColor[]);

    const graphValues = graphValuesWithColors.map((element) => ({
        value: element.value,
        valueNormalized: 0,
        color: element.color,
        index: element.index,
        parentId: element.parentId,
        type: element.type,
    }));

    const graphValuesSum = graphValues.reduce((sum, element) => sum + element.value, 0);

    graphValues.forEach(x => x.valueNormalized = (x.value / graphValuesSum) * 100);

    if (exampleValue > 10000000000) {
        return postFixInfiniteBug(graphValues, destinationsArray, partitions);
    }

    return { graphValues, exampleValue };
};

const postFixInfiniteBug = (graphValues: any[], destinationsArray: DestinationsElement[], partitions: Partition) => {
    const newExampleValue = 10000;

    /* quick correction, looks silly but appears to solve the mismatch issue */
    const newGraphValues = graphValues.map(x => {
        const a = destinationsArray.find(y => y.id === x.index);
        if (a) {
            switch (a.type) {
                case 'percentage': {
                    if (x.parentId && x.parentId in partitions) {
                        const calculatedPercentage = partitions[x.parentId] * (Number(a.value) / 100);
                        /* super specific fix for a 100% split of a split of the total */
                        x.value = Number.isNaN(calculatedPercentage) ? newExampleValue * (Number(partitions['root'] * 100) / 100) : calculatedPercentage;
                    } else {
                        const newValue = newExampleValue * (Number(a.value) / 100);
                        x.value = newValue;
                    }
                    break;
                }
                case 'remaining_amount': {
                    /* special case for remaining_amount with parent destination */
                    const newValue = graphValues.reduce((remaining, curr) => {
                        if (curr.type === 'flat_amount' && x.parentId && curr.parentId === x.parentId) {
                            remaining = remaining - curr.value;
                        }
                        return remaining;
                    }, x.value);
                    x.value = Number.isNaN(newValue) ? 0 : newValue;
                    break;
                }
                default:
                    break;
            }
        }
        return x;
    });

    const graphValuesSum = newGraphValues.reduce((sum, element) => sum + element.value, 0);

    newGraphValues.forEach(x => x.valueNormalized = (x.value / graphValuesSum) * 100);

    return { graphValues: newGraphValues, exampleValue: newExampleValue };
};

const predefinedColors = [
    transaction_states.AUTHORIZED,
    transaction_states.FAILED,
    transaction_states.REFUNDED,
    transaction_states.CAPTURED,
    transaction_states.PARTIALLY_REFUNDED,
    transaction_states.INITIATED,
];

let colorIdx = 0;
const resetColorCount = () => {
    colorIdx = 0;
};

const getColor = () => {
    const predefined = predefinedColors[colorIdx];
    colorIdx++;
    return (
        predefined ||
        `#${Math.floor(Math.random() * 2 ** 24)
            .toString(16)
            .padStart(6, '0')}`
    );
};
