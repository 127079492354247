import React, { useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { distances, grid } from '../../../../styles/constants';
import { Dl, Dt, Dd } from '../../../../components/DescriptionList/Horizontal';
import { H2, P, Small } from '../../../../components/Typography';
import { DateTime } from '../../../../components/Formatters';
import { ProfileConfiguration } from '../../../../types/checkout';
import { EnvironmentBadge } from '../../../../components/EnvironmentBadge';
import { getPaymentProfilePaymentMethods } from '../../../helpers';
import { CHECKOUT_API_HOSTNAME } from '../../../../env';
import { getProfilePaymentTypeLabel } from '../../../../helpers/getProfilePaymentTypeLabel';
import StyledLink from '../../../../components/StyledLink';
import Hr from '../../../../components/Hr';
import { smsOrPush, toAllowDifferentBillingShippingAddress } from '../../helpers';

const hasSmsCheckoutLink = (profile: ProfileConfiguration) => {
    if (profile.configuration.publish && profile.configuration.publish.length > 0) {
        return profile.configuration.publish.find((elem) => elem.channel === 'sms' && elem.type === 'checkout-link');
    }
    return false;
};

interface MatchParams {
    accountId: string;
    profileId: string;
}
export interface DetailsInfoProps extends WithTranslation, RouteComponentProps<MatchParams> {
    profile: ProfileConfiguration;
}

const DetailsInfo = (
    {
        t,
        profile,
    }: DetailsInfoProps
) => {
    const paymentTypes = getPaymentProfilePaymentMethods(profile);
    const publish_sms_checkout_link = hasSmsCheckoutLink(profile) ? 'enabled' : 'disabled';
    const auto_capture = profile.configuration.auto_capture ? 'enabled' : 'disabled';
    const brandingUrl = `${CHECKOUT_API_HOSTNAME}/v1/branding/profiles/${profile.id}/variant/colors/width/420/dintero_top_frame.svg`;
    const [hasImage, setHasImage] = useState(true);
    return (
        <Wrapper>
            <InfoWrapper>
                <H2>{t('settings.payment_profile.profile_info')}</H2>
                <Hr />
                <Dl>
                    <Dt doubleColumn>{t('settings.payment_profile.fields.id')}</Dt>
                    <Dd>
                        <code>{profile.profile_id}</code>
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.payment_profile.fields.description')}</Dt>
                    <Dd>{profile.description}</Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.payment_profile.fields.environment')}</Dt>
                    <Dd>
                        <EnvironmentBadge environment={profile.id.startsWith('P') ? 'production' : 'test'} />
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.payment_profile.fields.updated_at')}</Dt>
                    <Dd>
                        <DateTime isoString={profile.updated_at || ''} boldTime />
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.payment_profile.fields.created_at')}</Dt>
                    <Dd>
                        <DateTime isoString={profile.created_at || ''} boldTime />
                    </Dd>
                </Dl>
                {profile.url && profile.url.merchant_terms_url && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.payment_profile.fields.merchant_terms_url')}</Dt>
                        <Dd>{profile.url.merchant_terms_url}</Dd>
                    </Dl>
                )}
            </InfoWrapper>
            <InfoWrapper>
                <H2>{t('settings.payment_profile.payment_methods')}</H2>
                <Hr />
                {profile.configuration.active_payment_types?.enabled && (
                    <>
                        <P>{t('settings.payment_profile.active_payment_types_enabled_empty')}</P>
                        <br />
                    </>
                )}
                {profile.configuration.default_payment_type && (
                    <>
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_profile.fields.default_payment_type')}</Dt>
                            <Dd>{getProfilePaymentTypeLabel(profile.configuration.default_payment_type, t)}</Dd>
                        </Dl>
                        <Spacer />
                    </>
                )}
                <Ul>
                    {paymentTypes.map((payment_type) => (
                        <li key={payment_type.type}>
                            {getProfilePaymentTypeLabel(payment_type.type, t)}
                            {payment_type.dynamic_descriptor && (
                                <>
                                    {' '}
                                    <em>{payment_type.dynamic_descriptor}</em>
                                </>
                            )}
                        </li>
                    ))}
                </Ul>
            </InfoWrapper>
            <InfoWrapper>
                <H2>{t('settings.payment_profile.payment_settings_title')}</H2>
                <Hr />
                <Dl>
                    <Dt doubleColumn>{t('settings.payment_profile.fields.auto_capture')}</Dt>
                    <Dd>{t(`settings.payment_profile.values.auto_capture.${auto_capture}`)}</Dd>
                </Dl>
            </InfoWrapper>
            <InfoWrapper>
                <H2>{t('settings.payment_profile.express_settings_title')}</H2>
                <Hr />
                <Dl>
                    <Dt doubleColumn>{t('settings.payment_profile.fields.default_customer_type')}</Dt>
                    <Dd>{t('settings.payment_profile.values.default_customer_type', { context: profile.configuration.default_customer_type })}</Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.payment_profile.fields.allow_different_billing_shipping_address')}</Dt>
                    <Dd>{t('settings.payment_profile.values.allow_different_billing_shipping_address', { context: toAllowDifferentBillingShippingAddress(profile.configuration.allow_different_billing_shipping_address || []) })}</Dd>
                </Dl>
                {profile.configuration.countries?.preferred_country && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.payment_profile.fields.country_preferred')}</Dt>
                        <Dd>
                            {t(`settings.country_options.${profile.configuration.countries?.preferred_country}`)}
                        </Dd>
                    </Dl>
                )}
                {profile.configuration.countries?.allow_countries && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.payment_profile.fields.countries_allowed')}</Dt>
                        <Dd>
                            <Ul>
                                {(profile.configuration.countries?.allow_countries || []).map((country) => (
                                    <li key={country}>{t(`settings.country_options.${country}`)}</li>
                                ))}
                            </Ul>
                        </Dd>
                    </Dl>
                )}
                {profile.configuration.countries?.deny_countries && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.payment_profile.fields.countries_denied')}</Dt>
                        <Dd>
                            <Ul>
                                {(profile.configuration.countries?.deny_countries || []).map((country) => (
                                    <li key={country}>{t(`settings.country_options.${country}`)}</li>
                                ))}
                            </Ul>
                        </Dd>
                    </Dl>
                )}
            </InfoWrapper>

            <InfoWrapper>
                <H2>{t('settings.payment_profile.publish_title')}</H2>
                <Hr />
                <Dl>
                    <Dt doubleColumn>
                        {t(
                            `settings.payment_profile.fields.publish_${smsOrPush(
                                paymentTypes.map((p) => p.type),
                                undefined
                            )}_checkout_link`
                        )}
                    </Dt>
                    <Dd>
                        {t(`settings.payment_profile.values.publish_sms_checkout_link.${publish_sms_checkout_link}`)}
                    </Dd>
                </Dl>
            </InfoWrapper>
            <InfoWrapper>
                <H2>{t('settings.payment_profile.payment_branding')}</H2>
                <Hr />
                <P>
                    {t('settings.payment_profile.payment_branding_about')}{' '}
                    <StyledLink
                        href="https://docs.dintero.com/docs/checkout/checkout-branding/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {t('settings.payment_profile.payment_branding_link')}{' '}
                    </StyledLink>
                </P>
                <Code>{brandingUrl}</Code>
                {hasImage && (
                    <Branding>
                        <img
                            alt=""
                            src={`${brandingUrl}?q=${paymentTypes.map((p) => p.type).join('_')}`}
                            onError={() => setHasImage(false)}
                        />
                    </Branding>
                )}
                {!hasImage && (
                    <MissingBranding>{t('settings.payment_profile.payment_branding_missing')}</MissingBranding>
                )}
                <CenteredSmall>{t('settings.payment_profile.payment_branding_about_small')}</CenteredSmall>
            </InfoWrapper>
        </Wrapper>
    );
};

export default withRouter(withTranslation()(DetailsInfo));

const Wrapper = styled.div`
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    padding: ${distances.normal} ${distances.small};
`;

const InfoWrapper = styled.div`
    padding-top: ${distances.normal};
    margin-right: ${grid.gutter};
`;

const Spacer = styled.div`
    margin-top: ${distances.normal};
`;



const Ul = styled.ul`
    margin: 0;
    padding-left: 17px;
    list-style-type: circle;
`;

const Code = styled.code`
    margin-top: 32px;
    word-break: break-all;
    white-space: normal;
    display: block;
    background: #f0f0f0;
    padding: 1.25rem 1.5rem;
`;

const Branding = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
`;

const MissingBranding = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;
    margin-bottom: 32px;
    padding: 32px;
    border: 1px dotted #cecece;
`;

const CenteredSmall = styled(Small)`
    display: block;
    text-align: center;
`;
