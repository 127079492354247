import cc from 'currency-codes';

// European countries
const countryCurrencyMapping = {
    'AD': 'EUR',
    'AL': 'ALL',
    'AT': 'EUR',
    'BA': 'BAM',
    'BE': 'EUR',
    'BG': 'BGN',
    'BY': 'BYN',
    'CH': 'CHF',
    'CY': 'EUR',
    'CZ': 'CZK',
    'DE': 'EUR',
    'DK': 'DKK',
    'EE': 'EUR',
    'ES': 'EUR',
    'FI': 'EUR',
    'FO': 'DKK',
    'FR': 'EUR',
    'GB': 'GBP',
    'GG': 'GBP',
    'GI': 'GIP',
    'GR': 'EUR',
    'HR': 'HRK',
    'HU': 'HUF',
    'IE': 'EUR',
    'IM': 'GBP',
    'IS': 'ISK',
    'IT': 'EUR',
    'JE': 'GBP',
    'XK': 'EUR',
    'LI': 'CHF',
    'LT': 'EUR',
    'LU': 'EUR',
    'LV': 'EUR',
    'MC': 'EUR',
    'MD': 'MDL',
    'ME': 'EUR',
    'MK': 'MKD',
    'MT': 'EUR',
    'NL': 'EUR',
    'NO': 'NOK',
    'PL': 'PLN',
    'PT': 'EUR',
    'RO': 'RON',
    'RS': 'RSD',
    'RU': 'RUB',
    'SE': 'SEK',
    'SI': 'EUR',
    'SJ': 'NOK',
    'SK': 'EUR',
    'SM': 'EUR',
    'UA': 'UAH',
    'VA': 'EUR',
};

export const getCountryCurrency = (country: string): string | undefined => {
    const result = countryCurrencyMapping[country as keyof typeof countryCurrencyMapping];
    return result;
};
