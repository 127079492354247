import { effects as payoutRules } from './payoutRules';
import { effects as newPayoutRule } from './newPayoutRule';
import { effects as payoutRuleEdit } from './payoutRuleEdit';
import { effects as payoutRuleDetails } from './payoutRuleDetails';

const effects = {
    ...payoutRules,
    ...newPayoutRule,
    ...payoutRuleEdit,
    ...payoutRuleDetails,
};

export default effects;
