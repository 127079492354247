import React from 'react';

interface TimelineProps {
    strokewidth?: string | number;
    width?: string;
    height?: string;
    fill?: string;
}

const Timeline = (
    {
        strokewidth = 1,
        width = '100%',
        height = '100%',
        fill = '#0000ff',
    }: TimelineProps
) => {
    return (
        <svg height={height} width={width} viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
            <g
                fill={fill}
                stroke={fill}
                strokeLinecap="square"
                strokeLinejoin="miter"
                strokeMiterlimit="10"
                strokeWidth={strokewidth}
            >
                <line fill="none" x1="32" x2="32" y1="2" y2="62" />
                <line fill="none" x1="38" x2="32" y1="14" y2="14" />
                <line fill="none" x1="38" x2="32" y1="50" y2="50" />
                <line fill="none" x1="26" x2="32" y1="32" y2="32" />
                <rect height="16" width="16" fill="none" stroke={fill} x="44" y="6" />
                <rect height="16" width="16" fill="none" stroke={fill} x="44" y="42" />
                <rect
                    height="16"
                    width="16"
                    fill="none"
                    stroke={fill}
                    transform="translate(24 64) rotate(-180)"
                    x="4"
                    y="24"
                />
            </g>
        </svg>
    );
};

export default Timeline;
