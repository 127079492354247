import { setItem, getItem, removeItem } from '../helpers/localStorage';
import { CognitoSession, AuthenticatedUser } from './types';

export const setCognitoSession = (data: CognitoSession) => {
    setItem('cognito_access_token', data.cognito_access_token);
    setItem('cognito_expires', data.cognito_expires);
};

export const setPartner = (data: AuthenticatedUser, accountId: string) => {
    setItem('partner', JSON.stringify({ ...data, accountId }));
};

export const getPartner = (): [AuthenticatedUser | undefined, string | undefined] => {
    const partner = getItem('partner') || '{}';
    const { accountId, ...data } = JSON.parse(partner);
    return [data as AuthenticatedUser | undefined, accountId as string | undefined];
};

export const clearPartner = () => {
    removeItem('partner');
};

export const clearCognitoSession = () => {
    const cognitoSession = getCognitoSession();
    removeItem('cognito_access_token');
    removeItem('cognito_expires');
    removeItem('partner');
    return cognitoSession;
};

export const getCognitoSession = (): CognitoSession | undefined => {
    const cognito_access_token = getItem('cognito_access_token') || undefined;
    const cognito_expires = getItem('cognito_expires') || undefined;
    if (cognito_access_token && cognito_expires) {
        return {
            cognito_access_token,
            cognito_expires,
        };
    }
};
