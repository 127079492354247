import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { distances } from '../../../../styles/constants';
import { Button } from '../../../../components/Buttons';

import { FilterSelect } from '../../../../components/Filters';
import CountryFilter from './filters/CountryFilter';
import PhoneNumberFilter from './filters/PhoneNumberFilter';
import EmailFilter from './filters/EmailFilter';
import OrganizationNumberFilter from './filters/OrganizationNumberFilter';
import StatusFilter from './filters/StatusFilter';
import TypeFilter from './filters/TypeFilter';
import { useUrlSearchState } from '../../../../util/useUrlSearchState';
import { SalesLocationQuery } from '../../locationActions';
import useEffectCallback from '../../../../util/useEffectCallback';
import { FilterComponentWrapper, FilterRowWrapper, FiltersWrapper } from '../../../../components/Filters/elements';

type SalesLocationFilter = Omit<SalesLocationQuery, 'limit' | 'starting_after' | 'search'>;

const filterComponents = {
    country: CountryFilter,
    phone_number: PhoneNumberFilter,
    organization_number: OrganizationNumberFilter,
    email: EmailFilter,
    status: StatusFilter,
    type: TypeFilter,
};

type FilterKey = keyof typeof filterComponents;
const filterKeys = Object.keys(filterComponents);

const extraFilterProps: Partial<Record<FilterKey, any>> = {
    country: {
        countries: ['NO', 'SE'],
    },
};

const filterVisible = (searchKeys: string[]) => filterKeys
    .filter(filterKey => searchKeys.includes(filterKey)) as FilterKey[];

export type FiltersProps = {
    onChange: (value: SalesLocationFilter) => void;
}

export const Filters = ({
    onChange,
}: FiltersProps) => {
    const { t } = useTranslation('app');
    const [urlSearch, setUrlSearch] = useUrlSearchState<SalesLocationFilter>();
    const [pending, setPending] = useState<FilterKey>();
    const visibleFilterKeys = useMemo(() => filterVisible([...Object.keys(urlSearch), pending ?? '']), [urlSearch, pending]);
    const addableFilterKeys = useMemo(() => Object.keys(filterComponents).filter(key => !visibleFilterKeys.includes(key as any)) as FilterKey[], [visibleFilterKeys]);
    const queryParams = useMemo(() => new URLSearchParams(urlSearch), [urlSearch]);

    const onChangeFilter = useCallback((changes: Record<string, unknown>) => {
        setUrlSearch(prev => {
            const next = {
                ...prev,
                ...changes,
            };
            return next;
        });
        setPending(undefined);
    }, [setUrlSearch]);

    useEffectCallback(onChange, [urlSearch]);

    return (
        <FilterRowWrapper uniformPadding>
            <FiltersWrapper>
                {visibleFilterKeys
                    .map((key) => {
                        const FilterComponent = filterComponents[key];
                        const componentExtraFilterProps = extraFilterProps[key];
                        return (
                            <FilterComponentWrapper key={`location-filter-${key}`}>
                                <FilterComponent
                                    {...(componentExtraFilterProps)}
                                    queryParams={queryParams}
                                    onChange={onChangeFilter}
                                />
                            </FilterComponentWrapper>
                        );
                    })}
                {!pending && addableFilterKeys.length > 0 && (
                    <FilterSelect
                        name="filterSelect"
                        options={addableFilterKeys.map((key) => (
                            <Button
                                className="alt tiny"
                                onClick={() => setPending(key)}
                                key={`add-filter-${key}`}
                            >
                                {t(`locations.filter.${key}.add_filter_button`)}
                            </Button>
                        ))}
                    />
                )}
            </FiltersWrapper>
        </FilterRowWrapper>
    );
};

export default Filters;
