import styled from 'styled-components/macro';
import { AccordionItemProvider, useAccordionContext } from './Context';
import { palette } from '../../styles/constants';

type AccordionItemProps = {
    value: string;
    children: React.ReactNode;
    unstyled?: boolean;
};

export default function AccordionItem({ value, children, unstyled }: AccordionItemProps) {
    const ctx = useAccordionContext();
    const isActive = ctx.isItemActive(value);
    return (
        <AccordionItemProvider value={{ value }}>
            <Item data-active={isActive} data-unstyled={unstyled}>
                {children}
            </Item>
        </AccordionItemProvider>
    );
}

const Item = styled.div`
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    transition: all 0.2s cubic-bezier(0.39, 0.575, 0.565, 1);
    border-bottom: 1px solid ${palette.neutral[200]};
    &[data-unstyled='true'] {
        border: unset;
    }
    &[data-active='true'] {
        overflow: visible;
    }
`;
