import { connect } from 'react-redux';

import { fetchPayoutRule, editPayoutRule, resetForm, fetchLocations } from '../actions';
import { State } from '../../../../reducer';
import { payoutRuleEditSelector } from '../selectors';
import PayoutRuleEdit from '../components/PayoutRuleEdit';

const mapStateToProps = (state: State) => ({
    payoutRuleEditState: payoutRuleEditSelector(state),
});

const mapDispatchToProps = {
    fetchPayoutRule,
    editPayoutRule,
    resetForm,
    fetchLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutRuleEdit);
