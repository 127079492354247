import { TFunction } from 'i18next';

import { LocationFormValues } from '../../types';
import {
    validate,
    validateRequired,
    validatePhoneNumber,
    getOrgNumberValidator,
    getPostalCodeValidator
} from '../../../../helpers/validation';

export const validateValues = (values: LocationFormValues, t: TFunction) => {
    let errors: { [key: string]: string } = {};
    const manipulatedValues = {
        ...values,
        phoneNumber: values.phoneNumber ? `${values.countryPrefix} ${values.phoneNumber}` : '',
    };

    const validators: { path: string; validator: (value: any) => string | undefined }[] = [
        {
            path: 'organizationNumber',
            validator: validateRequired(
                t('locations.location_form.validation.required', {
                    fieldName: t('locations.location_form.fields.org_nr'),
                })
            ),
        },
        {
            path: 'organizationNumber',
            validator: getOrgNumberValidator(
                values.country.value,
                t('locations.location_form.validation.invalid', {
                    fieldName: t('locations.location_form.fields.org_nr'),
                })
            ),
        },
        {
            path: 'businessName',
            validator: validateRequired(
                t('locations.location_form.validation.required', {
                    fieldName: t('locations.location_form.fields.legal_name'),
                })
            ),
        },
        {
            path: 'name',
            validator: validateRequired(
                t('locations.location_form.validation.required', {
                    fieldName: t('locations.location_form.fields.name'),
                })
            ),
        },
        {
            path: 'addressLine',
            validator: validateRequired(
                t('locations.location_form.validation.required', {
                    fieldName: t('locations.location_form.fields.address_line'),
                })
            ),
        },
        {
            path: 'postalCode',
            validator: validateRequired(
                t('locations.location_form.validation.required', {
                    fieldName: t('locations.location_form.fields.postal_code'),
                })
            ),
        },
        {
            path: 'postalCode',
            validator: getPostalCodeValidator(
                values.country.value,
                t('locations.location_form.validation.invalid', {
                    fieldName: t('locations.location_form.fields.postal_code'),
                })
            ),
        },
        {
            path: 'postalPlace',
            validator: validateRequired(
                t('locations.location_form.validation.required', {
                    fieldName: t('locations.location_form.fields.postal_place'),
                })
            ),
        },
    ];

    if (values.phoneNumber.length > 0) {
        validators.push({
            path: 'phoneNumber',
            validator: validatePhoneNumber(
                t('locations.location_form.validation.invalid', {
                    fieldName: t('locations.location_form.fields.phone_number'),
                })
            ),
        });
    }

    if (values.franchise) {
        validators.push({
            path: 'franchiseName',
            validator: validateRequired(
                t('locations.location_form.validation.required', {
                    fieldName: t('locations.location_form.fields.franchise_name'),
                })
            ),
        });
    }

    errors = validators.reduce((acc, elem) => {
        return validate(elem.path, elem.validator, manipulatedValues, acc);
    }, errors);

    return errors;
};
