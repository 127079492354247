import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useDispatch } from 'react-redux';
import { Button, ButtonGroup } from '../../../components/Buttons';
import Modal from '../../../components/Modal';
import { H2, P } from '../../../components/Typography';
import { colors, distances, grid } from '../../../styles/constants';
import errorExternalStore from '../../observables/errorExternalStore';
import { HttpError } from '../../types';
import AccessDeniedContent from './AccessDeniedContent';
interface HttpErrorBodyProps {
    title: string;
    description: string;
    error?: HttpError;
    dismissError: () => void;
    namespace: string;
}


const goBack = () => {
    window.history.back();
};

const GoBackButton = () => {
    const dispatch = useDispatch();

    return (
        <Button
            onClick={() => {
                goBack();
                const errorAction = errorExternalStore.dispatch('clearErrors');
                dispatch(errorAction);
            }}
        >
            <Trans i18nKey="errors.go_back_call_to_action" />
        </Button>
    );
};

const CloseErrorButton = ({ dismissError }: {dismissError: () => void}) => {
    return (
        <Button
            onClick={() => {
                dismissError();
            }}
            className="alt"
        >
            <Trans i18nKey="errors.close" />
        </Button>
    );
};

const emailBody = (error: HttpError | undefined): string => {
    return [
        `Error details`,
        `URL: ${error?.url}`,
        `Method: ${error?.method}`,
        `Request-ID: ${error?.['request-id']}`,
        `Status: ${error?.status} ${error?.statusText}`,
        `Message: ${error?.message}`,
        ``,
        `Description:`,
        ``,
    ].join('%0D%0A');
};

const SendEmailButton = (
    {
        error,
    }: {
        error?: HttpError;
    }
) => (<SendEmailButtonLink
    target="_top"
    href={`mailto:support@dintero.com?subject=${window.location.href}&body=${emailBody(error)}`}
    className="alt"
>
    <Trans i18nKey="errors.send_email_call_to_action" />
</SendEmailButtonLink>);

const formatErrorMessage = (errorMessage: string) => {
    try {
        return JSON.stringify(JSON.parse(errorMessage), null, 2);
    } catch (e) {
        console.log('e', e);
        return errorMessage;
    }
};

const ErrorTemplate = (
    {
        title,
        description,
        error,
        dismissError,
        namespace,
    }: HttpErrorBodyProps
) => {
    if (namespace === 'http_403_error') {
        return <Modal>
            <AccessDeniedContent error={<>
                {error?.url !== undefined && (
                    <StatusError>
                    URL
                        <Pre>
                            {error.method} {error.url}
                        </Pre>
                    </StatusError>
                )}
                {error?.status !== undefined && (
                    <StatusError>
                    Status
                        <Pre>
                            {error?.status} {error?.statusText}
                        </Pre>
                    </StatusError>
                )}
                {error?.['request-id'] !== undefined && (
                    <StatusError>
                    Request-id
                        <Pre>{error?.['request-id']}</Pre>
                    </StatusError>
                )}
                {error?.message !== undefined && (
                    <StatusError>
                    Message
                        <Pre title={error.message}>{formatErrorMessage(error.message)}</Pre>
                    </StatusError>
                )}
            </>} title={title} description={description}
            buttons={
                <>
                    <CloseErrorButton dismissError={dismissError} />
                    <GoBackButton />
                </>
            }
            />
        </Modal>;
    }
    return <Modal>
        <H2>{title}</H2>
        <ErrorWrapper>
            <P>{description}</P>

            {(error?.status !== undefined || error?.message !== undefined || error?.['request-id']) !== undefined && (
                <Spacer />
            )}
            {error?.url !== undefined && (
                <StatusError>
                    URL
                    <Pre>
                        {error.method} {error.url}
                    </Pre>
                </StatusError>
            )}
            {error?.status !== undefined && (
                <StatusError>
                    Status
                    <Pre>
                        {error?.status} {error?.statusText}
                    </Pre>
                </StatusError>
            )}
            {error?.['request-id'] !== undefined && (
                <StatusError>
                    Request-id
                    <Pre>{error?.['request-id']}</Pre>
                </StatusError>
            )}
            {error?.message !== undefined && (
                <StatusError>
                    Message
                    <Pre title={error.message}>{formatErrorMessage(error.message)}</Pre>
                </StatusError>
            )}

        </ErrorWrapper>
        <ButtonGroup>
            <SendEmailButton error={error} />
            <CloseErrorButton dismissError={dismissError} />
            <GoBackButton />
        </ButtonGroup>
    </Modal>;
};

const Pre = styled.pre`
    color: #bd044b;
    padding: 0;
    padding-top: 2px;
    margin: 0;
    white-space: pre-wrap;
`;
const Spacer = styled.div`
    height: ${distances.normal};
`;

const SendEmailButtonLink = styled.a`
    color: ${colors.primary};
    margin-right: auto;

    &:hover,
    &:focus {
        color: ${colors.primaryHover};
    }
`;

const StatusError = styled.div`
    padding: ${distances.tiny};
    background: ${colors.backgroundAlt};
    font-size: 14px;
    font-family: Consolas, 'Andale Mono WT', 'Andale Mono', 'Lucida Console', 'Lucida Sans Typewriter',
        'DejaVu Sans Mono', 'Bitstream Vera Sans Mono', 'Liberation Mono', 'Nimbus Mono L', Monaco, 'Courier New',
        Courier, monospace;
`;

const ErrorWrapper = styled.div`
    width: ${grid.spans.span4};
    padding-top: ${distances.normal};
    padding-bottom: ${distances.normal};
    font-size: 14px;
`;

interface TranslatedErrorProps extends WithTranslation {
    namespace: string;
    error?: HttpError;
    dismissError: () => void;
}

const ErrorModal = (
    {
        t,
        namespace,
        error,
        dismissError,
    }: TranslatedErrorProps
) => (<ErrorTemplate
    title={t(`errors.${namespace}.title`)}
    description={t(`errors.${namespace}.description`)}
    error={error}
    namespace={namespace}
    dismissError={dismissError}
/>);

export default withTranslation()(ErrorModal);
