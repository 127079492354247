import { useCallback, useReducer, Reducer } from 'react';
import _get from 'lodash/get';
import _set from 'lodash/fp/set';
import _merge from 'lodash/merge';
import _reduce from 'lodash/reduce';
import useEffectCallback from './useEffectCallback';

type SetValue = (path: string, value: any) => void

type Draft<T extends Record<string, unknown>> = Partial<T>

type UseDraftReturn<T extends Record<string, unknown>> = Readonly<{
    draft: Readonly<Draft<T>>;
    combined: Readonly<T>;
    dirty: boolean;
    original: T;
    setValue: SetValue;
    clear: () => void;
    isTouched: (path: string) => boolean;
    isNotPristine: (path: string) => boolean;
    setSubmit: () => void;
    setTouched: (path: string) => void;
}>

type DraftState<T extends Record<string, unknown>> = Readonly<{
    original: T;
    draft: Draft<T>;
    combined: T;
    dirty: boolean;
    submitted: boolean;
    touched: Partial<Record<keyof T, boolean | undefined>>;
}>

type SetValueAction = {
    type: 'set';
    key: string;
    value: any;
}

type ClearDraftAction<T> = {
    type: 'clear';
    original: T;
}

type SubmitAction = {
    type: 'submit';
}

type SetTouchedAction = {
    type: 'touched';
    key: string;
}


type DraftAction<T> = SetValueAction | ClearDraftAction<T> | SubmitAction | SetTouchedAction

function draftInit<T extends Record<string, unknown>>(original: T): DraftState<T> {
    return {
        original,
        draft: {},
        combined: { ...original },
        dirty: false,
        submitted: false,
        touched: {},
    };
}

export function draftReducer<T extends Record<string, unknown>>(state: DraftState<T>, action: DraftAction<T>): DraftState<T> {
    if (action.type === 'clear') {
        return draftInit(action.original);
    }
    if (action.type === 'submit') {
        return {
            ...state,
            submitted: true,
        };
    }
    if (action.type === 'touched') {
        return {
            ...state,
            touched: {
                ...state.touched,
                [action.key]: true,
            },
        };
    }
    const draft = _set(action.key, action.value)(state.draft);
    const combined = _merge(
        {},
        state.original,
        draft
    );
    // not deep, but should suffice
    const dirty = _reduce(draft, (prev, value, key) => prev || value !== state.original[key], false);

    return {
        ...state,
        draft,
        combined,
        dirty,
    };
}

const noop = () => {};

/**
 * Keep track of changes made to the original
 * @example
 * const {draft, combined, dirty, clear, setValue} = useDraft<PatchCustomerModel>(customer)
 *
 * const onChangeInput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
 *      const key = event.target.name as keyof CreateCustomerModel
 *      const value = event.target.value
 *      setValue(key, value)
 * }, [setValue])
 *
 * const onSaveChanges = useCallback(() => {
 *      if (!dirty) return
 *      if (await customerActions.patch(draft)) // returns true/false indicating the success of the action
 *          clear()
 * }, [draft, dirty, clear])
 *
 * return (<>
 *      // ... much stuff
 *      <input onChange={onChangeInput} name='firstName' value={combined.firstName} />
 *      <input onChange={onChangeInput} name='lastName' value={combined.lastName} />
 *      <input onChange={onChangeInput} name='phone' value={combined.phone} />
 *      // ... many more stuffs
 *      <button onClick={onSaveChanges}>Save changes</button>
 * </>)
 */
function useDraft<T extends Record<string, unknown> = {}>(original: T, onChange: (draft: Draft<T>, combined: T) => void = noop): UseDraftReturn<T> {
    const [state, dispatch] = useReducer<Reducer<DraftState<T>, DraftAction<T>>, T>(
        draftReducer,
        original,
        draftInit
    );

    const setValue: SetValue = useCallback((key, value) => dispatch({ type: 'set', key, value }), []);
    const clear = useCallback(() => dispatch({ type: 'clear', original }), [original]);
    const isTouched = useCallback((key: string) => _get(state.touched, key) === true, [state.touched]);
    const setSubmit = useCallback(() => dispatch({ type: 'submit' }), []);
    const isNotPristine = useCallback((key: string) => {
        const touched = _get(state.touched, key) === true ;
        const submitted = state.submitted;
        return touched || submitted;
    }, [state.touched, state.submitted]);
    const setTouched = useCallback((key: string) => dispatch({ type: 'touched', key }), []);

    useEffectCallback(onChange, [state.draft, state.combined]);

    return {
        draft: state.draft,
        combined: state.combined,
        dirty: state.dirty,
        original: state.original,
        setValue,
        clear,
        isTouched,
        setSubmit,
        isNotPristine,
        setTouched,
    } as const;
}

export default useDraft;
