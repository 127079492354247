import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';
import {
    ActionTypes,
    FetchHookResponse,
    GetHook,
    DeleteHook,
    FetchDeleteHookResponse,
    PingHook,
    FetchPingHookResponse,
    GetDeliveries,
    getDeliveries,
    FetchDeliveriesResponse,
    GetDeliveryDetails,
    FetchDeliveryDetailsResponse
} from './actions';
import { Subscription } from '../../../types/webhooks';

const fetchHook: SideEffectFunction<GetHook, FetchHookResponse> = async action => {
    const result: FetchHookResponse = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/hooks/subscriptions/${
            action.payload.hookId
        }`,
        handlers: {
            200: (hook: Subscription) => ({
                type: ActionTypes.FetchHookResponse,
                payload: {
                    hook: hook,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchDeleteHook: SideEffectFunction<DeleteHook, FetchDeleteHookResponse> = async action => {
    const result: FetchDeleteHookResponse = await fulfill.delete({
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/hooks/subscriptions/${
            action.payload.hookId
        }`,
        accountId: action.payload.accountId,
        handlers: {
            200: () => ({
                type: ActionTypes.FetchDeleteHookResponse,
                payload: {},
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchPingHook: SideEffectFunction<PingHook, FetchPingHookResponse> = async action => {
    const result: FetchPingHookResponse = await fulfill.post({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/hooks/subscriptions/${
            action.payload.hookId
        }/ping`,
        handlers: {
            204: () => ({
                type: ActionTypes.FetchPingHookResponse,
                payload: {},
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchDeliveries: SideEffectFunction<GetDeliveries, FetchDeliveriesResponse> = async action => {
    const result: FetchDeliveriesResponse = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/hooks/subscriptions/${
            action.payload.hookId
        }/deliveries`,
        handlers: {
            200: deliveries => ({
                type: ActionTypes.FetchDeliveriesResponse,
                payload: {
                    deliveries,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const refetchDeliveries: SideEffectFunction<FetchPingHookResponse, GetDeliveries> = async action =>
    new Promise<GetDeliveries>((resolve, reject) =>
        setTimeout(
            () => resolve(getDeliveries(action.meta.cause.payload.accountId, action.meta.cause.payload.hookId)),
            2000
        ));

type FetchDeliveryDetails = SideEffectFunction<GetDeliveryDetails, FetchDeliveryDetailsResponse>;

const fetchDeliveryDetails: FetchDeliveryDetails = async action => {
    const { accountId, hookId, deliveryId } = action.payload;
    const result: FetchDeliveryDetailsResponse = await fulfill.get({
        accountId: accountId,
        url:
            `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/hooks/subscriptions/${hookId}/deliveries/${deliveryId}`,
        handlers: {
            200: deliveryDetails => ({
                type: ActionTypes.FetchDeliveryDetailsResponse,
                payload: {
                    deliveryDetails,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.GetHook]: fetchHook,
    [ActionTypes.DeleteHook]: fetchDeleteHook,
    [ActionTypes.PingHook]: fetchPingHook,
    [ActionTypes.GetDeliveries]: fetchDeliveries,
    [ActionTypes.FetchPingHookResponse]: refetchDeliveries,
    [ActionTypes.GetDeliveryDetails]: fetchDeliveryDetails,
};

export default effects;
