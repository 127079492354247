import { TFunction } from 'i18next';

import {
    validate,
    validateRequired,
    getOrgNumberValidator,
    getPostalCodeValidator,
    validateISO_3166_1,
    validateEmail,
    validateUrl
} from '../../../../helpers/validation';
import { FormValues } from '../components/EditFullAccount';

export const validateValues = (values: FormValues, t: TFunction) => {
    let errors: { [key: string]: string } = {};
    if (!values || Object.keys(values).length === 0) {
        // this should not happen but...
        return { error: 'account not loaded yet...' };
    }

    const validationValues = { ...values };
    if (values.company.website === 'https://') {
        validationValues.company.website = '';
    }

    let validators = [
        {
            path: 'company.business_name',
            validator: validateRequired(t('settings.edit_account.invalid.company.business_name')),
        },
        {
            path: 'company.organization_number',
            validator: validateRequired(t('settings.edit_account.invalid.company.organization_number.required')),
        },
        {
            path: 'company.organization_number',
            validator: getOrgNumberValidator(
                values.company.address.country,
                t('settings.edit_account.invalid.company.organization_number.format')
            ),
        },
        {
            path: 'company.address.address_line',
            validator: validateRequired(t('settings.edit_account.invalid.company.address.address_line')),
        },
        {
            path: 'company.address.postal_place',
            validator: validateRequired(t('settings.edit_account.invalid.company.address.postal_place')),
        },
        {
            path: 'company.address.postal_code',
            validator: getPostalCodeValidator(
                values.company.address.country,
                t('settings.edit_account.invalid.company.address.postal_code.format')
            ),
        },
        {
            path: 'company.address.country',
            validator: validateRequired(t('settings.edit_account.invalid.company.address.country.required')),
        },
        {
            path: 'company.address.country',
            validator: validateISO_3166_1(t('settings.edit_account.invalid.company.address.country.format')),
        },
        {
            path: 'company.email',
            validator: validateEmail(t('settings.edit_account.invalid.company.email')),
        },
        {
            path: 'company.technical_email',
            validator: validateEmail(t('settings.edit_account.invalid.company.technical_email')),
        },
        {
            path: 'company.website',
            validator: validateRequired(t('settings.edit_account.invalid.company.website.required')),
        },
        {
            path: 'company.website',
            validator: validateUrl(t('settings.edit_account.invalid.company.website.format')),
        },
        {
            path: 'company.terms_url',
            validator: validateUrl(t('settings.edit_account.invalid.company.terms_url.format')),
        },
        {
            path: 'billing.email',
            validator: validateRequired(t('settings.edit_account.invalid.billing.email')),
        },
        {
            path: 'billing.email',
            validator: validateEmail(t('settings.edit_account.invalid.billing.email')),
        },
    ];

    if (!values.same_info) {
        validators = [
            ...validators,
            {
                path: 'billing.business_name',
                validator: validateRequired(t('settings.edit_account.invalid.billing.business_name')),
            },
            {
                path: 'billing.organization_number',
                validator: validateRequired(t('settings.edit_account.invalid.billing.organization_number.required')),
            },
            {
                path: 'billing.organization_number',
                validator: getOrgNumberValidator(
                    values.company.address.country,
                    t('settings.edit_account.invalid.company.organization_number.format')
                ),
            },
            {
                path: 'billing.address.address_line',
                validator: validateRequired(t('settings.edit_account.invalid.billing.address.address_line')),
            },
            {
                path: 'billing.address.postal_place',
                validator: validateRequired(t('settings.edit_account.invalid.billing.address.postal_place')),
            },
            {
                path: 'billing.address.postal_code',
                validator: getPostalCodeValidator(
                    values.billing.address.country,
                    t('settings.edit_account.invalid.company.address.postal_code.format')
                ),
            },
            {
                path: 'billing.address.country',
                validator: validateRequired(t('settings.edit_account.invalid.billing.address.country.required')),
            },
            {
                path: 'billing.address.country',
                validator: validateISO_3166_1(t('settings.edit_account.invalid.billing.address.country.format')),
            },
        ];
    }
    errors = validators.reduce((acc, elem) => {
        return validate(elem.path, elem.validator, validationValues, acc);
    }, errors);

    return errors;
};
