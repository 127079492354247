import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useActions } from '../../../../Actions';
import ActivatePaymentsLink from '../../../../components/ActivatePaymentsLink';
import { P } from '../../../../components/Typography';
import { defaultRadius, distances, palette } from '../../../../styles/constants';
import type { ApprovalsPaymentResponse } from '../../../../types/management-auth/generated';

type MissingApprovalDescriptionProps = {
    activeApprovals: ApprovalsPaymentResponse[];
    agreement: string;
};

function ActivatePaymentsShortcut() {
    const { closeModal } = useActions('connections.collector');

    return (
        <ActivatePaymentsLink sideEffect={() => {
            closeModal();
        }} />
    );
}

export default function MissingApprovalDescription({ activeApprovals, agreement }: MissingApprovalDescriptionProps) {
    const { t } = useTranslation();
    if (activeApprovals.length) {
        return null;
    }

    return (
        <elements.Wrapper>
            <P>
                {t('settings.payment_connections.collector_modal.missing_approval', {
                    agreement,
                })}
            </P>
            <elements.Group className="align-right">
                <ActivatePaymentsShortcut />
            </elements.Group>
        </elements.Wrapper>
    );
}

const elements = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
        border-radius: ${defaultRadius};
        background: ${palette.warning[50]};
        border: 1px solid ${palette.warning[300]};
        padding: ${distances.small12};
    `,
    Group: styled.div`
        display: flex;
        align-items: center;
        &.align-right {
            justify-content: flex-end;
        }
    `,
};
