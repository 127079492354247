import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { H1 } from '../../../../components/Typography';
import { Dl, Dt, Dd } from '../../../../components/DescriptionList/Horizontal';
import { Button } from '../../../../components/Buttons';
import { distances, grid, colors, palette } from '../../../../styles/constants';
import { Subscription } from '../../../../types/webhooks';

export interface HeroProps extends WithTranslation {
    hook: Subscription;
    openPingHookModal: () => void;
}

const Hero = (
    {
        t,
        hook,
        openPingHookModal,
    }: HeroProps
) => {
    return (
        <HeroWrapper>
            <Inner>
                <H1>{hook.config.url}</H1>
                <Dl>
                    <Dt>{t('settings.hook.fields.state')}</Dt>
                    <Dd>
                        {hook.deleted_at ? (
                            <Deleted>{t('settings.hook.state.deleted')}</Deleted>
                        ) : hook.active ? (
                            <Active>{t('settings.hook.state.active')}</Active>
                        ) : (
                            <InActive>{t('settings.hook.state.disabled')}</InActive>
                        )}
                    </Dd>
                </Dl>
                <Dl>
                    <Dt>{t('settings.hook.fields.id')}</Dt>
                    <Dd>{hook.id}</Dd>
                </Dl>
                <ActionWrapper>
                    {!hook.deleted_at && <Button onClick={openPingHookModal}>{t('settings.hook.ping_hook')}</Button>}
                </ActionWrapper>
            </Inner>
        </HeroWrapper>
    );
};

export default withTranslation()(Hero);

const HeroWrapper = styled.div`
    background: ${palette.primary[50]};
    width: 100%;
    color: ${palette.primary[700]};
`;
const Inner = styled.div`
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    padding: ${distances.large} ${distances.small};
`;

const Active = styled.span`
    color: ${colors.primary};
`;

const Deleted = styled.span`
    color: ${colors.invalid};
`;

const InActive = Deleted;

const ActionWrapper = styled.div`
    margin-top: ${distances.small};
`;
