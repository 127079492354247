import { connect } from 'react-redux';

import { fetchPayoutRule, deletePayoutRule, clearFetchedData } from '../actions';
import { State } from '../../../../reducer';
import { payoutRuleDetailsSelector } from '../selectors';
import PayoutRuleDetails from '../components/PayoutRuleDetails';

const mapStateToProps = (state: State) => ({
    payoutRuleDetailsState: payoutRuleDetailsSelector(state),
});

const mapDispatchToProps = {
    fetchPayoutRule,
    deletePayoutRule,
    clearFetchedData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PayoutRuleDetails);
