import deepmerge from 'deepmerge';
import { Dropdown } from '../../../components/Forms';
import { DropdownOption } from '../../../components/Forms/Dropdown';
import { useTranslation } from 'react-i18next';
import { State as AccountState } from '../reducer';
import styled from 'styled-components/macro';

interface PricePackageDropdownProps {
    account: AccountState;
    values: any;
    setFieldValue: (field: string, value: any) => void;
    setTouched: (fields: any) => void;
    touched: any;
}

const PricePackageDropdown = (props: PricePackageDropdownProps) => {
    const { account, setFieldValue, setTouched, touched, values } = props;
    const { t } = useTranslation('app');

    const options: DropdownOption[] = [
        ...account.pricePackages.map((pricePackage) => ({
            value: pricePackage.price_package_id,
            label: pricePackage.name,
        })),
    ];

    return (
        <Wrapper>
            <Dropdown
                name="price_package"
                value={values.price_package}
                label={t('partner.new_account.fields.price_package')}
                options={options}
                onChange={(opt) => {
                    setFieldValue('price_package', opt);
                    setTouched(
                        deepmerge(touched || {}, {
                            price_package: true,
                        })
                    );
                }}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    position: relative;
`;

export default PricePackageDropdown;
