import { CORE_API_HOSTNAME } from '../../../env';
import fulfill from '../../../fulfill';
import { Dispatch } from 'redux';
import { AccountAdminUser } from '../../../types/management-auth';
import { createSwitchlessReducer } from '../../../util/switchlessReducer';
import namespace from './namespace';

export interface State {
    isLoading: boolean;
    users: AccountAdminUser[];
    /** Ensure that the request has been completed by using the status. */
    status: number;
}

export const initialState: State = {
    isLoading: false,
    users: [],
    status: -1,
};

type Received = { users: AccountAdminUser[]; status: number };

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        getUsers: (state: State) => {
            return {
                ...state,
                isLoading: true,
                status: -1,
            };
        },
        fetchUsersResponse: (state: State, { users, status }: Received) => {
            return {
                ...state,
                isLoading: false,
                users,
                status,
            };
        },
    },
});

export const createUsersActions = (dispatch: Dispatch) => {
    const fetchUsers = async (account_id: string) => {
        dispatch(actions.getUsers());
        fulfill.get({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/management/users`,
            handlers: {
                200: (users: AccountAdminUser[]) => {
                    dispatch(actions.fetchUsersResponse({ users, status: 200 }));
                },
                401: (users: AccountAdminUser[]) => {
                    dispatch(actions.fetchUsersResponse({ users, status: 401 }));
                },
                403: (users: AccountAdminUser[]) => {
                    dispatch(actions.fetchUsersResponse({ users, status: 403 }));
                },
                500: (users: AccountAdminUser[]) => {
                    dispatch(actions.fetchUsersResponse({ users, status: 500 }));
                },
            },
        });
    };
    return {
        fetchUsers,
    };
};
