import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { useSelector } from 'react-redux';
import { useActions } from '../../../../Actions';
import { useAccountIds } from '../../../../auth/accessToken/withAccountIds';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { LoadingOverlay } from '../../../../components/Loading';
import Modal from '../../../../components/Modal';
import { H1, P } from '../../../../components/Typography';
import { distances } from '../../../../styles/constants';
import { usePaymentApprovals } from '../../approvalsPayments/hooks';
import { ModalState, namespace } from '../actions';
import BamboraForm from './BamboraForm';

const BamboraModal = () => {
    const { t } = useTranslation();
    const { closeModal } = useActions('connections.bambora');
    const { prodAccountId } = useAccountIds();
    const { approvals, isLoading } = usePaymentApprovals();
    const activeApprovals = (approvals || []).filter(x => x.case_status === 'ACTIVE');

    const { modalState, account } = useSelector(state => {
        return ({
            modalState: state[namespace].modalState,
            account: state[namespace].account,
        });
    });

    if (!prodAccountId) {
        return null;
    }

    if (modalState === ModalState.Closed) {
        return null;
    }

    if (!account || isLoading) {
        return <LoadingOverlay />;
    }

    const noApprovals = approvals === undefined || approvals.length === 0;
    const approvalsInvalidState = approvals && approvals.length > 0 && activeApprovals.length === 0;
    const approvalValidState = !(noApprovals || approvalsInvalidState);
    return (
        (<Modal fixedWidth>
            {[
                ModalState.ApplicationFormOpened,
                ModalState.InitiateApplicationLoading,
            ].includes(
                modalState
            ) && (
                <>
                    <H1>{t('settings.payment_connections.bambora_modal.title_intro')}</H1>
                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.bambora_modal.intro" />
                        </P>
                    </SpacedWrapper>
                    <SpacedWrapper>
                        {noApprovals &&
                            <P>
                                {t('settings.payment_connections.bambora_modal.no_approvals')}
                            </P>
                        }
                        {approvalsInvalidState &&
                            <P>
                                {t('settings.payment_connections.bambora_modal.approvals_invalid_state')}
                            </P>
                        }
                        {
                            approvalValidState && <>
                                <BamboraForm
                                    prodAccountId={prodAccountId}
                                    activeApprovals={activeApprovals}
                                    account={account}
                                />
                            </>
                        }
                        {
                            !approvalValidState && <SpacedWrapper>
                                <ButtonGroup>
                                    <Button className="alt" onClick={closeModal}>
                                        {t('settings.payment_connections.bambora_modal.cancel')}
                                    </Button>
                                </ButtonGroup>
                            </SpacedWrapper>
                        }

                    </SpacedWrapper>
                    {modalState === ModalState.InitiateApplicationLoading && <LoadingOverlay />}
                </>
            )}
            {modalState === ModalState.ApplicationReceived && (
                <>
                    <H1>{t('settings.payment_connections.bambora_modal.title_last_step')}</H1>
                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.bambora_modal.last_step" />
                        </P>
                    </SpacedWrapper>

                    <ButtonGroup>
                        <Button onClick={closeModal}>
                            {t('settings.payment_connections.bambora_modal.close')}
                        </Button>
                    </ButtonGroup>
                </>
            )}
            {modalState === ModalState.BamboraApiError && (
                <>
                    <H1>{t('settings.payment_connections.bambora_callback_modal.title_api_error')}</H1>
                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.payment_connections.bambora_callback_modal.error">
                                <a title="support@dintero.com" href="mailto:support@dintero.com">
                                    {' '}
                                </a>
                            </Trans>
                        </P>
                    </SpacedWrapper>
                    <ButtonGroup>
                        <Button onClick={closeModal}>
                            {t('settings.payment_connections.bambora_callback_modal.close')}
                        </Button>
                    </ButtonGroup>
                </>
            )}
        </Modal>)
    );
};


const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
`;

export default BamboraModal;
