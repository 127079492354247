import React from 'react';

const Xml = () => (
    <svg
        height="24"
        width="16"
        viewBox="0 0 12 16"
        preserveAspectRatio="xMidYMid meet"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="m8 0 4 4v11c0 .5522847-.4477153 1-1 1h-10c-.55228475 0-1-.4477153-1-1v-14c0-.55228475.44771525-1 1-1zm-.99 1h-6.01v14h10v-10.003l-2.96294504-.00926954c-.54715305-.00169402-.9913515-.44281176-.99685339-.98993977zm-2.62036643 6 .61036643.64195202-1.84031471 1.86666766 1.83736432 1.84820622-.61196911.6431741-2.3850805-2.48974572zm3.22073286 0 2.38963357 2.51025428-2.3850805 2.48974572-.61196911-.6431741 1.83736432-1.84820622-1.84031471-1.86666766zm.40363357-5.57.02615101 2.55773525 2.54084899.00726475z"
            fill="#0552e0"
        />
    </svg>
);

export default Xml;
