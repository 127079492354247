import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { namespace } from './';
import { useAccountId } from '../../auth/useAccountId';
import { useActions } from '../../Actions';
import { getAccountSelector } from './selectors';

export const useAccount = () => {
    const actions = useActions(namespace);
    const fetchAccount = actions.fetchAccount;
    const fetchAccountRef = useRef(fetchAccount);
    const accountId = useAccountId();
    const { account, isLoading, isNotFound } = useSelector(getAccountSelector);
    useEffect(() => {
        const hasAccountData = account?.account_id === accountId.substring(1);
        if (!hasAccountData && !isLoading && !isNotFound) {
            fetchAccountRef.current(accountId);
        }
    }, [accountId, account, fetchAccountRef, isLoading, isNotFound]);
    return { account, isLoading, isNotFound };
};
