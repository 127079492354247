

import decode from 'safe-decode-uri-component';

export const safeDecodeURIComponent = (value: string | undefined): string => {
    if (!value) {
        return '';
    }
    return decode(value);
};
