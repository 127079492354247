import React, {  useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Formik } from 'formik';
import { colors, distances, globalColumnMaxWidth, grid } from '../../../../styles/constants';
import Page, { Dismiss, PageButton } from '../../../../components/Page';
import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import { H1, H2 } from '../../../../components/Typography';
import Icon from '../../../../components/Icons';
import { Checkbox, BackButton, PhoneNumber } from '../../../../components/Forms';
import { AccountBilling, AccountCompany, UpdateAccount, Account } from '../../../../types/management-auth';
import { checkAccountingAndBillingEqual, checkAccountReadyForProduction } from '../../helpers';
import CountryDropdown from '../../../components/CountryDropdown';
import deepmerge from 'deepmerge';
import { unformatOrgNumber } from '../../../../helpers/formatters';
import { getValueAt } from '../../../../helpers/getValueAt';
import { TranslatedValidatedInput } from './ValidatedInput';
import { getPostalPlace } from '../../../../helpers/dataload/postalCodes';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { validateValues } from '../validators/validateEditAccount';
import { useIsDinteroRoot } from '../../../../auth/accessToken/Allow';
import { useAccountId } from '../../../../auth/useAccountId';
import * as phoneHelper from '../../../../helpers/phoneNumber';
import { useActions } from '../../../../Actions';
import { formSubmissionType } from './EditAccountPage';
import Hr from '../../../../components/Hr';
export interface FullFormProps {
    resetForm: () => void;
    setWasSubmitted: (value: formSubmissionType) => void;
    account: Account;
    isPrefilled: boolean;
    isLoading: boolean;
}

export interface FormValues {
    company: AccountCompany;
    billing: AccountBilling;
    same_info: boolean;
    [key: string]: any;
    companyCountryPrefix: string;
    companyPhoneNumber: string;
    billingCountryPrefix: string;
    billingPhoneNumber: string;
}

const countryPrefix = (value: phoneHelper.PhoneNumber, country: string | undefined): string => {
    const addressCountryPrefix = phoneHelper.countryPrefix(country || 'NO');
    return value.countryPrefix || addressCountryPrefix || '+47';
};

const EditFullAccount = ({ account, isPrefilled, isLoading, resetForm, setWasSubmitted }: FullFormProps) => {
    const { t } = useTranslation('app');
    const accountId = useAccountId();
    const isDinteroAdmin = useIsDinteroRoot();
    const { updateAccount } = useActions('accountDetails');

    const isNotReadyForProduction = useMemo(() => account && !checkAccountReadyForProduction(account), [account]);

    const { companyPN, billingPN } = useMemo(() => ({
        companyPN: phoneHelper.parse(account?.company.phone_number ?? ''),
        billingPN: phoneHelper.parse(account?.billing.phone_number ?? ''),
    }), [account]);

    const initialFormValues: FormValues = useMemo(() => ({
        company: account?.company ?? {} as any,
        billing: account?.billing ?? {} as any,
        companyCountryPrefix: countryPrefix(companyPN, account?.company?.address?.country),
        companyPhoneNumber: companyPN.phoneNumber,
        billingCountryPrefix: countryPrefix(billingPN, account?.company?.address?.country),
        billingPhoneNumber: billingPN.phoneNumber,
        same_info: !!account && checkAccountingAndBillingEqual(account.company, account.billing),
    }), [account, companyPN, billingPN]);

    if (isNotReadyForProduction && initialFormValues && !initialFormValues.billing.email && account) {
        // inject email address from applicant if none is set
        initialFormValues.billing.email = account.applicant.email;
    }

    const dismissPageButton = isPrefilled ? (
        <Dismiss handleClick={() => resetForm()} />
    ) : (
        <Dismiss />
    );

    const abortButton = isPrefilled ? (
        <Button className="alt" onClick={() => resetForm()}>
            {t('settings.edit_account.abort')}
        </Button>
    ) : (
        <BackButton>{t('settings.edit_account.abort')}</BackButton>
    );

    const isNotUndefinedNorAdmin = (value: any) => {
        return !isDinteroAdmin && value !== undefined;
    };

    if (!account) {
        return <LoadingOverlay />;
    }

    return (
        <Formik
            enableReinitialize
            initialValues={initialFormValues}
            validate={(values) => validateValues(values, t)}
            onSubmit={(values) => {
                if (!account || !values) {
                    return;
                }
                const submitValues = { ...values };
                if (values.same_info) {
                    submitValues.billing.business_name = submitValues.company.business_name;
                    submitValues.billing.organization_number = submitValues.company.organization_number;
                    submitValues.billing.address = {
                        address_line: submitValues.company.address.address_line,
                        address_line_2: submitValues.company.address.address_line_2,
                        postal_code: submitValues.company.address.postal_code,
                        postal_place: submitValues.company.address.postal_place,
                        country: submitValues.company.address.country,
                    };
                }

                submitValues.company.phone_number = phoneHelper.toString({
                    countryPrefix: submitValues.companyCountryPrefix,
                    phoneNumber: submitValues.companyPhoneNumber,
                });

                submitValues.billing.phone_number = phoneHelper.toString({
                    countryPrefix: submitValues.billingCountryPrefix,
                    phoneNumber: submitValues.billingPhoneNumber,
                });

                if (submitValues.company.website === 'https://') {
                    submitValues.company.website = '';
                }
                if (submitValues.company.terms_url === 'https://') {
                    submitValues.company.terms_url = '';
                }
                submitValues.company.organization_number = unformatOrgNumber(
                    submitValues.company.address.country,
                    submitValues.company.organization_number || ''
                );
                submitValues.billing.organization_number = unformatOrgNumber(
                    submitValues.billing.address.country,
                    submitValues.billing.organization_number || ''
                );
                if (submitValues.company.email === '') {
                    // Backend does not accept empty string
                    submitValues.company.email = undefined;
                }
                if (submitValues.company.technical_email === '') {
                    // Backend does not accept empty string
                    submitValues.company.technical_email = undefined;
                }
                updateAccount(accountId, submitValues).then(() => setWasSubmitted('submitted'));
            }}
        >
            {({ values, errors, handleChange, handleBlur, handleSubmit, setFieldValue, touched, setTouched }) => {
                return (
                    <Form onSubmit={handleSubmit} autoComplete="nope">
                        <Page
                            title={
                                <React.Fragment>
                                    {dismissPageButton}
                                    <Title>
                                        {t(
                                            isNotReadyForProduction || isPrefilled
                                                ? 'settings.edit_account.title_not_finished'
                                                : 'settings.edit_account.title'
                                        )}
                                    </Title>
                                    <PageButton
                                        disabled={
                                            isLoading ||
                                            Object.keys(errors || {}).length > 0 ||
                                            Object.keys(touched || {}).length === 0
                                        }
                                        type="submit"
                                    >
                                        <Icon icon="save" fill="currentColor" />
                                        {t(
                                            isNotReadyForProduction
                                                ? 'settings.edit_account.save_not_finished'
                                                : 'settings.edit_account.save'
                                        )}
                                    </PageButton>
                                </React.Fragment>
                            }
                        >
                            <Card>
                                <Wrapper>
                                    <FormGroup>
                                        <H2>{t('settings.edit_account.company_info')}</H2>
                                        <Hr />
                                        <FlexHalf>
                                            <CountryDropdown
                                                name="company.address.country"
                                                value={getValueAt('company.address.country', values)}
                                                label={
                                                    t('settings.edit_account.fields.company.address.country')
                                                }
                                                onChange={(value) => {
                                                    setFieldValue('company.address.country', value);
                                                    setFieldValue(
                                                        'companyCountryPrefix',
                                                        countryPrefix(companyPN, value)
                                                    );
                                                    setFieldValue(
                                                        'billingCountryPrefix',
                                                        countryPrefix(billingPN, value)
                                                    );
                                                    setTouched(
                                                        deepmerge(touched || {}, {
                                                            companyCountryPrefix: true,
                                                            billingCountryPrefix: true,
                                                            company: {
                                                                address: {
                                                                    country: true,
                                                                },
                                                            },
                                                        })
                                                    );
                                                }}
                                                placeholder={t(
                                                    'settings.edit_account.placeholders.company.address.country'
                                                )}
                                                disabled={isNotUndefinedNorAdmin(account?.company.address.country)}
                                            />
                                        </FlexHalf>
                                        <Flex>
                                            <TranslatedValidatedInput
                                                path="company.business_name"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                required
                                                disabled={isNotUndefinedNorAdmin(account?.company.business_name)}
                                            />
                                            <TranslatedValidatedInput
                                                path="company.display_name"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                            />
                                        </Flex>
                                        <Flex>
                                            <TranslatedValidatedInput
                                                path="company.organization_number"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                setTouched={setTouched}
                                                initialValues={initialFormValues}
                                                required
                                                disabled={isNotUndefinedNorAdmin(account?.company.organization_number)}
                                            />
                                            <TranslatedValidatedInput
                                                path="company.industry"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                disabled={isNotUndefinedNorAdmin(account?.company.industry)}
                                            />
                                        </Flex>
                                        <Flex>
                                            <TranslatedValidatedInput
                                                path="company.website"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                                required
                                            />
                                            <TranslatedValidatedInput
                                                path="company.terms_url"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                                disabled={isNotUndefinedNorAdmin(account?.company.terms_url)}
                                            />
                                        </Flex>
                                        <Flex>
                                            <TranslatedValidatedInput
                                                path="company.address.address_line"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                required
                                                disabled={isNotUndefinedNorAdmin(account?.company.address.address_line)}
                                            />
                                            <TranslatedValidatedInput
                                                path="company.address.address_line_2"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                disabled={isNotUndefinedNorAdmin(account?.company.address.address_line_2)}
                                            />
                                        </Flex>
                                        <Flex>
                                            <TranslatedValidatedInput
                                                path="company.address.postal_code"
                                                values={values}
                                                errors={errors}
                                                onChange={(e) => {
                                                    getPostalPlace(getValueAt('company.address.country', values), (e.target as HTMLInputElement).value).then(
                                                        (place) => {
                                                            if (place) {
                                                                setFieldValue(
                                                                    'company.address.postal_place',
                                                                    place
                                                                );
                                                            }
                                                        }
                                                    );
                                                    handleChange(e);
                                                }}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                disabled={isNotUndefinedNorAdmin(account?.company.address.postal_code)}
                                            />
                                            <TranslatedValidatedInput
                                                path="company.address.postal_place"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                required
                                                disabled={isNotUndefinedNorAdmin(account?.company.address.postal_place)}
                                            />
                                        </Flex>
                                        <Flex>
                                            <PhoneNumber
                                                name="companyPhoneNumber"
                                                label={t('settings.edit_account.placeholders.company.phone_number')}
                                                phoneNumber={values.companyPhoneNumber}
                                                countryPrefix={values.companyCountryPrefix}
                                                countryPrefixName="companyCountryPrefix"
                                                setFieldValue={setFieldValue}
                                                onBlur={handleBlur}
                                                disabled={false}
                                                autoComplete="off"
                                            />
                                        </Flex>
                                        <Flex>
                                            <TranslatedValidatedInput
                                                path="company.email"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                            />
                                            <TranslatedValidatedInput
                                                path="company.technical_email"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Flex>
                                        <Flex>
                                            <TranslatedValidatedInput
                                                path="company.branding.logo_url"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                            />
                                            <TranslatedValidatedInput
                                                path="company.branding.icon_url"
                                                values={values}
                                                errors={errors}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                touched={touched}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Flex>
                                    </FormGroup>
                                    <H2>{t('settings.edit_account.billing_info')}</H2>
                                    <Hr />
                                    <MarginWrapper>
                                        <Checkbox
                                            label={t('settings.edit_account.use_same_info_for_company_and_billing')}
                                            checked={values.same_info}
                                            name="same_info"
                                            onChange={(event) => {
                                                if (values && account) {
                                                    const source = values.company;
                                                    setFieldValue(
                                                        'billing.business_name',
                                                        getValueAt('business_name', source)
                                                    );
                                                    setFieldValue(
                                                        'billing.organization_number',
                                                        getValueAt('organization_number', source)
                                                    );
                                                    setFieldValue(
                                                        'billing.address.address_line',
                                                        getValueAt('address.address_line', source)
                                                    );
                                                    setFieldValue(
                                                        'billing.address.address_line_2',
                                                        getValueAt('address.address_line_2', source)
                                                    );
                                                    setFieldValue(
                                                        'billing.address.postal_code',
                                                        getValueAt('address.postal_code', source)
                                                    );
                                                    setFieldValue(
                                                        'billing.address.postal_place',
                                                        getValueAt('address.postal_place', source)
                                                    );
                                                    setFieldValue(
                                                        'billing.address.country',
                                                        getValueAt('address.country', source)
                                                    );
                                                    handleChange(event);
                                                }
                                            }}
                                        />
                                    </MarginWrapper>
                                    {values && !values.same_info && (
                                        <React.Fragment>
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="billing.business_name"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                />
                                                <TranslatedValidatedInput
                                                    path="billing.organization_number"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    setFieldValue={setFieldValue}
                                                    setTouched={setTouched}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    disabled={isNotUndefinedNorAdmin(account?.billing.organization_number)}
                                                    required
                                                />
                                            </Flex>
                                        </React.Fragment>
                                    )}
                                    <Flex>
                                        <TranslatedValidatedInput
                                            path="billing.email"
                                            values={values}
                                            errors={errors}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            touched={touched}
                                            required
                                            setFieldValue={setFieldValue}
                                        />
                                        <TranslatedValidatedInput
                                            path="billing.reference"
                                            values={values}
                                            errors={errors}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            touched={touched}
                                        />
                                    </Flex>
                                    {values && !values.same_info && (
                                        <React.Fragment>
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="billing.address.address_line"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                />
                                                <TranslatedValidatedInput
                                                    path="billing.address.address_line_2"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                />
                                            </Flex>
                                            <Flex>
                                                <TranslatedValidatedInput
                                                    path="billing.address.postal_code"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={(e) => {
                                                        getPostalPlace(values.billing.address.country || values.company.address.country, (e.target as HTMLInputElement).value).then(
                                                            (place) => {
                                                                if (place) {
                                                                    setFieldValue(
                                                                        'billing.address.postal_place',
                                                                        place
                                                                    );
                                                                }
                                                            }
                                                        );
                                                        handleChange(e);
                                                    }}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                />
                                                <TranslatedValidatedInput
                                                    path="billing.address.postal_place"
                                                    values={values}
                                                    errors={errors}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    touched={touched}
                                                    required
                                                />
                                            </Flex>

                                            <FlexHalf>
                                                <CountryDropdown
                                                    name="billing.address.country"
                                                    value={getValueAt('billing.address.country', values)}
                                                    label={
                                                        t('settings.edit_account.fields.billing.address.country') +
                                                        ' *'
                                                    }
                                                    onChange={(value) => {
                                                        setFieldValue('billing.address.country', value);
                                                        setTouched(
                                                            deepmerge(touched || {}, {
                                                                billing: {
                                                                    address: {
                                                                        country: true,
                                                                    },
                                                                },
                                                            })
                                                        );
                                                    }}
                                                    placeholder={t(
                                                        'settings.edit_account.placeholders.billing.address.country'
                                                    )}
                                                />
                                            </FlexHalf>
                                        </React.Fragment>
                                    )}
                                    <ButtonGroup>
                                        {abortButton}
                                        <Button
                                            disabled={
                                                isLoading ||
                                                Object.keys(errors || {}).length > 0 ||
                                                Object.keys(touched || {}).length === 0
                                            }
                                            type="submit"
                                        >
                                            {t(
                                                isNotReadyForProduction
                                                    ? 'settings.edit_account.save_not_finished'
                                                    : 'settings.edit_account.save'
                                            )}
                                        </Button>
                                    </ButtonGroup>
                                    {isLoading && <LoadingOverlay />}
                                </Wrapper>
                            </Card>
                        </Page>
                    </Form>
                );
            }}
        </Formik>
    );
};

const Form = styled.form`
    width: 100%;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    width: 100%;
`;

const FormGroup = styled.div`
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Flex = styled.div`
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const FlexHalf = styled.div`
    display: flex;
    max-width: ${grid.spans.span4};
`;

const MarginWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.small};
`;



export default EditFullAccount;
