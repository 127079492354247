import styled from 'styled-components/macro';
import { Button } from '../Buttons';
import { palette } from '../../styles/constants';
import Icon from '../Icons';

type RefreshFilterButtonProps = {
    onClick: () => void;
    title?: string;
};

export default function RefreshFilterButton({ onClick, title }: RefreshFilterButtonProps) {
    return (
        <FilterButton className="alt" onClick={onClick} title={title} tooltip={title}>
            <Icon icon="refresh" />
        </FilterButton>
    );
}



const FilterButton = styled(Button)<{ tooltip?: string  }>`
    max-height: 40px;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    position: relative;
    &::before {
        content: '${props => props.tooltip || ''}';
        position: absolute;
        background: ${palette.neutral[900]};
        color: white;
        border-radius: 3px;
        padding: 4px 8px;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        z-index: 10;
        top: -100%;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    &::after {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        background: ${palette.neutral[900]};
        clip-path: polygon(0 0, 100% 0, 0 100%);
        z-index: 10;
        top: -17px;
        left: 50%;
        transform: translateX(-50%) rotate(226deg);
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
    }

    &:hover::before {
        opacity: 1;
    }

    &:hover::after {
        opacity: 1;
    }
`;
