import { connect } from 'react-redux';

import { createHook, resetForm } from '../actions';
import { State } from '../../../../reducer';
import { getHooksSelector } from '../selectors';
import { getAccountUser } from '../../../../auth/accessToken/selectors';

import NewHookForm from '../components/NewHookForm';

const mapStateToProps = (state: State) => ({
    hook: getHooksSelector(state),
    accountUser: getAccountUser(state),
});

const mapDispatchToProps = {
    createHook,
    resetForm,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewHookForm);
