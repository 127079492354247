import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CHECKOUT_API_HOSTNAME, CORE_API_HOSTNAME } from '../../../env';
import {
    ActionTypes,
    FetchEditPaymentProfileResponse,
    EditPaymentProfile,
    GetCheckoutConfiguration,
    FetchCheckoutConfigurationResponse
} from './actions';
import { ProfileConfiguration, NewProfileConfiguration, CheckoutConfig } from '../../../types/checkout';
import { Account } from '../../../types/management-auth';

const fetchGetCheckoutConfiguration: SideEffectFunction<
    GetCheckoutConfiguration,
    FetchCheckoutConfigurationResponse
> = async action => {
    const result = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/checkout`,
        handlers: {
            200: (configuration: CheckoutConfig[]) => ({
                configuration,
            }),
            404: () => ({
                configuration: undefined,
            }),
        },
    });
    const [account, profile] = await Promise.all([
        fulfill.get({
            accountId: action.payload.accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/settings`,
            handlers: {
                200: (account: Account) => ({
                    account,
                }),
                404: () => ({
                    account: undefined,
                }),
            },
        }),
        fulfill.get({
            accountId: action.payload.accountId,
            url: `${CHECKOUT_API_HOSTNAME}/v1/admin/session/profiles/${action.payload.profileId}`,
            handlers: {
                200: (profile: ProfileConfiguration) => ({
                    profile,
                }),
                404: () => ({
                    profile: undefined,
                }),
            },
        }),
    ]);
    return {
        type: ActionTypes.FetchCheckoutConfigurationResponse,
        payload: {
            configuration: result.configuration,
            account: account.account,
            profile: profile.profile,
        },
        meta: {
            cause: action,
        },
    };
};

const fetchEditPaymentProfile: SideEffectFunction<
    EditPaymentProfile,
    FetchEditPaymentProfileResponse
> = async action => {
    const result: FetchEditPaymentProfileResponse = await fulfill.put<NewProfileConfiguration>({
        accountId: action.payload.accountId,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/session/profiles/${action.payload.profileId}`,
        json: action.payload.newProfile,
        handlers: {
            200: (paymentProfile: ProfileConfiguration) => {
                return ({
                    type: ActionTypes.FetchEditPaymentProfileResponse,
                    payload: {
                        paymentProfile: paymentProfile,
                        accountId: action.payload.accountId,
                    },
                    meta: {
                        cause: action,
                    },
                });
            },
        },
    });
    return result;
};

const effects = {
    [ActionTypes.GetCheckoutConfiguration]: fetchGetCheckoutConfiguration,
    [ActionTypes.EditPaymentProfile]: fetchEditPaymentProfile,
};

export default effects;
