export const validateEndswith = (endsWith: string, message: string) => {
    return (value: string) => {
        if (!value) {
            return;
        }
        const isValid = value.endsWith(endsWith);
        if (!isValid) {
            return message;
        }
    };
};
