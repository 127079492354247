import { ReactNode } from 'react';
import Button from './Buttons/Button';
import Modal from './Modal';
import { H2 } from './Typography';
import View from './View';
import { ButtonGroup } from './Buttons';

export type ConfirmationDialogProps = {
    open: boolean;
    title?: string;
    okButtonText: string;
    cancelButtonText: string;
    disableOk?: boolean;
    onOk: () => void;
    onCancel: () => void;
    children: ReactNode;
};

const ConfirmationDialog = ({
    open,
    onCancel,
    onOk,
    title,
    okButtonText,
    cancelButtonText,
    disableOk,
    children,
}: ConfirmationDialogProps) => (
    open ?
        <Modal>
            <View gap={24} direction="column" justify="flex-start" alignItems="flex-start">
                <View direction="column" justify="flex-start" alignItems="flex-start">
                    {title && <H2>{title}</H2>}
                    {children}
                </View>
                <ButtonGroup>
                    <Button onClick={onCancel} className="alt">{cancelButtonText}</Button>
                    <Button onClick={onOk} disabled={disableOk}>{okButtonText}</Button>
                </ButtonGroup>
            </View>
        </Modal>
        : null
);

export default ConfirmationDialog;
