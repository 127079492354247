import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';
import {
    ActionTypes,
    FetchClientResponse,
    GetClient,
    FetchGrantResponse,
    CreateGrant,
    FetchCreateGrantResponse
} from './actions';
import { Client, Grant } from '../../../types/management-auth';

const fetchClient: SideEffectFunction<GetClient, FetchClientResponse> = async action => {
    const result: FetchClientResponse = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/auth/clients/${action.payload.clientId}`,
        handlers: {
            200: (client: Client) => ({
                type: ActionTypes.FetchClientResponse,
                payload: {
                    client,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchGrant: SideEffectFunction<FetchClientResponse, FetchGrantResponse> = async action => {
    const result: FetchGrantResponse = await fulfill.get({
        accountId: action.meta.cause.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.meta.cause.payload.accountId}/auth/client-grants?client_id=${
            action.payload.client.client_id
        }`,
        handlers: {
            200: (grants: Grant[]) => ({
                type: ActionTypes.FetchGrantResponse,
                payload: {
                    grants,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchPostGrant: SideEffectFunction<CreateGrant, FetchCreateGrantResponse> = async action => {
    const audience = action.payload.grant.audience;
    const account = audience.split('/').pop();
    const result: FetchCreateGrantResponse = await fulfill.post({
        accountId: account || '',
        url: `${CORE_API_HOSTNAME}/v1/accounts/${account}/auth/client-grants`,
        json: action.payload.grant,
        handlers: {
            200: (grant: Grant) => ({
                type: ActionTypes.FetchCreateGrantResponse,
                payload: {},
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.GetClient]: fetchClient,
    [ActionTypes.FetchClientResponse]: fetchGrant,
    [ActionTypes.CreateGrant]: fetchPostGrant,
};

export default effects;
