import React from 'react';
import styled from 'styled-components/macro';
import { RouteComponentProps } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

import { Button } from '../../components/Buttons';
import Page from '../../components/Page';
import Card from '../../components/Card';
import { distances } from '../../styles/constants';
import { P } from '../../components/Typography';

interface MatchParams {
    accountId: string;
}
interface NotFoundProps extends WithTranslation, RouteComponentProps<MatchParams> {}

const NotFound = (
    {
        t,
        match,
        history,
    }: NotFoundProps
) => {
    return (
        <Page title={t('errors.not_found.title')}>
            <Card>
                <P>{t('errors.not_found.description')}</P>
                <ButtonWrapper>
                    <Button
                        className="alt"
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        {t('errors.not_found.browser_back_call_to_action')}
                    </Button>
                </ButtonWrapper>
            </Card>
        </Page>
    );
};

export default withTranslation()(NotFound);

const ButtonWrapper = styled.div`
    margin-top: ${distances.normal};
`;
