import { reducer as clientsReducer } from './clients';
import { reducer as usersReducer } from './users';
import { reducer as hooksReducer } from './hooks';
import { reducer as accountReducer } from './account';
import { reducer as paymentProfilesReducer } from './paymentProfiles';
import { reducer as paymentConnectionsReducer } from './paymentConnections';
import { reducer as payoutRulesReducer } from './payoutRules';
import { reducer as locationsReduced } from './locations';
import * as cdd from './cddActions';
import * as approvals from './approvalsActions';
import * as locations from './locations/locationActions';
import * as reports from './reports/actions';
import * as settlementReports from './settlementReports/actions';
import * as checkoutConfiguration from './checkoutConfigurationActions';
import * as billingActions from './billingActions';

export interface State
    extends clientsReducer.State,
        usersReducer.State,
        hooksReducer.State,
        accountReducer.State,
        paymentProfilesReducer.State,
        paymentConnectionsReducer.State,
        payoutRulesReducer.State,
        locationsReduced.State {
    [cdd.namespace]: cdd.CustomerDueDiligenceState;
    [approvals.namespace]: approvals.ApprovalsState;
    [locations.namespace]: locations.SalesLocationsState;
    [reports.namespace]: reports.State;
    [settlementReports.namespace]: settlementReports.State;
    [checkoutConfiguration.namespace]: checkoutConfiguration.CheckoutConfigurationState;
    [billingActions.namespace]: billingActions.State;
}

export const initialState: State = {
    ...usersReducer.initialState,
    ...clientsReducer.initialState,
    ...hooksReducer.initialState,
    ...accountReducer.initialState,
    ...paymentProfilesReducer.initialState,
    ...paymentConnectionsReducer.initialState,
    ...payoutRulesReducer.initialState,
    ...locationsReduced.initialState,
    [cdd.namespace]: cdd.initialState,
    [approvals.namespace]: approvals.initialState,
    [locations.namespace]: locations.initialState,
    [reports.namespace]: reports.initialState,
    [settlementReports.namespace]: settlementReports.initialState,
    [checkoutConfiguration.namespace]: checkoutConfiguration.initialState,
    [billingActions.namespace]: billingActions.initialState,
};

export const reducers = {
    ...usersReducer.reducers,
    ...clientsReducer.reducers,
    ...hooksReducer.reducers,
    ...accountReducer.reducers,
    ...paymentProfilesReducer.reducers,
    ...paymentConnectionsReducer.reducers,
    ...payoutRulesReducer.reducers,
    ...locationsReduced.reducers,
    [cdd.namespace]: cdd.reducer,
    [approvals.namespace]: approvals.reducer,
    [locations.namespace]: locations.reducer,
    [reports.namespace]: reports.reducer,
    [settlementReports.namespace]: settlementReports.reducer,
    [checkoutConfiguration.namespace]: checkoutConfiguration.reducer,
    [billingActions.namespace]: billingActions.reducer,
};
