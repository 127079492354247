import { ReactNode, cloneElement, useEffect, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import Portal from '../common/components/Portal';
import { palette } from '../styles/constants';

type TooltipProps = {
    title: ReactNode;
    children: JSX.Element;
};

function Tooltip({ title, children }: TooltipProps) {
    const [isHovered, setIsHovered] = useState(false);
    const ref = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLElement>(null);
    const [childRect, setChildRect] = useState<DOMRect | null>(null);

    useEffect(() => {
        if (childRef.current) {
            setChildRect(childRef.current.getBoundingClientRect());
        }
    }, [childRef]);

    return (
        <Wrapper
            ref={ref}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {cloneElement(children, { ref: childRef })}
            <Portal>
                <PopoverWrapper data-active={isHovered} style={{
                    top: childRect?.bottom || 0 + window.scrollY,
                    left: childRect?.left,
                    width: childRect?.width,
                }}><Popover>{title}</Popover></PopoverWrapper>
            </Portal>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: contents;
`;

const PopoverWrapper = styled.div`
    position: absolute;
    display: inline-block;
    opacity: 0;
    pointer-events: none;
    transition: all 0.1s ease-in-out;
    &[data-active='true'] {
        opacity: 1;
        pointer-events: auto;
    }
`;

const Popover = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    min-width: max-content;
    background: ${palette.neutral[900]};
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 3px;
    padding: 4px 8px;
    font-size: 12px;
    top: 8px;
    &::before {
        content: '';
        position: absolute;
        height: 10px;
        width: 10px;
        top: -4px;
        background: ${palette.neutral[900]};
        clip-path: polygon(0 0, 100% 0, 0 100%);
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        pointer-events: none;
        transition: opacity 0.1s cubic-bezier(0.39, 0.575, 0.565, 1);
    }
`;

export default Tooltip;
