import React from 'react';
import { Route } from 'react-router-dom';

import SettlementReportConfigurationsList from './components/SettlementReportConfigurationsList';

const Routes = [
    <Route key="settlement-reports" path="/:accountId/settings/payout-report-configs" exact component={SettlementReportConfigurationsList} />,
];

export default Routes;
