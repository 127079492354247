import { connect } from 'react-redux';

import {
    getClient,
    openDeleteClientModal,
    dismissDeleteClientModal,
    deleteClient,
    openRotateSecretModal,
    dismissRotateSecretModal,
    rotateSecret,
    toggleSecretIsSafe,
    openDeleteGrantModal,
    dismissDeleteGrantModal,
    deleteGrant
} from '../actions';
import { State } from '../../../../reducer';
import { getClientSelector } from '../selectors';

import ClientPage from '../components/ClientPage';

const mapStateToProps = (state: State) => ({
    client: getClientSelector(state),
});

const mapDispatchToProps = {
    getClient,
    openDeleteClientModal,
    dismissDeleteClientModal,
    deleteClient,
    openRotateSecretModal,
    dismissRotateSecretModal,
    rotateSecret,
    toggleSecretIsSafe,
    openDeleteGrantModal,
    dismissDeleteGrantModal,
    deleteGrant,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientPage);
