import { PayoutRuleFormikErrors, PayoutRuleFormikValues } from './types';
import { smallerOrEqual, validate, validatePositiveNumber, validateRequired } from '../../../../helpers/validation';
import { BillingPayoutRuleDestination, BillingPayoutRuleDestinationNested } from '../../../../types/billing/generated';
import { TFunction } from 'i18next';
import { validateBothNotSet } from '../../../../helpers/validation/bothNotSet';
import { getValueAt } from '../../../../helpers/getValueAt';
import { PayoutDestinationGroup } from '../../payoutRules/types';

type Validator = { path: string; validator: (value: any, values?: any) => string | undefined };

const isRemainingAmountSet = (destinations: PayoutDestinationGroup) => {
    const remainingAmountDestinations = destinations.filter(
        (d) => d.type === 'remaining_amount'
    );
    return (
        remainingAmountDestinations.length === 1 &&
        destinations[destinations.length - 1].type === 'remaining_amount'
    );
};

const destinationSumValidations = (path: string, values: PayoutRuleFormikValues, t: TFunction) => {
    const destinations = getValueAt(path, values) as PayoutDestinationGroup;
    if (!destinations || destinations.length === 0) {
        return [];
    }

    const validators: Validator[] = [];

    if (destinations.every((dest) => dest.type === 'percentage')) {
        const percentageSum = destinations
            .filter((dest: any) => dest.type === 'percentage')
            .map((dest: any) => (dest.value ? parseFloat(dest.value) : 0))
            .reduce((acc: number, item: number) => acc + item, 0);
        validators.push({
            path: `${path}_overall`,
            validator: () =>
                percentageSum !== 100
                    ? t('payout_rules.new_payout_rule.validation.destination.percentage_sum_not_100')
                    : undefined,
        });
    } else if (!isRemainingAmountSet(destinations)) {
        validators.push({
            path: `${path}_overall`,
            validator: () => t('payout_rules.new_payout_rule.validation.destination.remaining_amount_not_last'),
        });
    } else {

        const percentageTotalSum = destinations
            .filter((dest: any) => dest.type === 'percentage')
            .map((dest: any) => (dest.value ? parseFloat(dest.value) : 0))
            .reduce((acc: number, item: number) => acc + item, 0);


        /** Prevent making split rules that kinda don't make sense */
        if (percentageTotalSum > 100) {
            validators.push({
                path: `${path}_overall`,
                validator: () => t('payout_rules.new_payout_rule.validation.destination.percentage_sum_over_100'),
            });
        } else if (percentageTotalSum === 100 && isRemainingAmountSet(destinations)) {
            validators.push({
                path: `${path}_overall`,
                validator: () => t('payout_rules.new_payout_rule.validation.destination.remaining_amount_no_value'),
            });
        }
    }

    return validators;
};

export const buildPayoutRuleValidations = (values: PayoutRuleFormikValues, t: TFunction): PayoutRuleFormikErrors => {
    let errors: PayoutRuleFormikErrors = {};

    const validators: Validator[] = [
        {
            path: 'rule_id',
            validator: validateRequired(t('payout_rules.new_payout_rule.validation.rule_id_required')),
        },
        {
            path: 'rule_type',
            validator: validateRequired(t('payout_rules.new_payout_rule.validation.rule_type_required')),
        },
    ];
    const destinationValidations = (values.destinations || [])
        .map(buildDestinationValidations('destinations', values, t))
        .reduce((acc, item) => [...acc, ...item], []);
    validators.push(...destinationValidations);
    validators.push(...destinationSumValidations('destinations', values, t));
    errors = validators.reduce((acc, elem) => {
        return validate(elem.path, elem.validator, values, acc);
    }, errors);

    return errors;
};

const buildDestinationValidations = (pathPrefix: string, values: PayoutRuleFormikValues, t: TFunction) => (
    destination: BillingPayoutRuleDestination | BillingPayoutRuleDestinationNested,
    idx: number
): Validator[] => {
    const subDestinations = (destination.destinations || []) as BillingPayoutRuleDestinationNested[];
    const subDestinationValidations = subDestinations
        .map(buildDestinationValidations(`${pathPrefix}.${idx}.destinations`, values, t))
        .reduce((acc, item) => [...acc, ...item], []);
    const validations = [
        {
            path: `${pathPrefix}.${idx}.destination`,
            validator: validateBothNotSet(
                `${pathPrefix}.${idx}.destinations`,
                t('payout_rules.new_payout_rule.validation.destination.both_destination_destinations')
            ),
        },
        {
            path: `${pathPrefix}.${idx}.value`,
            validator: validatePositiveNumber(t('payout_rules.new_payout_rule.validation.destination.value_positive')),
        },
        ...subDestinationValidations,
    ];
    if (getValueAt(`${pathPrefix}.${idx}.type`, values) !== 'remaining_amount') {
        validations.push({
            path: `${pathPrefix}.${idx}.value`,
            validator: validateRequired(t('payout_rules.new_payout_rule.validation.destination.value_required')),
        });
    }

    if (getValueAt(`${pathPrefix}.${idx}.type`, values) === 'percentage') {
        validations.push({
            path: `${pathPrefix}.${idx}.value`,
            validator: smallerOrEqual(
                100,
                t('payout_rules.new_payout_rule.validation.destination.value_higher_than_100')
            ),
        });
    }
    const destinations = getValueAt(`${pathPrefix}.${idx}.destinations`, values);
    if (destinations === undefined || destinations.length === 0) {
        validations.push({
            path: `${pathPrefix}.${idx}.destination`,
            validator: validateRequired(t('payout_rules.new_payout_rule.validation.destination.destination_required')),
        });
    }
    validations.push(...destinationSumValidations(`${pathPrefix}.${idx}.destinations`, values, t));
    return validations;
};
