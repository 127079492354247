import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import { distances, grid, border, colors, globalColumnMaxWidth } from '../../../../styles/constants';
import { Dl, Dt, Dd } from '../../../../components/DescriptionList/Horizontal';
import { H2 } from '../../../../components/Typography';
import { AccountAdminUser } from '../../../../types/management-auth';
import { DateTime } from '../../../../components/Formatters';
import { StyledRouteLink } from '../../../../components/StyledLink';
import Hr from '../../../../components/Hr';

interface MatchParams {
    accountId: string;
    userId: string;
}
export interface DetailsInfoProps extends WithTranslation, RouteComponentProps<MatchParams> {
    user: AccountAdminUser;
    users: AccountAdminUser[];
}

const getCreatedBy = (createdBy: string, users: AccountAdminUser[], accountId: string) => {
    const userIdMatch = users.find((u) => u.id === createdBy);
    if (userIdMatch) {
        return (
            <StyledRouteLink to={`/${accountId}/settings/users/${userIdMatch.id}`}>
                {userIdMatch.name || userIdMatch.id}
            </StyledRouteLink>
        );
    }
    return createdBy;
};

const DetailsInfo = (
    {
        t,
        user,
        users,
        match,
    }: DetailsInfoProps
) => {
    const { accountId } = match.params;

    return (
        <Wrapper>
            <InfoWrapper>
                <H2>{t('settings.user.user_info')}</H2>
                <Hr />
                <Dl>
                    <Dt doubleColumn>{t('settings.user.fields.last_logged_in_at')}</Dt>
                    <Dd>
                        <DateTime isoString={user.last_seen_at || ''} boldTime />
                    </Dd>
                </Dl>

                <Dl>
                    <Dt doubleColumn>{t('settings.user.fields.updated_at')}</Dt>
                    <Dd>
                        <DateTime isoString={user.updated_at || ''} boldTime />
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.user.fields.created_at')}</Dt>
                    <Dd>
                        <DateTime isoString={user.created_at || ''} boldTime />
                    </Dd>
                </Dl>
                <Dl>
                    <Dt doubleColumn>{t('settings.user.fields.created_by')}</Dt>
                    <Dd>{getCreatedBy(user.created_by || '', users, accountId)}</Dd>
                </Dl>
                {user?.configuration?.mfa?.sms?.enabled && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.users.fields.mfa_enabled_at')}</Dt>
                        <Dd>
                            <DateTime isoString={user?.configuration?.mfa?.sms?.updated_at || ''} boldTime />
                        </Dd>
                    </Dl>
                )}
            </InfoWrapper>
            <AccessWrapper>
                <H2>{t('settings.user.fields.scopes')}</H2>
                <Hr />
                <Ul>
                    {user.scope &&
                        user.scope.sort().map((scope) => (
                            <li key={scope}>
                                <code>{scope}</code>
                            </li>
                        ))}
                </Ul>
            </AccessWrapper>
        </Wrapper>
    );
};

export default withRouter(withTranslation()(DetailsInfo));

const Wrapper = styled.div`
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    padding: ${distances.normal} ${distances.small};
    display: flex;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const InfoWrapper = styled.div`
    padding-top: ${distances.normal};
    max-width: ${grid.spans.span5};
    margin-right: ${grid.gutter};

    @media (max-width: ${globalColumnMaxWidth}px) {
        max-width: 100%;
        margin-right: 0;
    }
`;

const AccessWrapper = styled.div`
    padding-top: ${distances.normal};
    max-width: ${grid.spans.span3};
    margin-right: ${grid.gutter};

    @media (max-width: ${globalColumnMaxWidth}px) {
        max-width: 100%;
        margin-right: 0;
    }
`;

const Ul = styled.ul`
    margin: 0;
    padding-left: 17px;
    list-style-type: circle;
`;
