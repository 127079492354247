import { Subscription } from '../../../types/webhooks';

export enum ActionTypes {
    ResetForm = '[settings/hooks] RESET_FORM',
    CreateHook = '[settings/hooks] CREATE_HOOK',
    CreateHookResponse = '[settings/hooks] FETCH_POST_HOOK_RESPONSE',
}

export interface ResetForm {
    type: ActionTypes.ResetForm;
    payload: {};
}

export function resetForm(): ResetForm {
    return {
        type: ActionTypes.ResetForm,
        payload: {},
    };
}
export interface CreateHook {
    type: ActionTypes.CreateHook;
    payload: {
        accountId: string;
        newHook: Subscription;
    };
}

export interface FetchCreateHookResponse {
    type: ActionTypes.CreateHookResponse;
    payload: {
        hook: Subscription;
    };
    meta: {
        cause: CreateHook;
    };
}

export function createHook(accountId: string, newHook: Subscription): CreateHook {
    return {
        type: ActionTypes.CreateHook,
        payload: {
            accountId,
            newHook: newHook,
        },
    };
}

export type Actions = CreateHook | FetchCreateHookResponse | ResetForm;
