import React, { Component } from 'react';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { colors, distances } from '../../../../styles/constants';
import Page, { Back, PageButton } from '../../../../components/Page';
import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import { H1 } from '../../../../components/Typography';
import Icon from '../../../../components/Icons';
import NotFound from './NotFound';
import Details from './Details';
import { State as HookState } from '../reducer';
import DeleteHookModal from './DeleteHookModal';
import PingHookModal from './PingHookModal';
import View from '../../../../components/View';

interface MatchParams {
    accountId: string;
    hookAccountId: string;
    hookId: string;
}

export interface HookPageProps extends WithTranslation, RouteComponentProps<MatchParams> {
    getHook: (accountId: string, hookId: string) => void;
    openDeleteHookModal: () => void;
    dismissDeleteHookModal: () => void;
    deleteHook: (accountId: string, hookId: string) => void;
    openPingHookModal: () => void;
    dismissPingHookModal: () => void;
    pingHook: (accountId: string, hookId: string) => void;
    hook: HookState;
    getDeliveries: (accountId: string, hookId: string) => void;
    getDeliveryDetails: (accountId: string, hookId: string, deliveryId: string) => void;
}

class HookPage extends Component<HookPageProps> {
    componentDidMount() {
        const { hookAccountId, hookId } = this.props.match.params;
        this.props.getHook(hookAccountId, hookId);
        this.props.getDeliveries(hookAccountId, hookId);
    }

    componentDidUpdate(prevProps: Readonly<HookPageProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const { hookId } = prevProps.match.params;
        if (this.props.match.params.hookId !== hookId) {
            this.props.getHook(this.props.match.params.hookAccountId, this.props.match.params.hookId);
        }
    }

    render() {
        const { t, match, hook, openDeleteHookModal, openPingHookModal, getDeliveryDetails } = this.props;
        const { accountId, hookAccountId, hookId } = match.params;

        if (hook.isDeleted) {
            return <Redirect to={`/${accountId}/settings/hooks`} />;
        }

        return (
            <Page
                title={
                    <React.Fragment>
                        <Back />
                        <Title>{t('settings.hook.title')}</Title>
                        <View ml="auto" gap={16}>
                            <PageButton
                                onClick={openDeleteHookModal}
                                disabled={!hook.hook || hook.hook.deleted_at !== undefined}
                            >
                                <Icon icon="delete" />
                                {t('settings.hook.delete_hook')}
                            </PageButton>
                        </View>
                    </React.Fragment>
                }
            >
                <Card title={undefined} noPadding>
                    <Wrapper>
                        {!hook.isLoading && !hook.hook && <NotFound hookId={hookId} accountId={accountId} />}
                        {hook.hook && (
                            <Details
                                hook={hook.hook}
                                deliveries={hook.deliveries}
                                deliveryDetails={hook.deliveryDetails}
                                getDeliveryDetails={getDeliveryDetails}
                                openPingHookModal={openPingHookModal}
                            />
                        )}
                        {!hook.isLoading && !hook.hook && <NotFound hookId={hookId} accountId={accountId} />}
                        {hook.isLoading && <LoadingOverlay />}
                    </Wrapper>
                </Card>
                {hook.hook && hook.isShowingDeleteModal && (
                    <DeleteHookModal
                        accountId={hookAccountId}
                        hook={hook.hook}
                        deleteHook={this.props.deleteHook}
                        dismissDeleteHookModal={this.props.dismissDeleteHookModal}
                    />
                )}
                {hook.hook && hook.isShowingPingModal && (
                    <PingHookModal
                        accountId={hookAccountId}
                        hook={hook.hook}
                        pingHook={this.props.pingHook}
                        dismissPingHookModal={this.props.dismissPingHookModal}
                    />
                )}
            </Page>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default withTranslation()(HookPage);
