import { Actions, ActionTypes } from './actions';
import { AccountAdminUser } from '../../../types/management-auth';

export interface State {
    isLoading: boolean;
    user: AccountAdminUser | undefined;
    isEdited: boolean;
}

export const initialState: State = {
    isLoading: false,
    user: undefined,
    isEdited: false,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.ResetForm:
            return {
                ...initialState,
            };
        case ActionTypes.GetUser:
            return {
                ...initialState,
                isLoading: true,
            };

        case ActionTypes.FetchUserResponse:
            return {
                ...state,
                isLoading: false,
                user: action.payload.user,
            };

        case ActionTypes.EditUser:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FetchEditUserResponse:
            return {
                ...state,
                isEdited: true,
            };

        default:
            return state;
    }
}
