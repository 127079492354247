import { type Actions, ActionTypes } from './actions';
import type {
    AuthenticatedUser,
    ChangePasswordModalStates,
    ChangeMfaSmsModalStates,
    ChangeMfaTotpModalStates
} from '../types';
import { setCognitoSession, setPartner, clearPartner, getPartner } from '../localStorage';

export interface State extends AuthenticatedUser {
    tokens: {
        [accountId: string]: string;
    };
    isLoadingTokens: boolean;
    isLoadingSubAccountTokens: boolean;
    exchangeTokenPartnerId: string | undefined;
    seq: number;
    changePasswordState: ChangePasswordModalStates;
    changeMfaSmsState: ChangeMfaSmsModalStates;
    changeMfaTotpState: ChangeMfaTotpModalStates;
    partner_user: AuthenticatedUser | undefined;
}

export const initialState: State = {
    cognito_access_token: '',
    cognito_expires: '',
    exchangeTokenPartnerId: undefined,
    account_user: {
        id: '',
        accounts: [],
    },
    tokens: {},
    isLoadingTokens: false,
    isLoadingSubAccountTokens: false,
    seq: 0,
    changePasswordState: 'pristine',
    changeMfaSmsState: 'pristine',
    changeMfaTotpState: 'pristine',
    partner_user: undefined,
};

export function reducer(state: State = initialState, action: Actions): State {
    switch (action.type) {
        case ActionTypes.SetAuthenticatedUser: {
            const [partner_user, exchangeTokenPartnerId] = getPartner();
            return {
                ...state,
                ...action.payload.accountUser,
                partner_user,
                exchangeTokenPartnerId,
                isLoadingTokens: true,
            };
        }

        case ActionTypes.SetInspectAuthenticatedUser: {
            setPartner(state, action.payload.exchangeTokenPartnerId);
            return {
                ...state,
                ...action.payload.accountUser,
                exchangeTokenPartnerId: action.payload.exchangeTokenPartnerId,
                partner_user: { ...state },
                isLoadingTokens: true,
            };
        }

        case ActionTypes.SetLeaveInspect: {
            if (state.partner_user) {
                // Update local storage data also to avoid "split brain" on site refresh.
                clearPartner();
                setCognitoSession({
                    cognito_access_token: state.partner_user.cognito_access_token,
                    cognito_expires: state.partner_user.cognito_expires,
                });
            }
            return {
                ...state,
                ...(state.partner_user || {}),
                partner_user: undefined,
                exchangeTokenPartnerId: undefined,
            };
        }

        case ActionTypes.UpdateAuthenticatedUserResult: {
            return {
                ...state,
                ...action.payload,
            };
        }

        case ActionTypes.LoadTokens: {
            return {
                ...state,
                isLoadingTokens: action.payload.showLoading,
                seq: state.seq + 1,
            };
        }

        case ActionTypes.FetchTokenResponse: {
            return {
                ...state,
                tokens: action.payload.accounts.reduce((acc, x) => ({ ...acc, [x.accountId]: x.token }), {}),
                isLoadingTokens: false,
            };
        }

        case ActionTypes.LoadSubAccountTokens: {
            return {
                ...state,
                isLoadingSubAccountTokens: true,
            };
        }

        case ActionTypes.FetchSubAccountTokensResponse: {
            return {
                ...state,
                isLoadingSubAccountTokens: false,
                tokens: {
                    ...state.tokens,
                    ...action.payload.accounts.reduce((acc, x) => ({ ...acc, [x.accountId]: x.token }), {}),
                },
            };
        }

        case ActionTypes.OpenChangePassword: {
            return {
                ...state,
                changePasswordState: 'pristine',
            };
        }
        case ActionTypes.ChangePassword: {
            return {
                ...state,
                changePasswordState: 'in-flight',
            };
        }
        case ActionTypes.ChangePasswordResponse: {
            return {
                ...state,
                changePasswordState: action.payload.result,
            };
        }

        case ActionTypes.OpenAuthenticatedUserSettings: {
            return {
                ...state,
                changeMfaSmsState: 'pristine',
                changeMfaTotpState: 'pristine',
            };
        }
        case ActionTypes.ChangeAuthenticatedUserMfaSms: {
            return {
                ...state,
                changeMfaSmsState: 'in-flight',
            };
        }
        case ActionTypes.ChangeAuthenticatedUserMfaSmsResponse: {
            return {
                ...state,
                changeMfaSmsState: action.payload.result,
            };
        }

        case ActionTypes.ChangeAuthenticatedUserMfaTotp: {
            return {
                ...state,
                changeMfaTotpState: 'in-flight',
            };
        }
        case ActionTypes.ChangeAuthenticatedUserMfaTotpResponse: {
            return {
                ...state,
                changeMfaTotpState: action.payload.result,
            };
        }

        default:
            return state;
    }
}
