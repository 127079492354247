import React from 'react';

import { Input, InputValidation } from './';

interface EmailProps {
    label: any;
    placeholder: string;
    setFieldValue: (field: string, value: string) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    disabled?: boolean;
    validation?: InputValidation;
    autoComplete?: string;
    name: string;
    value: string;
    required?: boolean;
}

const Email = (props: EmailProps) => (<Input
    label={props.label}
    placeholder={props.placeholder}
    type="email"
    name={props.name}
    value={props.value}
    onChange={(event: any) => {
        const value = event.currentTarget.value.trim();
        const formattedEmail = value.toLowerCase();
        event.currentTarget.value = formattedEmail;
        props.setFieldValue(props.name, formattedEmail);
    }}
    onBlur={props.onBlur}
    validation={props.validation}
    required={props.required}
/>);

export default Email;
