import styled from 'styled-components/macro';

import { colors } from '../../../styles/constants';

const backgrounds = {
    success: colors.valid,
    pending: 'rgb(23 23 23)',
} as const;

interface ApprovalsStatusBadgeProps {
    status: keyof typeof backgrounds;
    label: string;
}

const ApprovalsStatusBadge = ({ status, label }: ApprovalsStatusBadgeProps) => {
    const background = backgrounds[status];
    return <BaseBadge background={background}>
        <span>{label}</span>
    </BaseBadge>;
};

interface BaseBadgeProps {
    background: string;
}

const BaseBadge = styled.span<BaseBadgeProps>`
    font-weight: 600;
    line-height: normal;
    padding: 4px 10px;
    border-radius: 16px;
    display: inline-block;
    background: ${props => props.background};
    color: #fff;
    margin-right: 2px;
    position: relative;
    overflow: hidden;
    max-width: 100%;

    &> span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export default ApprovalsStatusBadge;
