import React from 'react';

const BackgroundAnimatedGrid = () => (
    <svg viewBox="0 0 1352 883" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.35">
            <path d="M1505.01 954.985C1164.84 741.347 1048.75 505.744 1006.68 327.01C959.736 127.535 983.947 -90.482 933.268 -104.021C900.817 -112.694 860.887 -31.2836 786.443 147.177C560.692 688.335 555.581 832.047 459.4 869.346C324.663 921.635 241.604 675.642 34.4966 680.945C-117.617 684.848 -240.707 821.813 -323.669 943.561" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1513.17 933.505C1182.58 724.466 1059.83 495.453 1014.43 327.943C963.686 140.745 987.649 -66.0106 937.497 -75.7892C905.254 -82.0805 866.075 -2.0628 794.166 170.282C575.63 693.959 568.85 839.46 473.41 874.918C337.892 924.559 258.453 660.772 49.1503 657.18C44.5869 657.102 40.0945 657.102 35.597 657.258C-112.906 662.561 -237.75 799.591 -322.488 920.59" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1521.32 912.033C1200.33 707.613 1071.01 485.582 1022.15 328.897C967.692 154.249 991.316 -41.5043 941.706 -47.5354C909.661 -51.439 871.227 27.1929 801.874 193.415C590.599 699.585 582.192 847.343 487.385 880.498C351.208 928.122 275.227 645.78 63.7696 633.418C59.1301 633.145 54.5059 632.872 49.8817 632.904C-101.076 633.971 -232.41 773.832 -321.331 897.622" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1529.48 890.558C1218.05 690.7 1082.36 476.183 1029.88 329.849C971.864 168.038 994.975 -16.9809 945.934 -19.2775C914.071 -20.7739 876.428 56.4397 809.595 216.54C605.555 705.26 595.485 854.964 501.373 886.076C364.471 931.332 291.989 630.675 78.4014 609.654C73.6606 609.186 68.9248 608.737 64.2042 608.542C-89.197 602.257 -227.032 748.063 -320.135 874.638" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1537.64 869.079C1235.79 673.84 1093.94 467.28 1037.62 330.796C976.271 182.101 998.612 7.5635 950.154 8.97531C918.468 9.89266 881.606 85.6879 817.308 239.666C620.509 710.904 608.771 862.56 515.358 891.642C377.691 934.504 308.727 615.455 93.0309 585.859C88.1886 585.208 83.3413 584.557 78.4787 584.154C-77.3562 572.17 -221.691 722.277 -318.993 851.663" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1545.8 847.609C1253.52 656.982 1105.8 458.926 1045.34 331.759C981.009 196.433 1002.24 32.1366 954.359 37.2308C922.841 40.5879 886.775 114.939 825.012 262.801C635.444 716.558 622.032 870.139 529.329 897.224C390.906 937.659 325.447 600.139 107.646 562.118C102.697 561.253 97.7485 560.374 92.7592 559.815C-65.5045 542.183 -216.37 696.532 -317.84 828.716" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1553.95 826.132C1271.25 640.098 1118.06 451.098 1053.09 332.708C986.242 210.902 1005.85 56.7347 958.588 65.4853C927.227 71.2887 891.962 144.208 832.739 285.923C650.406 722.198 635.306 877.692 543.323 902.792C404.114 940.781 342.184 584.725 122.284 538.35C117.214 537.283 112.174 536.19 107.073 535.455C-53.6094 512.215 -211.001 670.781 -316.654 805.742" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1562.11 804.639C1288.99 623.205 1145.82 424.687 1060.82 333.641C1003.62 213.69 1005.96 83.2284 962.805 93.7186C931.602 101.994 897.138 173.417 840.45 309.068C665.358 727.861 648.57 885.236 557.307 908.384C417.317 943.888 358.916 569.238 136.917 514.6C131.755 513.331 126.588 512.03 121.36 511.106C-41.7506 482.317 -205.683 645.014 -315.504 782.785" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1570.26 783.172C1306.72 606.338 1159.68 413.109 1068.55 334.601C1016.41 222.515 1013.07 106.024 967.023 121.983C935.972 132.744 902.319 202.658 848.167 332.174C680.335 733.485 661.823 892.72 571.285 913.937C430.51 946.922 375.652 553.626 151.544 490.81C146.281 489.333 141.008 487.824 135.653 486.718C-29.8866 452.425 -200.314 619.226 -314.338 759.789" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1578.43 761.693C1324.46 589.458 1173.56 401.532 1076.29 335.548C1023.51 234.379 1016.68 130.712 971.245 150.23C940.351 163.496 907.5 231.906 855.887 355.293C695.266 739.149 675.076 900.193 585.273 919.496C443.696 949.938 392.408 537.957 166.175 467.028C160.811 465.349 155.426 463.638 149.95 462.344C-18.0185 422.579 -194.962 593.447 -313.169 736.807" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1586.58 740.219C1342.19 572.584 1187.42 389.953 1084.02 336.506C1030.59 246.261 1020.3 155.444 975.471 178.488C944.734 194.285 912.679 261.167 863.602 378.405C710.221 744.76 688.322 907.638 599.259 925.062C456.881 952.914 409.174 522.215 180.806 443.251C175.335 441.358 169.844 439.458 164.251 437.968C-6.16139 392.771 -189.61 567.672 -312 713.824" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1594.73 718.739C1359.93 555.717 1201.27 378.376 1091.75 337.485C1037.66 258.164 1023.91 180.215 979.689 206.773C949.12 225.12 917.855 290.44 871.319 401.583C725.173 750.456 701.565 915.117 613.233 930.679C470.044 955.916 425.946 506.486 195.429 419.533C189.851 417.431 184.274 415.33 178.539 413.677C5.68271 363.074 -184.266 541.97 -310.84 690.919" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1602.89 697.266C1377.66 538.844 1215.15 366.799 1099.48 338.413C1044.75 270.015 1027.56 204.968 983.902 234.999C953.51 255.91 923.031 319.669 879.03 424.689C740.1 756.08 714.798 922.505 627.222 936.232C483.221 958.802 442.754 490.602 210.056 395.744C204.382 393.428 198.673 391.138 192.817 389.27C17.5061 333.318 -178.933 516.138 -309.69 667.911" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1611.04 675.781C1395.39 521.959 1229.02 355.216 1107.22 339.368C1051.84 281.893 1031.22 229.747 988.122 263.247C957.912 286.734 928.209 348.905 886.743 447.804C755.069 761.72 728.038 929.9 641.207 941.8C496.385 961.669 459.579 474.667 224.69 371.969C218.91 369.438 213.089 366.953 207.126 364.897C29.3567 303.584 -173.567 490.386 -308.538 644.93" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1619.21 654.309C1413.14 505.086 1242.89 343.639 1114.95 340.315C1058.93 293.764 1034.89 254.546 992.345 291.519C962.333 317.589 933.391 378.167 894.485 470.949C770.046 767.384 741.302 937.263 655.216 947.386C509.578 964.516 476.447 458.783 239.343 348.213C233.462 345.474 227.529 342.8 221.45 340.549C41.2005 273.914 -168.193 464.638 -307.321 621.974" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1627.36 632.829C1430.87 488.206 1256.75 332.055 1122.69 341.261C1066.01 305.634 1038.57 279.349 996.558 319.752C966.754 348.418 938.567 407.401 902.172 494.062C784.973 773.008 754.515 944.585 669.17 952.945C522.715 967.291 493.286 442.768 253.946 324.462C247.963 321.509 241.919 318.646 235.692 316.206C52.9892 244.236 -162.87 438.851 -306.181 598.958" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1635.52 611.359C1448.6 471.343 1270.62 320.507 1130.42 342.217C1073.13 317.495 1042.27 304.177 1000.78 348.014C971.199 379.276 943.748 436.653 909.893 517.171C799.93 778.636 767.758 951.885 683.158 958.495C535.887 970.01 510.179 426.731 268.578 300.644C262.493 297.469 256.338 294.424 250.015 291.789C64.797 214.602 -157.532 413.094 -305.011 576.018" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1643.68 589.858C1466.33 454.442 1284.48 308.882 1138.16 343.15C1080.19 329.363 1045.99 328.967 1005.01 376.239C975.648 410.071 948.927 465.873 917.612 540.295C814.885 784.272 780.999 959.18 697.144 964.072C549.051 972.738 527.086 410.708 283.203 276.918C277.022 273.522 270.75 270.295 264.311 267.465C76.5826 184.988 -152.17 387.339 -303.848 553.08" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1651.83 568.385C1484.07 437.614 1300.27 379.014 1145.88 344.122C1088.11 331.065 1049.71 353.81 1009.22 404.518C980.1 440.952 954.099 495.147 925.319 563.447C829.832 789.955 794.238 966.476 711.123 969.671C562.224 975.39 544.016 394.622 297.831 253.129C291.554 249.525 285.155 246.109 278.604 243.084C88.3662 155.363 -146.806 361.559 -302.686 530.071" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1659.99 546.914C1501.79 420.697 1315.71 304.057 1153.62 345.058C1095.14 359.853 1053.45 378.61 1013.44 432.759C984.568 471.795 959.282 524.377 933.037 586.562C844.781 795.588 807.488 973.71 725.109 975.226C575.409 977.984 560.973 378.506 312.466 229.348C306.082 225.523 299.572 221.944 292.899 218.711C99.9992 126.254 -141.47 335.787 -301.534 507.097" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1668.15 525.442C1519.54 403.825 1328.33 283.262 1161.35 346.013C1102.12 368.276 1057.2 403.428 1017.66 461.013C989.047 502.652 964.46 553.633 940.756 609.689C859.735 801.24 820.738 981.398 739.094 980.806C588.598 979.713 578.128 361.926 327.095 205.586C320.622 201.527 313.99 197.781 307.199 194.351C111.825 96.4282 -136.12 310.015 -300.352 484.116" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
            <path d="M1676.31 503.977C1537.28 386.966 1339.95 262.603 1169.09 346.98C911.508 474.199 889.224 989.379 753.072 986.386C597.866 982.977 594.692 312.758 321.492 169.997C123.653 66.6157 -130.792 284.255 -299.186 461.161" stroke="white" strokeWidth="0.559419" strokeMiterlimit="10"/>
        </g>
    </svg>
);

export default BackgroundAnimatedGrid;
