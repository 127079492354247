import React, { Component } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import Card from '../../../../components/Card';
import Icon from '../../../../components/Icons';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { Back, PageButton, PageButtonLink } from '../../../../components/Page';
import { H1 } from '../../../../components/Typography';
import { colors, distances } from '../../../../styles/constants';
import { State as UserState } from '../reducer';
import Details from './Details';
import NotFound from './NotFound';
import RemoveUserModal from './RemoveUserModal';
import View from '../../../../components/View';

interface MatchParams {
    accountId: string;
    userId: string;
}

export interface UserPageProps extends WithTranslation, RouteComponentProps<MatchParams> {
    getUser: (accountId: string, userId: string) => void;
    openRemoveUserModal: () => void;
    dismissRemoveUserModal: () => void;
    removeUser: (accountId: string, userId: string) => void;
    user: UserState;
}

class UserPage extends Component<UserPageProps> {
    componentDidMount() {
        const { accountId, userId } = this.props.match.params;
        this.props.getUser(accountId, userId);
    }

    componentDidUpdate(prevProps: Readonly<UserPageProps>, prevState: Readonly<{}>, snapshot?: any): void {
        const { userId } = prevProps.match.params;
        if (this.props.match.params.userId !== userId) {
            this.props.getUser(this.props.match.params.accountId, this.props.match.params.userId);
        }
    }

    render() {
        const { t, match, user, openRemoveUserModal } = this.props;
        const { accountId, userId } = match.params;

        if (user.isDeleted) {
            return <Redirect to={`/${accountId}/settings/users`} />;
        }

        return (
            <Page
                title={
                    <React.Fragment>
                        <Back />
                        <Title>{t('settings.user.title')}</Title>
                        <View ml="auto" gap={8}>
                            <PageButton className="outlined red" onClick={openRemoveUserModal} disabled={!user.user}>
                                <Icon icon="delete" fill="red" />
                                {t('settings.user.remove_user')}
                            </PageButton>
                            <PageButtonLink
                                to={`/${accountId}/settings/users/${userId}/edit`}
                                className={!user.user ? 'disabled' : ''}
                            >
                                <Icon icon="document" fill="currentColor" />
                                {t('settings.user.edit_user')}
                            </PageButtonLink>
                        </View>
                    </React.Fragment>
                }
            >
                <Card title={undefined} noPadding>
                    <Wrapper>
                        {user.user && <Details user={user.user} users={user.users} />}
                        {!user.isLoading && !user.user && <NotFound userId={userId} accountId={accountId} />}
                        {user.isLoading && <LoadingOverlay />}
                    </Wrapper>
                </Card>
                {user.user && user.isShowingRemoveModal && (
                    <RemoveUserModal
                        accountId={accountId}
                        user={user.user}
                        removeUser={this.props.removeUser}
                        dismissRemoveUserModal={this.props.dismissRemoveUserModal}
                    />
                )}
            </Page>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
    min-height: calc(${distances.ginormous} + ${distances.large});
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

export default withTranslation()(UserPage);
