import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';
import { ActionTypes, FetchCreateHookResponse, CreateHook } from './actions';
import { Subscription } from '../../../types/webhooks';

const fetchCreateHook: SideEffectFunction<CreateHook, FetchCreateHookResponse> = async action => {
    const result: FetchCreateHookResponse = await fulfill.post<Subscription>({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/hooks/subscriptions`,
        json: action.payload.newHook,
        handlers: {
            200: (hook: Subscription) => ({
                type: ActionTypes.CreateHookResponse,
                payload: {
                    hook,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.CreateHook]: fetchCreateHook,
};

export default effects;
