import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';
import {
    ActionTypes,
    FetchPayoutRule,
    FetchPayoutRuleResponse,
    DeletePayoutRule,
    DeletePayoutRuleResponse
} from './actions';
import { BillingPayoutRule } from '../../../types/billing';

const fetchPayoutRule: SideEffectFunction<FetchPayoutRule, FetchPayoutRuleResponse> = async (action) => {
    const { accountId, payoutRuleId } = action.payload;
    const result: FetchPayoutRuleResponse = await fulfill.get({
        accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/billing/payout-rules?include_deleted=true`,
        handlers: {
            200: (response: { payout_rules: BillingPayoutRule[] }) => ({
                type: ActionTypes.FetchPayoutRuleResponse,
                payload: {
                    payoutRules: response.payout_rules,
                    payoutRuleId,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const deletePayoutRule: SideEffectFunction<DeletePayoutRule, DeletePayoutRuleResponse> = async (action) => {
    const { accountId, payoutRuleId } = action.payload;
    const result: DeletePayoutRuleResponse = await fulfill.delete({
        accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/billing/payout-rules/${payoutRuleId}`,
        handlers: {
            200: () => ({
                type: ActionTypes.DeletePayoutRuleResponse,
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.FetchPayoutRule]: fetchPayoutRule,
    [ActionTypes.DeletePayoutRule]: deletePayoutRule,
};

export default effects;
