import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import cc, { CurrencyCodeRecord } from 'currency-codes';

import { numberFormat } from '../../helpers/intl';

type PayoutMoneyProps = {
    amount: string;
    currency: string;
    boldCurrency?: boolean;
    hideCurrency?: boolean;
}

const PayoutMoney = ({ amount, currency, boldCurrency, hideCurrency  }: PayoutMoneyProps) => {
    // The payout service returns monetary amounts in the common denominator
    // for the currency and as a string, while other Dintero services
    // returns amounts as integers in the lowest denominator of the currency.
    const { i18n } = useTranslation();
    const exponent = currency && cc.code(currency) ? (cc.code(currency) as CurrencyCodeRecord).digits : 2;
    const normalCurrencyAmount = parseFloat(amount);
    const amountString = numberFormat(i18n.language, { minimumFractionDigits: exponent }).format(
        normalCurrencyAmount
    );
    if (hideCurrency) {
        return <Wrapper>{amountString}</Wrapper>;
    }
    if (boldCurrency) {
        return (
            <Wrapper>
                {amountString} <Currency>{currency}</Currency>
            </Wrapper>
        );
    }
    return (
        <Wrapper>
            {amountString} {currency}
        </Wrapper>
    );
};


const Wrapper = styled.span`
    white-space: nowrap;
    font-feature-settings: 'tnum';
`;

const Currency = styled.span`
    font-size: 11px;
    font-weight: 700;
`;

export default PayoutMoney;
