import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Formik } from 'formik';
import { TFunction } from 'i18next';
import { useAccountId } from '../../../auth/useAccountId';
import { Button, ButtonGroup } from '../../../components/Buttons';
import { Checkbox, Dropdown, Input, ValidationState } from '../../../components/Forms';
import { DropdownOption } from '../../../components/Forms/Dropdown';
import Modal from '../../../components/Modal';
import { H1, Label, P } from '../../../components/Typography';
import { getValueAt } from '../../../helpers/getValueAt';
import { validate, validateEmail, validateRequired } from '../../../helpers/validation';
import { border, colors, distances, grid } from '../../../styles/constants';
import {
    InsightOpenTransactionReportFilter,
    InsightOrderReportFilter,
    PersistedInsightReportConfig
} from '../../../types/insight';
import { InsightTransactionReportFilter } from '../../../types/insight/generated';
import { PayoutDestinationOption } from '../../../util/usePayoutDestinations';
import PayoutDestinationDropdown from '../../components/PayoutDestinationDropdown';
import { HalfNHalf } from '../../payoutRules/newPayoutRule/components/common';
import ScheduleDropdown from './ScheduleDropdown';
import SourceDropdown from './SourceDropdown';

interface ValidatedInputProps {
    values: any;
    initialValues: any;
    errors: any;
    path: string;
    placeholder: string;
    label: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    prefix?: string;
    maxlength?: number;
    formatter?: (value: string) => string;
}

const ValidatedInput = (
    {
        values,
        initialValues,
        errors,
        path,
        onChange,
        onBlur,
        touched,
        required,
        prefix,
        maxlength,
        placeholder,
        label,
    }: ValidatedInputProps
) => {
    const value = getValueAt(path, values);
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const was_touched = getValueAt(path, touched);
    const validation =
        (was_touched || initialValue) && error
            ? {
                state: ValidationState.Invalid,
                message: error,
            }
            : undefined;
    return (
        <InputWrapper>
            <Input
                label={label}
                placeholder={placeholder}
                type="text"
                name={path}
                value={value || ''}
                onChange={(e) => {
                    onChange(e);
                }}
                onBlur={(e) => {
                    onChange(e);
                    onBlur(e);
                }}
                validation={validation}
                prefix={prefix}
                maxlength={maxlength}
                required={required}
            />
        </InputWrapper>
    );
};

export interface CreateConfigurationModalProps {
    accountId: string;
    dismissModal: () => void;
    createConfiguration: (accountId: string, configuration: PersistedInsightReportConfig) => Promise<boolean>;
    destinations: PayoutDestinationOption[];
    createError: undefined | 'bad_request' | 'conflict';
}

type FormikValues = {
    name: string;
    accountId: string;
    dataType: PersistedInsightReportConfig['dataType'];
    schedule: PersistedInsightReportConfig['schedule'];
    language: PersistedInsightReportConfig['language'];
    emails?: string[];
    reportFilters?: (InsightOrderReportFilter | InsightOpenTransactionReportFilter | InsightTransactionReportFilter)[];
    contentTypes?: string[];
};

const toConfiguration = (values: FormikValues): PersistedInsightReportConfig => {
    const { accountId, ...config } = values;
    const emails = config.emails || [];
    const significantEmails = emails.filter((x) => x);
    if (significantEmails.length === 0) {
        delete config.emails;
    } else {
        config.emails = significantEmails;
    }

    if (config.reportFilters) {
        config.reportFilters = config.reportFilters.filter(f => f.value !== '*');
        if (config.reportFilters.length === 0) {
            delete config.reportFilters;
        }
    }
    return config as PersistedInsightReportConfig;
};

const validateForm = (values: FormikValues, t: TFunction) => {
    const validators = [
        {
            path: 'name',
            validator: validateRequired(t('settings.reports.create.invalid.name')),
        },
        {
            path: 'accountId',
            validator: validateRequired(t('settings.reports.create.invalid.accountId')),
        },
    ];
    (values.emails || []).forEach((_, i) => {
        validators.push({
            path: `emails.${i}`,
            validator: validateEmail(t('settings.reports.create.invalid.email')),
        });
    });

    return validators.reduce((acc, elem) => {
        return validate(elem.path, elem.validator, values, acc);
    }, {});
};

const dataTypeReportFilters: {[key: string]: (InsightOrderReportFilter | InsightOpenTransactionReportFilter)[]} = {
    'checkout/transactions': [
    ],
    'checkout/transactions/open': [
        { filter: 'older_than_days', value: '28' },
    ],
    'orders/orderoperations': [
        { filter: 'operation_payout_destination', value: '*' },
    ],
};

const contentTypesFor = (dataType: PersistedInsightReportConfig['dataType']) => {
    switch (dataType) {
        case 'checkout/transactions':
            return ['application/pdf', 'text/csv'];
        case 'checkout/transactions/open':
            return ['application/pdf', 'text/csv'];
        case 'wallets/transactions':
            return ['text/csv'];
        case 'orders/orderoperations':
            return ['text/csv'];
    }
};

const reportFiltersFor = (dataType: PersistedInsightReportConfig['dataType']) => {
    return dataTypeReportFilters[dataType] || [];
};

const CreateConfigurationModal = (
    {
        accountId,
        dismissModal,
        createConfiguration,
        destinations,
        createError,
    }: CreateConfigurationModalProps
) => {
    const { t } = useTranslation();

    const initialValues: FormikValues = {
        name: '',
        accountId: accountId,
        dataType: 'checkout/transactions',
        schedule: 'weekly',
        language: 'en',
        emails: [''],
        reportFilters: reportFiltersFor('checkout/transactions'),
        contentTypes: contentTypesFor('checkout/transactions'),
    };

    const olderThanDaysOptions = [
        {
            label: t('settings.reports.create.fields.older_than_weeks', { count: 1 }),
            value: '7',
        },
        {
            label: t('settings.reports.create.fields.older_than_weeks', { count: 2 }),
            value: '14',
        },
        {
            label: t('settings.reports.create.fields.older_than_weeks', { count: 4 }),
            value: '28',
        },
        {
            label: t('settings.reports.create.fields.older_than_weeks', { count: 8 }),
            value: '56',
        },
    ] as DropdownOption[];

    const checkoutTransactionDestinations = destinations.filter(d => d.type === 'payout-destination');
    return (
        <Modal fixedWidth width="600px">
            <H1>{t('settings.reports.create.title')}</H1>
            <TextWrapper>
                <P>{t('settings.reports.create.description')}</P>
            </TextWrapper>
            {createError && <ErrorMessage>{t('settings.reports.create.error', { context: createError })}</ErrorMessage>}
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validate={(values) => {
                    return validateForm(values, t);
                }}
                onSubmit={async (values, formikBag) => {
                    const configuration = toConfiguration(values);
                    const result = await createConfiguration(values.accountId, configuration);
                    formikBag.setSubmitting(result);
                }}
            >
                {({ values, errors, handleChange, handleBlur, touched, handleSubmit, setFieldValue, isSubmitting }) => {
                    const currentOlderThanDays = (values.reportFilters || []).find(
                        (x) => x.filter === 'older_than_days'
                    )?.value || olderThanDaysOptions[0].value;
                    const currentOlderThanWeekLabel = t(
                        'settings.reports.create.fields.older_than_weeks',
                        { count: Number(currentOlderThanDays) / 7 }
                    );
                    return (
                        <form onSubmit={handleSubmit}>
                            <SpacedWrapper>
                                <SourceDropdown
                                    name="source"
                                    onChange={(value) => {
                                        // also reset schedule, and filters
                                        setFieldValue('schedule', 'weekly');
                                        setFieldValue('reportFilters', reportFiltersFor(value as PersistedInsightReportConfig['dataType']));
                                        setFieldValue('dataType', value);
                                        setFieldValue('contentTypes', contentTypesFor(value as PersistedInsightReportConfig['dataType']));
                                    }}
                                    value={values.dataType}
                                    placeholder=""
                                />
                            </SpacedWrapper>
                            {values.dataType === 'checkout/transactions/open' && (
                                <SpacedWrapper>
                                    <Dropdown
                                        name="olderThanDaysFilter"
                                        label={t(`settings.reports.create.fields.older_than_days_filter`)}
                                        onChange={(value) => {
                                            setFieldValue('reportFilters', [
                                                { filter: 'older_than_days', value: value.value },
                                            ]);
                                        }}
                                        value={{
                                            value: currentOlderThanDays,
                                            label: currentOlderThanWeekLabel,
                                        }}
                                        options={olderThanDaysOptions}
                                    />
                                </SpacedWrapper>
                            )}
                            <SpacedWrapper>
                                <ScheduleDropdown
                                    name="schedule"
                                    onChange={(value) => {
                                        setFieldValue('schedule', value);
                                    }}
                                    value={values.schedule}
                                    source={values.dataType}
                                    placeholder=""
                                />
                            </SpacedWrapper>
                            {destinations.length > 0 && values.dataType === 'orders/orderoperations' && (
                                <SpacedWrapper>
                                    <PayoutDestinationDropdown
                                        name="filters"
                                        onChange={(value) => {
                                            setFieldValue('reportFilters', [
                                                { filter: 'operation_payout_destination', value: value },
                                            ]);
                                        }}
                                        currentValue={(values.reportFilters || []).find(
                                            (x) => x.filter === 'operation_payout_destination'
                                        )?.value}
                                        placeholder=""
                                        accountId={values.accountId}
                                        destinations={destinations}
                                    />
                                </SpacedWrapper>
                            )}
                            {checkoutTransactionDestinations.length > 0 && values.dataType === 'checkout/transactions' && (
                                <SpacedWrapper>
                                    <PayoutDestinationDropdown
                                        name="filters"
                                        onChange={(value) => {
                                            setFieldValue('reportFilters', [
                                                { filter: 'payout_destination_id', value: value },
                                            ]);
                                        }}
                                        currentValue={(values.reportFilters || []).find(
                                            (x) => x.filter === 'payout_destination_id'
                                        )?.value}
                                        placeholder=""
                                        accountId={values.accountId}
                                        destinations={checkoutTransactionDestinations}
                                    />
                                </SpacedWrapper>
                            )}

                            <SpacedWrapper>
                                <Label>{t('settings.reports.create.fields.contentTypes')}</Label>
                                <HalfNHalf>
                                    {values.dataType !== 'orders/orderoperations'
                                    && values.dataType !== 'wallets/transactions' && <Checkbox
                                        label="PDF"
                                        name="contentTypes.application/pdf"
                                        onChange={(value) => {
                                            const initial = values.contentTypes?.filter(c => c !== 'application/pdf') ?? [];
                                            console.log({ value, initial });
                                            if (value.target.checked) {
                                                setFieldValue('contentTypes', [
                                                    ...initial,
                                                    'application/pdf',
                                                ]);
                                            } else {
                                                setFieldValue('contentTypes', initial.filter(c => (c !== 'application/pdf')));
                                            }
                                        }}
                                        checked={values.contentTypes?.some(c => c === 'application/pdf') ?? false}
                                    />}
                                    <Checkbox
                                        label="CSV"
                                        name="contentTypes.text/csv"
                                        onChange={(value) => {
                                            const initial = values.contentTypes?.filter(c => c !== 'text/csv') ?? [];
                                            if (value.target.checked) {
                                                setFieldValue('contentTypes', [
                                                    ...initial,
                                                    'text/csv',
                                                ]);
                                            } else {
                                                setFieldValue('contentTypes', initial.filter(c => (c !== 'text/csv')));
                                            }
                                        }}
                                        checked={values.contentTypes?.some(c => c === 'text/csv') ?? false}
                                    />
                                </HalfNHalf>
                            </SpacedWrapper>
                            <SpacedWrapper>
                                <ValidatedInput
                                    path="name"
                                    label={t('settings.reports.create.fields.name')}
                                    placeholder={t('settings.reports.create.placeholders.name')}
                                    initialValues={initialValues}
                                    values={values}
                                    errors={errors}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    touched={touched}
                                    maxlength={20}
                                    required
                                />
                            </SpacedWrapper>
                            {(values.emails || []).map((value, i) => (
                                <SpacedWrapper key={`new-config-email-${i}`}>
                                    <ValidatedInput
                                        path={`emails.${i}`}
                                        label={
                                            t('settings.reports.create.fields.email') +
                                            ((values.emails || []).length > 1 ? ` ${i + 1}` : '')
                                        }
                                        placeholder={t('settings.reports.create.placeholders.email')}
                                        initialValues={initialValues}
                                        values={values}
                                        errors={errors}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        touched={touched}
                                    />
                                </SpacedWrapper>
                            ))}
                            <SpacedWrapper>
                                <AddEmailButton
                                    type="button"
                                    onClick={() => {
                                        setFieldValue('emails', [...(values.emails || []), '']);
                                    }}
                                >
                                    {t('settings.reports.create.fields.add_email')}
                                </AddEmailButton>
                            </SpacedWrapper>

                            <ButtonGroup>
                                <Button className="alt" onClick={dismissModal}>
                                    {t('settings.reports.create.go_back')}
                                </Button>
                                <Button type="submit" disabled={isSubmitting}>
                                    {t('settings.reports.create.call_to_action')}
                                </Button>
                            </ButtonGroup>
                        </form>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default CreateConfigurationModal;

const ErrorMessage = styled.div`
    padding: ${distances.small};
    background-color: ${colors.interactiveBackgroundInvalid};
    color: ${colors.invalid};
    border: 1px solid ${colors.invalid};
    border-radius: 4px;
`;

const ButtonSpacer = styled.span`
    display: inline-block;
    width: ${distances.tiny};
`;

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;

const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.small};
`;

const InputWrapper = styled.div`
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }
`;

const AddEmailButton = styled(Button)`
    border: ${border.normal} dashed ${colors.borderDark};
    color: ${colors.primary};
    text-align: center;
    background-color: transparent;
    width: 100%;

    &:hover {
        background: ${colors.background};
        border: ${border.normal} solid ${colors.primary};
    }
    &:active {
        background: ${colors.primary};
        color: ${colors.background};
    }
`;
