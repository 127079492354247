import React from 'react';
import { useTranslation } from 'react-i18next';

import { isValidNumber } from 'libphonenumber-js';
import { Formik } from 'formik';
import {
    ErrorMessage, MfaBadge,
    MfaHeading,
    MfaHeadingAction,
    MfaHeadingTitleDescription,
    StyledButton,
    Wrapper
} from './Components';
import { H3 } from '../../../components/Typography';
import { MfaSettingContent, MfaSettingExpandCollapse, MfaSettingLabel } from '../MfaSettingExpandCollapse';
import Checkbox from '../../../components/Forms/Checkbox';
import PhoneNumber, { ValidationState } from '../../../components/Forms/PhoneNumber';
import { AuthenticatedAccountUser } from '../../../types/management-auth';
import { ChangeMfaSmsModalStates } from '../../types';
import Button from '../../../components/Buttons/Button';
import { ButtonGroup } from '../../../components/Buttons';
import Chat from './ChatIcon';
import Hr from '../../../components/Hr';

interface MfaSmsSettingsProps {
    account: AuthenticatedAccountUser['accounts'][number] | undefined;
    changeMfaSmsState: ChangeMfaSmsModalStates;
    changeMfaSms: (enabled: boolean, phone_number: string) => void;
    setCurrentMfaType: (name: string | undefined) => void;
    currentMfaType: string | undefined;
    mfaSmsEnabled: boolean;
    disabled?: boolean;
}

const phoneNumber = (values: { country_prefix: string; national_phone_number: string }): string =>
    [values.country_prefix, values.national_phone_number.replace(/\s+/g, '')].join('');

const MfaSmsSettings = ({
    changeMfaSmsState,
    changeMfaSms,
    setCurrentMfaType,
    currentMfaType,
    mfaSmsEnabled,
    disabled,
}: MfaSmsSettingsProps) => {

    const { t } = useTranslation();

    return <Formik
        validateOnMount={true}
        initialValues={{
            mfa_sms_enabled: mfaSmsEnabled,
            national_phone_number: mfaSmsEnabled ? '********' : '',
            country_prefix: mfaSmsEnabled ? '***' : '+47',
        }}
        validate={(values) => {
            const errors: { national_phone_number?: string } = {};
            const validNumber = isValidNumber(phoneNumber(values));
            if (values.mfa_sms_enabled && !validNumber) {
                errors.national_phone_number = t('change_settings.errors.phone_number.required');
            }
            return Object.values(errors).find((x) => x) ? errors : undefined;
        }}
        onSubmit={(values) => {
            changeMfaSms(values.mfa_sms_enabled, phoneNumber(values));
        }}
    >
        {({ values, errors, handleChange, handleBlur, handleSubmit, touched, isValid, setFieldValue, resetForm }) => (
            <form onSubmit={handleSubmit}>
                <Wrapper>
                    <MfaSettingExpandCollapse>
                        <MfaSettingLabel>
                            <MfaHeading>
                                <MfaHeadingTitleDescription><Chat /></MfaHeadingTitleDescription>
                                <MfaHeadingTitleDescription>
                                    <H3>
                                        {t('change_settings.mfa_sms.title')}
                                        {mfaSmsEnabled && <MfaBadge>{t('change_settings.configured')}</MfaBadge>}
                                    </H3>

                                    <p>{t('change_settings.mfa_sms.description')}</p>
                                </MfaHeadingTitleDescription>
                                <MfaHeadingAction>
                                    {mfaSmsEnabled &&
                                        <Button type="button" className="alt tiny" disabled={disabled || currentMfaType !== undefined} onClick={() => {
                                            setCurrentMfaType('sms');
                                        }}>{t('change_settings.edit')}</Button>}
                                    {!mfaSmsEnabled &&
                                        <Button type="button" className="alt tiny" disabled={disabled || currentMfaType !== undefined} onClick={() => {
                                            setCurrentMfaType('sms');
                                        }}>{t('change_settings.add')}</Button>}
                                </MfaHeadingAction>
                            </MfaHeading>
                        </MfaSettingLabel>
                        <MfaSettingContent isExpanded={currentMfaType === 'sms'}>
                            <Checkbox
                                name="mfa_sms_enabled"
                                label={t('change_settings.mfa_sms_enabled')}
                                checked={values.mfa_sms_enabled}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <PhoneNumber
                                name={'national_phone_number'}
                                label={''}
                                phoneNumber={values.national_phone_number}
                                countryPrefix={values.country_prefix}
                                countryPrefixName="country_prefix"
                                setFieldValue={setFieldValue}
                                onBlur={handleBlur}
                                disabled={!values.mfa_sms_enabled}
                                validation={
                                    touched.national_phone_number && errors.national_phone_number
                                        ? {
                                            state: ValidationState.Invalid,
                                            message: errors.national_phone_number,
                                        }
                                        : undefined
                                }
                                required={values.mfa_sms_enabled}
                            />
                            <ErrorMessage
                                visible={['error', 'invalid_phone', 'rate_limit'].includes(changeMfaSmsState)}
                            >
                                {t(`change_settings.errors.response.${changeMfaSmsState as 'error' | 'invalid_phone' | 'rate_limit'}`)}
                            </ErrorMessage>
                            <ButtonGroup>
                                <StyledButton type="button" className="alt" onClick={() => {
                                    resetForm();
                                    setCurrentMfaType(undefined);
                                }}>
                                    {t('change_settings.abort')}
                                </StyledButton>
                                <StyledButton type="submit" disabled={!isValid || changeMfaSmsState === 'in-flight'}>
                                    {t('change_settings.submit')}
                                </StyledButton>
                            </ButtonGroup>
                            <Hr />
                        </MfaSettingContent>
                    </MfaSettingExpandCollapse>
                </Wrapper>

            </form>
        )}
    </Formik>;
};


export default MfaSmsSettings;
