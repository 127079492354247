import { Client, Grant } from '../../../types/management-auth';

export enum ActionTypes {
    ResetForm = '[settings/editClientGrant] RESET_FORM',
    GetClient = '[settings/editClientGrant] GET_CLIENT',
    FetchClientResponse = '[settings/editClientGrant] FETCH_CLIENT_RESPONSE',
    FetchGrantResponse = '[settings/editClientGrant] FETCH_GRANT_RESPONSE',
    CreateGrant = '[settings/editClientGrant] UPDATE_GRANT',
    FetchCreateGrantResponse = '[settings/editClientGrant] FETCH_UPDATE_GRANT_RESPONSE',
}

export interface ResetForm {
    type: ActionTypes.ResetForm;
    payload: {};
}
export function resetForm(): ResetForm {
    return {
        type: ActionTypes.ResetForm,
        payload: {},
    };
}

export interface GetClient {
    type: ActionTypes.GetClient;
    payload: {
        accountId: string;
        clientId: string;
    };
}

export interface FetchClientResponse {
    type: ActionTypes.FetchClientResponse;
    payload: {
        client: Client;
    };
    meta: {
        cause: GetClient;
    };
}

export interface FetchGrantResponse {
    type: ActionTypes.FetchGrantResponse;
    payload: {
        grants: Grant[];
    };
    meta: {
        cause: GetClient;
    };
}

export function getClient(accountId: string, clientId: string): GetClient {
    return {
        type: ActionTypes.GetClient,
        payload: {
            accountId,
            clientId,
        },
    };
}

export interface CreateGrant {
    type: ActionTypes.CreateGrant;
    payload: {
        grant: Grant;
        accountId: string;
        clientId: string;
    };
}

export interface FetchCreateGrantResponse {
    type: ActionTypes.FetchCreateGrantResponse;
    payload: {
        grant: Grant;
    };
    meta: {
        cause: CreateGrant;
    };
}

export function createGrant(accountId: string, clientId: string, grant: Grant): CreateGrant {
    return {
        type: ActionTypes.CreateGrant,
        payload: {
            accountId,
            clientId,
            grant,
        },
    };
}

export type Actions =
    | ResetForm
    | GetClient
    | FetchClientResponse
    | FetchGrantResponse
    | CreateGrant
    | FetchCreateGrantResponse;
