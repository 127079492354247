import { CORE_API_HOSTNAME } from '../../../env';
import fulfill from '../../../fulfill';
import { Dispatch } from 'redux';
import { sortElements } from '../../helpers';
import { Client, Grant } from '../../../types/management-auth';
import { createSwitchlessReducer } from '../../../util/switchlessReducer';
import namespace from './namespace';

export interface State {
    isLoading: boolean;
    clients: Client[];
    grants: { [clientId: string]: Grant[] };

    status: number;
};

export const initialState: State = {
    isLoading: false,
    clients: [],
    grants: {},
    status: -1,
};

type Response = { clients: Client[]; grants: { [clientId: string]: Grant[] }; status: number };

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        getClients: (state: State) => {
            return {
                ...state,
                isLoading: true,
                status: -1,
            };
        },
        fetchClientsResponse: (state: State, { clients, grants, status }: Response) => {
            return {
                ...state,
                isLoading: false,
                clients,
                grants,
                status,
            };
        },
    },
});

export const createClientsActions = (dispatch: Dispatch) => {
    const fetchClients = async (account_id: string) => {
        dispatch(actions.getClients());
        const clients: Client[] = await fulfill.get({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/auth/clients`,
            handlers: {
                200: (clients: Client[]) => clients,
            },
        });

        const clientIds = clients.map(client => client.client_id).join(',');
        const grants: Grant[] = await fulfill.get({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/auth/client-grants?client_id=${clientIds}`,
            handlers: {
                200: (grants: Grant[]) => grants,
            },
        });
        const grantsByClientId = grants.reduce((acc: { [clientId: string]: Grant[] }, grant) => {
            if (grant.client_id in acc) {
                return {
                    ...acc,
                    [grant.client_id]: [...acc[grant.client_id], grant],
                };
            }
            return {
                ...acc,
                [grant.client_id]: [grant],
            };
        }, {});
        dispatch(actions.fetchClientsResponse({
            clients: sortElements(clients),
            grants: grantsByClientId,
            status: 200,
        }));
    };
    return {
        fetchClients,
    };
};
