import { ReactNode, Suspense } from 'react';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled, { keyframes } from 'styled-components/macro';

import { border, colors, defaultRadius, distances, globalColumnMaxWidth, globalMobileBreakpoint, globalPageMaxWidth, palette } from '../styles/constants';
import Icon from './Icons';
import { H1 } from './Typography';

interface PageProps {
    hasEnvFrame?: boolean;
    children: ReactNode;
    title: ReactNode;
    compress?: boolean;
}

const Page = (props: PageProps) => {
    const TitleWrapper = typeof props.title === 'string' ? AlignedTitle : AlignedDiv;
    return (
        <ChildrenWrapper>
            <PageWrapper data-compress={props.compress}>
                <Suspense fallback={null}>
                    <TitleWrapper data-compress={props.compress}>{props.title}</TitleWrapper>
                    {props.children}
                </Suspense>
            </PageWrapper>
        </ChildrenWrapper>
    );
};

const ChildrenWrapper = styled.div`
    display: flex;
    flex: 1;
    position: relative;
    width: 100%;
    @media (max-width: ${globalMobileBreakpoint}px) {
        flex-direction: column;
    }
`;

const PageWrapper = styled.section`
    flex: 1;
    box-sizing: border-box;
    padding: 24px 32px 32px 32px;

    display: flex;
    flex-direction: column;

    gap: 16px;

    width: 100%;

    & > * {
        margin: 0 auto;
        width: 100%;
        @media (max-width: ${globalPageMaxWidth}px) {
            max-width: 100%;
        }
    }

    @media (max-width: ${globalMobileBreakpoint}px) {
        padding: 8px;
        padding-block: 40px;
    }

    &[data-compress='true'] {
        max-width: ${globalColumnMaxWidth}px;
        margin: 0 auto;
    }
`;

const HeaderLink = styled.span`
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin-right: ${distances.small};
    color: ${colors.primary};

    path {
        fill: ${colors.primary};
    }

    &:hover {
        path {
            fill: ${colors.primaryHover};
        }
    }
`;

const backFrames = keyframes`
  from {
    left: 0;
  }

  to {
    left: -10px;
  }
`;

const HeaderBackLink = styled(HeaderLink)`
    &:hover {
        animation: ${backFrames} 1000ms ease-out infinite;
        animation-delay: 2s;
    }
`;

const dismissFrames = keyframes`
  from {
    transform: scale(0.5);
  }

  to {
    transform: scale(1);
  }
`;

const HeaderDismissLink = styled(HeaderLink)`
    &:hover {
        animation: ${dismissFrames} 1000ms ease-out infinite;
        animation-delay: 2s;
    }
`;

const _Back = ({ to, history }: { to?: string } & RouteComponentProps) => (
    <HeaderBackLink onClick={() => to ? history.push(to) : history.goBack()}>
        <Icon icon="arrow_left" />
    </HeaderBackLink>
);
export const Back = withRouter(_Back);

interface CustomBackProps {
    onClick?: ((event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void) | undefined;
}
export const CustomBack = (props: CustomBackProps) => (
    <HeaderBackLink onClick={props.onClick}>
        <Icon icon="arrow_left" />
    </HeaderBackLink>
);

export const Title = styled(H1)`
    display: inline-block;

    /* Heading/H6/Medium/Desktop */
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const AlignedTitle = styled(Title)`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: ${globalPageMaxWidth}px) {
        max-width: 100%;
    }

    @media (max-width: ${globalMobileBreakpoint}px) {
        padding-left: ${distances.small};
        padding-right: ${distances.small};
    }

    & > a {
        margin-left: ${distances.small};
        &:first {
            margin-left: auto;
        }
    }

    & > .page-button {
        margin-left: ${distances.small};
        &:first-of-type:not(:last-of-type) {
            margin-left: auto;
        }
    }

    &[data-compress='true'] {
        max-width: ${globalColumnMaxWidth}px;
    }
`;

const AlignedDiv = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    @media (max-width: ${globalPageMaxWidth}px) {
        max-width: 100%;
    }

    @media (max-width: ${globalMobileBreakpoint}px) {
        display: flex;
        padding-left: ${distances.tiny};
        padding-right: ${distances.tiny};
    }

    & > .page-button {
        margin-left: ${distances.small};
        &:first-of-type {
            margin-left: auto;
        }
    }

    &[data-compress='true'] {
        max-width: ${globalColumnMaxWidth}px;
    }
`;

interface DismissProps extends RouteComponentProps {
    handleClick?: () => void;
}

export const Dismiss = withRouter<DismissProps, React.FC<DismissProps>>(({ history, handleClick }) => {
    const onClick = handleClick ? handleClick : () => history.goBack();
    return (
        <HeaderDismissLink onClick={onClick}>
            <Icon icon="close" />
        </HeaderDismissLink>
    );
});

export const PageButton = styled.button.attrs(props => ({
    className: ['page-button', props.className].filter(Boolean).join(' '),
}))`
    all: unset;
    padding: 6px 12px;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: ${defaultRadius};
    background: ${colors.primary};
    color: white;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s ease-in-out;
    margin: 0;
    max-height: 32px;

    border: ${border.normal} solid ${palette.primary[500]};

    span {
        display: contents;
    }

    svg {
        height: 16px;
        width: 16px;
    }

    &:hover {
        background: ${palette.primary[600]};
        border-color: ${palette.primary[600]};
    }

    &:active {
        color: ${palette.primary[200]};
    }

    &:disabled {
        background: ${palette.primary[300]};
        border-color: ${palette.primary[300]};
        cursor: not-allowed;
    }


    &.red {
        background: ${palette.destructive[500]};
        border-color: ${palette.destructive[500]};
        &:hover {
            background: ${palette.destructive[600]};
            border-color: ${palette.destructive[600]};
        }
        &:active {
            color: ${palette.destructive[200]};
            border-color: ${palette.destructive[200]};
        }
        &:disabled {
            background: ${palette.destructive[300]};
            border-color: ${palette.destructive[300]};
        }
    }

    &.outlined {
        background: transparent;
        color: ${palette.primary[500]};
        border: ${border.normal} solid ${palette.primary[500]};

        &:hover {
            background: transparent;
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:active {
            background: transparent;
            color: ${palette.primary[400]};
            border: ${border.normal} solid ${palette.primary[400]};
        }

        &:disabled {
          opacity: .5;
          pointer-events: none;
        }

        &.red {
            background: transparent;
            color: ${palette.destructive[500]};
            border: ${border.normal} solid ${palette.destructive[500]};

            &:hover {
                background: transparent;
                border: ${border.normal} solid ${palette.destructive[600]};
            }
            &:active {
                background: transparent;
                color: ${palette.destructive[700]};
                border: ${border.normal} solid ${palette.destructive[700]};
            }
        }
    }
`;

export const PageButtonLink = styled(Link).attrs(props => ({
    className: ['page-button', props.className].filter(Boolean).join(' '),
}))`
    padding: 6px 12px;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: ${defaultRadius};
    background: ${colors.primary};
    color: white;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    border: ${border.normal} solid ${palette.primary[500]};
    transition: all 0.15s ease-in-out;
    max-height: 32px;

    span {
        display: contents;
    }

    svg {
        height: 16px;
        width: 16px;
    }
    &:hover {
        background: ${palette.primary[600]};
        border-color: ${palette.primary[600]};
    }

    &:active {
        color: ${palette.primary[200]};
    }

    &:disabled {
        background: ${palette.primary[300]};
        border-color: ${palette.primary[300]};
        cursor: not-allowed;
    }


    &.red {
        background: ${palette.destructive[500]};
        border-color: ${palette.destructive[500]};
        &:hover {
            background: ${palette.destructive[600]};
            border-color: ${palette.destructive[600]};
        }
        &:active {
            color: ${palette.destructive[200]};
            border-color: ${palette.destructive[200]};
        }
        &:disabled {
            background: ${palette.destructive[300]};
            border-color: ${palette.destructive[300]};
        }
    }

    &.outlined {
        background: transparent;
        color: ${palette.primary[500]};
        border: ${border.normal} solid ${palette.primary[500]};

        &:hover {
            background: transparent;
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:active {
            background: transparent;
            color: ${palette.primary[400]};
            border: ${border.normal} solid ${palette.primary[400]};
        }

        &:disabled {
        opacity: .5;
        pointer-events: none;
        }

        &.red {
            background: transparent;
            color: ${palette.destructive[500]};
            border: ${border.normal} solid ${palette.destructive[500]};

            &:hover {
                background: transparent;
                border: ${border.normal} solid ${palette.destructive[600]};
            }
            &:active {
                background: transparent;
                color: ${palette.destructive[700]};
                border: ${border.normal} solid ${palette.destructive[700]};
            }
        }
    }
`;

export const PageButtonExternalLink = styled.a.attrs(props => ({
    className: ['page-button', props.className].filter(Boolean).join(' '),
}))`
    padding: 6px 12px;
    justify-content: center;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: ${defaultRadius};
    background: ${colors.primary};
    color: white;
    text-decoration: none;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    max-height: 32px;

    border: ${border.normal} solid ${palette.primary[500]};
    transition: all 0.15s ease-in-out;
    span {
        display: contents;
    }

    svg {
        height: 16px;
        width: 16px;
    }

    &:hover {
        background: ${palette.primary[600]};
        border-color: ${palette.primary[600]};
    }

    &:active {
        color: ${palette.primary[200]};
    }

    &:disabled {
        background: ${palette.primary[300]};
        border-color: ${palette.primary[300]};
        cursor: not-allowed;
    }


    &.red {
        background: ${palette.destructive[500]};
        border-color: ${palette.destructive[500]};
        &:hover {
            background: ${palette.destructive[600]};
            border-color: ${palette.destructive[600]};
        }
        &:active {
            color: ${palette.destructive[200]};
            border-color: ${palette.destructive[200]};
        }
        &:disabled {
            background: ${palette.destructive[300]};
            border-color: ${palette.destructive[300]};
        }
    }

    &.outlined {
        background: transparent;
        color: ${palette.primary[500]};
        border: ${border.normal} solid ${palette.primary[500]};

        &:hover {
            background: transparent;
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:focus {
            border: ${border.normal} solid ${palette.primary[400]};
        }
        &:active {
            background: transparent;
            color: ${palette.primary[400]};
            border: ${border.normal} solid ${palette.primary[400]};
        }

        &:disabled {
        opacity: .5;
        pointer-events: none;
        }

        &.red {
            background: transparent;
            color: ${palette.destructive[500]};
            border: ${border.normal} solid ${palette.destructive[500]};

            &:hover {
                background: transparent;
                border: ${border.normal} solid ${palette.destructive[600]};
            }
            &:active {
                background: transparent;
                color: ${palette.destructive[700]};
                border: ${border.normal} solid ${palette.destructive[700]};
            }
        }
    }
`;

export default Page;
