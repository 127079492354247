import React from 'react';
import { Route } from 'react-router-dom';

import { Clients } from './clients';
import { NewClient, NewCheckoutClient, SelectClientForm } from './newClient';
import { ClientDetails } from './clientDetails';
import { NewClientGrant } from './newClientGrant';

const ClientRoutes = [
    <Route key="clients" path="/:accountId/settings/clients" exact component={Clients} />,
    <Route key="new_client" path="/:accountId/settings/clients/new" exact component={NewClient} />,
    <Route
        key="new_checkout_client"
        path="/:accountId/settings/clients/new-checkout"
        exact
        component={NewCheckoutClient}
    />,
    <Route key="select_client_form" path="/:accountId/settings/clients/select" exact component={SelectClientForm} />,
    <Route key="client_details" path="/:accountId/settings/clients/:clientId" exact component={ClientDetails} />,
    <Route
        key="new_client_grant"
        path="/:accountId/settings/clients/:clientId/grants/new"
        exact
        component={NewClientGrant}
    />,
];

export default ClientRoutes;
