import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { distances } from '../../../styles/constants';
import { H3 } from '../../../components/Typography';
import { Account } from '../../../types/management-auth';
import { Json } from '../../../components/Formatters';
import Card from '../../../components/Card';
import CardArea from './CardArea';
import Hr from '../../../components/Hr';
import differenceInDays from 'date-fns/differenceInDays';
import { Button } from '../../../components/Buttons';

export interface PaymentConnectionsSignupProps extends WithTranslation {
    account: Account;
    downloadSignupFile: () => void;
    partnerId: string;
}

const hasS3file = (account: Account) => {
    const signup = account.connections?.common?.signup;
    const createdAt = account.connections?.common?.created_at;
    if (signup && createdAt) {
        const createdAtDate = new Date(createdAt);
        const now = new Date();
        return differenceInDays(now, createdAtDate) < 30;
    }
    return false;
};

const PaymentConnectionSignup = (
    {
        t,
        account,
        partnerId,
        downloadSignupFile,
    }: PaymentConnectionsSignupProps
) => {
    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('partner.merchant.payment_connection_signup')}</H3>
            <Hr />
            <CardArea>
                <Json data={account.connections?.common?.signup} />
            </CardArea>
            {hasS3file(account) && partnerId === 'P00000000' && (
                <>
                    <br />
                    <Button onClick={downloadSignupFile}>
                        {t('partner.merchant.payment_connection_signup_download')}
                    </Button>
                </>
            )}
        </Card>
    );
};

export default withTranslation()(PaymentConnectionSignup);
