import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CHECKOUT_API_HOSTNAME, CORE_API_HOSTNAME } from '../../../env';
import { ActionTypes, FetchCompletePayExConnectionResponse, CompletePayExConnection, PayExApiError, FetchPayExCreateCredentialsResponse, PayExCreateCredentials } from './actions';

const fetchCompletePayExConnection: SideEffectFunction<
    CompletePayExConnection,
    FetchCompletePayExConnectionResponse
> = async action => {
    const result: FetchCompletePayExConnectionResponse = await fulfill.post({
        accountId: action.payload.accountId,
        json: action.payload.data,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${
            action.payload.accountId
        }/management/settings/connections/payex/payex.creditcard/${action.payload.userId}`,
        handlers: {
            202: (response: {}) => ({
                type: ActionTypes.FetchCompletePayExConnectionResponse,
                payload: {
                    response,
                },
                meta: {
                    cause: action,
                },
            }),
            500: returnPayExApiError,
            501: returnPayExApiError,
            502: returnPayExApiError,
            503: returnPayExApiError,
            504: returnPayExApiError,
        },
    });
    return result;
};

const fetchCreatePayExCredentials: SideEffectFunction<
    PayExCreateCredentials,
    FetchPayExCreateCredentialsResponse
> = async action => {
    const result: FetchPayExCreateCredentialsResponse = await fulfill.put({
        accountId: action.payload.accountId,
        json: action.payload.data,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/gateways/payex/credentials`,
        handlers: {
            200: (response: {}) => ({
                type: ActionTypes.FetchPayExCreateCredentialsResponse,
                payload: {
                    response,
                },
                meta: {
                    cause: action,
                },
            }),
            201: (response: {}) => ({
                type: ActionTypes.FetchPayExCreateCredentialsResponse,
                payload: {
                    response,
                },
                meta: {
                    cause: action,
                },
            }),
            400: returnPayExApiError,
            401: returnPayExApiError,
            403: returnPayExApiError,
            409: returnPayExApiError,
            500: returnPayExApiError,
        },
    });
    return result;
};

const returnPayExApiError = (error?: any): PayExApiError => ({
    type: ActionTypes.PayExApiError,
    payload: {
        ...(error || {}),
    },
});

const effects = {
    [ActionTypes.CompletePayExConnection]: fetchCompletePayExConnection,
    [ActionTypes.PayExCreateCredentials]: fetchCreatePayExCredentials,
};

export default effects;
