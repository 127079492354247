import { useState } from 'react';
import { Trans, WithTranslation } from 'react-i18next';
import { useActions } from '../../../../Actions';
import { Button } from '../../../../components/Buttons';
import { Dd, Dl, Dt } from '../../../../components/DescriptionList/Horizontal';
import { DateTime } from '../../../../components/Formatters';
import { H2, P } from '../../../../components/Typography';
import View from '../../../../components/View';
import { distances } from '../../../../styles/constants';
import { Account, VippsConnection } from '../../../../types/management-auth';
import {
    ConnectionAside,
    ConnectionsStatus,
    ConnectionText,
    Details,
    Flex,
    PaymentConnection,
    ToggleDetails
} from './styledListItemComponents';

export interface VippsConnectionListItemProps extends WithTranslation {
    vipps: VippsConnection | undefined;
    disabled: boolean;
    account: Account;
    accountId: string;
}

const VippsConnectionListItem = (
    {
        t,
        vipps,
        disabled,
        account,
    }: VippsConnectionListItemProps
) => {
    const { openVippsModal } = useActions('connections.vipps');
    const [vippsDetails, setVippsDetails] = useState(false);
    return (
        <PaymentConnection disabled={disabled}>
            <View alignItem="flex-start" gap={distances.small}>
                <H2>{t(`settings.payment_connections.vipps.name`)}</H2>
                {vipps && vipps.status && (
                    <ConnectionsStatus status={vipps.status}>
                        {t(`settings.payment_connections.status.${vipps.status}`)}
                    </ConnectionsStatus>
                )}
            </View>
            <Flex>
                <ConnectionText>
                    <Trans i18nKey={'settings.payment_connections.vipps.description'}>
                        <P className={'margin-bottom'}>Apply for Vipps payments.</P>
                        <P>
                            If you have a previous Vipps agreement, you still need to apply to get Vipps through Dintero
                        </P>
                    </Trans>
                </ConnectionText>
                <ConnectionAside>
                    {!vipps && (
                        <Button
                            disabled={disabled}
                            onClick={() => {
                                openVippsModal(account);
                            }}
                            className="stretch"
                        >
                            {t(`settings.payment_connections.vipps.call_to_action`)}
                        </Button>
                    )}
                    {vipps && !vipps.signup?.callback_at && (
                        <div>
                            <br />
                            <Button onClick={() => openVippsModal(account)} className="alt">
                                {t(`settings.payment_connections.vipps.complete_callback`)}
                            </Button>
                        </div>
                    )}
                </ConnectionAside>
            </Flex>
            {vipps && (
                <>
                    <ToggleDetails onClick={() => setVippsDetails(!vippsDetails)}>
                        {vippsDetails
                            ? t('settings.payment_connections.collapse_details')
                            : t('settings.payment_connections.expand_details')}
                    </ToggleDetails>
                    <Details className={vippsDetails ? 'open' : 'closed'}>
                        {vipps.signup?.id && <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.external_enrollment_id')}</Dt>
                            <Dd>{vipps.signup.id}</Dd>
                        </Dl>}
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.signup_url')}</Dt>
                            <Dd>
                                {vipps.signup?.url && (
                                    <a href={vipps.signup.url} target="_blank" rel="noopener noreferrer">
                                        {vipps.signup.url}
                                    </a>
                                )}
                            </Dd>
                        </Dl>
                        {vipps.signup?.created_at && <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.created_at')}</Dt>
                            <Dd>
                                <DateTime isoString={vipps.signup?.created_at} />
                            </Dd>
                        </Dl>}
                        <Dl>
                            <Dt doubleColumn>{t('settings.payment_connections.fields.callback_at')}</Dt>
                            <Dd>
                                {vipps.signup?.callback_at ? (
                                    <DateTime isoString={vipps.signup.callback_at} />
                                ) : (
                                    <em>{t('settings.payment_connections.fields.no_callback_yet')}</em>
                                )}
                            </Dd>
                        </Dl>
                    </Details>
                </>
            )}
        </PaymentConnection>
    );
};
export default VippsConnectionListItem;
