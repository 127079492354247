import React, { useState } from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Button, ButtonExternalLink, ButtonGroup } from '../../../../components/Buttons';
import { Dd, Dl, Dt } from '../../../../components/DescriptionList/Horizontal';
import { Checkbox } from '../../../../components/Forms';
import Modal from '../../../../components/Modal';
import { H1, P } from '../../../../components/Typography';
import { copy } from '../../../../helpers/clipboard';
import { clientCsv } from '../../../../helpers/csvExport';
import { clientPaste } from '../../../../helpers/formatPaste';
import { distances } from '../../../../styles/constants';
import { Client, Grant, HasSecretClientResponse } from '../../../../types/management-auth';
import { Warning } from './Warning';

export interface RotateSecretModalProps extends WithTranslation {
    accountId: string;
    client: Client;
    grants: Grant[];
    rotated: HasSecretClientResponse | undefined;
    dismissRotateSecretModal: () => void;
    rotateSecret: (account_id: string, client_id: string) => void;
    isSecretSafe: boolean;
    toggleSecretIsSafe: (value: boolean) => void;
}

const RotateSecretModal = (
    {
        t,
        client,
        grants,
        rotated,
        accountId,
        dismissRotateSecretModal,
        rotateSecret,
        isSecretSafe,
        toggleSecretIsSafe,
    }: RotateSecretModalProps
) => {
    const nonPrefixedAccountId = accountId.substr(1);
    const blob = rotated
        ? new Blob([clientCsv(nonPrefixedAccountId, rotated, grants)], {
            type: 'text/csv; charset=utf-8',
        })
        : null;
    const blobHref = blob ? URL.createObjectURL(blob) : '';
    const isRootAccount = accountId === 'P00000000';
    const [rootAccountConfirm, setRootAccountConfirm] = useState(false);

    return (
        <Modal>
            {!rotated && (
                <React.Fragment>
                    <H1>{t('settings.client_rotate_secret.title_confirm', { name: client.name })}</H1>
                    {isRootAccount &&
                        <Warning flash={rootAccountConfirm} />
                    }

                    <SpacedWrapper>
                        <P>{t('settings.client_rotate_secret.description')}</P>
                    </SpacedWrapper>
                    <ButtonGroup>
                        {
                            (isRootAccount && !rootAccountConfirm) &&
                            <Button
                                onClick={() => {
                                    setRootAccountConfirm(true);
                                }}
                            >
                                Yes I confirm that I want to rotate the secret for the Dintero root account API key
                            </Button>
                        }
                        <ButtonGroup>
                            <Button className="alt" onClick={dismissRotateSecretModal}>
                                {t('settings.client_rotate_secret.go_back')}
                            </Button>
                            {(!isRootAccount || rootAccountConfirm) && <Button
                                onClick={() => {
                                    rotateSecret(accountId, client.id || '');
                                }}
                            >
                                {t('settings.client_rotate_secret.rotate_secret_call_to_action')}
                            </Button>}
                        </ButtonGroup>
                    </ButtonGroup>
                </React.Fragment>
            )}
            {rotated && (
                <React.Fragment>
                    <H1>{t('settings.client_rotate_secret.title_success', { name: rotated.name })}</H1>
                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.client_secret_modal.description">
                                Before moving on you need to save the <em>client secret</em> in a secure location, like
                                a password vault.
                            </Trans>
                        </P>
                    </SpacedWrapper>
                    <SpacedWrapper>
                        <P>
                            <Trans i18nKey="settings.client_secret_modal.never_revealed_again">
                                {'When this modal is closed the client secret will '}
                                <strong>never be revealed again</strong>.
                            </Trans>
                        </P>
                    </SpacedWrapper>
                    <SpacedWrapper>
                        <Dl>
                            <Dt doubleColumn>{t('settings.client_secret_modal.fields.account_id')}</Dt>
                            <Dd>
                                <code>{nonPrefixedAccountId}</code>
                            </Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>{t('settings.client_secret_modal.fields.client_id')}</Dt>
                            <Dd>
                                <code>{rotated.client_id}</code>
                            </Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>{t('settings.client_secret_modal.fields.client_secret')}</Dt>
                            <Dd>
                                <code>{rotated.client_secret}</code>
                            </Dd>
                        </Dl>
                        <ButtonGroup>
                            <Button
                                className="alt"
                                onClick={() => {
                                    const secretText = clientPaste(nonPrefixedAccountId, rotated, grants);
                                    copy(secretText);
                                }}
                            >
                                {t('settings.client_secret_modal.button_copy')}
                            </Button>
                            <ButtonExternalLink
                                className="alt"
                                href={blobHref}
                                download={`dintero_credentials_${nonPrefixedAccountId}_client_${client.client_id}.csv`}
                            >
                                {t('settings.client_secret_modal.button_download_csv_file')}
                            </ButtonExternalLink>
                        </ButtonGroup>
                    </SpacedWrapper>
                    <SpacedWrapper>
                        <Checkbox
                            name="client_secret_is_safe"
                            label={
                                <Trans i18nKey="settings.client_secret_modal.key_is_safe">
                                    Yes, the <em>client secret</em> is saved in a secure storage.
                                </Trans>
                            }
                            checked={isSecretSafe}
                            onChange={(event) => {
                                toggleSecretIsSafe(event.target.checked);
                            }}
                        />
                    </SpacedWrapper>
                    <Button disabled={!isSecretSafe} onClick={dismissRotateSecretModal}>
                        {t('settings.client_secret_modal.button_ok')}
                    </Button>
                </React.Fragment>
            )}
        </Modal>
    );
};

export default withTranslation()(RotateSecretModal);

const SpacedWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
