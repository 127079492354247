import { connect } from 'react-redux';

import { getCheckoutConfiguration, createPaymentProfile, resetForm } from '../actions';
import { State } from '../../../../reducer';
import { getPaymentProfileSelector } from '../selectors';
import { getAccountUser } from '../../../../auth/accessToken/selectors';

import NewPaymentProfileForm from '../components/NewPaymentProfileForm';

const mapStateToProps = (state: State) => ({
    paymentProfile: getPaymentProfileSelector(state),
    accountUser: getAccountUser(state),
});

const mapDispatchToProps = {
    createPaymentProfile,
    resetForm,
    getCheckoutConfiguration,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewPaymentProfileForm);
