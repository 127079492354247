import fulfill from '../../fulfill';
import _pick from 'lodash/pick';
import { SideEffectFunction } from '../../sideEffects';
import {
    ActionTypes,
    GetMerchant,
    MerchantResponse,
    MerchantNotFoundResponse,
    MerchantBillingResponse,
    EditMerchant,
    DownloadSignupFile,
    MerchantCheckoutResponse,
    MerchantCheckoutNotFoundResponse,
    MerchantApprovalsPaymentsResponse
} from './actions';
import { CHECKOUT_API_HOSTNAME, CORE_API_HOSTNAME } from '../../env';
import { Account } from '../../types/management-auth';
import { BillingSubscription } from '../../types/billing';
import { CheckoutConfig } from '../../types/checkout';
import { ApprovalsPaymentResponse } from '../../types/management-auth/generated';

type FetchMerchantResult = MerchantResponse | MerchantNotFoundResponse;
type FetchMerchantCheckoutResult = MerchantCheckoutResponse | MerchantCheckoutNotFoundResponse;

const fetchSubAccountCheckoutConfig: SideEffectFunction<GetMerchant, FetchMerchantCheckoutResult> = async (action) => {
    const result: FetchMerchantCheckoutResult = await fulfill.get({
        accountId: action.payload.subAccountId,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/checkout`,
        handlers: {
            200: (checkout: CheckoutConfig) => ({
                type: ActionTypes.MerchantCheckoutResponse,
                payload: {
                    checkout,
                },
                meta: {
                    cause: action,
                },
            }),
            404: () => ({
                type: ActionTypes.MerchantCheckoutNotFoundResponse,
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchSubAccount: SideEffectFunction<GetMerchant, FetchMerchantResult> = async (action) => {
    const { accountId, subAccountId } = action.payload;
    const result: MerchantResponse = await fulfill.get({
        accountId: accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/accounts/${subAccountId}`,
        handlers: {
            200: (account: Account) => ({
                type: ActionTypes.MerchantResponse,
                payload: {
                    merchant: account,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchSubAccountSubscriptions: SideEffectFunction<GetMerchant, MerchantBillingResponse> = async (action) => {
    const result: MerchantBillingResponse = await fulfill.get({
        accountId: action.payload.subAccountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.subAccountId}/billing/subscriptions`,
        handlers: {
            200: (subscriptions: BillingSubscription[]) => ({
                type: ActionTypes.MerchantBillingResponse,
                payload: {
                    subscriptions,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchEditMerchant: SideEffectFunction<EditMerchant, FetchMerchantResult> = async (action) => {
    const prefix = `${action.payload.account.livemode ? 'P' : 'T'}`;
    const accountId = `${prefix}${action.payload.account.account_id || ''}`;
    const accountUpdate = _pick(
        action.payload.account,
        'applicant',
        'company',
        'billing',
        'account_manager',
        'sale_channel',
        'price_package'
    );
    const result: FetchMerchantResult = await fulfill.put({
        accountId: action.payload.partnerId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.partnerId}/management/accounts/${accountId}`,
        json: accountUpdate,
        handlers: {
            200: (account: Account) => ({
                type: ActionTypes.MerchantResponse,
                payload: {
                    merchant: account,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const getFilenName = (path: string) => {
    const [lastTrail] = path.split('/').reverse();
    const [withoutParams] = lastTrail.split('?');
    return withoutParams;
};

const fetchDownloadSignupFile: SideEffectFunction<DownloadSignupFile, void> = async (action) => {
    const { subAccountId } = action.payload;
    await fulfill.post({
        accountId: subAccountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${subAccountId}/management/settings/connections/common/signup`,
        handlers: {
            200: (payload: any) => {
                const filename = getFilenName(payload.rel);
                const a = document.createElement('a');
                a.setAttribute('download', filename);
                a.setAttribute('target', '_blank');
                a.style.display = 'none';
                a.href = payload.rel;
                const addedElement = document.body.appendChild(a);
                a.click();
                document.body.removeChild(addedElement);
            },
        },
    });
};


const fetchSubAccountApprovalsPayments: SideEffectFunction<GetMerchant, MerchantApprovalsPaymentsResponse> = async (action) => {
    const result: MerchantApprovalsPaymentsResponse = await fulfill.get({
        accountId: action.payload.subAccountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.subAccountId}/management/settings/approvals/payments`,
        handlers: {
            200: ({ payments }: {payments: ApprovalsPaymentResponse[]}) => ({
                type: ActionTypes.MerchantApprovalsPaymentsResponse,
                payload: {
                    approvals: payments,
                },
                meta: {
                    cause: action,
                },
            }),
            404: () => ({
                type: ActionTypes.MerchantApprovalsPaymentsResponse,
                payload: {
                    approvals: [],
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};


const effects = {
    [ActionTypes.GetMerchant]: [
        fetchSubAccount,
        fetchSubAccountSubscriptions,
        fetchSubAccountCheckoutConfig,
        fetchSubAccountApprovalsPayments,
    ],
    [ActionTypes.EditMerchant]: [fetchEditMerchant],
    [ActionTypes.DownloadSignupFile]: fetchDownloadSignupFile,
};

export default effects;
