export enum ActionTypes {
    PutOverride = '[settings/locations] PUT_OVERRIDE',
    PutOverrideResponse = '[settings/locations] PUT_OVERRIDE_RESPONSE',
}

export interface PutOverride {
    type: ActionTypes.PutOverride;
    payload: {
        accountId: string;
        storeId: string;
        gateway: string;
        credentials: Record<string, string | undefined>;
    };
}

export interface PutOverrideResponse {
    type: ActionTypes.PutOverrideResponse;
    meta: {
        cause: PutOverride;
    };
    payload: {
        gateway: string;
    };
}

export const putOverride = (accountId: string, storeId: string, gateway: string, credentials: Record<string, string | undefined>): PutOverride => {
    return {
        type: ActionTypes.PutOverride,
        payload: {
            accountId,
            storeId,
            credentials,
            gateway,
        },
    };
};

export type Actions =
    | PutOverride
    | PutOverrideResponse;
