import { FormEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import type { ValidationSchemaResult } from '../../../util/useValidatedSchema';
import type { ConfigBankAccountDraft } from '../../../payout/types';
import { H6 } from '../../../components/Typography';
import Grid, { GridItem } from '../../../components/Grid';
import { Input, ValidationState } from '../../../components/Forms';
import { InputProps } from '../../../components/Forms/Input';

type BankAccountFormProps = {
    value: ConfigBankAccountDraft;
    onChange: (name: string, value: any) => void;
    onTouched: (name: string) => void;
    isTouched: (name: string) => boolean;
    errors: ValidationSchemaResult<ConfigBankAccountDraft>;
    disabled?: boolean;
    readOnly?: boolean;
    hideOwner?: boolean;
    disabledFields?: (keyof ConfigBankAccountDraft)[];
};

const BankAccountForm = ({
    value,
    onChange: propOnChange,
    onTouched: propOnTouched,
    isTouched,
    errors,
    disabled,
    readOnly,
    hideOwner,
    disabledFields,
}: BankAccountFormProps) => {
    const { t } = useTranslation('payout');

    const onChange = useCallback(
        (e: FormEvent<HTMLInputElement>) => {
            propOnChange(e.currentTarget.name, e.currentTarget.value);
        },
        [propOnChange]
    );

    const onBlur = useCallback((e: FormEvent<HTMLInputElement>) => {
        propOnChange(e?.currentTarget?.name, e?.currentTarget?.value);
        propOnTouched(e?.currentTarget?.name);
    }, [propOnChange, propOnTouched]);

    const getFieldProps = (key: keyof ConfigBankAccountDraft): InputProps => {
        const touched = isTouched(key);
        const error = errors[key];
        const fieldDisabled = disabled || disabledFields?.includes(key);
        return {
            name: key as string,
            value: value[key] as string,
            onChange,
            onBlur,
            error: touched && !!error,
            helperText: touched ? t<any>(error ?? ' ') : ' ',
            fullWidth: true,
            required: !readOnly,
            InputProps: {
                disabled: fieldDisabled,
                readOnly: readOnly,
                disableUnderline: readOnly,
            },
            disabled: fieldDisabled,
            placeholder: '',
            validation: touched && !!error ? {
                state: ValidationState.Invalid,
                message: t<any>(error),
            } : undefined,
        };
    };

    return (<>
        <Input
            className="stretch"
            label={t('bank_account.nickname')}
            {...getFieldProps('nickname')}
        />
        <Grid>
            {!hideOwner && <>
                <GridItem>
                    <H6>{t('bank_account.owner')}</H6>
                </GridItem>
                <GridItem>
                    <Input
                        label={t('bank_account.owner_address')}
                        {...getFieldProps('owner_address')}
                    />
                    <Input
                        label={t('bank_account.owner_postal_code')}
                        {...getFieldProps('owner_postal_code')}
                    />
                    <Input
                        label={t('bank_account.owner_city')}
                        {...getFieldProps('owner_city')}
                    />
                </GridItem>
                <GridItem>
                    <Input
                        label={t('bank_account.owner_name')}
                        {...getFieldProps('owner_name')}
                    />
                    <Input
                        label={t('bank_account.owner_orgno')}
                        {...getFieldProps('owner_orgno')}
                    />
                    <Input
                        label={t('bank_account.owner_country_code')}
                        {...getFieldProps('owner_country_code')}
                    />
                </GridItem>
                <GridItem>
                    <H6>{t('bank_account.details')}</H6>
                </GridItem>
            </>}
            <GridItem>
                <Input
                    label={t('bank_account.number', { context: value.bank_account_type })}
                    {...getFieldProps('bank_account_number')}
                />
            </GridItem>
            <GridItem>
                <Input
                    label={t('bank_account.bic')}
                    {...getFieldProps('bank_account_bic')}
                />
            </GridItem>
            <GridItem>
                {/*
                  * This should propably be an option here when the customer wants to add an international bank account
                  * For now this won't likely happen on a merchant level, but it may be applicable for elsewhere.
                  * Hence I left this field commented out, since this BankAccountForm component may be reusable elsewhere.
                <TextField
                    label={t('bank_account.type')}
                    {...getFieldProps('bank_account_type')}
                    select
                >
                    <MenuItem value={'bban'} selected={value.bank_account_type === 'bban'}>
                        {t('bank_account.type_bban')}
                    </MenuItem>
                    <MenuItem value={'iban'} selected={value.bank_account_type === 'iban'} disabled>
                        {t('bank_account.type_iban')}
                    </MenuItem>
                </TextField> */}
                <Input
                    label={t('general.currency')}
                    {...getFieldProps('bank_account_currency')}
                />
            </GridItem>
            <GridItem>
                <Input
                    label={t('bank_account.country_code')}
                    {...getFieldProps('bank_account_country_code')}
                />
            </GridItem>
        </Grid>
    </>
    );
};

export default BankAccountForm;
