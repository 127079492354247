import React from 'react';

const Icon = () => (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 69 47">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="eye-strikethrough" fill="#0852E0">
                <path
                    d="M34.4,31.4 C39,31.4 42.8,27.6 42.8,23 C42.8,18.4 39,14.6 34.4,14.6 C29.8,14.6 26,18.3 26,23 C26,27.7 29.8,31.4 34.4,31.4 Z M34.4,35.6 C27.4,35.6 21.8,30 21.8,23 C21.8,16 27.4,10.4 34.4,10.4 C41.4,10.4 47,16 47,23 C47,30 41.4,35.6 34.4,35.6 Z M47,7 C46.3,6.7 45.5,6.3 44.7,6.1 M44.7,6.1 C41.4,4.9 38,4.3 34.3,4.3 C22.5,4.3 13.1,10.3 5.7,22.9 C13.2,35.9 22.6,42.1 34.3,42.1 C46,42.1 55.6,35.9 63.3,22.9 C58.6,15.2 53.2,9.9 47,7 M50.2,3.9 C57.1,7.6 63.1,13.9 68.1,22.8 C59.5,38.4 48.2,46.2 34.2,46.2 C20.2,46.2 9.2,38.5 0.9,22.9 C9.2,7.7 20.3,0.1 34.3,0.1 C40.1,0.1 45.4,1.4 50.2,3.9 L12.5,41.4"
                    id="Combined-Shape"
                ></path>
            </g>
        </g>
    </svg>
);

export default Icon;
