import { CognitoUser } from 'amazon-cognito-identity-js';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { Button, ButtonGroup } from '../../../components/Buttons';
import { Input, ValidationState } from '../../../components/Forms';
import { P } from '../../../components/Typography';
import {
    validate,
    validateLength,
    validateMinLength,
    validatePositiveNumber,
    validateRequired
} from '../../../helpers/validation';
import { colors, distances } from '../../../styles/constants';
import { confirmForgottenPassword, redirectToApp } from '../../cognitoIdentityWrapper';
import type { MfaType, SelectMfaTypeOptions } from '../../types';
import { stringToSlug } from '../helpers';

interface VerifyForgotPasswordProps {
    username: string;
    setBack: () => void;
    setMfaRequired: (cognitoUser: CognitoUser, mfaType: MfaType) => void;
    setSelectMfaRequired: (cognitoUser: CognitoUser, options: SelectMfaTypeOptions) => void;
    setShake: (value: boolean) => void;
}

const VerifyForgotPassword = ({
    username,
    setBack,
    setMfaRequired,
    setShake,
    setSelectMfaRequired,
}: VerifyForgotPasswordProps) => {
    const { t } = useTranslation();

    const [loginError, setLoginError] = useState('');

    const formik = useFormik({
        initialValues: {
            verificationCode: '',
            newPassword: '',
        },
        validate: (values) => {
            let errors: { [key: string]: string } = {};
            const validators = [
                {
                    path: 'newPassword',
                    validator: validateRequired(t('login.invalid.new_password')),
                },
                {
                    path: 'newPassword',
                    validator: validateMinLength(8, t('login.invalid.new_password')),
                },
                {
                    path: 'verificationCode',
                    validator: validateRequired(t('login.invalid.verification_code')),
                },
                {
                    path: 'verificationCode',
                    validator: validateLength(6, t('login.invalid.verification_code')),
                },
                {
                    path: 'verificationCode',
                    validator: validatePositiveNumber(t('login.invalid.verification_code')),
                },
            ];
            errors = validators.reduce((acc, elem) => {
                return validate(elem.path, elem.validator, values, acc);
            }, errors);

            return errors;
        },
        onSubmit: async (values) => {
            try {
                const authenticateResult = await confirmForgottenPassword(
                    username.toLowerCase(),
                    values.verificationCode,
                    values.newPassword
                );
                if (authenticateResult.success) {
                    redirectToApp(authenticateResult.success);
                } else if (authenticateResult.mfaRequired && authenticateResult.selectMfaTypeOptions) {
                    setSelectMfaRequired(authenticateResult.mfaRequired, authenticateResult.selectMfaTypeOptions);
                } else if (authenticateResult.mfaRequired) {
                    setMfaRequired(authenticateResult.mfaRequired, authenticateResult.mfaType || 'SMS_MFA');
                } else {
                    throw new Error('Unexpected result');
                }
            } catch (error) {
                console.log('Error verifying new password', error);
                setLoginError(error.message);
                setShake(true);
            }
        },
    });

    return (
        <>
            <P style={{ textAlign: 'center' }}>{t('login.verify_forgot_password_description')}</P>
            <ErrorMessage visible={loginError !== ''}>
                {t('login.verify_forgot_password_error', { context: stringToSlug(loginError) })}
            </ErrorMessage>
            <form onSubmit={formik.handleSubmit}>
                <input hidden autoComplete="username" name="username" type="email" defaultValue={username} />
                <InputWrapper>
                    <Input
                        label={t('login.fields.verification_code')}
                        placeholder={t(`login.placeholders.verification_code`)}
                        type="text"
                        inputmode="numeric"
                        autoComplete="one-time-code"
                        pattern="[0-9]{6}"
                        name="verificationCode"
                        value={formik.values.verificationCode}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus={true}
                        validation={
                            (formik.touched.verificationCode &&
                                formik.errors.verificationCode && {
                                state: ValidationState.Invalid,
                                message: formik.errors.verificationCode,
                            }) ||
                            undefined
                        }
                        required
                    />
                </InputWrapper>
                <InputWrapper>
                    <Input
                        label={t('login.fields.new_password')}
                        placeholder={t(`login.placeholders.new_password`)}
                        type="password"
                        name="newPassword"
                        autoComplete="new-password"
                        value={formik.values.newPassword}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        validation={
                            (formik.touched.newPassword &&
                                formik.errors.newPassword && {
                                state: ValidationState.Invalid,
                                message: formik.errors.newPassword,
                            }) ||
                            undefined
                        }
                        required
                    />
                </InputWrapper>
                <StyledButtonGroup>
                    <Button type="submit" className="stretch" disabled={!formik.isValid || formik.isSubmitting}>
                        {t('login.actions.verify_forgotten_password')}
                    </Button>
                    <Button className="stretch outlined" onClick={setBack}>{t('login.actions.back')}</Button>
                </StyledButtonGroup>
            </form>
        </>
    );
};

const InputWrapper = styled.div`

    &:last-child {
        margin: 0;
    }
`;
const StyledButtonGroup = styled(ButtonGroup)`
        display: flex;
        flex-direction: column;
        gap: var(--Spacing-3);
    @media (max-width: 768px) {

        margin-right: ${distances.tiny};
    }
`;

interface ErrorMessageProps {
    visible: boolean;
}

const ErrorMessage = styled.p<ErrorMessageProps>`
    text-align: center;
    font-size: 11px;
    line-height: ${distances.small};
    color: ${colors.invalid};
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export default VerifyForgotPassword;
