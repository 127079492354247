import * as React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

import { colors, distances, border } from '../../styles/constants';

interface PagingProps {
    nextLocation?: string;
    firstLocation?: string;
}

const Paging = (props: PagingProps) => {
    return (
        <Wrapper>
            {props.firstLocation ? (
                <Link to={props.firstLocation}>
                    <PageIconLeftStop />
                </Link>
            ) : (
                <PageIconLeftStop className="disabled" />
            )}{' '}
            {props.nextLocation ? (
                <Link to={props.nextLocation}>
                    <PageIconRight />
                </Link>
            ) : (
                <PageIconRight className="disabled" />
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    padding: ${distances.small} ${distances.normal};
    font-size: 14px;
    text-align: right;
    user-select: none;
`;
export default Paging;

const PageIconLeftStop = styled.span`
    display: inline-block;
    width: 25px;
    height: 25px;
    border: ${border.normal} solid ${colors.borderLight};
    user-select: none;
    cursor: pointer;
    background-image: url('/assets/icons/arrow_left_stop.svg');
    background-size: cover;

    &.disabled {
        background-color: ${colors.borderLight};
        opacity: 0.3;
    }
`;

const PageIconLeft = styled.span`
    display: inline-block;
    width: 25px;
    height: 25px;
    border: ${border.normal} solid ${colors.borderLight};
    user-select: none;
    cursor: pointer;
    background-image: url('/assets/icons/arrow_left.svg');
    background-size: cover;

    &.disabled {
        background-color: ${colors.borderLight};
        opacity: 0.3;
    }
`;

const PageIconRight = styled(PageIconLeft)`
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
`;
