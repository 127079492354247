import { Formik } from 'formik';
import { useEffect } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Button, ButtonGroup } from '../../components/Buttons';
import { Input, ValidationState } from '../../components/Forms';
import Hr from '../../components/Hr';
import Modal from '../../components/Modal';
import { H2, P } from '../../components/Typography';
import { State } from '../../reducer';
import { colors, distances, grid } from '../../styles/constants';
import { AuthenticatedAccountUser } from '../../types/management-auth';
import { ChangePasswordModalStates } from '../types';
import { changePassword, openChangePasswordModal } from './actions';
import * as selectors from './selectors';
import { isFederatedSignin } from '../cognito';

const mapStateToProps = (state: State) => ({
    accountUser: selectors.getAccountUser(state),
    changePasswordState: selectors.getChangePasswordState(state),
});

const mapDispatchToProps = {
    changePassword,
    openChangePasswordModal,
};

interface MatchParams {
    accountId: string;
}

interface ChangeAccountProps extends WithTranslation, RouteComponentProps<MatchParams> {
    accountUser: AuthenticatedAccountUser;
    changePasswordState: ChangePasswordModalStates;
    changePassword: (previous_password: string, proposed_password: string) => void;
    openChangePasswordModal: () => void;
}

const ChangePassword = ({
    t,
    match,
    history,
    accountUser,
    changePasswordState,
    changePassword,
    openChangePasswordModal,
}: ChangeAccountProps) => {
    // reset form on mount
    useEffect(() => {
        openChangePasswordModal();
    }, [openChangePasswordModal]);
    const accountId = match.params.accountId;
    const account = accountUser.accounts.find((x) => x.account_id === accountId);

    const isFederatedSession = isFederatedSignin();

    if (isFederatedSession) {
        return null;
    }

    return (
        <Modal>
            {changePasswordState === 'success' ? (
                <>
                    <H2>{t('change_password.title')}</H2>
                    <P></P>
                    <Hr />
                    <Wrapper>
                        <P>{t('change_password.success')}</P>
                        <SuccessIcon />
                    </Wrapper>
                    <ButtonGroup>
                        <StyledButton type="button" className="alt" onClick={history.goBack}>
                            {t('change_password.close')}
                        </StyledButton>
                    </ButtonGroup>
                </>
            ) : (
                <Formik
                    isInitialValid={false}
                    initialValues={{
                        previous_password: '',
                        proposed_password: '',
                    }}
                    validate={(values) => {
                        const errors: { previous_password?: string; proposed_password?: string } = {};
                        if (!values.previous_password) {
                            errors.previous_password = t('change_password.errors.previous_password.required');
                        }
                        if (values.proposed_password.length < 8) {
                            errors.proposed_password = t('change_password.errors.proposed_password.invalid');
                        }

                        return Object.values(errors).find((x) => x) ? errors : undefined;
                    }}
                    onSubmit={(values) => {
                        changePassword(values.previous_password, values.proposed_password);
                    }}
                >
                    {({ values, errors, handleChange, handleBlur, handleSubmit, touched, isValid }) => (
                        <form onSubmit={handleSubmit}>
                            <H2>{t('change_password.title')}</H2>
                            <P>
                                <Trans
                                    i18nKey="change_password.subtitle"
                                    values={{ email: account && account.user.email }}
                                >
                                    <em />
                                </Trans>
                            </P>
                            <Hr />
                            <Wrapper>
                                <ErrorMessage
                                    visible={['error', 'wrong_password', 'rate_limit'].includes(changePasswordState)}
                                >
                                    {t(`change_password.errors.response.${changePasswordState}`)}
                                </ErrorMessage>
                                <input
                                    hidden
                                    autoComplete="username"
                                    name="username"
                                    type="email"
                                    defaultValue={account && account.user.email}
                                />
                                <Input
                                    label={t('change_password.previous_password')}
                                    type="password"
                                    name="previous_password"
                                    value={values.previous_password}
                                    placeholder="********"
                                    autoComplete="current-password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                    validation={{
                                        state:
                                            errors?.previous_password && touched.previous_password
                                                ? ValidationState.Invalid
                                                : ValidationState.Pristine,
                                        message: touched.previous_password ? errors?.previous_password : '',
                                    }}
                                />
                                <Input
                                    label={t('change_password.proposed_password')}
                                    type="password"
                                    name="proposed_password"
                                    value={values.proposed_password}
                                    placeholder="********"
                                    autoComplete="new-password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    required
                                    validation={{
                                        state:
                                            errors?.proposed_password && touched.proposed_password
                                                ? ValidationState.Invalid
                                                : ValidationState.Pristine,
                                        message: t('change_password.errors.proposed_password.invalid'),
                                    }}
                                />
                            </Wrapper>
                            <ButtonGroup>
                                <StyledButton type="button" className="alt" onClick={history.goBack}>
                                    {t('change_password.abort')}
                                </StyledButton>
                                <StyledButton type="submit" disabled={!isValid || changePasswordState === 'in-flight'}>
                                    {t('change_password.submit')}
                                </StyledButton>
                            </ButtonGroup>
                        </form>
                    )}
                </Formik>
            )}
        </Modal>
    );
};

interface ErrorMessageProps {
    visible: boolean;
}

const ErrorMessage = styled.p<ErrorMessageProps>`
    font-size: 11px;
    line-height: ${distances.small};
    color: ${colors.invalid};
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const Wrapper = styled.div`
    width: ${grid.spans.span6};
    margin-bottom: ${distances.small};
    font-size: 14px;
    overflow-y: auto;

    @media (max-width: 768px) {
        width: 100%;
        height: 300px;
    }

    @media (max-width: 350px) {
        width: 100%;
        height: 250px;
    }
`;

const StyledButton = styled(Button as any)`
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const SuccessIcon = styled.div`
    background-image: url(/assets/icons/icon_check_circle_primary.svg);
    height: 80px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin: ${distances.normal};
`;

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation()(ChangePassword)));
