import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Select from './SelectWithButton';

import { distances, grid } from '../../styles/constants';

export interface DropdownExampleProps {
    value: string;
    onChange: (value: string) => void;
    options: string[];
    disabled?: boolean;
}

const CountryPrefix = (
    {
        value,
        options,
        onChange,
        disabled,
    }: DropdownExampleProps
) => {
    const selectedOption = options.find((option) => option === value) || '';
    const t = (a: string) => a;
    const [prefix, setPrefix] = useState(selectedOption);

    useEffect(() => {
        setPrefix(value);
    }, [value]);

    return (
        <Wrapper>
            <Select
                name="phoneNumberDropdown"
                value={prefix}
                options={options}
                getOptionLabel={(option) => option}
                getOptionValue={(option) => option}
                getOptionSubLabel={(option) => ''}
                onBlur={(e) => {
                    // setPrefix(e.currentTarget.value);
                    // onChange(e.currentTarget.value);
                }}
                maxOptions={10}
                maxOptionsReachedMessage={t('settings.edit_account.prefill.max_options')}
                noOptionsMessage={t('settings.edit_account.prefill.full_words')}
                autoFocus={false}
                onInputChange={(e) => {
                    setPrefix(e.target.value);
                    onChange(e.target.value);
                }}
                onChange={(selection) => {
                    setPrefix(selection);
                    onChange(selection);
                }}
                placeholder={'+00'}
                disabled={disabled}
                hideOnNoOptions={false}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div`
    min-width: ${grid.spans.span1};
    width: ${distances.ginormous};
    margin-right: ${distances.tiny};
    max-height: 40px;
`;

export default CountryPrefix;
