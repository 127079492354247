import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { compose } from 'redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { State } from '../../reducer';
import { getUser } from './selectors';
import { AuthenticatedUser } from '../types';
import { useAccountId } from '../useAccountId';

// A HOC that injects urlAccountId, prodAccountId and accountId props.

interface MatchParams {
    accountId: string;
}

export interface AccountIdsProps {
    urlAccountId: string; // The one in the url param :accountId
    prodAccountId?: string; // The production version of the account in the url
    accountIds: string[]; // Either only test or both test and prod ids
    baseAccountId: string; // AccountId without T or P prefix
    isTest: boolean; // Is the account a test account
}

const mapStateToProps = (state: State) => ({
    user: getUser(state),
});

export const withAccountIds = <WrappedProps extends AccountIdsProps>(
    WrappedComponent: React.ComponentType<WrappedProps>
) => {
    type Excluded = Exclude<WrappedProps, AccountIdsProps>;
    type HocOnly = {
        user: AuthenticatedUser;
    };

    type HocProps = Excluded & HocOnly & RouteComponentProps<MatchParams>;

    class WithAccountIds extends React.Component<HocProps, {}> {
        public static displayName = `withAccount(${WrappedComponent.name})`;
        public static readonly WrappedComponent = WrappedComponent;

        public render() {
            const { ...restProps } = this.props as {};
            const allAccountIds = this.props.user.account_user.accounts.map(x => x.account_id);
            const urlAccountId = this.props.match.params.accountId;
            const accountIds = allAccountIds.filter(a => a.substr(1) === urlAccountId.substring(1));
            const prodAccountId = accountIds.find(a => a.startsWith('P'));
            const baseAccountId = urlAccountId.replace(/^[T|P]/i, '');
            const isTest = urlAccountId.startsWith('T');
            return (
                <WrappedComponent
                    {...restProps as WrappedProps}
                    urlAccountId={urlAccountId}
                    prodAccountId={prodAccountId}
                    accountIds={accountIds}
                    baseAccountId={baseAccountId}
                    isTest={isTest}
                />
            );
        }
    }

    return compose(connect(mapStateToProps))(withRouter(WithAccountIds) as any);
};

export const useAccountIds = () => {
    const user = useSelector(getUser);
    const urlAccountId = useAccountId();
    return useMemo(() => {
        const allAccountIds = user.account_user.accounts.map(x => x.account_id);
        const accountIds = allAccountIds.filter(a => a.substr(1) === urlAccountId.substring(1));
        const baseAccountId = urlAccountId.replace(/^[T|P]/i, '');
        const isTest = urlAccountId.startsWith('T');
        return {
            urlAccountId,
            allAccountIds,
            accountIds,
            prodAccountId: accountIds.find(a => a.startsWith('P')),
            testAccountId: accountIds.find(a => a.startsWith('T')),
            baseAccountId: baseAccountId,
            // This one is always defined, as opposed to the other one which is only defined if it exists
            optimisticProdAccountId: `P${baseAccountId}`,
            isTest,
        };
    }, [user.account_user.accounts, urlAccountId]);
};
