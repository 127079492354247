import React from 'react';
import styled from 'styled-components/macro';
import { colors, distances, globalPageMaxWidth } from '../../../styles/constants';
import { P } from '../../../components/Typography';
import { useTranslation } from 'react-i18next';
import { inviteMerchantPrefillUrl } from '../utils/inviteLink';

type MessagesProps = {
    newAccount: string | undefined;
    partnerLinkCreated: boolean;
    accountType: 'partner' | 'merchant' | undefined;
}
export const Messages = (
    {
        newAccount,
        partnerLinkCreated,
        accountType,
    }: MessagesProps
) => {
    const { t } = useTranslation();
    const messages = [];
    if (newAccount) {
        messages.push(t('partner.new_account.result.account_created', { account_id: newAccount, context: accountType }));
        if (partnerLinkCreated) {
            const prefillUrl = inviteMerchantPrefillUrl(newAccount);
            messages.push(t('partner.new_account.result.account_link_created', { onboarding_link: prefillUrl }));
        } else if (accountType === 'partner') {
            messages.push(t('partner.new_account.result.account_link_not_created'));
        }
    }

    if (messages.length === 0) {
        return null;
    }
    return (
        <div>
            <Infobox>
                {messages.map((message, i) => {
                    return (
                        <P key={i}>{message}</P>
                    );
                })}
            </Infobox>
        </div>

    );
};

const Infobox = styled.div`
  position: relative;
  background-color: ${colors.interactiveBackgroundHover};
  border-radius: 4px;
  padding: ${distances.small};
  margin-bottom: ${distances.small};

  max-width: ${globalPageMaxWidth}px;

  @media (max-width: 1471px) {
    max-width: 752px;
  }
  @media (max-width: 1087px) {
    max-width: 370px;
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
