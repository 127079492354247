import styled from 'styled-components/macro';
import { border, colors, defaultRadius, distances, palette } from '../../styles/constants';
import * as React from 'react';
import { InputValidation } from './validation';
import { P, Label } from '../Typography';

interface TextAreaElementProps {
    rightAligned: boolean;
    prefix?: string;
    height?: string;
    fullWidth?: boolean;
}

interface ValidatedTextAreaElementProps extends TextAreaElementProps {
    name: string;
    value: string;
    label?: string;
    description?: string;
    onChange: (e: React.ChangeEvent<any>) => void;
    onBlur: (e: React.ChangeEvent<any>) => void;
    validation?: InputValidation;
    className?: string;
    autoFocus?: boolean;
    style?: React.CSSProperties;
    onKeyPress?: (e: React.KeyboardEvent<any>) => void;
    minRows?: number;
}

export const ValidatedTextAreaElement = (props: ValidatedTextAreaElementProps) => {
    return (
        <Wrapper {...{ className: props.className, fullWidth: props.fullWidth, style: props.style }}>
            {props.label && <Label htmlFor={props.name}>{props.label}</Label>}
            {props.description && <P>{props.description}</P>}
            <TextareaElement
                rightAligned={props.rightAligned}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                onKeyPress={props.onKeyPress}
                autoFocus={props.autoFocus}
                className={props.validation ? props.validation.state : ''}
                rows={props.minRows}
            />
            {props.validation && props.validation.message && (
                <Message
                    htmlFor={props.name}
                    className={props.validation.state}
                    labelExists={props.label !== undefined && props.label.length > 0}
                >
                    {props.validation.message}
                </Message>
            )}
        </Wrapper>
    );
};

export const TextareaElement = styled.textarea<TextAreaElementProps>`
    height: ${(props) => (props.height ? props.height : distances.ginormous)};
    resize: none;
    min-height: 40px;
    border: none;
    outline: none;
    border: ${border.normal} solid ${palette.neutral[400]};
    border-radius: ${defaultRadius};
    background: ${colors.background};
    padding: ${distances.tiny} ${distances.small12};
    text-align: ${(props) => (props.rightAligned ? 'right' : 'left')};
    width: 100%;
    -moz-appearance: textfield;

    ${(props) => (props.prefix ? `padding-left: calc(${distances.small} + ${props.prefix.length * 12}px);` : '')}

    &::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
    }

    &::placeholder {
        color: ${colors.textSecondary};
    }

    &:focus {
        outline: none;
        border: ${border.normal} solid ${palette.primary[300]};
        box-shadow: 0 0 0 3px ${palette.primary[100]};

    }
    &.invalid,
    &:focus .invalid {
        border: ${border.normal} solid ${palette.destructive[300]};
    }

    &.invalid:focus {
        box-shadow: 0 0 0 3px ${palette.destructive[100]};
    }

    &.valid,
    &:focus .valid {
        border: ${border.normal} solid ${palette.success[300]};
    }

    &.valid:focus {
        box-shadow: 0 0 0 3px ${palette.success[100]};
    }

    &:disabled {
        background: ${palette.neutral[50]};
        border: 1px solid ${palette.neutral[200]};
        color: ${palette.neutral[500]};
    }
`;

const Wrapper = styled.div<Partial<Pick<TextAreaElementProps, 'fullWidth'>>>`
    position: relative;
    &.huge input {
        font-size: 44px;
    }
    &.huge label.invalid {
        top: 101px;
    }
    ${(props) => props.fullWidth && 'width: 100%;'}
    margin-bottom: ${distances.normal};
`;

const Message = styled.label<{ labelExists: boolean }>`
    font-size: 14px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;
