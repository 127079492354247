import React, { memo } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Button } from '../../../components/Buttons';
import Card from '../../../components/Card';
import { colors, distances } from '../../../styles/constants';
import type { ApplicationEntityItem } from '../types';
import Logo from './Logo';

type ApplicationEntityProps = ApplicationEntityItem & Omit<React.HTMLProps<HTMLDivElement>, 'children' | 'ref' | 'as'>;

const ApplicationEntity = memo((props: ApplicationEntityProps) => {
    const { id, logo, name, type, provider, href, ...rest } = props;
    const { t } = useTranslation('app');
    return (
        <ApplicationEntityCard id={id} {...rest}>
            <Container>
                <Logo alt={name} logo={logo} />
                <Stack grow gap={2}>
                    <Trans parent={Description} ns="app" i18nKey="settings.third_party.type" values={{
                        context: type,
                        provider,
                    }}><strong /></Trans>
                </Stack>
            </Container>
            <Group justify="flex-end">
                <LinkButton as="a" href={href} target="_blank">{t('settings.third_party.link')}</LinkButton>
            </Group>
        </ApplicationEntityCard>
    );
});

ApplicationEntity.displayName = 'ApplicationEntity';

const ApplicationEntityCard = styled(Card)`
    position: relative;
    display: flex;
    flex-direction: column;
    gap: ${distances.small};
    padding: ${distances.small};
    width: 100%;
    border-radius: 4px;
    background-color: white;

    &:focus-within {
        outline: 2px solid ${colors.primary};
    }
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: ${distances.tiny};
    flex: 1;
`;

const Stack = styled.div<{ gap?: number; grow?: boolean }>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    gap: ${({ gap }) => gap ? gap : '0'}px;
    ${({ grow }) => grow ? 'flex-grow: 1;' : ''}
`;

const Group = styled.div<{ gap?: number; grow?: boolean; justify?: React.CSSProperties['justifyContent']; align?: React.CSSProperties['alignItems'] }>`
    display: flex;
    flex-direction: row;
    position: relative;
    gap: ${({ gap }) => gap ? gap : '0'}px;
    ${({ grow }) => grow ? 'flex-grow: 1;' : ''}
    justify-content: ${({ justify }) => justify ? justify : 'flex-start'};
    align-items: ${({ align }) => align ? align : 'center'};
`;

const Description = styled.p`
    font-weight: 600;
    margin: 0;
    word-wrap: break-word;
    color: ${colors.text};
`;

const SubscriptionDetails = styled.p`
    margin: 0;
    font-weight: bold;
    font-size: 12px;
    white-space: pre-line;
    color: ${colors.textSecondary};
    margin-right: ${distances.tiny};
`;

const LinkButton = styled(Button)`
    @media (max-width: 768px) {
        flex: 1;
    }
`;

export default ApplicationEntity;
