import React from 'react';

const Search = () => (
    <svg width="36px" height="36px" viewBox="0 0 36 36" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="element/top-button" fill="#0852E0">
                <g id="icons/search">
                    <path
                        d="M16.4997086,20.9987271 C18.98499,20.9987271 20.9997086,18.9840085 20.9997086,16.4987271 C20.9997086,14.0134457 18.98499,11.9987271 16.4997086,11.9987271 C14.0144273,11.9987271 11.9997086,14.0134457 11.9997086,16.4987271 C11.9997086,18.9840085 14.0144273,20.9987271 16.4997086,20.9987271 Z M20.7261535,20.0184831 L24.9985432,24.2861368 L24.2918287,24.9936356 L20.0190086,20.7255521 C19.0652942,21.5204916 17.8383503,21.9987271 16.4997086,21.9987271 C13.4621425,21.9987271 10.9997086,19.5362932 10.9997086,16.4987271 C10.9997086,13.461161 13.4621425,10.9987271 16.4997086,10.9987271 C19.5372748,10.9987271 21.9997086,13.461161 21.9997086,16.4987271 C21.9997086,17.8375822 21.5213207,19.0647038 20.7261535,20.0184831 Z"
                        id="Search"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
);

export default Search;
