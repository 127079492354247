import * as React from 'react';
import { formatOrgNumber } from '../../helpers/formatters';

interface DateTimeProps {
    country: string;
    value: string;
}

const OrganizationNumber = (
    {
        country,
        value,
    }: DateTimeProps
) => (<>{formatOrgNumber(country, value)}</>);

export default OrganizationNumber;
