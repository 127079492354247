import React, { Component, memo } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Allow } from '../../../auth';
import { isPartner } from '../../../auth/helpers';
import Icon from '../../../components/Icons';
import { LoadingOverlay } from '../../../components/Loading';
import Page, { PageButtonLink, Title } from '../../../components/Page';
import { ListGap } from '../../../components/Paging';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../../components/Table/Fake';
import { getListKey } from '../../../helpers/paging';
import { paramsEqual, updateParams } from '../../../helpers/urlParams';
import { GetAccountsReason } from '../actions';
import { accountPagingIdFn, getGapPageArgs, getNextPageArgs, isAccount } from '../helpers';
import { State as AccountState } from '../reducer';
import AccountRow from './AccountRow';
import Filters from './Filters';
import { Messages } from './Messages';
import Paging from './Paging';

interface MatchParams {
    accountId: string;
}

export interface MerchantsPageProps extends WithTranslation, RouteComponentProps<MatchParams> {
    getPartnerUsers: (accountId: string) => void;
    getAccounts: (accountId: string, params: URLSearchParams, reason: GetAccountsReason) => void;
    getExchangeToken: (accountId: string, subAccountId: string) => void;
    accounts: AccountState;
}

class Merchants extends Component<MerchantsPageProps> {
    componentDidMount() {
        const { limit, accounts, accountId, params } = this.props.accounts;
        const locationQueryParams = new URLSearchParams(this.props.location.search.substring(1));
        const requestQueryParams = updateParams(locationQueryParams, { limit: limit.toString() });
        const urlAccountId = this.props.match.params.accountId;
        const hasAccounts = accounts.length > 0;
        const isSameAccount = accountId === urlAccountId;
        const hasSameParams = paramsEqual(requestQueryParams, params, ['limit', 'starting_after']);
        const reason =
            hasAccounts && isSameAccount && hasSameParams ? GetAccountsReason.refresh : GetAccountsReason.mount;
        this.props.getAccounts(urlAccountId, requestQueryParams, reason);
        this.props.getPartnerUsers(urlAccountId);
    }

    componentDidUpdate(prevProps: MerchantsPageProps, prevState: any) {
        if (this.props.location.search !== prevProps.location.search) {
            const { limit } = this.props.accounts;
            const locationQueryParams = new URLSearchParams(this.props.location.search.substring(1));
            const requestQueryParams = updateParams(locationQueryParams, { limit: limit.toString() });
            const accountId = this.props.match.params.accountId;
            this.props.getAccounts(accountId, requestQueryParams, GetAccountsReason.update);
        }
    }
    render() {
        const { t, getAccounts, getExchangeToken } = this.props;
        const { accounts, isLoading, hasNext, accountId, users } = this.props.accounts;
        const limit = this.props.accounts.limit.toString();
        const locationQueryParams = new URLSearchParams(this.props.location.search.substring(1));
        const newAccount = locationQueryParams.get('newAccount') || undefined;
        const partnerLinkCreated = locationQueryParams.get('partnerLinkCreated') === 'true';

        const refreshParams = updateParams(locationQueryParams, { limit });
        const refresh = () => {
            getAccounts(accountId, refreshParams, GetAccountsReason.refresh);
        };

        const nextPageArgs = getNextPageArgs(accounts);
        const nextParams = updateParams(locationQueryParams, { ...nextPageArgs, limit });
        const next = () => {
            getAccounts(accountId, nextParams, GetAccountsReason.next);
        };

        const fillGap = (id: string) => {
            const gapPageArgs = getGapPageArgs(accounts, id);
            const gapParams = updateParams(locationQueryParams, { ...gapPageArgs, limit });
            getAccounts(accountId, gapParams, GetAccountsReason.refresh);
        };

        const accountOwners = users.filter(user => user.deleted_at === undefined).map(user => user.email);

        const newAccountType = newAccount && isPartner(newAccount) ? 'partner' : 'merchant';

        return (
            <Page
                title={
                    <React.Fragment>
                        <Title>{t('partner.merchants.title')}</Title>
                        <Allow anyOfScopes={[['admin:accounts'], ['write:accounts']]}>
                            {accountId.endsWith('00000000') ? (
                                <PageButtonLink to={`/${accountId}/sub-accounts/new`}>
                                    <Icon icon="plus" fill="currentColor" />
                                    {t('partner.merchants.new_partner')}
                                </PageButtonLink>
                            ) : (
                                <PageButtonLink to={`/${accountId}/sub-accounts/new`}>
                                    <Icon icon="plus" fill="currentColor" />
                                    {t('partner.merchants.invite_new_merchant')}
                                </PageButtonLink>
                            )}
                        </Allow>
                    </React.Fragment>
                }
            >
                <Messages newAccount={newAccount} partnerLinkCreated={partnerLinkCreated} accountType={newAccountType} />
                <Filters history={this.props.history} location={this.props.location} refresh={refresh} account_managers={accountOwners} />
                <Table className="no-wrap">
                    <Thead>
                        <Tr>
                            <Th className="first-child">{t('partner.merchants.fields.account_id')}</Th>
                            <Th>{t('partner.merchants.fields.business_name')}</Th>
                            <Th>{t('partner.merchants.fields.applicant')}</Th>
                            <Th>{t('partner.merchants.fields.payment_connections_status')}</Th>
                            <Th>{t('partner.merchants.fields.account_manager')}</Th>
                            <Th>{t('partner.merchants.fields.created_at')}</Th>
                            <Th />
                        </Tr>
                    </Thead>
                    {accounts.length > 0 && (
                        <Tbody>
                            {accounts.map((account) => (
                                <React.Fragment key={getListKey(account, accountPagingIdFn)}>
                                    {isAccount(account) ? (
                                        <AccountRow {...{ account, accountId, getExchangeToken }} />
                                    ) : (
                                        <ListGap
                                            fillGap={fillGap}
                                            gap={account}
                                            label={t('partner.merchants.load_gap')}
                                        />
                                    )}
                                </React.Fragment>
                            ))}
                        </Tbody>
                    )}
                </Table>
                <Wrapper>
                    <RelativeGroup>
                        <Paging next={next} isLoading={isLoading} hasNext={hasNext} />
                        {isLoading && <LoadingOverlay />}
                    </RelativeGroup>
                </Wrapper>
            </Page>
        );
    }
}

const Wrapper = styled.div`
    position: relative;
`;

const RelativeGroup = styled.div`
    position: relative;
    box-sizing: border-box;
`;

export default withTranslation()(memo(Merchants));
