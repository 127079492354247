import { Actions, ActionTypes } from './actions';
import { Subscription } from '../../../types/webhooks';

export interface State {
    isLoading: boolean;
    hooks: Subscription[];
}

export const initialState: State = {
    isLoading: false,
    hooks: [],
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {

        case ActionTypes.GetHooks:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FetchHooksResponse:
            return {
                ...state,
                isLoading: false,
                hooks: action.payload.hooks,
            };

        default:
            return state;
    }
}
