import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';

import { Button, ButtonGroup, ButtonLink } from '../../components/Buttons';
import Modal from '../../components/Modal';
import { H2, P } from '../../components/Typography';
import { State } from '../../reducer';
import { border, colors, distances } from '../../styles/constants';
import { AuthenticatedAccountUser } from '../../types/management-auth';
import { updateAuthenticatedUser } from './actions';
import { AccountUserAccount, getNonDupedAccountList } from './getAccountList';
import * as selectors from './selectors';

import profileInactive from '../../components/AppBar/icons/profile-inactive.svg';
import Hr from '../../components/Hr';

const mapStateToProps = (state: State) => ({
    accountUser: selectors.getAccountUser(state),
});

const mapDispatchToProps = {
    updateAuthenticatedUser,
};

interface MatchParams {
    accountId: string;
}

interface ChangeAccountProps extends WithTranslation, RouteComponentProps<MatchParams> {
    accountUser: AuthenticatedAccountUser;
    updateAuthenticatedUser: (accountId: string) => void;
}

const getDisplayName = ({ display_name, business_name, account_id }: AccountUserAccount) => {
    if (!display_name && !business_name) return account_id;
    return `${display_name ?? business_name}, ${account_id}`;
};

const getIconSource = (account: AccountUserAccount) => account.icon_url ?? profileInactive;

const ChangeAccount = ({
    t,
    match,
    history,
    accountUser,
    updateAuthenticatedUser,
}: ChangeAccountProps) => {
    const accountId = match.params.accountId;

    useEffect(() => {
        updateAuthenticatedUser(accountId);
    }, [accountId, updateAuthenticatedUser]);

    const nonDupedAccountList = useMemo(() => getNonDupedAccountList(accountUser), [accountUser]);

    const [accounts, setAccounts] = useState(nonDupedAccountList);

    const onSearchValueChange = useCallback(
        (event: FormEvent<HTMLInputElement>) => {
            if (!event.currentTarget.value) {
                setAccounts(nonDupedAccountList);
                return;
            }
            const re = new RegExp(event.currentTarget.value, 'i');
            const accountsFiltered = nonDupedAccountList.filter((account) =>
                Object.values(account).some((value) => re.test(value)));
            setAccounts(accountsFiltered);
        },
        [nonDupedAccountList]
    );


    return (
        <Modal width={'720px'} onClose={history.goBack} withCloseButton={false}>
            <H2>{t('change_account.title')}</H2>
            <P>{t('change_account.subtitle')}</P>
            <Hr />
            <StyledInput
                autoFocus
                placeholder={t('change_account.search_accounts_placeholder')}
                onChange={onSearchValueChange}
            />
            <Wrapper>
                {accounts.length > 0 &&
                    accounts.map((account) => (
                        <AccountLink key={account.account_id} to={`/${account.account_id}/`}>
                            <LinkContentWrapper>
                                <ProfileImage iconUrl={getIconSource(account)} />
                                <TextWrapper>
                                    <div>
                                        <StatusText>
                                            {accountId === account.account_id
                                                ? t('change_account.logged_in')
                                                : t('change_account.switch_to')}
                                        </StatusText>
                                        <StyledName>{getDisplayName(account)}</StyledName>
                                    </div>
                                    <StyledSymbol>{'>'}</StyledSymbol>
                                </TextWrapper>
                            </LinkContentWrapper>
                        </AccountLink>
                    ))}
                {accounts.length === 0 && (
                    <NoDataWrapper>
                        <P>{t('change_account.no_data')}</P>
                    </NoDataWrapper>
                )}
            </Wrapper>
            <ButtonGroup>
                <StyledButton className="alt" onClick={history.goBack}>
                    {t('change_account.back')}
                </StyledButton>
            </ButtonGroup>
        </Modal>
    );
};

const Wrapper = styled.div`
    height: 20vw;
    margin-bottom: ${distances.small};
    font-size: 14px;
    overflow-y: auto;

    @media (max-width: 768px) {
        width: 100%;
        height: 300px;
    }

    @media (max-width: 350px) {
        width: 100%;
        height: 250px;
    }
`;

const StyledInput = styled.input`
    height: ${distances.normal};
    width: 100%;
    margin: ${distances.normal} 0;
    padding: ${distances.small};
    border: none;
    border-bottom: ${border.normal} solid ${colors.borderLight};
    background: ${colors.backgroundInset};
    outline: none;

    &:hover {
        border-bottom: ${border.normal} solid ${colors.primaryHover};
    }

    &:focus {
        outline: none;
        border-bottom: ${border.normal} solid ${colors.primaryFocusOutline};
        background: ${colors.interactiveBackgroundHover};
    }

    &::-webkit-inner-spin-button {
        display: none;
        -webkit-appearance: none;
    }

    &::placeholder {
        color: ${colors.textSecondary};
    }

    @media (max-width: 768px) {
        margin: ${distances.small} 0;
    }
`;

const AccountLink = styled(Link)`
    position: relative;
    display: block;
    padding: ${distances.small};
    color: ${colors.text};
    text-decoration: none;
    border-radius: 8px;
    background: ${colors.backgroundAlt};
    margin-bottom: ${distances.small};

    &:hover {
        background: ${colors.primaryHover};
        color: ${colors.background};
    }

    @media (max-width: 768px) {
        margin: ${distances.small} 0;
    }
`;

const LinkContentWrapper = styled.div`
    display: flex;
`;

interface ProfileImageProps {
    iconUrl: string;
}

const ProfileImage = styled.div<ProfileImageProps>`
    min-width: 40px;
    min-height: 40px;
    background-size: contain;
    background-image: url(${(props) => props.iconUrl});
    background-repeat: no-repeat;
    background-position: center;
    margin-right: ${distances.small};
`;

const TextWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const StatusText = styled.p`
    margin: 0;
    margin-bottom: ${distances.tiny};
    opacity: 0.7;
`;

const StyledName = styled.p`
    margin: 0;
`;

const StyledSymbol = styled.p`
    margin: 0;
    font-size: 20px;
`;

const NoDataWrapper = styled.div`
    padding: ${distances.large};
    text-align: center;
`;

const StyledButton = styled(Button as any)`
    @media (max-width: 768px) {
        width: 100%;
    }
`;
const StyledButtonLink = styled(ButtonLink as any)`
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation()(ChangeAccount)));
