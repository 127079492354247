const checkoutIdRegex = new RegExp(
    //eslint-disable-next-line
    /^[T|P]?(\d{8})\..*$/
);

export const accountFromPossibleCheckoutId = (searchString: string | undefined): string | undefined => {
    if (!searchString) {
        return undefined;
    }
    const match = checkoutIdRegex.exec(searchString);
    return match ? match[1] : undefined;

};
