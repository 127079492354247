import { connect } from 'react-redux';

import { getUser, editUser, resetForm } from '../actions';
import { State } from '../../../../reducer';
import { getUsersSelector } from '../selectors';
import { getAccountUser } from '../../../../auth/accessToken/selectors';

import EditUserPage from '../components/EditUserPage';
import { selectTokenScopes } from '../../../../auth/accessToken/Allow';

const mapStateToProps = (state: State) => ({
    user: getUsersSelector(state),
    accountUser: getAccountUser(state),
    loggedInUserScopes: selectTokenScopes(state, state.accountDetails.account_id),
});

const mapDispatchToProps = {
    getUser,
    editUser,
    resetForm,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUserPage);
