import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Card from '../../../components/Card';
import { H3 } from '../../../components/Typography';
import { colors, distances } from '../../../styles/constants';
import Hr from '../../../components/Hr';
import { Account } from '../../../types/management-auth';
import { Json } from '../../../components/Formatters';

const getSignificantData = (applicant: Account['applicant']) => {
    // Removes values displayed by the TermsInformation component
    const { agreement, last_name, first_name, email, phone_number, ...data } = applicant;
    return Object.entries(data).reduce((acc, [key, value]) => {
        if (!value) {
            return acc;
        }
        return { ...acc, [key]: value };
    }, {});
};

export type ApplicantProps = {
    account: Account;
}

const Applicant = (
    {
        account,
    }: ApplicantProps
) => {
    const { t } = useTranslation();

    if (!account.applicant) {
        return null;
    }
    const data = getSignificantData(account.applicant);

    if (Object.keys(data).length === 0) {
        return null;
    }

    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('partner.merchant.applicant')}</H3>
            <Hr />
            <Json data={data} />

        </Card>
    );
};

export default Applicant;
