import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import styled from 'styled-components/macro';

import { useActions } from '../../Actions';
import { createPdBalancesSelector, createPdSelector, createPdTransfersSelector, externalSelectors } from '../../payout/merchant/config/selectors';
import { distances } from '../../styles/constants';
import PayoutDestinationStatus from './components/PayoutDestinationStatus';

import Card from '../../components/Card';
import { Dd, Dl, Dt } from '../../components/DescriptionList/Horizontal';
import { LoadingOverlay } from '../../components/Loading';
import Page, { Back, PageButton, Title } from '../../components/Page';
import { H2, H3, P } from '../../components/Typography';

import { createSelector } from 'reselect';
import { AllowDinteroRoot } from '../../auth/accessToken/Allow';
import { useAccountIds } from '../../auth/accessToken/withAccountIds';
import { useAccountId } from '../../auth/useAccountId';
import { Button, ButtonLink } from '../../components/Buttons';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import { DateTime } from '../../components/Formatters';
import { Input } from '../../components/Forms';
import Grid, { GridItem } from '../../components/Grid';
import Hr from '../../components/Hr';
import Icon from '../../components/Icons';
import MenuButton from '../../components/MenuButton/MenuButton';
import MenuItem from '../../components/MenuButton/MenuItem';
import { ContentLong, ContentMedium, ContentShort } from '../../components/Placeholders/App';
import { Table, Tbody, Td, Th, Thead, Tr } from '../../components/Table';
import View from '../../components/View';
import PayoutMoney from '../../payout/components/PayoutMoney';
import { ConfigBankAccount, PayoutDestinationConfig, PayoutDestinationTransfersEntry } from '../../payout/types';
import { approvalsStateSelector } from '../approvalsSelectors';
import FinishAccountInfobox from '../components/FinishAccountInfobox';
import BankAccountAutocomplete from '../components/PayoutBankAccount/BankAccountAutocomplete';
import PayoutDestinationBankAccounts from '../components/PayoutBankAccount/BankAccounts';
import CreateBankAccountDialog, {
    CreateBankAccountTempate
} from '../components/PayoutBankAccount/CreateBankAccountDialog';
import { getGeoLocation, mapValues } from '../locations/shared/utils';
import { LocationFormValues } from '../locations/types';
import SellerLocationForm from './components/SellerLocationForm';
import SellerLocations from './components/SellerLocations';
import { downloadSettlementByUrl } from '../../reports/settlementList/helpers';
import { Attachment } from '../../reports/settlementList/types';
import { AttachmentLink } from '../../reports/components/AttachmentLink';
/**
 * For now, we only allow bank accounts with the same org nr to be created and added to the payout destination
 * Additionally we only allow one account pr currency
 */

const createUnlinkedBankAccountsSelector = (accountId: string, pdId: string) => createSelector(
    externalSelectors.links(accountId).links,
    externalSelectors.bankAccounts(accountId).bankAccounts,
    externalSelectors.bankAccounts(accountId).bankAccountsRaw,
    (links, bankAccounts, bankAccountsRaw) => {
        const linkedBankAccounts = links
            .filter(l => l.payout_destination_config_id === pdId && !!bankAccountsRaw[l.config_bank_account_id])
            .map(l => bankAccountsRaw[l.config_bank_account_id]);

        const linkedBankAccountIds = new Set(linkedBankAccounts.map(ba => ba.id));
        const linkedCurrencies = new Set(linkedBankAccounts.map(ba => ba.bank_account_currency));
        const linkedOrgnos = new Set(linkedBankAccounts.map(ba => ba.owner_orgno));

        return bankAccounts.filter(ba => !linkedBankAccountIds.has(ba.id)
            && (linkedCurrencies.size === 0 || linkedCurrencies.has(ba.bank_account_currency))
            && (linkedOrgnos.size === 0 || linkedOrgnos.has(ba.owner_orgno)));
    }
);

const createBankAccountTemplateSelector = (accountId: string, pdId: string) => createSelector(
    externalSelectors.links(accountId).links,
    externalSelectors.bankAccounts(accountId).bankAccountsRaw,
    (links, bankAccounts) => {
        const linked = links
            .filter(l => l.payout_destination_config_id === pdId)
            .map(l => bankAccounts[l.config_bank_account_id]);
        const firstAccount = linked.find(l => !!l);
        if (!firstAccount) return undefined;

        const template: CreateBankAccountTempate = {
            owner_address: firstAccount.owner_address,
            owner_city: firstAccount.owner_city,
            owner_country_code: firstAccount.owner_country_code,
            owner_name: firstAccount.owner_name,
            owner_orgno: firstAccount.owner_orgno,
            owner_postal_code: firstAccount.owner_postal_code,
        };
        return template;
    }
);

const TransferSource = ({ entry }: {entry: PayoutDestinationTransfersEntry}) => {
    const { t } = useTranslation('payout');
    if (entry.inbound_payment_provider) {
        return <GatewayBadge>{entry.inbound_payment_provider}</GatewayBadge>;
    }
    if (entry.transfer_source_payout_destination_id) {
        return <SellerBadge>{t('payout_destination.title')}: {entry.transfer_source_payout_destination_id}</SellerBadge>;
    }
    if (entry.type === 'transfer' && parseFloat(entry.amount) < 0) {
        return <SellerBadge>{t('payout_destination.title')}: {entry.payout_destination_id}</SellerBadge>;
    }
    if (entry.type === 'outbound') {
        return <SellerBadge>{t('payout_destination.title')}: {entry.payout_destination_id}</SellerBadge>;
    }
    return <>—</>;
};

const TransferDestination = ({ entry }: { entry: PayoutDestinationTransfersEntry }) => {
    const { t } = useTranslation('payout');
    if (entry.type === 'inbound') {
        return <SellerBadge>{t('payout_destination.title')}: {entry.payout_destination_id}</SellerBadge>;
    }
    if (entry.transfer_destination_payout_destination_id) {
        return <SellerBadge>{t('payout_destination.title')}: {entry.transfer_destination_payout_destination_id}</SellerBadge>;
    }
    if (entry.type === 'transfer' && parseFloat(entry.amount) > 0) {
        return <SellerBadge>{t('payout_destination.title')}: {entry.payout_destination_id}</SellerBadge>;
    }
    if (entry.type === 'outbound') {
        if (entry.outbound_receiver_bank_account_type?.toLowerCase() === 'bban') {
            return <BankAccountBadge>BBAN: {entry.outbound_receiver_bank_account_number}</BankAccountBadge>;
        }
        if (entry.outbound_receiver_bank_account_type?.toLowerCase() === 'iban') {
            return <>
                <BankAccountBadge>BIC: {entry.outbound_receiver_bank_account_bic}</BankAccountBadge><br/>
                <BankAccountBadge>IBAN: {entry.outbound_receiver_bank_account_number}</BankAccountBadge>
            </>;
        }
    }
    return <>—</>;
};

const TransferReference = ({ entry }: { entry: PayoutDestinationTransfersEntry }) => {
    if (entry.transfer_reference || entry.transfer_id) {
        const id = entry.transfer_id ? <>ID<code>{entry.transfer_id}</code></> : null;
        const ref = entry.transfer_reference ? <>Ref: {entry.transfer_reference}</> : null;
        if (id && ref) {
            return <>{id}<br/>{ref}</>;
        }
        return <>{id}{ref}</>;
    }
    if (entry.outbound_message) {
        return <>{entry.outbound_message}</>;
    }
    return <>—</>;
};


const TransferPaymentMatchReportData = ({ accountId, entry }: { accountId: string;  entry: PayoutDestinationTransfersEntry }) => {
    const attachments = entry?.payment_match_report_data?.attachments || [];

    const download = (attachment: Attachment) => {
        const pathElements = attachment.key.split('/');
        const filename = pathElements[pathElements.length - 1];
        downloadSettlementByUrl(accountId, attachment.key, filename);
    };

    if (attachments.length > 0) {
        return <>
            {attachments.map(attachment => (
                <AttachmentLink
                    key={attachment.id}
                    attachment={attachment}
                    download={download}
                />
            ))}</>;
    }
    return <>—</>;
};

const createFirstBankAccountSelector = (accountId: string, pdId: string) => createSelector(
    externalSelectors.links(accountId).links,
    externalSelectors.bankAccounts(accountId).bankAccountsRaw,
    (links, bankAccounts) => {
        const linked = links
            .filter(l => l.payout_destination_config_id === pdId)
            .map(l => bankAccounts[l.config_bank_account_id]);
        return linked.find(l => !!l);
    }
);

type EditFieldProps = {
    payoutDestination: PayoutDestinationConfig;
    fieldName: 'name' | 'description' | 'reference';
};
const EditableField = ({
    payoutDestination,
    fieldName,
}: EditFieldProps) => {
    const [editing, setEditing] = useState(false);
    const [value, setValue] = useState(payoutDestination[fieldName]);
    const update = useActions('payout.config').updatePayoutDestination;
    const accountId = useAccountId();

    const onBlur = async () => {
        setEditing(false);
        if (value !== payoutDestination.reference) {
            await update(accountId, payoutDestination.id, { [fieldName]: value });
        }
    };

    return (
        <Input
            className="minimal"
            value={value}
            onChange={e => setValue(e.currentTarget.value)}
            onFocus={() => setEditing(true)}
            onBlur={onBlur}
            required={editing}
            name={fieldName}
            placeholder=""
        />
    );
};

const approvalsSelector = (accountId: string, payoutDestinationId: string) => createSelector(
    approvalsStateSelector(accountId),
    ({ loading, approvals }) => ({
        loading,
        // typescript behaves rather odd here for some reason..
        approvals: approvals.filter(x => x.payout_destination_id === payoutDestinationId),
    })
);

export default function PayoutDestination() {
    const { t, i18n } = useTranslation(['app', 'payout']);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const [selectBankAccountDialogOpen, setSelectBankAccountDialogOpen] = useState(false);
    const [isAddingNewLocation, setAddingNewLocation] = useState(false);
    const [selectedBankAccount, setSelectedBankAccount] = useState<ConfigBankAccount>();

    const { urlAccountId: accountId } = useAccountIds();
    const {
        payoutDestinationConfigId,
    } = useRouteMatch<{ payoutDestinationConfigId: string }>().params;


    const createLocation = useActions('settings.locations').createLocation;

    const {
        getBankAccountPayoutDestinations,
        getBankAccounts,
        getPayoutDestination,
        deletePayoutDestination,
        createConfigBankAccountPayoutDestination,
        getPayoutDestinationBalance,
        getPayoutDestinationTransfers,
    } = useActions('payout.config');
    const { getSellerApprovals } = useActions('management.approvals');

    useEffect(() => {
        if (accountId) {
            getPayoutDestination(accountId, payoutDestinationConfigId);
            getBankAccountPayoutDestinations(accountId);
            getBankAccounts(accountId);
            getSellerApprovals(accountId, {});
            getPayoutDestinationBalance(accountId, payoutDestinationConfigId);
            getPayoutDestinationTransfers(accountId, payoutDestinationConfigId, undefined);
        }
    }, [accountId, payoutDestinationConfigId, getPayoutDestination, getBankAccountPayoutDestinations, getBankAccounts, getSellerApprovals, getPayoutDestinationBalance, getPayoutDestinationTransfers]);

    const loadNextTransfersPage = (page: string) => {
        console.log('loadNextTransfersPage', page);
        getPayoutDestinationTransfers(accountId, payoutDestinationConfigId, page);
    };

    const {
        loading,
        payoutDestination,
    } = useSelector(
        useMemo(() => createPdSelector(accountId, payoutDestinationConfigId), [accountId, payoutDestinationConfigId])
    );


    const {
        payoutDestinationBalances,
    } = useSelector(
        useMemo(() => createPdBalancesSelector(accountId, payoutDestinationConfigId), [accountId, payoutDestinationConfigId])
    );

    const {
        payoutDestinationTransfers,
        nextPageToken,
    } = useSelector(
        useMemo(() => createPdTransfersSelector(accountId, payoutDestinationConfigId), [accountId, payoutDestinationConfigId])
    );

    const payoutDestinationId = payoutDestination?.payout_destination_id || '';

    const {
        approvals,
    } = useSelector(
        useMemo(() => approvalsSelector(accountId, payoutDestinationId), [accountId, payoutDestinationId])
    );

    const unlinkedBankAccounts = useSelector(
        useMemo(() => createUnlinkedBankAccountsSelector(accountId, payoutDestinationId), [accountId, payoutDestinationId])
    );

    const template = useSelector(
        useMemo(() => createBankAccountTemplateSelector(accountId, payoutDestinationConfigId), [accountId, payoutDestinationConfigId])
    );

    const firstBankAccount = useSelector(
        useMemo(() => createFirstBankAccountSelector(accountId, payoutDestinationConfigId), [accountId, payoutDestinationConfigId])
    );

    const onConfirmBankAccountSelection = async () => {
        if (!(selectedBankAccount && accountId)) return;
        if (await createConfigBankAccountPayoutDestination(accountId, {
            config_bank_account_id: selectedBankAccount.id,
            payout_destination_config_id: payoutDestinationConfigId,
            is_default: false,
        })) {
            setSelectBankAccountDialogOpen(false);
        }
    };

    const onConfirmAddingNewLocation = async (values: LocationFormValues) => {
        const location = mapValues(values);
        const geoLocation = await getGeoLocation(location.address!, i18n.language);

        await createLocation({
            ...location,
            address: {
                ...location.address!,
                ...(geoLocation || {}),
            },
        }, values.accountId);
    };

    const onSelectBankAccount = (_: string, account: string | ConfigBankAccount | null) => {
        if (!account || typeof account === 'string') {
            setSelectedBankAccount(undefined);
            return;
        }
        setSelectedBankAccount(account);
    };

    if (!accountId) {
        return (
            <Page
                title={
                    <React.Fragment>
                        <Title>{t('payout:payout_destination.title')}</Title>
                    </React.Fragment>
                }
            >
                <>
                    <Card title={''}>
                        <FinishAccountInfobox context="payout_destination" />
                        <ContentMedium />
                        <ContentLong />
                        <ContentShort />
                    </Card>
                </>
            </Page>
        );
    }

    return (
        <Page
            title={<>
                <Back />
                <Title>
                    {t('payout:payout_destination.title')}
                </Title>
            </>}
        >
            <Card marginBottom={distances.normal}>
                <View justify="flex-start" alignItems="flex-start">
                    <H2>{payoutDestination?.payout_destination_id}</H2>
                    {/* <Box marginLeft="auto">
                        <Button
                            className="alt"
                            onClick={() => setDeleteDialogOpen(true)}
                        >
                            {t('payout:payout_destination.delete_dialog.title')}
                        </Button>
                    </Box> */}
                </View>
                <Grid>
                    <GridItem>
                        <Dl>
                            <Dt doubleColumn>{t('payout:payout_destination.name')}</Dt>
                            <Dd>{payoutDestination && <EditableField fieldName="name" payoutDestination={payoutDestination} />}</Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>{t('payout:payout_destination.description')}</Dt>
                            <Dd>{payoutDestination && <EditableField fieldName="description" payoutDestination={payoutDestination} />}</Dd>
                        </Dl>
                        <Dl>
                            <Dt doubleColumn>{t('payout:payout_destination.reference')}</Dt>
                            <Dd>{payoutDestination && <EditableField fieldName="reference" payoutDestination={payoutDestination} />}</Dd>
                        </Dl>
                    </GridItem>
                    <GridItem>
                        {!payoutDestination?.deleted_at && <Dl>
                            <Dt>{t('payout:payout_destination.status')}</Dt>
                            <Dd><PayoutDestinationStatus status="PAYOUT_DESTINATION" /></Dd>
                        </Dl>}
                        <Dl>
                            <Dt>{t('payout:payout_destination.created_at')}</Dt>
                            <Dd><DateTime isoString={payoutDestination?.created_at.toISOString() ?? ''} /></Dd>
                        </Dl>
                        <Dl>
                            <Dt>{t('payout:payout_destination.updated_at')}</Dt>
                            <Dd><DateTime isoString={payoutDestination?.updated_at.toISOString() ?? ''} /></Dd>
                        </Dl>
                        {payoutDestination?.deleted_at && <Dl>
                            <Dt doubleColumn>{t('payout:payout_destination.deleted_at')}</Dt>
                            <Dd><DateTime isoString={payoutDestination?.deleted_at.toISOString() ?? ''} /></Dd>
                        </Dl>}
                    </GridItem>
                </Grid>
                {loading && <LoadingOverlay />}
            </Card>
            <Card marginBottom={distances.normal}>
                <View justify="space-between">
                    <H2>{t('payout:config.bank_accounts')}</H2>
                    <MenuButton
                        name="payout_destination_menu"
                        target={(onClick) =>
                            <PageButton onClick={onClick}>
                                <Icon icon="plus" />
                                {t('payout:bank_account.add')}
                            </PageButton>
                        }
                        position="bottom-right"
                    >
                        <View direction="column" justify="flex-start" alignItems="flex-start">
                            <MenuItem
                                onClick={() => {
                                    setSelectBankAccountDialogOpen(true);
                                }}
                                disabled={unlinkedBankAccounts.length === 0}
                            >
                                {t('payout:payout_destination.link_existing_bank_account_dialog.title')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    setCreateDialogOpen(true);
                                }}
                            >
                                {t('payout:bank_account.create_dialog.title')}
                            </MenuItem>
                        </View>
                    </MenuButton>
                </View>
                <Hr/>
                <PayoutDestinationBankAccounts payoutDestinationConfigId={payoutDestinationConfigId} accountId={accountId} />
            </Card>
            <Card marginBottom={distances.normal}>
                <View justify="space-between">
                    <H2>{t('payout:config.payout_destination_balances')}</H2>
                </View>
                <Hr />
                {payoutDestinationBalances?.length === 0 && <span>{t('payout:config.payout_destination_balances_empty')}</span>}
                {payoutDestinationBalances?.map((balance) => (<p><PayoutMoney currency={balance.currency} amount={balance.amount} boldCurrency /> </p>))}
            </Card>
            <Card marginBottom={distances.normal}>
                <View justify="space-between">
                    <H2>{t('payout:config.payout_destination_transfers')}</H2>
                </View>
                <Hr />
                {payoutDestinationTransfers?.length === 0 && <span>{t('payout:config.payout_destination_transfers_empty')}</span>}
                {(payoutDestinationTransfers?.length || 0) > 0 && (
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>
                                    {t('payout:config.payout_destination_transfers_fields.id')}
                                </Th>
                                <Th>
                                    {t('payout:config.payout_destination_transfers_fields.date')}
                                </Th>
                                <Th>
                                    {t('payout:config.payout_destination_transfers_fields.amount')}
                                </Th>
                                <Th>
                                    {t('payout:config.payout_destination_transfers_fields.type')}
                                </Th>
                                <Th>
                                    {t('payout:config.payout_destination_transfers_fields.source')}
                                </Th>
                                <Th>
                                    {t('payout:config.payout_destination_transfers_fields.destination')}
                                </Th>
                                <Th>
                                    {t('payout:config.payout_destination_transfers_fields.reference')}
                                </Th>
                                <Th>
                                    {t('payout:config.payout_destination_transfers_fields.reports')}
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {payoutDestinationTransfers?.map((entry) => (<Tr>
                                <Td><code>{entry.ledger_entry_id}</code></Td>
                                <Td><DateTime isoString={entry.created_at} /></Td>
                                <Td className="align-right"><PayoutMoney currency={entry.currency} amount={entry.amount} boldCurrency /></Td>
                                <Td>{t('payout:config.payout_destination_transfers_types', { context: entry.type })}</Td>
                                <Td><TransferSource entry={entry} /></Td>
                                <Td><TransferDestination entry={entry} /></Td>
                                <Td><TransferReference entry={entry} /></Td>
                                <Td><TransferPaymentMatchReportData accountId={accountId} entry={entry} /></Td>
                            </Tr>))}
                        </Tbody>
                    </Table>
                )}
                {nextPageToken && <Center><Button className="alt" onClick={() => loadNextTransfersPage(nextPageToken)}>{t('payout:config.payout_destination_transfers_load_more')}</Button></Center>}
            </Card>
            <Card>
                <View justify="space-between">
                    <H2>{t('payout:payout_destination.locations')}</H2>
                    <PageButton disabled={!firstBankAccount} onClick={() => setAddingNewLocation(true)}>
                        <Icon icon="plus" />
                        {t('payout:payout_destination.add_a_location')}
                    </PageButton>
                </View>
                <Hr />
                {firstBankAccount ? <SellerLocations organizationNumber={firstBankAccount?.owner_orgno} accountId={accountId} /> : <span>{t('payout:payout_destination.no_bank_account_location')}</span>}
            </Card>
            <ConfirmationDialog
                open={deleteDialogOpen}
                onCancel={() => setDeleteDialogOpen(false)}
                onOk={() => deletePayoutDestination(accountId, payoutDestinationConfigId)}
                cancelButtonText={t('payout:payout_destination.delete_dialog.cancel')}
                okButtonText={t('payout:payout_destination.delete_dialog.delete')}
                title={t('payout:payout_destination.delete_dialog.title')}
            >
                <P>
                    {t('payout:payout_destination.delete_dialog.message')}
                </P>
            </ConfirmationDialog>
            {template && <CreateBankAccountDialog
                template={template}
                payoutDestination={{
                    payout_destination_id: payoutDestination?.payout_destination_id ?? '',
                    reference: payoutDestination?.reference,
                }}
                open={createDialogOpen}
                onClose={() => setCreateDialogOpen(false)}
            />}
            <ConfirmationDialog
                open={selectBankAccountDialogOpen}
                onCancel={() => setSelectBankAccountDialogOpen(false)}
                onOk={onConfirmBankAccountSelection}
                cancelButtonText={t('payout:payout_destination.link_existing_bank_account_dialog.cancel')}
                okButtonText={t('payout:payout_destination.link_existing_bank_account_dialog.link')}
                title={t('payout:payout_destination.link_existing_bank_account_dialog.title')}
                disableOk={!selectedBankAccount}
            >
                <P>
                    {t('payout:payout_destination.link_existing_bank_account_dialog.message')}
                </P>
                <BankAccountAutocomplete
                    name="bankAccount"
                    onChange={onSelectBankAccount}
                    required
                    bankAccounts={unlinkedBankAccounts}
                    value={selectedBankAccount}
                />
            </ConfirmationDialog>
            <SellerLocationForm
                organizationNumber={template?.owner_orgno}
                asDialog={{
                    onClose: () => setAddingNewLocation(false),
                    open: isAddingNewLocation,
                    cancelText: t('payout:payout_destination.location_form.cancel'),
                    saveText: t('payout:payout_destination.location_form.save'),
                    title: t('payout:payout_destination.add_a_location'),
                }} onSubmit={onConfirmAddingNewLocation} autoSave={false}  />
            <AllowDinteroRoot visibleFrame>
                <View direction="column" alignItems="flex-start" justify="flex-start">
                    <H3>Approvals</H3>
                    <View direction="column" alignItems="flex-start" justify="flex-start" gap={16}>
                        {approvals.map((approval) => (
                            <View justify="space-between" key={approval.id} wrap="wrap" gap={4}>
                                <ButtonLink
                                    to={`/${accountId}/settings/sellers/approvals/${approval.id}?isPayoutDestination=true`}
                                >
                            Approval {approval.id}
                                </ButtonLink>

                                <DateTime isoString={approval.created_at || ''} boldTime />
                                <PayoutDestinationStatus status={approval.case_status || 'ERROR'} activeAsPayoutDestination />
                            </View>))}
                    </View>
                </View>
            </AllowDinteroRoot>
        </Page>
    );
}


const Center = styled.div`
    display: flex;
    justify-content: center;
`;


const Badge = styled.span`
    font-weight: 700;
    text-shadow: rgba(255, 255, 255, 0.5) 1px 1px 1px;
    font-size: 11px;
    padding-top: 2px;
    padding-right: 8px;
    padding-left: 8px;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 2px;
`;
const SellerBadge = styled(Badge)`
    background-color: #f0f0f0e8;
`;
const GatewayBadge = styled(Badge)`
    background-color: #efccefe8;
`;
const BankAccountBadge = styled(Badge)`
    background-color: #bcffc1e8;
`;
