import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { getCurrentLanguage } from '../../i18n';
import { distances } from '../../styles/constants';
import { Button, ButtonGroup } from '../Buttons';
import { Dropdown } from '../Forms';
import Modal from '../Modal';
import { H2, P } from '../Typography';
import View from '../View';

const width = 27;

const Flag = styled.img`
    width: ${width}px;
    margin: 0;
    padding: 3px;
    cursor: pointer;
`;

const flags = [
    {
        value: 'no',
        label: <View gap={8}><Flag src={`${process.env.PUBLIC_URL}/assets/flags/no.svg`} alt="Norsk" title="Norsk" />
            <P>Norsk</P>
        </View>,
    },
    {
        value: 'en',
        label: <View gap={8}><Flag src={`${process.env.PUBLIC_URL}/assets/flags/uk.svg`} alt="English" title="English" />
            <P>English</P>
        </View>,
    },
    {
        value: 'sv',
        label: <View gap={8}><Flag src={`${process.env.PUBLIC_URL}/assets/flags/se.svg`} alt="Svenska" title="Svenska" />
            <P>Svenska</P>
        </View>,
    },
];

export default function ChangeLanguage() {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const language = getCurrentLanguage(i18n);
    const currentLanguage = flags.find(({ value }) => value === language);
    const otherLanguages = flags.filter(({ value }) => value !== language);

    return (
        <Modal onClose={history.goBack}>
            <H2>{t('app:change_language.title')}</H2>
            <Wrapper>
                {currentLanguage && <Dropdown
                    name="language"
                    label={t('app:change_language.language_list_title')}
                    value={currentLanguage}
                    onChange={(e) => i18n.changeLanguage(e.value)}
                    options={otherLanguages}
                    required
                    style={{
                        paddingTop: 9,
                    }}
                />}
            </Wrapper>
            <ButtonGroup>
                <Button onClick={history.goBack}>{t('app:change_language.close')}</Button>
            </ButtonGroup>
        </Modal>
    );
};


const Wrapper = styled(View)`
    margin-block: ${distances.small};
    flex-direction: column;
    align-items: unset;
`;
