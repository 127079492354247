import { HasSecretClientResponse, Grant } from '../types/management-auth';

export const clientCsv = (accountId: string, client: HasSecretClientResponse, grants: Grant[]): string => {
    const activeGrants = grants.filter(grant => grant.deleted_at === undefined);
    const headers = 'account_id,client_id,client_secret';
    const row = `${accountId},${client.client_id || ''},${client.client_secret}`;

    if (activeGrants.length === 0) {
        return [headers, row].join('\n');
    }

    return [
        `${headers},client_audience`,
        activeGrants
            .map(grant => `${row},${grant.audience}`)
            .join('\n'),
    ].join('\n');
};
