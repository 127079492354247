import React, { Component } from 'react';
import styled from 'styled-components/macro';

import { colors, distances, palette } from '../../../styles/constants';
import Dropdown, { DropdownOption } from '../../../components/Forms/Dropdown';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withAccountIds, AccountIdsProps } from '../../../auth/accessToken/withAccountIds';
import { InputValidation } from '../../../components/Forms';

import { Label } from '../../../components/Typography';

export interface ClientAudienceDropdownProps extends WithTranslation, AccountIdsProps {
    value: string;
    name: string;
    label: string;
    placeholder: string;
    onChange: (value: string) => void;
    audienceRoot: string;
    validation?: InputValidation;
}

class ClientAudienceDropdown extends Component<ClientAudienceDropdownProps> {
    render() {
        const { label, name, value, onChange, audienceRoot, validation, t, accountIds } = this.props;

        const labelTitle = (accountId: string) =>
            t((accountId || '').startsWith('P') ? 'environments.production' : 'environments.test');
        const options: DropdownOption[] = accountIds.map(accountId => ({
            label: (
                <span>
                    {labelTitle(accountId || '')} <Muted>{`${audienceRoot}/${accountId}`}</Muted>
                </span>
            ),
            value: `${audienceRoot}/${accountId}`,
        }));
        const selectedOption = options.find(option => option.value === value);
        return (
            <Wrapper>
                <Label htmlFor={name}>{label}</Label>
                {selectedOption && (
                    <Dropdown
                        name={name}
                        value={selectedOption}
                        options={options}
                        onChange={val => {
                            onChange(val.value);
                        }}
                    />
                )}
                {validation && validation.message && (
                    <Message htmlFor={name} className={validation.state}>
                        {validation.message}
                    </Message>
                )}
            </Wrapper>
        );
    }
}

const Message = styled.label`
    position: absolute;
    top: 66px;
    left: 0;
    font-size: 11px;
    line-height: ${distances.small};

    &.invalid {
        color: ${colors.invalid};
    }

    &.valid {
        color: ${colors.valid};
    }
`;

const Wrapper = styled.div`
    position: relative;
`;

const Muted = styled.span`
    color: ${palette.neutral[500]};
    font-style: italic;
    font-size: 11px;
    margin-left: 8px;
`;

export default withAccountIds(withTranslation()(ClientAudienceDropdown));
