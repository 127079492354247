const arrItemRex = /\[([0-9]+)]/;
//** Get value from an object at a specified dot-path */
export const getValueAt = (path: string, values: any): any => {
    const trail = path.split('.');
    return trail.reduce((acc: any, crumb) => {
        if (!acc) {
            return undefined;
        }
        const accElement = acc[crumb];
        if (!accElement) {
            // see if it's an array item
            const match = crumb.match(arrItemRex);
            if (match && match[1]) {
                const keyName = crumb.replace(arrItemRex, '');
                const arr = acc[keyName];
                if (!arr) {
                    return undefined;
                }
                return arr[parseInt(match[1])];
            }
        }
        return accElement;
    }, values);
};
