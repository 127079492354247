import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { H1, P } from '../../../../components/Typography';
import Modal from '../../../../components/Modal';
import { Button } from '../../../../components/Buttons';
import { distances } from '../../../../styles/constants';
import { ProfileConfiguration } from '../../../../types/checkout';

export interface DeleteUserModalProps extends WithTranslation {
    accountId: string;
    profile: ProfileConfiguration;
    dismissRemoveProfileModal: () => void;
    removeProfile: (accountOd: string, profileId: string) => void;
}

const RemoveUserModal = (
    {
        t,
        profile,
        accountId,
        removeProfile,
        dismissRemoveProfileModal,
    }: DeleteUserModalProps
) => {
    return (
        <Modal>
            <H1>{t('settings.remove_payment_profile.title')}</H1>
            <TextWrapper>
                <P>{t('settings.remove_payment_profile.description')}</P>
            </TextWrapper>
            <Button
                onClick={() => {
                    removeProfile(accountId, profile.id || '');
                }}
            >
                {t('settings.remove_payment_profile.remove_payment_profile')}
            </Button>
            <ButtonSpacer />
            <Button className="alt" onClick={dismissRemoveProfileModal}>
                {t('settings.remove_payment_profile.go_back')}
            </Button>
        </Modal>
    );
};

export default withTranslation()(RemoveUserModal);

const ButtonSpacer = styled.span`
    display: inline-block;
    width: ${distances.tiny};
`;

const TextWrapper = styled.div`
    margin-top: ${distances.small};
    margin-bottom: ${distances.normal};
`;
