import React from 'react';
import { withTranslation } from 'react-i18next';
import InputFilter from '../../../../components/Filters/InputFilter';
import { FilterProps } from './FilterProps';

export type RuleTypeFilterProps = FilterProps

const RuleTypeFilter = (
    {
        queryParams,
        t,
        onChange,
    }: RuleTypeFilterProps
) => {
    const value = queryParams.get('rule_type') || '';

    return (
        <InputFilter
            label={value ? value : ''}
            name="rule_type"
            title={t('payout_rules.payout_rules.filter.rule_type.title')}
            value={value}
            placeholder={t('payout_rules.payout_rules.filter.rule_type.placeholder')}
            validate={(value: string) => {
                if (!value) {
                    return 'error';
                }
                return;
            }}
            autoFocus={true}
            onApply={(value) => {
                onChange({ rule_type: value ? [value] : []});
            }}
            onDelete={(_) => {
                onChange({
                    rule_type: [],
                });
            }}
        />
    );
};

export default withTranslation()<React.ComponentType<RuleTypeFilterProps>>(RuleTypeFilter);
