import { createSwitchlessReducer } from '../../util/switchlessReducer';
import namespace from './namespace';
import type { Dispatch } from 'redux';
import fulfill from '../../fulfill';
import { CORE_API_HOSTNAME } from '../../env';
import { Account, UpdateAccount } from '../../types/management-auth';
import { updateAuthenticatedUser } from '../../auth/accessToken/actions';

export interface State {
    isLoading: boolean;
    isNotFound: boolean;
    account_id: string;
    account: Account | undefined;
};

export const initialState: State = {
    isLoading: false,
    account_id: '',
    account: undefined,
    isNotFound: false,
};

type Requested = { account_id: string };
type Received = Requested & { account: Account };
type NotFound = Requested;

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        startLoading: (_: State, { account_id }: Requested) => {
            return {
                isLoading: true,
                account_id,
                account: undefined,
                isNotFound: false,
            };
        },
        setAccount: (state: State, { account_id, account }: Received): State => {
            if (state.account_id !== account_id) {
                // disregard old response for wrong account or old query
                return state;
            }
            return {
                ...state,
                isLoading: false,
                isNotFound: false,
                account,
            };
        },
        setNotFound: (state: State, { account_id }: NotFound) => {
            if (state.account_id !== account_id) {
                // disregard old response for wrong account or old query
                return state;
            }
            return {
                isLoading: false,
                account_id,
                account: undefined,
                isNotFound: true,
            };
        },
    },
});

export const createAccountActions = (dispatch: Dispatch) => {
    const fetchAccount = async (account_id: string) => {
        dispatch(actions.startLoading({ account_id }));
        fulfill.get({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/management/settings`,
            handlers: {
                200: (account: Account) => {
                    dispatch(actions.setAccount({ account_id, account }));
                },
                403: () => {
                    // user does not have scope to read accounts
                    dispatch(actions.setNotFound({ account_id }));
                },
            },
        });
    };
    const updateAccount = async (account_id: string, account: UpdateAccount) => {
        dispatch(actions.startLoading({ account_id }));
        fulfill.put({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/management/settings`,
            json: account,
            handlers: {
                200: (account: Account) => {
                    dispatch(actions.setAccount({ account_id, account }));
                    dispatch(updateAuthenticatedUser(account_id));
                },
            },
        });
    };
    return {
        fetchAccount,
        updateAccount,
    };
};
