import { ApplicationEntityItem } from './types';
import xledgerLogo from './assets/xledger.logo.png';
import powerofficeGoLogo from './assets/poweroffice-go.logo.webp';
import tripletexLogo from './assets/tripletex.logo.png';
import twentyFourSevenOfficeLogo from './assets/24sevenoffice.logo.svg';
import woocommerceLogo from './assets/woocommerce.logo.svg';
import magentoLogo from './assets/magento.logo.png';
import nopcommerceLogo from './assets/nopcommerce.logo.svg';
import optimizelyLogo from './assets/optimizely.logo.svg';
import silentTouchLogo from './assets/extenda-go.logo.svg';

export const EMONKEY_HREF = 'https://www.emonkey.no/integrasjoner/dintero/';

export const ACCOUNTING_INTEGRATIONS: ApplicationEntityItem[] = [
    {
        id: 'xledger',
        name: 'xledger',
        logo: xledgerLogo,
        type: 'accounting',
        provider: 'eMonkey',
        href: EMONKEY_HREF,
    },
    {
        id: 'poweroffice-go',
        name: 'PowerOffice Go',
        logo: powerofficeGoLogo,
        type: 'accounting',
        provider: 'eMonkey',
        href: EMONKEY_HREF,
    },
    {
        id: 'tripletex',
        name: 'Tripletex',
        logo: tripletexLogo,
        type: 'accounting',
        provider: 'eMonkey',
        href: EMONKEY_HREF,
    },
    {
        id: '24sevenoffice',
        name: '24SevenOffice',
        logo: twentyFourSevenOfficeLogo,
        type: 'accounting',
        provider: 'eMonkey',
        href: EMONKEY_HREF,
    },
];

export const ECOMMERCE_INTEGRATIONS: ApplicationEntityItem[] = [
    {
        id: 'woocommerce',
        name: 'WooCommerce',
        type: 'ecommerce',
        logo: woocommerceLogo,
        provider: 'Krokedil',
        href: 'https://wordpress.org/plugins/dintero-checkout-for-woocommerce/',
    },
    {
        id: 'magento',
        name: 'Magento',
        logo: magentoLogo,
        type: 'ecommerce',
        provider: 'Dintero',
        href: 'https://commercemarketplace.adobe.com/dintero-hp.html',
    },
    {
        id: 'nopcommerce',
        name: 'nopCommerce',
        logo: nopcommerceLogo,
        type: 'ecommerce',
        provider: 'Digitroll',
        href: 'https://www.nopcommerce.com/',
    },
    {
        id: 'optimizely',
        name: 'Optimizely',
        logo: optimizelyLogo,
        type: 'ecommerce',
        provider: 'Dintero',
        href: 'https://github.com/Dintero/Dintero.Checkout.Episerver',
    },
];

export const POS_INTEGRATIONS: ApplicationEntityItem[] = [
    {
        id: 'silent-touch',
        name: 'extendaGO Silent Touch',
        logo: silentTouchLogo,
        type: 'pos',
        provider: 'ExtendaGO',
        href: 'https://www.extendago.com/no/losninger/silent-touch/',
    },
];
