import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getAndClearPullRequestRedirect } from '../../RedirectToPullRequestRoute';

import { State } from '../../reducer';
import { AuthenticatedAccountUser } from '../../types/management-auth';
import { getInitialAccount } from './helpers';
import { getAccountUser } from './selectors';

const mapStateToProps = (state: State) => ({
    accountUser: getAccountUser(state),
});

interface RedirectToAccountProps {
    accountUser: AuthenticatedAccountUser;
}
/** Redirect a logged in user to the initial account */
const RedirectToAccount = ({ accountUser }: RedirectToAccountProps) => {
    // Follow pull request redirect if it exists
    const prRedirect = getAndClearPullRequestRedirect();
    if (prRedirect) {
        return <Redirect to={prRedirect} />;
    }
    const accountId = getInitialAccount(accountUser);
    return <Redirect to={`/${accountId}/`} />;
};

export default connect(mapStateToProps)(RedirectToAccount);
