import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { distances } from '../../../styles/constants';
import { Dl, Dt, Dd } from '../../../components/DescriptionList/Vertical';
import { H3 } from '../../../components/Typography';
import { Account } from '../../../types/management-auth';
import { OrganizationNumber } from '../../../components/Formatters';
import Address from '../../../components/Address';
import Card from '../../../components/Card';
import CardArea from './CardArea';
import Hr from '../../../components/Hr';
import { checkAccountingAndBillingEqual } from '../../../settings/account/helpers';

export interface BillingInformationProps extends WithTranslation {
    account: Account;
}

const BillingInformation = (
    {
        t,
        account,
    }: BillingInformationProps
) => {
    const companyBillingEqual = checkAccountingAndBillingEqual(account.company, account.billing);

    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('settings.account.billing_info')}</H3>
            <Hr />
            <CardArea>
                {!companyBillingEqual && account.billing.organization_number && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.business_name')}</Dt>
                        <Dd>{account.billing.business_name}</Dd>
                    </Dl>
                )}
                {!companyBillingEqual && account.billing.organization_number && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.organization_number')}</Dt>
                        <Dd>
                            <OrganizationNumber
                                country={account.billing.address.country}
                                value={account.billing.organization_number || account.company.address.country}
                            />
                        </Dd>
                    </Dl>
                )}
                {account.billing.email && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.email')}</Dt>
                        <Dd>{account.billing.email}</Dd>
                    </Dl>
                )}
                {account.billing.reference && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.billing_reference')}</Dt>
                        <Dd>{account.billing.reference}</Dd>
                    </Dl>
                )}
                {!companyBillingEqual && account.billing.address && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.address')}</Dt>
                        <Dd>
                            <Address {...account.billing.address} />
                        </Dd>
                    </Dl>
                )}
            </CardArea>
        </Card>
    );
};

export default withTranslation()(BillingInformation);
