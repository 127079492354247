import { ProfileConfiguration, CheckoutConfig } from '../../../types/checkout';
import { Account } from '../../../types/management-auth';

export enum ActionTypes {
    GetProfile = '[settings/profileDetails] GET_PROFILE',
    FetchProfileResponse = '[settings/profileDetails] FETCH_PROFILE_RESPONSE',
    OpenRemoveProfileModal = '[settings/profileDetails] OPEN_DELETE_PROFILE_MODAL',
    DismissRemoveProfileModal = '[settings/profileDetails] DISMISS_DELETE_PROFILE_MODAL',
    RemoveProfile = '[settings/profileDetails] DELETE_PROFILE',
    FetchRemoveProfileResponse = '[settings/profileDetails] DELETE_PROFILE_RESPONSE',
}

export interface GetProfile {
    type: ActionTypes.GetProfile;
    payload: {
        accountId: string;
        profileId: string;
    };
}

export interface FetchProfileResponse {
    type: ActionTypes.FetchProfileResponse;
    payload: {
        profile: ProfileConfiguration;
        configuration: CheckoutConfig;
        account: Account;
    };
    meta: {
        cause: GetProfile;
    };
}

export function getProfile(accountId: string, profileId: string): GetProfile {
    return {
        type: ActionTypes.GetProfile,
        payload: {
            accountId,
            profileId,
        },
    };
}

export interface DismissRemoveProfileModal {
    type: ActionTypes.DismissRemoveProfileModal;
    payload: {};
}

export function dismissRemoveProfileModal(): DismissRemoveProfileModal {
    return {
        type: ActionTypes.DismissRemoveProfileModal,
        payload: {},
    };
}

export interface OpenRemoveProfileModal {
    type: ActionTypes.OpenRemoveProfileModal;
    payload: {};
}

export function openRemoveProfileModal(): OpenRemoveProfileModal {
    return {
        type: ActionTypes.OpenRemoveProfileModal,
        payload: {},
    };
}

export interface RemoveProfile {
    type: ActionTypes.RemoveProfile;
    payload: {
        accountId: string;
        profileId: string;
    };
}

export function removeProfile(accountId: string, profileId: string): RemoveProfile {
    return {
        type: ActionTypes.RemoveProfile,
        payload: {
            accountId,
            profileId,
        },
    };
}

export interface FetchRemoveProfileResponse {
    type: ActionTypes.FetchRemoveProfileResponse;
    payload: {};
    meta: {
        cause: RemoveProfile;
    };
}

export type Actions =
    | GetProfile
    | FetchProfileResponse
    | OpenRemoveProfileModal
    | DismissRemoveProfileModal
    | RemoveProfile
    | FetchRemoveProfileResponse;
