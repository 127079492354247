import { connect } from 'react-redux';

import { getAccounts, getExchangeToken, getPartnerUsers } from '../actions';
import { State } from '../../../reducer';
import { getAccountSelector } from '../selectors';

import Merchants from '../components/Merchants';

const mapStateToProps = (state: State) => ({
    accounts: getAccountSelector(state),
});

const mapDispatchToProps = {
    getAccounts,
    getExchangeToken,
    getPartnerUsers,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Merchants);
