import React from 'react';
import styled from 'styled-components/macro';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';

import { logout } from '../cognito';
import { distances, grid, colors, border } from '../../styles/constants';
import Modal from '../../components/Modal';
import { H2, P } from '../../components/Typography';
import { Button, ButtonGroup } from '../../components/Buttons';
import * as selectors from './selectors';
import { State } from '../../reducer';
import { AuthenticatedAccountUser } from '../../types/management-auth';
import { getNonDupedAccountList } from './getAccountList';

const mapStateToProps = (state: State) => ({
    accountUser: selectors.getAccountUser(state),
});

interface MatchParams {
    accountId: string;
}

export interface NoAccessProps extends WithTranslation, RouteComponentProps<MatchParams> {
    accountUser: AuthenticatedAccountUser;
    subAccountId?: string;
}

const NoAccess = ({ t, match, history, accountUser, subAccountId }: NoAccessProps) => (
    <Modal>
        <H2>{t('errors.forbidden_account.title')}</H2>
        <ErrorWrapper>
            <P className="margin-bottom">
                <Trans
                    i18nKey="errors.forbidden_account.description"
                    values={{ account_id: subAccountId || match.params.accountId }}
                >
                    <strong />
                </Trans>
            </P>
            <P className="margin-bottom">{t('errors.forbidden_account.cause')}</P>
            <P className="margin-bottom">
                <strong>{t('errors.forbidden_account.account_list_title')}</strong>
            </P>
            {getNonDupedAccountList(accountUser).map((account) => (
                <AccountLink key={account.account_id} to={`/${account.account_id}/`}>
                    {account.account_id} {account.business_name}
                </AccountLink>
            ))}
        </ErrorWrapper>
        <ButtonGroup>
            <Button onClick={() => logout(false)}>
                {t('errors.forbidden_account.log_in_as_different_user_call_to_action')}
            </Button>
            <Button
                className="alt"
                onClick={() => {
                    history.goBack();
                }}
            >
                {t('errors.forbidden_account.browser_back_call_to_action')}
            </Button>
        </ButtonGroup>
    </Modal>
);

const ErrorWrapper = styled.div`
    width: ${grid.spans.span6};
    padding-top: ${distances.normal};
    padding-bottom: ${distances.normal};
    font-size: 14px;
`;

const AccountLink = styled(Link)`
    position: relative;
    display: block;
    cursor: pointer;
    padding: ${distances.tiny};
    padding-right: 41px;
    color: ${colors.primary};
    font-weight: 700;
    text-decoration: none;
    border-bottom: ${border.normal} solid ${colors.borderLight};

    &:hover {
        background: ${colors.interactiveBackgroundHover};
    }

    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 41px;
        background-image: url('/assets/icons/arrow_left.svg');
        background-size: 25px 25px;
        background-position: center;
        background-repeat: no-repeat;
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: 'FlipH';
    }
`;

export default connect(mapStateToProps)(withRouter(withTranslation()(NoAccess)));
