import { useTranslation } from 'react-i18next';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

import { useActions } from '../../../Actions';
import useDraft from '../../../util/useDraft';
import { useValidatedSchema } from '../../../util/useValidatedSchema';

import { externalSelectors as selectors } from '../../../payout/merchant/config/selectors';
import type { ConfigBankAccountDraft, ConfigRfcData } from '../../../payout/types';
import { Button, ButtonGroup } from '../../../components/Buttons';

import BankAccountForm from './BankAccountForm';
import { bankAccountSchema } from './validationSchema';
import { useAccountId } from '../../../auth/useAccountId';
import { H2 } from '../../../components/Typography';
import Modal from '../../../components/Modal';
import View from '../../../components/View';

export type CreateBankAccountTempate = Pick<ConfigBankAccountDraft, Extract<keyof ConfigBankAccountDraft, `owner_${string}`>>;
export type CreateBankAccountDialogProps = {
    open: boolean;
    onClose: () => void;
    payoutDestination?: {
        payout_destination_id: string;
        reference?: string;
    };
    template?: CreateBankAccountTempate;
};

const createDialogSelector = (accountId: string) => createSelector(
    selectors.account(accountId).config,
    selectors.bankAccounts(accountId).isLoading,
    (config, loading) => ({
        onboarded: config !== null,
        loading,
    })
);

const CreateBankAccountDialog = ({ onClose, payoutDestination, template, ...props }: CreateBankAccountDialogProps) => {
    const { t } = useTranslation('payout');
    const createConfigRfc = useActions('payout.config').createConfigRfc;
    const accountId = useAccountId();
    const { loading, onboarded } = useSelector(createDialogSelector(accountId));

    const { setValue, setTouched, setSubmit, combined, dirty, clear, isTouched } = useDraft<ConfigBankAccountDraft>({
        bank_account_bic: '',
        bank_account_country_code: template?.owner_country_code ?? '',
        bank_account_currency: 'NOK',
        bank_account_number: '',
        bank_account_type: 'bban',
        is_default_for_currency: false, // TODO: Currently not editable
        nickname: '',
        owner_address: '',
        owner_city: '',
        owner_country_code: '',
        owner_name: '',
        owner_orgno: '',
        owner_postal_code: '',
        ...template,
    });

    const { hasErrors, result } = useValidatedSchema({ schema: bankAccountSchema, value: combined });

    const onDialogClose = () => {
        if (dirty) return;
        onClose();
    };

    const onSave = async () => {
        setSubmit();
        if (loading || hasErrors) return;
        const payoutDestinations = payoutDestination ? [{
            bank_account_numbers: [combined.bank_account_number],
            default_bank_account_number: null,
            payout_destination_id: payoutDestination.payout_destination_id,
            reference: payoutDestination.reference,
        }] : [];

        const rfc: ConfigRfcData = onboarded ? {
            type: 'configBankAccountCreate',
            bank_accounts: [combined],
            payout_destinations: payoutDestinations,
        } : {
            type: 'onboarding',
            bank_accounts: [{
                ...combined,
                is_default_for_currency: true,
            }],
            config_account: {
                default_bank_account_number: combined.bank_account_number,
                payout_interval_type: 'week',
                payout_interval_value: '',
                split_payout_enabled: false,
            },
            payout_destinations: payoutDestinations,
        };

        if (await createConfigRfc(accountId, rfc)) {
            onClose();
            clear();
        }
    };

    if (!props.open) {
        return null;
    }

    return (
        <Modal onClose={onDialogClose}>
            <View gap={24} direction="column" justify="flex-start" alignItems="flex-start">
                <View direction="column" justify="flex-start" alignItems="flex-start">
                    <H2>
                        {t('bank_account.create_dialog.title')}
                    </H2>
                    <BankAccountForm
                        errors={result}
                        value={combined}
                        onChange={setValue}
                        onTouched={setTouched}
                        isTouched={isTouched}
                        hideOwner={!!template}
                    />
                </View>
                <ButtonGroup>
                    <Button
                        className="alt large"
                        onClick={onClose}
                    >
                        {t('bank_account.create_dialog.cancel')}
                    </Button>
                    <Button
                        className="large"
                        onClick={onSave}
                        disabled={hasErrors || !dirty}
                    >
                        {t('bank_account.create_dialog.save')}
                    </Button>
                </ButtonGroup>
            </View>
        </Modal>
    );
};

export default CreateBankAccountDialog;
