import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounced } from './useDebounced';
import _mapValues from 'lodash/mapValues';

export type Validator<T, TParent = T, V = any> = (value: V, adjacentValues: T, rootValues: TParent) => V extends Record<string, unknown>
    ? ValidationSchemaResult<V> | (string | undefined)
    : (string | undefined)

export type ValidationSchema<T extends Record<string, unknown>, TParent extends Record<string, unknown> = T> = {
    [K in keyof T]?: NonNullable<T[K]> extends ReadonlyArray<infer I>
        ? NonNullable<I> extends Record<string, unknown>
            ? ValidationSchema<NonNullable<I>, TParent>
            : Validator<T, TParent, I> | Validator<T, TParent, I>[]
        :  NonNullable<T[K]> extends Record<string, unknown>
            ? ValidationSchema<NonNullable<T[K]>, TParent>
            : Validator<T, TParent, T[K]> | Validator<T, TParent, T[K]>[]
}

export type ValidationSchemaResult<T> = {
    [K in keyof T]: NonNullable<T[K]> extends ReadonlyArray<infer I>
        ? NonNullable<I> extends Record<string, unknown>
            ? ReadonlyArray<ValidationSchemaResult<NonNullable<I>> | undefined> | undefined
            : (string | undefined)
        :  NonNullable<T[K]> extends Record<string, unknown>
            ? ValidationSchemaResult<NonNullable<T[K]>> | undefined
            : (string | undefined)
}

export type SchemaValidator<T> = (values: T) => ValidationSchemaResult<T>

const combineValidators = <T, TParent = T>(...validators: Validator<T, TParent>[]): Validator<T, TParent> => (value, adjacentValues, rootValues) => {
    for (const validator of validators) {
        const res = validator(value, adjacentValues, rootValues);
        if (res)
            return res;
    }
    return undefined;
};

const _makeSchemaValidator = <
    T extends Record<string, any>,
    TParent extends Record<string, unknown>
>(schema: ValidationSchema<T, TParent>) => {

    const schemaWithCombinedValidators = _mapValues(schema, (validatorOrSchema) => {
        return Array.isArray(validatorOrSchema)
            ? combineValidators(...validatorOrSchema)
            : validatorOrSchema;
    }) as ValidationSchema<T, TParent>;

    const validators: Record<string, Validator<T, TParent, any>> = _mapValues(schemaWithCombinedValidators, (validatorOrSchema) => {
        if (typeof validatorOrSchema === 'function')
            return validatorOrSchema;

        const innerSchemaValidator = _makeSchemaValidator(validatorOrSchema as any);
        return ((value: any, _: any, rootValues: TParent) => {
            const innerValidator = innerSchemaValidator(rootValues);
            return Array.isArray(value)
                ? value.map(innerValidator)
                : innerValidator(value);
        }) as any;
    });

    return (rootValues: TParent): SchemaValidator<T> => (adjacentValues) => {
        if (adjacentValues === undefined)
            return {} as ValidationSchemaResult<T>;

        return Object.entries(validators).reduce((schemaResult, [key, validator]) => {
            schemaResult[key as keyof T] = validator(adjacentValues[key], adjacentValues, rootValues) as any;
            return schemaResult;
        }, {} as ValidationSchemaResult<T>);
    };
};

export const makeSchemaValidator = <T extends Record<string, any>>(
    schema: ValidationSchema<T>
): SchemaValidator<T> => {
    const validator = _makeSchemaValidator(schema);
    return (values) => validator(values)(values);
};

export const hasErrors = <T>(validationResult: ValidationSchemaResult<T | undefined> | ReadonlyArray<ValidationSchemaResult<T | undefined> | undefined>) => {
    for (const value of Object.values(validationResult ?? {})) {
        const error = typeof value === 'object'
            ? hasErrors(value)
            // value is not undefined, ie its a validation error
            : value !== undefined;
        if (error) return true;
    }
    return false;
};

type SchemaValidatedState<T> = {
    result: ValidationSchemaResult<T>;
    hasErrors: boolean;
}

const getValidationResult = <T extends Record<string, unknown>>(validator: SchemaValidator<T>, value: T) => {
    const result = validator(value);
    return {
        result,
        hasErrors: hasErrors(result),
    };
};

export type UseSchemaValidationOptions<T extends Record<string, unknown>> = {
    disableValidateOnMount?: boolean;
    /**
     * Use with care, can cause lag. Usefull if value changes on input blur
     */
    disableDebouncedValidation?: boolean;
    schema: ValidationSchema<T>;
    value: T;
}

export const useValidatedSchema = <T extends Record<string, unknown>>(
    { schema, value, disableValidateOnMount, disableDebouncedValidation }: UseSchemaValidationOptions<T>
) => {
    const validator = useMemo(() => makeSchemaValidator(schema), [schema]);
    const [validationResult, setValidationResult] = useState<SchemaValidatedState<T>>(() => {
        if (disableValidateOnMount) {
            return {
                hasErrors: false,
                result: {} as any,
            };
        }
        return getValidationResult(validator, value);
    });
    const validatedValueRef = useRef(value);
    const setValidationResultDebounced = useDebounced(setValidationResult, 150);
    useEffect(() => {
        if (validatedValueRef.current !== value) {
            if (disableDebouncedValidation) {
                setValidationResult(() => getValidationResult(validator, value));
            } else {
                setValidationResultDebounced(() => getValidationResult(validator, value));
            }
            validatedValueRef.current = value;
        }
        return () => setValidationResultDebounced.cancel();
    }, [setValidationResultDebounced, disableDebouncedValidation, validator, value]);

    const validateValues = () => setValidationResult(() => getValidationResult(validator, value));

    return { ...validationResult, validateValues };
};
