import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';
import {
    ActionTypes,
    FetchCreateClientResponse,
    CreateClient,
    FetchCreateGrantsResponse,
    GetAccount,
    GetAccountResponse
} from './actions';
import { Client, HasSecretClientResponse, Grant, Account } from '../../../types/management-auth';

const fetchGetAccount: SideEffectFunction<GetAccount, GetAccountResponse> = async (action) => {
    return fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/settings`,
        handlers: {
            200: (account: Account) => ({
                type: ActionTypes.GetAccountResponse,
                payload: {
                    account,
                },
            }),
        },
    });
};

const fetchCreateClient: SideEffectFunction<CreateClient, FetchCreateClientResponse> = async (action) => {
    const audience = action.payload.newGrants[0].audience;
    const account = audience.split('/').pop();
    const result: FetchCreateClientResponse = await fulfill.post<Client>({
        accountId: account || '',
        url: `${CORE_API_HOSTNAME}/v1/accounts/${account}/auth/clients`,
        json: action.payload.newClient,
        handlers: {
            200: (client: HasSecretClientResponse) => ({
                type: ActionTypes.CreateClientResponse,
                payload: {
                    client,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchCreateGrants: SideEffectFunction<FetchCreateClientResponse, FetchCreateGrantsResponse> = async (action) => {
    // Set client_id in grant
    const grants = action.meta.cause.payload.newGrants.map((grant) => ({
        ...grant,
        client_id: action.payload.client.client_id,
    }));
    //
    const results = await Promise.all(
        grants.map((grant) => {
            const audience = grant.audience;
            const account = audience.split('/').pop();
            return fulfill.post<Client>({
                accountId: account || '',
                url: `${CORE_API_HOSTNAME}/v1/accounts/${account}/auth/client-grants`,
                json: grant,
                handlers: {
                    200: (grant: Grant) => ({
                        success: true,
                        grant,
                    }),
                },
            });
        })
    );
    // raise error from http request if any
    const error = results.find((result) => result.success !== true);
    if (error) {
        return error;
    }

    // return ok result with grants
    return {
        type: ActionTypes.CreateGrantsResponse,
        payload: {
            grants: results.map((result) => result.grant),
        },
        meta: {
            cause: action,
        },
    };
};
const effects = {
    [ActionTypes.GetAccount]: fetchGetAccount,
    [ActionTypes.CreateClient]: fetchCreateClient,
    [ActionTypes.CreateClientResponse]: fetchCreateGrants,
};

export default effects;
