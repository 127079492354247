import React, { createContext, useContext } from 'react';
import styled, { keyframes } from 'styled-components/macro';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { WithTranslation, withTranslation, Trans } from 'react-i18next';
import { Location, History } from 'history';

import { State } from '../../reducer';
import { colors, distances, palette } from '../../styles/constants';
import { AuthenticatedAccountUser } from '../../types/management-auth';
import * as selectors from './selectors';
import { Toggle } from '../../components/Toggle';

const mapStateToProps = (state: State) => ({
    accountUser: selectors.getAccountUser(state),
});

const checkHasProdAccount = (accountId: string, accountUser: AuthenticatedAccountUser) => {
    const withoutLeadingChar = accountId.substr(1);
    const prodMatch = accountUser.accounts.find((account) => account.account_id === `P${withoutLeadingChar}`);
    return prodMatch !== undefined;
};

const checkUserHasAdminScope = (accountId: string, accountUser: AuthenticatedAccountUser) => {
    const currentAccount = accountUser.accounts.find((account) => account.account_id === accountId);
    if (currentAccount) {
        return (
            currentAccount.user.scope &&
            (currentAccount.user.scope.includes('admin') ||
                currentAccount.user.scope.includes('admin:accounts') ||
                currentAccount.user.scope.includes('accounts:admin'))
        );
    }
    return false;
};

const geEnvLink = (env: 'P' | 'T', path: string) => {
    const [, accountId, firstRoute, secondRoute] = path.split('/');
    const withoutLeadingChar = accountId.substr(1);
    if (!firstRoute) {
        return `/${env}${withoutLeadingChar}/`;
    } else if (!secondRoute) {
        return `/${env}${withoutLeadingChar}/${firstRoute}`;
    } else {
        return `/${env}${withoutLeadingChar}/${firstRoute}/${secondRoute}`;
    }
};

const goToProd = (location: Location, history: History) => {
    const prodLink = geEnvLink('P', location.pathname);
    history.push(prodLink);
};

const goToTest = (location: Location, history: History) => {
    const prodLink = geEnvLink('T', location.pathname);
    history.push(prodLink);
};

interface MatchParams {
    accountId: string;
}

interface EnvFrameProps extends WithTranslation, RouteComponentProps<MatchParams> {
    accountUser: AuthenticatedAccountUser;
    children?: React.ReactNode;
}

const EnvFrameContext = createContext<{
    hasEnvFrame: boolean;
    hasAdminScope?: boolean;
    hasProdAccount: boolean;
    accountId: string;
    isTest: boolean;
    envName: string;
    toggle: () => void;
        }>({ hasEnvFrame: false, toggle: () => { }, envName: 'production', hasProdAccount: false, accountId: '', isTest: false });
export const useEnvFrame = () => useContext(EnvFrameContext);

const EnvFrame = ({ t, match, location, history, accountUser, children }: EnvFrameProps) => {
    const accountId = match.params.accountId;
    const hasProdAccount = checkHasProdAccount(accountId, accountUser);
    const hasAdminScope = checkUserHasAdminScope(accountId, accountUser);
    const isTest = accountId.startsWith('T');
    const envName = isTest ? 'test' : 'production';
    const goTo = isTest ? goToProd : goToTest;

    return (
        <EnvFrameContext.Provider value={{
            hasEnvFrame: true,
            toggle: () => goTo(location, history),
            hasAdminScope,
            hasProdAccount,
            accountId,
            isTest,
            envName,
        }}>
            {hasProdAccount && (
                <AbsoluteToggle>
                    <Small>{t('env.view_test_data')}</Small>
                    <Toggle value={isTest} onClick={() => goTo(location, history)} />
                </AbsoluteToggle>
            )}
            {/* <Wrapper>
                <EnvBorder className={envName}>
                    <EnvBar className={envName}>
                        {isTest && (
                            <>
                                <Trans i18nKey="env.test_mode_message">
                                    <strong> </strong>
                                </Trans>
                                {hasAdminScope && !hasProdAccount && (
                                    <FrameLink to={`/${accountId}/settings/account/edit`}>
                                        {t('env.setup_production_link')}
                                    </FrameLink>
                                )}
                            </>
                        )}
                    </EnvBar>
                    <ChildrenWrapper>{children}</ChildrenWrapper>
                </EnvBorder>
            </Wrapper> */}
            {children}
        </EnvFrameContext.Provider>
    );
};

const FrameLink = styled(Link)`
    margin-left: ${distances.small};
    color: ${colors.text};
    &:visited {
        color: ${colors.text};
    }
    &:hover {
        color: ${colors.background};
    }
`;

const Small = styled.span`
    font-size: 11px;
`;

const AbsoluteToggle = styled.span`
    z-index: 1;
    position: absolute;
    top: 10px;
    right: ${distances.small};
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        top: 22px;
        right: 64px;
        z-index: 1001;
    }
`;

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 0;
`;

const fadeInBackground = keyframes`
  from {
    background: transparent;
  }
  to {
    background: ${palette.warning[400]};
  }
`;

const EnvBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding-top: 8px;
    padding-left: ${distances.small};
    padding-right: ${distances.small};
    padding-bottom: 12px;
    height: 36px;
    box-sizing: border-box;
    animation: ${fadeInBackground} 100ms linear forwards;

    &.production {
        background: transparent;
        animation: none;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const fadeInBorder = keyframes`
  from {
    border-color: transparent;
  }
  to {
    border-color: ${palette.warning[400]};
  }
`;

const EnvBorder = styled.div`
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 100vh;
    outline: 1px solid ${palette.warning[400]};
    box-sizing: border-box;
    animation: ${fadeInBorder} 100ms linear forwards;

    &.production {
        outline-color: transparent;
        animation: none;
    }

    @media (max-width: 768px) {
        outline: none;
        border-top: 4px solid ${palette.warning[400]};
    }
`;

const ChildrenWrapper = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export default connect(mapStateToProps)(withRouter(withTranslation()(EnvFrame)));
