import styled from 'styled-components/macro';

import { distances } from '../../../styles/constants';

const Dd = styled.dd`
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 14px;
    line-height: ${distances.normal};

    &.amount {
        text-align: right;
        min-width: 260px;
    }

    @media (max-width: 768px) {
        width: 100%;

        &.amount {
            text-align: left;
        }
    }
`;

export default Dd;
