import { Actions, ActionTypes } from './actions';
import { ProfileConfiguration, CheckoutConfig } from '../../../types/checkout';
import { Account } from '../../../types/management-auth';

export interface State {
    isLoading: boolean;
    profile: ProfileConfiguration | undefined;
    configuration: CheckoutConfig | undefined;
    account: Account | undefined;
    isShowingRemoveModal: boolean;
    isDeleted: boolean;
}

export const initialState: State = {
    isLoading: false,
    profile: undefined,
    configuration: undefined,
    account: undefined,
    isShowingRemoveModal: false,
    isDeleted: false,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.GetProfile:
            return {
                ...initialState,
                isLoading: true,
            };

        case ActionTypes.FetchProfileResponse:
            return {
                ...state,
                isLoading: false,
                profile: action.payload.profile,
                configuration: action.payload.configuration,
                account: action.payload.account,
            };

        case ActionTypes.OpenRemoveProfileModal:
            return {
                ...state,
                isShowingRemoveModal: true,
            };

        case ActionTypes.DismissRemoveProfileModal:
            return {
                ...state,
                isShowingRemoveModal: false,
            };

        case ActionTypes.FetchRemoveProfileResponse:
            return {
                ...state,
                isDeleted: true,
            };

        default:
            return state;
    }
}
