export const isInteger = (integer: string) => {
    try {
        parseFloat(integer);
        return true;
    } catch {
        return false;
    }
};

export const validateIsInteger = (message: string) => {
    return (value: string) => {
        if (!value) {
            return;
        }
        const isValid = isInteger(value);
        if (!isValid) {
            return message;
        }
    };
};
