import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '../../../components/Buttons';
import { useFormik } from 'formik';
import { colors, distances } from '../../../styles/constants';
import { ValidatedTextAreaElement } from '../../../components/Forms/TextAreaElement';
import styled from 'styled-components/macro';
import { validate, validateRequired } from '../../../helpers/validation';
import { ValidationState } from '../../../components/Forms';
import _upperFirst from 'lodash/upperFirst';
import Modal from '../../../components/Modal';
import View from '../../../components/View';

export type MarkAsDeclinedBag = {
    handleMarkAsDeclined: (connection: string, description: string) => void;
    lastConnectionEventSuccess: string | undefined;
    lastConnectionEventFailure: string | undefined;
};
type MarkAsDeclinedProps = {
    status?: 'pending' | 'enabled' | 'failed' | 'declined';
    connection: string;
    markAsDeclinedBag: MarkAsDeclinedBag;
}

export const MarkAsDeclined = ({ status, markAsDeclinedBag, connection }: MarkAsDeclinedProps) => {
    const { t } = useTranslation();
    const [showDeclineDialog, setShowDeclineDialog] = useState(false);

    const { handleMarkAsDeclined, lastConnectionEventSuccess, lastConnectionEventFailure } = markAsDeclinedBag;

    const formik = useFormik({
        initialValues: {
            description: '',
        },
        validateOnMount: true,
        validate: (values => {
            let errors: { [key: string]: string } = {};
            if (!values || Object.keys(values).length === 0) {
                // this should not happen but...
                return { error: 'account not loaded yet...' };
            }
            const validators = [
                {
                    path: 'description',
                    validator: validateRequired(t('settings.payment_connections.mark_as_declined.invalid.description')),
                },
            ];
            errors = validators.reduce((acc, elem) => {
                return validate(elem.path, elem.validator, values, acc);
            }, errors);
            return errors;
        }),
        onSubmit: (values) => {
            handleMarkAsDeclined(connection, values.description);
        },
    });

    useEffect(() => {
        if (lastConnectionEventSuccess === connection) {
            setShowDeclineDialog(false);
        }
    }, [setShowDeclineDialog, connection, lastConnectionEventSuccess]);

    if (status !== 'pending') {
        return null;
    }

    const handleCloseDialog = () => {
        formik.resetForm();
        setShowDeclineDialog(false);
    };

    return <>
        <Button
            type="button"
            className="alt"
            onClick={() => setShowDeclineDialog(true)}>
            {t('settings.payment_connections.mark_as_declined.call_to_action')}
        </Button>
        {showDeclineDialog && <Modal
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <form onSubmit={formik.handleSubmit}>
                <View gap={24} direction="column" justify="flex-start" alignItems="flex-start">
                    <ValidatedTextAreaElement
                        label={t('settings.payment_connections.mark_as_declined.fields.description', { connection: _upperFirst(connection) })}
                        name="description"
                        value={formik.values.description}
                        onChange={formik.handleChange}
                        rightAligned={false}
                        height={distances.large}
                        autoFocus={true}
                        onBlur={formik.handleBlur}
                        validation={
                            formik.touched.description &&
                                formik.errors.description
                                ? {
                                    state: ValidationState.Invalid,
                                    message: formik.errors.description,
                                }
                                : undefined
                        }
                    />
                    {lastConnectionEventFailure === connection && <ErrorMessage>{t('settings.payment_connections.mark_as_declined.error')}</ErrorMessage>}
                    <ButtonGroup>
                        <Button type="button" className="alt"
                            onClick={handleCloseDialog}>{t('settings.payment_connections.mark_as_declined.disagree')}</Button>
                        <Button type="submit" disabled={!formik.isValid}>
                            {t('settings.payment_connections.mark_as_declined.agree')}
                        </Button>
                    </ButtonGroup>
                </View>
            </form>
        </Modal>}
    </>;
};

const ErrorMessage = styled.div`
    color: ${colors.invalid};
    font-weight: 500;
    padding-left: ${distances.tiny};
`;
