import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { State } from '../../reducer';
import * as selectors from './selectors';
import { loadSubAccountTokens } from './actions';
import { LoadingOverlay } from '../../components/Loading';
import NoAccess from './NoAccess';

interface MatchParams {
    accountId: string;
    subAccountId: string;
}

const mapDispatchToProps = {
    loadSubAccountTokens,
};

const mapStateToProps = (state: State) => ({
    isLoadingSubAccountTokens: selectors.getIsLoadingSubAccountTokens(state),
    tokens: selectors.getTokens(state),
});

interface WithSubAccountAccessTokenProps extends RouteComponentProps<MatchParams> {
    children: any;
    loadSubAccountTokens: (accountId: string, subAccountId: string) => void;
    tokens: { [subAccountId: string]: string };
    isLoadingSubAccountTokens: boolean;
}

class WithSubAccountAccessToken extends Component<WithSubAccountAccessTokenProps> {
    componentDidMount() {
        const accountId = this.props.match.params.accountId;
        const subAccountId = this.props.match.params.subAccountId;
        if (accountId && subAccountId) {
            this.props.loadSubAccountTokens(accountId, subAccountId);
        }
    }

    shouldUpdate(prevProps: WithSubAccountAccessTokenProps) {
        const accountId = this.props.match.params.accountId || '';
        const prevAccountId = prevProps.match.params.accountId || '';
        const accountIdChanged = accountId && accountId.substr(1) !== prevAccountId.substr(1);

        const subAccountId = this.props.match.params.subAccountId || '';
        const prevSubAccountId = prevProps.match.params.subAccountId || '';
        const subAccountIdChanged = subAccountId && subAccountId.substr(1) !== prevSubAccountId.substr(1);

        if (accountIdChanged || subAccountIdChanged) {
            return true;
        }
        return false;
    }

    componentDidUpdate(prevProps: WithSubAccountAccessTokenProps) {
        const shouldUpdate = this.shouldUpdate(prevProps);
        if (shouldUpdate) {
            const accountId = this.props.match.params.accountId;
            const subAccountId = this.props.match.params.subAccountId;
            if (accountId && subAccountId) {
                this.props.loadSubAccountTokens(accountId, subAccountId);
            }
        }
    }

    render() {
        // Loading animation
        if (this.props.isLoadingSubAccountTokens) {
            return <LoadingOverlay></LoadingOverlay>;
        }

        // If we aren't able to get a token for this sub account
        const subAccountId = this.props.match.params.subAccountId;
        const subToken = this.props.tokens[subAccountId];
        if (!subToken) {
            return (
                <>
                    <NoAccess {...{ subAccountId }} />
                </>
            );
        }

        // With access tokens
        return <React.Fragment key={subAccountId}>{this.props.children}</React.Fragment>;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WithSubAccountAccessToken);
