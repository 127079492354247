import decode from 'jwt-decode';

export const expiresAfterMilliseconds = (jwt: string) => {
    const payload = decode(jwt) as any;
    return (payload.exp - payload.iat) * 1000;
};

export const expiresAt = (jwt: string) => {
    const payload = decode(jwt) as any;
    return new Date((payload.exp) * 1000);
};
