import React from 'react';

import CheckboxListFilter, { Option } from '../../../components/Filters/CheckboxListFilter';

import { FilterProps } from './FilterProps';
import { getAccountManager } from '../helpers';
import { useTranslation } from 'react-i18next';


export interface AccountManagerFilterProps extends FilterProps {
    account_managers: string[];
}


const AccountManagerFilter = (
    {
        queryParams,
        onChange,
        account_managers,
        onClose,
    }: AccountManagerFilterProps
) => {
    const { t } = useTranslation();
    const options: Option[] = account_managers.map((value) => ({
        label: getAccountManager(value),
        value,
    }));

    const values = queryParams.getAll('account_manager');

    return (
        <CheckboxListFilter
            label={t('partner.merchants.filter.account_manager.label', {
                count: values.length,
                value: values.map(getAccountManager).join(', '),
            })}
            title={t('partner.merchants.filter.account_manager.title')}
            name="account_manager"
            values={values}
            options={options}
            onApply={(name, values) => {
                onChange({ [name]: values });
            }}
            onDelete={(name) => {
                onChange({ [name]: []});
            }}
            onCloseMenu={onClose}
        />
    );
};

export default AccountManagerFilter;
