import type { State } from '../../reducer';
import type { SalesLocation } from '../../types/customers';
import { createSelector } from 'reselect';
import { namespace } from './locationActions';

export const hasMore = (state: State) => (accountId: string): boolean => !!state[namespace].nextPageStartingAfter[accountId];
export const startAfter = (state: State) => (accountId: string): string | undefined => state[namespace].nextPageStartingAfter[accountId];
export const isLoading = (state: State) => (accountId: string) => state[namespace].isLoading[accountId];
export const isLoadingAny = (state: State) => Object.values(state[namespace].isLoading).reduce((res, val) => res || val, false);
export const salesLocationsRaw = (state: State) => state[namespace].locations;
export const query = (state: State) => (accountId: string) => state[namespace].queries[accountId] ?? { limit: 100 };

export const allSalesLocations = createSelector(
    salesLocationsRaw,
    (locations) => Object.values(locations).flat()
);

export const salesLocations = createSelector(
    salesLocationsRaw,
    (state: State) => state[namespace].queriedIds,
    (locations, queriedIds) => Object.entries(queriedIds).reduce((res, [accountId, ids]) => {
        res[accountId] = ids.map(id => locations[accountId][id]);
        return res;
    }, {} as Record<string, SalesLocation[]>)
);
