import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';

import { useActions } from '../../Actions';
import { externalSelectors, pdSelector } from '../../payout/merchant/config/selectors';
import type { ConfigBankAccount, ConfigBankAccountPayoutDestination, PayoutDestinationConfig } from '../../payout/types';
import { colors, defaultRadius, distances } from '../../styles/constants';
import { useAccount } from '../account/hooks';
import { approvalsStateSelector } from '../approvalsSelectors';

import { Button, ButtonLink } from '../../components/Buttons';
import Card from '../../components/Card';
import Icon from '../../components/Icons';
import { LoadingOverlay } from '../../components/Loading';
import Page, { PageButton, Title } from '../../components/Page';
import CreatePayoutDestinationDialog from './components/CreatePayoutDestinationDialog';

import { useAccountIds } from '../../auth/accessToken/withAccountIds';
import { Search } from '../../components/Filters';
import Dropdown, { DropdownOption } from '../../components/Forms/Dropdown';
import { H2, P } from '../../components/Typography';
import View from '../../components/View';
import { PayoutDestinationTable } from './components/PayoutDestinationTable';


export type PayoutDestinationWithBankAccounts = PayoutDestinationConfig & {
    bankAccounts: ConfigBankAccount[];
    links: ConfigBankAccountPayoutDestination[];
};



const setFilterDefault = (key: string, value: string) => {
    const currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete(key);
    if (value) {
        currentUrl.searchParams.set(key, value);
    }
    window.history.replaceState({}, '', currentUrl.toString());
};

const getFilterDefault = (key: string, fallbackValue: string) => {
    const currentUrl = new URL(window.location.href);
    const value = currentUrl.searchParams.get(key);
    return value || fallbackValue;
};


function useFilterState<T>(key: string, initialValue: T) {
    const [state, setState] = useState<T>(getFilterDefault(key, initialValue as unknown as string) as unknown as T);
    const setFilterState = (newValue: T) => {
        setState(newValue);
        setFilterDefault(key, newValue as unknown as string);
    };
    return [state, setFilterState] as const;
}

const allowedCountries = ['NO', 'SE'];

export default function PayoutDestinationList() {
    const { t } = useTranslation(['app', 'payout']);
    const accountIds = useAccountIds();
    const { account } = useAccount();

    const [query, setQuery] = useFilterState('query', '');

    const [createDialogOpen, setCreateDialogOpen] = useState(false);
    const statusOptions: DropdownOption[] = [
        {
            label: t('app:settings.payout_destinations.case_status_ALL'),
            value: 'ALL',
        },
        {
            label: t('app:settings.payout_destinations.case_status_PAYOUT_DESTINATION'),
            value: 'PAYOUT_DESTINATION',
        },
        {
            label: t('app:settings.payout_destinations.case_status_ACTIVE'),
            value: 'ACTIVE',
        },
        {
            label: t('app:settings.payout_destinations.case_status_AUTOMATIC_REVIEW'),
            value: 'AUTOMATIC_REVIEW',
        },
        {
            label: t('app:settings.payout_destinations.case_status_UNDER_MANUAL_REVIEW'),
            value: 'UNDER_MANUAL_REVIEW',
        },
        {
            label: t('app:settings.payout_destinations.case_status_WAITING_FOR_SIGNATURE'),
            value: 'WAITING_FOR_SIGNATURE',
        },
        {
            label: t('app:settings.payout_destinations.case_status_WAITING_FOR_DECLARATION'),
            value: 'WAITING_FOR_DECLARATION',
        },
        {
            label: t('app:settings.payout_destinations.case_status_WAITING_FOR_DETAILS'),
            value: 'WAITING_FOR_DETAILS',
        },
        {
            label: t('app:settings.payout_destinations.case_status_DECLINED'),
            value: 'DECLINED',
        },
        {
            label: t('app:settings.payout_destinations.case_status_ERROR'),
            value: 'ERROR',
        },
        {
            label: t('app:settings.payout_destinations.case_status_ARCHIVED'),
            value: 'ARCHIVED',
        },
    ];
    const [statusFilter, setStatusFiler] = useFilterState('status', 'ALL');

    const { getPayoutDestinations, getBankAccounts, getBankAccountPayoutDestinations, getAccountConfig } = useActions(
        'payout.config'
    );

    const getSellerApprovals = useActions('management.approvals').getSellerApprovals;

    const prodAccount = accountIds.prodAccountId;
    const testAccount = accountIds.testAccountId;

    const [sellerEnvironment, setSellerEnvironment] = useFilterState<'prod' | 'test'>('environment', prodAccount === accountIds.urlAccountId ? 'prod' : 'test');

    const accountId = (sellerEnvironment === 'prod' ? prodAccount : testAccount) as string;

    const { payoutDestinations: prodPayoutDestinations, loading: isLoadingProd } = useSelector(pdSelector(prodAccount || ''));
    const { payoutDestinations: testPayoutDestinations, loading: isLoadingTest } = useSelector(pdSelector(testAccount || ''));

    const prodApprovals = useSelector(approvalsStateSelector(prodAccount || ''));
    const testApprovals = useSelector(approvalsStateSelector(testAccount || ''));

    const isLoading = isLoadingProd || isLoadingTest || prodApprovals.loading || testApprovals.loading;

    const hasEnabledPayoutInProd = useSelector(externalSelectors.account(prodAccount || '').hasPayout);

    useEffect(() => {
        getPayoutDestinations(accountId);
        getBankAccounts(accountId);
        getBankAccountPayoutDestinations(accountId);
        getAccountConfig(accountId);
    }, [accountId, getPayoutDestinations, getBankAccounts, getBankAccountPayoutDestinations, getAccountConfig]);

    // Only re-fetch approvals when the account id or status is changed to/from ARCHIVED
    const [currentApprovalsKey, setCurrentApprovalsKey] = useState('');
    useEffect(() => {
        const archivedKey = statusFilter === 'ARCHIVED' ? 'ARCHIVED' : 'ALL';
        const key = `${accountId}-${archivedKey}-approvals`;
        if (key !== currentApprovalsKey) {
            if (statusFilter === 'ARCHIVED') {
                getSellerApprovals(accountId, { case_status: ['ARCHIVED']});
            } else {
                getSellerApprovals(accountId);
            }
        }
        setCurrentApprovalsKey(key);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountId, getSellerApprovals, statusFilter]);

    const onCloseCreateDialog = () => {
        setCreateDialogOpen(false);
        if (statusFilter === 'ARCHIVED') {
            getSellerApprovals(accountId, { case_status: ['ARCHIVED']});
        } else {
            getSellerApprovals(accountId);
        }
    };

    if (!account) {
        return null;
    }
    const allowedForCountry = allowedCountries.includes(account?.company?.address?.country || '');
    const allowCreateProdPayoutDestinations = allowedForCountry && hasEnabledPayoutInProd;

    const prodDestinationIds = prodPayoutDestinations.map(p => p.payout_destination_id);
    const prodApprovalsWithoutPayoutDestination = prodApprovals.approvals.filter(a => !prodDestinationIds.includes(a.payout_destination_id || ''));

    const testDestinationIds = testPayoutDestinations.map(p => p.payout_destination_id);
    const testApprovalsWithoutPayoutDestination = testApprovals.approvals.filter(a => !testDestinationIds.includes(a.payout_destination_id || ''));

    const envDestinations = sellerEnvironment === 'prod' ? prodPayoutDestinations : testPayoutDestinations;

    const destinations = envDestinations.filter(pd => {
        if (statusFilter === 'ALL') {
            return true;
        }
        if (statusFilter === 'PAYOUT_DESTINATION') {
            return true;
        }
        return false;
    }).filter(pd => {
        if (query === '') {
            return true;
        }
        const queryWithoutWhitespace = query.replace(/\s/g, '');
        const nameResult = pd.name.toLowerCase().startsWith(query.toLowerCase());
        const orgResult = pd.bankAccounts.some(ba => ba.owner_orgno.toLowerCase().startsWith(queryWithoutWhitespace.toLowerCase()));
        const idResult = pd.payout_destination_id.toLowerCase().startsWith(query.toLowerCase());
        const nicknameResult = pd.bankAccounts.some(ba => ba.nickname.toLowerCase().startsWith(query.toLowerCase()));
        const referenceResult = pd.reference.toLowerCase().startsWith(query.toLowerCase());
        return nameResult || orgResult || idResult || nicknameResult || referenceResult;
    });

    const envApprovals = sellerEnvironment === 'prod' ? prodApprovalsWithoutPayoutDestination : testApprovalsWithoutPayoutDestination;
    const approvals = envApprovals.filter(a => {
        if (statusFilter === 'ALL') {
            return a.case_status !== 'ARCHIVED';
        }
        if (statusFilter === 'PAYOUT_DESTINATION') {
            return false;
        }
        if (statusFilter === 'WAITING_FOR_SIGNATURE') {
            return a.case_status as string === 'DECLARATION_SUBMITTED' || a.case_status === 'WAITING_FOR_SIGNATURE';
        }
        if (statusFilter === 'ARCHIVED') {
            return true;
        }
        return a.case_status === statusFilter;
    }).filter(a => {
        const queryWithoutWhitespace = query.replace(/\s/g, '');
        const nameResult = (a.payout_destination_description || '').toLowerCase().startsWith(query.toLowerCase());
        const orgResult = a.organization_number.toLowerCase().startsWith(queryWithoutWhitespace.toLowerCase());
        const idResult = a.payout_destination_id.toLowerCase().startsWith(query.toLowerCase());
        const descriptionResult = (a.payout_destination_description || '').toLowerCase().startsWith(query.toLowerCase());
        const referenceResult = a.payout_reference.toLowerCase().startsWith(query.toLowerCase());

        return nameResult || orgResult || idResult || descriptionResult || referenceResult;
    });

    const debouncedSearch = debounce((value: string) => {
        setQuery(value);
    }, 500);


    return (
        <Page
            title={
                <>
                    <Title>{t('app:settings.menu.payout_destinations')}</Title>
                    {(
                        <PageButton onClick={() => setCreateDialogOpen(true)} disabled={sellerEnvironment === 'prod' && !hasEnabledPayoutInProd}>
                            <Icon icon="plus" fill="currentColor" />
                            {t('payout:payout_destination.form.title')}
                        </PageButton>
                    )}
                </>
            }
        >
            {
                allowedForCountry && (<>
                    <Filters>
                        <StatusFilterWrapper>
                            <Dropdown
                                name="status"
                                value={statusOptions.find(o => o.value === statusFilter) as DropdownOption}
                                onChange={(value) => {
                                    setStatusFiler(value.value);
                                }}
                                options={statusOptions}
                                selectedFormatter={(option) => `${t('payout:payout_destination.status')}: ${option.label}`}
                                noBottomMargin
                            />
                        </StatusFilterWrapper>
                        <Search
                            name="search"
                            placeholder={t('app:settings.payout_destinations.search_placeholder')}
                            defaultValue={query}
                            onChange={(value) => {
                                debouncedSearch(value);
                            }}
                            onBlur={(value) => {
                                debouncedSearch(value);
                            }}
                            onPaste={(event: React.ClipboardEvent<HTMLInputElement>) => {
                                try {
                                    const pasted = event.clipboardData.getData('Text');
                                    if (pasted) {
                                        debouncedSearch(pasted);
                                    }
                                } catch (error) {
                                    // ignore error
                                }
                            }}
                        />
                    </Filters>
                    <PayoutDestinationTable
                        accountId={accountId || ''}
                        payoutDestinations={destinations}
                        approvalsWithoutPayoutDestination={approvals}
                        account={account}
                    />
                    {
                        sellerEnvironment === 'prod' && !hasEnabledPayoutInProd && (
                            <>
                                <View maxWidth={600} p={20} m="100px auto" border="1px solid"
                                    direction="column"
                                    alignItems="flex-start"
                                    borderColor={colors.borderLight}
                                    radius={defaultRadius}
                                >
                                    <H2>{t('app:settings.payout_destinations.apply_for_payments.title')}</H2>
                                    <br />
                                    <br />
                                    <P>{t('app:settings.payout_destinations.apply_for_payments.text')}</P>
                                    <br />
                                    <br />
                                    <ButtonLink to={`/${prodAccount}/settings/payment-connections`}>
                                        {t('app:settings.payout_destinations.apply_for_payments.call_to_action')}
                                    </ButtonLink>
                                </View>
                                <br />
                            </>
                        )
                    }
                    {((sellerEnvironment === 'prod' && hasEnabledPayoutInProd) || sellerEnvironment === 'test') &&  envApprovals.length === 0 && envDestinations.length === 0 && (
                        <>
                            <View maxWidth={600} p={20} m="100px auto" border="1px solid"
                                direction="column"
                                alignItems="flex-start"
                                borderColor={colors.borderLight}
                                radius={defaultRadius}
                            >
                                <H2>{t('app:settings.payout_destinations.no_sellers.title')}</H2>
                                <br />
                                <br />
                                <P>{t('app:settings.payout_destinations.no_sellers.text')}</P>
                                <br />
                                <br />
                                <Button onClick={() => setCreateDialogOpen(true)} type="button" disabled={sellerEnvironment === 'prod' && !hasEnabledPayoutInProd}>
                                    {t('app:settings.payout_destinations.no_sellers.call_to_action')}
                                </Button>
                            </View>
                            <br />
                        </>
                    )}
                    {(isLoading) && <LoadingOverlay />}
                </>
                )
            }



            {!allowedForCountry && (
                <Card title={t('payout:payout_destination.country_not_allowed')}>
                    <P>{t('payout:payout_destination.country_not_allowed_text')}</P>
                </Card>
            )}
            {createDialogOpen && <CreatePayoutDestinationDialog
                environment={sellerEnvironment}
                onClose={onCloseCreateDialog}
                allowCreateProdPayoutDestinations={allowCreateProdPayoutDestinations}
            />}
        </Page>
    );
}

const Filters = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;

`;

const StatusFilterWrapper = styled.div`
    min-width: 250px;
`;
