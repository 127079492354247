import memoize from 'lodash/fp/memoize';

const languageLocales = [['no', 'nn', 'nb', 'nb-NO', 'nn-NO'], ['sv', 'sv-SE', 'sv-FI', 'se']];

const getLocaleArray = (locale: string) => {
    const locales = languageLocales.find(lst => lst.includes(locale));
    return locales || [locale];
};

const canUseLocales = (locales: string[]) => {
    try {
        return Intl.NumberFormat.supportedLocalesOf(locales).length > 0;
    } catch {
        return false;
    }
};

const getSupportedLocales = memoize((locale: string) => {
    const locales = getLocaleArray(locale);
    if (canUseLocales(locales)) {
        return locales;
    }
});

export const numberFormat = (lng: string | undefined, options?: Intl.NumberFormatOptions) => {
    if (lng) {
        const locales = getSupportedLocales(lng);
        if (locales) {
            return Intl.NumberFormat(locales, options);
        }
    }
    // Use default locale for browser or OS
    return Intl.NumberFormat(undefined, options);
};

export const getLocaleNumeralDecimal = (lng: string | undefined) => {
    return numberFormat(lng).format(1.1).replace(/1/g, '') || '.';
};

export const getLocaleNumeralDelimiter = (lng: string | undefined) => {
    return numberFormat(lng).format(1111).replace(/1/g, '') || ' ';
};

export const getDateTimeFormat = memoize((
    languageCode: string,
    options?: Intl.DateTimeFormatOptions
) => new Intl.DateTimeFormat(languageCode, options));

export const getNumberFormat = memoize((
    languageCode: string,
    exponent: number
) => Intl.NumberFormat(languageCode, { minimumFractionDigits: exponent }));
