import React, {  useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { P, H3 } from '../../../../components/Typography';
import Icon from '../../../../components/Icons';
import { DestinationsElement } from '../utils';
import { distances, colors } from '../../../../styles/constants';
import { toMonetaryAmountString } from '../../../../helpers/formatters';
import { EXAMPLE_VALUE_CURRENCY } from '../../constants';

interface DestinationsListProps {
    destination: DestinationsElement;
    destinationsArray: DestinationsElement[];
    index: number;
    parentIndex?: number;
    isChildren?: boolean;
}

const DestinationsList = (
    {
        destination,
        destinationsArray,
        index,
        parentIndex,
        isChildren,
    }: DestinationsListProps
) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const { t, i18n } = useTranslation();

    const parseValueAndType = (value: string, type: 'percentage' | 'flat_amount' | 'remaining_amount') => {
        switch (type) {
            case 'flat_amount':
                return t('payout_rules.payout_rules_details.fields.flat_fee', { value:  toMonetaryAmountString(i18n.language, parseFloat(value), EXAMPLE_VALUE_CURRENCY) });
            case 'remaining_amount':
                return t('payout_rules.payout_rules_details.fields.remaining_amount');
            case 'percentage':
                return isChildren
                    ? t('payout_rules.payout_rules_details.fields.percentage_of_split', {
                        value,
                        index: (parentIndex ?? 0) + 1,
                    })
                    : t('payout_rules.payout_rules_details.fields.percentage_of_total', { value });
        }
    };

    const subDestinations = destinationsArray.filter(des => des.parentId === destination.id);

    return (
        <ListElement isChildren={!!isChildren}>
            <ListRow onClick={() => setIsVisible(!isVisible)}>
                <ListText>
                    <StyledH3 isWithColor={!!destination.destination}>
                        {t('payout_rules.payout_rules_details.fields.split', { count: index + 1 })}
                    </StyledH3>
                    {destination.destination && <Circle color={destination.color} />}
                    <P>{parseValueAndType(destination.value ?? '', destination.type)}</P>
                    {destination.destination && (
                        <DestinationP>
                            {t('payout_rules.payout_rules_details.fields.to', {
                                destination: destination.destination,
                            })}
                        </DestinationP>
                    )}
                </ListText>
                {subDestinations.length > 0 && <Icon icon="caret_down" />}
            </ListRow>
            {isVisible &&
                subDestinations &&
                subDestinations.map((destination, subIndex) => (
                    <DestinationsList
                        key={subIndex}
                        destination={destination}
                        destinationsArray={subDestinations}
                        index={subIndex}
                        parentIndex={index}
                        isChildren
                    />
                ))}
        </ListElement>
    );
};

const ListElement = styled.div<{ isChildren: boolean }>`
    margin: ${(props) => (props.isChildren ? `0 0 0 ${distances.small}` : `0 0 ${distances.small} 0`)};
`;

const ListRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 22px;
    margin-bottom: ${distances.tiny};
    background: ${colors.backgroundAlt};
    border-radius: 2px;
    cursor: pointer;
`;

const ListText = styled.div`
    display: flex;
`;

const StyledH3 = styled(H3 as any)<{ isWithColor: boolean }>`
    line-height: 22px;
    margin-right: ${props => props.isWithColor ? 0 : distances.small}
`;

const Circle = styled.div<{ color: string }>`
    height: 20px;
    width: 20px;
    margin: 0 ${distances.small};
    background-color: ${(props) => props.color};
    border-radius: 50%;
`;

const DestinationP = styled(P as any)`
    font-weight: bold;
    color: ${colors.valid};
    margin-left: 4px;
`;

export default DestinationsList;
