import { BillingSubscription } from '../../types/billing';
import { CheckoutConfig } from '../../types/checkout';
import { ApprovalsPaymentResponse } from '../../types/management-auth/generated';
import { ActionTypes, Actions } from './actions';
import { Editable, PartnerAccount } from './types';

export interface State {
    isLoading: boolean;
    isNotFound: boolean;
    merchant: PartnerAccount | undefined;
    subscriptions: BillingSubscription[];
    editing: Editable;
    checkout: CheckoutConfig | undefined;
    approvals: ApprovalsPaymentResponse[];
}

export const initialState: State = {
    isLoading: false,
    isNotFound: false,
    merchant: undefined,
    subscriptions: [],
    editing: undefined,
    checkout: undefined,
    approvals: [],
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.GetMerchant:
            if (state.merchant && state.merchant.id === action.payload.subAccountId) {
                return { ...state };
            }
            return {
                ...initialState,
                isLoading: true,
            };
        case ActionTypes.MerchantResponse:
            return {
                ...state,
                isLoading: false,
                merchant: action.payload.merchant,
                editing: undefined,
            };
        case ActionTypes.MerchantCheckoutResponse:
            return {
                ...state,
                isLoading: false,
                checkout: action.payload.checkout,
                editing: undefined,
            };

        case ActionTypes.MerchantBillingResponse:
            return {
                ...state,
                subscriptions: action.payload.subscriptions,
            };

        case ActionTypes.MerchantNotFoundResponse:
            return {
                ...state,
                isLoading: false,
                isNotFound: true,
            };

        case ActionTypes.MerchantCheckoutNotFoundResponse:
            return {
                ...state,
                isLoading: false,
            };

        case ActionTypes.ChangeEditState:
            return {
                ...state,
                editing: action.payload.editing,
            };

        case ActionTypes.EditMerchant:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.MerchantApprovalsPaymentsResponse:
            return {
                ...state,
                approvals: action.payload.approvals,
            };
        default:
            return state;
    }
}
