import { useEffect, useState } from 'react';
import { Account } from '../types/management-auth';
import fulfill from '../fulfill';
import { CORE_API_HOSTNAME } from '../env';
import { isInteger } from '../helpers/validation/integer';

const asProtocolUrl = (url: string | undefined) => {
    if (!url) {
        return '';
    }
    if (url.startsWith('http')) {
        return url;
    }
    return `https://${url}`;
};

const organizationAsAccount = (entry: any) => ({
    company: {
        organization_number: entry.organization_number,
        business_name: entry.business_name,
        website: asProtocolUrl(entry.url),
        address: {
            address_line: entry.business_address?.address_line,
            address_line_2: entry.business_address?.address_line_2,
            postal_code: entry.business_address?.postal_code,
            postal_place: entry.business_address?.postal_place,
            country: entry.business_address?.country,
        },
    },
} as Account);


export const useOrganizationSearch = (accountId: string) => {
    const [state, setState] = useState<{query: string; country: string}>({ query: '', country: '' });
    const [searchResults, setSearchResults] = useState<Account[]>([]);
    const search = (country: string, query: string) => {
        setState({ country,  query });
    };
    const { country, query } = state;
    useEffect(() => {
        if (query) {
            if (country === 'NO') {
                let searchValue = query;
                let searchProp = 'name';
                const possibleOrgNumber = query.replace(/\D/g, '');
                if (possibleOrgNumber.length === 9 && isInteger(query)) {
                    searchValue = possibleOrgNumber;
                    searchProp = 'organization_number';
                }
                fulfill.get({
                    url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/search/external/organizations/no?${searchProp}=${searchValue}`,
                    accountId: 'none',
                    handlers: {
                        200: (response) => {
                            const accounts = response.map(organizationAsAccount);
                            setSearchResults(accounts);
                        },
                    },
                });
            } else {
                setSearchResults([]);
            }
        }
    }, [country, query, accountId]);
    return { search, searchResults, country, query };
};
