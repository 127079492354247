import React from 'react';
import { Trans, withTranslation, WithTranslation } from 'react-i18next';

import { ButtonLink } from '../../../../components/Buttons';
import EmptyState from '../../../../components/EmptyState';

import { P } from '../../../../components/Typography';

import { TFunction } from 'i18next';
import NoData from './NoData';
import { safeDecodeURIComponent } from '../../../../helpers/formatters';


export interface ListMessageProps extends WithTranslation<'app'> {
    queryParams: URLSearchParams;
    hasRules: boolean;
    isLoading: boolean;
    accountId: string;
}

const getEmptyFilterStringPart = (t: TFunction, locationQueryParams: URLSearchParams) => {
    const entries = [...locationQueryParams.entries()].filter(([key]) => !['starting_after'].includes(key));
    return entries.map(([key, value]) => safeDecodeURIComponent(value)).join(', ');
};

const ListMessage = (
    {
        t,
        hasRules,
        isLoading,
        accountId,
        queryParams,
    }: ListMessageProps
) => {
    if (isLoading) {
        return null;
    }

    const locationValues = getEmptyFilterStringPart(t, queryParams);
    // The account has no payout rules yet
    if (!isLoading && !hasRules && !locationValues) {
        return <NoData />;
    }

    // TODO: Translation keys used here are missing in locale
    // Filtering payout rules yields an empty result and date filter is set
    if (!hasRules && locationValues) {
        return (
            <EmptyState>
                <P>
                    <Trans
                        i18nKey={'payout_rules.payout_rules.empty_filtered_list.message' as any}
                        values={{ locationValues }}
                    >
                        Sorry, we can't find any orders matching <strong>{locationValues}</strong>.
                    </Trans>
                </P>
                <ButtonLink to={`/${accountId}/settings/payout-rules`}>
                    {t('payout_rules.payout_rules.empty_filtered_list.clear_search_and_filters' as any)}
                </ButtonLink>
            </EmptyState>
        );
    }

    return null;
};

export default withTranslation()(ListMessage);
