import React, {  } from 'react';

interface WindowCodeProps {
    width?: string;
    height?: string;
    fill?: string;
}

const WindowCode = (
    {
        width = '100%',
        height = '100%',
        fill = '#0000FF',
    }: WindowCodeProps
) => (<svg height={height} width={width} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g fill={fill}>
        <path d="M32,6V3c0-0.552-0.447-1-1-1H7v4H32z" />
        <path d="M5,2H1C0.447,2,0,2.448,0,3v3h5V2z" />
        <path
            d="M0,8v21c0,0.552,0.447,1,1,1h30c0.553,0,1-0.448,1-1V8H0z M6,23.414L4.586,22l4-4l-4-4L6,12.586L11.414,18 L6,23.414z M20,23h-8v-2h8V23z"
            fill={fill}
        />
    </g>
</svg>);

export default WindowCode;
