import { createSwitchlessReducer } from '../util/switchlessReducer';
import type { Dispatch } from 'redux';
import fulfill from '../fulfill';
import { CORE_API_HOSTNAME } from '../env';
import { BillingPayoutRule } from '../types/billing';
import { createSelector } from 'reselect';
import { State as RootState } from '../reducer';


export const namespace = 'settings.billing' as const;

export interface State {
    payoutRules: BillingPayoutRule[];
    isLoading: boolean;
}

export const initialState: State = {
    payoutRules: [],
    isLoading: false,
};

type ReturnedRules = { account_id: string; payout_rules: BillingPayoutRule[] };

export const billingSelector = (state: RootState) => state[namespace];

export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        startLoading: (state: State, { account_id }) => {
            return {
                ...state,
                isLoading: true,
            };
        },
        addPayoutRules: (state: State, { account_id, payout_rules }: ReturnedRules): State => {
            return {
                ...state,
                payoutRules: payout_rules || [],
                isLoading: false,
            };
        },
    },
});

export const createConnectedActions = (dispatch: Dispatch) => {
    const fetchPayoutRules = async (account_id: string) => {
        actions.startLoading(!!account_id);
        fulfill.get({
            accountId: account_id,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${account_id}/billing/payout-rules`,
            handlers: {
                200: ({ payout_rules }: { payout_rules: BillingPayoutRule[] }) => {
                    dispatch(actions.addPayoutRules({ account_id, payout_rules }));
                },
                403: () => {
                    // user does not have scope to list payout-rules
                    dispatch(actions.addPayoutRules({ account_id, payout_rules: []}));
                },
            },
        });
    };

    return {
        fetchPayoutRules,
    };
};
