import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { colors, distances, grid } from '../../../../styles/constants';
import { DateTime } from '../../../../components/Formatters';
import Icon from '../../../../components/Icons';

import { Tabs } from '../../../../components/Tabs';
import { H3 } from '../../../../components/Typography';
import { Dl, Dt, Dd } from '../../../../components/DescriptionList/Horizontal';

import { Delivery, Subscription, DeliveryDetails } from '../../../../types/webhooks';

type HttpDataProps = WithTranslation & {
    headers: { [key: string]: string };
    payload: any;
};

const HttpData = (
    {
        t,
        headers,
        payload,
    }: HttpDataProps
) => {
    let data = payload;
    try {
        if (typeof payload === 'string') {
            data = JSON.stringify(JSON.parse(payload), undefined, 2);
        } else {
            data = JSON.stringify(payload, undefined, 2);
        }
    } catch (err) {
        /** format JSON */ console.log(err);
    }
    return (
        <Wrapper>
            <H3>{t('settings.hook.deliveries.headers')}</H3>
            <Pre>
                {Object.keys(headers).map((header) => (
                    <Dl key={header}>
                        <Dt doubleColumn>{header}</Dt>
                        <Dd>{headers[header]}</Dd>
                    </Dl>
                ))}
            </Pre>
            <H3>{t('settings.hook.deliveries.payload')}</H3>
            <Pre>{data as string}</Pre>
        </Wrapper>
    );
};

interface TabState {
    activeTab: string;
}

interface DeliveryTabsProps extends WithTranslation {
    details: DeliveryDetails[];
}

class DeliveryDetailsTabs extends React.Component<DeliveryTabsProps, TabState> {
    constructor(props: DeliveryTabsProps) {
        super(props);
        this.state = {
            activeTab: 'request',
        };
    }

    setActiveTab(activeTab: string) {
        this.setState({ activeTab });
    }

    render() {
        const { t, details, i18n, tReady } = this.props;
        const data = details[0];

        return (
            (data && (
                <TabsWrapper>
                    <Tabs
                        groups={[
                            {
                                id: 'request',
                                option: t('settings.hook.deliveries.request.tab_title'),
                                content: (
                                    <HttpData
                                        headers={data.request.headers}
                                        payload={data.request.body}
                                        t={t}
                                        i18n={i18n}
                                        tReady={tReady}
                                    />
                                ),
                            },
                            {
                                id: 'response',
                                option: t('settings.hook.deliveries.response.tab_title'),
                                content: (
                                    <HttpData
                                        headers={data.response.headers}
                                        payload={data.response.body}
                                        t={t}
                                        i18n={i18n}
                                        tReady={tReady}
                                    />
                                ),
                            },
                        ]}
                        onTabClick={(id: string) => this.setActiveTab(id)}
                        active={this.state.activeTab}
                    />
                </TabsWrapper>
            )) || <span />
        );
    }
}

const TabsWrapper = styled.div`
    display: block;
    max-width: ${grid.spans.span8};
    margin: 0 auto;
    padding: ${distances.tiny} ${distances.small};
`;

const Wrapper = styled.div`
    padding-top: ${distances.tiny};
    margin-right: ${grid.gutter};
`;

const Pre = styled.pre`
    background-color: #f8f8f8;
    padding: ${distances.small} ${distances.small};
    margin: ${distances.small} 0px;
    white-space: pre-wrap;
`;

export interface DeliveryRowProps extends WithTranslation {
    hook: Subscription;
    delivery: Delivery;
    deliveryDetails: DeliveryDetails[];
    getDeliveryDetails: (accountId: string, hookId: string, deliveryId: string) => void;
}

interface DeliveryRowState {
    showDetails: boolean;
    fetched: boolean;
}

class DeliveryRow extends React.Component<DeliveryRowProps, DeliveryRowState> {
    constructor(props: DeliveryRowProps) {
        super(props);
        this.state = { showDetails: false, fetched: false };
    }

    fetchDeliveryDetails() {
        const { hook, delivery } = this.props;
        if (!this.state.fetched) {
            this.props.getDeliveryDetails(hook.account_id as string, hook.id as string, delivery.id);
        }

        this.setState({
            showDetails: !this.state.showDetails,
            fetched: true,
        });
    }

    render() {
        const { delivery, deliveryDetails } = this.props;
        const details = this.state.showDetails ? deliveryDetails.filter((dd) => dd.id === delivery.id) : [];
        return [
            <Row key={delivery.id} className="interactive" onClick={() => this.fetchDeliveryDetails()}>
                <Cell style={{ paddingLeft: '12px' }}>
                    {(this.state.showDetails && <Icon icon="caret_down" />) || <CaretRight icon="caret_down" />}
                </Cell>
                <Cell style={{ whiteSpace: 'nowrap' }}>
                    <DeliveryBadge status={delivery.status}>{delivery.status}</DeliveryBadge>
                </Cell>
                <Cell className="align-left" style={{ flexGrow: 10, paddingLeft: '8px' }}>
                    <code>{delivery.event_delivery}</code>
                </Cell>
                <Cell className="align-right">
                    <DateTime isoString={delivery.created_at || ''} boldTime />
                </Cell>
            </Row>,
            <DeliveryDetailsTabs
                key={delivery.event_delivery}
                details={details}
                i18n={this.props.i18n}
                t={this.props.t}
                tReady={this.props.tReady}
            />,
        ];
    }
}

export default withTranslation()(DeliveryRow);

const Row = styled.div`
    display: flex;
    align-content: stretch;
`;
const Cell = styled.div`
    margin: 0;
    padding: ${distances.small} 0;
    color: ${colors.text};
    font-size: 14px;
    font-weight: 400;
    position: relative;
    z-index: 0;
    font-feature-settings: 'tnum';

    &.align-right {
        text-align: right;
        padding-right: ${distances.small};
    }

    &:after {
        content: ' ';
        height: 10px;
        position: absolute;
        height: 1px;
        background: ${colors.borderLight};
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.first-child {
        padding-left: ${distances.normal};
        &:after {
            left: ${distances.normal};
        }
    }

    &.last-child {
        padding-right: ${distances.normal};
        &:after {
            right: ${distances.normal};
        }
    }
    &.ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &.collapse-1200 {
        @media (max-width: 1200px) {
            display: none;
        }
    }

    &.collapse-768 {
        @media (max-width: 768px) {
            display: none;
        }
    }
`;

const CaretRight = styled(Icon)`
    svg {
        transform: rotate(-90deg);
    }
`;

const badgeColor = (status: number) => {
    return status > 199 && status < 300 ? colors.valid : colors.warning;
};

interface BadgeProps {
    status: number;
}

const DeliveryBadge = styled.span<BadgeProps>`
    font-weight: 700;
    text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.5);
    font-size: 11px;
    padding: 2px ${distances.tiny};
    border-radius: 2px;
    background: ${(props) => badgeColor(props.status)};
`;
