import styled from 'styled-components/macro';
import { colors, distances } from '../../styles/constants';

const Tr = styled.tr`
    margin: 0;
    padding: 0;

    &.no-highlight > td {
        border-bottom: 1px solid transparent !important;
    }

    &.alt {
        background-color: ${colors.backgroundAlt};
    }

    &.no-wrap > th,
    &.no-wrap > td {
        overflow: hidden;
        word-break: normal;
        text-overflow: ellipsis;
        padding-right: ${distances.tiny};

        &:last-child {
            padding-right: 0;
        }
    }
`;

export default Tr;
