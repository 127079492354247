import { showGatewayTypes } from '../../../helpers/getProfilePaymentTypeLabel';
import { PaymentType } from '../../../types/checkout';

export const paymentOptionTranslationKeys = (key: string): string[] => {
    if (showGatewayTypes.includes(key as PaymentType)) {
        return [
            `payments.payment_product.${key.split('.')[0]}`,
            `payments.product_types.${key}`,
        ];
    } else {
        return [`payments.product_types.${key}`];
    }
};
