import { connect } from 'react-redux';

import { createPayoutRule, resetForm, fetchLocations } from '../actions';
import { State } from '../../../../reducer';
import { getNewPayoutRuleSelector } from '../selectors';
import { getAccountUser } from '../../../../auth/accessToken/selectors';

import NewPayoutRule from '../components/NewPayoutRule';

const mapStateToProps = (state: State) => ({
    newPayoutRuleState: getNewPayoutRuleSelector(state),
    accountUser: getAccountUser(state),
});

const mapDispatchToProps = {
    createPayoutRule,
    resetForm,
    fetchLocations,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewPayoutRule);
