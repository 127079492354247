import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CHECKOUT_API_HOSTNAME, CORE_API_HOSTNAME } from '../../../env';
import { ActionTypes, FetchProfileResponse, FetchRemoveProfileResponse, RemoveProfile, GetProfile } from './actions';
import { ProfileConfiguration, CheckoutConfig } from '../../../types/checkout';
import { Account } from '../../../types/management-auth';

const fetchProfile: SideEffectFunction<GetProfile, FetchProfileResponse> = async (action) => {
    const profilePromise: Promise<ProfileConfiguration> = fulfill.get({
        accountId: action.payload.accountId,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/session/profiles/${action.payload.profileId}`,
        handlers: {
            200: (profile: ProfileConfiguration) => profile,
        },
    });

    const configurationPromise: Promise<CheckoutConfig> = fulfill.get({
        accountId: action.payload.accountId,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/checkout`,
        handlers: {
            200: (configuration: CheckoutConfig) => configuration,
        },
    });

    const accountPromise: Promise<Account> = fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/management/settings`,
        handlers: {
            200: (account: Account) => account,
            404: () => undefined,
        },
    });

    const [profile, configuration, account] = await Promise.all([profilePromise, configurationPromise, accountPromise]);
    return {
        type: ActionTypes.FetchProfileResponse,
        payload: {
            profile,
            configuration,
            account,
        },
        meta: {
            cause: action,
        },
    };
};

const fetchDeleteProfile: SideEffectFunction<RemoveProfile, FetchRemoveProfileResponse> = async (action) => {
    const result: FetchRemoveProfileResponse = await fulfill.delete({
        accountId: action.payload.accountId,
        url: `${CHECKOUT_API_HOSTNAME}/v1/admin/session/profiles/${action.payload.profileId}`,
        handlers: {
            200: () => ({
                type: ActionTypes.FetchRemoveProfileResponse,
                payload: {},
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.GetProfile]: fetchProfile,
    [ActionTypes.RemoveProfile]: fetchDeleteProfile,
};

export default effects;
