import styled from 'styled-components/macro';
import { useTranslation } from 'react-i18next';
import startOfISOWeek from 'date-fns/startOfISOWeek';
import getISOWeek  from 'date-fns/getISOWeek';
import {
    VictoryAxis,
    VictoryChart,
    VictoryBar,
    VictoryLine,
    VictoryTooltip,
    VictoryVoronoiContainer,
    VictoryClipContainer
} from 'victory';
import mean from 'lodash/mean';
import max from 'lodash/max';
import { median } from '../../../helpers/numbers';
import sortBy from 'lodash/sortBy';
import memoize from 'lodash/memoize';

import { border, colors } from '../../../styles/constants';
import theme, { fontFamily } from '../../../styles/victory-theme';
import { InsightCheckoutKpis } from '../../../types/insight';
import { formatMoney, toCommonMonetaryAmountString } from '../../../helpers/formatters';
import { MarginTopCard, CardDescription, CardTitle } from '../common';
import { NoData } from './EmptyStates';
import { LoadingOverlay } from '../../../components/Loading';
import { useState } from 'react';


const padNullValues = (kpis: InsightCheckoutKpis | undefined, length: number) => {
    if (!kpis) {
        return undefined;
    }
    const pads = Math.max(0, length - kpis.values.length);
    return {
        ...kpis,
        values: [...kpis.values, ...(new Array(pads).fill(0).map(() => ({ date: '', value: null })))],
    } as InsightCheckoutKpis;
};

const groupByLastTwentyFourIsoWeeks = (kpis: InsightCheckoutKpis | undefined, currency: string): InsightCheckoutKpis | undefined => {
    if (!kpis) {
        return undefined;
    }
    const sorted = sortBy(kpis.values.filter(v => v.currency ? v.currency === currency : v), kpi => kpi.date);
    const grouped = sorted.reduce<InsightCheckoutKpis['values']>((acc, kpi) => {
        const kpiWeek = startOfISOWeek(new Date(kpi.date)).toISOString();
        const prev = acc.pop() || {
            date: kpi.date,
            value: 0,

        };
        if (prev.date === kpiWeek) {
            return [...acc, { date: kpiWeek, value: prev.value + kpi.value }];
        }
        return [...acc, prev, { date: kpiWeek, value: kpi.value }];
    }, []);
    const sliced = grouped.slice(-24);
    return padNullValues({
        kpi_name: kpis.kpi_name,
        values: sliced,
    }, 24);
};


type CaptureChartProps = {
    kpisCollection: InsightCheckoutKpis[];
    currencies: string[];
    loading: boolean;
};

const medianKpiValue = (kpis: InsightCheckoutKpis | undefined) => {
    if (!kpis?.values) {
        return 0;
    }
    const values = kpis.values.map(v => v.value);
    return median(values);
};

const meanKpiValue = (kpis: InsightCheckoutKpis | undefined) => {
    if (!kpis?.values) {
        return 0;
    }
    const values = kpis.values.map(v => v.value);
    return mean(values)  || 0;
};

const maxKpiValue = (kpis: InsightCheckoutKpis | undefined) => {
    if (!kpis?.values) {
        return 0;
    }
    const values = kpis.values.map(v => v.value);
    return max(values) || 0;
};

const axisLabelFontSize = '12px';
const tooltipLabelFontSize = '14px';

const kpisHasData = (kpis: InsightCheckoutKpis) => {
    const significant = kpis.values.find(datum => datum.value !== null && datum.value > 0);
    return !!significant;
};

const checkEmptyKpisCollection = (kpisCollection: InsightCheckoutKpis[]) => {
    return !kpisCollection.find(kpisHasData);
};



const CaptureChart = ({ kpisCollection, currencies, loading }: CaptureChartProps) => {
    const { t, i18n } = useTranslation();


    const [currency, setCurrency] = useState(currencies[0]);

    // We plot the volume in actual values and normalize the count data so that they can both be plotted in the
    // same chart.
    const volumeKpis = groupByLastTwentyFourIsoWeeks(kpisCollection.find(kpi => kpi.kpi_name === 'sum'), currency);
    const countKpis = groupByLastTwentyFourIsoWeeks(kpisCollection.find(kpi => kpi.kpi_name === 'count'), currency);

    // We want the count data to be grouped around the middle of the chart, so we need to take that into
    // account when creating a ratio for the normalization.
    const maxVolume = maxKpiValue(volumeKpis);
    const maxCount = maxKpiValue(countKpis);
    const maxCountRatioMax = max([maxCount + 1, Math.ceil(maxCount * 1.2)]);
    const medianCountRatioMax = Math.ceil(medianKpiValue(countKpis)) * 2;
    const meanCountRatioMax = Math.ceil(meanKpiValue(countKpis)) * 2;
    const countRatioMax = max([maxCountRatioMax, medianCountRatioMax, meanCountRatioMax]) || 1;
    const ratio = (maxVolume / countRatioMax);

    // Generate array of tick values for the right axis
    const rightTickValues = new Array(countRatioMax + 1).fill(0).map((x, i) => i * ratio);

    // Axis offset and domain padding
    const maxVolumeStrLen = maxVolume.toString().length;
    const paddingLeft = Math.max((maxVolumeStrLen * 10)+20, 50);
    const maxCountStrLen = maxCount.toString().length;
    const paddingRight = Math.max((maxCountStrLen * 10)+25, 45);

    // Empty state
    const isEmpty = checkEmptyKpisCollection(kpisCollection);

    return (
        <MarginTopCard marginBottom="36px">
            {loading && <LoadingOverlay />}
            {currencies.length > 1 && <Group position="apart">
                <div />
                <Group>
                    {currencies.map(c => <FilterButton key={c} className={c === currency ? 'active' : undefined} onClick={() => setCurrency(c)}>{c}</FilterButton>)}
                </Group>
            </Group>}
            <CardTitle>{t('dashboard.kpis.captured_transactions.title')}</CardTitle>
            <DescriptionWrapper>
                <CardDescription>{t('dashboard.kpis.captured_transactions.description', { currency })}</CardDescription>
                <LegendsWrapper>
                    <LegendWrapper>
                        <LegendVolumeExample />
                        <LegendVolume>{t('dashboard.kpis.captured_transactions.volume_captured_legend')}</LegendVolume>
                    </LegendWrapper>
                    <LegendWrapper>
                        <LegendCountExample />
                        <LegendCount>{t('dashboard.kpis.captured_transactions.count_captures_legend')}</LegendCount>
                    </LegendWrapper>
                </LegendsWrapper>
            </DescriptionWrapper>

            <Wrapper>
                {isEmpty ? <NoData /> :
                    <VictoryChart
                        theme={theme}
                        height={200}
                        width={1000}
                        padding={{ top: 10, bottom: 10, left: paddingLeft, right: paddingRight }}
                        containerComponent={
                            // VictoryVoronoiContainer used so we can show tooltips on VictoryLine
                            <VictoryVoronoiContainer/>
                        }
                    >
                        <VictoryAxis
                            dependentAxis
                            crossAxis={false}
                            tickCount={6}
                            tickFormat={v => toCommonMonetaryAmountString(i18n.language, v, currency)}
                            offsetX={paddingLeft - 20}
                            style={{
                                grid: {
                                    stroke: 'transparent',
                                },
                                axis: { stroke: 'transparent' },
                                tickLabels: { fill: colors.primary, fontSize: axisLabelFontSize, fontWeight: 700 },
                            }}/>
                        {volumeKpis && <VictoryBar
                            style={{ data: { fill: '#fff', stroke: (({ datum }) => datum.y ? colors.primary : 'transparent'), strokeWidth: 1 } }}
                            data={
                                volumeKpis.values.map((datum, i) => ({ x: i, y: datum.value, label: [t('dashboard.kpis.captured_transactions.week', { week: getISOWeek(new Date(datum.date)) }), formatMoney(i18n.language, datum.value, currency)]}))
                            }
                            animate={{
                                duration: 400,
                                onLoad: { duration: 400 },
                            }}
                            barWidth={30}
                            alignment="middle"

                            labelComponent={<VictoryTooltip
                                cornerRadius={2}
                                pointerLength={3}
                                dy={props => getTooltipDy(volumeKpis, countKpis, parseInt(props.index + ''), 1/ratio)}
                                flyoutStyle={{
                                    display: (props => props.datum.y === null ? 'none' : 'block'),
                                    stroke: colors.primary,
                                    fill: '#fff',
                                }}
                                style={{
                                    display: (props => props.datum.y === null ? 'none' : 'block'),
                                    fill: colors.primary,
                                    fontSize: tooltipLabelFontSize,
                                    fontWeight: 700,
                                    fontFamily,
                                }}
                                orientation={props => getTooltipOrientation(volumeKpis, countKpis, parseInt(props.index), 1/ratio)}

                            />}
                        />}
                        {countKpis && <VictoryLine
                            groupComponent={
                            // VictoryClipContainer used so 0 values in the VictoryLine are not clipped
                                <VictoryClipContainer clipPadding={{ top: 5, bottom: 5 }} />
                            }
                            style={{ data: { stroke: 'black', strokeWidth: 2 } }}
                            data={
                                countKpis.values.map((datum, i) => ({ x: i, y: datum.value === null ? null : Math.round(datum.value * ratio), label: [t('dashboard.kpis.captured_transactions.week', { week: getISOWeek(new Date(datum.date)) }), t('dashboard.kpis.captured_transactions.captures', { count: datum.value })]}))
                            }
                            animate={{
                                duration: 400,
                                onLoad: { duration: 400 },
                            }}
                            labelComponent={<VictoryTooltip
                                cornerRadius={2}
                                pointerLength={3}
                                dy={props => getTooltipDy(countKpis, volumeKpis, parseInt(props.index + ''), ratio)}
                                flyoutStyle={{
                                    display: (props => props.datum.y === null ? 'none' : 'block'),
                                    stroke: 'transparent',
                                    fill: 'black',
                                    padding: 5,
                                }}
                                flyoutWidth={100}
                                style={{
                                    display: (props => props.datum.y === null ? 'none' : 'block'),
                                    fill: 'white',
                                    fontSize: tooltipLabelFontSize,
                                    fontWeight: 700,
                                    fontFamily,
                                }}
                                orientation={props => getTooltipOrientation(countKpis, volumeKpis, parseInt(props.index), ratio)}
                            />}
                        />}
                        <VictoryAxis
                            dependentAxis
                            orientation="right"
                            tickFormat={v => Math.round(v / ratio) || 0}
                            tickCount={5}
                            tickValues={rightTickValues}
                            style={{
                                grid: {
                                    stroke: 'transparent',
                                },
                                axis: { stroke: 'transparent' },
                                tickLabels: { fill: 'black', fontSize: axisLabelFontSize, fontWeight: 700 },
                            }}
                            offsetX={paddingRight - 20}
                        />
                    </VictoryChart>}
            </Wrapper>


        </MarginTopCard>
    );
};
// memoize this function as it is triggered on every mousemove that displays a tooltip
const currentLargerThanOtherAtIndex = memoize((currentVal: number, otherVal: number | undefined, ratio: number) => {
    if (otherVal === undefined || otherVal === null) {
        return true;
    }
    const normalizedCurrentVal = currentVal * ratio;
    if (normalizedCurrentVal === otherVal) {
        // If both values are the same we use the ratio for comparison so that volume tooltip is
        // rendered over and count is rendered under values.
        return ratio < 1;
    }
    return normalizedCurrentVal > otherVal;
}, (currentVal, otherVal, ratio) => `${currentVal}:${otherVal}:${ratio}`);

const getTooltipDy = (current: InsightCheckoutKpis, other: InsightCheckoutKpis | undefined, index: number, ratio: number) => {
    const currentVal = current.values[index].value;
    const otherVal = other?.values[index]?.value;
    return currentLargerThanOtherAtIndex(currentVal, otherVal, ratio) ? -5 : 5;
};

const getTooltipOrientation = (current: InsightCheckoutKpis, other: InsightCheckoutKpis | undefined, index: number, ratio: number) => {
    const currentVal = current.values[index].value;
    const otherVal = other?.values[index]?.value;
    return currentLargerThanOtherAtIndex(currentVal, otherVal, ratio) ? 'top' : 'bottom';
};

const FilterButton = styled.span`
      margin: auto 4px;
      background: none;
      color: ${colors.primary};
      border: none;
      min-width: initial;
      cursor: pointer;
      &:hover {
        color: ${colors.primaryHover};
      }
      &.active {
        color: initial;
        font-weight: 700;
      }
`;

const Group = styled.div<{position?: string}>`
    display: flex;
    justify-content: ${props => props.position === 'apart' ? 'space-between' : 'initial'};
    > span:not(span:last-of-type) {
        border-right: ${border.normal} solid ${colors.borderLight};
        padding-right: 8px;
    }
`;

const Wrapper = styled.div`
    overflow: visible;
`;

const LegendVolume = styled.div`
    color: ${colors.primary};
    white-space: nowrap;
`;
const LegendVolumeExample = styled.div`
    border: 2px solid ${colors.primary};
    height: 10px;
    width: 10px;
    margin-right: 4px;

    @media (max-width: 1024px) {
        border-width: 1px;
    }
`;

const LegendCount = styled.div`
    color: '#000';
    white-space: nowrap;
`;
const LegendCountExample = styled.div`
    border-top: 2px solid #000;
    height: 0px;
    width: 10px;
    margin-right: 4px;

    @media (max-width: 1024px) {
        border-top: 1px solid #000;
    }
`;

const LegendWrapper = styled.div`
    display:flex;
    align-items: center;
    margin-right: 16px;
`;

const LegendsWrapper = styled.div`
    display: flex;
    margin-left: 8px;
    margin-bottom: 8px;

    @media (max-width: 1024px) {
        flex-direction: column;
    }
`;

const DescriptionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;


export default CaptureChart;
