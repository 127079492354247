import React from 'react';
import { Vipps } from './vipps';
import { Instabank } from './instabank';
import { Payex } from './payex';
import { Payex as PayexCallback } from './payexCallback';
import { Collector } from './collector';
import { Klarna } from './klarna';
import { Swish } from './swish';
import { CollectorCallback } from './collectorCallback';
import { Bambora } from './bambora';

const ConnectionModals = () => (
    <>
        <Vipps />
        <Instabank />
        <Payex />
        <PayexCallback />
        <Collector />
        <CollectorCallback />
        <Swish />
        <Klarna />
        <Bambora />
    </>
);

export default ConnectionModals;
