import { Actions, ActionTypes } from './actions';
import { Subscription } from '../../../types/webhooks';

export interface State {
    isLoading: boolean;
    hook: Subscription | undefined;
}

export const initialState: State = {
    isLoading: false,
    hook: undefined,
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.ResetForm:
            return {
                ...initialState,
            };

        case ActionTypes.CreateHook:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.CreateHookResponse:
            return {
                ...state,
                isLoading: false,
                hook: action.payload.hook,
            };

        default:
            return state;
    }
}
