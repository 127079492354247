import * as React from 'react';
import styled from 'styled-components/macro';

import { colors, distances } from '../../styles/constants';
import { WithTranslation, withTranslation } from 'react-i18next';

interface EnvironmentBadgeProps extends WithTranslation {
    environment: 'test' | 'production' | 'unknown';
    label?: string;
}

const EnvironmentBadge = (
    {
        t,
        environment,
        label,
    }: EnvironmentBadgeProps
) => (<StyledEnvironmentBadge environment={environment}>
    {label ? label : t(`environments.${environment}`)}
</StyledEnvironmentBadge>);

const getEnvColor = (environment: 'test' | 'production' | 'unknown') => {
    if (environment === 'test') {
        return colors.warning;
    }
    if (environment === 'production') {
        return colors.valid;
    }
    return colors.primary;
};

interface StyledEnvironmentBadgeProps {
    environment: 'test' | 'production' | 'unknown';
}
const StyledEnvironmentBadge = styled.span<StyledEnvironmentBadgeProps>`
    color: ${colors.background};
    font-weight: 700;
    font-size: 11px;
    padding: 2px ${distances.tiny};
    border-radius: 2px;
    background: ${(props) => getEnvColor(props.environment)};
`;

export default withTranslation()(EnvironmentBadge);
