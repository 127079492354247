import { reducer as users, namespace as usersNamespace } from './users';
import { reducer as userDetails, namespace as userDetailsNamespace } from './userDetails';
import { reducer as newUser, namespace as newUserNamespace } from './newUser';
import { reducer as editUser, namespace as editUserNamespace } from './editUser';

export interface State extends
    users.State {
    [userDetailsNamespace]: userDetails.State;
    [newUserNamespace]: newUser.State;
    [editUserNamespace]: editUser.State;
}

export const initialState: State = {
    ...users.initialState,
    [userDetailsNamespace]: userDetails.initialState,
    [newUserNamespace]: newUser.initialState,
    [editUserNamespace]: editUser.initialState,
};

export const reducers = {
    ...users.reducers,
    [userDetailsNamespace]: userDetails.reducer,
    [newUserNamespace]: newUser.reducer,
    [editUserNamespace]: editUser.reducer,
};
