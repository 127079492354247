import { connect } from 'react-redux';

import { createClient, resetForm, toggleSecretIsSafe, getAccount } from '../actions';
import { State } from '../../../../reducer';
import { getClientSelector } from '../selectors';
import { getAccountUser } from '../../../../auth/accessToken/selectors';

import NewCheckoutClientForm from '../components/NewCheckoutClientForm';

const mapStateToProps = (state: State) => ({
    client: getClientSelector(state),
    accountUser: getAccountUser(state),
});

const mapDispatchToProps = {
    createClient,
    resetForm,
    toggleSecretIsSafe,
    getAccount,
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCheckoutClientForm);
