import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { distances } from '../../../styles/constants';
import { H3 } from '../../../components/Typography';
import { Table, Thead, Tbody, Th, Td, Tr } from '../../../components/Table';
import { BillingSubscription } from '../../../types/billing';
import { Json } from '../../../components/Formatters';
import Card from '../../../components/Card';
import Hr from '../../../components/Hr';

export interface MerchantSubscriptionPricesProps extends WithTranslation {
    subscriptions: BillingSubscription[];
}

const MerchantSubscriptionPrices = (
    {
        t,
        subscriptions,
    }: MerchantSubscriptionPricesProps
) => {
    if (subscriptions.length === 0) {
        return null;
    }
    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('partner.merchant.subscription_prices.header')}</H3>
            <Hr />
            <Table className="striped">
                <Thead>
                    <Tr>
                        <Th>{t('partner.merchant.subscription_prices.fields.description')}</Th>
                        <Th>{t('partner.merchant.subscription_prices.fields.action')}</Th>
                        <Th>{t('partner.merchant.subscription_prices.fields.dimensions')}</Th>
                        <Th>{t('partner.merchant.subscription_prices.fields.prices')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {subscriptions.map((s) => (
                        <Tr key={s.id}>
                            <Td>{s.description}</Td>
                            <Td>
                                <code>
                                    {s.namespace}: {s.metric_name}
                                </code>
                            </Td>
                            <Td>
                                <Json data={s.dimensions} />
                            </Td>
                            <Td>
                                <Json data={s.prices} />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Card>
    );
};

export default withTranslation()(MerchantSubscriptionPrices);
