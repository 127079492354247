import { connect } from 'react-redux';

import { getCheckoutConfiguration, editPaymentProfile, resetForm } from '../actions';
import { State } from '../../../../reducer';
import { getPaymentProfileSelector } from '../selectors';
import { getAccountUser } from '../../../../auth/accessToken/selectors';

import EditPaymentProfileForm from '../components/EditPaymentProfileForm';

const mapStateToProps = (state: State) => ({
    paymentProfile: getPaymentProfileSelector(state),
    accountUser: getAccountUser(state),
});

const mapDispatchToProps = {
    resetForm,
    editPaymentProfile,
    getCheckoutConfiguration,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditPaymentProfileForm);
