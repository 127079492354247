import React from 'react';
import styled from 'styled-components/macro';

import { distances, globalMobileBreakpoint, palette } from '../../styles/constants';
import AppBarDinteroLogo from './AppBarDinteroLogo';

type AppBarContainerProps = {
    children: React.ReactNode;
    noInlinePadding?: boolean;
};

export const AppBarContainer = ({ children, noInlinePadding }: AppBarContainerProps) => (
    <AppBarBg>
        <ScrollableAppBarContainer>
            <AppBarContainerInner className={noInlinePadding ? 'no-inline-padding' : ''}>{children}</AppBarContainerInner>
        </ScrollableAppBarContainer>
        <AtTheBottom>
            <AppBarDinteroLogo color="white" />
        </AtTheBottom>
    </AppBarBg>
);

export const AppBarBg = styled.div`
    position: fixed;
    top: var(--topbar-height);
    left: 0;
    height: calc(100vh - var(--topbar-height, 0px));
    display: flex;
    flex-direction: column;
    z-index: 100;
    width: var(--navbar-width);
    border-right: 1px solid ${palette.neutral[200]};
    background-color: ${palette.darkmode[400]};
    padding-bottom: 24px;
    overflow-x: hidden;

    scrollbar-width: none;

    @media (max-width: ${globalMobileBreakpoint}px) {
        display: none;
    }

`;

export const ScrollableAppBarContainer = styled.div`
    display: contents;

    @media (max-width: ${globalMobileBreakpoint}px) {
        width: 100%;
        min-width: 100%;
        overflow-x: overlay;
        z-index: 2;
        display: block;
        border-left: none;
    }
`;

export const AppBarContainerInner = styled.nav`
    height: 100%;
    align-self: flex-start;
    display: flex;
    flex-direction: row;
    z-index: 1;
    overflow-y: auto;
    transition: all 200ms ease-out;
    padding-inline: 16px;
    padding-top: 16px;
    position: relative;

    scrollbar-width: thin;
    scrollbar-color: ${palette.neutral[200]} transparent;

    overflow-x: hidden;


    @media (max-width: ${globalMobileBreakpoint}px) {
        min-height: auto;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        z-index: 100;
        align-items: center;
        padding: 0;
        gap: ${distances.small};
        padding-inline: ${distances.small};
        width: 100%;
        min-width: max-content;
        border-bottom: 1px solid ${palette.neutral[200]};
        overflow-y: unset;
        overflow-x: unset;

        &.no-inline-padding {
            padding-inline: 0;
        }
    }

    width: var(--navbar-width);

    &::-webkit-scrollbar {
        width: 3px;
    }
    &::-webkit-scrollbar-thumb {
        background-color: ${palette.neutral[200]};
        border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }
`;

export const AtTheTop = styled.div`
    flex-grow: 99;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const AtTheBottom = styled.div`
    position: fixed;
    padding-bottom: var(--Spacing-3);
    bottom: 0;
    width: calc(var(--navbar-width) - 1px);
    left: 0;
    z-index: 4;
    background: ${palette.darkmode[400]}aa;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Spacer = styled.div`
    padding-bottom: 16px;
`;
