import createExternalStore from '../../common/utils/createExternalStore';

export type Message = Readonly<{
    text: string;
    id: string;
    severity: 'info' | 'warning' | 'error' | 'success';
}>;

const messageExStore = createExternalStore({
    messages: [] as Message[],
}, {
    appendMessage: (state, message: Message) => {
        return {
            messages: [...state.messages, message],
        };
    },
    removeVisibleMessage: (state) => {
        return {
            messages: state.messages.slice(1),
        };
    },
}, 'MESSAGE');

export default messageExStore;
