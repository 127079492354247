export const uuidRegex = new RegExp(
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/
);

export const isUuid = (url: string) => {
    return uuidRegex.test(url);
};

export const validateUuid = (message: string) => {
    return (value: string) => {
        if (!value) {
            return;
        }
        const isValid = isUuid(value);
        if (!isValid) {
            return message;
        }
    };
};
