import { useEffect, useRef } from 'react';
import { useAccountId } from '../../auth/useAccountId';
import { P } from '../../components/Typography';
import { AccountAdminUser } from '../../types/management-auth';
import useAccountUser from './useAccountUser';
import useUsers from './useUsers';

type UseCurrentUserType = {
    isLoading: boolean;
    currentUser?: AccountAdminUser;
}

export const useCurrentUser = (): UseCurrentUserType => {
    const accountId = useAccountId();
    const account_user  = useAccountUser();
    const { users, isLoading, refresh } = useUsers();
    const refreshRef = useRef(refresh);
    useEffect(() => {
        if (refreshRef.current) {
            refreshRef.current();
        }
    }, [accountId, refreshRef]);

    if (isLoading) {
        return { isLoading };
    }
    const currentAccount = account_user?.accounts.find(x => x.account_id === accountId);
    const currentUser = (users || []).find(user => user.id === currentAccount?.user.id);
    return {
        isLoading: false,
        currentUser,
    };
};
