import { Formik } from 'formik';
import { Trans, useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { Input, ValidationState } from '../../../../components/Forms';
import { getValueAt } from '../../../../helpers/getValueAt';
import { distances } from '../../../../styles/constants';
import { Account } from '../../../../types/management-auth';
import { useActions } from '../../../../Actions';
import { useSelector } from 'react-redux';
import { getUserId } from '../../../../auth/accessToken/selectors';
import { Button, ButtonGroup } from '../../../../components/Buttons';
import { SwishConnectionCallback } from '../../../../types/management-auth/generated';
import { useState } from 'react';
import { ToggleDetails } from '../../connections/components/styledListItemComponents';
import { validate, validatePositiveNumber, validateRequired } from '../../../../helpers/validation';

type ValidatedInputProps = {
    values: any;
    initialValues: any;
    errors: any;
    path: string;
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    onBlur: (e: React.FormEvent<HTMLInputElement>) => void;
    touched: any;
    required?: boolean;
    maxlength?: number;
    type?: 'text' | 'password';
    formatter?: (value: string) => string;
    autoComplete?: string;
    autoFocus?: boolean;
} & WithTranslation;
const TranslatedValidatedInput = withTranslation()((props: ValidatedInputProps) => {
    const { t, values, initialValues, errors, path, onChange, onBlur, touched, required, maxlength, type, formatter, autoComplete, autoFocus } = props;
    const value = getValueAt(path, values);
    const initialValue = getValueAt(path, initialValues);
    const error = getValueAt(path, errors);
    const wasTouched = getValueAt(path, touched);
    const validation = (wasTouched || initialValue) && error ? {
        state: ValidationState.Invalid,
        message: error,
    } : undefined;
    const label = t(`settings.payment_connections.swish_callback_modal.fields.${path}`);
    return (
        <Input
            label={label}
            placeholder={t(`settings.payment_connections.swish_callback_modal.placeholders.${path}`)}
            type={type || 'text'}
            name={path}
            value={value || ''}
            onChange={(e) => {
                onChange(e);
            }}
            onBlur={(e) => {
                onChange(e);
                onBlur(e);
            }}
            validation={validation}
            maxlength={maxlength}
            autoComplete={autoComplete}
            autoFocus={autoFocus}
            required={required}
        />
    );
});

type SwishFormProps = {
    account: Account;
    accountId: string;
};

export default function SwishForm({ account, accountId }: SwishFormProps) {
    const { t } = useTranslation();
    const { postConnection, closeSwishModal } = useActions('connections.swish');
    const [overrideSettings, setOverrideSettings] = useState(false);
    const userId = useSelector(state => getUserId(state));
    const initialFormValues = {
        swish_number: '',
        cert: '',
        passphrase: '',
        key: '',
        ca: '',
    };
    return (
        <Formik
            enableReinitialize
            validateOnChange
            initialValues={initialFormValues}
            validate={values => {
                let errors: { [key: string]: string } = {};
                if (!values) {
                    // this should not happen but...
                    return { error: 'account not loaded yet...' };
                }

                const validators = [
                    {
                        path: 'swish_number',
                        validator: validateRequired(
                            t(
                                'settings.payment_connections.swish_callback_modal.invalid.swish_number.required'
                            )
                        ),
                    },
                    {
                        path: 'swish_number',
                        validator: validatePositiveNumber(
                            t(
                                'settings.payment_connections.swish_callback_modal.invalid.passphrase.invalid'
                            )
                        ),
                    },
                ];

                errors = validators.reduce((acc, elem) => {
                    return validate(elem.path, elem.validator, values, acc);
                }, errors);

                return errors;
            }}
            onSubmit={values => {
                const signup = {
                    business_name: account.company.business_name,
                    legal_name: account.company.business_name,
                    organization_number: account.company.organization_number || '',
                    website: account.company.website || '',
                    payment_options: [
                        {
                            currencies: ['SEK'],
                            type: 'swish.swish',
                        },
                    ],
                };
                const payload: SwishConnectionCallback = {
                    swish_number: values.swish_number.trim(),
                    cert: values.cert?.trim(),
                    passphrase: values.passphrase?.trim(),
                    key: values.key?.trim(),
                    ca: values.ca?.trim(),
                    payment_options: [
                        {
                            currencies: ['SEK'],
                            type: 'swish.swish',
                        },
                    ],
                };
                postConnection({
                    accountId,
                    body: {
                        payload,
                        signup,
                    },
                    userId,
                    account,
                });
            }}
        >
            {({ handleSubmit, handleChange, handleBlur, values, errors, touched }) => (
                <elements.Form onSubmit={handleSubmit}>
                    <TranslatedValidatedInput
                        path="swish_number"
                        initialValues={initialFormValues}
                        values={values}
                        errors={errors}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched}
                        required
                        autoFocus
                    />
                    <ToggleDetails type="button" onClick={() => setOverrideSettings(!overrideSettings)}>
                        {t('settings.payment_connections.swish_callback_modal.advanced_toggle', {
                            context: overrideSettings ? 'hide' : 'show',
                        })}
                    </ToggleDetails>
                    {overrideSettings && (
                        <>
                            <TranslatedValidatedInput
                                path="cert"
                                initialValues={initialFormValues}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched}
                                autoComplete="nope"
                            />
                            <TranslatedValidatedInput
                                path="passphrase"
                                initialValues={initialFormValues}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched}
                                type="text"
                            />
                            <TranslatedValidatedInput
                                path="key"
                                initialValues={initialFormValues}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched}
                            />
                            <TranslatedValidatedInput
                                path="ca"
                                initialValues={initialFormValues}
                                values={values}
                                errors={errors}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                touched={touched}
                            />
                        </>
                    )}
                    <ButtonGroup>
                        <Button className="alt"
                            type="button"
                            onClick={closeSwishModal}
                        >
                            {t('settings.payment_connections.swish_callback_modal.cancel')}
                        </Button>
                        <Button type="submit"
                            disabled={
                                Object.keys(errors || {}).length > 0 ||
                                Object.keys(touched || {}).length === 0
                            }
                        >
                            {t('settings.payment_connections.swish_callback_modal.send_callback_call_to_action')}
                        </Button>
                    </ButtonGroup>
                </elements.Form>
            )}
        </Formik>
    );
}

const elements = {
    Form: styled.form`
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        gap: ${distances.small};
    `,
};
