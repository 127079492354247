import { Actions, ActionTypes } from './actions';
import { Subscription, Delivery, DeliveryDetails } from '../../../types/webhooks';

export interface State {
    isLoading: boolean;
    hook: Subscription | undefined;
    deliveries: Delivery[];
    isShowingDeleteModal: boolean;
    isDeleted: boolean;
    isShowingPingModal: boolean;
    deliveryDetails: DeliveryDetails[];
}

export const initialState: State = {
    isLoading: false,
    hook: undefined,
    deliveries: [],
    isShowingDeleteModal: false,
    isDeleted: false,
    isShowingPingModal: false,
    deliveryDetails: [],
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.GetHook:
            return {
                ...initialState,
                isLoading: true,
            };

        case ActionTypes.FetchHookResponse:
            return {
                ...state,
                hook: action.payload.hook,
                isLoading: false,
            };

        case ActionTypes.GetDeliveries:
            return {
                ...state,
                isLoading: true,
            };

        case ActionTypes.FetchDeliveriesResponse:
            return {
                ...state,
                deliveries: action.payload.deliveries,
                isLoading: false,
            };

        case ActionTypes.GetDeliveryDetails:
            return {
                ...state,
            };

        case ActionTypes.FetchDeliveryDetailsResponse:
            return {
                ...state,
                deliveryDetails: [...state.deliveryDetails, action.payload.deliveryDetails],
            };

        case ActionTypes.OpenDeleteHookModal:
            return {
                ...state,
                isShowingDeleteModal: true,
            };

        case ActionTypes.DismissDeleteHookModal:
            return {
                ...state,
                isShowingDeleteModal: false,
            };

        case ActionTypes.FetchDeleteHookResponse:
            return {
                ...state,
                isDeleted: true,
            };

        case ActionTypes.OpenPingHookModal:
            return {
                ...state,
                isShowingPingModal: true,
            };

        case ActionTypes.FetchPingHookResponse:
            return {
                ...state,
                isShowingPingModal: false,
            };

        case ActionTypes.DismissPingHookModal:
            return {
                ...state,
                isShowingPingModal: false,
            };

        default:
            return state;
    }
}
