import { effects as hooksEffects } from './hooks';
import { effects as newHookEffects } from './newHook';
import { effects as hookDetailsEffects } from './hookDetails';

const effects = {
    ...hooksEffects,
    ...newHookEffects,
    ...hookDetailsEffects,
};

export default effects;
