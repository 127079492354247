import type { Dispatch } from 'redux';
import fulfill from '../../../fulfill';
import { CORE_API_HOSTNAME } from '../../../env';
import { createSwitchlessReducer } from '../../../util/switchlessReducer';
import { ApprovalsPayment, ApprovalsPaymentResponse } from '../../../types/management-auth/generated';
import equal from 'fast-deep-equal';

export const namespace = 'paymentsApprovals' as const;

export interface State {
    posted: ApprovalsPaymentResponse | undefined;
    isPosting: boolean;
    isLoading: boolean;
    accountId: string;
    approvals: ApprovalsPaymentResponse[] | undefined;
};

export const initialState: State = {
    posted: undefined,
    isPosting: false,
    isLoading: false,
    accountId: '',
    approvals: undefined,
};


export const { reducer, actions } = createSwitchlessReducer({
    namespace: namespace,
    initialState,
    reducers: {
        startLoading: (state: State, { accountId, clear, hiddenUpdateState }: { accountId: string; clear: boolean; hiddenUpdateState: boolean }) => {
            return {
                posted: undefined,
                isPosting: state.isPosting,
                isLoading: hiddenUpdateState ? state.isLoading : true,
                accountId,
                approvals: clear ? undefined : state.approvals,
                isNotFound: false,
            };
        },
        startPosting: (state: State, { accountId, clear }: { accountId: string; clear: boolean }) => {
            return {
                ...state,
                isPosting: true,
                accountId,
            };
        },
        setApprovals: (state: State, { accountId, approvals }: { accountId: string; approvals: ApprovalsPaymentResponse[] }): State => {
            if (state.accountId !== accountId) {
                // disregard old response for wrong account or old query
                return state;
            }
            if (state.isLoading === false && equal(approvals, state.approvals)) {
                // do not reset approvals object if unchanged
                return state;
            }
            return {
                ...state,
                isLoading: false,
                approvals,
            };
        },
        appendApproval: (state: State, { accountId, approval }: { accountId: string; approval: ApprovalsPaymentResponse }): State => {
            if (state.accountId !== accountId) {
                // disregard old response for wrong account or old query
                return state;
            }
            return {
                ...state,
                isPosting: false,
                posted: approval,
                approvals: [...(state.approvals || []), approval],
            };
        },
    },
});

export const createPaymentsApprovalsActions = (dispatch: Dispatch) => {
    const fetchPaymentApprovals = async (accountId: string, hiddenUpdateState?: boolean) => {
        if (!hiddenUpdateState) {
            dispatch(actions.startLoading({ accountId, clear: !Boolean(hiddenUpdateState), hiddenUpdateState: Boolean(hiddenUpdateState) }));
        }
        fulfill.get({
            accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/approvals/payments`,
            handlers: {
                200: ({ payments }: { payments: ApprovalsPaymentResponse[] }) => {
                    dispatch(actions.setApprovals({ accountId, approvals: payments }));
                },
                404: () => {
                    dispatch(actions.setApprovals({ accountId, approvals: []}));
                },
            },
        });
    };
    const postApproval = async (accountId: string, approval: ApprovalsPayment): Promise<ApprovalsPaymentResponse> => {
        dispatch(actions.startPosting({ accountId, clear: false }));
        const result = await fulfill.post({
            accountId,
            url: `${CORE_API_HOSTNAME}/v1/accounts/${accountId}/management/settings/approvals/payments?quickr_version=2`,
            json: approval,
            handlers: {
                200: (approval: ApprovalsPaymentResponse) => approval,
            },
        }) as ApprovalsPaymentResponse;
        dispatch(actions.appendApproval({
            accountId,
            approval: result,
        }));
        return result;
    };
    return {
        fetchPaymentApprovals,
        postApproval,
    };
};
