import { Account } from '../../../types/management-auth';

export enum ActionTypes {
    GetAccount = '[settings/paymentConnections] GET_ACCOUNT',
    FetchAccountResponse = '[settings/paymentConnections] FETCH_ACCOUNT_RESPONSE',
    AddConnectionEvent = '[settings/paymentConnections] ADD_CONNECTION_EVENT',
    AddConnectionEventResponse = '[settings/paymentConnections] ADD_CONNECTION_EVENT_RESPONSE',
}

export interface GetAccount {
    type: ActionTypes.GetAccount;
    payload: {
        accountId: string;
    };
}

export function getAccount(accountId: string): GetAccount {
    return {
        type: ActionTypes.GetAccount,
        payload: {
            accountId,
        },
    };
}

export interface FetchAccountResponse {
    type: ActionTypes.FetchAccountResponse;
    payload: {
        account: Account;
    };
    meta: {
        cause: GetAccount;
    };
}

export type Actions = GetAccount | FetchAccountResponse;
