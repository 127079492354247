import { FormikErrors, FormikTouched } from 'formik';
import Geocode from 'react-geocode';
import { GOOGLE_API_KEY } from '../../../env';
import * as Sentry from '@sentry/react';

import { ValidationState } from '../../../components/Forms';
import { LocationFormValues } from '../types';
import { Address, SalesLocation } from '../../../types/customers';

export const validateInput = (
    fieldName: keyof LocationFormValues,
    errors: FormikErrors<LocationFormValues>,
    touched: FormikTouched<LocationFormValues>
) =>
    errors[fieldName] && touched[fieldName]
        ? {
            state: ValidationState.Invalid,
            message: errors[fieldName] as string,
        }
        : undefined;

export const mapValues = (values: LocationFormValues): SalesLocation => {
    const mappedValues = {
        name: values.name,
        email: values.email,
        status: 'Active',
        organization_number: values.organizationNumber,
        business_name: values.businessName,
        type: values.type.value,
        address: {
            address_line: values.addressLine,
            postal_code: values.postalCode,
            postal_place: values.postalPlace,
            country: values.country.value,
            latitude: values.latitude,
            longitude: values.longitude,
        },
    };

    const { locationId, countryPrefix, phoneNumber, websiteUrl, addressLine2, franchise, franchiseName } = values;

    const composedPhoneNumber = `${countryPrefix}${phoneNumber}`
        .replace(/\s/g, '')
        .replace(/-/g, '')
        .replace('+4607', '+467');

    Object.assign(mappedValues, locationId.length > 0 ? { location_id: locationId } : null);
    Object.assign(mappedValues, phoneNumber.length > 0 ? { phone_number: composedPhoneNumber } : null);
    Object.assign(mappedValues, websiteUrl.length > 0 ? { website_url: websiteUrl } : null);
    Object.assign(mappedValues, addressLine2.length > 0 ? { ...mappedValues.address, ...{ address_line_2: addressLine2 } } : null);
    Object.assign(mappedValues, franchise ? { franchise: franchiseName } : null);

    return mappedValues;
};

const prefixCountryMapping: { [key: string]: string } = {
    SE: '+46',
    NO: '+47',
    PL: '+48',
    DK: '+45',
    GB: '+44',
    IS: '+354',
    FI: '+358',
    DE: '+49',
};

export const getPhonePrefixFromCountry = (country: string | undefined): string => {
    const key = country || '';
    return prefixCountryMapping[key] || '+47';
};

export const getGeoLocation = async (address: Address, language: string): Promise<{
    latitude: number; longitude: number;
} | null> => {
    Geocode.setApiKey(GOOGLE_API_KEY);
    Geocode.setLanguage(language);
    Geocode.setRegion(address.country.toLowerCase());
    try {
        const response = await Geocode.fromAddress(
            `${address.address_line} ${address.address_line_2}, ${address.postal_code} ${address.postal_place}`
        );
        const { lat, lng } = response.results[0].geometry.location;
        return {
            latitude: lat,
            longitude: lng,
        };
    } catch (err) {
        if (!(err?.message || '').includes('ZERO_RESULTS')) {
            console.error(err);
            Sentry.captureException(err);
        }
        return null;
    }
};
