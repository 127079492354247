export const intersectMany = <T extends {}>(sets: Set<T>[]) => {
    if (sets.length === 0) {
        return new Set<T>();
    }
    if (sets.length === 1) {
        return sets[0];
    }
    const [head, ...tail] = sets;
    return tail.reduce(intersection, head);
};

export const intersection = <T extends {}>(setA: Set<T>, setB: Set<T>) => {
    const result = new Set<T>();
    setB.forEach((elem) => {
        if (setA.has(elem)) {
            result.add(elem);
        }
    });
    return result;
};

export const isSuperset = <T>(set: Set<T>, subset: Set<T>) => {
    for (const elem of subset) {
        if (!set.has(elem)) {
            return false;
        }
    }
    return true;
};
