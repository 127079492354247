import { State } from '../../reducer';
import { namespace } from './';
import { createSelector } from 'reselect';

const getCognitoTokenState = (state: State) => state[namespace].cognito_access_token;
export const getCognitoToken = createSelector([getCognitoTokenState], (s) => s);

const getAccountUserState = (state: State) => state[namespace].account_user;
export const getAccountUser = createSelector([getAccountUserState], (s) => s);

const getUserState = (state: State) => state[namespace];
export const getUser = createSelector([getUserState], (s) => s);

const getAccountUserId = (state: State) => {
    const prodAccountId = Object.keys(state[namespace].tokens).find((k) => k.startsWith('P'));
    if (prodAccountId) {
        const prodAccount = state[namespace].account_user.accounts.find((a) => a.account_id === prodAccountId);
        if (prodAccount) {
            return prodAccount.user.id;
        }
    }
    return '';
};
export const getUserId = createSelector([getAccountUserId], (s) => s);

const getTokensState = (state: State) => state[namespace].tokens;
export const getTokens = createSelector([getTokensState], (s) => s);
export const getToken = createSelector(
    (state: State, _props: { accountId: string }) => state[namespace].tokens,
    (_state: State, props: { accountId: string }) => props.accountId,
    (tokens, accountId) => tokens[accountId] ?? undefined
);

const getIsLoadingTokensState = (state: State) => state[namespace].isLoadingTokens;
export const getIsLoadingTokens = createSelector([getIsLoadingTokensState], (s) => s);

const getIsLoadingSubAccountTokensState = (state: State) => state[namespace].isLoadingSubAccountTokens;
export const getIsLoadingSubAccountTokens = createSelector([getIsLoadingSubAccountTokensState], (s) => s);

const getExchangeTokenPartnerIdState = (state: State) => state[namespace].exchangeTokenPartnerId;
export const getExchangeTokenPartnerId = createSelector([getExchangeTokenPartnerIdState], (s) => s);

const getChangePasswordStateState = (state: State) => state[namespace].changePasswordState;
export const getChangePasswordState = createSelector([getChangePasswordStateState], (s) => s);

const getChangeMfaSmsStateState = (state: State) => state[namespace].changeMfaSmsState;
export const getMfaSmsState = createSelector([getChangeMfaSmsStateState], (s) => s);

const getChangeMfaTotpStateState = (state: State) => state[namespace].changeMfaTotpState;
export const getMfaTotpState = createSelector([getChangeMfaTotpStateState], (s) => s);
