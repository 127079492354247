import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components/macro';

import DinteroLogo from './dintero-dark.svg';
import BackgroundAnimatedGrid from './BackgroundAnimatedGrid';
import Login from './Login';
import ForgotPassword from './ForgotPassword';
import VerifyMfa from './VerifyMfa';
import VerifyForgotPassword from './VerifyForgotPassword';
import { colors, defaultRadius, palette } from '../../../styles/constants';
import { CognitoUser } from 'amazon-cognito-identity-js';
import useWindowSize from '../../../util/useWindowSize';
import { MfaType, SelectMfaTypeOptions } from '../../types';
import SelectMfaType from './SelectMfaType';

const LoginPage = () => {
    const [loginView, setLoginView] = useState<'login' | 'forgotPassword' | 'verifyForgotPassword' | 'mfaRequired' | 'selectMfaType'>(
        'login'
    );
    const [shake, setShake] = useState<boolean>(false);
    const [init, setInit] = useState<boolean>(true);

    const [username, setUsername] = useState<string>('');
    const [cognitoUser, setCognitoUser] = useState<CognitoUser | undefined>();
    const [mfaType, setMfaType] = useState<MfaType | undefined>();
    const [selectMfaTypeOptions, setSelectMfaTypeOptions] = useState<undefined | SelectMfaTypeOptions>();
    if (loginView === 'login') {
        return (
            <LoginPageWrapper shake={shake} setShake={setShake} init={init} setInit={setInit}>
                <Login
                    username={username}
                    setForgotPassword={(username: string) => {
                        setUsername(username);
                        setLoginView('forgotPassword');
                        setCognitoUser(undefined);
                    }}
                    setMfaRequired={(cognitoUser: CognitoUser, mfaType) => {
                        setCognitoUser(cognitoUser);
                        setLoginView('mfaRequired');
                        setMfaType(mfaType);
                    }}
                    setSelectMfaRequired={(cognitoUser: CognitoUser, options) => {
                        setCognitoUser(cognitoUser);
                        setSelectMfaTypeOptions(options);
                        setLoginView('selectMfaType');
                    }}
                    setShake={setShake}
                />
            </LoginPageWrapper>
        );
    }
    if (loginView === 'forgotPassword') {
        return (
            <LoginPageWrapper shake={shake} setShake={setShake} init={init} setInit={setInit}>
                <ForgotPassword
                    username={username}
                    setSubmitUsername={(username: string) => {
                        setUsername(username);
                        setLoginView('verifyForgotPassword');
                        setCognitoUser(undefined);
                    }}
                    setBack={(username: string) => {
                        setUsername(username);
                        setLoginView('login');
                        setCognitoUser(undefined);
                    }}
                    setShake={setShake}
                />
            </LoginPageWrapper>
        );
    }
    if (loginView === 'mfaRequired' && cognitoUser) {
        return (
            <LoginPageWrapper shake={shake} setShake={setShake} init={init} setInit={setInit}>
                <VerifyMfa
                    cognitoUser={cognitoUser}
                    setBack={() => {
                        setLoginView('login');
                        setCognitoUser(undefined);
                    }}
                    setShake={setShake}
                    mfaType={mfaType || 'SMS_MFA'}
                />
            </LoginPageWrapper>
        );
    }
    if (loginView === 'selectMfaType' && cognitoUser && selectMfaTypeOptions) {
        return (
            <LoginPageWrapper shake={shake} setShake={setShake} init={init} setInit={setInit}>
                <SelectMfaType
                    cognitoUser={cognitoUser}
                    setBack={() => {
                        setLoginView('login');
                        setCognitoUser(undefined);
                    }}
                    setMfaRequired={(cognitoUser: CognitoUser, mfaType) => {
                        setCognitoUser(cognitoUser);
                        setLoginView('mfaRequired');
                        setMfaType(mfaType);
                    }}
                    setShake={setShake}
                    selectMfaTypeOptions={selectMfaTypeOptions}
                />
            </LoginPageWrapper>
        );
    }
    return (
        <LoginPageWrapper shake={shake} setShake={setShake} init={init} setInit={setInit}>
            <VerifyForgotPassword
                username={username}
                setBack={() => {
                    setLoginView('login');
                    setCognitoUser(undefined);
                }}
                setMfaRequired={(cognitoUser: CognitoUser, mfaType) => {
                    setCognitoUser(cognitoUser);
                    setLoginView('mfaRequired');
                    setMfaType(mfaType);
                }}
                setShake={setShake}
                setSelectMfaRequired={(cognitoUser: CognitoUser, options) => {
                    setCognitoUser(cognitoUser);
                    setSelectMfaTypeOptions(options);
                    setLoginView('selectMfaType');
                }}
            />
        </LoginPageWrapper>
    );
};

type Cover = {
    width: number;
    height: number;
    offsetY: number;
    offsetX: number;
};

const calculateCover = (windowWidth: number, windowHeight: number, aspectRatio: number): Cover => {
    // emulate css cover where the image fits to the widow but keeps aspect ratio intact
    const heightFromWidth = windowWidth / aspectRatio;
    if (heightFromWidth > windowHeight) {
        return {
            width: windowWidth,
            height: heightFromWidth,
            offsetY: heightFromWidth - windowHeight,
            offsetX: 0,
        };
    }
    const widthFromHeight = windowHeight * aspectRatio;
    return {
        width: widthFromHeight,
        height: windowHeight,
        offsetY: 0,
        offsetX: widthFromHeight - windowWidth,
    };
};

const LoginPageWrapper = ({ children, shake, setShake, init, setInit }: {
    children: React.ReactNode;
    shake: boolean;
    setShake: (value: boolean) => void;
    init: boolean;
    setInit: (value: boolean) => void;
}) => {
    const { width, height } = useWindowSize();
    const cover = calculateCover(width, height, 1400 / 800);
    return (
        <>
            <BackgroundWrapper cover={cover}>
                <BackgroundAnimatedGrid />
            </BackgroundWrapper>
            <LoginContainer onAnimationEnd={() => {
                setInit(false);
                setShake(false);
            }} className={[shake ? 'shake' : '', init ? 'init' : ''].join(' ')}>
                <LogoWrapper>
                    <img src={DinteroLogo} alt="Dintero Logo" />
                </LogoWrapper>
                {children}
            </LoginContainer>
        </>
    );
};

const shake = keyframes`
  10%, 90% {
    transform: translateX(-2px);
  }
  20%, 80% {
    transform: translateX(4px);
  }

  30%, 50%, 70% {
    transform: translateX(-6px);
  }

  40%, 60% {
    transform: translateX(8px);
}`;

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
    margin: auto;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    }
  to {
    opacity: 1;
    transform: translateY(0);
    margin: auto;
    box-shadow: 215px 304px 104px 0px rgba(0, 0, 0, 0.00), 137px 194px 95px 0px rgba(0, 0, 0, 0.01), 77px 109px 80px 0px rgba(0, 0, 0, 0.02), 34px 49px 60px 0px rgba(0, 0, 0, 0.03), 9px 12px 33px 0px rgba(0, 0, 0, 0.04);
}`;

const LoginContainer = styled.div`
    z-index: 1;
    width: 562px;
    padding: 60px 37px;
    margin: auto;
    box-shadow: 215px 304px 104px 0px rgba(0, 0, 0, 0.00), 137px 194px 95px 0px rgba(0, 0, 0, 0.01), 77px 109px 80px 0px rgba(0, 0, 0, 0.02), 34px 49px 60px 0px rgba(0, 0, 0, 0.03), 9px 12px 33px 0px rgba(0, 0, 0, 0.04);
    background-color: white;
    transform-origin: center;
    border: 1px solid ${colors.borderDark};
    border-radius: ${defaultRadius};

    &.init {
        animation: ${fadeIn} 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97);
    }

    &.shake {
        animation: ${shake} 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    }

    @media (max-width: 768px) {
        width: 100%;
        height: 100vh;
        margin: 0;
        bottom: 0;
    }

    & > * {
        max-width: 328px;
        margin: 0 auto;
    }
`;

const draw = keyframes`
    to {
        stroke-dashoffset: 8000;
    }
`;

const BackgroundWrapper = styled.div<{ cover: Cover }>`
    z-index: 0;
    position: fixed;
    background: ${palette.neutral[900]};
    top: -${(props) => props.cover.offsetY / 2}px;
    bottom: -${(props) => props.cover.offsetY / 2}px;
    left: -${(props) => props.cover.offsetX / 2}px;
    right: -${(props) => props.cover.offsetX / 2}px;
    > svg {
        height: ${(props) => props.cover.height}px;
        width: ${(props) => props.cover.width}px;
        path {
            stroke-dasharray: 4000;
            stroke-dashoffset: 4000;
            animation: ${draw} 4s ease forwards;
        }
        opacity: 0.5;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

const LogoWrapper = styled.div`
    margin-bottom: 37px;
    width: 135px;
    position: relative;
    margin-inline: auto;
    img {
        width: 100%;
        height: auto;
    }
`;

export default LoginPage;
