import styled from 'styled-components/macro';

import { distances } from '../../styles/constants';

const Tbody = styled.tbody`
    margin: ${distances.normal} 0;
    padding: 0;
`;

export default Tbody;
