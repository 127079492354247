import { effects as connectionsEffects } from './connections';
import { effects as instabankEffects } from './instabank';
import { effects as payexCallbackEffects } from './payexCallback';

const effects = {
    ...connectionsEffects,
    ...instabankEffects,
    ...payexCallbackEffects,
};

export default effects;
