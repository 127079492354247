import { createContext, useContext } from 'react';

export function createFullContext<T>(errorMessage: string) {
    const Context = createContext<T | null>(null);
    const useFullContext = () => {
        const context = useContext(Context);
        if (!context) {
            throw new Error(errorMessage);
        }
        return context;
    };
    const Provider = ({ value, children }: { value: T; children: React.ReactNode }) => {
        return <Context.Provider value={value}>{children}</Context.Provider>;
    };

    return [Provider, useFullContext] as const;
}

type AccordionContext = {
    isItemActive: (id: string) => boolean;
    onChange: (id: string) => void;
    getControlId: (id: string) => string;
    getRegionId: (id: string) => string;
}

export const [AccordionProvider, useAccordionContext] = createFullContext<AccordionContext>('Accordion component not found in the tree');

type AccordionItemContext = {
    value: string;
};

export const [AccordionItemProvider, useAccordionItemContext] = createFullContext<AccordionItemContext>('AccordionItem component not found in the tree');
