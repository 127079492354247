import React, { Component } from 'react';
import { Trans, WithTranslation, useTranslation, withTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components/macro';

import Card from '../../../../components/Card';
import { LoadingOverlay } from '../../../../components/Loading';
import Page, { PageButtonLink } from '../../../../components/Page';
import { LinkTr, Table, Tbody, Td, Th, Thead, Tr } from '../../../../components/Table/Fake';
import { H1, P } from '../../../../components/Typography';

import { AccountIdsProps, withAccountIds } from '../../../../auth/accessToken/withAccountIds';
import { ButtonLink } from '../../../../components/Buttons';
import EmptyState, { EmptyStateIconWrapper } from '../../../../components/EmptyState';
import Icon from '../../../../components/Icons';
import { colors, distances } from '../../../../styles/constants';
import { Subscription } from '../../../../types/webhooks';
import HookIcon from '../HookIcon';
import { State as HooksState } from '../reducer';

interface MatchParams {
    accountId: string;
}

export interface HooksListProps extends WithTranslation, RouteComponentProps<MatchParams>, AccountIdsProps {
    getHooks: (accountIds: string[]) => void;
    hooks: HooksState;
}

const SubscriptionRow = (
    {
        subscription,
        accountId,
    }: {
        subscription: Subscription;
        accountId: string;
    }
) => {
    const { t } = useTranslation();
    return (
        <SubscriptionTr
            key={subscription.id}
            to={`/${accountId}/settings/hooks/${subscription.account_id}/${subscription.id}`}
            className={subscription.deleted_at ? 'deleted' : ''}
        >
            <Td className="first-child ellipsis">{subscription.config.url}</Td>
            <Td>
                {subscription.deleted_at ? (
                    <Deleted>{t('settings.hooks.state.deleted')}</Deleted>
                ) : subscription.active ? (
                    <Active>{t('settings.hooks.state.active')}</Active>
                ) : (
                    <InActive>{t('settings.hooks.state.disabled')}</InActive>
                )}
            </Td>
            <Td className="last-child ellipsis">
                <code>{subscription.events.join(' ')}</code>
            </Td>
        </SubscriptionTr>
    );
};

const SubcriptionsTable = (
    {
        subscriptions,
        accountId,
    }: {
        subscriptions: Subscription[];
        accountId: string;
    }
) => {
    const { t } = useTranslation();
    return (
        <MarginTopWrapper>
            <Table className="no-wrap">
                <Thead>
                    <Tr>
                        <Th className="first-child ellipsis">{t('settings.hooks.fields.url')}</Th>
                        <Th>{t('settings.hooks.fields.state')}</Th>
                        <Th className="last-child">{t('settings.hooks.fields.events')}</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {subscriptions.map((subscription) => (
                        <SubscriptionRow
                            key={subscription.id}
                            subscription={subscription}
                            accountId={accountId}
                        />
                    ))}
                </Tbody>
            </Table>
        </MarginTopWrapper>
    );
};

class HookList extends Component<HooksListProps> {
    componentDidMount() {
        this.props.getHooks([this.props.match.params.accountId]);
    }

    render() {
        const { t } = this.props;
        const { hooks, isLoading } = this.props.hooks;
        const accountId = this.props.match.params.accountId;

        const hasSubscriptions = (hooks || []).filter((x) => x.account_id === accountId).length > 0;
        const subscriptions = hasSubscriptions ? hooks.filter((x) => x.account_id === accountId) : [];

        return (
            <Page
                title={
                    <React.Fragment>
                        <Title>{t('settings.hooks.title')}</Title>
                        <PageButtonLink to={`/${accountId}/settings/hooks/new`}>
                            <Icon icon="plus" fill="currentColor" />
                            {t('settings.hooks.create_new_hook')}
                        </PageButtonLink>
                    </React.Fragment>
                }
            >
                {hasSubscriptions && (
                    <SubcriptionsTable
                        accountId={accountId}
                        subscriptions={subscriptions}
                    />
                )}
                {!hasSubscriptions && (
                    <Card noPadding>
                        <Wrapper>
                            {isLoading && <LoadingOverlay />}
                            {!isLoading && hooks.length === 0 && (
                                <EmptyState>
                                    <EmptyStateIconWrapper>
                                        <HookIcon />
                                    </EmptyStateIconWrapper>

                                    <P>
                                        {t('settings.hooks.empty.message')}
                                        <br />
                                        <br />
                                    </P>
                                    <P>
                                        <Trans i18nKey="settings.hooks.empty.docs">
                                            <a
                                                href="https://docs.dintero.com/docs/webhooks/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {' '}
                                            </a>
                                        </Trans>
                                    </P>
                                    <ButtonLink to={`/${accountId}/settings/hooks/new`}>
                                        {t('settings.hooks.empty.call_to_action')}
                                    </ButtonLink>
                                </EmptyState>
                            )}
                        </Wrapper>
                    </Card>
                )}
            </Page>
        );
    }
}

export default withAccountIds(withTranslation()(HookList));

const Wrapper = styled.div`
    position: relative;
`;

const MarginTopWrapper = styled.div`
`;

const Title = styled(H1)`
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const SubscriptionTr = styled(LinkTr)`
    &.deleted {
        background-color: ${colors.backgroundAlt};
    }
`;

const Active = styled.span`
    color: ${colors.primary};
`;

const Deleted = styled.span`
    color: ${colors.invalid};
`;

const InActive = Deleted;
