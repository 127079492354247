import store from './store';
import { getToken } from './auth/accessToken/selectors';
import {
    // addCacheBustParam,
    getAuthorizationBearerHeader,
    getSystemHeaders
} from './fulfill';

const getAccountAuthHeader = (accountId: string) => {
    const state = store.getState();
    const token = getToken(state, { accountId });
    return token ? getAuthorizationBearerHeader(token) : {};
};

const makeHeaders = (
    accountId: string,
    headers: Record<string, string> = {}
) => ({
    // default
    'Accept': 'application/json',
    'Accept-Charset': 'utf-8',
    'Content-Type': 'application/json',
    // overrides
    ...headers,
    // required
    ...getAccountAuthHeader(accountId),
    ...getSystemHeaders(),
});

export const isApiError = (err: unknown): err is IApiError => err instanceof ApiError;

export type IApiError = {
    message: string;
    traceId: string;
    statusText: string;
    status: number;
    requestId: string | undefined;
    userError: boolean;
    forbidden: boolean;
    notAuthorized: boolean;
}

class ApiError extends Error implements IApiError {
    readonly traceId: string;
    readonly statusText: string;
    readonly status: number;
    readonly requestId: string | undefined;
    readonly userError: boolean;
    readonly forbidden: boolean;
    readonly notAuthorized: boolean;

    constructor(message: string, fields: Omit<IApiError, 'message'>) {
        super(message);
        this.name = 'ApiError';
        this.traceId = fields.traceId;
        this.statusText = fields.statusText;
        this.status = fields.status;
        this.requestId = fields.requestId;
        this.userError = fields.userError;
        this.forbidden = fields.forbidden;
        this.notAuthorized = fields.notAuthorized;
        Object.freeze(this);
    }
}

const makeApiError = async (response: Response) => {
    let decoded: Record<string, any> = {};
    try {
        decoded = await response.json();
    } catch (err) {
        // nop
    }
    // TODO Maybe parse swagger error?
    const message = decoded.error?.message ?? response.statusText;
    const requestId = response.headers.get('request-id') ?? undefined;

    const fields = {
        traceId: response.headers.get('x-amzn-trace-id') ?? '',
        statusText: response.statusText,
        status: response.status,
        requestId,
        userError: response.status >= 400 && response.status < 500,
        forbidden: response.status === 403,
        notAuthorized: response.status === 401,
    };
    return new ApiError(message, fields);
};

const apiRequest = async <T = any>(
    method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
    accountId: string,
    url: string,
    headers?: Record<string, string>,
    body?: any
) => {
    const response = await fetch(url, {
        method,
        body: JSON.stringify(body),
        headers: makeHeaders(accountId, headers),
    });

    if (!response.ok) {
        throw await makeApiError(response);
    }

    if (response.headers.get('content-type')?.startsWith('application/json')) {
        return await response.json() as T;
    }

    return await response.text() as unknown as T;
};

export default apiRequest;
