import * as React from 'react';
import styled from 'styled-components/macro';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { distances, colors, border } from '../../styles/constants';

interface PageLinksProps extends WithTranslation {
    currentPage: number;
    currentPageItemsCount: number;
    itemsPerPage: number;
    totalCount?: number;
    nextLocation?: string;
    prevLocation?: string;
}

const PageLinks = (
    {
        t,
        currentPage,
        currentPageItemsCount,
        itemsPerPage,
        totalCount,
        nextLocation,
        prevLocation,
        ...otherProps
    }: PageLinksProps
) => {
    const prevEnabled = currentPage > 0;
    const pageTotalCount = currentPage * itemsPerPage + currentPageItemsCount;
    const nextEnabled = totalCount ? totalCount - pageTotalCount : currentPageItemsCount >= itemsPerPage;

    return (
        <Wrapper>
            <PrevPage to={prevLocation || ''} className={!prevEnabled ? 'disabled' : ''} />{' '}
            <CurrentPage>{t('components.paging.page', { count: currentPage + 1 })}</CurrentPage>
            <NextPage to={nextLocation || ''} className={!nextEnabled ? 'disabled' : ''} />
        </Wrapper>
    );
};

export default withTranslation()(PageLinks);
const Wrapper = styled.div`
    float: right;
    display: flex;
`;
const CurrentPage = styled.span`
    padding: 4px ${distances.small};
`;

const PrevPage = styled(Link)`
    position: relative;
    display: inline-block;
    width: 25px;
    height: 25px;
    border: ${border.normal} solid ${colors.borderLight};
    user-select: none;
    cursor: pointer;

    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url('/assets/icons/arrow_left.svg');
        background-size: cover;
    }

    &.disabled:after {
        background-color: ${colors.borderLight};
        opacity: 0.3;
    }
`;

const NextPage = styled(PrevPage)`
    &:after {
        -moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: 'FlipH';
    }
`;
