import styled from 'styled-components/macro';

import { distances } from '../../../styles/constants';

const Dd = styled.dd`
    padding: 0;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    font-size: 14px;
    line-height: ${distances.normal};

    &.amount {
        text-align: right;
        min-width: 260px;
    }

    &.word-break-all {
        word-break: break-all;
    }
`;

export default Dd;
