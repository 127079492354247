import styled from 'styled-components/macro';

import { grid } from '../../../../../styles/constants';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup } from '../../../../../components/Buttons';

import { useFormik } from 'formik';
import { capitalize } from '../../../../../helpers/string';
import { validate, validatePositiveNumber, validateRequired } from '../../../../../helpers/validation';
import { ValidatedInput } from './ValidatedInput';
import { OverrideDialogProps } from '../../types';
import Modal from '../../../../../components/Modal';
import { H2 } from '../../../../../components/Typography';
import View from '../../../../../components/View';

const SwishOverrideDialog = (
    {
        gateway,
        putOverride,
        isLoading,
        isDialogOpen,
        handleCloseDialog,
    }: OverrideDialogProps
) => {
    const { t } = useTranslation();
    const { name, gatewayDetails } = gateway;
    const formik = useFormik({
        initialValues: {
            swish_number: gatewayDetails.find(detail => detail.key === 'gateway_id')?.value || '',
        },
        validate: (values => {
            let errors: { [key: string]: string } = {};
            if (!values || Object.keys(values).length === 0) {
                // this should not happen but...
                return { error: 'account not loaded yet...' };
            }
            const validators = [
                {
                    path: 'swish_number',
                    validator: validateRequired(t('locations.location_details.payment_connections.edit_override.swish.invalid.swish_number.required')),
                },
                {
                    path: 'swish_number',
                    validator: validatePositiveNumber(t('locations.location_details.payment_connections.edit_override.swish.invalid.swish_number.numeric')),
                },
            ];
            errors = validators.reduce((acc, elem) => {
                return validate(elem.path, elem.validator, values, acc);
            }, errors);
            return errors;
        }),
        onSubmit: values => {
            putOverride(name, values);
        },
    });
    return (
        <form onSubmit={formik.handleSubmit}>
            {isDialogOpen ? <Modal onClose={handleCloseDialog}>
                <View gap={24} direction="column" justify="flex-start" alignItems="flex-start">
                    <View direction="column" justify="flex-start" alignItems="flex-start">
                        <H2>
                            {t('locations.location_details.payment_connections.edit_override.title', { gateway: capitalize(gateway.name) })}
                        </H2>
                        <InputWrapper>
                            <ValidatedInput
                                values={formik.values}
                                errors={formik.errors}
                                path="swish_number"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                touched={formik.touched}
                                label={t('locations.location_details.payment_connections.edit_override.swish.fields.swish_number')}
                                placeholder={t('locations.location_details.payment_connections.edit_override.swish.placeholders.swish_number')}
                            />
                        </InputWrapper>
                    </View>
                    <ButtonGroup>
                        <Button
                            disabled={isLoading}
                            className="alt"
                            type="button"
                            onClick={handleCloseDialog}
                        >
                            {t('locations.location_details.payment_connections.edit_override.cancel')}
                        </Button>
                        <Button disabled={isLoading || !formik.isValid}
                            type="submit">{t('locations.location_details.payment_connections.edit_override.save')}</Button>
                    </ButtonGroup>
                </View>
            </Modal> : null}
        </form>
    );
};

const InputWrapper = styled.div`
  margin-right: ${grid.gutter};

  &:last-child {
    margin: 0;
  }
`;

export default SwishOverrideDialog;
