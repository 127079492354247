import { useTranslation } from 'react-i18next';

import type { ConfigBankAccount } from '../../../payout/types';

import Select from '../../../components/Forms/Select';
import { useState } from 'react';
import { ValidationState } from '../../../components/Forms';

export type BankAccountAutocompleteProps = {
    name: string;
    required?: boolean;
    disabled?: boolean;
    bankAccounts: ConfigBankAccount[];
    onChange: (name: string, value: string | ConfigBankAccount | null, type: 'bban' | 'iban') => void;
    value?: ConfigBankAccount;
}

const BankAccountAutocomplete = ({
    name,
    disabled,
    required,
    bankAccounts,
    onChange,
    value,
}: BankAccountAutocompleteProps) => {
    const { t } = useTranslation('payout');
    const [touched, setTouched] = useState(false);
    const [tempValue, setTempValue] = useState(
        value
            ? `${value.nickname} - ${value.bank_account_number}`
            : ''
    );

    const re = new RegExp(tempValue, 'ig');
    const filteredBankAccounts = bankAccounts.filter(o => o.bank_account_number.match(re) || o.nickname.match(re));

    return (
        <Select
            name={name}
            label={t('bank_account.number_bban')}
            options={filteredBankAccounts}
            getOptionLabel={(option => option.nickname)}
            getOptionValue={(option => option.bank_account_number)}
            getOptionSubLabel={(option => option.bank_account_number)}
            onChange={(value) => {
                if (!value) {
                    setTempValue('');
                    return;
                }
                onChange(name, value, 'bban');
                setTempValue(`${value.nickname} - ${value.bank_account_number}`);
            }}
            onInputChange={(e) => {
                setTouched(true);
                setTempValue(e.target.value);
            }}
            disabled={disabled}
            value={tempValue}
            placeholder=""
            required={required}
            onBlur={() => {
                setTouched(true);
                if (!tempValue) {
                    onChange(name, null, 'bban');
                }
            }}
            validation={required && !value && touched ? {
                state: ValidationState.Invalid,
                message: t('config.create_payout_destination.fields.bank_account.errors.required'),
            } : undefined}
        />
    );
};

export default BankAccountAutocomplete;
