import styled from 'styled-components/macro';
import { colors, distances, grid } from '../../../styles/constants';
import { Button } from '../../../components/Buttons';

interface ErrorMessageProps {
    visible: boolean;
}

export const ErrorMessage = styled.p<ErrorMessageProps>`
    font-size: 11px;
    line-height: ${distances.small};
    color: ${colors.invalid};
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export const Wrapper = styled.div`
    width: 100%;
    max-width: ${grid.spans.span7};
    margin-bottom: ${distances.small};
    font-size: 14px;

    @media (max-width: 768px) {
        width: 100%;
        height: 300px;
    }

    @media (max-width: 350px) {
        width: 100%;
        height: 250px;
    }
`;

export const MfaHeading = styled.div`
    width: 100%;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: 10px;
`;

export const MfaHeadingTitleDescription = styled.div`
    display: flex;
    flex-direction: column;

    h2 {
        margin: 0;
        line-height: 24px;
        display: flex;
        align-items: center;
    }

    p {
        margin: 0;
    }
`;

export const MfaHeadingAction = styled.div`
    align-self: flex-end;
    margin-left: auto;
`;

export const StyledButton = styled(Button as any)`
    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const MfaBadge = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: normal;
    padding: 2px 10px;
    border-radius: 8px;
    display: inline-block;
    background-color: green;
    color: #fff;
    border-width: 1px;
    margin-right: 2px;
    margin-left: 5px;
    position: relative;
    overflow: hidden;
    max-width: 100%;

    &> span {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;
