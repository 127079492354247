import * as React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

interface ItemCounterProps extends WithTranslation {
    currentPage: number;
    currentPageItemsCount: number;
    itemsPerPage: number;
    totalCount?: number;
    entityStringKey?: string;
}

const ItemCounter = (
    {
        t,
        currentPage,
        currentPageItemsCount,
        itemsPerPage,
        totalCount,
        entityStringKey,
    }: ItemCounterProps
) => {
    const fromCount = currentPage * itemsPerPage + 1;
    const toCount = fromCount + currentPageItemsCount - 1;

    // If there are no elements in the list we use the plural form of
    // the entity sting.
    const entityStringCounter = toCount === -1 ? 10 : toCount;

    const entityString = entityStringKey
        ? t(entityStringKey, { count: toCount })
        : t('components.paging.default_entity', { count: entityStringCounter });

    if (currentPageItemsCount === 0 && currentPage === 0) {
        return (
            <span>
                {t('components.paging.empty', {
                    entityString,
                })}
            </span>
        );
    }

    if (currentPageItemsCount === 0 && currentPage > 0) {
        return (
            <span>
                {t('components.paging.last_empty_page', {
                    entityString,
                })}
            </span>
        );
    }

    if (currentPageItemsCount <= itemsPerPage && currentPage === 0 && !totalCount) {
        return (
            <span>
                {t('components.paging.text_without_total_first_page', {
                    entityString,
                    toCount,
                })}
            </span>
        );
    }

    if (totalCount) {
        return (
            <span>
                {t('components.paging.text_with_total', {
                    fromCount,
                    toCount,
                    totalCount,
                    entityString,
                })}
            </span>
        );
    }
    return (
        <span>
            {t('components.paging.text_without_total', {
                fromCount,
                toCount,
                entityString,
            })}
        </span>
    );
};
export default withTranslation()(ItemCounter);
