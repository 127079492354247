import i18next, { FallbackLng, FallbackLngObjList, i18n } from 'i18next';
import Backend from './customI18nBackend';
import LanguageDetector from 'i18next-browser-languagedetector';
// import * according to docs (something to do with module augmentation) https://react.i18next.com/latest/typescript#create-a-declaration-file
import * as reactI18next from 'react-i18next';
// `import type` ensures the import is removed when transpiled by tsc.
import type en from './locales/app/en.json';
import type enPayout from './locales/payout/en.json';

const defaultLangue = 'en';
const { initReactI18next } = reactI18next;
i18next
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: {
            nb: ['no'],
            nn: ['no'],
            'se': ['sv'],
            'nb-NO': ['no'],
            'nb-no': ['no'],
            'nn-NO': ['no'],
            'nn-no': ['no'],
            'en-GB': ['en'],
            'en-US': ['en'],
            'se-SE': ['sv'],
            'sv-SE': ['sv'],
            'sv-FI': ['sv'],
            default: [defaultLangue],
        }, // language to use if translations in user language are not available
        ns: ['app'], // string or array of namespaces to load
        defaultNS: 'app', // default namespace used if not passed to translation function​
        backend: {},
    });

const isFallbackLngObjList = (fallback: FallbackLng): fallback is FallbackLngObjList => {
    if (typeof fallback === 'object' && !Array.isArray(fallback)) {
        return true;
    }
    return false;
};

export const getCurrentLanguage = (i18: i18n) => {
    const language = i18.language;
    const fallback = i18.options.fallbackLng;

    if (fallback && isFallbackLngObjList(fallback)) {
        const fallbackArray = fallback[language];
        if (fallbackArray && fallbackArray[0]) {
            return fallbackArray[0];
        }
    }
    // Handle cases where browser has multiple langues eg. i18n.languages = ['en-GB', ' en'] and i18.language = 'en-GB'
    // but we want to match 'en' instead.
    const validLangues = new Set(
        Object.values(i18.options.fallbackLng || {}).reduce<any[]>((acc, elem) => [...acc, ...elem], [])
    );
    const validLangueMatch = i18.languages.find((x) => validLangues.has(x));
    return validLangueMatch || defaultLangue;
};

type AppLocale = typeof en;
type PayoutLocale = typeof enPayout;

declare module 'react-i18next' {
    interface Resources {
        app: AppLocale;
        payout: PayoutLocale;
    }
}
