import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';
import { useFormik } from 'formik';
import { CognitoUser } from 'amazon-cognito-identity-js';

import { colors, distances, grid } from '../../../styles/constants';
import { Input, Checkbox } from '../../../components/Forms';
import { Button, ButtonGroup } from '../../../components/Buttons';
import { validate, validateRequired } from '../../../helpers/validation';
import { verifyMfa, redirectToApp } from '../../cognitoIdentityWrapper';
import { stringToSlug } from '../helpers';
import { MfaType } from '../../types';
import { P } from '../../../components/Typography';

interface VerifyMfaProps {
    cognitoUser: CognitoUser;
    setBack: () => void;
    setShake: (value: boolean) => void;
    mfaType: MfaType;
}

const VerifyMfa = ({ cognitoUser, setBack, setShake, mfaType }: VerifyMfaProps) => {
    const { t } = useTranslation();
    const [loginError, setLoginError] = useState('');
    const formik = useFormik({
        initialValues: {
            mfa_code: '',
            remember_device: false,
        },
        validate: (values) => {
            let errors: { [key: string]: string } = {};
            const validators = [
                {
                    path: 'mfa_code',
                    validator: validateRequired(t('login.invalid.mfa_code')),
                },
            ];
            errors = validators.reduce((acc, elem) => {
                return validate(elem.path, elem.validator, values, acc);
            }, errors);

            return errors;
        },
        onSubmit: async (values) => {
            try {
                const authenticateResult = await verifyMfa(cognitoUser, values.mfa_code, values.remember_device, mfaType);
                redirectToApp(authenticateResult);
            } catch (error) {
                console.log('Error verify MFA code ', error);
                setLoginError(error.message);
                setShake(true);
            }
        },
    });
    return (
        <>
            {mfaType === 'SMS_MFA' && <P style={{ textAlign: 'center' }}>{t('login.verify_mfa_description')}</P>}
            {mfaType === 'SOFTWARE_TOKEN_MFA' && <P style={{ textAlign: 'center' }}>{t('login.verify_mfa_totp_description')}</P>}

            <ErrorMessage visible={loginError !== ''}>
                {t('login.verify_mfa_error', { context: stringToSlug(loginError) })}
            </ErrorMessage>
            <form onSubmit={formik.handleSubmit}>
                <InputWrapper>
                    <Input
                        label={t('login.fields.mfa_code')}
                        placeholder={t(`login.placeholders.mfa_code`)}
                        autoComplete="one-time-code"
                        pattern="[0-9]{6}"
                        name="mfa_code"
                        inputmode="numeric"
                        type="text"
                        value={formik.values.mfa_code}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoFocus={true}
                        disabled={formik.isSubmitting}
                        required
                    />
                    <Checkbox
                        name="remember_device"
                        label={t(`login.fields.remember_device`)}
                        checked={formik.values.remember_device}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <p />
                </InputWrapper>
                <StyledButtonGroup>
                    <Button type="submit" className="stretch" disabled={!formik.isValid || formik.isSubmitting}>
                        {t('login.actions.verify_mfa_code')}
                    </Button>
                    <Button className="stretch outlined" onClick={setBack}>{t('login.actions.back')}</Button>
                </StyledButtonGroup>
            </form>
        </>
    );
};

const InputWrapper = styled.div`
    margin-right: ${grid.gutter};

    &:last-child {
        margin: 0;
    }
`;

const StyledButtonGroup = styled(ButtonGroup)`
        display: flex;
        flex-direction: column;
        gap: var(--Spacing-3);
    @media (max-width: 768px) {
        margin-right: ${distances.tiny};
    }
`;

interface ErrorMessageProps {
    visible: boolean;
}

const ErrorMessage = styled.p<ErrorMessageProps>`
    text-align: center;
    font-size: 11px;
    line-height: ${distances.small};
    color: ${colors.invalid};
    visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

export default VerifyMfa;
