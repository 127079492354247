import styled from 'styled-components/macro';
import Card from '../../components/Card';
import { P } from '../../components/Typography';

export const ListItem = styled.li`
    margin: 0;
    padding: 0;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const MarginTopCard= styled(Card)`
    margin-top: 8px;
`;

export const CardDescription= styled(P)`
    margin-bottom: 8px;
`;

export const CardTitle = styled.div`
    font-size: 18px;
    font-weight: 700;
    padding-bottom: 8px;
`;
