import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import Card from '../../../components/Card';
import { H3 } from '../../../components/Typography';
import { colors, distances } from '../../../styles/constants';
import { CheckoutConfig } from '../../../types/checkout';
import Hr from '../../../components/Hr';

export type CheckoutConfigurationProps = {
    checkout: CheckoutConfig;
}

const CheckoutConfiguration = (
    {
        checkout,
    }: CheckoutConfigurationProps
) => {
    const { t } = useTranslation();

    if (!checkout.configuration.payment_options.length) {
        return null;
    }
    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('partner.merchant.checkout_configuration')}</H3>
            <Hr />
            {checkout.configuration.payment_options.map((option) => <PaymentProvider key={option.type}>
                <PaymentProviderRow>
                    <PaymentProviderText>{option.type}</PaymentProviderText>
                    {option.currencies && <Group>
                        {option.currencies.map((currency) => <div key={currency}>{currency}</div>)}
                    </Group>}
                </PaymentProviderRow>
            </PaymentProvider>)}
        </Card>
    );
};

export default CheckoutConfiguration;

const PaymentProviderText = styled.div`
    display: inline-block;
    font-weight: 700;
`;

const PaymentProvider = styled.div`
    margin-bottom: ${distances.tiny};
    border-radius: ${distances.tiny};
    border: 2px solid ${colors.backgroundAlt};
`;

const PaymentProviderRow = styled.div`
    padding: ${distances.small};
    display: flex;
    justify-content: space-between;
`;

const Group = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${distances.tiny};
`;
