import React from 'react';
import styled from 'styled-components/macro';
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { distances, grid, colors, border, globalColumnMaxWidth } from '../../../../styles/constants';
import Page, { Dismiss } from '../../../../components/Page';
import { H1, H2, P } from '../../../../components/Typography';
import { Payments, WindowCode } from '../../../../components/nucleoIcons';

export type SelectClientProps = WithTranslation

const SelectClientForm = (
    {
        t,
    }: SelectClientProps
) => {
    return (
        <Page
            title={
                <>
                    <Dismiss />
                    <Title>{t('settings.select_new_client_form.title')}</Title>
                </>
            }
        >
            <Wrapper>
                <HelpText>{t('settings.select_new_client_form.description')}</HelpText>

                <Options>
                    <Option to="./new-checkout">
                        <ImgWrap>
                            <Payments width={'80px'} />
                        </ImgWrap>

                        <OptionTitle>{t('settings.select_new_client_form.checkout_title')}</OptionTitle>
                        <P>{t('settings.select_new_client_form.checkout_description')}</P>
                    </Option>
                    <Option to="./new">
                        <ImgWrap>
                            <WindowCode width={'80px'} />
                        </ImgWrap>

                        <OptionTitle>{t('settings.select_new_client_form.advanced_title')}</OptionTitle>
                        <P>{t('settings.select_new_client_form.advanced_description')}</P>
                    </Option>
                </Options>
            </Wrapper>
        </Page>
    );
};

const ImgWrap = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${distances.normal};
`;

const Title = styled(H1)`
    color: ${colors.text};
    display: inline-block;
        /* Heading/H6/Medium/Desktop */
        font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; /* 140% */
    letter-spacing: -0.4px;
`;

const Wrapper = styled.div`
    position: relative;
    max-width: ${grid.spans.span8};
    margin: 0;
    width: 100%;
    margin-top: ${distances.normal};
`;

const HelpText = styled(P)`
    margin-bottom: ${distances.normal};
    text-align: center;
`;

const Options = styled.div`
    display: flex;
    justify-content: space-between;
    text-align: center;

    @media (max-width: ${globalColumnMaxWidth}px) {
        flex-direction: column;
    }
`;

const OptionTitle = styled(H2)`
    text-align: center;
    margin-bottom: ${distances.small};
`;

const Option = styled(Link)`
    width: 48.5%;
    padding: ${distances.large};
    border: ${border.normal} solid ${colors.borderLight};
    display: block;
    background: ${colors.background};
    text-decoration: none;
    color: ${colors.text};

    &:hover {
        cursor: pointer;
        border-color: ${colors.primaryHover};
    }

    @media (max-width: ${globalColumnMaxWidth}px) {
        width: 100%;
        margin-bottom: ${distances.normal};
    }
`;

export default withTranslation()(SelectClientForm);
