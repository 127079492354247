export const median = (values: number[]) => {
    // Sort values without mutating original input values
    const sorted = [...values].sort(); 
    if (sorted.length % 2 === 0) {
        // Average of the 2 middle values
        const middle = sorted.length / 2;
        return (sorted[middle] + sorted[middle -1])/2;
    };
    // Middle value
    return sorted[Math.floor(sorted.length /2)];
};
