import { StrictMode, Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { Redirect } from 'react-router';
import { BrowserRouter, Route } from 'react-router-dom';
import { SWRConfig } from 'swr';
import { PUBLIC_URL } from './env';
import './i18n';
import './sentry';
import store from './store';

const AppBar = lazy(() => import('./AppBar'));
const AppRoutes = lazy(() => import('./AppRoutes'));
const RedirectToPullRequestRoute = lazy(() => import('./RedirectToPullRequestRoute'));
const Main = lazy(() => import('./components/Main'));
const MobileMenu = lazy(() => import('./components/MobileMenu/MobileMenu'));
const Root = lazy(() => import('./components/Root'));
const ToastProvider = lazy(() => import('./components/Toast/Provider'));
const EnvPatcher = lazy(() => import('./components/TopBar/EnvPatcher'));
const TopBar = lazy(() => import('./components/TopBar/TopBar'));
const WithErrorHandler = lazy(() => import('./errors').then((module) => ({ default: module.WithErrorHandler })));
const Messages = lazy(() => import('./messages/components/Messages'));
const MenuProvider = lazy(() => import('./components/MenuButton/Provider'));
const ActionsProvider = lazy(() => import('./Actions').then((module) => ({ default: module.ActionsProvider })));
const AccountIdChangedDispatcher = lazy(() => import('./auth/useAccountId').then((module) => ({ default: module.AccountIdChangedDispatcher })));
const Allow = lazy(() => import('./auth').then((module) => ({ default: module.Allow })));
const RedirectToAccount = lazy(() => import('./auth').then((module) => ({ default: module.RedirectToAccount })));
const WithAccountAccessToken = lazy(() => import('./auth').then((module) => ({ default: module.WithAccountAccessToken })));
const Welcome = lazy(() => import('./welcome/modal/components/Welcome'));

// Setting basename in BrowserRouter so that pull requests can be served from a relative path
const basename = PUBLIC_URL ? PUBLIC_URL : undefined;

function App() {
    return (
        <StrictMode>
            <Suspense fallback={null}>
                <SWRConfig value={{
                    revalidateOnFocus: false,
                    revalidateOnReconnect: false,
                    revalidateOnMount: true,
                    shouldRetryOnError: false,
                    provider: () => new Map(),
                }}>
                    <ToastProvider>
                        <MenuProvider>
                            <Root>
                                <Suspense fallback={null}>
                                    <Provider store={store}>
                                        <BrowserRouter basename={basename}>
                                            <WithErrorHandler>
                                                <Route path={'/_pr'} component={RedirectToPullRequestRoute} />
                                                <Route path={'/'} exact component={RedirectToAccount} />
                                                <Route path={'/login'} render={() => <Redirect to="/" />} />
                                                <Route
                                                    path={`/:accountId`}
                                                    render={(props) => (
                                                        <ActionsProvider>
                                                            <Messages />
                                                            <AccountIdChangedDispatcher />
                                                            <WithAccountAccessToken {...props}>
                                                                <EnvPatcher>
                                                                    <TopBar />
                                                                    <AppBar />
                                                                    <MobileMenu />
                                                                    <Main>
                                                                        <Suspense fallback={null}>
                                                                            <AppRoutes />
                                                                        </Suspense>
                                                                        <Allow anyOfScopes={[['admin:accounts']]}>
                                                                            <Welcome />
                                                                        </Allow>
                                                                    </Main>
                                                                </EnvPatcher>
                                                            </WithAccountAccessToken>
                                                        </ActionsProvider>
                                                    )}
                                                />
                                            </WithErrorHandler>
                                        </BrowserRouter>
                                    </Provider>
                                </Suspense>
                            </Root>
                        </MenuProvider>
                    </ToastProvider>
                </SWRConfig>
            </Suspense>
        </StrictMode>
    );
}


export default App;
