import fulfill from '../../../fulfill';
import { SideEffectFunction } from '../../../sideEffects';
import { CORE_API_HOSTNAME } from '../../../env';
import {
    ActionTypes,
    FetchClientResponse,
    GetClient,
    DeleteClient,
    FetchDeleteClientResponse,
    FetchGrantResponse,
    RotateSecret,
    FetchRotateSecretResponse,
    DeleteGrant,
    FetchDeleteGrantResponse
} from './actions';
import { Client, Grant, HasSecretClientResponse } from '../../../types/management-auth';
import { sortElements } from '../../helpers';

const fetchClient: SideEffectFunction<GetClient, FetchClientResponse> = async action => {
    const result: FetchClientResponse = await fulfill.get({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/auth/clients/${action.payload.clientId}`,
        handlers: {
            200: (client: Client) => ({
                type: ActionTypes.FetchClientResponse,
                payload: {
                    client,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchGrant: SideEffectFunction<FetchClientResponse, FetchGrantResponse> = async action => {
    const result: FetchGrantResponse = await fulfill.get({
        accountId: action.meta.cause.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.meta.cause.payload.accountId}/auth/client-grants?client_id=${
            action.payload.client.client_id
        }`,
        handlers: {
            200: (grants: Grant[]) => ({
                type: ActionTypes.FetchGrantResponse,
                payload: {
                    grants: sortElements(grants),
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchDeleteClient: SideEffectFunction<DeleteClient, FetchDeleteClientResponse> = async action => {
    const result: FetchDeleteClientResponse = await fulfill.delete({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/auth/clients/${action.payload.clientId}`,
        handlers: {
            200: (client: Client) => ({
                type: ActionTypes.FetchDeleteClientResponse,
                payload: {
                    client,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchRotateSecret: SideEffectFunction<RotateSecret, FetchRotateSecretResponse> = async action => {
    const result: FetchRotateSecretResponse = await fulfill.post({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/auth/clients/${
            action.payload.clientId
        }/rotate-secret`,
        handlers: {
            200: (client: HasSecretClientResponse) => ({
                type: ActionTypes.FetchRotateSecretResponse,
                payload: {
                    rotated: client,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const fetchDeleteGrant: SideEffectFunction<DeleteGrant, FetchDeleteGrantResponse> = async action => {
    const result: FetchDeleteGrantResponse = await fulfill.delete({
        accountId: action.payload.accountId,
        url: `${CORE_API_HOSTNAME}/v1/accounts/${action.payload.accountId}/auth/client-grants/${
            action.payload.grant_id
        }`,
        handlers: {
            200: (grant: Grant) => ({
                type: ActionTypes.FetchDeleteGrantResponse,
                payload: {
                    grant,
                },
                meta: {
                    cause: action,
                },
            }),
        },
    });
    return result;
};

const effects = {
    [ActionTypes.GetClient]: fetchClient,
    [ActionTypes.FetchClientResponse]: fetchGrant,
    [ActionTypes.DeleteClient]: fetchDeleteClient,
    [ActionTypes.RotateSecret]: fetchRotateSecret,
    [ActionTypes.DeleteGrant]: fetchDeleteGrant,
};

export default effects;
