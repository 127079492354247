import { Actions, ActionTypes } from './actions';
import { AccountAdminUser } from '../../../types/management-auth';

export interface State {
    isLoading: boolean;
    user: AccountAdminUser | undefined;
    isShowingRemoveModal: boolean;
    isDeleted: boolean;
    users: AccountAdminUser[];
}

export const initialState: State = {
    isLoading: false,
    user: undefined,
    isShowingRemoveModal: false,
    isDeleted: false,
    users: [],
};

export function reducer(state: State = initialState, action: Actions) {
    switch (action.type) {
        case ActionTypes.GetUser:
            return {
                ...initialState,
                isLoading: true,
            };

        case ActionTypes.FetchUserResponse:
            return {
                ...state,
                isLoading: false,
                user: action.payload.user,
                users: action.payload.users,
            };

        case ActionTypes.OpenRemoveUserModal:
            return {
                ...state,
                isShowingRemoveModal: true,
            };

        case ActionTypes.DismissRemoveUserModal:
            return {
                ...state,
                isShowingRemoveModal: false,
            };

        case ActionTypes.FetchRemoveUserResponse:
            return {
                ...state,
                isDeleted: true,
            };

        default:
            return state;
    }
}
