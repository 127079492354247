import { Account } from '../../types/management-auth';
import { PricePackage } from '../../types/management-auth/generated';

export enum ActionTypes {
    CreateAccount = '[settings/newAccount] CREATE_ACCOUNT',
    CreateAccountResponse = '[settings/newAccount] CREATE_ACCOUNT_RESPONSE',
    FetchNewAccountResponse = '[settings/newAccount] FETCH_EDIT_ACCOUNT_RESPONSE',
    ResetForm = '[settings/newAccount] RESET_FORM',
    SearchCompanyName = '[settings/newAccount] SEARCH_COMPANY_NAME',
    SearchCompanyNameResponse = '[settings/newAccount] SEARCH_COMPANY_NAME_RESPONSE',
    FillAccount = '[settings/newAccount] FILL_ACCOUNT',
    GetPricePackages = '[settings/newAccount] GET_PRICE_PACKAGES',
    GetPricePackagesResponse = '[settings/newAccount] GET_PRICE_PACKAGES_RESPONSE',
    GetAccountType = '[settings/newAccount] GET_ACCOUNT_TYPE',
}

export interface CreateAccount {
    type: ActionTypes.CreateAccount;
    payload: {
        accountId: string;
        account: Account;
        requestId: number;
        type: 'partner' | 'merchant';
    };
}

export function createAccount(accountId: string, account: Account, type: 'partner' | 'merchant'): CreateAccount {
    return {
        type: ActionTypes.CreateAccount,
        payload: {
            accountId,
            account,
            requestId: performance.now(),
            type,
        },
    };
}

export interface CreateAccountResponse {
    type: ActionTypes.CreateAccountResponse;
    payload: {
        account: any;
        partnerLink: string;
    };
}

export interface ResetForm {
    type: ActionTypes.ResetForm;
    payload: {};
}
export function resetForm(): ResetForm {
    return {
        type: ActionTypes.ResetForm,
        payload: {},
    };
}

export interface FetchNewAccountResponse {
    type: ActionTypes.FetchNewAccountResponse;
    payload: {};
    meta: {
        cause: CreateAccount;
    };
}


export interface SearchCompanyName {
    type: ActionTypes.SearchCompanyName;
    payload: {
        partialName: string;
        accountId: string;
    };
}

export function searchCompanyName(partialName: string, accountId: string): SearchCompanyName {
    return {
        type: ActionTypes.SearchCompanyName,
        payload: {
            partialName,
            accountId,
        },
    };
}

export interface SearchCompanyNameResponse {
    type: ActionTypes.SearchCompanyNameResponse;
    payload: {
        accounts: Account[];
    };
}

export function searchCompanyNameResponse(accounts: Account[]): SearchCompanyNameResponse {
    return {
        type: ActionTypes.SearchCompanyNameResponse,
        payload: {
            accounts,
        },
    };
}

export interface FillAccount {
    type: ActionTypes.FillAccount;
    payload: {
        account: Account;
    };
}

export function fillAccount(account: Account): FillAccount {
    return {
        type: ActionTypes.FillAccount,
        payload: {
            account,
        },
    };
}

export interface GetPricePackages {
    type: ActionTypes.GetPricePackages;
    payload: {
        accountId: string;
    };
}

export function getPricePackages(accountId: string): GetPricePackages {
    return {
        type: ActionTypes.GetPricePackages,
        payload: {
            accountId,
        },
    };
}

export interface GetPricePackagesResponse {
    type: ActionTypes.GetPricePackagesResponse;
    payload: {
        pricePackages: PricePackage[];
    };
}

export function getPricePackagesResponse(pricePackages: PricePackage[]): GetPricePackagesResponse {
    return {
        type: ActionTypes.GetPricePackagesResponse,
        payload: {
            pricePackages,
        },
    };
};

export interface GetAccountType {
    type: ActionTypes.GetAccountType;
    payload: {
        type: 'partner' | 'merchant';
    };
}

export function getAccountType(type: 'partner' | 'merchant'): GetAccountType {
    return {
        type: ActionTypes.GetAccountType,
        payload: {
            type,
        },
    };
}

export type Actions =
    | CreateAccount
    | CreateAccountResponse
    | FetchNewAccountResponse
    | ResetForm
    | SearchCompanyName
    | SearchCompanyNameResponse
    | FillAccount
    | GetPricePackages
    | GetPricePackagesResponse
    | GetAccountType;
