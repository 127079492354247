import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';

import { distances } from '../../../styles/constants';
import { Dl, Dt, Dd } from '../../../components/DescriptionList/Vertical';
import { H3 } from '../../../components/Typography';
import { DateTime } from '../../../components/Formatters';
import { Account } from '../../../types/management-auth';
import Card from '../../../components/Card';
import CardArea from './CardArea';
import Hr from '../../../components/Hr';
import StyledLink from '../../../components/StyledLink';

export interface TermsInformationProps extends WithTranslation {
    account: Account;
}

const TermsInformation = (
    {
        t,
        account,
    }: TermsInformationProps
) => {
    return (
        <Card marginBottom={distances.normal}>
            <H3>{t('settings.account.terms_and_conditions')}</H3>
            <Hr />
            <CardArea>
                {account.applicant.agreement && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.agreement.name')}</Dt>
                        <Dd>{account.applicant.agreement.name}</Dd>
                    </Dl>
                )}
                {account.company.terms_url && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.terms_url')}</Dt>
                        <Dd>{account.company.terms_url}</Dd>
                    </Dl>
                )}
                {account.applicant.agreement && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.agreement.version')}</Dt>
                        <Dd>
                            {account.applicant.agreement.version &&
                            account.applicant.agreement.version.startsWith('https') ? (
                                    <StyledLink
                                        href={account.applicant.agreement.version}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {account.applicant.agreement.version}
                                    </StyledLink>
                                ) : (
                                    account.applicant.agreement.version
                                )}
                        </Dd>
                    </Dl>
                )}
                {account.applicant.agreement && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.agreement.accepted_at')}</Dt>
                        <Dd>
                            <DateTime isoString={account.applicant.agreement.accepted_at || ''} />
                        </Dd>
                    </Dl>
                )}
                {account.applicant.email && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.applicant_email')}</Dt>
                        <Dd>{account.applicant.email}</Dd>
                    </Dl>
                )}
                {(account.applicant.first_name || account.applicant.last_name) && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.applicant_name')}</Dt>
                        <Dd>
                            {account.applicant.first_name} {account.applicant.last_name}
                        </Dd>
                    </Dl>
                )}
                {account.applicant.phone_number && (
                    <Dl>
                        <Dt doubleColumn>{t('settings.account.fields.applicant_phone_number')}</Dt>
                        <Dd>{account.applicant.phone_number}</Dd>
                    </Dl>
                )}
            </CardArea>
        </Card>
    );
};

export default withTranslation()(TermsInformation);
