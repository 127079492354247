import { effects as newClientEffects } from './newClient';
import { effects as clientDetailsEffects } from './clientDetails';
import { effects as newClientGrantEffects } from './newClientGrant';

const effects = {
    ...newClientEffects,
    ...clientDetailsEffects,
    ...newClientGrantEffects,
};

export default effects;
