export const chunk = (value: string, blocks: number[], delimiters: string[] = [' ']) => {
    const chars = value.split('');
    const formatted = blocks
        .map((block, i) => {
            return chars.splice(0, block).join('') + (delimiters[i] || delimiters[0] || '');
        })
        .join('');
    // add any remaining leftover characters after the chunking
    return `${formatted}${chars.join('')}`;
};
